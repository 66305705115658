
import { Nullable } from "@jamesgmarks/utilities";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { loadCommissions } from "../../../redux/features/commissions/actions";
import { useAppSelector } from "../../../redux/hooks";
import { HHMonthPicker } from "../../parts/HHMonthPicker";
import { CommissionsTable } from "./CommissionsTable";

export const CommissionsMonthly = ({ salesRepId }: { salesRepId: number }) => {
  const commissionsList = useAppSelector(state => state.commissions.commissionsList);
  const commissionSource = useAppSelector(state => state.commissions.commissionSource);

  const [yearValue, setYearValue] = useState((new Date()).getFullYear());
  const [monthValue, setMonthValue] = useState((new Date()).getMonth());

  const legacyInvoiceStates = [
    'Draft',
    'Sent',
    'Viewed',
    'Partial',
    'Disputed',
    'Paid',
    'Pending',
    'Outstanding',
    'Declined',
  ];
  const hydraInvoiceStates = ['sent', 'partial', 'disputed', 'resolved', 'overdue', 'viewed', 'paid'];
  const invoiceStates = JSON.stringify({
    legacyInvoiceStates,
    hydraInvoiceStates,
  });

  const fetchCommissions = () => {
    loadCommissions(
      salesRepId,
      `/monthly?invoiceStates=${invoiceStates}&year=${yearValue}&month=${monthValue + 1}&source=${commissionSource}`,
    );
  };

  useEffect(() => {
    fetchCommissions();
  }, [salesRepId, commissionSource]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Form.Group>
        <Grid container mt={1} p={2} spacing={2} alignItems='center'>
          <Grid item xs='auto'>
            <HHMonthPicker
              onChange={
                (d: Nullable<Date>) => {
                  setYearValue(d ? d.getFullYear() : (new Date()).getFullYear());
                  setMonthValue(d ? d.getMonth() : (new Date()).getMonth());
                }
              }
              value={new Date(yearValue, monthValue, 1)}
            />
          </Grid>
          <Grid item xs>
            <Button onClick={() => fetchCommissions()}>Load</Button>
          </Grid>
        </Grid>

      </Form.Group>

      <CommissionsTable commissionsList={commissionsList} />
    </>
  );
};
