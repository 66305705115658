import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { Button, Paper } from '@mui/material';
import { Form, Row, Col, Container } from 'react-bootstrap';

import { distinct, Nullable } from "@jamesgmarks/utilities";

import { beginOrchestration, loadRuntimeGroups } from "../../../redux/features/billing-runs/actions";
import { useAppSelector } from "../../../redux/hooks";
import { HHMonthPicker } from "../../parts/HHMonthPicker";

export const CreateBillingRunForm = () => {
  const history = useHistory();

  const today = new Date();
  const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
  const lastMonth = (lastDayOfLastMonth).getMonth() + 1;
  const lastMonthsYear = (lastDayOfLastMonth).getFullYear();

  const [ groupsLoaded, setGroupsLoaded ] = useState(false);
  const runtimeGroups = useAppSelector(state => state.billingRuns.runtimeGroups);

  const [checkedGroups, setCheckedGroups] = useState([] as number[]);
  const [year, setYear] = useState(lastMonthsYear);
  const [month, setMonth] = useState(lastMonth);
  const [preloadMissingClients, setPreloadMissingClients] = useState(false);
  const [runImmediately, setRunImmediately] = useState(true);

  const setCheckedGroup = (groupId: number, checked: boolean) => {
    const withRemovedGroup = checkedGroups.filter(cg => cg !== groupId);
    setCheckedGroups(checked ? [...withRemovedGroup, groupId] : withRemovedGroup);
  };

  const setAllGroupsChecked = (checked: boolean) => {
    const allGroups = distinct(
      (runtimeGroups || [])
        .filter((group) => (group.groupClientCount + group.groupPartnerCount) > 0)
        .map(rg => rg.id),
    );
    const toSet = (allGroups.length > distinct(checkedGroups ?? []).length) ? allGroups : [];
    setCheckedGroups(toSet);
  };

  useEffect(() => {
    setGroupsLoaded(true);
    if (!groupsLoaded) loadRuntimeGroups();
  }, [ groupsLoaded ]);

  return (
    <>
      <Container style={{ marginBottom: '4rem' }}>
        <Link to='/billing_runs'>
          <Button variant='contained' color='info'>&larr; Billing Runs</Button>
        </Link>

        <Form
          style={{ marginTop: '3rem' }}
          onSubmit={
            (e) => {
              e.preventDefault();
              console.info('Beginning orchestration with ', {
                year,
                month,
                checkedGroups,
                runImmediately,
                preloadMissingClients,
              });

              beginOrchestration(year, month, checkedGroups, preloadMissingClients, runImmediately);
              history.push('/billing_runs');
            }}>
          <Form.Row style={{ marginBottom: '2rem' }}>
            <HHMonthPicker
              onChange={
                (d: Nullable<Date>) => {
                  setYear(d ? d.getFullYear() : lastDayOfLastMonth.getFullYear());
                  setMonth((d ? d.getMonth() : lastDayOfLastMonth.getMonth()) + 1);
                }
              }
              value={new Date(year, month - 1, 1)}
            />
          </Form.Row>
          <Form.Row>
            <Form.Group controlId="allGroups">
              <Button
                color='secondary'
                onClick={(e) => setAllGroupsChecked(true)}
                variant='contained'
              >
                {checkedGroups.length > 0 ? 'Unselect all' : 'Select all groups'}
              </Button>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group controlId="preloadMissingClients">
              <Form.Check type="checkbox"
                label="Preload Missing Clients"
                checked={preloadMissingClients}
                onChange={(e) => setPreloadMissingClients(e.target.checked)}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group controlId="runImmediately">
              <Form.Check type="checkbox"
                label="Run immediately"
                checked={runImmediately}
                onChange={(e) => setRunImmediately(e.target.checked)}
              />
            </Form.Group>
          </Form.Row>

          <Paper sx={{ mt: 2, p: 2 }} elevation={5}>
            <Form.Row as={Row} xs={2} md={3} xl={4}>
              {(runtimeGroups || [])
                .filter((group) => (group.groupClientCount + group.groupPartnerCount) > 0)
                .map((group) => (
                  <Col key={group.id}>
                    <Form.Row style={{ marginTop: '1rem' }}>
                      <Col md="auto">
                        <strong>
                          <Form.Check
                            id={`group-cb-${group.id}`}
                            checked={checkedGroups.includes(group.id)}
                            onChange={(e) => setCheckedGroup(group.id, e.target.checked)}
                            type="checkbox"
                            label={
                              group.label
                                ? <span style={{color: 'blue'}}>{`${group.label} (${group.id})`}</span>
                                : (
                                  `${group.runType.slice(0, 1).toUpperCase()}`
                                + `${group.runType.slice(1).toLowerCase()} Group: ${group.id}`
                                )
                            } />
                        </strong>
                      </Col>
                      <Col md="auto">
                        {
                          group.runType === 'clients'
                            ? `${group.groupClientCount} billing accounts / ${group.clients?.length} total clients`
                            : `${group.groupPartnerCount} partner billing accounts`
                        }
                      </Col>
                    </Form.Row>
                  </Col>
                ))}
            </Form.Row>
          </Paper>
          
          <br />
          <Button
            color='secondary'
            size='large'
            type="submit"
            variant='contained'
          >
            Begin Run
          </Button>
        </Form>
      </Container>
    </>
  );
};
