import { Hash, Nullable } from '@jamesgmarks/utilities';
import { createSlice } from '@reduxjs/toolkit';
import { getSystemInfo } from '../systemInfo/actions';

export interface IAuthState {
  token: Nullable<string>,
  decodedToken: Nullable<Hash>,
  isDeveloper: boolean,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: { token: null, decodedToken: null, isDeveloper: false } as IAuthState,
  reducers: {
    setUserToken: (state, action: { type: string, payload: Nullable<string> }) => {
      // console.log(`Loading user token.`);
      state.token = action.payload;
      if (action.payload === null) state.decodedToken = null;
      const forcedPayload = action.payload ?? '';
      if ((forcedPayload ?? '').length > 0 && forcedPayload.indexOf('.') !== -1) {
        const tokenData = JSON.parse(atob((forcedPayload ?? '').split('.')[1]));
        // console.log(`Token data: `, { tokenData });
        const { isDeveloper } = tokenData;
        state.isDeveloper = isDeveloper;
        state.decodedToken = {
          ...tokenData,
          // isExpired: () => (new Date((tokenData?.exp ?? 0) * 1000).getTime() < Date.now()),
        };
        // console.log({ decodedToken: state.decodedToken })
        getSystemInfo();
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUserToken,
} = authSlice.actions;

export default authSlice.reducer;