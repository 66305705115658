import { Route, Switch } from "react-router-dom";
import { ChargesListView } from './ChargesListView';
import { useAppSelector } from "../../../redux/hooks";
import { loadCharges } from "../../../redux/features/charges/actions";
import React, { useEffect, useState } from "react";
import { ChargeSingleView } from "./ChargeSingleView";

export const ChargesContainer = () => {
  const charges = useAppSelector(state => state.charges.chargesList);

  const today = new Date();
  const [year] = useState<number>(today.getFullYear());
  const [month] = useState<number>(today.getMonth() + 1);

  useEffect(() => {
    loadCharges(year, month);
  }, [ month, year ]);

  return (
    <>
      <header className="Section-header">Charges</header>
      <Switch>
        <Route path="/charges/:id"><ChargeSingleView /></Route>
        <Route path="/charges">
          <ChargesListView charges={(charges || [])} />
        </Route>
      </Switch>
    </>
  );
};
