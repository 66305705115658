import { createSlice } from '@reduxjs/toolkit';
import { IInvoice } from '../../../entity-interfaces/IInvoice';
import { Invoice } from '../../../../../entities/hydra/Invoice';
import IFbInvoice from '../../../../../freshbooks-api/models/IInvoices';
import { IInvoiceUsageSplit } from '../../../../../rentsync-api/invoicing/IInvoiceUsageSplit';
import { IInvoiceQuickView } from '../../../entity-interfaces/IInvoiceQuickView';
import { Hash, HashOf, Nullable, partition } from '@jamesgmarks/utilities';
import { IActionType } from '../../utils';
import { IInvoiceWithUsageItems, IMonthLockedResponse } from './interfaces';
import { IInvoiceSendHistory } from '../../../interfaces/IInvoiceSendHistory';
import { IInvoiceWithUpdatedSendHistory } from '../../../interfaces/IInvoiceWithUpdatedSendHistory';
import { IClients, IPartners } from '@llws/lift-entity-interfaces';

export interface ISingleInvoiceData {
  invoice: Invoice,
  freshbooksInvoice: IInvoiceWithUsageItems,
  split: IInvoiceUsageSplit,
  quickview: IInvoiceQuickView,
  client: IClients,
  partner: IPartners,
  invoiceSendHistory: IInvoiceSendHistory[],
}

export interface IInvoicesState {
  loadedState: Nullable<string>,
  invoiceDownloadUrl: Nullable<string>,
  monthLocks: HashOf<boolean>,
  invoices?: IInvoice[],
  currentInvoice?: ISingleInvoiceData,
  currentInvoiceWIPWithUpdatedSendHistory?: IInvoiceWithUpdatedSendHistory,
  freshbooksInvoices?: Hash[],
  currentFreshbooksInvoice?: Hash,
  lastCreatedOtfInvoice: Nullable<IInvoice>,
  invoiceDownloadQueueLength: number,
  invoiceEmailSendQueueLength: number,
};

export const invoiceSlice = createSlice({
  name: 'invoices',
  initialState: {
    loadedState: null,
    lastCreatedOtfInvoice: null,
    monthLocks: {},
    invoiceDownloadQueueLength: 0,
    invoiceEmailSendQueueLength: 0,
  } as IInvoicesState,
  reducers: {
    invoicesListReceived: (state, action) => {
      // console.info(`Receiving (${action.payload?.length}) invoices.`);
      state.invoices = action.payload;
      state.loadedState = 'loaded';
    },
    currentInvoiceReceived: (state, action: IActionType<ISingleInvoiceData>) => {
      state.currentInvoice = action.payload;
      state.loadedState = 'loaded';
    },
    currentInvoiceWIPReceived: (state, action: IActionType<IInvoiceWithUpdatedSendHistory>) => {
      state.currentInvoiceWIPWithUpdatedSendHistory = action.payload;
      state.loadedState = 'loaded';
    },
    freshbooksInvoicesListReceived: (state, action: IActionType<IFbInvoice[]>) => {
      state.freshbooksInvoices = action.payload;
      state.loadedState = 'loaded';
    },
    currentFreshbooksInvoiceReceived: (state, action: IActionType<IFbInvoice>) => {
      state.currentFreshbooksInvoice = action.payload;
      state.loadedState = 'loaded';
    },
    otfInvoiceCreated: (state, action: { type: string, payload: IInvoice }) => {
      state.lastCreatedOtfInvoice = action.payload;
    },
    setLoadedState: (state, action: { type: string, payload: Nullable<string> }) => {
      state.loadedState = action.payload;
    },
    setInvoiceDownloadUrl: (state, action: { type: string, payload: Nullable<string> }) => {
      state.invoiceDownloadUrl = action.payload;
    },
    clearLastCreatedOtf: (state, action: { type: string, payload: void }) => {
      state.lastCreatedOtfInvoice = null;
    },
    invoiceUpdated: (state, { payload }: IActionType<IInvoice>) => {
      const current = (state.invoices ?? []).find(i => i.id === payload.id);
      if (!current) {
        console.info('Invoice not found:', { payload });
        return;
      }
      state.invoices = [
        ...(state.invoices ?? []).filter(i => i.id !== current.id),
        payload,
      ];
    },
    invoicesUpdated: (state, { payload }: IActionType<IInvoice[]>) => {
      const [ updatedInvoicesAlreadyInStore, updatedInvoicesNotInStore ] = (
        partition(
          payload,
          (updatedInvoice) => !!(state.invoices ?? []).find((i) => i.id === updatedInvoice.id),
        )
      );

      const foundInvoiceIds = updatedInvoicesAlreadyInStore.map((i) => i.id);
      const notFoundInvoiceIds = updatedInvoicesNotInStore.map((i) => i.id);

      if (notFoundInvoiceIds.length > 0) {
        console.info('The following invoices were not found in the Redux store:', notFoundInvoiceIds);
      }

      state.invoices = [
        ...(state.invoices ?? []).filter((i) => !foundInvoiceIds.includes(i.id)),
        ...updatedInvoicesAlreadyInStore,
      ];
    },
    monthLockUpdated: (state, { type, payload }: { type: string, payload: IMonthLockedResponse }) => {
      console.info(`Setting locked state to ${payload.locked} for ${payload.year}-${payload.month}`, { payload});
      state.monthLocks = {
        ...state.monthLocks,
        [`${payload.year}-${payload.month}`]: payload.locked,
      };
    },
    invoiceDownloadQueueLengthReceived: (state, action: IActionType<number>) => {
      state.invoiceDownloadQueueLength = action.payload;
    },
    invoiceEmailSendQueueLengthReceived: (state, action: IActionType<number>) => {
      state.invoiceEmailSendQueueLength += action.payload;
    },
    // currentInvoiceQuickViewReceived: (state, action) => {
    //   state.currentInvoiceQuickView = action.payload;
    // },
  },
});

// Action creators are generated for each case reducer function
export const {
  invoicesListReceived,
  currentFreshbooksInvoiceReceived,
  currentInvoiceReceived,
  currentInvoiceWIPReceived,
  freshbooksInvoicesListReceived,
  otfInvoiceCreated,
  setLoadedState,
  setInvoiceDownloadUrl,
  clearLastCreatedOtf,
  invoiceUpdated,
  invoicesUpdated,
  monthLockUpdated,
  invoiceDownloadQueueLengthReceived,
  invoiceEmailSendQueueLengthReceived,
} = invoiceSlice.actions;

export default invoiceSlice.reducer;
