import { useEffect, useState } from "react";

import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  Button, Col, Container, Form, Spinner,
} from "react-bootstrap";

import { intVal, Nullable } from "@jamesgmarks/utilities";

import { DataTable } from "../../../parts/data-table/DataTable";
import { getLastDayOfThisMonth, getMonthEnd } from "../../../../app-utils";
import { handleSelectChanged, numberSelectValueTransform } from "../../../../app-utils/handlers";
import { loadPartnersLookup } from "../../../../redux/features/lookups/actions";
import { loadReport } from "../../../../redux/features/reporting/actions";
import { setLoadingState } from "../../../../redux/features/reporting/reportingSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";

export const PartnerCallTrackingLinesSummary = () => {
  const dispatch = useAppDispatch();

  const callTrackingLineSummary = useAppSelector(state => state.reporting.partnerCallTrackingLineSummary);
  const { year, month, partnerId, loadedState } = useAppSelector(state => state.reporting);
  const partners = useAppSelector((store) => store.lookups.partners);

  const todayRef = (new Date());
  const lastDayOfThisMonth = getLastDayOfThisMonth();
  const lastDayOfTargetMonth = (
    (todayRef.getDate() === lastDayOfThisMonth.getDate())
      ? lastDayOfThisMonth
      : getMonthEnd(lastDayOfThisMonth.getFullYear(), lastDayOfThisMonth.getMonth())
  );

  const targetYear = year ?? lastDayOfTargetMonth.getFullYear();
  const targetMonth = month ?? (lastDayOfTargetMonth.getMonth() + 1);
  const targetPartnerId = partnerId ?? 7 as Nullable<number>; // Default Padmapper

  const [selectedYear, setSelectedYear] = useState(targetYear);
  const [selectedMonth, setSelectedMonth] = useState(targetMonth);
  const [selectedPartnerId, setSelectedPartnerId] = useState(targetPartnerId);

  useEffect(() => {
    loadPartnersLookup({ includeInactive: false });
  }, [ ]);

  return (
    <>
      <header className="Section-header">Padmapper Call Tracking Line Summary</header>
      <Container style={{ textAlign: 'center' }}>
        <Form style={{ justifyContent: 'center', marginBottom: "30px" }} onSubmit={(e) => {
          e.preventDefault();
          if (loadedState === 'loading') return;
          dispatch(setLoadingState('loading'));
          loadReport('partner_call_tracking_lines_summary', {
            year: selectedYear,
            month: selectedMonth,
            partnerId: selectedPartnerId,
          });
        }}>
          <Grid item xs={12}>
            <FormControl variant="outlined" size="small" fullWidth={true}>
              <InputLabel id="partnerId-label">Partner</InputLabel>
              {
                partners.length > 0
                  ? <Select
                    labelId="partnerId-label"
                    id="partnerId"
                    value={selectedPartnerId?.toString() ?? ''}
                    onChange={ handleSelectChanged(setSelectedPartnerId, numberSelectValueTransform) }
                  >
                    <MenuItem value={''}>-- Select One --</MenuItem>
                    {partners.map((p) => (
                      <MenuItem key={p.id} value={p.id ?? ''}>{p.name}</MenuItem>
                    ))}
                  </Select>
                  : '...'
              }
            </FormControl>
          </Grid>
          <Form.Row className="text-center" >
            <Col></Col>
            <Form.Label column xs="auto">Year</Form.Label>
            <Col xs="auto">
              <Form.Control
                id="summaryYear"
                type="number" min="2010" max={(new Date().getFullYear())} placeholder="YYYY" value={selectedYear}
                onChange={(e) => setSelectedYear(intVal(e.target.value))}
              />
            </Col>
            <Form.Label column xs="auto">Month</Form.Label>
            <Col xs="auto">
              <Form.Control
                id="summaryMonth"
                as="select"
                placeholder="Choose"
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(intVal(e.target.value))}
              >
                <option value="">Choose</option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </Form.Control>
            </Col>
            <Col xs="auto">
              <Button variant="primary" type="submit" disabled={loadedState === 'loading'}>
                {loadedState === 'loading' ? (<>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  /> Loading...</>)
                  : (callTrackingLineSummary ? 'Reload Summary' : 'Load Summary')
                }
              </Button>
            </Col>
            <Col></Col>
          </Form.Row>
        </Form>
        {
          callTrackingLineSummary &&
          <div>{callTrackingLineSummary.length} total call tracking lines</div>
        }
        {
          callTrackingLineSummary &&
          <>
            <DataTable
              data={callTrackingLineSummary}
              downloadFilename={`${partners.find(x => x.id === partnerId)?.partnerKey}_call_tracking_${year}_${month}`}
            />
          </>
        }
      </Container>
    </>
  );
};
