import { first, strVal } from "@jamesgmarks/utilities";
import { Grid, IconButton } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Card, Col, FormCheck, ListGroup, Row } from "react-bootstrap";
import { IInvoice, isIInvoiceArray } from "../../../entity-interfaces/IInvoice";
import { ILiftClient } from "../../../entity-interfaces/ILiftClient";
import { IAugmentedCreditNote } from "../../../interfaces/ICreditNote";
import { CreditNoteSendCardBody } from "./CreditNoteSendCardBody";
import { InvoiceSendCardBody } from "./InvoiceSendCardBody";
import ClearIcon from '@mui/icons-material/Clear';
import { IClientHasBillingContacts, IOwnershipGroups } from "@llws/lift-entity-interfaces";

/**
 * If the provided `ownershipGroup` has one or more `clientHasBillingContacts` records that match the
 * `ownershipGroup.id`, return those billing contacts. Otherwise, return all billing contacts
 * that don't specify an `ownershipGroupId`. 
 * @param ownershipGroup 
 * @param client
 */
export const getDefaultContactsForGroup = (
  client: ILiftClient,
  ownershipGroup?: IOwnershipGroups,
): IClientHasBillingContacts[] => {
  const ogContacts = (
    (client.clientHasBillingContacts ?? []).filter((chbc) => chbc.ownershipGroupId === (ownershipGroup?.id ?? null))
  );
  
  return (
    ogContacts.length > 0
      ? ogContacts
      : (client.clientHasBillingContacts ?? []).filter((chbc) => !chbc.ownershipGroupId)
  );
};

interface IDocumentGroupSectionProps {
  documents: IInvoice[] | IAugmentedCreditNote[];
  ownershipGroup?: IOwnershipGroups;
  groupKey: string;
  setRemovedSendCards: Dispatch<SetStateAction<string[]>>;
  setSelectedContactsForGroup: (groupKey: string, contacts: number[]) => void;
}

export const DocumentGroupSection = ({
  documents,
  ownershipGroup,
  groupKey,
  setRemovedSendCards,
  setSelectedContactsForGroup,
}: IDocumentGroupSectionProps) => {
  const clients = first<IInvoice | IAugmentedCreditNote>(documents)!.billingAccount.clients;
  const client = first(clients!)!;
  
  const billingContacts = getDefaultContactsForGroup(client, ownershipGroup);
    
  const [ checkedContacts, setCheckedContacts ] = useState([] as number[]);

  const [ opacity, setOpacity ] = useState(1);
  
  useEffect(() => {
    setCheckedContacts(
      (billingContacts ?? [])
        .filter(c => c.receivesInvoices)
        .map(c => c.billingContact!.id! ?? null),
    );
  }, [ setCheckedContacts ]); // eslint-disable-line react-hooks/exhaustive-deps
  
  const setContactChecked = (contactId: number, checked: boolean = true) => {
    const newContacts = [
      ...(checkedContacts.filter((cId) => cId !== contactId)),
      ...(checked ? [ contactId ] : []),
    ];

    setCheckedContacts(newContacts);
    setSelectedContactsForGroup(groupKey, newContacts);
  };

  const documentsDescriptor = isIInvoiceArray(documents) ? 'Invoices' : 'Credit Notes';
  const datePlaceholder = isIInvoiceArray(documents) ? '{{INVOICE_DATE}}' : '{{CREDIT_NOTE_DATE}}';
  
  const transitionTimeout = 400;

  return (
    <Row>
      <Col xs={12}>
        <Grid
          container
          alignItems='center'
          sx={{
            opacity,
            transition: `opacity ${transitionTimeout}ms ease-in`,
            WebkitTransition: `opacity ${transitionTimeout}ms ease-in`,
          }}
        >
          <Grid item xs={1}>
            <IconButton
              onClick={
                () => {
                  setOpacity(0);
                  
                  setTimeout(() => {
                    setRemovedSendCards((old) => [ ...old, strVal(groupKey) ]);
                  }, transitionTimeout);
                }
              }
              sx={{
                color: '#fff',
                backgroundColor: '#fd0100',
                '&:hover': {
                  backgroundColor: '#fd0100',
                  opacity: '0.75',
                },
              }}
            >
              <ClearIcon>
                Remove
              </ClearIcon>
            </IconButton>
          </Grid>
          <Grid item xs>
            <Card>
              <Card.Header>
                <Card.Title>
                  {
                    ownershipGroup?.name
                      ? `Ownership Group: [${ownershipGroup.id}] ${ownershipGroup?.name} (${client?.clientName})`
                      : client?.clientName ?? ' ISSUE'
                  }
                </Card.Title>
                <Card.Subtitle>
                  Subject: <em>New {documentsDescriptor} From Rentsync (Landlord Web Solutions) | {datePlaceholder}</em>
                </Card.Subtitle>
              </Card.Header>
              <Card.Body>
                <Card.Text><strong>Send To:</strong></Card.Text>
                <ListGroup variant="flush">
                  {
                    (billingContacts ?? [])
                      .slice()
                      .sort((a, b) => a.receivesInvoices > b.receivesInvoices ? -1 : 1)
                      .map((contactInfo, i) => {
                        const billingContactId = contactInfo?.billingContact?.id ?? -1;
                        const contactChecked = checkedContacts.includes(billingContactId);
              
                        return (
                          <ListGroup.Item
                            key={`${contactInfo.id ?? i}`}
                            action
                            variant={contactChecked ? 'primary' : (checkedContacts.length === 0 ? 'danger' : 'light')}
                            onClick={e => setContactChecked(billingContactId, !contactChecked)}
                          >
                            <FormCheck
                              checked={contactChecked}
                              label={
                                `${contactInfo.billingContact?.name ?? ''} (${contactInfo.billingContact?.email ?? ''})`
                              }
                              readOnly
                            />
                          </ListGroup.Item>
                        );
                      })
                  }
                </ListGroup>

                <hr />
                {
                  isIInvoiceArray(documents)
                    ? (
                      <InvoiceSendCardBody
                        invoices={documents}
                        numCheckedContacts={checkedContacts.length}
                      />
                    )
                    : (
                      <CreditNoteSendCardBody
                        creditNotes={documents}
                        numCheckedContacts={checkedContacts.length}
                      />
                    )
                }
              </Card.Body>
              {/* TODO: (ISSUE:#166) Set up capability to send one client at a time */}
              {/* <Card.Footer style={{textAlign: 'right'}}>
          <Button type="button" size="sm">Send</Button>
        </Card.Footer> */}
            </Card>
          </Grid>
        </Grid>
      </Col>
    </Row>
  );
};
