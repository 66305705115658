import { useEffect, useState } from "react";

import {
  Button, Col, Container, Form, Spinner, Tab, Tabs,
} from "react-bootstrap";

import { Nullable } from "@jamesgmarks/utilities";

import { getLastDayOfThisMonth, getMonthEnd } from "../../../../../app-utils";
import { loadReport } from "../../../../../redux/features/reporting/actions";
import { monthLockCoinStyle } from './styles';
import { MonthlyBillingCurrencyTab } from "./MonthlyBillingCurrencyTab";
import { MonthlyOverview } from "./MonthlyOverview";
import { setLoadingState } from "../../../../../redux/features/reporting/reportingSlice";
import { TMonthlyReportVersion } from "../../../../../../../entities/hydra/ReportCache";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { HHMonthPicker } from "../../../../parts/HHMonthPicker";

export enum EHideableColumns {
  accountNumber,
  discountAmount,
}

const reportVersionToHiddenColumnsMap: Record<TMonthlyReportVersion, EHideableColumns[]> = {
  '1.0.0': [ EHideableColumns.accountNumber, EHideableColumns.discountAmount ],
  '1.1.0': [],
  '1.2.0': [],
};

const today = new Date();
const dateOneMonthPrior = new Date(today.getFullYear(), today.getMonth() - 1, 1);

export const MonthlyBillingReport = () => {
  const dispatch = useAppDispatch();

  const monthLocks = useAppSelector(state => state.invoices.monthLocks);

  const monthlySummary = useAppSelector(state => state.reporting.monthlySummary);
  const reportVersion = monthlySummary?.version;
  const revenueSummary = monthlySummary?.revenueBreakdown;

  const { year, month, loadedState } = useAppSelector(state => state.reporting);

  const todayRef = (new Date(2021, 2, 23));
  const lastDayOfThisMonth = getLastDayOfThisMonth();
  const lastDayOfTargetMonth = (
    (todayRef.getDate() === lastDayOfThisMonth.getDate())
      ? lastDayOfThisMonth
      : getMonthEnd(lastDayOfThisMonth.getFullYear(), lastDayOfThisMonth.getMonth())
  );

  const targetYear = year ?? lastDayOfTargetMonth.getFullYear();
  const targetMonth = month ?? (lastDayOfTargetMonth.getMonth() + 1);

  const [selectedYear, setSelectedYear] = useState(targetYear);
  const [selectedMonth, setSelectedMonth] = useState(targetMonth);
  const [rebuildCache, setRebuildCache] = useState(false as boolean);
  const [isCurrentMonthLocked, setIsCurrentMonthLocked] = useState(true as boolean);

  const allCurrencyCodes = [ 'CAD', 'USD' ]; // TODO: Use currency codes from database?

  useEffect(() => {
    const label = `${selectedYear}-${selectedMonth}`;
    const newLocked = monthLocks[label] ?? false;
    setIsCurrentMonthLocked(newLocked);
  }, [ monthlySummary, selectedYear, selectedMonth ]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <header className="Section-header">Monthly Billing Summary</header>
      <Container style={{ textAlign: 'center' }}>
        <Form style={{ justifyContent: 'center' }} onSubmit={(e) => {
          e.preventDefault();
          if (loadedState === 'loading') return;
          dispatch(setLoadingState('loading'));
          loadReport('monthly_billing', { year: selectedYear, month: selectedMonth, rebuildCache });
        }}>
          <Form.Row className="text-center" style={{ marginTop: 30 }}>
            <Col></Col>
            <Col xs='auto'>
              <HHMonthPicker
                onChange={
                  (d: Nullable<Date>) => {
                    setSelectedYear(d ? d.getFullYear() : dateOneMonthPrior.getFullYear());
                    setSelectedMonth(((d ? d.getMonth() : dateOneMonthPrior.getMonth()) + 1));
                  }
                }
                value={new Date(selectedYear, selectedMonth - 1, 1)}
              />
            </Col>

            <Col xs="auto" style={{ paddingTop: 4 }}>
              <Button variant="primary" type="submit" size='lg' disabled={loadedState === 'loading'}>
                {loadedState === 'loading' ? (<>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  /> Loading...</>)
                  : (monthlySummary ? 'Reload Summary' : 'Load Summary')
                }
              </Button>
            </Col>
            <Col></Col>
          </Form.Row>
          {
            monthlySummary
            && (
              <Form.Row style={{ marginTop: '1rem' }}>
                <Col>
                  Last cached:{' '}
                  <b>
                    {new Date(monthlySummary.cached ?? '').toLocaleDateString()}{' '}
                    {new Date(monthlySummary.cached ?? '').toLocaleTimeString()}
                  </b>
                  {
                    !isCurrentMonthLocked
                      ? <>
                        {` - `}
                        <Form.Check
                          inline
                          type="checkbox"
                          id={`rebuild-cache-cb`}
                          label={`Rebuild`}
                          checked={rebuildCache}
                          onChange={() => setRebuildCache(!rebuildCache)}
                        />
                      </>
                      : <span style={{ ...monthLockCoinStyle }}>Month Locked</span>

                  }
                </Col>
              </Form.Row>
            )
          }
        </Form>
        {
          monthlySummary &&
          <>
            <Tabs defaultActiveKey="overview" id="uncontrolled-tab-example" transition={false} mountOnEnter>
              <Tab eventKey="overview" title="Overview">
                <MonthlyOverview revenueSummary={revenueSummary ?? ([])} />
              </Tab>
              {
                allCurrencyCodes.map(currencyCode => (
                  <Tab eventKey={`currency_${currencyCode}`} title={currencyCode} key={currencyCode}>
                    <MonthlyBillingCurrencyTab
                      currencyCode={currencyCode}
                      hiddenColumns={
                        reportVersion
                          ? reportVersionToHiddenColumnsMap[reportVersion] ?? []
                          : []}
                      monthlySummary={monthlySummary}
                    />
                  </Tab>
                ))
              }
            </Tabs>
          </>
        }
      </Container>
    </>
  );
};
