import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Popper,
}from '@mui/material';

import { arrayWrap, Nullable } from '@jamesgmarks/utilities';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface ISplitButtonItemProps {
  id?: string,
  label: string,
  visible?: boolean,
  disabled?: boolean,
  url?: string,
  onClick?: () => void,
}

export type SplitButtonItemProps = ISplitButtonItemProps & (
  { url: string, handler: undefined }
  | { url: undefined, handler: () => void }
  | { url: undefined, handler: undefined }
)

interface ChildSplitButtonItemProps extends ISplitButtonItemProps {
  __listIndex: number,
  onSelect: (id: string, index: number) => void,
};

export interface SplitButtonProps {
  variant?: "button" | "dots",
  defaultItemIndex?: number,
  onSelect?: (id: string, index: number) => void,
};

export class SplitButtonItem<T extends ISplitButtonItemProps> extends React.Component<T> {
  public getItemHandler() {
    return (() => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this.props.onClick
        ? this.props.onClick()
        : this.props.url ?? null;
    });
  }

  render () {
    return (
      <MenuItem
        disabled={this.props.disabled}
        onClick={this.getItemHandler()}
      >
        {this.props.label}
      </MenuItem>
    );
  }
}

class ChildSplitButtonItem extends SplitButtonItem<ChildSplitButtonItemProps> {
  public getItemHandler() {
    return (() => {
      const itemIndex = this.props.__listIndex;
      (super.getItemHandler())();
      (this.props.onSelect ?? (() => {}))(this.props.id ?? `${itemIndex}`, itemIndex);
    });
  }

  render () {
    return (
      this.props.visible !== false
        ? <MenuItem
          disabled={this.props.disabled}
          onClick={() => {
            this.getItemHandler()();
          }}
        >
          {this.props.label}
        </MenuItem>
        : <></>
    );
  }
}

export const SplitButton: React.FC<React.PropsWithChildren<SplitButtonProps>> = ({
  defaultItemIndex = 0,
  variant = "button",
  onSelect,
  children,
}) => {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<Nullable<HTMLElement>>(null);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    setOpen((prevOpen) => !prevOpen);
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const createUrlHandler = (url: string) => () => {
    if(url) { history.push(url); }
  };

  const defaultItem = (
    (Array.isArray(children)
      ? children[defaultItemIndex || 0]!
      : children!) as SplitButtonItem<ISplitButtonItemProps>
  );

  const defaultHandler = () => {
    (defaultItem.props.onClick ?? createUrlHandler(defaultItem.props.url ?? ''))();
    (onSelect ?? (() => {}))(defaultItem.props.id ?? `${defaultItemIndex}`, defaultItemIndex);
  };

  const _variant = variant === "button" ? "contained" : "text";
  const _color = variant === "button" ? "primary" : undefined;

  return (
    <>
      {
        variant === "button"
          ? (
            <ButtonGroup
              variant={_variant}
              color={_color}
              ref={anchorRef}
              aria-label="split button"
            >
              <Button onClick={defaultHandler}>{defaultItem.props.label}</Button>
              <Button
                color={_color}
                size="small"
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
          ): <>
            <Button variant={_variant} color={_color} onClick={defaultHandler}>{defaultItem.props.label}</Button>
            <Button
              variant={_variant}
              color={_color}
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <MoreVertIcon />
            </Button>

          </>
      }
      <Popper 
        open={open} 
        anchorEl={anchorEl} 
        role={undefined}
        nonce={undefined}
        onResize={() => {}}
        onResizeCapture={() => {}}
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList id="split-button-menu">
              {
                arrayWrap(children)
                  .map((child, i) => {
                    const __child = child as SplitButtonItem<ISplitButtonItemProps>;
                    return <ChildSplitButtonItem
                      key={i}
                      {...__child.props}
                      __listIndex={i}
                      onSelect={onSelect ?? (() => {})}
                    />;
                  })
              }
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};

SplitButton.propTypes = {
  onSelect: PropTypes.any.isRequired,
  children: (props, propName, componentName) => {
    let error: Nullable<Error> = null;
    const prop = props[propName];
    React.Children.forEach(prop, (child) => {
      // type.name seems to work for both Class and Functional components
      if (child.type.name !== 'SplitButtonItem') {
        error = new Error(`\`${componentName}\` only accepts children of type \`SplitButtonItem\`.`);
      }
    });
    return error;
  },
  // children: PropTypes.oneOfType([
  //   PropTypes.instanceOf(SplitButtonItem),
  //   PropTypes.arrayOf(PropTypes.instanceOf(SplitButtonItem)),
  // ]),
};