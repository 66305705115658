import { performApiActionRequest, TActionResponse } from '../../apiActionRequest';
import { Hash } from '@jamesgmarks/utilities';
import { showMessage } from '../messaging/actions';
import { IApiQueryResponse } from '@llws/api-common';

/**
 * Performs the `performApiActionRequest()` action and then alerts the user.
 * @param creditNoteIds - An array of credit note IDs to trigger PDF generation for.
 */
export const triggerActionWithResponse = async (
  action: string, parameters: Hash,
): Promise<void> => {
  const response = await performApiActionRequest<TActionResponse<Hash>>(
    action,
    parameters,
  ) as IApiQueryResponse<TActionResponse<Hash>>;

  showMessage({ asAlert: true, message: JSON.stringify(response.data), severity: 'success' });
};

