import {
  Button, Grid,
} from "@mui/material";
import { triggerActionWithResponse } from "src/redux/features/finance-control-tools/actions";
export const FlushCache = () => {
  // const charges = useAppSelector(state => state.charges.chargesList);
  return (
    <>
      <h3>Flush Cache</h3>
      <p>Flush Cache</p>

      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Button
            variant="contained"
            onClick={(e) => {
              e.stopPropagation();
              triggerActionWithResponse('flushCache', {});
            }}
          >Flush Cache</Button>
        </Grid>
      </Grid>
    </>
  );
};
