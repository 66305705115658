import { IBillingContacts, TBillingContactInternalNote } from "@llws/lift-entity-interfaces";
import { compactObject, Nullable } from "@jamesgmarks/utilities";
import { IApiCommandResponse } from "@llws/api-common";
import { ClientHasBillingContacts } from "@llws/typeorm-entities";
import {
  billingContactsListReceived,
  setLoadedState,
} from './billingContactsSlice';
import { dispatch } from '../../store';
import { IApiQueryListResponse, typedApiFetch } from '../../utils';
import { IShowMessageProps, showMessage } from "../messaging/actions";
import { REACT_APP_API_ROOT_URI } from '../../../App';

type TBillingContactBody = {
  id?: number,
  name: string,
  email: string,
  phone?: Nullable<string>,
  phone2?: Nullable<string>,
  address?: Nullable<string>,
  city?: Nullable<string>,
  province?: Nullable<string>,
  postal?: Nullable<string>,
  country?: Nullable<string>,
  internalNotes?: Nullable<TBillingContactInternalNote[]>
}

type TClientHasBillingContactBody = {
  id?: number,
  freshbooksClientId: number,
  ownershipGroupId: Nullable<number>,
  receivesInvoices: number,
  billingContactId?: Nullable<number>,
}

interface IBillingContactsQueryOptions {
  searchFilter?: string;
  page?: number;
  pageSize?: number;
  sortCriteria?: string;
  sortDirection?: 'ASC' | 'DESC';
}

const defaultQueryOptions: IBillingContactsQueryOptions = {
  searchFilter: '',
  pageSize: 20,
  page: 0,
  sortCriteria: 'id',
  sortDirection: 'DESC',
};

export const loadAllBillingContacts = async (
  queryOptions: IBillingContactsQueryOptions = defaultQueryOptions,
) => {
  dispatch(setLoadedState('loading'));
  const queryOptionsKeysArray = Object.keys(queryOptions);
  const queryOptionsValuesArray = Object.values(queryOptions);
  const queryArray = queryOptionsKeysArray.map((key, index) => `${key}=${queryOptionsValuesArray[index]}`);
  const queryString = `?${queryArray.join('&')}`;
  const url = `${REACT_APP_API_ROOT_URI}/billing_contacts/${queryString}`;
  const response = await typedApiFetch<IApiQueryListResponse<IBillingContacts>>(url);
  const responseData = await response.json();
  dispatch(billingContactsListReceived(responseData));
  dispatch(setLoadedState('loaded'));
};

export const createNewBillingContact = async (
  billingContactBody: TBillingContactBody,
  clientHasBillingContactBody: TClientHasBillingContactBody,
) => {
  dispatch(setLoadedState('loading'));
  const { freshbooksClientId } = clientHasBillingContactBody;
  const body = {
    billingContactBody: compactObject(billingContactBody),
    clientHasBillingContactBody: compactObject(clientHasBillingContactBody),
  };
  const url = (
    `${REACT_APP_API_ROOT_URI}/billing_contacts/${freshbooksClientId}`
  );
  const response = await typedApiFetch<IApiCommandResponse<ClientHasBillingContacts>>(
    url,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    },
  );

  const showMessageContent: IShowMessageProps = (
    response.ok 
      ? {
        message: `Billing contact has successfully been created and added to the client.`,
        severity: 'success', 
      }
      : { 
        message: `Failed to create and add the billing contact.`, 
        severity: 'error', 
      }
  );

  showMessage({...showMessageContent});
  dispatch(setLoadedState('loaded'));
  return response.ok;
};

export const updateBillingContact = async (
  billingContactBody: Required<TBillingContactBody>,
  clientHasBillingContactBody: Required<TClientHasBillingContactBody>,
) => {
  dispatch(setLoadedState('loading'));
  const { freshbooksClientId } = clientHasBillingContactBody;
  const { billingContactId } = clientHasBillingContactBody;
  const body = {
    billingContactBody: compactObject(billingContactBody),
    clientHasBillingContactBody: compactObject(clientHasBillingContactBody),
  };
  const url = (
    `${REACT_APP_API_ROOT_URI}/billing_contacts/${freshbooksClientId}/${billingContactId}`
  );
  const response = await typedApiFetch<IApiCommandResponse<ClientHasBillingContacts>>(
    url,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    },
  );

  const showMessageContent: IShowMessageProps = (
    response.ok 
      ? {
        message: `Billing contact has successfully been updated.`,
        severity: 'success', 
      }
      : { 
        message: `Failed to update the billing contact.`, 
        severity: 'error', 
      }
  );
  showMessage({...showMessageContent});
  dispatch(setLoadedState('loaded'));
  return response.ok;
};
