import { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { intVal } from '@jamesgmarks/utilities';

import {
  clearCurrentCreditNote,
  loadCurrentCreditNote,
  saveCreditNote,
} from '../../../redux/features/credit-notes/actions';
import { CreditNoteSingleView } from './CreditNoteSingleView';
import { ICreditNote } from '../../../interfaces/ICreditNote';
import { useAppSelector } from '../../../redux/hooks';
import { LoadingSpinner } from '../../parts/LoadingSpinner';

export const CreditNoteViewContainer = () => {
  const { creditNoteId } = useParams<{ creditNoteId?: string }>();

  const creditNote = useAppSelector((state) => state.creditNotes.currentCreditNote);

  const creditNoteLoadedState = useAppSelector(state => state.creditNotes.loadedState);

  useEffect(() => {
    clearCurrentCreditNote();
    loadCurrentCreditNote({
      creditNoteId: intVal(creditNoteId ?? 0),
    });

  }, [ creditNoteId ]);

  const onSave = (creditNote: ICreditNote) => {
    saveCreditNote(creditNote);
  };

  return (
    creditNoteLoadedState === 'loading'
      ? <LoadingSpinner loadedState={creditNoteLoadedState}></LoadingSpinner>
      : <>
        {creditNote && <CreditNoteSingleView creditNote={creditNote} onSave={onSave} />}
      </>
  );
};

