import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box } from '@mui/material';

export const PaymentCompleted = () => {
  return (
    <Box sx={{ textAlign: 'center'}} m={4}>
      <CheckCircleIcon/>
      YOUR PAYMENT WAS SUCCESSFULLY COMPLETED
    </Box>
  );
};