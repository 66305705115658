import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Select,
  Switch,
} from "@mui/material";

import { intVal, Nullable, strVal } from "@jamesgmarks/utilities";

import { assertYearMonth } from "../../../../interfaces/IYearMonth";
import { EIgnoredDataIntegrityErrorType } from "../../../../interfaces/EIgnoredDataIntegrityError";
import { isValidMonth, isValidYear } from "../../../../app-utils";
import { runSelectPreOrPostChecks } from "../../../../redux/features/data-integrity-checks/actions";
import useRefAction from "../../../../customHooks/useRefAction";
import { HHMonthPicker } from "../../../parts/HHMonthPicker";

interface IMultipleSelectProps {
  checks: string[]
  checksToRun?: string[]
  setChecks: React.Dispatch<React.SetStateAction<string[]>>
}

const MultipleSelect = (
  { 
    checks,
    checksToRun,
    setChecks,
  }: IMultipleSelectProps,
) => {
  const handleChangeMultiple = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { options } = e.target;
    const newChecks = Array.from(options).filter((o) => o.selected).map((o) => o.value);
    setChecks(newChecks);
  };

  return (
    <FormControl fullWidth style={{ height: '100%' }}>
      <InputLabel shrink htmlFor="select-multiple-native">
        Existing Checks
      </InputLabel>

      <Select
        inputProps={{
          id: 'select-multiple-native',
          size: checks.length,
        }}
        multiple
        native
        // @ts-ignore Typings are not considering `native`
        onChange={handleChangeMultiple}
        value={checksToRun}
      >
        {
          checks.map((c) => (
            <option key={c} value={c} style={{ paddingLeft: '15px', paddingTop: '4px' }}>
              {c}
            </option>
          ))
        }
      </Select>
    </FormControl>
  );
};

interface IDataIntegrityChecksFormProps {
  postcheckNames?: string[];
  precheckNames?: string[];
  setPostcheckTabActive: () => void;
  setPrecheckTabActive: () => void;
  setShowIgnoredErrors: () => void;
  setChecksAsJob: () => void;
  checksAsJob: boolean;
  showIgnoredErrors: boolean;
}

export const DataIntegrityChecksForm = (
  {
    postcheckNames,
    precheckNames,
    setPostcheckTabActive,
    setPrecheckTabActive,
    setShowIgnoredErrors,
    setChecksAsJob,
    checksAsJob,
    showIgnoredErrors,
  }: IDataIntegrityChecksFormProps,
) => {
  const today = new Date();
  const dateOneMonthPrior = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  const dateOneMonthPriorMonthNumber = dateOneMonthPrior.getMonth() + 1;
  
  const buttonStyle = {
    height: '50px',
    marginTop: 'auto',
  };

  const [year, setYear] = useState(`${dateOneMonthPrior.getFullYear()}`);
  const [month, setMonth] = useState(`${dateOneMonthPriorMonthNumber}`);

  const [prechecksToRun, setPrechecksToRun] = useState<string[]>([]);
  const [postchecksToRun, setPostchecksToRun] = useState<string[]>([]);

  const [ dateInputsFocused, setDateInputsFocused ] = useState(false);
  
  const [ runSelectedPostchecksButtonRef, clickRunSelectedPostchecksButton ] = useRefAction<HTMLButtonElement>('click');
  
  useEffect(() => {
    if (precheckNames) setPrechecksToRun(precheckNames);
  }, [precheckNames, setPrechecksToRun]);
  
  useEffect(() => {
    if (postcheckNames) setPostchecksToRun(postcheckNames);
  }, [postcheckNames, setPostchecksToRun]);

  return (
    <Box my={2} pt={3} px={1.5}>
      <Grid container spacing={2}>
        <Grid item container mb={2} xs={12}>
          <Grid item xs={4}>
            <FormGroup sx={{
              backgroundColor: '#fff',
              border: '1px solid gray',
              borderRadius: '9px',
              ml: '3px',
              p: '3px',
            }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showIgnoredErrors}
                    onChange={setShowIgnoredErrors}
                  />
                }
                label='Show Ignored Errors'
                sx={{
                  ml: '3px',
                  p: '3px',
                }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={checksAsJob}
                    onChange={setChecksAsJob}
                  />
                }
                label='Run check(s) as a new job'
                sx={{
                  ml: '3px',
                  p: '3px',
                }}
              />
            </FormGroup>
          </Grid>
        </Grid>

        <Grid item container xs={6}>
          <Grid item xs={12} mb={1}>
            <MultipleSelect 
              checks={precheckNames ?? []}
              checksToRun={prechecksToRun} 
              setChecks={setPrechecksToRun}
            />
          </Grid>

          <Button
            color="secondary"
            disabled={!isValidYear(year) || !isValidMonth(month)}
            fullWidth
            onClick={
              () => {
                setPrecheckTabActive();

                runSelectPreOrPostChecks(
                  assertYearMonth(
                    { year: intVal(year), month: intVal(month) },
                  ),
                  EIgnoredDataIntegrityErrorType.precheck,
                  prechecksToRun ?? [],
                  checksAsJob,
                );
              }
            }
            size="large"
            style={{ ...buttonStyle }}
            variant="contained"
          >
            Run Selected Prechecks
          </Button>
        </Grid>

        <Grid item container xs={6} alignItems='center' textAlign='center' spacing={1}>
          <Grid item xs={12}>
            <HHMonthPicker
              onChange={
                (d: Nullable<Date>) => {
                  setYear(strVal(d ? d.getFullYear() : dateOneMonthPrior.getFullYear()));
                  setMonth((strVal((d ? d.getMonth() : dateOneMonthPrior.getMonth()) + 1)));
                }
              }
              value={new Date(intVal(year), intVal(month) - 1, 1)}
            />
          </Grid>

          <Grid item xs={12}>
            <MultipleSelect
              checks={postcheckNames ?? []}
              checksToRun={postchecksToRun}
              setChecks={setPostchecksToRun}
            />
          </Grid>
  
          <Button
            color="secondary"
            disabled={!isValidYear(year) || !isValidMonth(month)}
            fullWidth
            onClick={
              () => {
                setPostcheckTabActive();
                
                runSelectPreOrPostChecks(
                  assertYearMonth(
                    { year: intVal(year), month: intVal(month) },
                  ),
                  EIgnoredDataIntegrityErrorType.postcheck,
                  postchecksToRun ?? [],
                  checksAsJob,
                );
              }
            }
            ref={runSelectedPostchecksButtonRef}
            size="large"
            style={{...buttonStyle}}
            variant="contained"
          >
            Run Selected Postchecks {dateInputsFocused && <>&nbsp; &crarr;</>}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
