import { IApiCommandResponse } from '@llws/api-common';
import { IQueryCommandOptions, convertQueryCommandToQueryString } from '@llws/dynamic-router-utils';
import { REACT_APP_API_ROOT_URI } from "../../../App";
import { dispatch, store } from "../../store";
import {
  IApiQueryListResponse,
  IApiQuerySingularResponse,
  typedApiFetch,
} from "../../utils";
import {
  setLoadedState,
  dynamicSingleReceived,
  dynamicSingleCommandResultReceived,
  dynamicManyReceived,
  clearDynamicDataForResource,
  dynamicManyCommandResultReceived,
} from "./dynamicSlice";
import { TDynamicTypeKey, TDynamicTypeTypeValue } from "./TDynamicTypesMap";
import { IShowMessageProps, showMessage } from "../messaging/actions";

export const getOne = async <T extends (string | number)>(
  resource: TDynamicTypeKey,
  id: T,
  relations: IQueryCommandOptions['relations'],
  source: 'lift' | 'hydra' = 'lift',
) => {
  dispatch(setLoadedState({ resource, newState: 'loading' }));

  const qs = convertQueryCommandToQueryString({ relations } ?? {});
  const url = `${REACT_APP_API_ROOT_URI}/v1/${source}/${resource}/${id}${qs}`;
  const response = await typedApiFetch<IApiQuerySingularResponse<TDynamicTypeTypeValue>>(url, { method: 'GET' });
  const responseData = await response.json();
  dispatch(dynamicSingleReceived({ resource, response: responseData })); // TODO: Error handling?

  dispatch(setLoadedState({ resource, newState: 'loaded' }));

  return store.getState().dynamic.data[resource].single;
};

export const getMany = async (
  resource: TDynamicTypeKey,
  queryCommandOptions?: IQueryCommandOptions,
  source: 'lift' | 'hydra' = 'lift',
) => {
  dispatch(setLoadedState({ resource, newState: 'loading' }));

  const url = (
    `${REACT_APP_API_ROOT_URI}/v1/${source}/${resource}${convertQueryCommandToQueryString(queryCommandOptions ?? {})}`
  );
  const response = await typedApiFetch<IApiQueryListResponse<TDynamicTypeTypeValue>>(url, { method: 'GET' });
  const responseData = await response.json();
  dispatch(dynamicManyReceived({ resource, response: responseData })); // TODO: Error handling?

  dispatch(setLoadedState({ resource, newState: 'loaded' }));
  return store.getState().dynamic.data[resource].list;
};

export const updateOne = async <T extends string>(
  resource: TDynamicTypeKey,
  body: Partial<Record<T, unknown>>,
  id: number,
  source: 'lift' | 'hydra' = 'lift',
) => {
  dispatch(setLoadedState({ resource, newState: 'loading' }));

  const url = (`${REACT_APP_API_ROOT_URI}/v1/${source}/${resource}/${id}`);
  const response = await typedApiFetch<IApiCommandResponse<TDynamicTypeTypeValue>>(
    url,
    { method: 'PUT', body: JSON.stringify(body) },
  );

  dispatch(setLoadedState({ resource, newState: 'loaded' }));

  const formattedResource: string = resource.split('_').join(' ');
  const showMessageContent: IShowMessageProps = (
    response.ok
      ? {
        message: `${formattedResource.charAt(0).toUpperCase()}${formattedResource.slice(1)} `
          + `has been successfully updated.`,
        severity: 'success',
      }
      : {
        message: `An error occured while updating ${formattedResource}.`,
        severity: 'error',
      }
  );

  if (response.ok) {
    const responseData = await response.json();
    dispatch(dynamicSingleCommandResultReceived({ resource, response: responseData })); // TODO: Error handling?
  }

  dispatch(setLoadedState({ resource, newState: 'loaded' }));

  showMessage({...showMessageContent});

  return response.ok;
};

export const updateMany = async <T extends string>(
  resource: TDynamicTypeKey,
  body: Partial<Record<T, unknown>>[],
  id: number,
  source: 'lift' | 'hydra' = 'lift',
) => {
  dispatch(setLoadedState({ resource, newState: 'loading' }));

  const url = (`${REACT_APP_API_ROOT_URI}/v1/${source}/${resource}/${id}`);
  const response = await typedApiFetch<IApiCommandResponse<TDynamicTypeTypeValue[]>>(
    url,
    { method: 'PUT', body: JSON.stringify(body) },
  );

  dispatch(setLoadedState({ resource, newState: 'loaded' }));

  const formattedResource: string = resource.split('_').join(' ');
  const showMessageContent: IShowMessageProps = (
    response.ok
      ? {
        message: `${formattedResource.charAt(0).toUpperCase()}${formattedResource.slice(1)} `
          + `has been successfully updated.`,
        severity: 'success',
      }
      : {
        message: `An error occured while updating ${formattedResource}.`,
        severity: 'error',
      }
  );

  if (response.ok) {
    const responseData = await response.json();
    dispatch(dynamicManyCommandResultReceived({ resource, response: responseData }));
  }

  showMessage({...showMessageContent});

  return response.ok;
};

export const createOne = async <T extends string>(
  resource: TDynamicTypeKey,
  body: Partial<Record<T, unknown>>,
  source: 'lift' | 'hydra' = 'lift',
) => {
  dispatch(setLoadedState({ resource, newState: 'loading' }));

  const url = (`${REACT_APP_API_ROOT_URI}/v1/${source}/${resource}`);
  const response = await typedApiFetch<IApiCommandResponse<TDynamicTypeTypeValue>>(
    url,
    { method: 'POST', body: JSON.stringify(body) },
  );

  dispatch(setLoadedState({ resource, newState: 'loaded' }));

  const formattedResource: string = resource.split('_').join(' ');
  const showMessageContent: IShowMessageProps = (
    response.ok
      ? {
        message: `${formattedResource.charAt(0).toUpperCase()}${formattedResource.slice(1)} `
          + `entry has been successfully created.`,
        severity: 'success',
      }
      : {
        message: `An error occured while creating ${formattedResource} entry.`,
        severity: 'error',
      }
  );
  showMessage({...showMessageContent});
  return response.ok;
};

export const deleteOne = async (
  resource: TDynamicTypeKey,
  id: number,
  source: 'lift' | 'hydra' = 'lift',
) => {
  dispatch(setLoadedState({ resource, newState: 'loading' }));

  const url = (`${REACT_APP_API_ROOT_URI}/v1/${source}/${resource}/${id}`);
  const response = await typedApiFetch<IApiCommandResponse<TDynamicTypeTypeValue>>(url, { method: 'DELETE' });

  dispatch(setLoadedState({ resource, newState: 'loaded' }));

  const formattedResource: string = resource.split('_').join(' ');
  const showMessageContent: IShowMessageProps = (
    response.ok
      ? {
        message: `${formattedResource.charAt(0).toUpperCase()}${formattedResource.slice(1)} `
          + `entry has been successfully deleted.`,
        severity: 'success',
      }
      : {
        message: `An error occured while deleting ${formattedResource} entry.`,
        severity: 'error',
      }
  );
  showMessage({...showMessageContent});
  return response.ok;
};

export const clearResource = async (resource: TDynamicTypeKey) => {
  dispatch(setLoadedState({ resource, newState: 'loading' }));

  dispatch(clearDynamicDataForResource({ resource })); // TODO: Error handling?

  dispatch(setLoadedState({ resource, newState: 'loaded' }));
};
