import { createSlice } from '@reduxjs/toolkit';
import { IApiQueryResponse } from '@llws/api-common';
import { arrayWrap, Hash, Nullable } from '@jamesgmarks/utilities';
import { Credit } from '../../../../../entities/hydra';
import { ICreditSummaryData } from '../../../interfaces/ICreditSummaryData';

export interface ICreditsState {
  loadedState: string,
  currentCredit: Nullable<Credit>,
  creditsList: Credit[],
  creditData?: ICreditSummaryData,
  nextPage: Nullable<string>,
  filters: Hash,
};

export const creditsSlice = createSlice({
  name: 'credits',
  initialState: {
    loadedState: 'idle',
    currentCredit: null,
    creditsList: [],
    nextPage: null,
    filters: {},
  } as ICreditsState,
  reducers: {
    creditDataReceived: (state, action: { type: string, payload: IApiQueryResponse<ICreditSummaryData> }) => {
      state.creditData = action.payload.data as ICreditSummaryData;
      state.loadedState = 'loaded';
    },
    creditsListReceived: (state, action: { type: string, payload: IApiQueryResponse<Credit> }) => {
      state.creditsList = arrayWrap(action.payload.data);

      console.log({ list: state.creditsList });

      state.nextPage = action.payload.meta?.links?.next;
      state.loadedState = 'loaded';
    },
    setLoadedState: (state, action: { type: string, payload: string }) => {
      state.loadedState = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoadedState,
  // currentCreditReceived,
  creditDataReceived,
  creditsListReceived,
} = creditsSlice.actions;

export default creditsSlice.reducer;
