import { useState } from "react";

import { Container, Grid, TextField, TextFieldProps } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { makeYmd } from "../../../../../app-utils";

export const TaxBreakdownByCurrency = () => {

  const quarterStartDate = new Date();
  quarterStartDate.setMonth(quarterStartDate.getMonth() - 4);

  const [selectedStartDate, setSelectedStartDate] = useState(quarterStartDate);
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());

  return (
    <>
      <Container style={{ textAlign: 'center' }}>
        <header className="Section-header">Tax Breakdown By Currency</header>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <DatePicker
                label="Start Date"
                value={selectedStartDate}
                onChange={(e: unknown) => { setSelectedStartDate(new Date(`${e || ''}`)); }}
                inputFormat="MM/dd/yyyy"
                renderInput={
                  (props: TextFieldProps) => <TextField {...props} />
                }
              />
            </Grid>
            <Grid item xs={3}>
              <DatePicker
                label="End Date"
                value={selectedEndDate}
                onChange={(e: unknown) => { setSelectedEndDate(new Date(`${e || ''}`)); }}
                inputFormat="MM/dd/yyyy"
                renderInput={
                  (props: TextFieldProps) => <TextField {...props} />
                }
              />
            </Grid>
          </Grid>
        </LocalizationProvider>
        <p>Start : {makeYmd(selectedStartDate)}</p>
        <p>End: {makeYmd(selectedEndDate)}</p>
      </Container>
    </>
  );
};
