import { intVal } from "@jamesgmarks/utilities";
import {
  Box, CircularProgress, Grid, IconButton, Paper, Typography, 
} from "@mui/material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { loadSingleStatementViewData } from "src/redux/features/statements/actions";
import { useAppSelector } from "src/redux/hooks";

import { SingleStatementViewComponents } from "./StatementSingleViewComponents/SingleStatementViewComponents";
import { REACT_APP_API_ROOT_URI } from "src/App";
import { displayFile } from "src/app-utils/display-file";
import { downloadSingleStatement } from "./StatementsListView";

type TView = 'statement' | 'sendHistory';
const isView = (object: unknown): object is TView => (
  (object as TView) === 'statement'
  || (object as TView) === 'sendHistory'
);

export const StatementSingleView = () => {
  const statementId  = intVal(useParams<{ statementId?: string }>().statementId);

  const singleViewStatementData = useAppSelector((state) => state.statements.singleViewStatementData);
  const loadedState = useAppSelector((state) => state.statements.loadedState);

  useEffect(() => {
    if (statementId) {
      loadSingleStatementViewData(statementId);
    }
  }, [statementId]);

  const generatePdf = async () => {
    if (!singleViewStatementData) {
      return;
    }
    await downloadSingleStatement(singleViewStatementData.statementId, singleViewStatementData.statementType);
  };

  return (
    <>{ singleViewStatementData &&
      
        <Grid container spacing={2} p={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} sx={{textAlign: 'center'}}>
            <Typography variant="h4" display="inline" pl={6}>
              STATEMENT
              { loadedState === 'loaded' 
              && <IconButton
                aria-label="download pdf"
                size="large"
                color="primary"
                sx={{ "&:hover": { backgroundColor: "#C9F5FF" } }}
                onClick={generatePdf}
              >
                <PictureAsPdfIcon />
              </IconButton>
              }
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Paper sx={{backgroundColor: '#f2eecb', padding: '2rem'}} elevation={2}>
              { loadedState === 'loaded' 
                ? <SingleStatementViewComponents 
                  statementData={singleViewStatementData} 
                  statementType={singleViewStatementData.statementType}
                />
                : <Box sx={{textAlign: 'center'}}><CircularProgress size={25} color='primary' sx={{ml: 2}} /> </Box>
              }
            </Paper>
          </Grid>
        </Grid>
    }
    </>
  );
};