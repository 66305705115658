import { Link } from "@mui/material";

export const MobileNav = () => (
  <div>
    <ul>
      <li><Link href="/mobile/actions">Actions</Link></li>
      <li><Link href="/mobile/billing-runs">Billing Runs</Link></li>
      <li><Link href="/mobile/integrity-checks">Integrity Checks</Link></li>
    </ul>
  </div>
);
