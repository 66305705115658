import React, { useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Nullable } from '@jamesgmarks/utilities';

export type TToggleOption = {
  label: string;
  value: string;
};

interface IMultiStateToggleProps {
  options: TToggleOption[];
  defaultValues: string | string[];
  onChange: (value: string | string[]) => void;
  multiple?: boolean;
  alwaysHaveOneSelected?: boolean;
}

export const HHMultiStateToggle = (
  { options, defaultValues, onChange, multiple = false, alwaysHaveOneSelected = true }: IMultiStateToggleProps,
) => {
  const [selectedValue, setSelectedValue] = useState<string | string[]>(defaultValues);

  const handleChange = (
    newSelectedValue: string | string[],
  ) => {
    if (multiple && Array.isArray(newSelectedValue) && newSelectedValue.length === 0) {
      return;
    }
    if (!multiple && newSelectedValue === null) {
      return;
    }
    setSelectedValue(newSelectedValue);
    onChange(newSelectedValue);
  };

  return (
    <ToggleButtonGroup
      value={selectedValue}
      exclusive={!multiple}
      onChange={(event, newSelectedValue) => handleChange(newSelectedValue)}
    >
      {options.map((option) => (
        <ToggleButton key={option.value} value={option.value} aria-label={option.label}>
          {option.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};