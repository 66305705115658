
export const partnerCoinStyle = {
  backgroundColor: '#00bfff',
  margin: '5px',
  padding: '3px',
  borderRadius: '7px',
  fontSize: 'x-small',
};

export const monthLockCoinStyle = {
  margin: '10px',
  padding: '5px 6px 3px',
  width: '15%',
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: '#3399cc',
  borderRadius: '7px',
  textAlign: 'center' as 'center',
  backgroundColor: 'gold',
  fontSize: 'x-small',
};