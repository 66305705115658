import { Card, CardContent, Container, Grid } from "@mui/material";
import { MassMigrateBuildingBillingTypes } from "./Tools/MassMigrateBuildingBillingTypes";
import { FlushCache } from "./Tools/FlushCache";
import { Hubspot } from "./Tools/Hubspot";
import { AddCreditToXero } from "./Tools/AddCreditToXero";
import { TriggerXeroInvoiceSync } from "./Tools/TriggerXeroInvoiceSync";
import { MarkInvoicesSentWithSendHistory } from "./Tools/MarkInvoicesSentWithSendHistory";
import { PublishInvoicesToFB } from "./Tools/PublishInvoicesToFB";
import { UpdateSubscriptionStartDate } from "./Tools/UpdateSubscriptionStartDate";

export const FinanceControlToolsContainer = () => {

  const UITools = [
    AddCreditToXero,
    FlushCache,
    MassMigrateBuildingBillingTypes,
    Hubspot,
    TriggerXeroInvoiceSync,
    MarkInvoicesSentWithSendHistory,
    PublishInvoicesToFB,
    UpdateSubscriptionStartDate,
  ];

  return (
    <>
      <header className="Section-header">Finance Control Tools</header>
      <Container fixed>
        <Grid container spacing={2}>
          {
            UITools.map((UITool) => {
              return (<>
                <Grid item xs={6}>
                  <Card>
                    <CardContent>
                      <UITool></UITool>
                    </CardContent>
                  </Card>
                </Grid></>);
            })
          }
        </Grid>
      </Container>
    </>
  );
};
