import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Container, Table, Row, Col, Button, Card, Tabs, Tab, Form,
} from 'react-bootstrap';

import {
  arrayWrap, floatVal, HashOf, intVal, keys, Nullable,
} from '@jamesgmarks/utilities';

import ILine from '../../../../../freshbooks-api/models/ILine';
import { toDollarAmount } from '../../../app-utils';
import { roundFloatToCents } from '../../../app-utils';
import { useAuth } from '../../../customHooks/useAuth';
import { loadCurrentClient } from '../../../redux/features/clients/actions';
import { loadCurrentInvoice } from '../../../redux/features/invoices/actions';
import { ILineUsageItemData, ILineWithUsageItems } from '../../../redux/features/invoices/interfaces';

import { useAppSelector } from '../../../redux/hooks';
import { FreshbooksClientLink } from '../../parts/freshbooks/FreshbooksClientLink';
import { FreshbooksInvoiceLink } from '../../parts/freshbooks/FreshbooksInvoiceLink';
import { EUnitOfMeasure } from './InvoicesContainer';
import { addCredit, loadAvailableCreditData, applyCredit } from '../../../redux/features/credits/actions';
import { AddCreditModal } from './AddCreditModalOLD';
import { ApplyCreditModal } from './ApplyCreditModal';
import { ICreditSummaryItem } from '../../../interfaces/ICreditSummaryItem';
import { RequirePermission } from '../../parts/RequirePermission';

import pdfFileIcon from '../../../assets/images/pdf.png';
import { REACT_APP_API_ROOT_URI } from '../../../App';
import { apiFetch } from '../../../redux/utils';
import { ISingleInvoiceData } from '../../../redux/features/invoices/invoiceSlice';
import { IClients } from '@llws/lift-entity-interfaces';

const InvoiceLineUsageItem = ({
  usageItem,
  index,
  client,
}: {
  usageItem: ILineUsageItemData,
  index: number,
  client: Nullable<IClients>,
}) => {
  return (
    <tr>
      <td>{usageItem.subscriptionId}</td>
      <td>
        ({usageItem.buildingId}) {(client?.buildings ?? []).find(b => b.id === usageItem.buildingId)?.buildingName}
      </td>
      <td>{usageItem.unitOfMeasure ? EUnitOfMeasure[usageItem.unitOfMeasure] : '-'}</td>
      <td>{usageItem.amount}</td>
      <td>{roundFloatToCents(usageItem.itemCost ?? 0)}</td>
      <td>{roundFloatToCents(usageItem.amount * (usageItem.itemCost ?? 0))}</td>
      {/* <td>{usageItem.zoneCoefficient}</td>
      <td>{roundFloatToCents(usageItem.amount * (usageItem.itemCost ?? 0) * (usageItem.zoneCoefficient ?? 1))}</td> */}
    </tr>
  );
};

const InvoiceLine = ({
  line,
  usageItems,
  index,
  client,
  onCreditButton,
}: {
  line: ILine,
  usageItems: ILineUsageItemData[]
  index: number,
  client: Nullable<IClients>,
  onCreditButton: () => void,
}) => {
  const [dropDown, setDropDown] = useState(false);

  const usageTotal = usageItems.reduce((acc, ui) => {
    return ({
      amount: acc.amount + ui.amount,
      count: acc.amount + 1,
      totalCost: acc.totalCost + (ui.itemCost ?? 0) * ui.amount,
    });
  }, {
    amount: 0,
    count: 0,
    totalCost: 0,
  });

  return (<>
    <tr>
      <td><button style={{ background: 'none', border: 'none', color: '#fff' }} onClick={() => setDropDown(!dropDown)}>
        {/* {dropDown ? (<>&#x21e9;</>) : (<>&#x21e8;</>)} */}
        <span style={{ fontFamily: 'Consolas, Courier, Roboto mono, Fixedsys' }}>
          {dropDown ? (<>[-]</>) : (<>[+]</>)}
        </span>
      </button></td>
      <td>{line.name}</td>
      <td>{line.description}</td>
      <td className='currency-right'>{toDollarAmount(line.unitCost?.amount || 0, 'brackets')}</td>
      <td style={{ textAlign: 'center' }}>{line.qty}</td>
      <td className='currency-right'>
        {toDollarAmount((line.unitCost?.amount || 0) * (line.qty || 0) || 0, 'brackets')}
        {/* Only provide credit button if user has credit permission */}
        {<RequirePermission grant="WRITE_CREDITS">
          &nbsp;<Button size="sm" onClick={onCreditButton}>credit</Button>
        </RequirePermission>}
      </td>
    </tr>
    {dropDown && <tr>
      <td colSpan={6}>
        <table className='mx-auto' width='98%'>
          <tr>
            <th>Subscription Id</th>
            <th>Building</th>
            <th>Unit</th>
            <th>Amount</th>
            <th>Unit Cost</th>
            <th>Subtotal</th>
          </tr>
          <tbody>
            {(usageItems || [])
              .slice()
              .sort((a, b) => (a.buildingId ?? 0) - (b.buildingId ?? 0))
              .filter(usageItem => usageItem.amount > 0)
              .map((usageItem, i) => (
                <InvoiceLineUsageItem key={i} index={i} usageItem={usageItem} client={client} />))
            }
            <tr>
              <td>{usageTotal.count} Items</td>
              <td></td>
              <td></td>
              <td>{usageTotal.amount}</td>
              <td></td>
              <td>{roundFloatToCents(usageTotal.totalCost)}</td>
            </tr>
          </tbody>
        </table>

      </td>
    </tr>}
  </>
  );
};

const InvoiceCreditLine = ({
  appliedCredit,
  index,
}: {
  appliedCredit: ICreditSummaryItem,
  index: number,
}) => {

  return (<>
    <tr>
      <td></td>
      <td>Credit</td>
      <td>{/* [{appliedCredit.identifier}]  */}{appliedCredit.description}</td>
      <td className='currency-right'>{toDollarAmount(-(appliedCredit.subtotal || 0), 'brackets')}</td>
      <td style={{ textAlign: 'center' }}>1</td>
      <td className='currency-right'>{toDollarAmount(-(appliedCredit.subtotal || 0), 'brackets')}</td>
    </tr>
  </>
  );
};

const getTaxData = (lines: ILineWithUsageItems[], subTotal: number) => {
  const taxData = lines.reduce((summary, line) => {
    const {
      taxName1, taxAmount1, taxName2, taxAmount2,
    } = line.line;
    const curKey1 = `${taxName1}:${taxAmount1}`;
    const curKey2 = `${taxName2}:${taxAmount2}`;
    return ({
      ...summary,
      ...(
        (taxName1 && !keys(summary).includes(curKey1))
          ? ({ [curKey1]: { name: taxName1, rate: taxAmount1 || 0, amount: subTotal * (taxAmount1 || 0) / 100 } })
          : {}
      ),
      ...(
        (taxName2 && !keys(summary).includes(curKey2))
          ? ({ [curKey2]: { name: taxName2, rate: taxAmount2 || 0, amount: subTotal * (taxAmount2 || 0) / 100 } })
          : {}
      ),
    });
  }, {} as HashOf<{ name: string, rate: number, amount: number }>);
  return taxData;
};

const loadCreditData = (invoice: ISingleInvoiceData) => {
  if (invoice && invoice.invoice) {
    loadAvailableCreditData({
      freshbooksClientId: intVal(invoice.invoice.freshbooksClientId),
      currencyCode: invoice.invoice.currency,
      taxRate: invoice.invoice.taxRate,
    });
  }
};

export const InvoiceSingleViewOLD = () => {
  const { isDeveloper } = useAuth()!;
  const { invoiceId, invoiceNumber } = useParams<{ invoiceId?: string, invoiceNumber?: string }>();

  const [ showAddCreditModal, setShowAddCreditModal ] = useState(false);
  const [ showApplyCreditModal, setShowApplyCreditModal ] = useState(false);
  const [ selectedLine, setSelectedLine ] = useState(null as Nullable<ILineWithUsageItems>);
  const [ showClientCreditSummary, setShowClientCreditSummary ] = useState(true);
  const [ filterCreditSummaryToInvoice, setFilterCreditSummaryToInvoice ] = useState(true);
  const [ showAwardedCreditInfo, setShowAwardedCreditInfo ] = useState(true);
  const [ showSpentCreditInfo, setShowSpentCreditInfo ] = useState(true);

  const toggleShowCreditSummary = () => { setShowClientCreditSummary(!showClientCreditSummary); };

  const invoice = useAppSelector(state => state.invoices.currentInvoice);
  const client = useAppSelector(state => state.clients.currentClient);
  // const loadedState = useAppSelector(state => state.invoices.loadedState);
  const invoiceDownloadUrl = useAppSelector(state => state.invoices.invoiceDownloadUrl);
  const creditData = useAppSelector(state => state.credits.creditData);

  loadCurrentInvoice({
    invoiceId: invoiceId ? intVal(invoiceId) : undefined,
    invoiceNumber: invoiceNumber,
    forceRefresh: false,
  });

  useEffect(() => {
    if (invoice) {
      loadCurrentClient({ clientId: invoice.client?.id ?? 0, forceRefresh: false });
      loadCreditData(invoice);
    }
  }, [invoice]);

  // const splitData = invoice ? JSON.parse(invoice.rawCostingData) as IInvoiceUsageSplit : null;

  const invoiceCredits = (
    (creditData?.creditSummary?.slice() ?? [])
      .filter(c => c.relatedInvoiceId === invoice?.invoice?.id && c.type === 'spend' && c.appliedAs === 'reduction')
  );

  const creditTotal = invoiceCredits.reduce((total, credit) => total + (credit.subtotal), 0);
  // const creditTaxes = invoiceCredits.reduce((total, credit) => total + (credit.tax), 0);
  const invoiceSubtotal = (
    (invoice?.freshbooksInvoice.lines || [])
      .reduce((acc: number, l) => acc + ((l.line.qty || 0) * (l.line.unitCost?.amount || 0)), 0) - creditTotal
  );
  const taxData = getTaxData(invoice?.freshbooksInvoice.lines || [], invoiceSubtotal);
  const totalTaxAmount = keys(taxData).reduce<number>((total, key) => {
    const td = taxData[key];
    return floatVal(total) + td.amount;
  }, 0);
  const invoiceTotal = invoiceSubtotal + totalTaxAmount;
  const outstandingAmount = (
    invoice?.invoice?.freshbooksOutstandingAmount
      ? floatVal(invoice?.invoice?.freshbooksOutstandingAmount)
      : invoiceTotal ?? 0
  );
  const paidAmount = invoiceTotal - floatVal(outstandingAmount);

  const representAmountAsNegative = (amount: number) => {
    return <span className='negativeNumber'>{`(${roundFloatToCents(amount)})`}</span>;
  };

  const numberAs = (amount: number, showAs: 'positive' | 'negative') => {
    return <>{
      showAs === 'positive'
        ? roundFloatToCents(amount)
        : representAmountAsNegative(amount)
    }</>;
  };

  const hasDownload = (invoice?.invoice?.pdfGenerations ?? []).length > 0;

  return (
    <>
      {
        showAddCreditModal
        && <AddCreditModal
          show={showAddCreditModal}
          line={selectedLine}
          invoice={invoice?.invoice ?? null}
          onHide={() => { setShowAddCreditModal(false); }}
          onSubmit={(creditData) => addCredit(creditData)}
        />
      }
      {
        showApplyCreditModal
        && <ApplyCreditModal
          show={showApplyCreditModal}
          invoice={invoice?.invoice ?? null}
          creditData={creditData!}
          onHide={() => { setShowApplyCreditModal(false); }}
          onCreditSubmit={(creditData) => applyCredit(creditData)}
        />
      }
      <Container>
        <Row><Col>&nbsp;</Col></Row>
        <Row className="align-items-start">
          <Col>
            <div>
              <h4>
                <strong>Invoice #: </strong>
                <span
                  title={`Rentsync: ${invoice?.invoice?.id}, Freshbooks: ${invoice?.invoice?.thirdPartyInvoiceId ?? 'n/a'}`} // eslint-disable-line max-len
                >{invoice?.invoice?.invoiceNumber ?? 'Not published'}</span>
                {
                  invoice?.invoice?.thirdPartyInvoiceId
                  && <>{' '}
                    <FreshbooksInvoiceLink
                      freshbooksInvoiceId={`${invoice.invoice.thirdPartyInvoiceId}`}
                      label={``}
                      includeCopyButton={false}
                    />
                  </>
                }
                {
                  invoice?.invoice?.thirdPartyInvoiceId
                  && <> <a href='#' onClick={async (e) => {
                    const invoiceNumber = invoice?.invoice?.invoiceNumber;
                    const url = `${REACT_APP_API_ROOT_URI}/downloads/download_invoice_pdf/${invoiceNumber}`;
                    const response = await apiFetch(url);
                    const file = window.URL.createObjectURL(await response.blob());
                    const a = Object.assign(document.createElement('a'), {
                      href: file,
                      download: `${invoiceNumber}.pdf`,
                    });
                    document.body.appendChild(a);
                    a.click(); a.remove();
                  }}>
                    <img
                      src={pdfFileIcon}
                      alt="pdf"
                      height="20px"
                      style={{
                        filter: 'grayscale(' + (hasDownload ? '0' : '1') + ')',
                      }}
                      title={hasDownload ? 'Download available' : 'Download not available'}
                    />
                  </a>
                  </>
                }
              </h4>
            </div>
            {
              invoice?.client
              && <div>
                <strong>Client: </strong>
                <Link
                  to={`/clients/${invoice?.split?.freshbooksCustomerData?.newFreshbooksClientId}`}
                >{invoice?.client?.name}</Link>
                  &nbsp;<FreshbooksClientLink
                  freshbooksClientId={invoice?.split?.freshbooksCustomerData?.newFreshbooksClientId ?? 0}
                  label={``} includeCopyButton={isDeveloper} />
              </div>
            }
            {
              invoice?.partner
              && <div>
                <strong>Partner: </strong>
                <Link
                  to={`/partners/${invoice?.split?.freshbooksCustomerData?.newFreshbooksClientId}`}
                >{invoice?.partner?.name}</Link>
                  &nbsp;<FreshbooksClientLink
                  freshbooksClientId={invoice?.split?.freshbooksCustomerData?.newFreshbooksClientId ?? 0}
                  label={``} includeCopyButton={isDeveloper} />
              </div>
            }
            <div>
              <strong>Status: </strong>
              {
                invoice?.invoice?.hydraState
                  ? arrayWrap(invoice?.invoice?.hydraState).map(hs => hs === 'unsent' ? 'unpublished' : hs)
                  : invoice?.invoice?.freshbooksState
              }
            </div>
            <div>
              <strong>Amount Outstanding:</strong> {toDollarAmount(invoiceSubtotal + totalTaxAmount)}{/** TODO: (ISSUE:#201) " - amountPaid" */}
            </div>
            <div>
              <strong>Invoice Date:</strong>{` `}
              <span style={{ color: 'red', fontWeight: 'bold' }}>
                {invoice?.invoice?.invoiceDate?.toString()?.split('T')?.[0]}
              </span>
            </div>
            {invoiceDownloadUrl && <div><a href={invoiceDownloadUrl}>Download</a></div>}
          </Col>
          <Col>
            <Card>
              <Card.Body style={{ padding: '.75rem' }}>
                {
                  invoice?.freshbooksInvoice?.organization
                  && <><Card.Title>{invoice?.freshbooksInvoice?.organization}</Card.Title></>
                }
                {/* first Name: {invoice?.freshbooksInvoice?.fName && <div>{invoice?.freshbooksInvoice?.fName}</div>}
                last Name: {invoice?.freshbooksInvoice?.lName && <div>{invoice?.freshbooksInvoice?.lName}</div>} */}
                {/* <Card.Title>{invoice?.freshbooksInvoice?.address && <div>{invoice?.freshbooksInvoice?.address}</div>}</Card.Title> */}
                {invoice?.freshbooksInvoice?.street && <div>{invoice?.freshbooksInvoice?.street}</div>}
                {invoice?.freshbooksInvoice?.street2 && <div>{invoice?.freshbooksInvoice?.street2}</div>}
                <div>
                  {invoice?.freshbooksInvoice?.city && `${invoice?.freshbooksInvoice?.city}`}
                  {invoice?.freshbooksInvoice?.province && `, ${invoice?.freshbooksInvoice?.province}`}
                  {invoice?.freshbooksInvoice?.code && `, ${invoice?.freshbooksInvoice?.code}`}
                </div>
                {invoice?.freshbooksInvoice?.country && <div>{invoice?.freshbooksInvoice?.country}</div>}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="align-items-end">

          <Col style={{ textAlign: 'right' }}>
          </Col>
        </Row>
        <Table striped bordered hover variant="dark" size="sm">
          <thead>
            <tr>
              <th>[+]</th>
              <th>Item</th>
              <th>Description</th>
              <th>Unit Cost</th>
              <th>Quantity</th>
              <th>Line Total</th>
            </tr>
          </thead>
          <tbody>
            {(invoice?.freshbooksInvoice.lines || []).map((line, i) => (
              <InvoiceLine
                key={i}
                index={i}
                line={line.line}
                usageItems={line.usageItems}
                client={client}
                onCreditButton={() => {
                  setSelectedLine(line);
                  setShowAddCreditModal(true);
                }} />
            ))}
            {invoiceCredits
              .map((c, i) => <InvoiceCreditLine key={c.identifier} appliedCredit={c} index={i} />)
            }
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={5}>Subtotal</th>
              <th className='currency-right'>{toDollarAmount(invoiceSubtotal)}</th>
            </tr>
            {keys(taxData).map((key) => {
              const td = taxData[key];
              return (<tr key={td.name + `${td.rate}`}>
                <td colSpan={5}>{td.name} ({td.rate}%)</td>
                <td colSpan={1} className='currency-right'>{toDollarAmount(td.amount)}</td>
              </tr>);
            })}
            <tr>
              <th colSpan={5}>Total</th>
              <th colSpan={1} className='currency-right'>{toDollarAmount(invoiceSubtotal + totalTaxAmount)}</th>
            </tr>
            <tr>
              <th colSpan={5}>Paid</th>
              <th colSpan={1} className='currency-right'>{toDollarAmount(paidAmount)}</th>
            </tr>
            <tr>
              <th colSpan={5}>Outstanding</th>
              <th colSpan={1} className='currency-right'>{toDollarAmount(outstandingAmount)}</th>
            </tr>
          </tfoot>
        </Table>
        <Tabs defaultActiveKey="credits" transition={false}>
          <Tab eventKey="credits" title="Credits">
            <Container style={{ padding: '8px' }}>
              <Row>
                <Col>
                  <h5>Available Client Credit:</h5>
                </Col>
                <Col style={{ textAlign: 'right' }}>
                  {
                    ((creditData?.availableSubtotal ?? 0) > 0)
                    &&
                    <Button onClick={() => setShowApplyCreditModal(true)}>
                      Apply a Credit to this Invoice
                    </Button>
                  }
                </Col>
              </Row>
              <Table>
                <tbody>
                  <tr>
                    <th>Before Tax:</th>
                    <td style={{ textAlign: 'right' }}>{roundFloatToCents(creditData?.availableSubtotal ?? 0)}</td>
                  </tr>
                  <tr>
                    <th>With Tax:</th>
                    <td style={{ textAlign: 'right' }}>{roundFloatToCents(creditData?.availableTotal ?? 0)}</td>
                  </tr>
                  <tr>
                    <th>Tax Rate: </th>
                    <td style={{ textAlign: 'right' }}>{floatVal(invoice?.invoice?.taxRate) * 100}%</td>
                  </tr>
                </tbody>
              </Table>
              <Row>
                <Col>
                  <h5>Credit Summary: <Button
                    size="sm"
                    onClick={(e) => toggleShowCreditSummary()}
                  >{showClientCreditSummary ? 'Hide' : 'Show'}</Button></h5>
                </Col>
                {
                  showClientCreditSummary
                  && (
                    <Col style={{ textAlign: 'right' }}>
                      <div>
                        Show Info For:&nbsp;
                        <Form.Check
                          inline
                          label="Client"
                          type="radio"
                          id={`show-all-radio`}
                          checked={!filterCreditSummaryToInvoice}
                          onChange={() => setFilterCreditSummaryToInvoice(false) }
                        />
                        <Form.Check
                          inline
                          label="This Invoice"
                          type="radio"
                          id={`show-invoice-only-radio`}
                          checked={filterCreditSummaryToInvoice}
                          onChange={() => setFilterCreditSummaryToInvoice(true) }
                        />
                      </div>
                      <div>
                        <Form.Check
                          inline
                          label="Awarded"
                          type="checkbox"
                          id={`show-awarded-checkbox`}
                          checked={showAwardedCreditInfo}
                          onChange={(e) => setShowAwardedCreditInfo(e.target.checked) }
                        />
                        <Form.Check
                          inline
                          label="Spent"
                          type="checkbox"
                          id={`show-spent-checkbox`}
                          checked={showSpentCreditInfo}
                          onChange={(e) => setShowSpentCreditInfo(e.target.checked) }
                        />
                      </div>
                    </Col>
                  )
                }
              </Row>
              {
                showClientCreditSummary
                && (
                  <>
                    <Table striped bordered hover variant="dark" size="sm">
                      <thead>
                        <tr>
                          <th>Identifier</th>
                          <th>Invoice</th>
                          <th>Description</th>
                          <th>Subtotal</th>
                          <th>Tax</th>
                          <th>Total</th>
                          <th>Applied as:</th>
                          <th>Credit/Applied Date:</th>
                          <th>Created:</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(creditData?.creditSummary ?? [])
                          .filter((cr) => (
                            (!filterCreditSummaryToInvoice || cr.relatedInvoiceId === invoice?.invoice?.id)
                          && (
                            ((showAwardedCreditInfo && cr.type === 'credit'))
                            || ((showSpentCreditInfo && cr.type === 'spend'))
                          )
                          ))
                          .map((cr) => {
                            return (
                              <tr key={cr.identifier}>
                                <td>{cr.identifier}</td>
                                <td>{cr.relatedInvoiceId}</td>
                                <td>{cr.description}</td>
                                <td>{numberAs(cr.subtotal, cr.type === 'credit' ? 'positive' : 'negative')}</td>
                                <td>{numberAs(cr.tax, cr.type === 'credit' ? 'positive' : 'negative')}</td>
                                <td>{numberAs(cr.total, cr.type === 'credit' ? 'positive' : 'negative')}</td>
                                <td>{cr.appliedAs ?? ' - '}</td>
                                <td>{!cr.appliedAs ? cr.creditDate : cr.dateApplied}</td>
                                <td>
                                  {
                                    new Date(cr.createdDate).toLocaleDateString()
                                  }
                                  {' '}
                                  {
                                    new Date(cr.createdDate).toLocaleTimeString()
                                  }
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>Total</td>
                          <td></td>
                          <td></td>
                          <td>{roundFloatToCents(creditData?.availableSubtotal ?? 0)}</td>
                          <td>
                            {
                              roundFloatToCents((creditData?.availableTotal ?? 0)
                              - (creditData?.availableSubtotal ?? 0))
                            }
                          </td>
                          <td>{roundFloatToCents(creditData?.availableTotal ?? 0)}</td>
                        </tr>
                      </tfoot>
                    </Table>
                  </>
                )
              }
            </Container>
          </Tab>
          <Tab eventKey="sendHistory" title="Send History">
            <Table>
              <thead>
                <th>Sender</th>
                <th>Recipient</th>
                <th>Sent</th>
              </thead>
              <tbody>
                {
                  invoice?.invoiceSendHistory.map(
                    (sendHistory) => <tr>
                      <td>{sendHistory.senderName}</td>
                      <td>{`${sendHistory.name} <${sendHistory.email}>`}</td>
                      {/* <td>{sendHistory.sentTime}</td> */}
                      <td>
                        {new Date(`${sendHistory.sentTime ?? ''}`).toLocaleDateString()}
                        {` `}
                        {new Date(`${sendHistory.sentTime ?? ''}`).toLocaleTimeString()}
                      </td>
                    </tr>,
                  )
                }
              </tbody>

            </Table>
          </Tab>
          <Tab eventKey="paymentHistory" title="Payment History">

          </Tab>
        </Tabs>
      </Container>
    </>
  );
};
