import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { Button } from "react-bootstrap";

interface IConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  titleText?: string;
  body?: string;
}

export const HHConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  titleText = "Are you sure?",
  body= "Are you sure you want to complete this action?",
}: IConfirmationModalProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{titleText}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {body}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          No
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};