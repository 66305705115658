import { TextField, TextFieldProps } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { Nullable } from '@jamesgmarks/utilities';

interface IHHMonthPickerProps {
  helperText?: string;
  label?: string;
  maxDate?: Date;
  minDate?: Date;
  onChange: (date: Nullable<Date>) => void;
  value: Nullable<Date>;
  yearFirst?: boolean;
}

export const HHMonthPicker = ({
  helperText,
  label = 'Month',
  maxDate,
  minDate = new Date(2020, 0),
  onChange,
  value,
  yearFirst,
}: IHHMonthPickerProps) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DatePicker
      label={label}
      maxDate={maxDate}
      minDate={minDate}
      onChange={onChange}
      renderInput={
        (params: TextFieldProps) => (
          <TextField
            sx={{ background: '#fff' }}
            {...params}
            helperText={helperText}
          />
        )
      }
      value={value}
      views={yearFirst ? [ 'year', 'month' ] : [ 'month', 'year' ]}
    />
  </LocalizationProvider>
);
