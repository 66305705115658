import {
  Switch,
  Route,
} from "react-router-dom";
import { StatementSingleView } from "./StatementSingleView";
import { StatementsListView } from "./StatementsListView";

export const StatementsContainer = () => {
  return (
    <div>
      <Switch>
        <Route path="/statements/:statementId"><StatementSingleView /></Route>
        <Route path="/statements">
          <header className="Section-header">Statements</header>
          <StatementsListView />
        </Route>
      </Switch>
    </div>
  );
};
  