import { floatVal } from "@jamesgmarks/utilities";
import { Grid, TextField } from "@mui/material";
import { handleInputChange } from "../../../../../app-utils/handlers";
import { isCostPerLeadMaxBillingType } from "@llws/hydra-shared";

export const CostPerLeadMax = ({
  billingTypeId,
  costPerLeadMax,
  setCostPerLeadMax,
}: {
  billingTypeId: number | null;
  costPerLeadMax: number;
  setCostPerLeadMax: React.Dispatch<React.SetStateAction<number>>,
}) => {
  if (billingTypeId === null || !isCostPerLeadMaxBillingType(billingTypeId)) { return <></>; }

  return (
    <Grid item xs={12}>
      <TextField
        id="baseSubscriptionCostPerLeadMax"
        variant="outlined"
        label="Cost Per Lead Max"
        name="baseSubscriptionCostPerLeadMax"
        type="number"
        value={costPerLeadMax ?? ''}
        onChange={handleInputChange(setCostPerLeadMax, floatVal)}
        size="small"
        fullWidth={true} />
    </Grid>
  );
};
