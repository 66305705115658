import { useContext } from 'react';
import { SocketContext } from './SocketContext';

export const SocketViewer = () => {
  const { me, name, eventData } = useContext(SocketContext);

  return (
    <>
      <div>Me: {me}, Name: {name}</div>
      <table>
        <tbody>
          {eventData.map(ed => (
            <tr><td>{ed.event}</td><td>{JSON.stringify(ed.payload, null, 2)}</td></tr>
          ))}
        </tbody>
      </table>
    </>
  );

};