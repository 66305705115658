import { useState } from "react";
import {
  Button, Grid,
  TextField,
} from "@mui/material";
import { DatePickerField } from "src/components/parts/runner-form/DatePickerField";
import { makeYmd } from "src/app-utils";
import { triggerActionWithResponse } from "src/redux/features/finance-control-tools/actions";
export const PublishInvoicesToFB = () => {

  const thisMonth = new Date();
  thisMonth.setDate(1);
  const lastMonth = new Date();
  lastMonth.setMonth(thisMonth.getMonth() - 1);
  const [publishMonth, setPublishMonth] = useState<string>(makeYmd(lastMonth));
  const [startingInvoiceNumber, setStartingInvoiceNumber] = useState<string>('');
  return (
    <>
      <h3>Publish Invoices to FB</h3>
      <p>Publish Invoices to FB in a give year and month.
         We can optionally provide an invoice number to start the upload from incase of a partial upload</p>
      <Grid container spacing={2}>

        <Grid item xs={8}>
          <DatePickerField
            label={'Date'}
            value={publishMonth}
            options={['month', 'year']}
            onChange={(newValue) => {
              if (newValue){
                setPublishMonth(makeYmd(newValue));
              }
            }}
          />
        </Grid>

        <Grid item xs={8}>
          <TextField
            id="invoice-number"
            label="invoice number"
            variant="outlined"
            value={startingInvoiceNumber}
            onChange={(e)=> {
              setStartingInvoiceNumber(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={8}>
          <Button
            variant="contained"
            onClick={(e) => {
              triggerActionWithResponse('dispatchAction', {
                actionName: 'postBillingFBInvoiceSync',
                parameters: {
                  date: publishMonth,
                  startingInvoiceNumber: startingInvoiceNumber === '' ? null : startingInvoiceNumber,
                },
              });
            }}
          >Publish Invoices to FB</Button>
        </Grid>
      </Grid>
    </>
  );
};
