import { useCallback, useEffect, useMemo, useState } from "react";

import {
  Button,
  Grid,
  TablePagination,
} from "@mui/material";
import { Add } from "@mui/icons-material";

import { intVal } from "@jamesgmarks/utilities";

import { useAppSelector } from "../../../../redux/hooks";
import { IBaseSubscriptions } from "@llws/lift-entity-interfaces";
import { BaseSubscriptionCreateEditModal } from "./BaseSubscriptionCreateEditModal";
import { BaseSubscriptionsListTable } from "./BaseSubscriptionsListTable";
import { getMany, createOne } from "src/redux/features/dynamic/actions";
import { Equal, IQueryCommandOptions } from "@llws/dynamic-router-utils";
import { IApiQueryListResponseMetaData } from "src/redux/utils";
import { saveBaseSubscription } from "src/redux/features/products/actions";

const CommonTablePager = ({
  metaData,
  rowsPerPageList,
  setPage,
  setLimit,
}: {
  metaData: IApiQueryListResponseMetaData,
  rowsPerPageList: number[],
  setPage: (n: number) => void,
  setLimit: (n: number) => void,
}) => {
  return (
    <TablePagination
      component="div"
      sx={{'& p': {marginTop: '1rem'}, backgroundColor: 'white'}}
      count={metaData?.totalRecords ?? 0}
      page={metaData && metaData.currentPage ? metaData.currentPage - 1 : 0}
      rowsPerPage={metaData?.recordsPerPage ?? 20}
      showFirstButton
      showLastButton
      rowsPerPageOptions={rowsPerPageList}
      onPageChange={(e, newPage)=>{setPage(newPage + 1);}}
      onRowsPerPageChange={(e) => { setLimit(intVal(e.target.value)); }}
    />
  );
};

const rowsPerPageList = [10, 20, 50, 100, 150, 200, 250, 500, 1000, 2000];

export const BaseSubscriptions = () => {
  const baseSubscriptions = useAppSelector(state => state.dynamic.data.base_subscriptions?.list ?? []);
  const metaData = useAppSelector(state => state.dynamic.metaData);

  const [ modalTarget, setModalTarget ] = useState({} as Partial<IBaseSubscriptions>);
  const [ show, setShowModal ] = useState(false);

  const [ page, setPage ] = useState<number>(1);
  const [ limit, setLimit ] = useState<number>(20);

  const queryCommand = useMemo<IQueryCommandOptions>(() => ({
    alias: 'bs',
    relations: [
      { path: 'bs.billingFrequency', alias: 'billingFrequency' },
      { path: 'bs.billingZones', alias: 'bz' },
      { path: 'bs.partner', alias: 'partner' },
      { path: 'bs.service', alias: 'service' },
      { path: 'bs.variableBasePrices', alias: 'vbp' },
    ],
    where: [
      Equal('bs.status', 'active'),
    ],
    page,
    limit,
    // orderBy: [{field: sortColumn, direction: (sortDirection === `ASC` ? `asc` : `desc`)}],
  }), [ page, limit ]);

  const updateList = useCallback(() => {
    getMany('base_subscriptions', queryCommand);
  }, [ queryCommand ]);

  useEffect(() => {
    updateList();
  }, [ updateList ]);

  const onNewButtonClicked = () => {
    setModalTarget({});
    setShowModal(true);
  };

  const onEditButtonClicked = (baseSubscription: IBaseSubscriptions) => {
    setModalTarget(baseSubscription);
    setShowModal(true);
  };

  return (
    <>
      <header className="Section-header">Subscription Types</header>
      <h5 style={{ textAlign: 'center' }}>Base Subscriptions</h5>
      <Grid container justifyContent="center">
        <>
          <Grid item xs={8} style={{ textAlign: 'right' }}>
            <Button onClick={(e) => { onNewButtonClicked(); }}><Add /> New Type</Button>
          </Grid>
          <Grid item xs={8}>
            <CommonTablePager
              metaData={metaData!}
              rowsPerPageList={rowsPerPageList}
              setPage={setPage}
              setLimit={setLimit}
            />
            <BaseSubscriptionsListTable
              rows={baseSubscriptions ?? []}
              onEditButtonClicked={onEditButtonClicked}
            />
          </Grid>
        </>
      </Grid>
      {show && <BaseSubscriptionCreateEditModal
        show={show}
        setShow={setShowModal}
        baseSubscription={modalTarget}
        onSaveRequest={async (saveObject) => {
          const saveable = {
            // TODO: `assertIBaseSubscriptions` (requires platform intervention)
            ...(saveObject as unknown as IBaseSubscriptions),
            revenueTrackingCode: (
              (saveObject.revenueTrackingCode ?? '').trim().length > 0
                ? saveObject.revenueTrackingCode
                : null
            ),
          };
          await saveBaseSubscription(saveable as unknown as IBaseSubscriptions);
          updateList();
        }}
      />}
    </>
  );
};
