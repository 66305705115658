import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  TablePagination,
  useTheme,
} from "@mui/material";
import { useAppSelector } from '../../../redux/hooks';
import { intVal } from '@jamesgmarks/utilities';
import { makeYmd } from '../../../app-utils';
import { showSortIconIfAvailable } from '../../../app-utils/helpers';
import CustomDataTable from '../../parts/customDataTable/CustomDataTable';
import { rowsPerPageList } from './Tabs';
import { createOne, deleteOne, getMany } from '../../../redux/features/dynamic/actions';
import { Equal, IQueryCommandOptions } from '@llws/dynamic-router-utils';
import { IOwnershipGroupHasBuildings } from '@llws/lift-entity-interfaces';
import { BuildingDropdown } from "../../parts/BuildingDropdown";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../customHooks/useAuth";

interface IOwnershipGroupPropertiesProps {
  ownershipGroupId: number;
}

export const OwnershipGroupProperties = ({ ownershipGroupId }: IOwnershipGroupPropertiesProps) => {
  const accountId = intVal(useParams<{ billingAccountId?: string }>().billingAccountId);
  const { userId } = useAuth();

  const ownershipGroupsHasBuildings = useAppSelector(
    state => state.dynamic.data.ownership_group_has_buildings?.list ?? [],
  );

  const loadedState = useAppSelector(state => state.dynamic.data.ownership_group_has_buildings?.loadedState);
  const metaData = useAppSelector(state => state.dynamic.metaData);

  const [ buildingsToBeAdded, setBuildingsToBeAdded ] = useState<{ buildingName: string, id: number }[]>([]);
  const [ buildingDropdownKey, setBuildingDropdownKey ] = useState<number>(0);

  const [ sortColumn, setSortColumn ] = useState('b.id');
  const [ sortDirection, setSortDirection ] = useState<`ASC` | `DESC`>(`ASC`);

  const [ page, setPage ] = useState<number>(1);
  const [ limit, setLimit ] = useState<number>(20);

  const [showAddPropertyList, setShowAddPropertyList] = useState<boolean>(false);

  const queryCommand: IQueryCommandOptions = useMemo(() => ({
    alias: 'oghb',
    relations: [
      { path: 'oghb.building', alias: 'b'},
      { path: 'b.city', alias: 'c'},
      { path: 'c.province', alias: 'p'},
      { path: 'b.buildingType', alias: 't'},
    ],
    where: [ Equal('oghb.ownershipGroupId', ownershipGroupId) ],
    page,
    limit,
    orderBy: [{field: sortColumn, direction: (sortDirection === `ASC` ? `asc` : `desc`)}],
  }), [ownershipGroupId, page, limit, sortColumn, sortDirection]);

  useEffect(() => {
    getMany('ownership_group_has_buildings', queryCommand);
  }, [queryCommand]);

  const sortBy = (criteria: string) => {
    setSortDirection(sortColumn === criteria && sortDirection === 'ASC' ? 'DESC' : 'ASC');
    setSortColumn(criteria);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLimit(intVal(event.target.value));
    setPage(1);
  };

  const customColumnsStyle = {
    width: '100%',
    backgroundColor: 'black',
    marginBottom: 1,
    fontWeight: 'bold',
    fontSize: '0.875rem',
    color: 'white',
  };
  const CustomColumns = () => {
    return (
      <>
        <Card sx={{ ...customColumnsStyle }}>
          <CardContent>
            <Grid container direction='row'>
              <Grid item xs sx={{cursor: 'pointer'}} onClick={() => sortBy('b.id')}>
                ID {showSortIconIfAvailable('b.id', sortColumn, sortDirection)}
              </Grid>
              <Grid item xs sx={{cursor: 'pointer'}} onClick={() => sortBy('b.buildingName')}>
                Name {showSortIconIfAvailable('b.buildingName', sortColumn, sortDirection)}
              </Grid>
              <Grid item xs sx={{cursor: 'pointer'}} onClick={() => sortBy('t.name')}>
                Type {showSortIconIfAvailable('t.name', sortColumn, sortDirection)}
              </Grid>
              <Grid item xs sx={{cursor: 'pointer'}} onClick={() => sortBy('b.status')}>
                Status {showSortIconIfAvailable('b.status', sortColumn, sortDirection)}
              </Grid>
              <Grid item xs>
                &nbsp;
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  };

  const customRowStyle = {
    width: '100%',
    marginBottom: 1,
    fontSize: '0.875rem',
    backgroundColor: 'gray',
    color: 'white',
  };
  const CustomRow = ({ item }: { item: IOwnershipGroupHasBuildings }) => {
    const [viewButtonColor, setViewButtonColor] = useState<'primary' | 'secondary' | 'info'>('primary');
    const theme = useTheme();
    const address: string =
      `${item.building?.streetNumber} ${item.building?.streetName} ${item.building?.city?.cityName} ` +
      `${item.building?.city?.province?.provinceCode} ${item.building?.postal}`;
    return (
      <>
        <Card
          sx={{ ...customRowStyle }}
          onMouseOver={() => setViewButtonColor('secondary')}
          onMouseLeave={() => setViewButtonColor('primary')}
        >
          <CardContent>
            <Grid container direction='row' sx={{ marginBottom: 1}}>
              <Grid item xs>
                {item.building?.id}
              </Grid>
              <Grid item xs>
                {item.building?.buildingName}
              </Grid>
              <Grid item xs>
                {item.building?.buildingType?.name}
              </Grid>
              <Grid item xs>
                {item.building?.status}
                {item.building?.status === 'deleted' ? ` (${makeYmd(new Date(item.building?.deletedAt as Date))})` : ``}
              </Grid>
              <Grid item xs>
                <Button
                  onClick={() => item.id ? handleRemovePropertyFromOwnershipGroup(item.id) : null}
                  sx={{'&:hover': {color: theme.palette.common.white}}}
                  variant='contained'
                  color={viewButtonColor}
                  size='small'
                >
                  Remove from og
                </Button>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs>
                <em>{address}</em>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  };

  const handleRemovePropertyFromOwnershipGroup = async (ownershipGroupHasBuildingId: number) => {
    await deleteOne(
      'ownership_group_has_buildings',
      ownershipGroupHasBuildingId,
    );
    setBuildingDropdownKey(buildingDropdownKey + 1);
    getMany('ownership_group_has_buildings', queryCommand);
  };

  const handleAddPropertyToOwnershipGroup = async (building: { buildingName: string, id: number }) => {
    const isOGHBCreated = await createOne(
      'ownership_group_has_buildings',
      {
        ownershipGroupId: ownershipGroupId,
        buildingId: building.id,
        createdUserId: userId,
      },
    );

    if (isOGHBCreated) {
      const index = buildingsToBeAdded.indexOf(building);
      const newBuildingsToBeAdded = [...buildingsToBeAdded.slice(0, index), ...buildingsToBeAdded.slice(index+1)];
      setBuildingsToBeAdded(newBuildingsToBeAdded);
      setBuildingDropdownKey(buildingDropdownKey + 1);
      getMany('ownership_group_has_buildings', queryCommand);
    }
  };

  const CustomBuildingRow = (item: { buildingName: string, id: number }) => {
    return (
      <Grid container item spacing={2}  xs={12} key={item.id}>
        <Grid item xs={6}>
          {`${item.buildingName} | Building ID: ${item.id}`}
        </Grid>
        <Grid item xs={6}>
          <Button variant='contained' onClick={() => handleAddPropertyToOwnershipGroup(item)}>
            Add To Ownership Group
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container spacing={1} component={Paper} p={2} my={2} justifyContent="center" elevation={3}>
        { showAddPropertyList
          ? <Grid item xs={12}>
            <BuildingDropdown
              accountId={accountId ?? null}
              onBuildingChanged={
                (building: { buildingName: string, id: number }) => {
                  // Add building to the list if it is not already in it
                  if (!buildingsToBeAdded.find((b) => b.id === building.id)) {
                    setBuildingsToBeAdded([
                      ...buildingsToBeAdded,
                      building,
                    ]);
                  }
                }
              }
              numberOfBuildingChanges={buildingDropdownKey}
            />
          </Grid>
          : <Grid item xs>
            <Button variant='contained' onClick={() => setShowAddPropertyList(!showAddPropertyList)}>
                Add Property
            </Button>
          </Grid>
        }
        {
          (buildingsToBeAdded && buildingsToBeAdded.length > 0)
            ? buildingsToBeAdded.map((b) => CustomBuildingRow(b))
            : ''
        }
        <Grid item xs={12}>
        </Grid>
      </Grid>
      <TablePagination
        component="div"
        sx={{'& p': {marginTop: '1rem'}, backgroundColor: 'white'}}
        count={metaData?.totalRecords ?? 0}
        page={metaData && metaData.currentPage ? metaData.currentPage - 1 : 0}
        rowsPerPage={limit}
        showFirstButton
        showLastButton
        rowsPerPageOptions={rowsPerPageList}
        onPageChange={(e, newPage)=>{setPage(newPage + 1);}}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
      <CustomDataTable
        data={
          (loadedState === 'loading'
            ? []
            : (ownershipGroupsHasBuildings ?? []))
        }
        rowComponent={CustomRow}
        columnsComponent={CustomColumns}
        loadingState={loadedState}
        dark={true}
        spacing={0}
      />
    </>
  );
};
