import { Nullable } from "@jamesgmarks/utilities";
import { CreditApplied, CreditNote, Invoice } from "../../../../../entities/hydra";

//duplicate
export enum EHydraPaymentState {
  active = 'active',
  deleted = 'deleted',
  void = 'void',
  processing = 'processing',
  cancelled = 'cancelled',
  failed = 'failed',
  expired = 'expired',
  timeout = 'timeout'
}

export enum EHydraPaymentType {
  creditBalance = 'credit-balance',
  creditCard = 'credit-card',
  eTransfer = 'e-transfer',
  cheque = 'cheque',
  credit = 'credit',
  bankTransfer = 'bank-transfer',
  paypal='paypal',
  ach='ach',
  cash='cash',
  other='other',
  amount='amount',
  unknown='unknown',
  overPayment='overpayment'
}

export enum EHydraPaymentSourceType {
  stripe = 'stripe',
  freshbooks = 'freshbooks',
  hydra = 'hydra',
  xero = 'xero',
}

export interface IPayment {
  id: number;
  parentPaymentId: Nullable<number>;
  invoiceId: number;
  creditNoteId: Nullable<number>;
  creditsAppliedId: Nullable<number>;
  amount: string;
  currencyCode: string;
  datePaid: string;
  paymentType: EHydraPaymentType;
  sourceType: EHydraPaymentSourceType;
  externalPaymentId: Nullable<string>;
  hydraState: EHydraPaymentState;

  created: string;
  createdUserId: number;
  modified: Nullable<string>;
  modifiedUserId: Nullable<number>;

  invoice?: Invoice;
  creditNote?: CreditNote;
  creditApplied?: CreditApplied;
}
