import {
  Grid, Paper, Table, TableBody, TableContainer, TableHead, 
} from "@mui/material";
import { Link } from "react-router-dom";
import { StyledTableCell, StyledTableRow } from "../../parts/mui/StyledTables";

export const ReportingListView = ({ reports }: { reports: Array<{ name: string, path: string }>}) => {
  return (
    <>
      <header className="Section-header">Reporting Portal</header>
      <br />

      <Grid container justifyContent='center'>
        <Grid item xs={10} md={8} lg={6}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <StyledTableRow dark={true}>
                  <StyledTableCell dark={true}>Report</StyledTableCell>
                  <StyledTableCell dark={true}>&nbsp;</StyledTableCell>
                </StyledTableRow>
              </TableHead>

              <TableBody>
                {
                  (reports || []).map((report, i) => (
                    <StyledTableRow dark={true} key={i}>
                      <StyledTableCell dark={true}>{report.name}</StyledTableCell>
                      <StyledTableCell dark={true} customSx={{ textAlign: 'right', pr: 3.5 }}>
                        <Link to={`/reporting/reports${report.path}`}>View</Link>
                      </StyledTableCell>
                    </StyledTableRow>))
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};
