import { dispatch, store } from '../../store';
import { IApiQueryListResponse, IApiQuerySingularResponse, typedApiFetch } from '../../utils';
import {
  creditNotesListReceived,
  currentCreditNoteCleared,
  currentCreditNoteReceived,
  setLoadedState,
} from './creditNotesSlice';
import { ICreditNote } from '../../../interfaces/ICreditNote';
import { REACT_APP_API_ROOT_URI } from '../../../App';
import { performApiActionRequest, TActionResponse } from '../../apiActionRequest';
import { arrayWrap, Hash } from '@jamesgmarks/utilities';
import { showMessage } from '../messaging/actions';
import { IApiCommandResponse, IApiQueryResponse } from '@llws/api-common';
import { EmailData } from '../../../interfaces/EmailData';
import { IApplyCreditNoteModalSubmitData } from '../../../interfaces/IApplyCreditModalSubmitData';
import { loadCurrentInvoice, loadCurrentWIPInvoice } from '../invoices/actions';
import { loadAvailableCreditData } from '../credits/actions';
import { IPayment } from '../payments/interfaces';
import { ICredit } from '../../../interfaces/ICredit';

export const loadCurrentCreditNote = async (
  {
    creditNoteId,
  }: {
    creditNoteId: number
  },
) => {
  const url = `${REACT_APP_API_ROOT_URI}/credit_notes/${creditNoteId}`;
  dispatch(setLoadedState('loading'));
  const response = await typedApiFetch(url);
  const responseData = await response.json() as IApiQuerySingularResponse<ICreditNote>;
  dispatch(currentCreditNoteReceived(responseData));
};

export const clearCurrentCreditNote = async () => {
  dispatch(currentCreditNoteCleared());
};

export const loadCreditNotes = async ({ billingAccountId }: { billingAccountId?: number }) => {
  const qs = billingAccountId ? `?billingAccountId=${billingAccountId}` : '';
  const url = `${REACT_APP_API_ROOT_URI}/credit_notes${qs}`;
  dispatch(setLoadedState('loading'));
  const response = await typedApiFetch(url);
  const responseData = await response.json() as IApiQueryListResponse<ICreditNote>;
  dispatch(creditNotesListReceived(responseData));
};

export const saveCreditNote = async (creditNote: ICreditNote) => {
  dispatch(setLoadedState('loading'));
  const suffix = creditNote.id ? `/${creditNote.id}` : '';
  const method = creditNote.id ? 'PUT' : 'POST';
  const url = `${REACT_APP_API_ROOT_URI}/credit_notes${suffix}`;
  
  const reducedCreditNote = {
    ...creditNote,
    credits: creditNote.credits.map((credit:ICredit) => {
      const {generatedSubscription, rawJson, ...reducedCredit} = credit;
      return reducedCredit;
    }), 
  };

  const response = await typedApiFetch<IApiCommandResponse<ICreditNote>>(url, {
    method,
    body: JSON.stringify(reducedCreditNote),
  });

  const { data } = await response.json();

  const [ savedCreditNote ] = arrayWrap(data);
  
  if (savedCreditNote.id) {
    loadCurrentCreditNote({creditNoteId: savedCreditNote.id});
  }

  loadCreditNotes({});
};

/**
 * Performs the `generateCreditNotesForCreditNotes()` action and then alerts the user.
 * @param creditNoteIds - An array of credit note IDs to trigger PDF generation for.
 */
export const triggerCreditNotePdfGeneration = async (
  creditNoteIds: number[],
): Promise<void> => {
  await performApiActionRequest<TActionResponse<Hash>>(
    'generatePdfsForCreditNotes',
    { creditNoteIds },
  ) as IApiQueryResponse<TActionResponse<Hash>>;
  
  showMessage({ message: 'Credit Notes PDF generation started...', severity: 'success' });
};

interface ICreditNoteNotification {
  toBillingContactIds: number[];
  creditNoteIds: number[];
}

const isCreditNoteNotification = (obj: unknown): obj is ICreditNoteNotification => (
  !!(obj as ICreditNoteNotification).creditNoteIds
);

export const assertValidCreditNoteNotificationArray = (x: unknown[]): ICreditNoteNotification[] | never => {
  if (!x.every(isCreditNoteNotification)) {
    throw new Error('Invalid array! Every element must be `ICreditNoteNotification`!');
  }

  return x;
};

export const sendMultipleCreditNoteNotifications = async (
  notifications: ICreditNoteNotification[],
) => {
  const systemInfo = store.getState().systemInfo;

  dispatch(setLoadedState('loading'));

  const outNotifications = notifications.reduce((acc, n) => {
    if (!n.toBillingContactIds || n.toBillingContactIds.length === 0) {
      showMessage({ message: 'Must select billing contacts.' });
      throw new Error('Must select billing contacts');
    }

    return [
      ...acc,
      ...(n.toBillingContactIds.map(c => ({
        to: c,
        creditNoteIds: n.creditNoteIds,
        isDevelopment: systemInfo.env.NODE_ENV !== 'production',
      }))),
    ];
  }, [] as ({ to: number, creditNoteIds: number[], isDevelopment: boolean; })[]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const response = await performApiActionRequest<TActionResponse<{ to: EmailData, success: boolean; }[]>>(
    'triggerCreditNoteNotifications',
    { notifications: outNotifications },
  );

  dispatch(setLoadedState('creditNotesSent'));
  showMessage({ message: 'Credit Notes sent', severity: 'success' });

  setTimeout(() => { setLoadedState('idle'); }, 5000);
};

export const applyCreditNote = async (creditNoteApplyData: IApplyCreditNoteModalSubmitData) => {
  const url = `${REACT_APP_API_ROOT_URI}/credit_notes/applied`;

  dispatch(setLoadedState('loading'));

  const response = await typedApiFetch<IApiQuerySingularResponse<ICreditNote>>(url, {
    method: 'POST',
    body: JSON.stringify(creditNoteApplyData),
  });

  const responseData = await response.json();
  console.info({ status: response.status, responseData });
  dispatch(currentCreditNoteReceived(responseData)); // TODO: Error handling?

  const { freshbooksClientId, invoiceId, currencyCode, taxRate } = creditNoteApplyData;

  if (invoiceId) {
    await loadCurrentInvoice({ invoiceId, forceRefresh: true });
    await loadCurrentWIPInvoice({ invoiceId, forceRefresh: true });
  }

  if (freshbooksClientId) {
    await loadCreditNotes({ billingAccountId: freshbooksClientId });
    await loadAvailableCreditData({freshbooksClientId, currencyCode, taxRate: `${taxRate}` });
  }
};

export const applyCreditBalanceToSourceInvoices = async (creditNoteId: number) => {
  const url = `${REACT_APP_API_ROOT_URI}/credit_notes/apply_to_source_invoices`;

  dispatch(setLoadedState('loading'));

  const response = (
    await typedApiFetch<IApiCommandResponse<IPayment>>(
      url,
      {
        method: 'POST',
        body: JSON.stringify({ creditNoteId }),
      },
    )
  );

  const createdPayments = arrayWrap((await response.json()).data);

  dispatch(setLoadedState('loaded'));

  if (createdPayments.length > 0) {
    showMessage({
      message: (
        `${createdPayments.length} payment${createdPayments.length > 1 ? 's' : ''}`
        + ` created for the credits of Credit Note ID ${creditNoteId}`
      ),
      severity: 'success',
    });
  }
};
