import { useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import { ClientBillingTypeHistory } from "./ClientBillingTypeHistory";
import { ClientSingleView } from "./ClientSingleView";
import { ClientsListView } from './ClientsListView';
import { IClientMatch } from '../../../../../rentsync-api/IClientMatch';
import { loadClients } from "../../../redux/features/clients/actions";
import { useAppSelector } from "../../../redux/hooks";

export const ClientsContainer = () => {
  const clients: IClientMatch[] = useAppSelector((state) => state.clients.clientsList);

  useEffect(() => {
    loadClients();
  }, []);

  return (
    <Switch>
      <Route path='/clients/:clientId/billing_type_history'><ClientBillingTypeHistory /></Route>
      <Route path='/clients/:billingAccountId'><ClientSingleView /></Route>
      <Route path='/clients'><ClientsListView clients={ (clients || []) } /></Route>
    </Switch>
  );
};
