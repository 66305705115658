import { TableHead } from "@mui/material";
import { StyledTableRow } from "src/components/parts/mui/StyledTables";

export const StatementTableHeader = ({children} : {children: React.ReactNode}) => {
  return (
    <TableHead>
      <StyledTableRow style={{ cursor: 'pointer' }}>
        {children}
      </StyledTableRow>
    </TableHead>
  );
};