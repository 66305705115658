import { TableContainer, Table as MuiTable, Paper, TableFooter } from "@mui/material";
import React from "react";
import { StyledTableCell, StyledTableRow } from "src/components/parts/mui/StyledTables";
import { ISingleViewStatementData, IStatementLine } from "src/interfaces/ISingleViewStatementData";
import { StatementTableBody } from "./StatementTableBody";
import { StatementTableHeader } from "./StatementTableHeader";

const StatementLineRow = ({statementLine} : {statementLine: IStatementLine}) => {
  return (
    <StyledTableRow
      customSx={{ userSelect: 'none' }}
      dark={false}
      hover={true}
    >
      <StyledTableCell style={{ whiteSpace: 'nowrap' }}>
        {statementLine.date}
      </StyledTableCell>
      <StyledTableCell style={{ whiteSpace: 'nowrap' }}>
        {statementLine.dueDate}
      </StyledTableCell>
      <StyledTableCell style={{ whiteSpace: 'nowrap' }}>
        {statementLine.invoiceNumber}
      </StyledTableCell>
      <StyledTableCell style={{ textAlign: 'left' }}>
        {statementLine.description}
      </StyledTableCell>
      <StyledTableCell style={{ whiteSpace: 'nowrap' }}>
        {statementLine.amountInvoiced}
      </StyledTableCell>
      <StyledTableCell style={{ whiteSpace: 'nowrap' }}>
        {statementLine.amountPaid}
      </StyledTableCell>
      <StyledTableCell style={{ whiteSpace: 'nowrap' }}>
        {statementLine.balance}
      </StyledTableCell>
    </StyledTableRow>
  );
};

const StatementFooterRow = ({statementData} : {statementData: ISingleViewStatementData}) => {
  return (
    <StyledTableRow
      customSx={{ userSelect: 'none' }}
      dark={false}
      hover={true}
    >
      <StyledTableCell style={{ whiteSpace: 'nowrap'}}>
        Balance Due
      </StyledTableCell>
      <StyledTableCell colSpan={5} style={{ whiteSpace: 'nowrap' }}>
        &nbsp;
      </StyledTableCell>
      <StyledTableCell style={{ whiteSpace: 'nowrap' }}>
        {statementData.balanceDue}
      </StyledTableCell>
    </StyledTableRow>
  );
};

// improve performance by only re-rendering if props change
const DefaultStatementLineRow = React.memo(StatementLineRow);
const DefaultStatementFooterRow = React.memo(StatementFooterRow);

export const DefaultStatementTable = ({statementData} : {statementData: ISingleViewStatementData}) => {
  return (
    <TableContainer component={Paper} elevation={12} sx={{mb: 1}}>
      <MuiTable stickyHeader style={{ borderCollapse: 'collapse' }}>
        <StatementTableHeader>
          <StyledTableCell
            style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
          >
            Date
          </StyledTableCell>
          <StyledTableCell
            style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
          >
            Due Date
          </StyledTableCell>
          <StyledTableCell
            style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
          >
            Invoice
          </StyledTableCell>
          <StyledTableCell
            style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
          >
            Description
          </StyledTableCell>
          <StyledTableCell
            style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
          >
            Invoice Amount
          </StyledTableCell>
          <StyledTableCell
            style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
          >
            Amount Paid
          </StyledTableCell>
          <StyledTableCell
            style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
          >
            Balance ({statementData.currencyCode})
          </StyledTableCell>
        </StatementTableHeader>
        <StatementTableBody statementLines={statementData.lines}>
          { 
            statementData.lines.map((line) => (
              <DefaultStatementLineRow
                key={line.lineId}
                statementLine={line}
              />
            ))
          }
        </StatementTableBody>
        <TableFooter>
          <DefaultStatementFooterRow key={statementData.statementId} statementData={statementData} />
        </TableFooter>
      </MuiTable>
    </TableContainer>
  );
};