/**
 * `custom-actions.ts`
 * Add dynamic router "queries" to this file that will be helpful to abstract and maintain in a reusable commonly
 * accessible place.
 *
 * 2023-11-08 - Though this philosophy may change in the future, the intention of this file is to wrap only `getOne`
 *              and `getMany` queries. Action (C_UD) queries, should likely not be abstracted here.
 */

import { Equal } from "@llws/dynamic-router-utils";
import { getMany } from "./actions";

export const getUserNotifications = (token: Record<string, unknown> | null) => (
  getMany('notifications', { where: [ Equal('userId', token?.userId)]})
);
