import { useEffect, useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { Nullable } from "@jamesgmarks/utilities";

import {
  getIsLastMonthLocked,
  loadClients,
  loadMoreClients,
  migrateUnmatchedClients,
  setClientFilter,
} from "../../../redux/features/clients/actions";
import { ClientDropdown } from "../../parts/ClientDropdown";
import { ClientListItemSplitButton } from "./ClientListItemSplitButton";
import { EClientLoadState } from "../../../redux/features/clients/clientsSlice";
import { getMonthStart } from "../../../app-utils";
import { IClientMatch } from '../../../../../rentsync-api/IClientMatch';
import { RequirePermission } from "../../parts/RequirePermission";
import { RerunModal } from "./RerunModal";
import { StyledTableCell, StyledTableRow } from "../../parts/mui/StyledTables";
import { useAppSelector } from "../../../redux/hooks";

interface IClientListDataRowProps {
  client: IClientMatch;
  isLastMonthLocked: boolean;
  setSelectedClient: (
    newState: {
    client: Nullable<IClientMatch>;
    year: Nullable<number>;
    month: Nullable<number>;
  }) => void;
  setShowModal: (newState: boolean) => void;
}

const ClientsListDataRow = (
  {
    client,
    isLastMonthLocked,
    setSelectedClient,
    setShowModal,
  }: IClientListDataRowProps,
) => {
  const lastMonth = getLastMonth();
  const year = lastMonth.getFullYear();
  const month = lastMonth.getMonth() + 1;

  return (
    <StyledTableRow>
      <RequirePermission grant="FULL_ACCESS">
        <StyledTableCell>
          {
            client.hasMatchingHydraClient
              ? <span style={{ color: 'green' }} title="Imported">&#x2713;</span>
              : <span style={{ color: 'red' }} title="Not imported">&#x2715;</span>
          }
        </StyledTableCell>
      </RequirePermission>

      <StyledTableCell>{client.id} / {client.clientKey}</StyledTableCell>
      <StyledTableCell>{client.parentId}</StyledTableCell>
      <StyledTableCell
        title={
          client.hasMatchingHydraClient
            ? `Runtime Group: ${client.clientRuntimeGroupId}`
            : undefined
        }
      >
        {client.freshbooksClientId}
      </StyledTableCell>
      <StyledTableCell>{client.name}</StyledTableCell>
      <StyledTableCell>{client.lwsCompanyLabel}</StyledTableCell>
      <StyledTableCell>
        <ClientListItemSplitButton
          client={client}
          isLastMonthLocked={isLastMonthLocked}
          month={month}
          setSelectedClient={setSelectedClient}
          setShowModal={setShowModal}
          year={year}
        />
      </StyledTableCell>
    </StyledTableRow>
  );
};

export const getLastMonth = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const lastMonth = currentMonth - 1;
  const monthStart = getMonthStart(currentYear, lastMonth);
  return monthStart;
};

interface IClientsListViewProps {
  clients: IClientMatch[];
}

export const ClientsListView = (
  { clients }: IClientsListViewProps,
) => {
  const nextPage = useAppSelector((state) => state.clients.nextPage);
  const searchIdFilter = useAppSelector((state) => state.clients.filters.searchIdFilter);

  const { loadedState } = useAppSelector((state) => state.clients);

  const isLastMonthLocked = useAppSelector((state) => state.clients.isLastMonthLocked);

  const [ searchNameFilter, setSearchNameFilter ] = useState(undefined as string | undefined);

  const [ selectedClient,  setSelectedClient] = useState({
    client: null,
    year: null,
    month: null,
  } as {
    client: Nullable<IClientMatch>,
    year: Nullable<number>,
    month: Nullable<number>
  });

  const [ showModal, setShowModal ] = useState(false);

  useEffect(() => {
    console.log('checking if month is locked');
    getIsLastMonthLocked();
  }, [ isLastMonthLocked ]);

  return (
    <>
      <Grid item container xs={8} m='auto'>

        <Grid item container xs={12}>
          
          <Grid item xs={9}>
            <form onSubmit={(e) => {
              e.preventDefault();

              setClientFilter('searchNameFilter', searchNameFilter);
              loadClients();
            }}
            >
              <Grid container spacing={2}>

                <Grid item xs={9}>
                  <ClientDropdown
                    allowFreeform
                    clientId={searchIdFilter ?? null}
                    onClientChanged={
                      (client) => {
                        if (client) {
                          setClientFilter('searchIdFilter', client.id);
                          setClientFilter('searchNameFilter', null);
                          loadClients();
                        } else {
                          setClientFilter('searchIdFilter', null);
                        }
                      }
                    }
                    onClientFreeForm={
                      (clientString) => {
                        setClientFilter('searchIdFilter', null);
                        clientString !== null && setSearchNameFilter(clientString.split(' (Client ID')[0]);
                      }
                    }
                  />
                </Grid>

                <Grid item xs={3}>
                  <Button type="submit" variant="contained" color="primary" >
                    Search&nbsp;<SearchIcon />
                  </Button>
                </Grid>

              </Grid>
            </form>
          </Grid>

          <Grid item xs={3} textAlign='right'>
            <RequirePermission grant="IMPORT_CLIENTS">
              <Button
                onClick={() => migrateUnmatchedClients()}
                type="button"
              >
                Migrate Unmatched Clients
              </Button>
            </RequirePermission>
          </Grid>
        </Grid>

        {
          loadedState === EClientLoadState.loading
            ? (
              <Box mt={10} width={'100%'} textAlign='center'>
                <CircularProgress />
              </Box>
            )
            : (
              <Paper elevation={5} sx={{ mb: 7, mt: 2 }}>
                <Grid item xs={12}>

                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <StyledTableRow>
                          <RequirePermission grant="FULL_ACCESS">
                            <StyledTableCell>
                              <span style={{ whiteSpace: 'nowrap' }}>
                                <span style={{ color: 'green' }}>&#x2713;</span>/
                                <span style={{ color: 'red' }}>&#x2715;</span>
                              </span>
                            </StyledTableCell>
                          </RequirePermission>
                          <StyledTableCell>ID / key</StyledTableCell>
                          <StyledTableCell>Parent</StyledTableCell>
                          <StyledTableCell>Account ID</StyledTableCell>
                          <StyledTableCell>Name</StyledTableCell>
                          <StyledTableCell>Company Label</StyledTableCell>
                          <StyledTableCell>&nbsp;</StyledTableCell>
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                        {
                          (clients || [])
                            .map((client) => (
                              <ClientsListDataRow
                                client={client}
                                isLastMonthLocked={isLastMonthLocked}
                                key={client.id}
                                setSelectedClient={setSelectedClient}
                                setShowModal={setShowModal}
                              />
                            ))
                        }
                      </TableBody>
                      {
                        nextPage && (
                          <TableFooter
                            onClick={() => loadMoreClients()}
                            sx={{ '&:hover': { cursor: 'pointer' } }}
                          >
                            <StyledTableRow>
                              <StyledTableCell colSpan={7}>
                                Load more...
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableFooter>
                        )
                      }
                    </Table>
                  </TableContainer>

                </Grid>
              </Paper>
            )
        }

      </Grid>

      <RerunModal
        client={selectedClient.client}
        month={selectedClient.month}
        setShowModal={setShowModal}
        showModal={showModal}
        year={selectedClient.year}
      />
    </>
  );
};
