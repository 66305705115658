import { dispatch } from '../../store';
import {
  currentChargeReceived,
  chargesListReceived,
  setLoadedState,
  chargesListForAccountViewReceived,
} from './chargesSlice';
import { store } from '../../store';
import { keys } from '@jamesgmarks/utilities';
import { Charge } from '../../../../../entities/hydra/Charge';
import { typedApiFetch, IApiQuerySingularResponse, IApiQueryListResponse } from '../../utils';
import { REACT_APP_API_ROOT_URI } from '../../../App';
import { TChargeForAccountView } from '../../../interfaces/TChargeForAccountView';

const getFilterQueryStringItems = () => {
  const filters = store.getState().subscriptions.filters || {};
  return (
    filters
      ? `?${keys(filters).map(f => `${f}=${filters[f]}`).join('&')}`
      : ''
  );
};

export const loadChargeById = async (id: number) => {
  const url = `${REACT_APP_API_ROOT_URI}/charges/${id}`;
  dispatch(setLoadedState('loading'));
  const response = await typedApiFetch<IApiQuerySingularResponse<Charge>>(url);
  const responseData = await response.json();
  dispatch(currentChargeReceived(responseData)); // ISSUE:#160: Error handling?
};

export const loadCharges = async (year: number, month: number) => {
  const queryString = getFilterQueryStringItems();
  const url = `${REACT_APP_API_ROOT_URI}/reporting/charges/${year}-${month}${queryString}`;
  dispatch(setLoadedState('loading'));
  const response = await typedApiFetch<IApiQueryListResponse<Charge>>(url);
  const responseData = await response.json();
  dispatch(chargesListReceived(responseData)); // TODO: Error handling?
};

export const loadAccountChargesForDateRange = async (
  accountId: number,
  startDate: string,
  endDate: string,
  page: number = 1,
  pageSize: number = 20,
  sortCriteria: string = 'id',
  sortDirection: `ASC` | `DESC` = `ASC`,
) => {
  const queryString = getFilterQueryStringItems();
  const paginationQueryString = 
    `page=${page}&pageSize=${pageSize}&sortCriteria=${sortCriteria}&sortDirection=${sortDirection}`;
  const combinedQueryString = queryString ? `${queryString}&${paginationQueryString}` : paginationQueryString;
  const url = `${REACT_APP_API_ROOT_URI}/charges/account_id/${accountId}/${startDate}/${endDate}${combinedQueryString}`;
  dispatch(setLoadedState('loading'));
  const response = await typedApiFetch<IApiQueryListResponse<TChargeForAccountView>>(url);
  const responseData = await response.json();
  dispatch(chargesListForAccountViewReceived(responseData)); // TODO: Error handling?
};
