import { floatVal, multiPartition } from '@jamesgmarks/utilities';
import { InvoiceLine } from '../../../entities/hydra';

// TODO: How can we share this code with the back end? This is duplicated.
export const arrangeLinesForInvoice = (
  lines: InvoiceLine[],
  options: { filterZeroDollarCharges: boolean } = { filterZeroDollarCharges: true },
) => {
  const [ creditLines, infoLines, charges ] = multiPartition(lines, [
    (line) => line.lineType === 'credit',
    (line) => line.lineType === 'info',
  ]);

  const filteredCharges = (
    options.filterZeroDollarCharges
      ? charges.filter((line) => floatVal(line.lineTotal) !== 0)
      : charges
  );

  // TODO: const standardLines = rest.filter((line) => line.lineType !== 'deleted');

  const sortByOriginalCreate = (a: InvoiceLine, b: InvoiceLine) => (
    ((a.parentLineId ?? a.id) > (b.parentLineId ?? b.id)) ? 1 : -1
  );

  const sortedLines = [
    ...filteredCharges.sort(
      (a, b) => ((a.item! > b.item! || (a.item === b.item && a.description! > b.description!)) ? 1 : -1),
    ),
    ...creditLines.sort(sortByOriginalCreate),
    ...infoLines.sort(sortByOriginalCreate),
  ];

  return sortedLines;
};