import { arrayWrap, compactObject, HashOf, keys } from '@jamesgmarks/utilities';
import { IApiCommandResponse } from '@llws/api-common';

import { apiFetch, IApiQueryListResponse, typedApiFetch } from '../../utils';
import {
  billingRunListReceived,
  billingRunScheduleCreated,
  billingRunScheduleDeleted,
  billingRunSchedulesReceived,
  billingRunScheduleUpdated,
  EBillingRunLoadedState,
  EBillingRunScheduleLoadedState,
  orchestrationResponseReceived,
  runtimeGroupsListReceived,
  setBillingRunScheduleLoadState,
  setLoadState,
} from './billingRunSlice';
import { BillingRunSchedule } from '../../../../../entities/hydra';
import { dispatch } from '../../store';
import { TMonthNumber } from '../../../interfaces/IYearMonth';
import { migrateUnmatchedClients } from '../clients/actions';
import { REACT_APP_API_ROOT_URI } from '../../../App';
import { showMessage } from '../messaging/actions';
import { IBillingRun } from '../../../entity-interfaces/IBillingRun';

/**
 * Loads Billing Run results into the Redux store, optionally filtering the results to a given year-month range.
 * @param dateRangeParams Parameters for filtering the returned Billing Run results
 */
export const refreshBillingRuns = async (
  dateRangeParams?: {
    startYear: number,
    startMonth: TMonthNumber,
    endYear: number,
    endMonth: TMonthNumber,
  },
): Promise<void> => {
  dispatch(setLoadState(EBillingRunLoadedState.loading));

  const urlParams = dateRangeParams
    ? `?start_year=${dateRangeParams.startYear}`
      + `&start_month=${dateRangeParams.startMonth}`
      + `&end_year=${dateRangeParams.endYear}`
      + `&end_month=${dateRangeParams.endMonth}`
    : '';

  const response = await typedApiFetch<IApiQueryListResponse<IBillingRun>>(
    `${REACT_APP_API_ROOT_URI}/instances/runs${urlParams}`,
  );

  dispatch(billingRunListReceived(await response.json())); // TODO: Error handling?

  dispatch(setLoadState(EBillingRunLoadedState.loaded));
};

export const rerunBillingRun = async (runId: number, bodyOptions?: HashOf<unknown>) => {
  const response = await apiFetch(`${REACT_APP_API_ROOT_URI}/instances/rerun`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ runId, ...bodyOptions }),
  });
};

export const loadBillingRunSchedules = async() => {
  dispatch(setBillingRunScheduleLoadState(EBillingRunScheduleLoadedState.loading));

  const url = `${REACT_APP_API_ROOT_URI}/billing_runs/schedules`;
  const response = await typedApiFetch<IApiQueryListResponse<BillingRunSchedule>>(url);
  const { data } = await response.json();

  dispatch(billingRunSchedulesReceived(data));
  dispatch(setBillingRunScheduleLoadState(EBillingRunScheduleLoadedState.loaded));
};

export const createBillingRunSchedule = async(
  year: number,
  month: TMonthNumber,
  billingDate: Date,
): Promise<void> => {
  const url = `${REACT_APP_API_ROOT_URI}/billing_runs/schedules`;

  const body = JSON.stringify(
    {
      year, month, billingDate,
    },
  );

  const response = await typedApiFetch<IApiCommandResponse<BillingRunSchedule>>(
    url,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    },
  );

  if (response.ok) {
    const { data } = await response.json();

    const [ createdBillingRunSchedule ] = arrayWrap(data);

    dispatch(billingRunScheduleCreated(createdBillingRunSchedule));
  }
};

export const deleteBillingRunSchedule = async (id: number): Promise<void> => {
  const url = `${REACT_APP_API_ROOT_URI}/billing_runs/schedules/${id}`;

  const response = await typedApiFetch<IApiCommandResponse<BillingRunSchedule>>(
    url,
    {
      method: 'DELETE',
    },
  );

  const { data } = await response.json();

  const [ deletedBillingRunSchedule ] = arrayWrap(data);

  dispatch(billingRunScheduleDeleted(deletedBillingRunSchedule.id));
};

export const updateBillingRunSchedule = async (
  id: number,
  updateFields: Partial<{ year: number, month: TMonthNumber, billingDate: Date }>,
): Promise<void> => {

  const finalFields = compactObject(updateFields);

  if (keys(finalFields).length === 0) {
    console.error('Nothing to update!');
    return;
  }

  const url = `${REACT_APP_API_ROOT_URI}/billing_runs/schedules`;

  const body = JSON.stringify({
    id,
    ...finalFields,
  });

  const response = await typedApiFetch<IApiCommandResponse<BillingRunSchedule>>(
    url,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body,
    },
  );

  const { data } = await response.json();

  const [ updatedBillingRunSchedule ] = arrayWrap(data);

  dispatch(billingRunScheduleUpdated(updatedBillingRunSchedule));
};

export const loadRuntimeGroups = async () => {
  const response = await apiFetch(`${REACT_APP_API_ROOT_URI}/instances/runtime_groups`);
  dispatch(runtimeGroupsListReceived(await response.json()));
};

export const beginOrchestration = async (
  year: number,
  month: number,
  groupIds: number[],
  preloadMissingClients: boolean = false,
  runImmediately: boolean = true,
) => {
  if (preloadMissingClients) { await migrateUnmatchedClients(); }
  const url = (
    `${REACT_APP_API_ROOT_URI}/instances/begin_orchestration/${year}-${month}/`
    + `?groupIds=${groupIds.map(gid => `${gid}`).join(',')}`
    + (!runImmediately ? `&runImmediately=false` : '')
  );
  const response = await apiFetch(url);
  dispatch(orchestrationResponseReceived(await response.json()));
};
