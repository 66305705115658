// eslint-disable-next-line no-shadow
export enum EIgnoredDataIntegrityErrorType {
    precheck = 'precheck',
    postcheck = 'postcheck',
  }
  
// eslint-disable-next-line no-shadow
export enum EIgnoredDataIntegrityErrorState {
    active = 'active',
    deleted = 'deleted',
    fixed = 'fixed',
  }
