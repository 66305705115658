import { createTheme } from "@mui/material";
import { orange } from "@mui/material/colors";

// Updates to custom theme typings are made in `./material-ui.d.ts`
export const theme = createTheme({
  palette: { 
    myCustomColor: 'blue', // Example - to be replaced with real styles
  },

  status: { // Example - to be replaced with real styles
    danger: orange[500],
  },
});