import { Grid } from "@mui/material";
import { AccountAddress } from "./AccountAddress";
import { CompanyAddress } from "./CompanyAddress";
import rentsyncLogo from "src/assets/images/rentsync-logo.png";

export const DefaultStatementHeader = (
  {address, statementType} : {address: string, statementType: string},
) => {
  return (
    <Grid container spacing={2} mr={2}>
      <Grid item xs={6}>
        <AccountAddress address={address}>
          <img width="184" height="129" src={rentsyncLogo} alt="rentsync logo" />
        </AccountAddress>
      </Grid>
      <Grid item xs={6}>
        <CompanyAddress statementType={statementType}/>
      </Grid>
    </Grid>
  );
};