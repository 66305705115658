import { arrayWrap } from "@jamesgmarks/utilities";
import { IInvoice } from "../entity-interfaces/IInvoice";
import { Invoice } from "../../../entities/hydra";

export const getInvoiceState = (invoice: IInvoice | Invoice) => (
  invoice?.freshbooksState
    ? invoice?.freshbooksState
    : (
      arrayWrap(invoice?.hydraState)
        .map((hs) => hs === 'unsent' ? 'unpublished' : hs)
    )
);
