// import { IApiCommandResponse, IApiErrorResponse, IApiQueryResponse, IApiResponse } from '@llws/api-common';
// import { performApiActionRequest, TActionResponse } from '../../apiActionRequest';
// import { store, dispatch } from '../../store';
import { apiFetch, ELoadState, IApiQueryListResponse, IApiQuerySingularResponse, typedApiFetch } from '../../utils';
import {
  baseSubscriptionsListReceived,
  setLoadState,
  servicesListReceived,
  baseSubscriptionUpdateReceived,
  serviceUpdateReceived,
} from './productsSlice';
import { REACT_APP_API_ROOT_URI } from '../../../App';
import { dispatch } from '../../store';
import { IServices, IBaseSubscriptions } from '@llws/lift-entity-interfaces';
import { IApiCommandResponse } from '@llws/api-common';
import { showMessage } from '../messaging/actions';

export const loadServices = async () => {
  setLoadState(ELoadState.pending);
  const servicesResponse = await apiFetch(`${REACT_APP_API_ROOT_URI}/products/services`);
  const servicesApiResponse = await servicesResponse.json() as IApiQueryListResponse<IServices>;
  const services = servicesApiResponse.data;
  dispatch(servicesListReceived(services));
  return services;
};
export const saveService = async (service: IServices) => {
  const suffix = service.id ? `/${service.id}` : '';
  const url = `${REACT_APP_API_ROOT_URI}/products/services${suffix}`;
  const method = service.id ? 'PUT' : 'POST';

  const servicesResponse = await apiFetch(url, { method, body: JSON.stringify(service) });
  const servicesApiResponse = (
    await servicesResponse.json() as IApiQuerySingularResponse<IServices>
  );
  if (servicesApiResponse.data) {
    const savedService = servicesApiResponse.data;
    dispatch(serviceUpdateReceived(savedService));
    return savedService;
  }
  return null;
};

export const loadBaseSubscriptions = async () => {
  setLoadState(ELoadState.pending);
  const baseSubscriptionsResponse = await apiFetch(`${REACT_APP_API_ROOT_URI}/products/base_subscriptions`);
  const baseSubscriptionsApiResponse = (
    await baseSubscriptionsResponse.json() as IApiQueryListResponse<IBaseSubscriptions>
  );
  const baseSubscriptions = baseSubscriptionsApiResponse.data;
  dispatch(baseSubscriptionsListReceived(baseSubscriptions));
  return baseSubscriptions;
};

export const saveBaseSubscription = async (baseSubscription: IBaseSubscriptions) => {
  showMessage({ message: `Saving ${baseSubscription.id ? '' : 'new '}Subscription Type Details`, severity: 'info' });

  const suffix = baseSubscription.id ? `/${baseSubscription.id}` : '';
  const url = `${REACT_APP_API_ROOT_URI}/products/base_subscriptions${suffix}`;
  const method = baseSubscription.id ? 'PUT' : 'POST';
  const baseSubscriptionsResponse = await typedApiFetch<IApiCommandResponse<IBaseSubscriptions>>(
    url,
    {
      method,
      body: JSON.stringify(baseSubscription),
    },
  );
  const baseSubscriptionsApiResponse = await baseSubscriptionsResponse.json();
  if (
    [200, 201].includes(baseSubscriptionsResponse.status)
    && baseSubscriptionsApiResponse.data
  ) {
    showMessage({ message: `Successfully saved subscription`, severity: 'success' });
    const savedBaseSubscription = baseSubscriptionsApiResponse.data;
    dispatch(baseSubscriptionUpdateReceived(savedBaseSubscription as IBaseSubscriptions));
    return savedBaseSubscription;
  }
  return null;
};
