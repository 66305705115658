import { useEffect } from "react";
import { getXeroConsentUrl } from "../../../redux/features/xero/actions";
import { useAppSelector } from "../../../redux/hooks";

export const XeroAuthorization = () => {
  const systemInfo = useAppSelector(state => state.systemInfo);
  const consentUrl = useAppSelector(state => state.xero.consentUrl);
  
  useEffect(()=>{
    if(consentUrl !== '' && consentUrl !== null)
      window.location.replace(consentUrl);  
  }, [consentUrl]);  

  return (
    <>
      <header className="Section-header">Xero Authorization</header>
      Environment: {systemInfo.env.NODE_ENV}<br />
      <button>
        <a
          id='auth-link'
          target='_blank'
          rel='noreferrer'
          style={{ color: 'black', textDecoration: 'none' }}
          onClick={() => {
            getXeroConsentUrl();
          }}
        >Authorize</a>
      </button>
    </>
  );
};
