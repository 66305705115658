import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { IBaseSubscriptions } from "@llws/lift-entity-interfaces";
import { BaseSubscriptionListTableRow } from "./BaseSubscriptionListTableRow";

export const BaseSubscriptionsListTable = ({
  rows, onEditButtonClicked,
}: {
  rows: IBaseSubscriptions[];
  onEditButtonClicked: (baseSubscription: IBaseSubscriptions) => void;
}) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell><strong>Service/Partner</strong></TableCell>
            <TableCell><strong>Description</strong></TableCell>
            <TableCell><strong>Frequency</strong></TableCell>
            <TableCell><strong>Status</strong></TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <BaseSubscriptionListTableRow
              key={row.id}
              baseSubscription={row}
              onEdit={() => { onEditButtonClicked(row); }}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
