import { Box } from "@mui/material";

interface ITabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

export const TabPanel = (props: ITabPanelProps) => {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index &&
          <Box p={3}>
            {children}
          </Box>
      }
    </div>
  );
};
