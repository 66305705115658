import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography, 
} from "@mui/material";

import { Nullable, strVal } from "@jamesgmarks/utilities";

import {
  loadBillingRunSchedules,
} from "../../../redux/features/billing-runs/actions";
import { EBillingRunScheduleLoadedState } from "../../../redux/features/billing-runs/billingRunSlice";
import { StyledTableCell, StyledTableRow } from "../../parts/mui/StyledTables";
import { useAppSelector } from "../../../redux/hooks";
import { ExistingBillingRunScheduleRow } from "./ExistingBillingRunScheduleRow";
import { BillingRunScheduleForm } from "./BillingRunScheduleForm";

export interface IFormData {
  id: Nullable<number>;
  year: Nullable<string>;
  month: Nullable<string>;
  billingDate: Nullable<Date>;
}

export const emptyFormData = { id: null, year: null, month: null, billingDate: null };

export const BillingRunSchedules = () => {
  const billingRunSchedules = useAppSelector((state) => state.billingRuns.billingRunSchedules);
  const loadState = useAppSelector((state) => state.billingRuns.billingRunSchedulesLoadState);

  const [ currentlyEditedId, setCurrentlyEditedId ] = useState<Nullable<number>>(null);
  const [ editing, setEditing ] = useState(false);
  const [ formData, setFormData ] = useState<IFormData>(emptyFormData);

  const [ showForm, setShowForm ] = useState(true);

  useEffect(() => {
    loadBillingRunSchedules();
  }, []);

  return (
    <Grid mt={6} container alignItems='center'>
      <Grid item mx='auto' my={2} xs={8}>
        <Link to='/billing_runs'>
          <Button variant='contained' color='info'>&larr; Billing Runs</Button>
        </Link>
      </Grid>
      <Grid item mx='auto' textAlign='right' xs={8}>
        <Button
          onClick={() => setShowForm((currentState) => !currentState)}
        >
          {`${showForm ? 'Hide' : 'Show'} form`}
        </Button>
      </Grid>
      {
        showForm
        && (
          <Grid item mb={8} mx='auto' xs={8}>
            <BillingRunScheduleForm
              editing={editing}
              setEditing={setEditing}
              formData={formData}
              setFormData={setFormData}
              setCurrentlyEditedId={setCurrentlyEditedId}
            />
          </Grid>
        )
      }

      <Grid item mx='auto' mb={8} xs={8}>
        {
          loadState === EBillingRunScheduleLoadedState.loading
            ? (
              <Box sx={{ width: '100%', textAlign: 'center' }}>
                <CircularProgress color="secondary" />
              </Box>
            )
            : (
              <>
                <Typography variant='h5' textAlign='center' my={2}>Existing Billing Run Schedules</Typography>

                <TableContainer sx={{ p: 0.5 }} component={Paper}>
                  <Table>
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell>Month</StyledTableCell>
                        <StyledTableCell>Billing Date</StyledTableCell>
                        <StyledTableCell>Edit</StyledTableCell>
                        <StyledTableCell>Delete</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>

                    <TableBody>
                      {
                        (billingRunSchedules ?? [])
                          .slice()
                          .sort((a, b) => (
                            `${a.year}${strVal(a.month).padStart(2, '0')}`
                              > `${b.year}${strVal(b.month).padStart(2, '0')}`
                              ? 1
                              : -1
                          ))
                          .map((billingRunSchedule, i) => (
                            <ExistingBillingRunScheduleRow
                              billingRunSchedule={billingRunSchedule}
                              currentlyEditedId={currentlyEditedId}
                              key={i}
                              setCurrentlyEditedId={setCurrentlyEditedId}
                              setEditing={setEditing}
                              setFormData={setFormData}                          
                            />
                          ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )
        }
      </Grid>
    </Grid>
  );
};
