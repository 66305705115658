import { createSlice } from '@reduxjs/toolkit';
import { IPartners } from '@llws/lift-entity-interfaces';
import { IApiQueryResponse } from '@llws/api-common';
import { arrayWrap, Hash, keys, Nullable, pickNot } from '@jamesgmarks/utilities';

export interface IPartnersState {
  loadedState: string,
  partnersList: IPartners[],
  nextPage: Nullable<string>,
  filters: Hash,
};

export const partnersSlice = createSlice({
  name: 'partners',
  initialState: { loadedState: 'idle', partnersList: [], nextPage: null, filters: {} } as IPartnersState,
  reducers: {
    partnersListReceived: (state, action: { type: string, payload: IApiQueryResponse<IPartners> }) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.partnersList = arrayWrap(action.payload.data);
      state.nextPage = action.payload.meta.links.next;
      state.loadedState = 'loaded';
    },
    morePartnersReceived: (state, action: { type: string, payload: IApiQueryResponse<IPartners> }) => {
      state.partnersList = state.partnersList.concat(arrayWrap(action.payload.data));
      state.nextPage = action.payload.meta.links.next;
      state.loadedState = 'loaded';
    },
    setLoadedState: (state, action: { type: string, payload: string }) => {
      state.loadedState = action.payload;
    },
    setPartnersFilters: (state, action: { type: string, payload: Hash }) => {
      console.log({ keys: keys(action.payload), payload: action.payload[keys(action.payload)[0]] });
      const deleteKeys = keys(action.payload).filter(k => !action.payload[k]);
      console.log({ deleteKeys });
      const filteredFilters = pickNot(state.filters, deleteKeys);
      console.log({ filteredFilters });
      state.filters = {
        ...filteredFilters,
        ...pickNot(action.payload, deleteKeys),
      };
      console.log({ newFilters: state.filters });
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  partnersListReceived,
  morePartnersReceived,
  setLoadedState,
  setPartnersFilters,
} = partnersSlice.actions;

export default partnersSlice.reducer;