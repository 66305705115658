import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, 
} from "@mui/material";
  
  interface IConfirmationModalProps {
      open: boolean;
      setOpen: (open: boolean) => void;
      dialogTitle?: string;
      promptText: string;
      onConfirm: () => void;
      onCancel?: () => void;
      onClose?: () => void;
      overrideCancelText?: string;
      overrideConfirmText?: string;
  }
  
export const ConfirmationModal = ({
  open,
  setOpen,
  dialogTitle,
  promptText,
  onConfirm,
  onCancel,
  onClose,
  overrideCancelText,
  overrideConfirmText,
}: IConfirmationModalProps) => (
  <Dialog
    open={open}
    onClose={() => { onClose?.(); setOpen(false); }}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    {
      dialogTitle
        && (
          <DialogTitle id="alert-dialog-title">
            {dialogTitle}
          </DialogTitle>
        )
    }
  
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {promptText}
      </DialogContentText>
    </DialogContent>
      
    <DialogActions>
      <Button color='error' onClick={() => { onCancel?.(); setOpen(false); }}>
        {overrideCancelText ?? 'Cancel'}
      </Button>
  
      <Button onClick={() => { onConfirm(); setOpen(false); }} autoFocus>
        {overrideConfirmText ?? 'Confirm'}
      </Button>
    </DialogActions>
  </Dialog>
);
  