
import { useEffect, useMemo, useState } from "react";

import Button from "react-bootstrap/esm/Button";
import Col from "react-bootstrap/esm/Col";
import Form from "react-bootstrap/esm/Form";
import Row from "react-bootstrap/esm/Row";

import { addDays, getNextPayDate, makeYmd } from "../../../app-utils";
import { CommissionsTable } from "./CommissionsTable";
import { loadCommissions } from "../../../redux/features/commissions/actions";
import { useAppSelector } from "../../../redux/hooks";

export const CommissionsPaid = ({ salesRepId }: { salesRepId: number }) => {
  const commissionsList = useAppSelector(state => state.commissions.commissionsList);
  const commissionSource = useAppSelector(state => state.commissions.commissionSource);

  const [dateValue, setDateValue] = useState(makeYmd(getNextPayDate()));
  const [numPayDates, setNumPayDates] = useState(40);

  const legacyInvoiceStates = ['Paid'];
  const hydraInvoiceStates = ['paid'];
  const invoiceStates = JSON.stringify({
    legacyInvoiceStates,
    hydraInvoiceStates,
  });

  const nextPayDate = getNextPayDate();
  const pastPayDates = useMemo(() => {
    return Array.from({ length: numPayDates }).map(
      (payDate, i) => makeYmd(
        getNextPayDate(addDays(nextPayDate, i * -14)),
      ),
    );
  },
  [numPayDates, nextPayDate]);

  const loadButtonHandler = () => {
    loadCommissions(
      salesRepId,
      `/paid?invoiceStates=${invoiceStates}&paid=true&unpaid=false&date=${dateValue}&source=${commissionSource}`,
    );
  };

  useEffect(() => {
    loadCommissions(
      salesRepId,
      `/paid?invoiceStates=${invoiceStates}&paid=true&unpaid=false&date=${dateValue}&source=${commissionSource}`,
    );
  }, [salesRepId, commissionSource]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>

      <Form.Group>
        <Row>
          <Col xs={4} >
            {/* TODO: NEED TO VALIDATE THIS */}
            <Form.Label>Date</Form.Label>
            <Form.Control value={dateValue}  onChange={
              (e) => (
                e.target.value ==='showMore'
                  ? setNumPayDates(numPayDates + 15)
                  : setDateValue(e.target.value)
              )} as="select">
              {pastPayDates.map((payDate) => <option value={payDate} key={payDate}>{payDate}</option>)}
              <option value={'showMore'} key={'showMore'}>Show More</option>
            </Form.Control>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <Button onClick={() => loadButtonHandler()}>Load</Button>
          </Col>
        </Row>

        <Row>
          <Col>
            <br></br>
            {/* <p>Shown Invoice States</p>
            {invoiceStates.map((invoiceState) => <Badge pill variant="primary">{invoiceState}</Badge>)} */}
          </Col>
        </Row>

      </Form.Group>
      <CommissionsTable commissionsList={commissionsList} />
    </>
  );
};
