import {
  Table,
  // Button,
  Container,
} from 'react-bootstrap';
import { Link } from "react-router-dom";
// import { loadMoreCharges } from "../../../redux/features/charges/actions";
// import { useAppSelector } from "../../../redux/hooks";
import { Charge } from '../../../../../entities/hydra';

const ChargesListDataRow = ({
  charge,
}: {
  charge: Charge
}) => {
  return (
    <tr>
      <td>{charge.id}</td>
      <td>{charge.hydraState}</td>
      <td>{charge.subscriptionId}</td>
      <td>{charge.chargeHasTaxes.map(cht => cht.invoice.invoiceNumber).join(' | ')}</td>
      <td>{charge.year}</td>
      <td>{charge.month}</td>
      <td>{charge.subtotal}</td>
      <td>
        <Link to={`/charges/${charge.id}`}>View</Link> &nbsp;
        {/* | <span><Link to={`/invoices?oldFreshbooksClientId=${subscription.freshbooksClientId}`}>Invoices</Link></span> */}
      </td>
    </tr>
  );
};

export const ChargesListView = ({ charges } : {
  charges: Charge[]
}) => {
  // const nextPage = useAppSelector(state => state.subscriptions.nextPage);

  // const [searchIdFilter, setSearchIdFilter] = useState(undefined as number | undefined);
  // const [searchNameFilter, setSearchNameFilter] = useState(undefined as string | undefined);

  return (<>
    <Container>
      <Table striped bordered hover variant="dark">
        <thead>
          <tr>
            <th>ID</th>
            <th>Status</th>
            <th>SubID</th>
            <th>Invoice(s)</th>
            <th>Year</th>
            <th>Month</th>
            <th>Subtotal</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {
            (charges ?? []).map((charge) => <ChargesListDataRow key={charge.id} charge={charge} />)
          }
        </tbody>
        {/* {nextPage && <tfoot>
          <tr>
            <th colSpan={7}><Button type="button" onClick={() => loadMoreCharges()}>More...</Button></th>
          </tr>
        </tfoot>} */}
      </Table>
    </Container>
  </>);
};