import { Dispatch, FormEvent, SetStateAction } from "react";
import {
  Button, Card, Grid, TextField, TextFieldProps, Typography, 
} from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { intVal, Nullable, strVal } from "@jamesgmarks/utilities";

import { assertMonthNumber } from "../../../interfaces/IYearMonth";
import { createBillingRunSchedule, updateBillingRunSchedule } from "../../../redux/features/billing-runs/actions";
import { emptyFormData, IFormData } from "./BillingRunSchedules";

interface IBillingRunScheduleFormProps {
  editing: boolean;
  setEditing: Dispatch<SetStateAction<boolean>>;
  formData: IFormData;
  setFormData: Dispatch<SetStateAction<IFormData>>;
  setCurrentlyEditedId: Dispatch<SetStateAction<Nullable<number>>>;
}

export const BillingRunScheduleForm = ({
  editing,
  setEditing,
  formData,
  setFormData,
  setCurrentlyEditedId,
}: IBillingRunScheduleFormProps) => {
  const handleSubmit = (e: FormEvent, formData: IFormData) => {
    e.preventDefault();

    editing
      ?
      updateBillingRunSchedule(
        formData.id!,
        {
          year: intVal(formData.year),
          month: assertMonthNumber(intVal(formData.month)),
          billingDate: formData.billingDate!,
        },
      )
      :
      createBillingRunSchedule(
        intVal(formData.year),
        assertMonthNumber(intVal(formData.month)),
        formData.billingDate!,
      );

    setEditing(false);
    setCurrentlyEditedId(null);
    setFormData(emptyFormData);
  };

  return (
    <>
      <Typography variant='h5' textAlign='center' my={2}>
        {`${editing ? 'Edit' : 'Create'} Billing Run Schedule`}
      </Typography>

      <form onSubmit={(e) => handleSubmit(e, formData)}>
        <Card
          elevation={4}
          sx={{ p: 3, backgroundColor: '#f5f5f5', border: editing ? '3px solid #50c878' : undefined }}
        >
          <Grid item container xs={12} justifyContent='space-evenly' textAlign='center'>
            <Grid item xs>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat='yyyy-MM'
                  label='Month'
                  mask='____-__'
                  onChange={(d: Date | null) => {
                    if (d) {
                      setFormData((old) => ({
                        ...old,
                        year: strVal(d.getFullYear()),
                        month: strVal(d.getMonth() + 1),
                      }));
                    }
                  }}
                  renderInput={(props: TextFieldProps) => <TextField sx={{ background: '#fff' }} {...props} />}
                  showToolbar={false}
                  value={
                    (formData.year && formData.month)
                      ? new Date(intVal(formData.year), intVal(formData.month) - 1)
                      : null
                  }
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat='yyyy-MM-dd'
                  label='Billing Run Date'
                  mask='____-__-__'
                  onChange={(d: Date | null) => {
                    const newDate = new Date(d ? d.setHours(0, 0, 0, 0) : '');
                    setFormData((old) => ({ ...old, billingDate: newDate }));
                  }}
                  renderInput={(props: TextFieldProps) => <TextField sx={{ background: '#fff' }} {...props} />}
                  showToolbar={false}
                  value={formData.billingDate}
                />
              </LocalizationProvider>
            </Grid>

            <Button
              color='secondary'
              disabled={!formData.year || !formData.month || !formData.billingDate}
              type='submit'
              variant='contained'
            >
              {editing ? 'Update' : 'Create'}
            </Button>
          </Grid>
        </Card>
      </form>
    </>
  );
};
