import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  Box, 
  FormControl, 
  Grid, 
  InputLabel, 
  MenuItem, 
  Select, 
  SelectChangeEvent,
  Tab, 
  Tabs,
  Typography,
} from "@mui/material";

import { Hash, intVal } from "@jamesgmarks/utilities";

import { BillingTypeHistory } from "./BillingTypeHistory";
import { loadClientBillingTypeData } from "../../../redux/features/clients/actions";
import { useAppSelector } from "../../../redux/hooks";
import { IBuildingBillingTypes } from "@llws/lift-entity-interfaces";
import { assertIdIsNotNullOrUndefined } from "src/interfaces/interfaceAssertions";

export const removeProp = <T extends (Record<K, unknown>), K extends (string | number)>(object: T, fieldName: K) => {
  return Object.keys(object).reduce((acc, key) => {
    return {
      ...acc,
      ...(key !== `${fieldName}` ? {
        [key]: object[key as K],
      } : {}),
    };
  }, {} as Partial<T>);
};

const TabPanel = (props : {
  children?: React.ReactElement | React.ReactElement[],
  value: number,
  index: number,
} & Hash) => {
  const {
    children,
    value,
    index,
    ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.any.isRequired,
//   value: PropTypes.any.isRequired,
// };

export const BillingTypeDropdown = ({
  id,
  label,
  defaultBillingId,
  clientDefaultBillingId,
  selectedValue,
  items,
  handleChange,
}: {
  id: string,
  label: string,
  defaultBillingId?: string,
  clientDefaultBillingId?: string,
  selectedValue: string,
  items: IBuildingBillingTypes[],
  handleChange: ((event: SelectChangeEvent, child: React.ReactNode) => void),
}) => {
  return (
    <FormControl variant="outlined" size="small" fullWidth>
      <InputLabel
        id={`label-${id}`}
        // disabled={selectedValue === ''}
        shrink={true}
      >{label}</InputLabel>
      <Select
        labelId={`label-${id}`}
        id={id}
        value={selectedValue}
        displayEmpty
        onChange={handleChange}
        label={label}
      >
        <MenuItem value="">
          {
            clientDefaultBillingId
              ? <em>Use Client Default ({items.find(bbt => `${bbt.id}` === clientDefaultBillingId)?.typeName})</em>
              : (
                defaultBillingId
                  ? <em>Use Global Default ({items.find(bbt => `${bbt.id}` === defaultBillingId)?.typeName})</em>
                  : <em>None</em>
              )
          }
        </MenuItem>
        {items.map((bbt) => {
          assertIdIsNotNullOrUndefined<IBuildingBillingTypes>(bbt);
          return (
            <MenuItem key={bbt.id} value={bbt.id}>
              <strong>{bbt.typeName}</strong>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export const ClientBillingTypeHistory = () => {
  const { clientId } = useParams<{ clientId?: string }>();

  const clientsState = useAppSelector(state => state.clients);
  const {
    currentClient,
    currentClientProperties,
    currentClientBillingTypes,
    currentClientBuildingTypeHistory,
  } = clientsState;

  const [ selectedTypesMap, setSelectedTypesMap ] = useState({} as Record<number, number>);
  const [ clientDefault, setClientDefault ] = useState(currentClient?.defaultBillingTypeId ?? null);
  const [ tabIndex, setTabIndex ] = useState(0);

  useEffect(() => {
    if (clientId) loadClientBillingTypeData(intVal(clientId));
  }, [ clientId ]);

  useEffect(() => {
    setClientDefault(currentClient?.defaultBillingTypeId ?? null);
  }, [ currentClient?.defaultBillingTypeId, setClientDefault ]); // eslint-disable-line react-hooks/exhaustive-deps

  // const getGlobalDefault = (buildingTypeId: number) => {
  //   const buildingTypeDefaultId = (
  //     currentClientBillingTypes?.buildingTypes?.find(bt => bt.id === buildingTypeId)?.defaultBillingTypeId
  //   );
  //   return buildingTypeDefaultId;
  // }

  // const getDefaultForType = (buildingTypeId: number) => {
  //   const clientBuildingDefaultId = (
  //     currentClientBillingTypes?.clientBuildingTypeDefaults?.[buildingTypeId]?.billingTypeId
  //   );
  //   const clientDefaultId = currentClient?.defaultBillingTypeId;
  //   const buildingTypeDefaultId = getGlobalDefault(buildingTypeId);
  //   const returnTypeId = (
  //     clientBuildingDefaultId
  //     ?? clientDefaultId
  //     ?? buildingTypeDefaultId
  //     ?? null // Theoretically should never happen since all building type records are populated with default billing types.
  //   );
  //   return returnTypeId;
  // }

  const setOverrideType = (buildingTypeId: number, billingTypeId: number ) => {
    const _billingTypeId = (
      Number.isNaN(billingTypeId)
        ? -1
        : billingTypeId
    );
    // const defaultType = getDefaultForType(buildingTypeId);
    // const isDefault = ( defaultType === billingTypeId );
    const newTypesMap = {
      ...removeProp(selectedTypesMap, buildingTypeId),
      ...(_billingTypeId !== -1 ? { [buildingTypeId]: _billingTypeId } : {}),
    } as Record<number, number>;
    console.log({
      buildingTypeId,
      billingTypeId,
      _billingTypeId,
      new: newTypesMap[buildingTypeId],
      selectedTypesMap,
      picked: removeProp(selectedTypesMap, buildingTypeId),
      newTypesMap,
    });
    setSelectedTypesMap(newTypesMap);
  };

  const billingTypes = (currentClientBillingTypes?.billingTypes ?? []);

  return <>
    <Grid container direction="column" alignItems="center" justifyContent='center'>
      <Grid item xs={10}>Billing Types: {
        !!currentClient
          ? `${currentClient.name} (${currentClient.lwsCompanyLabel})`
          : 'No Client selected'
      }</Grid>
      <Grid item xs={10}>
        <Grid container spacing={5}>
          {!!currentClient
            && <>
              <Grid item xs={12}>
                <Tabs
                  value={tabIndex}
                  onChange={(event, newValue) => { setTabIndex(newValue); }}
                  indicatorColor="primary"
                  textColor="primary"
                  // centered
                  // variant="fullWidth"
                >
                  <Tab label="Defaults" />
                  <Tab label="Billing Types" />
                </Tabs>
              </Grid>
              <Grid item xs={12}>
                <TabPanel value={tabIndex} index={0}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <BillingTypeDropdown
                          label={`Client Default`}
                          id={`client-billing-type`}
                          selectedValue={`${clientDefault ?? ''}` ?? ''}
                          items={billingTypes}
                          handleChange={(e) => {
                            if (e.target?.value) {
                              setClientDefault(intVal(e.target.value));
                            } else {
                              setClientDefault(null);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          {(currentClientBillingTypes?.buildingTypes ?? [])
                            .slice()
                            .sort((a, b) => (a.name < b.name ? -1 : 1))
                            .map(buildingType => {
                              const buildingTypeId = buildingType.id!;
                              const clientBuildingDefault = (
                                (currentClientBillingTypes?.clientBuildingTypeDefaults ?? [])
                                  .find(cbt => cbt.buildingTypeId === buildingTypeId)
                              );
                              const clientBuildingDefaultId = clientBuildingDefault?.billingTypeId;
                              const selectedValue = (
                                `${selectedTypesMap[buildingTypeId] ?? clientBuildingDefaultId ?? ''}`
                              );

                              return <React.Fragment key={buildingTypeId}>
                                <Grid item xs={12} sm={12} md={4} lg={3}>
                                  <BillingTypeDropdown
                                    label={`${buildingType.name}`}
                                    id={`building-type-${buildingType.id}-billing-type`}
                                    key={`building-type-${buildingType.id}-billing-type`}
                                    selectedValue={selectedValue ?? ''}
                                    defaultBillingId={`${buildingType.defaultBillingTypeId}`}
                                    clientDefaultBillingId={`${clientDefault ?? ''}`}
                                    items={billingTypes}
                                    handleChange={(e) => {
                                      setOverrideType(buildingTypeId, intVal(e.target.value));
                                    }}
                                  />
                                </Grid>
                              </React.Fragment>;
                            })}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={12}>Properties: {(currentClientProperties ?? []).length}</Grid>
                <Grid item xs={12}>history: {(currentClientBuildingTypeHistory ?? []).length}</Grid> */}
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <BillingTypeHistory
                          properties={currentClientProperties ?? []}
                          history={currentClientBuildingTypeHistory ?? []}
                          billingTypes={billingTypes}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </TabPanel>
              </Grid>
            </>
          }
        </Grid>
      </Grid>
    </Grid>
  </>;
};