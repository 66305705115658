import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Modal, Container, Button,
} from 'react-bootstrap';
import { first, HashOf, hasKey, strVal, values } from '@jamesgmarks/utilities';

import { IInvoice, isIInvoice } from '../../../entity-interfaces/IInvoice';
import {
  assertValidInvoiceNotificationArray,
  sendMultipleInvoiceNotifications,
} from '../../../redux/features/invoices/actions';
import { getContactGroups } from './getContactGroups';
import { getDefaultContactsForGroup, DocumentGroupSection } from './DocumentGroupSection';
import { EDocument } from '../../../redux/features/documents/interfaces';
import { camelCaseToCapitalized } from '../../../app-utils/helpers';
import {
  assertValidCreditNoteNotificationArray,
  sendMultipleCreditNoteNotifications,
} from '../../../redux/features/credit-notes/actions';
import { IAugmentedCreditNote } from '../../../interfaces/ICreditNote';
import { IBillingAccount } from '../../../entity-interfaces/IBillingAccount';

interface ISendDocumentsModalProps<T extends { id: number, billingAccount: IBillingAccount }> {
  documents: T[];
  show: boolean;
  onHide: () => void;
  onSend: () => void;
}

export const SendDocumentsModal = <T extends { id: number, billingAccount: IBillingAccount }>({
  documents,
  show,
  onHide: parentOnHide,
  onSend,
}: ISendDocumentsModalProps<T>) => {
  const documentName = isIInvoice(documents[0]) ? EDocument.invoice : EDocument.creditNote;

  const [ groupContacts, setGroupContacts ] = useState<HashOf<number[]>>({});

  const { unsortedGroups, sortedGroupKeys } = getContactGroups(documents);

  const setSelectedContactsForGroup = (groupKey: string, contacts: number[]) => {
    setGroupContacts({
      ...groupContacts,
      [ groupKey ]: contacts,
    });
  };

  const [ removedSendCards, setRemovedSendCards ] = useState<string[]>([]);

  const onHide = (
    useCallback(() => {
      setRemovedSendCards([]);
      parentOnHide();
    },
    [ parentOnHide ])
  );

  const filteredSortedGroupKeys = (
    useMemo(() => sortedGroupKeys.filter((key) => !removedSendCards.includes(strVal(key))),
      [ removedSendCards, sortedGroupKeys ])
  );

  useEffect(() => {
    if (filteredSortedGroupKeys.length === 0) {
      onHide();
    }
  },
  [ filteredSortedGroupKeys.length, onHide ]);

  return (
    <Modal
      centered
      dialogClassName="modal-90w"
      backdrop="static"
      animation={false}
      show={show}
    >
      <Modal.Header closeButton onHide={onHide}>
        <Modal.Title id="contained-modal-title-vcenter">
          {`Send ${camelCaseToCapitalized(documentName)}s by Email`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          {
            filteredSortedGroupKeys.map(
              (key) => {
                const documents = unsortedGroups[key];
                const ownershipGroup = first<IInvoice | IAugmentedCreditNote>(documents)!.ownershipGroup;

                return (
                  <DocumentGroupSection
                    key={strVal(key)}
                    documents={documents}
                    ownershipGroup={ownershipGroup}
                    groupKey={`${key}`}
                    setSelectedContactsForGroup={setSelectedContactsForGroup}
                    setRemovedSendCards={setRemovedSendCards}
                  />
                );
              },
            )
          }
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={values(groupContacts).some((arr) => arr.length === 0)}
          onClick={() => {
            const notifications = (
              filteredSortedGroupKeys.map(
                (key) => {
                  const documents = unsortedGroups[key];
                  const ownershipGroup = first<IInvoice | IAugmentedCreditNote>(documents)!.ownershipGroup!;
                  const clients = first<IInvoice | IAugmentedCreditNote>(documents)!.billingAccount.clients;
                  const client = first(clients!)!;
                  const billingContacts = (
                    hasKey(groupContacts, `${key}`)
                      ? groupContacts[key]
                      : (
                        getDefaultContactsForGroup(client, ownershipGroup)
                          .filter((contact) => contact.receivesInvoices)
                          .map((contact) => contact!.billingContact!.id!)
                      )
                  );

                  return {
                    toBillingContactIds: billingContacts,
                    [ isIInvoice(documents[0]) ? 'invoiceIds' : 'creditNoteIds' ]: documents.map(({ id }) => id),
                  };
                },
              )
            );

            console.info(`${notifications.length} notification${notifications.length - 1 ? 's' : ''} sent.`);

            isIInvoice(documents[0])
              ? sendMultipleInvoiceNotifications(assertValidInvoiceNotificationArray(notifications))
              : sendMultipleCreditNoteNotifications(assertValidCreditNoteNotificationArray(notifications));

            onSend();
            onHide();
          }}
        >
          Send All
        </Button>
        <Button onClick={onHide} variant="secondary">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
