import { useAppSelector } from "../../../redux/hooks";

export const getFreshbooksUrlForInvoice = (accountId: string, freshbooksInvoiceId: string) => {
  return `${process.env.REACT_APP_FRESHBOOKS_ROOT_URI}/invoice/${accountId}-${freshbooksInvoiceId}`;
};

export const FreshbooksInvoiceLink = (
  {
    freshbooksInvoiceId, label, includeCopyButton = false,
  }: {
    freshbooksInvoiceId: string, label: string, includeCopyButton?: boolean
  },
) => {
  const freshbooksAccountId = useAppSelector(state => state.systemInfo.freshbooksAccountId);
  return (
    <>
      <a
        href={getFreshbooksUrlForInvoice(freshbooksAccountId, `${freshbooksInvoiceId}`)}
        target="_blank"
        rel="noreferrer"
      >
        {label}
        <img src='/freshbooks.ico' width='16px' height='16px' alt='Open Freshbooks Invoice' />
      </a>
      {includeCopyButton && <button onClick={() => navigator.clipboard.writeText(`${freshbooksInvoiceId}`)}>C</button>}
    </>
  );
};