import React from "react";
import { ButtonGroup, Dropdown, DropdownButton, FormCheck } from "react-bootstrap";

import { HashOf } from "@jamesgmarks/utilities";

export interface ICustomDropdownMultiselectOption {
  location: 'prepend' | 'append',
  id: string,
  label: string,
}

export const DropdownMultiselect = ({
  title,
  selectionMap,
  onItemToggle,
  onCustomOptionSelected,
  disabled,
  customOptions,
}: {
  title: string,
  customOptions?: ICustomDropdownMultiselectOption[],
  selectionMap: HashOf<boolean>,
  onCustomOptionSelected?: (id: string) => void,
  onItemToggle: (id: string) => void,
  disabled: boolean,
}) => {

  return (
    <DropdownButton
      as={ButtonGroup}
      variant='primary'
      title={title}
      disabled={disabled}
    >
      {(customOptions ?? []).filter(co => co.location === 'prepend').map((co, i) => (
        <Dropdown.Item
          key={i}
          eventKey={`${co.id}`}
          onSelect={(label) => label && onCustomOptionSelected?.(`${co.id}`)}
        >
          {co.label}
        </Dropdown.Item>
      ))}
      {
        (customOptions ?? []).filter(co => co.location === 'prepend').length > 0
        && (
          Object.keys(selectionMap).length > 0
          || (customOptions ?? []).filter(co => co.location === 'append').length > 0
        )
        && <Dropdown.Divider />
      }
      {/* <Dropdown.Item onSelect={(e) => toggleSelectAllStatuses()}>
        Select/Deselect All
      </Dropdown.Item> */}
      {Object.keys(selectionMap).map((label, i) => (
        <Dropdown.Item key={i} eventKey={label} onSelect={(label) => label && onItemToggle(label)}>
          <FormCheck checked={selectionMap[label]} label={label} readOnly />
        </Dropdown.Item>
      ))}
      {
        (customOptions ?? []).filter(co => co.location === 'append').length > 0
        && (
          Object.keys(selectionMap).length > 0
          || (customOptions ?? []).filter(co => co.location === 'prepend').length > 0
        )
        && <Dropdown.Divider />
      }
      {(customOptions ?? []).filter(co => co.location === 'append').map((co, i) => (
        <Dropdown.Item key={i} eventKey={`${co.id}`}>{co.label}</Dropdown.Item>
      ))}
    </DropdownButton>
  );
};