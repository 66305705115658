import { createSlice } from '@reduxjs/toolkit';
import { arrayWrap } from '@jamesgmarks/utilities';
import { IPromotedListingSegmentTimeline, IPromotedListingsState } from './interfaces';
import { IActionType } from '../../utils';

const initialState:IPromotedListingsState = {
  loadedState: 'idle',
  segmentTimeline: [],
};
export const promotedListingsSlice = createSlice({
  name: 'promotedListings',
  initialState,
  reducers: {
    timelineReceived: (state, action: IActionType<IPromotedListingSegmentTimeline[]>) => {
      state.segmentTimeline = arrayWrap(action.payload);
      console.log({ list: state.segmentTimeline });
    },
    setLoadedState: (state, action: { type: string, payload: string }) => {
      state.loadedState = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  timelineReceived,
  setLoadedState,
} = promotedListingsSlice.actions;

export default promotedListingsSlice.reducer;