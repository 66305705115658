import { Table } from "react-bootstrap";
import { TableBuilder } from "../TableBuilder";

export const bootstrapTableBuilder: TableBuilder = ({
  data,
  displayAdaptor,
  displayHeaders,
  footerData,
}) => {
  return (
    <Table striped bordered hover variant="dark" size="sm">
      <thead><tr>{displayHeaders.map(h => <th>{h}</th>)}</tr></thead>
      <tbody>{data.map((row) => (<tr>{ displayAdaptor(row).map((element) => <td>{element}</td>) }</tr>))}</tbody>
      {
        (footerData ?? []).length > 0
        && <tfoot><tr>{footerData ? footerData.map(f => <th>{f}</th>) : ''}</tr></tfoot>
      }
    </Table>
  );
};