import { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { Grid, Paper, Table, TableBody, TableContainer } from "@mui/material";
import { Hash } from "@jamesgmarks/utilities";

import { StyledTableCell, StyledTableRow } from "../../../parts/mui/StyledTables";
import { ucaseFirst } from "../../../../app-utils";
import { loadSandboxes } from "../../../../redux/features/sandbox/actions";
import { useAppSelector } from "../../../../redux/hooks";
import { showMessage } from "src/redux/features/messaging/actions";

export const Sandbox = (props: Hash) => {
  const sandboxes = useAppSelector(state => state.sandboxes.sandboxes);

  useEffect(() => {
    try {
      loadSandboxes();
    } catch (error) {
      showMessage({
        severity: 'error',
        message: `Error loading sandboxes: ${ucaseFirst(String(error))}. Please reload`,
      });
    }
  }, []);

  const activeSandboxes = useMemo(() => sandboxes?.filter((s) => s.active) ?? [], [sandboxes]);

  return (
    <Grid container justifyContent='center'>
      <Grid item mx='auto' mt={3} xs={9}>
        <header className="Section-header">Sandbox</header>
        <TableContainer component={Paper} sx={{ mt: 5 }}>
          <Table>
            <TableBody>
              {activeSandboxes.map(s => (
                <StyledTableRow key={s.sandboxName}>
                  <StyledTableCell customSx={{ textAlign: 'left' }}>
                    {s.sandboxName.split('_').map(ucaseFirst).join(' ')} ({s.active? 'Active' : 'Inactive'})
                  </StyledTableCell>
                  <StyledTableCell>
                    <Link to={`/developer/sandbox/run/${s.sandboxName}`}>Run</Link>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};
