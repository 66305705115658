import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { loadAccountChargesForDateRange } from '../../../redux/features/charges/actions';
import { 
  Card,
  CardContent,
  Grid,
  TablePagination,
} from "@mui/material";
import { floatVal, intVal } from '@jamesgmarks/utilities';
import { useParams } from 'react-router-dom';
import { makeYmd, toDollarAmount } from '../../../app-utils';
import { TChargeForAccountView } from '../../../interfaces/TChargeForAccountView';
import CustomDataTable from '../../parts/customDataTable/CustomDataTable';
import { rowsPerPageList } from './Tabs';

export const ChargesTab = () => {

  const charges = useAppSelector(state => state.charges.chargesListForAccountView);
  const loadedState = useAppSelector(state => state.charges.loadedState);
  const metaData = useAppSelector(state => state.charges.metaData);

  const [ sortColumn, setSortColumn ] = useState('id');
  const [ sortDirection, setSortDirection ] = useState<`ASC` | `DESC`>(`ASC`);

  const [ page, setPage ] = useState(1);
  const [ rowsPerPage, setRowsPerPage ] = useState(20);

  const accountId = intVal(useParams<{ billingAccountId?: string }>().billingAccountId);

  // endDate will be today and startDate will be the first day of previous month
  const generatedDate = new Date();
  const endDate = makeYmd(generatedDate);

  generatedDate.setDate(1);
  generatedDate.setHours(0, 0, 0, 0);
  generatedDate.setMonth(generatedDate.getMonth() - 1);
  const startDate = makeYmd(generatedDate);

  useEffect(() => {
    loadAccountChargesForDateRange(accountId, startDate, endDate, page, rowsPerPage, sortColumn, sortDirection);
  }, [ accountId, startDate, endDate, page, rowsPerPage, sortColumn, sortDirection ]);

  const getSortIcon = (criteria: string) => {
    return (sortColumn === criteria 
      ? sortDirection === 'ASC' 
        ? <>&#x25b2;</> 
        : <>&#x25bc;</> 
      : null);
  };
  
  const sortBy = (criteria: string) => {
    setSortDirection(sortColumn === criteria && sortDirection === 'ASC' ? 'DESC' : 'ASC');
    setSortColumn(criteria);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(intVal(event.target.value));
    setPage(1);
  };

  const customColumnsStyle = {
    width: '100%',
    backgroundColor: 'black',
    marginBottom: 1,
    fontWeight: 'bold',
    fontSize: '0.875rem',
    color: 'white',
  };
  const CustomColumns = () => {
    return (
      <>
        <Card sx={{ ...customColumnsStyle }}>
          <CardContent>
            <Grid container direction='row'>
              <Grid item xs sx={{cursor: 'pointer'}} onClick={() => sortBy('id')}>
                Charge ID {getSortIcon('id')}
              </Grid>
              <Grid item xs sx={{cursor: 'pointer'}} onClick={() => sortBy('subscriptionId')}>
                Subscription ID {getSortIcon('subscriptionId')}
              </Grid>
              <Grid item xs>
                Service
              </Grid>
              <Grid item xs>
                Billing Type
              </Grid>
              <Grid item xs>
                Date
              </Grid>
              <Grid item xs>
                Prorated Subtotal
              </Grid>
              <Grid item xs sx={{cursor: 'pointer'}} onClick={() => sortBy('taxAmount')}>
                Tax {getSortIcon('taxAmount')}
              </Grid>
              <Grid item xs>
                Partner Split
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  };

  const customRowStyle = {
    width: '100%',
    marginBottom: 1,
    fontSize: '0.875rem',
    backgroundColor: 'gray',
    color: 'white',
  };
  const CustomRow = ({item}: {item: TChargeForAccountView}) => {
    return (
      <>
        <Card sx={{ ...customRowStyle }}>
          <CardContent>
            <Grid container direction='row' sx={{ marginBottom: 1}}>
              <Grid item xs>
                {item.id}
              </Grid>
              <Grid item xs>
                {item.subscriptionId}
              </Grid>
              <Grid item xs>
                {item.service}
              </Grid>
              <Grid item xs>
                {item.billingType}
              </Grid>
              <Grid item xs>
                {item.year} - {item.month}
              </Grid>
              <Grid item xs>
                {
                  item.proratedSubtotal
                    ? toDollarAmount((floatVal(item.proratedSubtotal)))
                    : `--`
                }
              </Grid>
              <Grid item xs>
                {
                  item.taxAmount
                    ? toDollarAmount(floatVal(item.taxAmount))
                    : `--`
                }
              </Grid>
              <Grid item xs>
                {
                  item.partnerSplit
                    ? toDollarAmount(item.partnerSplit)
                    : `--`
                }
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs>
                <em>{item.subscriptionDescription}</em>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  };

  return (
    <>
      <TablePagination
        component="div"
        sx={{'& p': {marginTop: '1rem'}, backgroundColor: 'white'}}
        count={metaData?.totalRecords ?? 0} 
        page={metaData && metaData.currentPage ? metaData.currentPage - 1 : 0}
        rowsPerPage={rowsPerPage}
        showFirstButton
        showLastButton
        rowsPerPageOptions={rowsPerPageList}
        onPageChange={(e, newPage)=>{setPage(newPage + 1);}}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
      <CustomDataTable
        data={
          (loadedState === 'loading' 
            ? [] 
            : (charges ?? []))
        }
        rowComponent={CustomRow}
        columnsComponent={CustomColumns}
        loadingState={loadedState}
        dark={true}
        spacing={0}
      />
    </>
  );
};