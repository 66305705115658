import { TextField } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Nullable } from "@jamesgmarks/utilities";

export const MonthPicker = ({
  helperText,
  label,
  maxDate,
  minDate,
  setValue,
  value,
} : {
  label: string,
  helperText?: string,
  minDate?: Date,
  maxDate?: Date,
  value: Date,
  setValue: React.Dispatch<React.SetStateAction<Date>>
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        views={["year", "month"]}
        label={label}
        renderInput={
          () => <TextField label="Date" helperText={helperText} />
        }
        minDate={minDate}
        maxDate={maxDate}
        value={value}
        onChange={(d: Nullable<Date>) => { d && setValue(d); }}
      />
    </LocalizationProvider>
  );
};
