import { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";

import { getMonthName, getMonthStart } from "../../../app-utils";
import { first, intVal, Nullable } from "@jamesgmarks/utilities";
import { IBuildingBillingTypes, IBuildings } from "@llws/lift-entity-interfaces";

import { BillingTypeDropdown } from "./ClientBillingTypeHistory";
import { MonthPicker } from "../../parts/MonthPicker";

export const BillingTypeHistoryModal = ({
  open,
  property,
  properties,
  billingTypes,
  onSaveChanges,
  onClose,
}: {
  billingTypes: IBuildingBillingTypes[],
  open: boolean,
  onSaveChanges: (newDate: Date, newType: number) => boolean,
  onClose: () => unknown,
} & (
  { property?: IBuildings, properties?: undefined }
  | { properties?: IBuildings[], property?: undefined }
)) => {
  const today = new Date();

  const getMostRecentBuildingHistory = (property: IBuildings) => {
    return first(
      (property.buildingsBillingTypeHistories?.slice() ?? [])
        .sort((a, b) => {
          return a.startTimestamp > b.startTimestamp ? -1 : 1;
        }) ?? [],
    );
  };

  const mostRecentHistory = property ? getMostRecentBuildingHistory(property) : null;

  const [
    billingTypeId,
    setBillingTypeId,
  ] = useState(mostRecentHistory?.billingTypeId as Nullable<number>);

  const [
    billingTypeStartDate,
    setBillingTypeStartDate,
  ] = useState(
    mostRecentHistory?.startTimestamp
    ?? getMonthStart(today.getFullYear(), today.getMonth() + 1),
  );

  const [ dirty, setDirty ] = useState(false);

  useEffect(() => {
    setDirty(true);
  }, []);

  const startMonth = mostRecentHistory?.startTimestamp ? new Date(mostRecentHistory.startTimestamp) : null;
  const startMonthText = startMonth ? getMonthName(startMonth) + ', ' + startMonth.getFullYear() : '';
  const billingTypeName = billingTypes.find(bt => bt.id === mostRecentHistory?.billingTypeId)?.typeName ?? '';

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title">
      {
        property
          ? <DialogTitle id="form-dialog-title">Update {property.buildingName}</DialogTitle>
          : (properties && <DialogTitle id="form-dialog-title">Update {properties.length} properties</DialogTitle>)
      }
      <DialogContent>
        {
          property
          && <DialogContentText>
            {`Current: ${billingTypeName} starting ${startMonthText}`}
          </DialogContentText>
        }

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <MonthPicker // Validate that the month is not locked?
              label="Start Month"
              value={billingTypeStartDate ?? new Date()}
              setValue={(d) => {
                if (d) {
                  setBillingTypeStartDate(d);
                  setDirty(true);
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <BillingTypeDropdown
              id={`billing-type-history-selector`}
              label={`Billing Type`}
              selectedValue={`${billingTypeId ?? ''}`}
              items={billingTypes}
              handleChange={(e, child) => {
                setBillingTypeId(intVal(e.target.value));
                setDirty(true);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={!dirty} onClick={() => {
          if (!billingTypeStartDate) { return alert('Please select a month!'); }
          if (!billingTypeId) { return alert('Please select a type!'); }
          if (onSaveChanges(billingTypeStartDate, billingTypeId)) {
            onClose();
          }
        }} color="primary">
          Save
        </Button>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};