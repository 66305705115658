import React, {
  ChangeEvent,
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Box,
  Paper,
  TableContainer,
  Table as MuiTable,
  TableHead,
  useTheme,
  TableBody,
  Button,
  TablePagination,
  Grid,
  TextField,
  CircularProgress,
} from "@mui/material";
import {
  HashOf, floatVal, intVal, keys, compactObject, first,
} from '@jamesgmarks/utilities';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../redux/hooks';
import { Payment } from '../../../../../entities/hydra';
import { dateFromYmd, makeYmd, subtractDays, toDollarAmount } from '../../../app-utils';
import pdfFileIcon from '../../../assets/images/pdf.png';
import { IInvoice } from '../../../entity-interfaces/IInvoice';
import { StyledTableCell, StyledTableRow } from "../../parts/mui/StyledTables";
import { loadInvoices } from '../../../redux/features/invoices/actions';
import { OwnershipGroupDropdown } from '../../parts/OwnershipGroupDropdown';
import { StatusDropdown } from '../../parts/statusDropdown/StatusDropdown';
import {
  defaultCustomOptionAction,
  invoiceStatusCustomOptions,
  invoiceStatusFilterOptions,
} from '../../parts/statusDropdown/StatusOptions';
import { DateRangeDropdown } from '../../parts/DateRangeDropdown';
import { getEndOfDay } from '../../../app-utils/helpers';
import { rowsPerPageList } from './Tabs';

/**
 * get the sum of all the payments made
 * with an active hydra state for the invoice
 * @param payments
 * @returns sum of all the payments made
 */
const getPaidAmount = (payments: Payment[]) => (
  payments.reduce((acc, payment) => (
    payment.hydraState === 'active'
      ? acc + floatVal(payment.amount)
      : acc
  ), 0)
);

const InvoiceRow = ({ invoice }: {invoice: IInvoice}) => {
  const hasDownload = (invoice.pdfGenerations ?? []).length > 0;
  const og = invoice.ownershipGroup?.name ?? `No Ownership Group name available`;
  const [viewButtonColor, setViewButtonColor] = useState<'primary' | 'secondary' | 'info'>('primary');
  const theme = useTheme();

  return (
    <StyledTableRow
      customSx={{ userSelect: 'none' }}
      dark={true}
      hover={true}
      onMouseOver={() => setViewButtonColor('secondary')}
      onMouseLeave={() => setViewButtonColor('primary')}
    >
      <StyledTableCell dark={true} style={{ whiteSpace: 'nowrap' }}>
        {invoice.invoiceNumber ?? `PV:${invoice.id}`}
        &nbsp;
        <img
          src={pdfFileIcon}
          alt="pdf"
          height="20px"
          style={{
            filter: `grayscale(${hasDownload ? '0' : '1'})`,
          }}
          title={hasDownload ? 'Download available' : 'Download not available'}
        />
      </StyledTableCell>
      <StyledTableCell
        dark={true}
        colSpan={1}
        style={{ textAlign: 'left' }}
      >
        {<small>{og}</small>}
      </StyledTableCell>
      <StyledTableCell dark={true} style={{ whiteSpace: 'nowrap' }}>
        {makeYmd(dateFromYmd(invoice.invoiceDate.split('T')[0]), true)}
      </StyledTableCell>
      <StyledTableCell dark={true}>
        <span
          style={{ whiteSpace: 'nowrap' }}
        >
          ${(floatVal(invoice.amountInvoiced)).toFixed(2)} {invoice.currency}
        </span>
      </StyledTableCell>
      <StyledTableCell dark={true}>
        {
          invoice.payments
            ? toDollarAmount(getPaidAmount(invoice.payments))
            : `$0`
        }
      </StyledTableCell>
      <StyledTableCell dark={true}>
        {
          invoice.thirdPartyInvoiceId
            ? `${invoice.freshbooksState}`
            : `${invoice.hydraState.replace('sent', 'published')}`
        }
      </StyledTableCell>
      <StyledTableCell dark={true}>
        {invoice.generationType === 'manual' ? 'OTF' : 'Monthly'}
      </StyledTableCell>
      <StyledTableCell dark={true}>
        <Button
          href={`/invoices/${invoice.id}`}
          sx={{'&:hover': {color: theme.palette.common.white}}}
          target='_blank'
          variant='contained'
          color={viewButtonColor}
          size='small'
        >
            View
        </Button>
      </StyledTableCell>
    </StyledTableRow>
  );
};

// improve performance by only re-rendering if props change
const InvoiceListRow = React.memo(InvoiceRow);

export const InvoicesTab = () => {

  const invoices = useAppSelector(state => state.invoices.invoices);
  const loadedState = useAppSelector(state => state.invoices.loadedState);
  const billingAccount = useAppSelector((state) => state.clients.currentClientBillingAccount);

  const billingAccountId = intVal(useParams<{ billingAccountId?: string }>().billingAccountId);

  const [ showSearchForm, setShowSearchForm ] = useState(false);
  const [sortCriteria, setSortCriteria] = useState(['invoiceDate', 'asc']);
  const [ page, setPage ] = useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(20);

  const [searchOwnershipGroupId, setSearchOwnershipGroupId] = useState<number | null>(null);
  const [searchInvoiceNumber, setSearchInvoiceNumber] = useState('');
  const [checkedStatusFilters, setCheckedStatusFilters] = useState<HashOf<boolean>>(invoiceStatusFilterOptions);
  const [invoiceDateStart, setInvoiceDateStart] = useState<Date>(subtractDays(new Date(), 30));
  const [invoiceDateEnd, setInvoiceDateEnd] = useState<Date>(getEndOfDay(new Date()));

  useEffect(() => {
    if (
      billingAccount
      && (
        billingAccount.accountId === billingAccountId
        || billingAccount.legacyAccountId === billingAccountId
      )
    ) {
      loadInvoices({
        accountId: billingAccount.accountId,
        invoiceV3Statuses: selectedStatuses,
      });
    }
  }, [billingAccountId, billingAccount]);

  const getCellDataForSorting = useCallback((sortCriteriaName: string, invoice: IInvoice) => {
    const modifiedCellData: HashOf<Function> = {
      'invoiceNumber': (invoice: IInvoice) => (invoice.invoiceNumber ?? `PV:${invoice.id}`),
      'amountInvoiced': (invoice: IInvoice) => (
        floatVal(first(invoice.amountInvoiced.replace('$', '').trim().split(' ')))
      ),
      'generationType': (invoice: IInvoice) => (
        invoice.generationType === 'manual' ? 'OTF' : 'Monthly'
      ),
      'state': (invoice: IInvoice) => (
        invoice.thirdPartyInvoiceId
          ? `${invoice.freshbooksState}`
          : `${invoice.hydraState.replace('sent', 'published')}`
      ),
      'paidAmount': (invoice: IInvoice) =>(
        invoice.payments ? getPaidAmount(invoice.payments) : 0
      ),
      'ownershipGroup': (invoice: IInvoice) =>(
        invoice.ownershipGroup?.name ?? `No Ownership Group name available`
      ),
    };
    return (
      modifiedCellData[sortCriteriaName]
        ? modifiedCellData[sortCriteriaName](invoice)
        : (invoice)[sortCriteriaName as keyof IInvoice]
    );
  }, []);

  const doSort = useCallback(
    (a: IInvoice, b: IInvoice) => {
      const [ field, sortOrder ] = sortCriteria;
      return (
        sortOrder === 'asc'
          ? (getCellDataForSorting(field, a) > getCellDataForSorting(field, b) ? -1 : 1)
          : (getCellDataForSorting(field, b) > getCellDataForSorting(field, a)) ? -1 : 1);
    }, [ sortCriteria, getCellDataForSorting ],
  );

  const sortedInvoices = useMemo<IInvoice[]>(() => (invoices ?? []).slice().sort(doSort), [ doSort, invoices ]);

  const pagedInvoices = useMemo(
    () => sortedInvoices.slice(page * rowsPerPage, (page + 1) * rowsPerPage),
    [ page, rowsPerPage, sortedInvoices ],
  );

  const sortBy = (criteria: string) => {
    setSortCriteria(
      (sortCriteria) => [criteria, (first(sortCriteria) === criteria && sortCriteria[1] === 'asc') ? 'desc' : 'asc'],
    );
  };
  const getSortIcon = (criteria: string) => {
    return first(sortCriteria) === criteria ? (sortCriteria[1] === 'asc' ? <>&#x25bc;</> : <>&#x25b2;</>) : null;
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(intVal(event.target.value));
    setPage(0);
  };

  const selectedStatuses = useMemo(() => (
    Object.keys(checkedStatusFilters).filter(s => checkedStatusFilters[s])
  ), [checkedStatusFilters]);

  const search = () => {
    const [start, end] = [
      makeYmd(invoiceDateStart),
      makeYmd(invoiceDateEnd),
    ];
    loadInvoices(compactObject({
      accountId: billingAccount?.accountId ?? billingAccountId,
      ownershipGroupId: (
        (searchOwnershipGroupId ?? 0) > 0
          ? intVal(searchOwnershipGroupId)
          : null
      ),
      invoiceV3Statuses: selectedStatuses,
      invoiceNumber: searchInvoiceNumber.trim().length > 0 ? searchInvoiceNumber.trim() : null,
      invoiceDateStart: start,
      invoiceDateEnd: end,
    }));
  };

  return (
    <Grid container spacing={2} mt={2} justifyContent="center" alignItems="center">
      {
        showSearchForm && <Grid item component={Paper} ml={2} mb={2} sx={{ background: '#f5f5f5' }}>
          <Grid container spacing={1} mb={2} alignItems="center">
            <Grid item xs={2}>
              Ownership Group:
            </Grid>
            <Grid item xs={10} pr={2}>
              <OwnershipGroupDropdown
                freshbooksClientId={billingAccountId}
                ownershipGroupId={searchOwnershipGroupId}
                onOwnershipGroupChanged={(ownershipGroup) => {
                  setSearchOwnershipGroupId(ownershipGroup ? intVal(ownershipGroup.id) : null);
                }}
                onOwnershipGroupFreeForm={(ownershipGroupString) => {}}
                disableIfNoOwnershipGroups={true}
              />
            </Grid>
            <Grid item xs={2}>
              Invoice Number:
            </Grid>
            <Grid item xs={10} pr={2}>
              <TextField
                label='Invoice Number'
                onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchInvoiceNumber(e.target.value)}
                size="small"
                sx={{my: '0.5rem'}}
                value={searchInvoiceNumber}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={2}>
              Statuses:
            </Grid>
            <Grid item xs={10} pr={2}>
              <StatusDropdown
                statusFilterOptions={checkedStatusFilters}
                onCustomOptionSelected = {
                  (optionId) => {
                    const selectedKeys = optionId.split('-');
                    const allKeys:string[] = keys(checkedStatusFilters);
                    const selectedAction = (
                      first(
                        invoiceStatusCustomOptions
                          .filter(
                            (option) => optionId === option.id,
                          ),
                      )?.action ?? defaultCustomOptionAction
                    );
                    setCheckedStatusFilters(selectedAction(allKeys, selectedKeys));
                  }
                }
                onItemToggle = {
                  (itemId) => {
                    const allKeys: string[] = keys(checkedStatusFilters);
                    const newMap: HashOf<boolean> = (
                      allKeys.reduce((acc, key) => (
                        {
                          ...acc,
                          [key]: (
                            key === itemId
                              ? !(checkedStatusFilters)[key]
                              : (checkedStatusFilters)[key]
                          ),
                        }

                      ), {})
                    );
                    setCheckedStatusFilters(newMap);
                  }
                }
              />
            </Grid>
            <Grid item xs={2} mt={1.2}>Date Range:</Grid>
            <Grid item xs={10} mt={1.2}>
              <DateRangeDropdown
                onStartDateChange= {(d) => {
                  const newDate = d ? getEndOfDay(d) : invoiceDateStart;
                  setInvoiceDateStart(newDate);
                }}
                onEndDateChange={(d) => setInvoiceDateEnd(d ? getEndOfDay(d) : new Date())}
                startDate={invoiceDateStart}
                endDate={invoiceDateEnd}
                onItemToggle={(itemId) => { }}
                onCustomOptionSelected={(optionId) => {
                  setInvoiceDateStart(
                    subtractDays(new Date(), intVal(optionId)),
                  );
                  setInvoiceDateEnd(getEndOfDay(new Date()));
                }}
              />
            </Grid>
          </Grid>
          <Grid container mb={2}>
            <Grid item xs={6}>
              <Button
                style={{ verticalAlign: 'middle' }}
                variant='contained'
                disabled={loadedState === 'loading'}
                onClick={search}
              >
                Search
                {loadedState === 'loading' && <CircularProgress size={25} color='primary' sx={{ml: 2}} />}
              </Button>
            </Grid>
            <Grid item xs={6} pr={2} style={{ textAlign: 'right' }}>
              <Button
                variant='outlined'
                onClick={() => { setShowSearchForm(!showSearchForm); }}
                sx={{ verticalAlign: 'middle' }}
              >
                Hide Search Form
              </Button>
            </Grid>
          </Grid>
        </Grid>
      }
      {
        !showSearchForm && <Grid container spacing={1} ml={1.3} alignItems="center">
          <Grid item xs={12}  style={{ textAlign: 'center' }}>
            <Button
              variant='contained'
              onClick={() => { setShowSearchForm(!showSearchForm); }}
              sx={{ width: '100%', verticalAlign: 'middle', my: 1 }}
            >
                Reveal Search Form
            </Button>
          </Grid>
        </Grid>
      }
      <TableContainer component={Paper} elevation={12} sx={{mb: 1, ml: 2}}>
        <TablePagination
          component="div"
          sx={{'& p': {marginTop: '1rem'}}}
          count={(invoices ?? []).length}
          page={page}
          rowsPerPage={rowsPerPage}
          showFirstButton
          showLastButton
          rowsPerPageOptions={rowsPerPageList}
          onPageChange={(e, newPage)=>{setPage(newPage);}}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
        <MuiTable stickyHeader style={{ borderCollapse: 'collapse' }}>
          <TableHead>
            <StyledTableRow dark={true} style={{ cursor: 'pointer' }}>
              <StyledTableCell
                dark={true}
                onClick={() => sortBy('invoiceNumber')}
                style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
              >
                Invoice # {getSortIcon('invoiceNumber')}
              </StyledTableCell>
              <StyledTableCell
                dark={true}
                onClick={() => sortBy('ownershipGroup')}
                style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
              >
                Group {getSortIcon('ownershipGroup')}
              </StyledTableCell>
              <StyledTableCell
                dark={true}
                onClick={() => sortBy('invoiceDate')}
                style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
              >
                Date {getSortIcon('invoiceDate')}
              </StyledTableCell>
              <StyledTableCell
                dark={true}
                onClick={() => sortBy('amountInvoiced')}
                style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
              >
                Subtotal {getSortIcon('amountInvoiced')}
              </StyledTableCell>
              <StyledTableCell
                dark={true}
                onClick={() => sortBy('paidAmount')}
                style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
              >
                Paid Amount {getSortIcon('paidAmount')}
              </StyledTableCell>
              <StyledTableCell
                dark={true}
                onClick={() => sortBy('state')}
                style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
              >
                State {getSortIcon('state')}
              </StyledTableCell>
              <StyledTableCell
                dark={true}
                onClick={() => sortBy('generationType')}
                style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
              >
                Type {getSortIcon('generationType')}
              </StyledTableCell>
              <StyledTableCell>&nbsp;</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            { (loadedState === 'loading'
              ? []
              : (pagedInvoices ?? []))
              .map((i) => (
                <InvoiceListRow
                  key={i.id}
                  invoice={i}
                />
              ))
            }
          </TableBody>
        </MuiTable>
      </TableContainer>
      <>
        { loadedState === 'loading'
          ? <CircularProgress size={25} color='primary' sx={{ml: 2}} />
          : invoices?.length === 0 && <Box>NO DATA AVAILABLE</Box>
        }
      </>
    </Grid>
  );
};