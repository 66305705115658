import { TableBody } from "@mui/material";
import { IStatementLine } from "src/interfaces/ISingleViewStatementData";

export const StatementTableBody = (
  {
    statementLines, 
    totalCol= 7, 
    children,
  } : {
    statementLines: IStatementLine[], 
    totalCol?: number, 
    children: React.ReactNode
  },
) => {
  return (
    <TableBody>
      { 
        statementLines.length > 0 
          ? <>{children}</>
          : <td colSpan={totalCol} style={{textAlign: 'center'}}>NO DATA AVAILABLE</td>
      }
    </TableBody>
  );
};