import {
  arrayWrap, compactArray, compactObject, distinct, Hash, intVal, keys, mapAsync, Nullable,
} from '@jamesgmarks/utilities';
import { IApiCommandResponse, IApiErrorResponse, IApiQueryResponse, IApiResponse } from '@llws/api-common';
import { IInvoice } from '../../../entity-interfaces/IInvoice';
import IFbInvoice from '../../../../../freshbooks-api/models/IInvoices';
import { performApiActionRequest, TActionResponse } from '../../apiActionRequest';
import { store, dispatch } from '../../store';
import { apiFetch, typedApiFetch } from '../../utils';
import { showMessage } from '../messaging/actions';
import { getFeesQueue } from '../subscriptions/actions';
import {
  clearLastCreatedOtf,
  currentFreshbooksInvoiceReceived,
  currentInvoiceReceived,
  currentInvoiceWIPReceived,
  freshbooksInvoicesListReceived,
  invoicesListReceived,
  monthLockUpdated,
  otfInvoiceCreated,
  setLoadedState,
} from './invoiceSlice';
import { EmailData } from '../../../interfaces/EmailData';
import { IMonthLockedResponse } from './interfaces';
import { REACT_APP_API_ROOT_URI } from '../../../App';
import { Invoice, InvoiceLine } from '../../../../../entities/hydra';
import { NullableObj } from '../../../interfaces/NullableObj';
import { IBillingAddress } from 'src/interfaces/IBillingAddress';

export interface ILoadInvoices {
  runId?: number,
  accountId?: number,
  oldFreshbooksClientId?: number,
  clientIds?: number[],
  ownershipGroupId?: number,
  accountType?: 'client' | 'partner' | 'both',
  invoiceNumber?: string,
  invoiceV3Statuses?: string[],
  invoiceDateStart?: string,
  invoiceDateEnd?: string,
  pageSize?: number,
  lineDescription?: string
  invoiceType?: string,
}

export const loadInvoices = async ({
  runId,
  accountId,
  oldFreshbooksClientId,
  clientIds,
  ownershipGroupId,
  accountType,
  invoiceNumber,
  invoiceV3Statuses,
  invoiceDateStart,
  invoiceDateEnd,
  pageSize,
  lineDescription,
  invoiceType,
}: NullableObj<ILoadInvoices>) => {
  dispatch(setLoadedState('loading'));
  const queryParams = compactObject({
    runId,
    accountId: accountId,
    oldFreshbooksClientId,
    clientIds,
    ownershipGroupId,
    invoiceNumber,
    invoiceDateStart,
    invoiceDateEnd,
    accountType,
    lineDescription,
    invoiceType,
  });
  const queryString = compactArray([
    (
      keys(queryParams).length > 0
        ? `${keys(queryParams).map(qp => `${qp}=${queryParams[qp]}`).join('&')}`
        : null
    ),
    ((invoiceV3Statuses ?? []).map(iv3 => `v3Status[]=${iv3}`).join('&')),
    `limit=${pageSize ?? 10000}`,
  ]).join('&');
  const url = `${REACT_APP_API_ROOT_URI}/invoices/generated_invoices${queryString.length > 0 ? `?${queryString}` : ''}`;
  // console.info(`Loading invoices.`, { url });
  const response = await apiFetch(url);
  const responseData = await response.json();
  // console.info({ status: response.status, responseData });
  dispatch(invoicesListReceived(responseData.data)); // TODO: Error handling?
  dispatch(setLoadedState('idle'));
};

export const loadFreshbooksInvoices = async ({
  newFreshbooksClientId,
}: { newFreshbooksClientId: number }) => {
  const url = `${REACT_APP_API_ROOT_URI}/freshbooks/client/${newFreshbooksClientId}/invoices/`;
  // console.info(`Loading invoices for client.`, { url });
  dispatch(setLoadedState('loading'));
  const response = await apiFetch(url);
  const responseData = await response.json() as IApiResponse<IFbInvoice>;
  // console.info({ status: response.status, responseData });

  const errorData = responseData as IApiErrorResponse;
  if (errorData.error) {
    showMessage({ message: `${errorData.error}` });
    return;
  }

  const successData = responseData as IApiQueryResponse<IFbInvoice>;

  dispatch(freshbooksInvoicesListReceived(successData.data as IFbInvoice[])); // TODO: Error handling?
};

export const loadFreshbooksInvoicesByInvoiceNumber = async ({ invoiceNumber }: { invoiceNumber: string }) => {
  const url = `${REACT_APP_API_ROOT_URI}/freshbooks/invoices_by_number/${invoiceNumber}`;
  // console.info(`Loading invoices for client using invoice number.`, { url });
  dispatch(setLoadedState('loading'));
  const response = await apiFetch(url);
  const responseData = await response.json() as IApiResponse<IFbInvoice>;
  // console.info({ status: response.status, responseData });

  const errorData = responseData as IApiErrorResponse;
  if (errorData.error) {
    showMessage({ message: `${errorData.error}` });
    return;
  }

  const successData = responseData as IApiQueryResponse<IFbInvoice>;

  dispatch(freshbooksInvoicesListReceived(successData.data as IFbInvoice[])); // TODO: Error handling?
};

export const loadFreshbooksInvoicesByClientId = async ({ clientId }: { clientId: number }) => {
  const url = `${REACT_APP_API_ROOT_URI}/freshbooks/client_by_id/${clientId}/invoices/`;
  // console.info(`Loading invoices for client using client ID.`, { url });
  dispatch(setLoadedState('loading'));
  const response = await apiFetch(url);
  const responseData = await response.json() as IApiResponse<IFbInvoice>;
  // console.info({ status: response.status, responseData });

  const errorData = responseData as IApiErrorResponse;
  if (errorData.error) {
    showMessage({ message: `${errorData.error}` });
    return;
  }

  const successData = responseData as IApiQueryResponse<IFbInvoice>;

  dispatch(freshbooksInvoicesListReceived(successData.data as IFbInvoice[])); // TODO: Error handling?
};

export const loadCurrentInvoice = async (
  {
    invoiceId,
    invoiceNumber,
    forceRefresh = false,
  }: {
    invoiceId?: number,
    invoiceNumber?: string,
    forceRefresh: boolean,
  },
) => {
  const currentInvoice = store.getState().invoices.currentInvoice;
  if (
    !forceRefresh
    && (
      (invoiceId && invoiceId === currentInvoice?.invoice.id)
      || (invoiceNumber && invoiceNumber === currentInvoice?.invoice.invoiceNumber)
    )
  ) {
    console.info(`Invoice ${invoiceId ?? invoiceNumber} already loaded.`);
    return;
  }

  const url = (
    invoiceNumber
      ? `${REACT_APP_API_ROOT_URI}/invoices/invoice_data_final/by_invoice_number/${invoiceNumber}`
      : `${REACT_APP_API_ROOT_URI}/invoices/invoice_data_final/${invoiceId}/`
  );
  // console.info(`Loading current invoice.`, { url });
  const response = await apiFetch(url);
  const responseData = await response.json();
  const invoice = responseData.data && (Array.isArray(responseData.data) ? responseData.data[0] : responseData.data);
  // console.info({ location: 'loadCurrentInvoice', status: response.status, invoice, responseData });
  try {
    dispatch(currentInvoiceReceived(invoice)); // TODO: Error handling?
  } catch (e) {
    console.error('Error occurred in loadCurrentInvoice dispatch: ', { e });
  }
};

export const loadCurrentWIPInvoice = async (
  {
    invoiceId,
    invoiceNumber,
    forceRefresh = false,
  }: {
    invoiceId?: number,
    invoiceNumber?: string,
    forceRefresh: boolean,
  },
) => {
  const currentInvoiceWithSendHistory = store.getState().invoices.currentInvoiceWIPWithUpdatedSendHistory;
  if (
    !forceRefresh
    && (
      (invoiceId && invoiceId === currentInvoiceWithSendHistory?.invoice?.id)
      || (invoiceNumber && invoiceNumber === currentInvoiceWithSendHistory?.invoice?.invoiceNumber)
    )
  ) {
    console.error(`Invoice ${invoiceId ?? invoiceNumber} already loaded.`);
    return;
  }

  const url = (
    invoiceNumber
      ? `${REACT_APP_API_ROOT_URI}/invoices/by_invoice_number/${invoiceNumber}`
      : `${REACT_APP_API_ROOT_URI}/invoices/${invoiceId}`
  );
  const response = await apiFetch(url);
  const responseData = await response.json();
  const invoice = responseData.data && (Array.isArray(responseData.data) ? responseData.data[0] : responseData.data);
  try {
    dispatch(currentInvoiceWIPReceived(invoice)); // TODO: Error handling?
  } catch (e) {
    console.error('Error occurred in loadCurrentInvoice dispatch: ', { e });
  }
};

export const loadCurrentFreshbooksInvoice = async (
  { invoiceId, forceRefresh = false }: { invoiceId: string, forceRefresh: boolean },
) => {
  const currentInvoice = store.getState().invoices.currentFreshbooksInvoice;
  if (!forceRefresh && invoiceId === currentInvoice?.invoiceId) {
    // console.info(`Invoice ${invoiceId} already loaded.`);
    return;
  }

  const url = `${REACT_APP_API_ROOT_URI}/freshbooks/invoices/${invoiceId}/`;
  // console.info(`Loading current freshbooks invoice.`, { url });
  const response = await apiFetch(url);
  const responseData = await response.json() as IApiQueryResponse<IFbInvoice>;
  const invoice = responseData.data && (Array.isArray(responseData.data) ? responseData.data[0] : responseData.data);
  // console.info({ location: 'loadCurrentFreshbooksInvoice', status: response.status, invoice, responseData });
  try {
    dispatch(currentFreshbooksInvoiceReceived(invoice)); // TODO: Error handling?
  } catch (e) {
    console.error('Error occurred in loadCurrentInvoice dispatch: ', { e });
  }
};

export const updateFreshbooksInvoiceAddress = async (
  invoiceId: number,
  addressDetails: {
    organization: string,
    address: string,
    street: string,
    street2: string,
    city: string,
    province: string,
    country: string,
    code: string,
    dueOffsetDays: string,
  },
) => {
  dispatch(setLoadedState('loading'));
  const url = `${REACT_APP_API_ROOT_URI}/freshbooks/invoices/${invoiceId}/`;
  // console.info(`Loading invoice.`, { url });
  const response = await apiFetch(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      "organization": addressDetails.organization,
      "address": addressDetails.address,
      "street": addressDetails.street,
      "street2": addressDetails.street2,
      "city": addressDetails.city,
      "province": addressDetails.province,
      "country": addressDetails.country,
      "code": addressDetails.code,
      "dueOffsetDays": addressDetails.dueOffsetDays,
    }),
  });
  const responseData = await response.json() as IApiCommandResponse<IFbInvoice>;
  const invoice = responseData.data && (Array.isArray(responseData.data) ? responseData.data[0] : responseData.data);

  dispatch(currentFreshbooksInvoiceReceived(invoice));
};

export const createOneTimeFeeInvoice = async ({
  billingContactIds,
  subscriptionIds,
  sendNow,
  invoiceDate,
  ownershipGroupId,
  isPartnerOtf = false,
}: {
  billingContactIds: number[];
  subscriptionIds: number[];
  sendNow: boolean;
  invoiceDate?: string;
  ownershipGroupId?: Nullable<number>;
  isPartnerOtf?: boolean;
}) => {
  dispatch(setLoadedState('loading'));
  const response = await performApiActionRequest<TActionResponse<IInvoice>>(
    isPartnerOtf ? 'generateOneTimeInvoiceForPartner' : 'generateOneTimeInvoice',
    { subscriptionIds, invoiceDate, ...(isPartnerOtf ? {} : { ownershipGroupId }) },
  );

  const errorResponse = response as IApiErrorResponse;
  if (errorResponse.error) {
    dispatch(setLoadedState('error'));
    await showMessage({ message: arrayWrap(errorResponse.error).join('<br>') });
    setTimeout(() => setLoadedState(null), 5000);
    return;
  }

  const apiResponse = response as IApiQueryResponse<TActionResponse<IInvoice>>;
  const invoice = (apiResponse.data as TActionResponse<IInvoice>).actionResponse as IInvoice;
  dispatch(setLoadedState('invoiceCreated'));
  await showMessage({ message: 'Invoice Created', severity: 'success' });
  getFeesQueue();
  setTimeout(() => { setLoadedState(null); }, 5000);

  dispatch(otfInvoiceCreated(invoice));

  await publishInvoices([invoice.id]);

  if (sendNow) {
    await sendInvoices(billingContactIds, [invoice.id]);
  }
};

export const backfillGenSubsForInvoice = async (invoiceId: number) => {
  dispatch(setLoadedState('loading'));
  const response = await performApiActionRequest<TActionResponse<IInvoice>>('backfillGenSubs', { invoiceId });

  const errorResponse = response as IApiErrorResponse;
  if (errorResponse.error) {
    dispatch(setLoadedState('error'));
    await showMessage({ message: arrayWrap(errorResponse.error).join('<br>') });
    setTimeout(() => setLoadedState(null), 5000);
    return;
  }
  dispatch(setLoadedState('invoice gen subs backfilled'));
};

export const publishInvoices = async (
  invoiceIds: number[],
) => {
  dispatch(setLoadedState('loading'));
  // type TFreshbooksResponseInfo = { responseStatus: number, bodyText: string }
  const response = await performApiActionRequest<TActionResponse<IInvoice>>('publishInvoices', { invoiceIds });

  const errorResponse = response as IApiErrorResponse;
  if (errorResponse?.error) {
    dispatch(setLoadedState('error'));
    showMessage({ message: arrayWrap(errorResponse.error).join('<br>') });
    setTimeout(() => setLoadedState(null), 5000);
    return;
  }

  const apiResponse = response as IApiQueryResponse<TActionResponse<IInvoice>>;
  const freshbooksResponseInfo = (apiResponse.data as TActionResponse<IInvoice>).actionResponse as IInvoice;

  if (!freshbooksResponseInfo) {
    dispatch(setLoadedState('error'));
    showMessage({ message: 'Response not provided. Invoice may not have been published.' });
    setTimeout(() => setLoadedState(null), 5000);
    return;
  }
  // console.info("invoice published");
  dispatch(setLoadedState('invoicePublished'));
  showMessage({ message: 'Invoice published', severity: 'success' });

  setTimeout(() => { setLoadedState(null); }, 5000);
  clearLastCreatedOtf();
};

export const sendInvoices = async (
  toBillingContactIds: number[],
  invoiceIds: number[],
) => {
  const systemInfo = store.getState().systemInfo;

  if (!toBillingContactIds || toBillingContactIds.length === 0) {
    showMessage({ message: 'Must select billing contacts.' });
    // throw new Error('Must select billing contacts');
    return;
  }

  dispatch(setLoadedState('loading'));
  // type TFreshbooksResponseInfo = { responseStatus: number, bodyText: string }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const responses = await mapAsync(toBillingContactIds, async (billingContactId) => {
    const response = await performApiActionRequest<TActionResponse<{ to: EmailData, success: boolean; }[]>>(
      'triggerInvoiceNotification',
      {
        to: billingContactId, // { name: 'James Marks', email: 'jmarks@rentsync.com' },
        invoiceIds,
        isDevelopment: systemInfo.env.NODE_ENV !== 'production',
      },
    );
    return response;
  });

  // console.info("invoices sent", { responses });
  dispatch(setLoadedState('invoicesSent'));
  showMessage({ message: 'Invoices sent', severity: 'success' });

  setTimeout(() => { setLoadedState(null); }, 5000);
};

interface IInvoiceNotification {
  toBillingContactIds: number[];
  invoiceIds: number[];
}

const isInvoiceNotification = (obj: unknown): obj is IInvoiceNotification => (
  !!(obj as IInvoiceNotification).invoiceIds
);

export const assertValidInvoiceNotificationArray = (x: unknown[]): IInvoiceNotification[] | never => {
  if (!x.every(isInvoiceNotification)) {
    throw new Error('Invalid array! Every element must be `IInvoiceNotification`!');
  }

  return x;
};

export const sendMultipleInvoiceNotifications = async (
  notifications: IInvoiceNotification[],
) => {
  const systemInfo = store.getState().systemInfo;

  dispatch(setLoadedState('loading'));
  // type TFreshbooksResponseInfo = { responseStatus: number, bodyText: string }

  const outNotifications = notifications.reduce((acc, n) => {

    if (!n.toBillingContactIds || n.toBillingContactIds.length === 0) {
      showMessage({ message: 'Must select billing contacts.' });
      throw new Error('Must select billing contacts');
    }

    return [
      ...acc,
      ...(n.toBillingContactIds.map(c => ({
        to: c,
        invoiceIds: n.invoiceIds,
        isDevelopment: systemInfo.env.NODE_ENV !== 'production',
      }))),
    ];
  }, [] as ({ to: number, invoiceIds: number[], isDevelopment: boolean })[]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const response = await performApiActionRequest<TActionResponse<{ to: EmailData, success: boolean }[]>>(
    'triggerInvoiceNotifications',
    { notifications: outNotifications },
  );

  // console.info("invoices sent", { responses });
  dispatch(setLoadedState('invoicesSent'));
  showMessage({ message: 'Invoices sent', severity: 'success' });

  setTimeout(() => { setLoadedState(null); }, 5000);
};

/**
 * Performs the `generatePdfsForInvoices()` action and then alerts the user.
 * @param invoiceIds - An array of invoice IDs to trigger PDF generation for.
 */
export const triggerInvoicePdfGeneration = async (
  invoiceIds: number[],
) => {
  await performApiActionRequest<TActionResponse<Hash>>(
    'generatePdfsForInvoices', { invoiceIds },
  ) as IApiQueryResponse<TActionResponse<Hash>>;
  showMessage({ message: 'Invoices PDF generation started.', severity: 'success' });
};

export const loadMonthLockData = async (year: number, month: number) => {
  const url = `${REACT_APP_API_ROOT_URI}/invoices/is_month_locked/${year}-${month}`;

  const response = await apiFetch(url);
  const responseData = await response.json() as IApiQueryResponse<IMonthLockedResponse>;
  dispatch(monthLockUpdated(responseData.data as IMonthLockedResponse));
};

export const updateInvoiceLineItems = async (lines: InvoiceLine[]) => {
  const invoiceIds = distinct(lines.map(l => l.invoiceId));
  if (invoiceIds.length !== 1) {
    throw new Error('updateInvoiceLineItems: Attempting to update lines for multiple invoices.');
  }

  const [invoiceId] = invoiceIds;

  const url = `${REACT_APP_API_ROOT_URI}/invoices/${invoiceIds[0]}/lines`;

  const response = await typedApiFetch<IApiQueryResponse<IMonthLockedResponse>>(
    url,
    { method: 'PUT', body: JSON.stringify(lines) },
  );

  await response.json();

  if (response.status === 200 && invoiceId) {
    await loadCurrentWIPInvoice({ invoiceId, forceRefresh: true }); // TODO: Clear out "WIP" language. Its now permanently live.
  }
};

export const updateInvoiceReferenceNumber = async (
  invoiceId: number,
  referenceNumber: string,
) => {
  const url = `${REACT_APP_API_ROOT_URI}/invoices/${invoiceId}`;

  const response = await typedApiFetch<IApiCommandResponse<Invoice>>(
    url,
    {
      method: 'PUT',
      body: JSON.stringify({ referenceNumber }),
    },
  );

  const { data: responseData } = await response.json();
  // console.info('Invoice updated:', responseData);
  return responseData;
};

export const updateInvoiceAddress = async (
  invoiceId?:number,
  addressOverride?:IBillingAddress,
) => {

  if(!invoiceId || ! addressOverride){
    throw new Error('invoiceId and addressOverride are required');
  }
  const url = `${REACT_APP_API_ROOT_URI}/invoices/address/${invoiceId}`;

  const response = await typedApiFetch<IApiCommandResponse<Invoice>>(
    url,
    {
      method: 'PUT',
      body: JSON.stringify({ addressOverride }),
    },
  );

  const { data: responseData } = await response.json();

  loadCurrentWIPInvoice({invoiceId, forceRefresh: true});
  return responseData;
};
