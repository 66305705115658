import { useState } from "react";
import { Button, Grid, Paper, TextField, TextFieldProps } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Nullable, strVal } from "@jamesgmarks/utilities";

import { getMonthStart, makeYmd } from "../../../app-utils";
import { getDocuments } from "../../../redux/features/documents/actions";
import { EDocument, TDocumentName } from "../../../redux/features/documents/interfaces";
import { DocumentMultipleSelect } from "./DocumentMultipleSelect";
import { ClientDropdown } from "../../parts/ClientDropdown";

export const DocumentRetrievalForm = () => {
  const [ startDate, setStartDate ] = useState(
    () => { const d = new Date(); return getMonthStart(d.getFullYear(), d.getMonth() + 1); },
  );

  const [ endDate, setEndDate ] = useState(new Date());

  const [ documentNames, setDocumentNames ] = useState<TDocumentName[]>([ EDocument.creditNote ]);

  const [ selectedClientId, setSelectedClientId ] = useState<Nullable<number>>(null);
  const [ billingAccountId, setBillingAccountId ] = useState<Nullable<string>>(null);

  const fetchDocuments = () => {
    documentNames.length > 0
    && (
      getDocuments(
        makeYmd(startDate, true),
        makeYmd(endDate, true),
        documentNames,
        billingAccountId,
      )
    );
  };

  return (
    <Grid container justifyContent='center' textAlign='center'pb={1} pt={2}>
      <Grid
        item
        container
        xs={10}
        xl={8}
        component={(props) => <Paper {...props} elevation={5} sx={{ p: 2, pb: 1.5 }} />}
        sx={{ minWidth: 500 }}
      >
        <Grid item container xs={12} lg={9}>
          <Grid item container spacing={1} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid item xs={3.5}>
                <DatePicker
                  inputFormat="yyyy-MM-dd"
                  label="Start Date"
                  mask='____-__-__'
                  onChange={(d: Date | null) => d && setStartDate(d)}
                  renderInput={(props: TextFieldProps) => <TextField {...props} />}
                  showToolbar={false}
                  value={startDate}
                />
              </Grid>
              <Grid item xs={3.5}>
                <DatePicker
                  inputFormat="yyyy-MM-dd"
                  label="End Date"
                  mask='____-__-__'
                  onChange={(d: Date | null) => d && setEndDate(d)}
                  renderInput={(props: TextFieldProps) => <TextField {...props} />}
                  showToolbar={false}
                  value={endDate}
                />
              </Grid>
              <Grid item xs={5}>
                <DocumentMultipleSelect
                  documentNames={documentNames}
                  setDocumentNames={setDocumentNames}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
          <Grid item container xs={12}>
            <Grid item p={1} pl={0} xs={12}>
              <ClientDropdown
                allowFreeform
                clientId={selectedClientId}
                onClientChanged={
                  (client) => {
                    setSelectedClientId(client ? client.id : null);
                    setBillingAccountId(client ? strVal(client.billingAccountId) : null);
                  }
                }
                onClientFreeForm={(clientString) => {}}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container justifyContent='right' alignContent='flex-end' xs={12} lg={3}>
          <Grid item>
            <Button
              size='large'
              variant='contained'
              onClick={() => fetchDocuments()} sx={{ ml: 8 }}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
