import { IApiCommandResponse } from '@llws/api-common';
import { IQueryCommandOptions, convertQueryCommandToQueryString } from '@llws/dynamic-router-utils';
import { REACT_APP_API_ROOT_URI } from "../../../App";
import { dispatch, store } from "../../store";
import {
  IApiQueryListResponse,
  IApiQuerySingularResponse,
  typedApiFetch,
} from "../../utils";
import {
  setLoadedState,
  dynamicSingleReceived,
  dynamicManyReceived,
  clearDynamicDataForResource,
} from "./dynamicHydraSlice";
import { THydraDynamicTypeKey, THydraDynamicTypeTypeValue, THydraDynamicTypesMap } from "./THydraDynamicTypesMap";
import { IShowMessageProps, showMessage } from "../messaging/actions";

type TSources = 'lift' | 'hydra'

export const getOne = async <T extends (string | number)>(
  resource: THydraDynamicTypeKey,
  id: T,
  relations: IQueryCommandOptions['relations'],
  source: TSources = 'hydra',
) => {
  dispatch(setLoadedState({ resource, newState: 'loading' }));

  const qs = convertQueryCommandToQueryString({ relations } ?? {});
  const url = `${REACT_APP_API_ROOT_URI}/v1/${source}/${resource}/${id}${qs}`;
  const response = await typedApiFetch<IApiQuerySingularResponse<THydraDynamicTypeTypeValue>>(url, { method: 'GET' });
  const responseData = await response.json();
  dispatch(dynamicSingleReceived({ resource, response: responseData })); // TODO: Error handling?

  dispatch(setLoadedState({ resource, newState: 'loaded' }));

  return store.getState().dynamicHydra.data[resource].single as THydraDynamicTypesMap[THydraDynamicTypeKey];
};

export const getMany = async (
  resource: THydraDynamicTypeKey,
  queryCommandOptions?: IQueryCommandOptions,
  source: TSources = 'hydra',
) => {
  dispatch(setLoadedState({ resource, newState: 'loading' }));

  const url = (
    `${REACT_APP_API_ROOT_URI}/v1/${source}/${resource}${convertQueryCommandToQueryString(queryCommandOptions ?? {})}`
  );
  const response = await typedApiFetch<IApiQueryListResponse<THydraDynamicTypeTypeValue>>(url, { method: 'GET' });
  const responseData = await response.json();
  dispatch(dynamicManyReceived({ resource, response: responseData })); // TODO: Error handling?

  dispatch(setLoadedState({ resource, newState: 'loaded' }));
  return store.getState().dynamicHydra.data[resource].list as THydraDynamicTypesMap[THydraDynamicTypeKey][];
};

export const updateOne = async (
  resource: THydraDynamicTypeKey,
  body: BodyInit,
  id: number,
  source: TSources = 'hydra',
) => {
  dispatch(setLoadedState({ resource, newState: 'loading' }));

  const url = (`${REACT_APP_API_ROOT_URI}/v1/${source}/${resource}/${id}`);
  const response = await typedApiFetch<IApiCommandResponse<THydraDynamicTypeTypeValue>>(url, { method: 'PUT', body });

  dispatch(setLoadedState({ resource, newState: 'loaded' }));

  const formattedResource: string = resource.split('_').join(' ');
  const showMessageContent: IShowMessageProps = (
    response.ok
      ? {
        message: `${formattedResource.charAt(0).toUpperCase()}${formattedResource.slice(1)} `
          + `has been successfully updated.`,
        severity: 'success',
      }
      : {
        message: `An error occured while updating ${formattedResource}.`,
        severity: 'error',
      }
  );
  showMessage({...showMessageContent});

  return response.ok;
};

export const createOne = async (
  resource: THydraDynamicTypeKey,
  body: BodyInit,
  source: TSources = 'hydra',
) => {
  dispatch(setLoadedState({ resource, newState: 'loading' }));

  const url = (`${REACT_APP_API_ROOT_URI}/v1/${source}/${resource}`);
  const response = await typedApiFetch<IApiCommandResponse<THydraDynamicTypeTypeValue>>(url, { method: 'POST', body });

  dispatch(setLoadedState({ resource, newState: 'loaded' }));

  const formattedResource: string = resource.split('_').join(' ');
  const showMessageContent: IShowMessageProps = (
    response.ok
      ? {
        message: `${formattedResource.charAt(0).toUpperCase()}${formattedResource.slice(1)} `
          + `entry has been successfully created.`,
        severity: 'success',
      }
      : {
        message: `An error occured while creating ${formattedResource} entry.`,
        severity: 'error',
      }
  );
  showMessage({...showMessageContent});
  return response.ok;
};

export const deleteOne = async (
  resource: THydraDynamicTypeKey,
  id: number,
  source: TSources = 'hydra',
) => {
  dispatch(setLoadedState({ resource, newState: 'loading' }));

  const url = (`${REACT_APP_API_ROOT_URI}/v1/${source}/${resource}/${id}`);
  const response = await typedApiFetch<IApiCommandResponse<THydraDynamicTypeTypeValue>>(url, { method: 'DELETE' });

  dispatch(setLoadedState({ resource, newState: 'loaded' }));

  const formattedResource: string = resource.split('_').join(' ');
  const showMessageContent: IShowMessageProps = (
    response.ok
      ? {
        message: `${formattedResource.charAt(0).toUpperCase()}${formattedResource.slice(1)} `
          + `entry has been successfully deleted.`,
        severity: 'success',
      }
      : {
        message: `An error occured while deleting ${formattedResource} entry.`,
        severity: 'error',
      }
  );
  showMessage({...showMessageContent});
  return response.ok;
};

export const clearResource = async (resource: THydraDynamicTypeKey) => {
  dispatch(setLoadedState({ resource, newState: 'loading' }));

  dispatch(clearDynamicDataForResource({ resource })); // TODO: Error handling?

  dispatch(setLoadedState({ resource, newState: 'loaded' }));
};
