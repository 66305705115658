import { intVal, Nullable } from "@jamesgmarks/utilities";
import {
  Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Switch,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { DateRangeDropdown } from "src/components/parts/DateRangeDropdown";
import { OwnershipGroupDropdown } from "src/components/parts/OwnershipGroupDropdown";
import { IClientDropdownItem } from "src/entity-interfaces/IClientDropdownItem";
import { useAppSelector } from "src/redux/hooks";
import { getMonthEnd, getMonthStart, makeYmd, subtractDays } from '../../../app-utils';
import { getEndOfDay } from '../../../app-utils/helpers';
import { ClientMultiSelectDropdown } from "src/components/parts/ClientMultiSelectDropdown";
import { EStatementType, isEStatementType } from "src/interfaces/ISingleViewStatementData";

export type TStatementGenerationParams = {
  accountIds: Nullable<number[]>,
  ownershipGroupId: Nullable<number>,
  startDate: string,
  endDate: string,
}
type TStatementSearchFormProps = {
  handleGeneration: ((options: TStatementGenerationParams) => void),
  handleHideSearchAction?: ((hideSearchForm: boolean) => void),
  textFieldBackgroundColor?: string,
  showSearchForm?: boolean,
  showHideSearchFormButton?: boolean,
}

export const StatementGeneratorForm = ({
  textFieldBackgroundColor = '#fff',
  showSearchForm = true,
  showHideSearchFormButton = true,
  handleGeneration,
  handleHideSearchAction,
}: TStatementSearchFormProps) => {

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  const loadedState = useAppSelector(state => state.statements.loadedState);

  const [selectedClients, setSelectedClients] = useState<Nullable<IClientDropdownItem[]>>(null);
  const [partnerSelected, setPartnerSelected] = useState(false);
  const [selectedOwnershipGroupId, setSelectedOwnershipGroupId] = useState<Nullable<number>>(null);
  const [statementTypeToGenerate, setStatementTypeToGenerate] = useState<EStatementType>(EStatementType.account);

  const [statementDateStart, setStatementDateStart] = useState<Date>(new Date());
  const [statementDateEnd, setStatementDateEnd] = useState<Date>(getMonthEnd(currentYear, currentMonth));
  const [showOwnershipGroup, setShowOwnershipGroup] = useState<boolean>(false);

  useEffect(() => {
    if (
      (selectedClients && selectedClients.length > 1)
      || !selectedClients
      || selectedClients.length === 0
    ) {
      setSelectedOwnershipGroupId(null);
    }
  }, [selectedClients, setSelectedOwnershipGroupId, selectedOwnershipGroupId]);

  const handleSelectOptionChange = (value: string) => {
    if (isEStatementType(value)) setStatementTypeToGenerate(value);
  };

  const getFreshbookClientId = useMemo(() => {
    if (!selectedClients) {
      return null;
    }
    return (
      selectedClients.length === 1
        ? selectedClients[0].freshbooksClientId
        : null
    );
  }, [selectedClients]);

  const generate = () => {
    const generationParams: TStatementGenerationParams = {
      accountIds: (selectedClients ?? []).map((c) => c.billingAccountId),
      ownershipGroupId: !showOwnershipGroup ? null : selectedOwnershipGroupId,
      startDate: makeYmd(statementDateStart),
      endDate: makeYmd(statementDateEnd),
    };
    handleGeneration(generationParams);
  };

  return (
    <>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={2}>
          Client:
        </Grid>
        <Grid item xs={10}>
          <ClientMultiSelectDropdown
            textFieldBackgroundColor={textFieldBackgroundColor}
            onClientChanged={
              (client) => {
                const partnersInClientArray = (client ?? []).filter((c) => c.type === 'partner');
                setSelectedClients(client);
                setPartnerSelected(partnersInClientArray.length > 0);

              }
            }
          />
        </Grid>
        <Grid item xs={2}>
          Statement Type:
        </Grid>
        <Grid item xs={10}>
          <FormControl fullWidth size="small">
            <InputLabel id="statement-type-select-label">Statement Type</InputLabel>
            <Select
              labelId="statement-type-select-label"
              id="statement-type-select"
              value={statementTypeToGenerate}
              label="Statement Type"
              disabled
              onChange={(e) => { handleSelectOptionChange(e.target.value); }}
            >
              <MenuItem value={EStatementType.account}>Account</MenuItem>
              <MenuItem value={EStatementType.activity}>Activity</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2} mt={1.2}>
          Date Range:
        </Grid>
        <Grid item xs={10} mt={1.2}>
          <DateRangeDropdown
            disableEndDate={statementTypeToGenerate === EStatementType.account ? true : false}
            onStartDateChange={(d) => setStatementDateStart(d ? getEndOfDay(d) : statementDateStart)}
            onEndDateChange={(d) => setStatementDateEnd(d ? getEndOfDay(d) : statementDateEnd)}
            startDate={statementDateStart}
            textFieldBackgroundColor={textFieldBackgroundColor}
            endDate={statementDateEnd}
            onItemToggle={(itemId) => { }}
            onCustomOptionSelected={(optionId) => {
              setStatementDateStart(
                subtractDays(new Date(), intVal(optionId)),
              );
              setStatementDateEnd(getEndOfDay(new Date()));
            }}
          />
        </Grid>
        {showOwnershipGroup 
          && <>
            <Grid item xs={2}>
              Show Ownership Groups:
            </Grid>
            <Grid item xs={10}>
              <Switch 
                edge="start" 
                checked={showOwnershipGroup} 
                onChange={(e) => {
                  setShowOwnershipGroup(e.target.checked);
                }}/>
            </Grid>
          </>
        }
        { showOwnershipGroup
          && <>
            <Grid item xs={2}>
              Ownership Group:
            </Grid>
            <Grid item xs={10}>
              <OwnershipGroupDropdown
                allowFreeform
                freshbooksClientId={getFreshbookClientId}
                ownershipGroupId={selectedOwnershipGroupId}
                onOwnershipGroupChanged={(ownershipGroup) => {
                  setSelectedOwnershipGroupId(ownershipGroup ? intVal(ownershipGroup.id) : null);
                }}
                onOwnershipGroupFreeForm={(ownershipGroupString) => { }}
                disableIfNoOwnershipGroups={true}
                disableComponent={!showOwnershipGroup && (partnerSelected || (selectedClients ?? []).length !== 1)}
              />
            </Grid>
          </>
        }

      </Grid>
      <Grid container pt={1}>
        <Grid item xs={6}>
          <Button
            style={{ verticalAlign: 'middle' }}
            variant='contained'
            disabled={loadedState === 'loading' || (selectedClients ?? []).length === 0}
            onClick={generate}
          >
            Search
            {loadedState === 'loading' && <CircularProgress size={25} color='primary' sx={{ ml: 2 }} />}
          </Button>
        </Grid>
        {
          showHideSearchFormButton
          && <Grid item xs={6} pr={2} style={{ textAlign: 'right' }}>
            <Button
              variant='outlined'
              onClick={() => { handleHideSearchAction && handleHideSearchAction(!showSearchForm); }}
              sx={{ verticalAlign: 'middle' }}
            >
              Hide Search Form
            </Button>
          </Grid>
        }
      </Grid>
    </>
  );
};