import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import {
  Grid,
  Paper,
} from "@mui/material";

import { loadSandboxes, runSandbox } from "../../../../redux/features/sandbox/actions";
import { ucaseFirst } from "../../../../app-utils";
import { useAppSelector } from "../../../../redux/hooks";
import { RunnerFieldsForm } from "../../../parts/runner-form/RunnerFieldsForm";

export const RunSandbox = () => {
  const sandboxes = useAppSelector((state) => state.sandboxes.sandboxes);
  const loadedState = useAppSelector((state) => state.sandboxes.loadedState);
  const lastSandboxResult = useAppSelector((state) => state.sandboxes.lastSandboxResult);

  const { sandboxName } = useParams<{ sandboxName: string }>();

  useEffect(() => { loadSandboxes(); }, []);

  const sandbox = useMemo(() => sandboxes?.find((s) => s.sandboxName === sandboxName)!, [sandboxes, sandboxName]);

  if (!sandbox) {
    return (<> Please wait... Loading... </>);
  }

  return (<>
    <br />
    <header className="Section-header">Run Sandbox: {sandbox?.sandboxName.split('_').map(ucaseFirst).join(' ')}</header>
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={10}>
        <Paper>
          <Grid container justifyContent="center" spacing={2} mt={7}>
            <Grid item xs={10} style={{ textAlign: 'left' }}><h4>Fields:</h4></Grid>
            <Grid item xs={10} style={{ textAlign: 'right' }}>
              <RunnerFieldsForm
                fields={sandbox.sandboxFields}
                submitDisabled={loadedState !== 'loaded'}
                onSubmit={(parameters) => runSandbox(sandboxName, parameters)}
              />
            </Grid>
            <Grid item xs={10}>
              <Paper>
                <Grid container spacing={2}>
                  <Grid item>
                    <pre>
                      {
                        loadedState === 'loaded' && lastSandboxResult?.sandboxname === sandboxName
                          ? JSON.stringify(lastSandboxResult.output, null, 2)
                          : (
                            loadedState ?? 'Use the Run button to run your sandbox script'
                          )
                      }
                    </pre>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  </>);
};
