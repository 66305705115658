import { createSlice } from '@reduxjs/toolkit';
import { IActionType, IApiQueryListResponse, IApiQuerySingularResponse } from '../../utils';
import { TLoadedState } from '../../../types/TLoadedState';
import { TDynamicTypeKey, TDynamicTypesMap, TDynamicTypeTypeValue } from './TDynamicTypesMap';
import { Nullable, replaceAt } from '@jamesgmarks/utilities';
import { IApiCommandResponse, IApiQueryResponse } from '@llws/api-common';
import { ISubscriptions } from '@llws/lift-entity-interfaces';

type TDynamicStoreData = {
  [K in keyof TDynamicTypesMap]: {
    loadedState: TLoadedState,
    single: null | TDynamicTypesMap[K],
    list: null | TDynamicTypesMap[K][],
    listMeta: null | IApiQueryListResponse<TDynamicTypesMap[K]>['meta'],
  }
}

export type TDynamicState = {
  data: TDynamicStoreData,
  metaData: Nullable<IApiQueryResponse<TDynamicTypeTypeValue>['meta']>,
};

const updateDynamicState = <T extends keyof TDynamicTypesMap>(
  startState: TDynamicStoreData,
  resource: T,
  newState: Partial<TDynamicStoreData[T]>,
) => {
  const listItemIndex = startState[resource]?.list?.findIndex(item => item.id === newState.single?.id) ?? -1;

  const oldList = (startState[resource]?.list ?? []);

  const newList = newState.list ?? (
    listItemIndex >= 0 && newState.single
      ? replaceAt(oldList, listItemIndex, newState.single as TDynamicTypesMap[T])
      : oldList
  );

  return {
    ...startState,
    [resource]: {
      loadedState: newState?.loadedState ?? startState[resource]?.loadedState ?? 'idle',
      single: newState?.single ?? startState[resource]?.single ?? null,
      list: newList,
    },
  };
};

export const dynamicSlice = () => createSlice({
  name: 'dynamic',
  initialState: {
    data: {},
    metaData: null,
  } as TDynamicState,
  reducers: {
    setLoadedState: (state, action: IActionType<{
      resource: TDynamicTypeKey,
      newState: TLoadedState,
    }>) => {
      state.data = updateDynamicState(state.data, action.payload.resource, {
        loadedState: action.payload.newState,
      });
    },
    dynamicSingleReceived: (state, action: IActionType<{
      resource: TDynamicTypeKey
      response: IApiQuerySingularResponse<TDynamicTypesMap[TDynamicTypeKey]>
    }>) => {
      state.data = updateDynamicState(state.data, action.payload.resource, {
        single: action.payload.response.data as ISubscriptions, // This is cheating, but it works
      });
    },
    dynamicSingleCommandResultReceived: (state, action: IActionType<{
      resource: TDynamicTypeKey
      response: IApiCommandResponse<TDynamicTypesMap[TDynamicTypeKey]>
    }>) => {
      state.data = updateDynamicState(state.data, action.payload.resource, {
        single: action.payload.response.data as ISubscriptions, // This is cheating, but it works
      });
    },
    dynamicManyReceived: (state, action: IActionType<{
      resource: TDynamicTypeKey
      response: IApiQueryListResponse<TDynamicTypesMap[TDynamicTypeKey]>
    }>) => {
      state.data = updateDynamicState(state.data, action.payload.resource, {
        list: action.payload.response.data as ISubscriptions[], // This is cheating, but it works
        listMeta: action.payload.response.meta,
      });
      state.metaData = { ...action.payload.response.meta };
    },
    dynamicManyCommandResultReceived: (state, action: IActionType<{
      resource: TDynamicTypeKey
      response: IApiCommandResponse<TDynamicTypesMap[TDynamicTypeKey][]>
    }>) => {
      const list = action.payload.response.data as ISubscriptions[]; // This is cheating, but it works
      const listMeta = {
        totalRecords: list.length,
        recordsPerPage: list.length,
        totalPages: 1,
        currentPage: 1,
        links: {
          self: '',
          next: '',
          prev: '',
          last: '',
          first: '',
        },
        custom: null,
      };
      state.data = updateDynamicState(state.data, action.payload.resource, {
        list,
        listMeta,
      });
      state.metaData = { ...listMeta };
    },
    clearDynamicDataForResource: (state, action: IActionType<{ resource: TDynamicTypeKey }>) => {
      state.data[action.payload.resource] = {
        loadedState: 'idle',
        single: null,
        list: [],
        listMeta: null,
      };
    },
  },
});

const slice = dynamicSlice();

// Action creators are generated for each case reducer function
export const {
  setLoadedState,
  dynamicSingleReceived,
  dynamicSingleCommandResultReceived,
  dynamicManyReceived,
  dynamicManyCommandResultReceived,
  clearDynamicDataForResource,
} = slice.actions;

export default slice.reducer;
