import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

import './monthLock.css';

import { Nullable } from "@jamesgmarks/utilities";

import { sendMonthLockRequest } from "../../../redux/features/reporting/actions";
import { useAuth } from "../../../customHooks/useAuth";

export const MonthLockModal = ({ showModal, setShowModal, year, month }
  : {
    showModal: boolean,
    setShowModal: ((newState: boolean) => void),
    year: Nullable<number>,
    month: Nullable<number>
  }) => {
  const { userId } = useAuth();

  const handleClose = () => {
    setShowModal(false);
  };

  const handleMonthLock = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`ARE YOU SURE`)) {
      year && month ? sendMonthLockRequest(year, month, userId) : (() => { })();
      handleClose();
    }
  };

  const months: {
    [key: number]: string
  } = {
    1: 'JANUARY',
    2: 'FEBRUARY',
    3: 'MARCH',
    4: 'APRIL',
    5: 'MAY',
    6: 'JUNE',
    7: 'JULY',
    8: 'AUGUST',
    9: 'SEPTEMBER',
    10: 'OCTOBER',
    11: 'NOVEMBER',
    12: 'DECEMBER',
  };
  return (
    <>
      { month ? <Dialog open={showModal} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          LOCK {`${months[month]} ${year}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            ARE YOU SURE YOU WANT TO LOCK

            <Typography variant="h2" >
              <span className='blink_me'>
                {months[month]}
              </span>
            </Typography>
            <Typography variant="h2" >
              {year}
            </Typography>
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleMonthLock} color="primary">
            Lock {months[month]}
          </Button>
        </DialogActions>
      </Dialog>
        : <></>
      }
    </>
  );
};
