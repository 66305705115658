import { IApiCommandResponse, IApiErrorResponse, IApiResponse } from '@llws/api-common';
import { REACT_APP_API_ROOT_URI } from '../../../App';
import { dispatch } from '../../store';
import { addMessage } from '../messaging/messagingSlice';
import { setUserToken } from './authSlice';

export interface IAuthResult {
  token: string;
}

export const login = async ({ email, password }: { email: string, password: string }) => {
  const url = `${REACT_APP_API_ROOT_URI}/auth/login`;
  const body = { email, password };
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  const responseData = await response.json() as IApiResponse<IAuthResult>;

  const errorResponse = responseData as IApiErrorResponse;
  if (errorResponse.error) {
    console.log(`Error: ${errorResponse.error}`);
    dispatch(addMessage({ timestamp: Date.now(), message: errorResponse.error as string }));
  }

  const commandResponse = (responseData as IApiCommandResponse<IAuthResult>);
  if (commandResponse.data) {
    console.log(`Command:`, { data: commandResponse.data });
    localStorage.setItem('token', (commandResponse.data as IAuthResult).token);
    dispatch(setUserToken((commandResponse.data as IAuthResult).token)); // TODO: Error handling?
  }
};

export const logout = async () => {
  localStorage.removeItem('token');
  dispatch(setUserToken(null));
};
