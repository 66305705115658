import { useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableHead,
  Typography,

} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";

import { strVal } from "@jamesgmarks/utilities";

import { BillingRunDataRow } from "./BillingRunDataRow";
import { EBillingRunStatus } from "./BillingRunFilters";
import { IBillingRun } from "../../../entity-interfaces/IBillingRun";
import { StyledTableCell, StyledTableRow } from "../../parts/mui/StyledTables";
import { getMonthName } from "../../../app-utils";
import { useAuth } from "../../../customHooks/useAuth";
import { performApiActionRequest } from "../../../redux/apiActionRequest";

interface IParentBillingRunAccordionProps {
  created: string;
  filteredBillingRunsForParent: IBillingRun[];
  parentRunId: number;
  runIdFilter: string;
  setStatusFilter: (status: EBillingRunStatus, checked: boolean) => void;
  setErroredRunsForRerun: (erroredRuns: IBillingRun[]) => void;
}

export const ParentBillingRunAccordion = ({
  created,
  filteredBillingRunsForParent,
  parentRunId,
  runIdFilter,
  setStatusFilter,
  setErroredRunsForRerun,
}: IParentBillingRunAccordionProps) => {
  const [ expanded, setExpanded ] = useState(false);
  const [ publishRunInProgress, setPublishRunInProgress ] = useState(false);

  const parentRun = filteredBillingRunsForParent.find((run) => run.id === parentRunId);

  const runYear = parentRun ? parentRun.year : filteredBillingRunsForParent[0]?.year;
  const runMonth = parentRun ? parentRun.month : filteredBillingRunsForParent[0]?.month;

  const isParentRunFilteredOut = runIdFilter && runIdFilter !== strVal(parentRunId);

  const childCount = filteredBillingRunsForParent.length - (isParentRunFilteredOut ? 0 : 1);

  const { isDeveloper } = useAuth();

  const erroredRuns = filteredBillingRunsForParent.filter((run) => run.status === EBillingRunStatus.error);

  return (
    filteredBillingRunsForParent.length === 0
      ? <></>
      : (
        <Accordion
          expanded={expanded}
          onChange={() => setExpanded((old) => !old)}
          sx={{ width: '100%' }}
        >
          <AccordionSummary
            sx={{ background: '#1c2128', border: '1px solid #0077b6', color: '#cdd9e5' }}
            expandIcon={<GridExpandMoreIcon sx={{ color: 'white', fontSize: '2.5rem' }} />}
          >
            <Grid container>
              <Grid item xs>
                <Typography variant="h6" gutterBottom>
                  {
                    <>
                      <span>Parent #: </span>
                      <span style={{ fontWeight: 700 }}>{parentRunId} </span>
                      <span style={{ color: '#bbd0ff' }}>
                        {`(${getMonthName(new Date(runYear, runMonth - 1))} ${runYear})`}
                      </span>
                      {
                        isDeveloper
                        && (
                          <>
                            <span> - </span>
                            <span style={{color: '#00f5d4'}}>{childCount}</span>
                            <span> child{childCount === 1 ? '' : 'ren'}</span>
                            <span> - </span>
                            <span style={{ fontSize: '0.9rem' }}>Created: </span>
                            <span style={{ fontSize: '0.9rem' }}>{new Date(created).toLocaleDateString()}</span>
                          </>
                        )
                      }
                    </>
                  }
                </Typography>
              </Grid>
              <>
                <Grid item mr={4}>
                  {
                    erroredRuns.length > 0
                      ? (
                        <Button onClick={(e) => {
                          e.stopPropagation();
                          setErroredRunsForRerun(erroredRuns);
                        }}
                        >
                          Re-run errored runs
                        </Button>
                      )
                      : (
                        <Button
                          disabled={publishRunInProgress}
                          onClick={(e) => {
                            e.stopPropagation();
                            console.info('Publish run initiated!');
                            performApiActionRequest('triggerPublishRun', {parentRunId});
                            setPublishRunInProgress(true);
                          }}
                        >
                          Begin publish run
                        </Button>
                      )
                  }
                </Grid>
              </>
            </Grid>
          </AccordionSummary>

          <AccordionDetails
            sx={{ background: '#2f3e46' }}
          >
            <Paper elevation={5}>
              <Table>
                <TableHead>
                  <StyledTableRow dark={true}>
                    <StyledTableCell dark={true}>#</StyledTableCell>
                    <StyledTableCell dark={true}>Parent</StyledTableCell>
                    <StyledTableCell dark={true}>Group</StyledTableCell>
                    <StyledTableCell dark={true}>Month</StyledTableCell>
                    <StyledTableCell dark={true}>Status</StyledTableCell>
                    <StyledTableCell dark={true}>Details</StyledTableCell>
                    <StyledTableCell dark={true}>Created</StyledTableCell>
                    <StyledTableCell dark={true}>&nbsp;</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {
                    filteredBillingRunsForParent
                      .slice()
                      .sort((a, b) => a.id < b.id ? -1 : 1)
                      .map(
                        (run, i) => (
                          <BillingRunDataRow
                            key={i}
                            run={run}
                            setStatusFilter={setStatusFilter}
                          />
                        ),
                      )
                  }
                </TableBody>
              </Table>
            </Paper>
                
          </AccordionDetails>
        </Accordion>
      )
  );
};
