import { HashOf } from "@jamesgmarks/utilities";
import { ICustomDropdownMultiselectOption } from "../DropdownMultiselect";

export type ICustomDropdownMultiselectAction = (options: string[], selectedKeys?: string[]) => HashOf<boolean>
export interface ICustomDropdownMultiselectWithActions extends ICustomDropdownMultiselectOption {
  action: ICustomDropdownMultiselectAction;
}

/**
 * The default actions for custom options for the 
 * multiselect dropdown
 * @param options all the options for the filter
 * @param selectedKeys selected keys representing which options
 * need to be turned on
 * @returns all the filters that are turned on or off based on the
 * key selected
 */
export const defaultCustomOptionAction: ICustomDropdownMultiselectAction = (
  (options, selectedKeys) => (
    options.reduce((acc: HashOf<boolean>, key) => {
      return { ...acc, [key]: (selectedKeys ?? []).includes(key) ? true : false };
    }, {})
  )
);

export const invoiceStatusCustomOptions: ICustomDropdownMultiselectWithActions[] = [
  {
    location: 'prepend',
    id: 'unpublished-draft',
    label: 'Unpublished or Draft',
    action: (options, selectedKeys) => defaultCustomOptionAction(options, selectedKeys),
  },
  {
    location: 'prepend',
    id: 'unpublished',
    label: 'Unpublished',
    action: (options, selectedKeys) => defaultCustomOptionAction(options, selectedKeys),
  },
  {
    location: 'prepend',
    id: 'draft',
    label: 'Draft',
    action: (options, selectedKeys) => defaultCustomOptionAction(options, selectedKeys),
  },
  {
    location: 'prepend',
    id: 'sent',
    label: 'Sent',
    action: (options, selectedKeys) => defaultCustomOptionAction(options, selectedKeys),
  },
  {
    location: 'prepend',
    id: 'select-all',
    label: 'Select All',
    action: (options) => (
      options.reduce((acc: HashOf<boolean>, key) => ({ ...acc, [key]: true }), {})
    ),
  },
  {
    location: 'prepend',
    id: 'select-none',
    label: 'Select None',
    action: (options) => (
      options.reduce((acc: HashOf<boolean>, key) => ({ ...acc, [key]: false }), {})
    ),
  },
];

export const invoiceStatusFilterOptions: HashOf<boolean> = {
  unpublished: true,
  draft: true,
  sent: true,
  viewed: true,
  paid: true,
  partial: true,
  disputed: true,
  overdue: true,
  declined: true,
  'legacy-imported': false,
};

export const basicCustomOptions: ICustomDropdownMultiselectWithActions[] = [
  {
    location: 'prepend',
    id: 'select-all',
    label: 'Select All',
    action: (options) => (
      options.reduce((acc: HashOf<boolean>, key) => ({ ...acc, [key]: true }), {})
    ),
  },
  {
    location: 'prepend',
    id: 'select-none',
    label: 'Select None',
    action: (options) => (
      options.reduce((acc: HashOf<boolean>, key) => ({ ...acc, [key]: false }), {})
    ),
  },
];

export const statementStatusFilterOptions: HashOf<boolean> = {
  draft: true,
  sent: true,
  unsent: true,
  deleted: true,
};

export const statementGenerationFilterOptions: HashOf<boolean> = {
  client: true,
  group: true,
  monthly: true,
  manual: true,
};
