import { Button, Card, Container } from "react-bootstrap";
import { DataTable } from "../../parts/data-table/DataTable";

const componentHelperFunction = () => {

};

export interface SourceType {
  name: string,
  date: Date,
  amount: number,
  color: string,
}

export const ComponentPlayground = () => {
  // place variables here

  const sourceData: SourceType[] = [
    { name: 'James', date: new Date('2021-01-01'), amount: 123.15, color: 'red' },
    { name: 'Amir', date: new Date('2021-01-02'), amount: 321.1, color: 'blue' },
    { name: 'Nik', date: new Date('2021-03-01'), amount: 100, color: 'green' },
    { name: 'Alex', date: new Date('2020-09-01'), amount: 600.55, color: 'yellow' },
    { name: 'Tanya', date: new Date('2021-01-01'), amount: 1231.67, color: 'purple' },
    { name: 'Michael', date: new Date('2021-01-03'), amount: 444.88, color: 'black' },
  ];

  return (
    <Container>
      <h3>Component Playground</h3>
      <Card>
        <Button onClick={() => componentHelperFunction()}>get help</Button>
        {/* place component here */}
        <DataTable
          data={sourceData}
          downloadFilename='invoices'
        />
      </Card>
    </Container>
  );
};