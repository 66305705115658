import { SyntheticEvent, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { keys } from "@jamesgmarks/utilities";

import { camelCaseToCapitalized } from "../../../app-utils/helpers";
import { DocumentListTable } from "./DocumentListTable";
import { IDocumentMap } from "../../../redux/features/documents/interfaces";
import { TabPanel } from "../../parts/TabPanel";
import { documentListFieldGetters, TTableData } from "./table-cell-getters";
  
interface IDocumentListTabsProps {
  documents: IDocumentMap;
}

export const DocumentListTabs = ({
  documents,
}: IDocumentListTabsProps) => {
  const [value, setValue] = useState(0);

  const handleChange = (e: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box width='100%' mt={8} sx={{ bgcolor: 'background.paper' }}>
      <Tabs value={value} onChange={handleChange} centered>
        {
          keys(documents).map((key) => <Tab key={key} label={`${camelCaseToCapitalized(key)}s`} />)
        }
      </Tabs>

      {
        keys(documents).map((key, i) => (
          <TabPanel key={key} value={value} index={i}>
            <DocumentListTable<typeof documents[typeof key][0]>
              documents={documents[key]}
              fields={documentListFieldGetters[key] as TTableData<typeof documents[typeof key][0]>[]}
            />
          </TabPanel>
        ))
      }
    </Box>
  );
};
