
import { dispatch } from "../../store";

import { IApiQuerySingularResponse, typedApiFetch } from "../../utils";
import { REACT_APP_API_ROOT_URI } from "../../../App";
import { setLoadedState, xeroConsentUrlReceived } from "./xeroSlice";

export const getXeroConsentUrl = async () => {
  dispatch(setLoadedState('loading'));
  const consentUrlResponse = (
    await typedApiFetch(`${REACT_APP_API_ROOT_URI}/xero/consent`)
  );
  const consentUrl = (
    await consentUrlResponse.json() as IApiQuerySingularResponse<{ consentUrl: string }>
  ).data.consentUrl;
  dispatch(xeroConsentUrlReceived({consentUrl}));
  dispatch(setLoadedState('loaded'));
};
