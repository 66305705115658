import { IApiCommandResponse } from "@llws/api-common";
import { REACT_APP_API_ROOT_URI } from "../../../App";
import { dispatch } from "../../store";
import { IApiQuerySingularResponse, typedApiFetch } from "../../utils";
import { receiveRouterSettings } from "./apiValidatorsSlice";
import { IApiValidatorSettingsOptions, TApiValidatorSettings } from "./interfaces";

export const getApiValidatorSettings = async () => {
  const url = `${REACT_APP_API_ROOT_URI}/api_validators`;

  const response = await typedApiFetch<IApiQuerySingularResponse<TApiValidatorSettings>>(url);
  const { data: responseBody } = await response.json();

  dispatch(receiveRouterSettings(responseBody));
};

export const updateApiValidatorSettings = async (
  router: string,
  newSettings: IApiValidatorSettingsOptions,
) => {
  const url = `${REACT_APP_API_ROOT_URI}/api_validators/${router}`;

  const response = (
    await typedApiFetch<IApiCommandResponse<TApiValidatorSettings> & { data: TApiValidatorSettings }>(
      url,
      { method: 'PUT', body: JSON.stringify(newSettings) },
    )
  );
  const { data: responseBody } = await response.json();
  
  dispatch(receiveRouterSettings(responseBody));
};
