import { useRef } from 'react';

export type TRefAction =
  | 'click'
  | 'focus'

const useRefAction = <T extends HTMLElement = HTMLElement>(action: TRefAction): [React.RefObject<T>, () => void] => {
  const htmlElRef = useRef<T>(null);
  const performAction = () => {
    htmlElRef?.current?.[action]?.();
  };
  return [htmlElRef, performAction];
};

export default useRefAction;
