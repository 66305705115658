import { IApiQueryResponse } from '@llws/api-common';
import { dispatch } from '../../store';
import { commissionsPayableListReceived, salesRepListReceived, setLoadedState } from './commissionsSlice';
import { SalesReps } from '@llws/typeorm-entities';
import { apiFetch, IApiQuerySingularResponse, typedApiFetch } from '../../utils';
import { ICommissionRecord } from './interfaces';
import { showMessage } from '../messaging/actions';
import { REACT_APP_API_ROOT_URI } from '../../../App';

export const loadSalesReps = async (source: 'legacy' | 'hydra' = 'legacy') => {
  const url = `${REACT_APP_API_ROOT_URI}/commissions/sales_reps?source=${source}`;
  dispatch(setLoadedState('loading'));
  const response = await apiFetch(url);
  const responseData = await response.json() as IApiQueryResponse<SalesReps>;
  console.log({ status: response.status, responseData });
  dispatch(salesRepListReceived(responseData)); // TODO: Error handling?
  dispatch(setLoadedState('loaded'));
};

export const loadCommissions = async (id: number, query: string = '') => {
  const url = `${REACT_APP_API_ROOT_URI}/commissions/sales_reps/${id}${query}`;
  dispatch(setLoadedState('loading'));
  const response = await apiFetch(url);
  const responseData = await response.json() as IApiQueryResponse<ICommissionRecord>;
  console.log({ status: response.status, responseData });
  dispatch(commissionsPayableListReceived(responseData)); // TODO: Error handling?
  dispatch(setLoadedState('loaded'));
};

export const markCommissionsAsPaid = async (commissionRecords: ICommissionRecord[], callbackFn: Function) => {
  const body = {
    action: "markCommissionsAsPaid",
    parameters: {
      commissionRecords,
    },
  };
  const url = `${REACT_APP_API_ROOT_URI}/actions`;
  const response = await apiFetch(url, { method: 'POST', body: JSON.stringify(body) });
  const responseData = await response.json() as IApiQuerySingularResponse<{ actionResponse: { message: string } }>;
  console.log({ status: response.status, response: responseData.data.actionResponse.message });
  showMessage({ message: `${responseData.data.actionResponse.message}`, severity: 'info' });
  callbackFn();
};

/**
 * Uses an uploaded commissions paid file to dispatch an action that updates the
 * `commissionPaidDate` on the Hydra records.
 * 
 * If the action returns `status` of error, an alert will be shown to the user.
 * If successful, an `info` variant Snackbar will be shown to the user, indicating
 * that the commissions are being updated.
 * @param commissionRecordsFile - A CSV file that holds the paid commissions data.
 */
export const markCommissionsAsPaidByFile = async (commissionRecordsFile: File) => {
  const formData = new FormData();
  formData.append("action", "markCommissionsAsPaidByFile");
  formData.append("commissionsUpload", commissionRecordsFile );
  console.log({ test: formData });
  const url = `${REACT_APP_API_ROOT_URI}/actions`;
  const response = await typedApiFetch<IApiQuerySingularResponse<
  {
    actionResponse: { status: string, message: string }
  }
  >>(url, {
    method: 'POST',
    body: formData,
  }, { defaultJsonContent: false });
  const responseData = await response.json();
  
  const { status, message } = responseData.data.actionResponse;

  const severity = status === 'error' ? 'error' : 'info';
  const asAlert = status === 'error';

  showMessage({ message, severity, asAlert });
};
