import {
  Box, Button, Card, CardContent, CardHeader, Paper, TextField, 
} from "@mui/material";
import { TBillingContactInternalNote } from "@llws/typeorm-entities/dist/entities/lift/BillingContacts";
import { Nullable } from "@jamesgmarks/utilities";
import { useState } from "react";
import { useAppSelector } from "src/redux/hooks";

const NotesComponent = ({note} : {note: TBillingContactInternalNote}) => {
  const getFormatedDate = (date: string) => (
    `${new Date(date)}`
  );
  return (
    <Card raised  sx={{backgroundColor: '#f9c5ac', marginBottom: '16px'}}>
      <CardHeader
        title={note.name}
        titleTypographyProps={{fontSize: '14px' }}
        subheader={getFormatedDate(note.timestamp)}
        subheaderTypographyProps={{fontSize: '12px'}}
      />
      <CardContent sx={{paddingTop: 0, fontSize: '14px'}}>
        {note.note}
      </CardContent>
    </Card>
  );
};

export const InternalNotesBox = (
  { 
    internalNotes,
    onNoteAdd,
  } : {
    internalNotes: Nullable<TBillingContactInternalNote[]>,
    onNoteAdd: ((updatedInternalNotes: TBillingContactInternalNote[]) => void)
  },
) => {

  const [newNoteMessage, setNewNoteMessage] = useState('');
  const [currentInternalNotes, setCurrentInternalNotes] = useState(internalNotes);

  const token = useAppSelector(state => state.auth?.decodedToken);

  const createNewInternalNote = () => ({
    userId: token?.userId,
    name: token?.name ?? '',
    note: newNoteMessage,
    timestamp: new Date().toISOString(),
  });

  const handleNoteAdd = () => {
    const newInternalNote = createNewInternalNote();
    const updatedInternalNotes = [newInternalNote].concat(internalNotes ?? []);
    onNoteAdd(updatedInternalNotes);
    setCurrentInternalNotes(updatedInternalNotes);
    setNewNoteMessage('');
  };
  return (
    <Paper elevation={3}>
      <Box p={2}>
        {
          currentInternalNotes
          && <Box p={2} mb={2} sx={{ maxHeight: '200px', overflowY: 'auto', backgroundColor: '#28e9c4'}}>
            {(currentInternalNotes ?? []).map((internalNote, index) => (
              <NotesComponent
                key={(
                  `${internalNote.userId}_`
                  +`${internalNote.name}_`
                  +`${internalNote.timestamp}_`
                  +`${internalNote.userId}_$`
                  +`{index}`)
                }
                note={internalNote}
              />))
            }
          </Box>
        }
        <Box>
          <Box mb={2}>
            <TextField 
              fullWidth 
              size='small' 
              multiline 
              minRows={2}
              placeholder="Add a note here..."
              onChange={(e) => setNewNoteMessage(e.target.value)}
              value={newNoteMessage}
            />
          </Box>
          <Box sx={{ textAlign: 'right'}}>
            <Button variant="contained" color="primary" onClick={handleNoteAdd}>
              Add Note
            </Button>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};