import { createSlice } from '@reduxjs/toolkit';
import { IActionType } from '../../utils';

export type TRealTimeStatus = 'idle' | 'connected' | 'failed' | 'error';

export interface IRealtimeState {
  connectedState: TRealTimeStatus,
};

export const realtimeSlice = createSlice({
  name: 'realtime',
  initialState: {
    connectedState: 'idle',
  } as IRealtimeState,
  reducers: {
    setConnectedState: (state, action: IActionType<TRealTimeStatus>) => {
      state.connectedState = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setConnectedState,
} = realtimeSlice.actions;

export default realtimeSlice.reducer;
