import { Button, Card } from "react-bootstrap";
import { Chart } from "react-google-charts";
import { useAppSelector } from "../../../redux/hooks";
import { useEffect, useState } from "react";
import { loadTimeline } from "../../../redux/features/promotedListings/actions";
import { intVal, Nullable } from "@jamesgmarks/utilities";
import { IClientDropdownItem } from "../../../entity-interfaces/IClientDropdownItem";
import { Grid, TextField, TextFieldProps } from "@mui/material";
import { ClientDropdown } from "../../parts/ClientDropdown";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { IPromotedListingSegmentTimeline } from "../../../redux/features/promotedListings/interfaces";
import { assertMonthNumber } from "../../../interfaces/IYearMonth";

export const PromotedListingsSegmentTimeline = () => {

  const plTimeline= useAppSelector(state => state.promotedListings.segmentTimeline);

  const [searchClient, setSearchClient] = useState<Nullable<IClientDropdownItem>>(null);

  type HeaderColumnData = Partial<IPromotedListingSegmentTimeline> & {
    type?: string,
    id?: string
  }[];

  const [data, setData] = useState<HeaderColumnData[]>([]);

  const [shouldLoad, setShouldLoad] = useState(true);

  const [date, setDate] = useState(new Date());

  useEffect(() => {
    loadTimeline(
      searchClient?.freshbooksClientId ?? -1, {year: date.getFullYear(), month: assertMonthNumber(date.getMonth()+1)},
    );
    setShouldLoad(false);
  }, [shouldLoad]);

  useEffect(() => {
    const columns = [
      { type: "string", id: "BuildingID" },
      { type: "date", id: "Start" },
      { type: "date", id: "End" },
    ];
    const calculatedRows: HeaderColumnData = plTimeline.map(
      (time) => ({
        label: time.label,
        startDate: new Date(time.startDate),
        endDate: new Date(time.endDate),
        type: undefined,
        id: undefined,
      }),
    );
    setData([columns, calculatedRows]);
  }, [ plTimeline]);

  return (
    <>
      <h1>Promoted Listings Building Timeline</h1>
      <p>According to the PL billing calculator</p>
      <Card>

        <Grid container spacing={2} mt={2} justifyContent="center" alignItems="center">
          <Grid item xs={2}>Client:</Grid>
          <Grid item xs={5}>
            <ClientDropdown
              clientId={!(searchClient?.id) ? null : intVal(searchClient.id)}
              onClientChanged={
                (client) => {
                  setSearchClient(client);
                }
              }
              onClientFreeForm={(clientString) => { }}
            />

          </Grid>
          <Grid item xs>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat='yyyy-MM'
                label='Month'
                mask='____-__'
                onChange={(d: Date | null) => {
                  if (d) {
                    setDate(d);
                  }
                }}
                renderInput={(props: TextFieldProps) => <TextField sx={{ background: '#fff' }} {...props} />}
                showToolbar={false}
                value={date}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item mr={4}>
            <Button onClick={(e) => {
              setShouldLoad(true);
            }}>
              Update Timeline
            </Button>
          </Grid>
        </Grid>
      </Card>
      {
        <Chart chartType="Timeline" data={data} width="100%" height="1000px"  legendToggle/>
      }
    </>

  );
};
