import { ReplayCircleFilledOutlined } from "@mui/icons-material";
import { Checkbox, IconButton } from "@mui/material";
import React from "react";
import { StyledTableRow, StyledTableCell } from "src/components/parts/mui/StyledTables";
import { WebhookCallback } from "../../../../../entities/hydra";
import { makeLocaleDateTimeString } from "@hydra/internal";

export const WebhookCallbackRow = React.memo(({
  callbackData,
  checked,
  onToggle,
  onReplay,
}: {
  callbackData: WebhookCallback,
  checked: boolean,
  onToggle: (id: number) => void,
  onReplay: (id: number) => void
}) => (
  <StyledTableRow customSx={{ userSelect: 'none' }}
    dark={true}
    hover={true}>
    <StyledTableCell dark={true} style={{ whiteSpace: 'nowrap' }}>
      <Checkbox
        style={{color: 'white'}}
        checked={checked}
        onChange={() => onToggle(callbackData.id)}
      />
    </StyledTableCell>
    <StyledTableCell dark={true} style={{ whiteSpace: 'nowrap' }} onClick={() => onToggle(callbackData.id)}>
      {callbackData.id}
    </StyledTableCell>
    <StyledTableCell dark={true} style={{ whiteSpace: 'nowrap' }} onClick={() => onToggle(callbackData.id)}>
      {callbackData.name}
    </StyledTableCell>
    <StyledTableCell dark={true} style={{ whiteSpace: 'nowrap' }} onClick={() => onToggle(callbackData.id)}>
      {callbackData.objectId}
    </StyledTableCell>
    <StyledTableCell dark={true} style={{ whiteSpace: 'nowrap' }} onClick={() => onToggle(callbackData.id)}>
      {callbackData.sourceType}
    </StyledTableCell>
    <StyledTableCell dark={true} style={{ whiteSpace: 'nowrap' }} onClick={() => onToggle(callbackData.id)}>
      {callbackData.status}
    </StyledTableCell>
    <StyledTableCell dark={true} style={{ whiteSpace: 'nowrap' }} onClick={() => onToggle(callbackData.id)}>
      {makeLocaleDateTimeString(callbackData.created)}
    </StyledTableCell>
    <StyledTableCell dark={true} style={{ whiteSpace: 'nowrap' }} onClick={() => onToggle(callbackData.id)}>
      {callbackData.modified ? makeLocaleDateTimeString(callbackData.modified) : '-'}
    </StyledTableCell>
    <StyledTableCell dark={true} style={{ whiteSpace: 'nowrap' }} onClick={() => onToggle(callbackData.id)}>
      {callbackData.modifiedUserId ?? '-'}
    </StyledTableCell>
    <StyledTableCell dark={true}>
      <IconButton 
        color="secondary" 
        size="large" 
        onClick={() => onReplay(callbackData.id)}
        disabled={callbackData.status !== 'errored'}
      >
        <ReplayCircleFilledOutlined />
      </IconButton>
    </StyledTableCell>
  </StyledTableRow>
));