import { createSlice } from '@reduxjs/toolkit';
import { IApiQueryResponse } from '@llws/api-common';
import { IActionType } from '../../utils';
import { HubspotClientUpdateResponse } from './HubspotClientUpdateResponse';
import { arrayWrap, first } from '@jamesgmarks/utilities';

export interface IHubspotState {
  loadedState: string,
  updatedClients: HubspotClientUpdateResponse[],
  missingClientCount: number,
  sentDealInfo?: { success: boolean },
};

export const hubspotSlice = createSlice({
  name: 'hubspot',
  initialState: {
    loadedState: 'idle',
    updatedClients: [],
    missingClientCount: 0,
    updatedDealInfo: null,
  } as IHubspotState,
  reducers: {
    hubspotClientUpdatesReceived: (state, action: IActionType<IApiQueryResponse<HubspotClientUpdateResponse>>) => {
      state.updatedClients = arrayWrap(action.payload.data);
    },
    hubspotDealUpdateInfoReceived: (state, action: IActionType<IApiQueryResponse<{ success: boolean }>>) => {
      state.sentDealInfo = first(arrayWrap(action.payload.data))!;
    },
    setLoadedState: (state, action: { type: string, payload: string }) => {
      state.loadedState = action.payload;
    },
    setMissingClientCount: (state, action: IActionType<number>) => {
      state.missingClientCount = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoadedState,
  hubspotClientUpdatesReceived,
  hubspotDealUpdateInfoReceived,
  setMissingClientCount,
} = hubspotSlice.actions;

export default hubspotSlice.reducer;