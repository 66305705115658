import { useEffect, useState } from "react";

import { Button, Grid, Paper } from "@mui/material";

import { ProgressRing } from "../../parts/ProgressRing";
import { HHMonthPicker } from "../../parts/HHMonthPicker";

export const PdfGenerations = () => {
  const today = new Date();
  const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);
  const maxYear = monthStart.getFullYear();
  const maxMonth = monthStart.getMonth() + 1;
  const [ invoicingMonth, setInvoicingMonth ] = useState(monthStart);

  const [ seconds, setSeconds ] = useState(0);
  const [ lastUpdate, setLastUpdate ] = useState(Date.now());
  const [ progress, setProgress ] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setLastUpdate(Date.now());
      setSeconds(seconds => seconds + 10);
    }, 10000);
    const interval2 = setInterval(() => {
      const pg = (Date.now() - lastUpdate) / 5;
      setProgress(pg);
    }, 10);
    return () => { clearInterval(interval); clearInterval(interval2); };
  }, [ lastUpdate, seconds ]);

  return (<>
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={10}>
        <header className="Section-header">PDF Generations:</header>
      </Grid>
      <Grid item xs={10}>
        <Paper>
          <Grid container justifyContent="center" spacing={2}>
            <Grid component={Paper} item xs={5} style={{ textAlign: 'center' }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <h6>Seconds Cause its fun</h6>
                </Grid>
                <Grid item xs={12}>
                  <h4>{seconds}</h4>
                  <span>seconds</span>
                </Grid>
                <Grid item xs={12}>
                  <ProgressRing radius={20} stroke={3} strokeColor="red" progress={progress} />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      // updateHubspotClients();
                    }}
                  >Nothing...</Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid component={Paper} item xs={5} style={{ textAlign: 'center' }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <h6>Get Download Stats For Month:</h6>
                </Grid>
                <Grid item mt={2} xs={12}>
                  <HHMonthPicker
                    helperText={`Between 2017-01 and ${maxYear}-${maxMonth}`}
                    label='Invoicing Month'
                    onChange={
                      (d) => { d && setInvoicingMonth(d); }
                    }
                    maxDate={monthStart}
                    minDate={new Date(2017, 0)}
                    value={invoicingMonth}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {

                    }}
                  >Update</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  </>);
};
