import React from 'react';

import {
  Switch,
  Route,
} from "react-router-dom";
import { CommissionsTabView } from './CommissionsTabView';

export const CommissionsContainer = () => {
  return (
    <div>
      <header className="Section-header">Commissions</header>
      <Switch>
        <Route path="/commissions/commissions_tabs"><CommissionsTabView /></Route>
      </Switch>
    </div>
  );
};