import {
  BillingZoneCityOverrides,
  BillingZones, Subscriptions,
  SubscriptionsHasBuildings,
  VariableBasePriceOverrides,
  VariableBasePrices,
} from "@llws/typeorm-entities";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  BILLING_FEATURE_BASE_PRICE,
  BILLING_FEATURE_BILLING_ZONES,
  BILLING_FEATURE_COST_PER_LEAD_MAX,
  BILLING_FEATURE_CUSTOM_CONFIG,
  BILLING_FEATURE_LIFT_BUILDING_PRICES,
  BILLING_FEATURE_MANAGED_EXTERNALLY,
  BILLING_FEATURE_MIN_DURATION,
  BILLING_FEATURE_PROPERTY_LIST,
  BILLING_FEATURE_SUPERSYNC_URL,
  BILLING_FEATURE_VARIABLE_BASE_PRICE,
  TValidBillingFeature,
  assertValidBillingType,
  billingTypeHasFeature,
  billingTypesConfig,
  getBillingFeatureMetadata,
  getBillingFrequencies,
} from '@llws/hydra-shared';

import { getOne, getMany } from "src/redux/features/dynamic/actions";
import { useAppSelector } from "src/redux/hooks";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Checkbox,
  FormControlLabel,
  Grid, IconButton,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Button,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from '@mui/icons-material/Info';
import { ClientDropdown } from "src/components/parts/ClientDropdown";
import { BaseSubscriptionDropdown } from "src/components/parts/BaseSubscriptionDropdown";
import { HHDatePicker } from "src/components/parts/HHDatePicker";
import { Nullable, first, floatVal, intVal } from "@jamesgmarks/utilities";
import { IColumnConfig, HHSimpleTable, IFooterRowConfig } from "src/components/parts/HHSimpleTable";
import {
  IRangedVariableBasePrice,
  appendRangeToSortedPriceBracketsForSubscription,
  getSortedPriceBracketsForSubscription,
} from '@hydra/internal';
import { PropertyListModal } from "./EditorComponents/PropertyListModal";
import { CommissionEditor } from "./EditorComponents/CommissionEditor";
import { HHConfigEditor } from "src/components/parts/HHConfigEditor";
import { BillingZoneEditor } from "./EditorComponents/BillingZoneEditor";

export const SubscriptionEditor = () => {
  const { subscriptionId } = useParams<{ subscriptionId: string }>();
  const storeSubscription = useAppSelector((store) => store.dynamic.data.subscriptions?.single);
  const billingFrequencies = useAppSelector((store) => store.dynamic.data.billing_frequencies?.list);

  const [ subscription, setSubscription ] = useState<Subscriptions | null>(
    storeSubscription as unknown as Subscriptions ?? null,
  );
  const [ billingZonesState, setBillingZoneStates ] = useState<null>(
    null,
  );

  const [ openPropertyListModal, setOpenPropertyListModal ] = useState(false);

  const setSubscriptionProp = useCallback(<T extends keyof Subscriptions>(prop: T, newVal: Subscriptions[T]) => {
    if (!subscription) return;
    setSubscription({ ...subscription, [prop]: newVal });
  }, [ subscription, setSubscription ]);

  useEffect(() => setSubscription(storeSubscription as unknown as Subscriptions), [ storeSubscription ]);

  useEffect(() => {
    getOne('subscriptions', subscriptionId, [
      { path: 'client', alias: 'c' },
      { path: 'variableBasePriceOverrides', alias: 'vbpo'},
      { path: 'billingZoneCityOverrides', alias: 'bzco'},
      { path: 'bzco.ownershipGroup', alias: 'bzcoog'},
      { path: 'bzco.city', alias: 'bzcogc' },
      { path: 'bzcogc.province', alias: 'bzcogp' },
      { path: 'baseSubscription', alias: 'bs' },
      { path: 'bs.variableBasePrices', alias: 'vbp'},
      { path: 'bs.billingZones', alias: 'bz' },
      { path: 'bz.billingZoneCities', alias: 'bzc' },
      { path: 'bzc.city', alias: 'gc' },
      { path: 'gc.province', alias: 'gp' },
      { path: 'bs.service', alias: 'svc' },
      { path: 'bs.partner', alias: 'p' },
      { path: 'subscriptionsHasBuildings', alias: 'shb' },
      { path: 'shb.building', alias: 'shbb' },
      { path: 'parentSubscription', alias: 'ps' },
      { path: 'workInProgressQueues', alias: 'wipqs' },
      { path: 'wipqs.workInProgress', alias: 'wip' },
      { path: 'salesRep', alias: 'sr' },
      { path: 'parentSalesRep', alias: 'psr' },
      { path: 'sr.user', alias: 'u' },
      { path: 'psr.user', alias: 'pu' },
      { path: 'commissionSchemes', alias: 'cs' },
    ]);
    getMany('billing_frequencies');
  }, [ subscriptionId ]);

  const billingFeatureMetadata = useCallback((billingTypeId: number, feature: TValidBillingFeature, field: string) => (
    (getBillingFeatureMetadata(assertValidBillingType(billingTypeId), feature as any) as any)[field]
  ), []);

  const accountType = useMemo(() => (subscription?.handleBilling ? 'client' : 'partner'), [ subscription ]);

  const variableBaseBrackets = useMemo(() => {
    if (
      subscription
      && (
        subscription.baseSubscription.variableBasePrices.length > 0
        || subscription.variableBasePriceOverrides.length > 0
      )
    ) {
      const subData = {
        baseSubscription: { variableBasePrices: subscription.baseSubscription.variableBasePrices.slice() },
        variableBasePriceOverrides: subscription.variableBasePriceOverrides.slice(),
      };
      const sortedVariablePriceData = getSortedPriceBracketsForSubscription(subData);
      return appendRangeToSortedPriceBracketsForSubscription(sortedVariablePriceData);
    }
    return [];
  }, [subscription]);

  const variablePriceBracketsColumnsConfig: IColumnConfig<IRangedVariableBasePrice>[]= useMemo(() => (
    [
      {
        headerLabel: 'Range',
        fieldName: 'range',
      },
      {
        headerLabel: 'Base Price',
        fieldName: 'basePrice',
        format: (value) => `$${value}`,
      },
      {
        headerLabel: 'Cost Per Unit',
        fieldName: 'costPerUnit',
        format: (value) => `$${value}`,
      },
    ]
  ), []);

  const subscriptioHasBuildingsColumnsConfig: IColumnConfig<SubscriptionsHasBuildings>[]= useMemo(() => (
    [
      {
        headerLabel: 'Building',
        fieldName: 'building',
        render: (data: SubscriptionsHasBuildings) => (
          <Tooltip
            title={data.building.streetNumber + ' ' + data.building.streetName}
            placement="top-start"
          >
            <div>
              {data.building.buildingName}
            </div>
          </Tooltip>

        ),
      },
      {
        headerLabel: 'Price',
        fieldName: 'price',
        format: (value) => `$${value}`,
        render: (data: SubscriptionsHasBuildings) => (
          <TextField
            type="number"
            sx={{background: "white"}}
            size="small"
            value={data.price ?? ''}
            onChange={
              (e) => {
                if (subscription) {
                  setSubscriptionProp(
                    'subscriptionsHasBuildings',
                    subscription.subscriptionsHasBuildings.map((shb) => ({
                      ...shb,
                      price: shb.id === data.id ? e.target.value : shb.price,
                    })),
                  );
                }
              }
            }
            inputProps={{
              step: 0.01,
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        ),
      },
      {
        headerLabel: '',
        fieldName: 'created',
        headerRender: () => (
          <IconButton
            onClick={() => setOpenPropertyListModal(true) }
          >
            <AddIcon
              sx={{
                backgroundColor: 'white',
                borderRadius: '5px',
                color: 'black',
                cursor: 'pointer',
                fontSize: '2rem',
                padding: 1,
                '&:hover': { opacity: 0.8 },
              }}
            />
          </IconButton>
        ),
        render: (data: SubscriptionsHasBuildings) => (
          <IconButton
            onClick={() => console.log(true) } //remove console log once editing in place
          >
            <DeleteIcon
              sx={{
                backgroundColor: 'crimson',
                borderRadius: '5px',
                color: 'white',
                cursor: 'pointer',
                fontSize: '2rem',
                padding: 1,
                '&:hover': { opacity: 0.8 },
              }}
            />
          </IconButton>
        ),
      },
    ]
  ), [subscription, setSubscriptionProp]);

  const shbFooterConfig: IFooterRowConfig<SubscriptionsHasBuildings> = useMemo(() => ({
    cells: [
      {
        label: 'Total',
      },
      {
        calculate: (rows: SubscriptionsHasBuildings[]) => (
          rows.reduce((sum, shb) => sum + floatVal(shb.price ?? 0), 0).toFixed(2)
        ),
      },
      {
        label: '',
      },
    ],
  }), []);

  const subscriptionFeeType = useMemo(
    () => subscription?.billingFrequencyId === 1 ? 'ONE_TIME' : 'RECURRING',
    [ subscription ],
  );

  const wipQueue = useMemo(
    () => subscription?.workInProgressQueues?.[0],
    [ subscription ],
  );

  const billingTypeId = useMemo(() => (subscription?.baseSubscription?.service?.billingTypeId), [ subscription ]);

  const billingFrequenciesOptions = useMemo(() => {
    if (billingTypeId) {
      const availableFrequencies = getBillingFrequencies(billingTypeId);
      return availableFrequencies.map((bf) => (
        {
          value: bf,
          label: billingFrequencies?.find((billingFrequncy) => billingFrequncy.id === bf)?.name ?? 'Not Available',
        }
      ));
    } return [];
  }, [billingFrequencies, billingTypeId]);

  if (!billingTypeId || !subscription) {
    return <>Loading...</>;
  }

  if (billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_MANAGED_EXTERNALLY)) {
    return <Grid container spacing={2} mt={2} mr={2}  justifyContent="center" alignItems="center">
      <Grid item xs={10}>
        <Paper variant='outlined' sx={{padding: '1rem', backgroundColor: '#e0e0e0'}}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <p> This feature is managed somewhere else</p>
            {
              billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_SUPERSYNC_URL)
              && <>

                <a href={billingFeatureMetadata(billingTypeId, BILLING_FEATURE_SUPERSYNC_URL, 'url')}>
                  Go to SuperSync
                </a>
              </>
            }
          </Grid>
        </Paper>
      </Grid>
    </Grid>;
  }

  return <>
    <Grid container spacing={2} mt={2} mr={2}  justifyContent="center" alignItems="center">
      <Grid item xs={10}>
        <Paper variant='outlined' sx={{padding: '1rem', backgroundColor: '#e0e0e0'}}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <strong>Subscription Editor ({billingTypeId})</strong>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <div>Account:</div>
              <div>
                <ClientDropdown
                  clientId={subscription.clientId}
                  onClientChanged={(client) => {
                    if (client && (client.id !== subscription.clientId)) {
                      setSubscriptionProp('clientId', client.id);
                    }
                  }}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <BaseSubscriptionDropdown
                label='Product'
                baseSubscriptionId={subscription.baseSubscriptionId}
                onBaseSubscriptionChanged={(bs) => {
                  // TODO: Handle null selection.
                  if (bs?.id) { setSubscriptionProp('baseSubscriptionId', bs.id); }
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={'PO Number'}
                type="text"
                sx={{background: "white"}}
                size="small"
                value={subscription.poNumber ?? ''}
                onChange={(e) => setSubscriptionProp('poNumber', e.target.value || null)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={'Quote ID'}
                type="text"
                sx={{background: "white"}}
                size="small"
                value={subscription.quoteId ?? ''}
                onChange={(e) => setSubscriptionProp('quoteId', e.target.value || null)}
              />
            </Grid>
            <Grid item xs={6}>
              <HHDatePicker
                label="Rate Expiry Date"
                value={subscription.rateExpiryDate}
                onChange={(d: Date | null) => { if (d) { setSubscriptionProp('rateExpiryDate', d); } }}
              />

            </Grid>
            <Grid item xs={6}>
              Quote: <input type="file" />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Min Properties Rate"
                type="number"
                inputProps={{
                  step: 0.01,
                }}
                sx={{background: "white"}}
                size="small"
                value={subscription.minPropertiesForRate ?? ''}
                onChange={(e) => (
                  setSubscriptionProp('minPropertiesForRate', e.target.value ? floatVal(e.target.value) : null)
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Max Properties Rate"
                type="number"
                inputProps={{
                  step: 0.01,
                }}
                sx={{background: "white"}}
                size="small"
                value={subscription.maxPropertiesForRate ?? ''}
                onChange={(e) => (
                  setSubscriptionProp('maxPropertiesForRate', e.target.value ? floatVal(e.target.value) : null)
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Invoice Description"
                type="text"
                sx={{background: "white"}}
                size="small"
                fullWidth
                value={subscription.invoiceDescription}
                onChange={(e) => setSubscriptionProp('invoiceDescription', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} >
              {
                billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_BASE_PRICE)
                && <Grid item container>
                  <Grid item xs={6}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <label style={{paddingRight: '1rem'}}>Base Price: </label>
                      <TextField
                        label={
                          billingFeatureMetadata(billingTypeId, BILLING_FEATURE_BASE_PRICE, 'label') ?? 'Base Price'
                        }
                        type="number"
                        sx={{background: "white"}}
                        size="small"
                        value={`${subscription.basePrice ?? ''}`}
                        onChange={(e) => setSubscriptionProp('basePrice', e.target.value || '0')}
                        disabled={
                          billingFeatureMetadata(billingTypeId, BILLING_FEATURE_BASE_PRICE, 'condition')
                            ? billingFeatureMetadata(
                              billingTypeId, BILLING_FEATURE_BASE_PRICE, 'condition',
                            )(subscription)
                            : false

                        }
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </span>
                  </Grid>
                  {
                    //TODO: should update the config
                    !!billingFeatureMetadata(billingTypeId, BILLING_FEATURE_BASE_PRICE, 'hasScalar')
                && <Grid item xs={6}>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{paddingRight: '1rem'}}>Base Price Scalar: </label>
                    <TextField
                      label={billingFeatureMetadata(billingTypeId, BILLING_FEATURE_BASE_PRICE, 'scalarLabel')}
                      type="number"
                      sx={{background: "white", marginRight: '0.5rem'}}
                      inputProps={{
                        step: 0.1,
                      }}
                      size="small"
                      value={`${subscription.basePriceScalar ?? ''}`}
                      onChange={(e) => setSubscriptionProp('basePriceScalar', e.target.value || '0')}
                    />
                    <Tooltip
                      title={
                        `Base Price(${subscription.basePrice}) * Base Price Scalar(${subscription.basePriceScalar}) =
                         ${(floatVal(subscription.basePrice ?? 0) * floatVal(subscription.basePriceScalar)).toFixed(2)}`
                      }
                      placement='right'
                    >
                      <InfoIcon />
                    </Tooltip>
                  </span>
                </Grid>
                  }

                </Grid>
              }
            </Grid>
            <Grid item xs={12}>
              { getBillingFrequencies(billingTypeId).length > 0
             &&
              <FormControl sx={{width: '50%', background: "white", marginRight: '0.5rem'}}>
                <InputLabel id="billing-frequency-label">Billing Frequency</InputLabel>
                <Select
                  labelId="billing-frequency-label"
                  value={subscription.billingFrequencyId}
                  onChange={e => setSubscriptionProp('billingFrequencyId', intVal(e.target.value))}
                  label="Billing Frequency"
                  size="small"
                >
                  {billingFrequenciesOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              }
            </Grid>
            <Grid item xs={12}>
              <CommissionEditor subscription={subscription} setSubscriptionProp={setSubscriptionProp} />
            </Grid>
            <Grid item xs={12}>

              <BillingZoneEditor subscription={subscription} setSubscriptionProp={setSubscriptionProp} />
            </Grid>
            <Grid item xs={12}>
              {
                billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_COST_PER_LEAD_MAX)
                && <>
                  <TextField
                    label="Cost Per Lead Max"
                    type="number"
                    inputProps={{
                      step: 0.01,
                    }}
                    sx={{background: "white"}}
                    size="small"
                    value={`${subscription.costPerLeadMax ?? ''}`}
                    onChange={(e) => setSubscriptionProp('costPerLeadMax', e.target.value || '0')}
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                  />
                </>
              }
            </Grid>
            <Grid item xs={12}>
              {
                billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_VARIABLE_BASE_PRICE)
                    && <>
                      <label htmlFor="variable base price">
                        <h4>
                          { `${billingFeatureMetadata(billingTypeId, BILLING_FEATURE_VARIABLE_BASE_PRICE, 'label')} ` }
                          Variable Base Price{ subscription.variableBasePriceOverrides?.length ? ' Overrides': 's'}
                        </h4>
                      </label>
                      <HHSimpleTable<IRangedVariableBasePrice>
                        rows={variableBaseBrackets}
                        columns={variablePriceBracketsColumnsConfig}
                        rowHover={false}
                      />
                    </>

              }
            </Grid>
            <Grid item xs={12}>
              {
                billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_MIN_DURATION)
                    && <>
                      <TextField
                        label="Min Duration"
                        type="number"
                        inputProps={{
                          step: 0.01,
                        }}
                        sx={{background: "white"}}
                        size="small"
                        value={`${subscription.minDuration ?? ''}`}
                        onChange={(e) => setSubscriptionProp('minDuration', intVal(e.target.value || '0'))}
                        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                      /></>
              }
            </Grid>
            <Grid item xs={12}>
              {
                billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_LIFT_BUILDING_PRICES)
        && <>
          <TextField
            label="Min Duration"
            type="number"
            inputProps={{
              step: 0.01,
            }}
            sx={{background: "white"}}
            size="small"
            value={`${subscription.liftSubscriptions?.costPerSingleFamilyHome ?? ''}`}
            onChange={(e) => (
              setSubscriptionProp(
                'liftSubscriptions',
                {...subscription.liftSubscriptions, costPerSingleFamilyHome: (e.target.value || '0')},
              )
            )}
            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
          />
          <TextField
            label="Min Duration"
            type="number"
            inputProps={{
              step: 0.01,
            }}
            sx={{background: "white"}}
            size="small"
            value={`${subscription.liftSubscriptions?.costPerMultiFamilyHome ?? ''}`}
            onChange={(e) => setSubscriptionProp(
              'liftSubscriptions',
              {...subscription.liftSubscriptions, costPerMultiFamilyHome: (e.target.value || '0')},
            )}
            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
          />

        </>
              }
            </Grid>
            <Grid item xs={12}>
              {
                billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_PROPERTY_LIST)
        && <>
          <label htmlFor="building-list">
            <h4>Buildings: Rentsync Buildings</h4>
          </label>
          {
            billingFeatureMetadata(billingTypeId, BILLING_FEATURE_PROPERTY_LIST, 'allowIndividualPropertyPricing')
            &&
            <>
              <HHSimpleTable<SubscriptionsHasBuildings>
                rows={subscription.subscriptionsHasBuildings ?? []}
                columns={subscriptioHasBuildingsColumnsConfig}
                rowHover={false}
                footer={shbFooterConfig}
                scrollable={true}
                maxHeight="600px"
              />
              <PropertyListModal
                open={openPropertyListModal}
                accountId={subscription.client.freshbooksClientId ?? 0}
                setOpen={setOpenPropertyListModal}
                onConfirm={
                  (buildingId, price) => {
                    //remove console log once editing in place
                    console.log(buildingId, price);
                  }
                }
                buildingStatuses={['deleted']}
                excludeStatus
              />
            </>
          }
          {
            billingFeatureMetadata(billingTypeId, BILLING_FEATURE_PROPERTY_LIST, 'allowEvenBuildingSplit')
            &&
            <Box>
              <FormControlLabel
                label="Enable even building split"
                control={
                  <Checkbox
                    checked={subscription.evenBuildingSplit}
                    onChange={(e) => setSubscriptionProp('evenBuildingSplit', e.target.checked)}
                  />
                }
              />
            </Box>
          }
        </>
              }
            </Grid>
            <Grid item xs={12}>
              {
                !billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_CUSTOM_CONFIG)
        && <>
          <h4>Custom Configuration</h4>
          <HHConfigEditor customConfig={subscription.customConfiguration ?? {}} />
        </>
              }
            </Grid>
            <Grid item xs={12}>
              {
                billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_VARIABLE_BASE_PRICE)
        && <TextField
          label="Variable Base Price"
          type="text"
          sx={{background: "white"}}
          size="small"
          value={`${subscription.basePrice ?? ''}`}
          onChange={(e) => setSubscriptionProp('basePrice', e.target.value || '0')}
          InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
        />
              }
            </Grid>

            {/* <pre>
              {JSON.stringify(subscription, null, 2)}
            </pre> */}
          </Grid>
          <hr/>
          <Button
            variant="contained"
            onClick={(e) => {
              alert('i didnt really save anything');
            }}
          >Save Subscription</Button>
          <Button
            variant="contained"
            onClick={(e) => {
              alert('i didnt really cancel anything');
            }}
          >Cancel</Button>
          <hr/>
        </Paper>
      </Grid>
    </Grid>
  </>;
};
