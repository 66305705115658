import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { TextField, TextFieldProps } from "@mui/material";

export const HHDatePicker = ({
  label,
  value,
  onChange,
}: {
  label: string,
  value: Date | null,
  onChange: (date: Date | null, keyboardInputValue?: string | undefined) => void,
}) => {
  return <>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        inputFormat='yyyy-MM-dd'
        label={label}
        mask='____-__-__'
        onChange={onChange}
        renderInput={
          (props: TextFieldProps) => (
            <TextField
              size="small"
              label={label}
              sx={{ background: '#fff' }}
              {...props}
            />
          )
        }
        showToolbar={false}
        value={value}
      />
    </LocalizationProvider>
  </>;
};
