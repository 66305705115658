import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

// import IFbInvoice from '../../../../../freshbooks-api/models/IInvoices';
import { useAppSelector } from "../../../redux/hooks";
import { loadCurrentFreshbooksInvoice, updateFreshbooksInvoiceAddress } from '../../../redux/features/invoices/actions';
// import { useQuery } from "../../../customHooks/useQuery";
import { useParams } from "react-router-dom";
import { Hash, intVal } from "@jamesgmarks/utilities";
// import { Link } from "react-router-dom";
import { FreshbooksInvoiceLink } from '../../parts/freshbooks/FreshbooksInvoiceLink';

const getInvoiceAddressState = (invoice?: Hash) => ({
  organization: invoice?.organization,
  address: invoice?.address,
  street: invoice?.street,
  street2: invoice?.street2,
  city: invoice?.city,
  province: invoice?.province,
  country: invoice?.country,
  code: invoice?.code,
  dueOffsetDays: invoice?.dueOffsetDays,
});

export const FreshbooksInvoice = () => {
  let { invoiceId } = useParams<{ invoiceId?: string }>();

  const invoice = useAppSelector(state => state.invoices.currentFreshbooksInvoice);
  const isDeveloper = useAppSelector(state => state.auth?.isDeveloper);

  loadCurrentFreshbooksInvoice({ invoiceId: invoiceId ?? '', forceRefresh: false });

  const [ address, setAddress ] = useState(getInvoiceAddressState(invoice));

  useEffect(() => {
    setAddress(getInvoiceAddressState(invoice));
  }, [ invoice ]);

  return (
    <>
      <Container>
        <h2>Change Billing Address on Freshbooks Invoice</h2>
        <FreshbooksInvoiceLink freshbooksInvoiceId={`${invoiceId}`} label='View Invoice on Freshbooks' />
        <Form
          onSubmit={(e) => {
            updateFreshbooksInvoiceAddress(intVal(invoiceId), address);
            e.preventDefault();
          }}
        >
          <Form.Group as={Row} controlId="organization">
            <Form.Label column sm="2">Organization</Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                placeholder="Organization"
                value={address.organization ?? ''}
                onChange={(e) => setAddress({ ...address, organization: e.target.value })}
              />
            </Col>
          </Form.Group>
          {/* <Form.Group as={Row} controlId="address">
            <Form.Label column sm="2">Addressee</Form.Label>
            <Col sm="10">
              <Form.Control type="text" placeholder="Addressee" value={address.address ?? ''} onChange={(e) => setAddress({ ...address, address: e.target.value })} />
            </Col>
          </Form.Group> */}
          <Form.Group as={Row} controlId="street">
            <Form.Label column sm="2">Street Line 1</Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                placeholder="Street Line 1"
                value={address.street ?? ''}
                onChange={(e) => setAddress({ ...address, street: e.target.value })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="street2">
            <Form.Label column sm="2">Street Line 2</Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                placeholder="Street Line 2"
                value={address.street2 ?? ''}
                onChange={(e) => setAddress({ ...address, street2: e.target.value })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="city">
            <Form.Label column sm="2">City</Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                placeholder="City"
                value={address.city ?? ''}
                onChange={(e) => setAddress({ ...address, city: e.target.value })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="province">
            <Form.Label column sm="2">Province</Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                placeholder="Province"
                value={address.province ?? ''}
                onChange={(e) => setAddress({ ...address, province: e.target.value })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="country">
            <Form.Label column sm="2">Country</Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                placeholder="Country"
                value={address.country ?? ''}
                onChange={(e) => setAddress({ ...address, country: e.target.value })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="code">
            <Form.Label column sm="2">Code</Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                placeholder="Code"
                value={address.code ?? ''}
                onChange={(e) => setAddress({ ...address, code: e.target.value })}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="dueOffsetDays">
            <Form.Label column sm="2">Due</Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                placeholder="Due
                Offset" value={address.dueOffsetDays ?? ''}
                onChange={(e) => setAddress({ ...address, dueOffsetDays: e.target.value })}
              />
            </Col>
          </Form.Group>

          <Button variant="primary" type="submit">
            Update Freshbooks Address
          </Button>
        </Form>
        {
          isDeveloper
          && <div>
            <pre>{JSON.stringify(invoice, null, 2)}</pre>
          </div>
        }
      </Container>
    </>
  );
};