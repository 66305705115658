import { SyntheticEvent, useState } from "react";

import {
  Box,
  Card,
  Dialog,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';

import { Nullable, strVal } from "@jamesgmarks/utilities";

import { BillingProfileUpdateForm } from "./BillingProfileUpdateForm";
import { LiftClient } from "../../../../../entities/hydra";
import { loadCurrentHydraClient } from "../../../redux/features/clients/actions";

interface IBillingProfileTableProps {
  client: Nullable<LiftClient>
}

export const BillingProfileCard = ({ client }: IBillingProfileTableProps) => {
  const [ modalOpen, setModalOpen ] = useState(false);
  
  const billingProfile = client?.billingAccount.billingProfile;

  return (
    <Box m='auto'>
      <Card sx={{ borderRadius: '20px', p: 2, pt: 1.5 }}>
        <Grid
          item
          container
          mb={1}
          ml={2}
        >
          <Grid item xs>
            <Typography sx={{ color: 'gray', fontSize: '0.8rem' }}>
              <u>Billing Profile</u>
            </Typography>
          </Grid>

          <Grid
            item
            mr={3}
            onClick={(_e: SyntheticEvent<HTMLDivElement>) => setModalOpen(true)}
            sx={{
              '&:hover': {
                backgroundColor: '#f5f5f5',
                color: 'dodgerblue',
                borderRadius: 1,
                opacity: 0.8,
                cursor: 'pointer',
              },
            }}
          >
            <Tooltip arrow title='Edit' placement='left'>
              <Box p={0.4}>
                <EditTwoToneIcon />
              </Box>
            </Tooltip>
          </Grid>
        </Grid>

        {billingProfile?.organization && <h4>{billingProfile?.organization}</h4>}
        {billingProfile?.street1.trim() && <div>{billingProfile?.street1}</div>}
        {billingProfile?.street2.trim() && <div>{billingProfile?.street2}</div>}
        <Box>
          {billingProfile?.city && `${billingProfile?.city}`}
          {billingProfile?.province && `, ${billingProfile?.province}`}
          {billingProfile?.code && `, ${billingProfile?.code}`}
          {billingProfile?.country && `, ${billingProfile?.country}`}
        </Box>
        <Box mt={1} sx={{ fontSize: '0.8rem' }}>
          {billingProfile?.contactName.trim() && <div><b>Contact name</b>: {billingProfile?.contactName}</div>}
          {billingProfile?.contactPhone && <div><b>Contact phone</b>: {billingProfile?.contactPhone}</div>}
          {billingProfile?.email && <div><b>Email</b>: {billingProfile?.email}</div>}
        </Box>
      </Card>
  
      <div>
        <Dialog
          fullWidth
          maxWidth={'sm'}
          onClose={() => setModalOpen(false) }
          open={modalOpen}
        >
          <IconButton
            aria-label="close"
            onClick={() => setModalOpen(false)}
            sx={{
              color: (theme) => theme.palette.grey[500],
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>

          {
            client 
              ? (
                <BillingProfileUpdateForm
                  afterSubmit={
                    () => {
                      loadCurrentHydraClient(strVal(client.clientId));
                      setModalOpen(false);
                    }
                  }
                  billingProfile={client.billingAccount.billingProfile}
                />
              )
              :
              <Typography variant='h6'>Loading client...</Typography>
          }
        </Dialog>
      </div>
    </Box>
  );
};
  