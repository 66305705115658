import { first, Hash, intVal, Nullable } from "@jamesgmarks/utilities";
import React, { useState } from "react";
import { Button, Col } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { ClientDropdown } from "../../../../parts/ClientDropdown";
import { RequirePermission } from "../../../../parts/RequirePermission";
import { useAuth } from "../../../../../customHooks/useAuth";

export const MonthlySubscriptionBreakdownSearchForm = (
  {
    currencyCode,
    applyFilters,
    applyOptionalFields,
  }
  : {
    currencyCode: string,
    applyFilters: (filters: {
      currencyCode: string,
      clientName?: string,
      itemName?: string,
      invoiceNumber?: string,
      isPartnerInvoiced?: boolean,
      hideZeroSubtotal?: boolean,
      hideZeroDiscountedTotal?: boolean,
    }) => void,
    applyOptionalFields: (fields: string[]) => void,
  },
) => {
  const [filters, setFilters] = useState({
    itemName: '',
    invoiceNumber: '',
    hideZeroSubtotal: true,
    hideZeroDiscountedTotal: true,
  } as Hash);
  const [searchClientId, setSearchClientId] = useState(null as Nullable<number>);
  const [ searchClientName, setSearchClientName ] = useState<string | undefined>(undefined);
  const [optionalFields, setOptionalFields] = useState(['GSID', 'Currency'] as string[]);
  const [showTaxesInColumns, setShowTaxesInColumns] = useState(true);

  const { hasPermission } = useAuth();

  const toggleOptionalField = (key: string) => {
    const currentlySelected = optionalFields.includes(key);
    const newFields = [
      ...optionalFields.filter(k => k !== key),
      ...(!currentlySelected ? [ key ] : []),
    ];
    setOptionalFields(newFields);
    applyOptionalFields(newFields);
  };

  const optionalFieldsList = {
    ...(hasPermission('FULL_ACCESS') ? { 'GSID': 'GSID' } : {}),
    'Commissions': 'Commissions',
    'TaxBreakdowns': 'Tax Breakdowns',
    'Currency': 'Currency',
  } as Hash;

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    setFilters((oldFilters) => ({
      ...oldFilters,
      [e.target.name]: e.target.value,
    }));
  };

  return <Form onSubmit={(e) => {
    e.preventDefault();
    applyFilters({ currencyCode, ...filters, clientName: searchClientName });
  }}>
    <Form.Row >
      <Form.Label column xs="2" style={{textAlign: 'left'}}>Client</Form.Label>
      <Col>
        <ClientDropdown
          allowFreeform
          clientId={searchClientId}
          onClientChanged={
            (client) => {
              setSearchClientId(client ? intVal(client.id) : null);
            }
          }
          onClientFreeForm={
            (clientString) => {
              setSearchClientName(first((clientString ?? '').split(' (Client ID: ')) ?? undefined);
            }
          }
        />
      </Col>
    </Form.Row>
    <Form.Row >
      <Form.Label column xs="2" style={{textAlign: 'left'}}>Item Name</Form.Label>
      <Col xs="auto">
        <Form.Control
          id={`itemNameFilter${currencyCode}`}
          name="itemName"
          value={filters.itemName}
          placeholder="Item Name"
          onChange={handleFilterChange}
        />
      </Col>
    </Form.Row>
    <Form.Row >
      <Form.Label column xs="2" style={{textAlign: 'left'}}>Invoice Number</Form.Label>
      <Col xs="auto">
        <Form.Control
          id={`invoiceNumberFilter${currencyCode}`}
          name="invoiceNumber"
          value={filters.invoiceNumber}
          placeholder="Invoice Number"
          onChange={handleFilterChange}
        />
      </Col>
    </Form.Row>
    <RequirePermission grant="FULL_ACCESS">
      <Form.Row style={{ marginTop: 15 }} >
        <Col xs="auto">
          <Form.Check
            type="checkbox"
            id={`isPartnerInvoiced${currencyCode}`}
            name="isPartnerInvoiced"
            checked={filters.isPartnerInvoiced ?? false}
            value="yes"
            label="Partner Invoiced Only?"
            onChange={
              (e) => setFilters((oldFilters) => ({ ...oldFilters, isPartnerInvoiced: e.target.checked }))
            }
          />
        </Col>
      </Form.Row>
      <Form.Row >
        <Col xs="auto">
          <Form.Check
            type="checkbox"
            id={`hideZeroSubtotal${currencyCode}`}
            name="hideZeroSubtotal"
            checked={filters.hideZeroSubtotal ?? false}
            value="yes"
            label="Hide Zero Subtotal?"
            onChange={(e) => setFilters((oldFilters) => ({ ...oldFilters, hideZeroSubtotal: e.target.checked }))}
          />
        </Col>
      </Form.Row>
      <Form.Row >
        <Col xs="auto">
          <Form.Check
            type="checkbox"
            id={`hideZeroDiscountedTotal${currencyCode}`}
            name="hideZeroDiscountedTotal"
            checked={filters.hideZeroDiscountedTotal ?? false}
            value="yes"
            label="Hide Zero Discounted Total?"
            onChange={(e) => setFilters((oldFilters) => ({ ...oldFilters, hideZeroDiscountedTotal: e.target.checked }))}
          />
        </Col>
      </Form.Row>
      <Form.Row >
        <Col xs="auto">
          <Form.Check
            type="checkbox"
            id={`showTaxesInColumns${currencyCode}`}
            name="showTaxesInColumns"
            checked={showTaxesInColumns ?? false}
            value="yes"
            label="Show Taxes in Separate Columns?"
            onChange={e => setShowTaxesInColumns(!showTaxesInColumns)}
          />
        </Col>
      </Form.Row>
    </RequirePermission>
    <Form.Row style={{ marginTop: 15 }}>
      <Col xs="auto">
        <div className="mb-3">
          <strong>Optional Fields: </strong>
          {Object.keys(optionalFieldsList).map((k) => {
            return <Form.Check
              key={k}
              custom
              inline
              label={optionalFieldsList[k] ?? false}
              type={`checkbox`}
              id={`optional-field-${k.toLowerCase()}-${currencyCode}`}
              checked={optionalFields.includes(k)}
              onChange={(e) => { toggleOptionalField(k); }}
            />;
          })}
        </div>
      </Col>
    </Form.Row>
    <Form.Row >
      <Col xs="auto">
        <Button variant="primary" type="submit">Search</Button>
      </Col>
      <Col></Col>
    </Form.Row>
  </Form>;
};
