import { Link, Route, Switch } from "react-router-dom";

import { DataIntegrityErrors } from "./DataIntegrityErrors/DataIntegrityErrors";
import { ComponentPlayground } from "./ComponentPlayground";
import { PromotedListingsSegmentTimeline} from "./PromotedListingsSegmentTimeline";
import { PaymentCompleted } from "./PaymentsPlayground/PaymentCompleted";
import { StripeContainer } from "./PaymentsPlayground/StripeContainer";

import { FreshbooksAuthorization } from "./FreshbooksAuthorization";
import { Info } from "./Info";
import { RunSandbox } from "./sandbox/RunSandbox";
import { Sandbox } from "./sandbox/Sandbox";
import { SocketViewer } from "./SocketViewer";
import { PdfGenerations } from "./PdfGenerations";
import { ApiValidators } from "./ApiValidators";
import { XeroAuthorization } from "./XeroAuthorization";
import { WebhookCallbackManagement } from "../WebhookCallbacks/WebhookCallbackManagement";

export const Developer = () => (
  <>
    <header className="Section-header">Developer Control Panel</header>
    <Switch>
      <Route path="/developer/api_validators"><ApiValidators /></Route>
      <Route path="/developer/component_playground"><ComponentPlayground /></Route>
      <Route path="/developer/data_integrity_errors"><DataIntegrityErrors /></Route>
      <Route path="/developer/pdf_generations"><PdfGenerations /></Route>
      <Route path="/developer/promoted_listings_segment_timeline"><PromotedListingsSegmentTimeline /></Route>
      <Route path="/developer/freshbooks_authorize"><FreshbooksAuthorization /></Route>
      <Route path="/developer/xero_authorize"><XeroAuthorization /></Route>
      <Route path="/developer/info"><Info /></Route>
      <Route path="/developer/sandbox/run/:sandboxName"><RunSandbox /></Route>
      <Route path="/developer/sandbox"><Sandbox /></Route>
      <Route path="/developer/payment_playground"><StripeContainer /></Route>
      <Route path="/developer/payment_completed"><PaymentCompleted /></Route>
      <Route path="/developer/webhook_callback_management"><WebhookCallbackManagement/></Route>
      <Route path="/developer/sockets"><SocketViewer /></Route>
      <Route path="/developer">
        <Link to='/developer/freshbooks_authorize'>Freshbooks Authorize</Link>
        <br></br>
        <Link to='/developer/xero_authorize'>Xero Authorize</Link>
        <br></br>
        <Link to='/developer/component_playground'>Component Playground</Link>
      </Route>
    </Switch>
  </>
);
