import { arrayWrap, HashOf, Nullable } from '@jamesgmarks/utilities';
import { IApiCommandResponse, IApiQueryResponse } from '@llws/api-common';

import { typedApiFetch } from '../../utils';
import {
  dataIntegrityErrorFixed,
  EDataIntegrityChecksLoadedState,
  postcheckErrorListReceived,
  postcheckIgnored,
  postcheckNoLongerIgnored,
  preAndPostcheckNamesReceived,
  precheckErrorListReceived,
  precheckIgnored,
  precheckNoLongerIgnored,
  setPostcheckLoadState,
  setPrecheckLoadState,
} from './dataIntegrityChecksSlice';
import { IgnoredDataIntegrityError } from '../../../../../entities/hydra';
import { dispatch } from '../../store';
import { EIgnoredDataIntegrityErrorType } from '../../../interfaces/EIgnoredDataIntegrityError';
import { IDataIntegrityCheckError, IDataIntegrityChecksResponse, TFixFnArgumentOption } from './interfaces';
import { IYearMonth } from '../../../interfaces/IYearMonth';
import { performApiActionRequest, TActionResponse } from '../../apiActionRequest';
import { REACT_APP_API_ROOT_URI } from '../../../App';
import { showMessage } from '../messaging/actions';
import { capitalize } from '../../../app-utils/helpers';

type TPreAndPostCheckNamesResponse = {
  precheckNames: string[],
  postcheckNames: string[],
}

export const getPreAndPostCheckNames = async() => {
  const { data } = await performApiActionRequest<
  TActionResponse<TPreAndPostCheckNamesResponse>
  >(
    'preAndPostcheckFetchNames',
    {},
  ) as IApiQueryResponse<TActionResponse<TPreAndPostCheckNamesResponse>>;
  
  const [ singularData ] = arrayWrap(data);
  dispatch(preAndPostcheckNamesReceived(singularData.actionResponse));
};
  
export const runSelectPreOrPostChecks = async (
  yearMonth: IYearMonth,
  runType: EIgnoredDataIntegrityErrorType,
  checksToRun: string[],
  runAsJob: boolean = false,
) => {
  try {    
    if (runType === 'precheck') {
      dispatch(setPrecheckLoadState(EDataIntegrityChecksLoadedState.loading));
      const checkAsJob = (
        runAsJob 
          ? await performApiActionRequest(
            'dispatchAction',
            { actionName: 'precheck', parameters: {checksToRun} },
          )
          : null
      );
    
      if (!checkAsJob) {
        const { data } = await performApiActionRequest<TActionResponse<IDataIntegrityChecksResponse>>(
          'precheck',
          { checksToRun },
        ) as IApiQueryResponse<TActionResponse<IDataIntegrityChecksResponse>>;
        
        const [ singularData ] = arrayWrap(data);
        dispatch(precheckErrorListReceived(singularData.actionResponse));
      }
  
      dispatch(setPrecheckLoadState(EDataIntegrityChecksLoadedState.loaded));
      
    } else if (runType === 'postcheck') {
      dispatch(setPostcheckLoadState(EDataIntegrityChecksLoadedState.loading));
      
      const { year, month } = yearMonth;

      const checkAsJob = (
        runAsJob 
          ? await performApiActionRequest(
            'dispatchAction',
            { actionName: 'postcheck', parameters: { year, month, checksToRun } },
          )
          : null
      );

      if (!checkAsJob) {
        const { data } = await performApiActionRequest<TActionResponse<IDataIntegrityChecksResponse>>(
          'postcheck',
          { year, month, checksToRun },
        ) as IApiQueryResponse<TActionResponse<IDataIntegrityChecksResponse>>;
        
        const [ singularData ] = arrayWrap(data);
        dispatch(postcheckErrorListReceived(singularData.actionResponse));
      }
      dispatch(setPostcheckLoadState(EDataIntegrityChecksLoadedState.loaded));
    }

    const message = (
      runAsJob
        ? `${capitalize(runType)} data integrity job started.`
        : `${capitalize(runType)} data integrity errors received.`
    );
    
    showMessage({ message, severity: 'info' });
  } catch (error) {
    showMessage({ message: `${capitalize(runType)} data integrity check failed.`, severity: 'error' });
  }
};
  
/**
   * Sends an `ApiActionRequest` to remedy a data integrity error by calling a specific
   * 'fix function' handler with arguments provided via the UI via a form.
   * @param dataIntegrityCheckName Name of the data integrity check.
   * @param fixFnName Name of the specific 'fix function'.
   * @param formState Values of type `TFixFnArgumentOption` for the 'fix function'.
   * @returns A Boolean indicating whether or not the fix was successful. 
   * If so, the data integrity check should no longer appear.
   */
export const fixDataIntegrityCheckError = async (
  dataIntegrityCheckName: string,
  fixFnName: string,
  formState: HashOf<TFixFnArgumentOption> & { errorData:IDataIntegrityCheckError },
): Promise<boolean> => {
  const fixResponse = await performApiActionRequest<TActionResponse<boolean>>(
    'fixDataIntegrityError',
    {
      dataIntegrityCheckName,
      fixFnArguments: formState,
      fixFnName,
    },
  ) as IApiQueryResponse<TActionResponse<boolean>>;
  
  const [ singularData ] = arrayWrap(fixResponse.data);
  const { actionResponse: success } = singularData;
    
  return !!success;
};
  
export const ignoreDataIntegrityError = async (
  dataIntegrityErrorIgnoreInfo: {
    type: EIgnoredDataIntegrityErrorType,
    dataIntegrityCheckName: string,
    objectId: string,
    reason: string,
  },
): Promise<IgnoredDataIntegrityError> => {
  const url = `${REACT_APP_API_ROOT_URI}/data_integrity_checks/ignore`;
    
  const response = await typedApiFetch<IApiCommandResponse<IgnoredDataIntegrityError>>(
    url,
    {
      method: 'POST',
      body: JSON.stringify(dataIntegrityErrorIgnoreInfo),
    },
  );
  
  const { data } = await response.json();
  
  const [ ignoredDataIntegrityError ] = arrayWrap(data);
  
  dispatch(
    dataIntegrityErrorIgnoreInfo.type === EIgnoredDataIntegrityErrorType.precheck
      ? precheckIgnored(ignoredDataIntegrityError)
      : postcheckIgnored(ignoredDataIntegrityError),
  );
  
  return ignoredDataIntegrityError;
};
  
export const unignoreDataIntegrityError = async (
  dataIntegrityErrorIgnoreInfo: {
    type: EIgnoredDataIntegrityErrorType,
    dataIntegrityCheckName: string,
    objectId: string,
  },
): Promise<IgnoredDataIntegrityError> => {
  const url = `${REACT_APP_API_ROOT_URI}/data_integrity_checks/unignore`;
    
  const response = await typedApiFetch<IApiCommandResponse<IgnoredDataIntegrityError>>(
    url,
    {
      method: 'POST',
      body: JSON.stringify(dataIntegrityErrorIgnoreInfo),
    },
  );
  
  const { data } = await response.json();
  
  const [ unignoredDataIntegrityError ] = arrayWrap(data);
  
  dispatch(
    dataIntegrityErrorIgnoreInfo.type === EIgnoredDataIntegrityErrorType.precheck
      ? precheckNoLongerIgnored(unignoredDataIntegrityError.id)
      : postcheckNoLongerIgnored(unignoredDataIntegrityError.id),
  );
  
  return unignoredDataIntegrityError;
};
  
export const fixDataIntegrityError = async (
  dataIntegrityErrorIgnoreInfo: {
    type: EIgnoredDataIntegrityErrorType,
    dataIntegrityCheckName: string,
    objectId: string,
    metaData:HashOf<unknown>,
  },
): Promise<void> => {
  const url = `${REACT_APP_API_ROOT_URI}/data_integrity_checks/fix`;
    
  const response = await typedApiFetch<IApiCommandResponse<Nullable<IgnoredDataIntegrityError>>>(
    url,
    {
      method: 'POST',
      body: JSON.stringify(dataIntegrityErrorIgnoreInfo),
    },
  );
  
  const { data } = await response.json();
  
  const [ updatedIgnoredDataIntegrityError ] = arrayWrap(data);
  
  const fixedErrorData = updatedIgnoredDataIntegrityError
    ?
    {
      handlerName: updatedIgnoredDataIntegrityError.checkName,
      objectId: updatedIgnoredDataIntegrityError.problematicObjectId,
    }
    :
    {
      handlerName: dataIntegrityErrorIgnoreInfo.dataIntegrityCheckName,
      objectId: dataIntegrityErrorIgnoreInfo.objectId,
    };
  
  dispatch(dataIntegrityErrorFixed(fixedErrorData));
};
  