import { Hash } from '@jamesgmarks/utilities';
import { IApiErrorResponse, IApiQueryResponse } from '@llws/api-common';
import { REACT_APP_API_ROOT_URI } from '../App';
import { apiFetch } from './utils';

export type TActionResponse<T> = { actionResponse: T };

export interface IActionRequest {
  action: string,
  parameters: Hash,
}

/**
 * Attempts to parse a JSON string. If the string is invalid, `null` is returned
 * from the caught error.
 * @param stringVal A string representing JSON data.
 * @returns Parsed JSON as JavaScript entities.
 */
export const tryParseJson = (stringVal: string) => {
  try {
    return JSON.parse(stringVal);
  } catch {
    return null;
  }
};

/**
 * Performs an HTTP request to the `actionRouter` and returns the result.
 * @param action - The name of the action that specifies the handler.
 * @param parameters - An object containing any parameters for the to-be-invoked handler.
 * @returns - The response from the HTTP request.
 */
export const performApiActionRequest = async <T>(action: string, parameters: Hash) => {
  const url = `${REACT_APP_API_ROOT_URI}/actions`;
  const requestBody = { action, parameters } as IActionRequest;
  const response = await apiFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json'},
    body: JSON.stringify(requestBody),
  });

  const status = response.status;
  const responseBody = await response.text();
  const responseJson = tryParseJson(responseBody);

  if (status !== 200 || responseJson.error) {
    return responseJson as IApiErrorResponse;
  }

  return responseJson as IApiQueryResponse<T>;
};

