import React, { useEffect } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { loadCommissions } from "../../../redux/features/commissions/actions";
import { useAppSelector } from "../../../redux/hooks";
import { CommissionsTable } from "./CommissionsTable";

export const CommissionsUnpaid = ({salesRepId}:{salesRepId:number}) => {
  const commissionsList = useAppSelector(state => state.commissions.commissionsList);
  const commissionSource = useAppSelector(state => state.commissions.commissionSource);

  const legacyInvoiceStates =  [
    'Draft',
    'Sent',
    'Viewed',
    'Partial',
    'Disputed',
    'Paid',
    'Pending',
    'Outstanding',
    'Declined',
  ];
  const hydraInvoiceStates =  ['sent', 'partial', 'disputed', 'resolved', 'overdue', 'viewed', 'paid'];
  const invoiceStates = JSON.stringify({
    legacyInvoiceStates,
    hydraInvoiceStates,
  });

  // const invoiceStates = (
  //   commissionSource === 'legacy'
  //     ? ['Draft','Sent', 'Viewed', 'Partial', 'Disputed', 'Paid', 'Pending', 'Outstanding', 'Declined']
  //     : ['sent','partial','disputed', 'resolved', 'overdue', 'viewed', 'paid']
  // )

  useEffect(()=>{
    loadCommissions(salesRepId, `/unpaid?invoiceStates=${invoiceStates}&source=${commissionSource}`);
  }, [salesRepId, commissionSource]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Row>
        <Col>
          <br></br>
          {/* <p>Shown Invoice States</p>
            {invoiceStates.map((invoiceState) => <Badge pill variant="primary">{invoiceState}</Badge>)} */}
        </Col>
      </Row>
      <CommissionsTable commissionsList={commissionsList} />
    </>
  );
};