import { TableCell, TableRow, IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";

import { IBaseSubscriptions } from "@llws/lift-entity-interfaces";

export const BaseSubscriptionListTableRow = ({
  baseSubscription, onEdit,
}: {
  baseSubscription: IBaseSubscriptions;
  onEdit: () => void;
}) => {
  const style = baseSubscription.status === 'inactive' ? { color: '#CCC' } : {};

  const accountCode = (
    baseSubscription.revenueTrackingCode
            ?? (
              baseSubscription.service?.revenueTrackingCode
                ? `${baseSubscription.service?.revenueTrackingCode} (Service)`
                : null
            )
            ?? <em>Not found</em>
  );

  const itemCode = (
    baseSubscription.service?.invoiceItem
    ?? baseSubscription.partner?.name
  );

  return (
    <>
      <TableRow style={style}>
        <TableCell component="th" scope="row">
          <strong>Service: {baseSubscription.service?.name ?? ''}</strong>
          <div><small>Partner: {baseSubscription.partner?.name ?? ''}</small></div>
          <div><small>Account Code: {accountCode}</small></div>
        </TableCell>
        <TableCell>
          <div><strong>{itemCode}</strong></div>
          <div>
            {
              baseSubscription.invoiceDescription.length <= 60
                ? baseSubscription.invoiceDescription
                : `${baseSubscription.invoiceDescription.slice(0, 47)}...`
            }
          </div>
        </TableCell>
        <TableCell>{baseSubscription.billingFrequency?.name}</TableCell>
        <TableCell>{baseSubscription.status}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <IconButton onClick={() => onEdit()}><Edit /></IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
