import { Typography } from '@mui/material';

import { HashOf } from '@jamesgmarks/utilities';

import { CreditsBreakdownTable } from './CreditsBreakdownTable';
import { CreditsSummaryTable } from './CreditsSummaryTable';
import { EHideableColumns } from '../MonthlyBillingReport';
import {
  ICreditBreakdown,
} from '../../../../../../redux/features/reporting/IMonthlySummaryData';
import { ICreditTotals } from '../../../../../../../../rentsync-api/reporting';

interface MonthlyCreditSummaryProps {
  creditBreakdown: ICreditBreakdown;
  creditTotals: HashOf<ICreditTotals>;
  currencyCode: string;
  hiddenColumns?: EHideableColumns[];
}

export const MonthlyCreditSummary = ({
  creditBreakdown,
  creditTotals,
  currencyCode,
  hiddenColumns,
}: MonthlyCreditSummaryProps) => (
  <>      
    <Typography sx={{ color: '#123456', mt: '4rem' }} variant='h5'>Credits Summary</Typography>
    <CreditsSummaryTable creditTotals={creditTotals} hiddenColumns={hiddenColumns} />

    <Typography sx={{ color: '#123456', mt: '4rem' }} variant='h5'>Credits Breakdown</Typography>
    <CreditsBreakdownTable creditBreakdown={creditBreakdown} currencyCode={currencyCode} />
    <br />
    <br />
  </>
);
