import { Hash } from '@jamesgmarks/utilities';
import { assertIsValueWithLabelArray } from '@hydra/internal';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@mui/material';
import { useMemo } from 'react';
import { TFixFnArgumentOption } from 'src/redux/features/data-integrity-checks/interfaces';

interface IFixFormTextFieldProps {
  disabled?: boolean
  label: string
  onBlurHandler?: (newValue: string) => void
  onChangeHandler: (newValue: string) => void
  required?: boolean
  size?: 'small' | 'medium'
  value: string
}

/**
 * Renders a controlled text input with `onChange` and `onBlur` handlers that convert
 * the typed string into a `TFixFnArgumentOption` to use as an argument for the
 * 'fix function'.
 * @param options.label `TextField` label.
 * @param options.onBlurHandler Function that converts the string into desired argument type when input loses focus.
 * @param options.onChangeHandler Function that converts the string into desired argument type when value changes.
 * @param options.required Whether or not user must provide input.
 * @param options.size Either 'small' or 'medium', for MUI.
 * @param options.value Value for the controlled `TextField` component.
 * @returns Configured MUI `TextField` controlled component.
 */
export const FixFormTextField = ({
  disabled,
  label,
  onBlurHandler,
  onChangeHandler,
  required,
  size = 'small',
  value,
}: IFixFormTextFieldProps) => (
  <FormControl>
    <TextField
      disabled={disabled}
      InputProps={{ style: { backgroundColor: 'white' } }}
      label={label}
      onBlur={(e) => onBlurHandler?.(e.target.value)}
      onChange={(e) => onChangeHandler(e.target.value)}
      required={required}
      size={size}
      value={value}
    />
  </FormControl>
);

interface IFixFormCheckboxProps {
  checked: boolean
  label: string
  onChangeHandler: (newValue: boolean) => void
  size?: 'small' | 'medium'
}

/**
 * Renders a controlled `Checkbox` component.
 * @param options.checked Whether or not the controlled `TextField` component is checked.
 * @param options.label `Checkbox` label that can also be clicked to toggle state.
 * @param options.onChangeHandler Function called when checkbox state is toggled.
 * @param options.size Either 'small' or 'medium', for MUI.
 * @returns Configured MUI `Checkbox` controlled component.
 */
export const FixFormCheckbox = ({
  checked,
  label,
  onChangeHandler,
  size = 'medium',
}: IFixFormCheckboxProps) => (
  <FormGroup>
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={(e) => onChangeHandler(e.target.checked)}
          size={size}
          sx={{ pl: 2 }}
        />
      }
      label={label}
    />
  </FormGroup>
);

export const FixFormMultiSelect = ( {
  value,
  onChangeHandler,
}:{
  value:TFixFnArgumentOption
  onChangeHandler: (newValue: Hash) => void
}) =>{

  const values = useMemo( () => assertIsValueWithLabelArray(value), [value]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const index = values.findIndex((v) => `${v.value}` === `${event.target.value}`);

    if (index !== -1 ) {
      onChangeHandler([
        ...values.slice(0, index),
        {
          ...values[index],
          checked: event.target.checked,
        },
        ...values.slice(index+1, values.length),
      ]);
    }
  };

  return <FormGroup>
    {
      values?.map((v)=> {
        const label = v.label;
        return <FormControlLabel
          control={<Checkbox  onChange={handleCheckboxChange} data-label={label}/>}
          value={v.value}
          label={label}
          checked={v.checked}
          key={v.value}
        />;
      })
    }
  </FormGroup>;
};