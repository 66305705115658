import { createSlice } from '@reduxjs/toolkit';
import { IApiQueryResponse } from '@llws/api-common';
import { arrayWrap, Hash, Nullable } from '@jamesgmarks/utilities';
import { ISubscriptionsWithInvoices } from './interfaces';
import { ISubscriptions } from '@llws/lift-entity-interfaces';

export interface ISubscriptionsState {
  loadedState: string,
  subscriptionsList: ISubscriptions[],
  nextPage: Nullable<string>,
  filters: Hash,
  feesQueue: ISubscriptionsWithInvoices[],
};

export const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState: {
    loadedState: 'idle',
    subscriptionsList: [],
    nextPage: null,
    filters: {},
    feesQueue: [],
  } as ISubscriptionsState,
  reducers: {
    feesQueueDataReceived: (
      state,
      action: { type: string, payload: IApiQueryResponse<ISubscriptionsWithInvoices> },
    ) => {
      const feesData = arrayWrap(action.payload.data);
      state.feesQueue = feesData;
    },
    subscriptionListReceived: (
      state,
      action: { type: string, payload: IApiQueryResponse<ISubscriptions> },
    ) => {
      const subscriptionData = arrayWrap(action.payload.data);
      state.subscriptionsList = subscriptionData;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  feesQueueDataReceived,
  subscriptionListReceived,
} = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;