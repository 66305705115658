import { Grid } from "@mui/material";

export const AccountAddress = ({address, children}: {address: string, children: React.ReactNode}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {children}
      </Grid>
      <Grid item>
        <span style={{color: 'rgb(86, 86, 138)',  fontWeight: 'bold'}}>
              STATEMENT OF ACCOUNT: <br/>
        </span>
        {address.split('<br>').map((addressLine, index) => {
          return (<span key={index}>{addressLine}<br/></span>);
        })}
      </Grid>
    </Grid>
  );
};