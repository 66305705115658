import { Hash, HashOf } from "@jamesgmarks/utilities";
import { Button, Grid } from "@mui/material";
import { useState } from "react";
import { IFieldDescription } from "../../../../../custom_runners/field_types/IFieldDescription";
import { Spinner } from "../Spinner";
import { BoolField } from "./BoolField";
import { DatePickerField } from "./DatePickerField";
import { MultiLineTextField } from "./MultiLineTextField";
import { MultiSelectField } from "./MultiSelectField";
import { NumberField } from "./NumberField";
import { TextField } from "./TextField";

const renderField = (
  fieldName: string,
  fieldDescription: IFieldDescription,
  fieldData: Hash,
  setFieldData: (data: Hash) => void,
): JSX.Element | undefined => {
  const placeholder: string | null = (
    ('placeholder' in fieldDescription)
      ? (fieldDescription.placeholder ?? null)
      : null
  );
  if (fieldDescription.type === 'boolean') {
    const checked = !!(fieldData[fieldName]?.checked ?? fieldDescription.default);
    return (
      <BoolField
        label={placeholder ?? fieldName}
        checked={checked}
        onChange={() => {
          setFieldData({...fieldData, [fieldName]: { checked: !checked }});
        }}
      />
    );
  } else if (fieldDescription.type === 'json') {
    const value = fieldData[fieldName]?.value ?? fieldDescription.default;
    return (
      <MultiLineTextField
        label={placeholder ?? fieldName}
        value={value ?? ''}
        onChange={(e) => {
          setFieldData({...fieldData, [fieldName]: { value: e.target.value }});
        }}
      />
    );
  } else if (fieldDescription.type === 'int' || fieldDescription.type === 'float') {
    const value = fieldData[fieldName]?.value ?? fieldDescription.default;
    return (
      <NumberField
        label={placeholder ?? fieldName}
        value={value ?? ''}
        type={fieldDescription.type}
        onChange={(e) => {
          setFieldData({...fieldData, [fieldName]: { value: e.target.value }});
        }}
      />
    );
  } else if (fieldDescription.type === 'multiselect') {
    const value = fieldData[fieldName]?.value;
    const options = fieldDescription.options;
    return (
      <MultiSelectField
        label={placeholder ?? fieldName}
        value={value ?? ''}
        options={options}
        onChange={(e) => {
          setFieldData({...fieldData, [fieldName]: { value: e.target.value }});
        }}
      />
    );
  } else if (fieldDescription.type === 'string') {
    const value = fieldData[fieldName]?.value ?? fieldDescription.default;
    return (
      <TextField
        label={placeholder ?? fieldName}
        value={value ?? ''}
        onChange={(e) => {
          setFieldData({...fieldData, [fieldName]: { value: e.target.value }});
        }}
      />
    );
  } else if (fieldDescription.type === 'datePicker') {
    const value = fieldData[fieldName]?.value ?? fieldDescription.default;
    return (
      <DatePickerField
        label={placeholder ?? fieldName}
        value={value ?? ''}
        options={fieldDescription.options ?? ['day', 'month', 'year']}
        onChange={(newValue) => {
          if (newValue){
            setFieldData({
              ...fieldData, [fieldName]: {
                value: new Date(newValue.setHours(0, 0, 0, 0)).toISOString(),
              },
            });
          }
        }}
      />
    );
  }
  return (<>{fieldName} {fieldDescription.type}</>);
};

export const RunnerFieldsForm  = ({
  fields,
  buttonLabel,
  submitDisabled,
  onSubmit,
}: {
  fields: HashOf<IFieldDescription>,
  buttonLabel?: string,
  submitDisabled?: boolean,
  onSubmit: (fieldData: Hash) => void, // TODO: Mapped type from the `fields` argument
}) => {
  const [ fieldData, setFieldData ] = useState(Object.keys(fields || {}).reduce((acc, f) => {
    return {
      ...acc,
      [f]: {value: fields[f].default},
    };
  }, {}) as Hash);

  return (
    <Grid container spacing={2}>
      {Object.keys(fields || {}).map(f => (
        <Grid item xs={12} key={f}  style={{ textAlign: 'left' }}>
          {renderField(f, fields[f], fieldData, setFieldData)}
        </Grid>
      ))}
      <Grid item xs={12} style={{ textAlign: 'right' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onSubmit(Object.keys(fieldData).reduce<Hash>((acc, f) => ({
              ...acc,
              [f]: fieldData[f].value ?? fieldData[f].checked,
            }), {}));
          }}
          disabled={submitDisabled ?? false}
        >{buttonLabel ?? 'Run'} <Spinner state={submitDisabled ? 'loading' : ''} /></Button>
      </Grid>
    </Grid>
  );
};
