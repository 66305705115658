
import { createSlice } from '@reduxjs/toolkit';
import { IBillingContacts } from "@llws/lift-entity-interfaces";
import { IActionType, IApiQueryListResponse } from '../../utils';
import { TLoadedState } from '../../../types/TLoadedState';
import { Nullable } from '@jamesgmarks/utilities';
export interface IBillingContactsState {
  loadedState: TLoadedState;
  billingContactsList: IBillingContacts[];
  metaData: Nullable<IApiQueryListResponse<IBillingContacts>['meta']>;
};

export const billingContactsSlice = createSlice({
  name: 'billingContactsSlice',
  initialState: {
    loadedState: 'idle',
    billingContactsList: [],
    metaData: null,
  } as IBillingContactsState,
  reducers: {
    billingContactsListReceived: (state, action: IActionType<IApiQueryListResponse<IBillingContacts>>) => {
      state.billingContactsList = action.payload.data;
      state.metaData = { ...action.payload.meta};
    },
    clearBillingContacts: (state) => {
      state.billingContactsList = [];
      state.metaData = null;
    },
    setLoadedState: (state, action: IActionType<TLoadedState>) => {
      state.loadedState = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  billingContactsListReceived,
  clearBillingContacts,
  setLoadedState,
} = billingContactsSlice.actions;

export default billingContactsSlice.reducer;