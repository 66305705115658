import { assertValidBillingType, BILLING_FEATURE_BILLING_ZONES, billingTypeHasFeature } from "@llws/hydra-shared";
import { TEditorComponentProps } from "./TEditorComponentProps";
import { BillingZoneCityOverrides, BillingZones } from "@llws/typeorm-entities";
import { useMemo } from "react";
import { IColumnConfig, HHSimpleTable } from "src/components/parts/HHSimpleTable";
import { Tooltip } from "@mui/material";

export const BillingZoneEditor = ({ subscription, setSubscriptionProp }: TEditorComponentProps) => {
  const billingTypeId = useMemo(() => (subscription?.baseSubscription?.service?.billingTypeId), [ subscription ]);

  const billingZonesTableColumnsConfig: IColumnConfig<BillingZones>[]= useMemo(() => (
    [
      {
        headerLabel: 'Billing Zone ID',
        fieldName: 'id',
        format: (value) => `ID: ${value}`,
      },
      {
        headerLabel: 'Base Subscription ID',
        fieldName: 'baseSubscriptionId',
      },
      {
        headerLabel: 'Cities',
        fieldName: 'billingZoneCities',
        render: (data: BillingZones) => (
          <Tooltip
            title={
              data.billingZoneCities.map((bzc) => (
                `${bzc.city.cityName}`
              + `: ${bzc.city.province.provinceName}`
              )).join(', ')
            }
            placement="top-start"
          >
            <div>
              {
                data.billingZoneCities.length
              }
            </div>
          </Tooltip>
        ),
      },
      {
        headerLabel: 'Cost Coefficient',
        fieldName: 'costCoefficient',
      },
      {
        headerLabel: 'Price Cap',
        fieldName: 'priceCap',
        format: (value) => value ?? 'Null',
      },
    ]
  ), []);
  const billingZoneCityOverridesColumnsConfig: IColumnConfig<BillingZoneCityOverrides>[]= useMemo(() => (
    [
      {
        headerLabel: 'Billing Zone City Override ID',
        fieldName: 'id',
        format: (value) => `ID: ${value}`,
      },
      {
        headerLabel: 'Subscription ID',
        fieldName: 'subscriptionId',
      },
      {
        headerLabel: 'Ownership Group Name',
        fieldName: 'ownershipGroup',
        render: (data: BillingZoneCityOverrides) => <>{data.ownershipGroup.name ?? 'N/A'}</>,
      },
      {
        headerLabel: 'City',
        fieldName: 'city',
        render: (data: BillingZoneCityOverrides) => (
          <>{data.city.cityName}, {data.city.province.provinceName}</>
        ),
      },
      {
        headerLabel: 'Override Coefficient',
        fieldName: 'overrideCoefficient',
      },
      {
        headerLabel: 'Price Cap Override',
        fieldName: 'priceCapOverride',
        format: (value) => value ?? 'Null',
      },
    ]
  ), []);

  return <>
    {
      billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_BILLING_ZONES)
                && subscription.billingZoneCityOverrides.length
        ? <>
          <label htmlFor="building-search">
            <h4>Billing Zone City Override</h4>
          </label>
          <HHSimpleTable<BillingZoneCityOverrides>
            rows={subscription.billingZoneCityOverrides}
            columns={billingZoneCityOverridesColumnsConfig}
            rowHover={false}
            scrollable={true}
            maxHeight="600px"
          />
        </>
        : billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_BILLING_ZONES)
                    && !subscription.billingZoneCityOverrides.length
                    && subscription.baseSubscription.billingZones
                    && <>
                      <label htmlFor="building-search">
                        <h4>Billing Zones</h4>
                      </label>
                      <HHSimpleTable<BillingZones>
                        rows={subscription.baseSubscription.billingZones}
                        columns={billingZonesTableColumnsConfig}
                        rowHover={false}
                        scrollable={true}
                        maxHeight="600px"
                      />
                    </>
    }
  </>;
};