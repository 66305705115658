export const BankingInformation = ({bankingInfo} : {bankingInfo: string}) => {
  return (
    <>
      <p>
        <span style={{color: 'red'}}>New banking information as of 01 February 2023</span>
        {(bankingInfo).split('<br>').map((bankInfoLine, index) => {
          return (<span key={index}>{bankInfoLine}<br/></span>);
        })}
      </p>
    </>
  );
};