import { Link, Route, Switch } from "react-router-dom";
import { BaseSubscriptions } from "./BaseSubscriptions/BaseSubscriptions";
import { Services } from "./Services";

export const ProductManager = () => {
  return (
    <>
      <Switch>
        <Route path="/products/services"><Services /></Route>
        <Route path="/products/subscription_types"><BaseSubscriptions /></Route>
        <Route path="/products">
          <header className="Section-header">Product Manager</header>
          <ul>
            <li><Link to="/products/services">Services</Link></li>
            <li><Link to="/products/subscription_types">Base Subscriptions</Link></li>
          </ul>
        </Route>
      </Switch>
    </>
  );
};