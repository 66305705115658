import { Nullable } from "@jamesgmarks/utilities";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  TextField, 
} from "@mui/material";
import { useState } from "react";
import { BuildingDropdown } from "src/components/parts/BuildingDropdown";
    
interface IPropertyModalProps {
  open: boolean;
  accountId: number
  setOpen: (open: boolean) => void;
  onConfirm: (buildingId: Nullable<number>, price: string) => void;
  onCancel?: () => void;
  onClose?: () => void;
  buildingStatuses?: ('enabled' | 'hidden' | 'disabled' | 'deleted')[];
  excludeStatus?: boolean;
  
}
    
export const PropertyListModal = ({
  open,
  accountId,
  setOpen,
  onConfirm,
  onCancel,
  onClose,
  buildingStatuses = ['enabled'],
  excludeStatus = false,
}: IPropertyModalProps) => {
  const [buildingId, setBuildingId] = useState<Nullable<number>>(null);
  const [price, setPrice] = useState<string>('0');

  return <Dialog
    open={open}
    onClose={() => { onClose?.(); setOpen(false); }}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    
    <DialogTitle id="alert-dialog-title">
      Add a new property to the subscription {accountId}
    </DialogTitle>
    
    <DialogContent>
      <Box sx={{marginTop: "10px"}}>
        <BuildingDropdown
          accountId={accountId ?? null}
          onBuildingChanged={
            (building: { buildingName: string, id: number }) => {
              setBuildingId(building.id);
            }
          }
          numberOfBuildingChanges={accountId}
          buildingStatuses={buildingStatuses}
          excludeStatus={excludeStatus}
          
        />
      </Box>
      <TextField
        label="Price"
        type="number"
        inputProps={{
          step: 0.01,
        }}
        sx={{background: "white", marginTop: "10px"}}
        size="small"
        value={price}
        onChange={(e) => setPrice(e.target.value || '0')}
        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
      />
    </DialogContent>
        
    <DialogActions>
      <Button color='error' onClick={() => { onCancel?.(); setOpen(false); }}>
        Cancel
      </Button>
    
      <Button onClick={() => { onConfirm(buildingId, price); setOpen(false); }} autoFocus>
        Add
      </Button>
    </DialogActions>
  </Dialog>;
};
    