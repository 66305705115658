import { Nullable } from "@jamesgmarks/utilities";

export type TApiValidatorSettings = Record<string, IApiValidatorSettingsOptions>;

export enum EApiValidatorStatus {
  enabled = 'enabled',
  disabled = 'disabled',
}

export interface IApiValidatorSettingsOptions {
  status: EApiValidatorStatus;
}

export interface IApiValidator {
  id: number;
  router: string;
  status: EApiValidatorStatus;
  created: string;
  createdUserId: number;
  modified: Nullable<string>;
  modifiedUserId: Nullable<number>;
}
