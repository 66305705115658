export enum EHydraCreditNoteState {
  unpublished = 'unpublished',
  draft = 'draft',
  deleted = 'deleted',
  sent = 'sent',
  void = 'void',
}

export enum EHydraCreditNoteVoidState {
  voidRequested = 'void-requested',
  voidDenied = 'void-denied',
  voidApproved = 'void-approved',
}
