import { HashOf, keys, first } from "@jamesgmarks/utilities";
import { EPermissionGrantDeny } from "../../../entities/hydra/UserPermission";
import { EUserAccessLevel } from "../../../entities/hydra/UserUiAccess";
import { useAppSelector } from "../redux/hooks";
import { ITokenData } from "./ITokenData";

export interface IAuthHelpers {
  isLoggedIn: () => boolean,
  hasPermission: (permission: string) => boolean,
  hasAccess: (to: string, accessLevel: 'readonly' | 'readwrite' | 'any') => boolean
}

export type IAuthData = ITokenData & IAuthHelpers;

export const useAuth = (): IAuthData => {
  const token = useAppSelector(state => state.auth?.decodedToken);
  const isDeveloper = useAppSelector(state => state.auth?.isDeveloper);

  const permissions: HashOf<EPermissionGrantDeny> = (token?.permissions ?? {});
  const access: HashOf<EUserAccessLevel> = (token?.access ?? {});

  return {
    userId: token?.userId,
    roles: token?.roles ?? [],
    email: token?.email ?? '',
    access,
    permissions,
    isDeveloper,
    isLoggedIn: () => !!token,
    hasAccess: (to: string, accessLevel: 'readonly' | 'readwrite' | 'any') => {
      const accessList = keys(access).map(k => `${k}`.toLowerCase());

      const associatedAccess = (
        accessList
          .filter(k => to.startsWith(k))
          .sort((a, b) => b.length - a.length) // Sort in descending order by route length
      );

      const accessToCompare = first(associatedAccess); // and take the first (longest length) match if any

      return (!!permissions['FULL_ACCESS']) || (
        accessToCompare
        && access[accessToCompare]
        && (
          (accessLevel === 'any' && access[accessToCompare] !== 'deny')
          || access[accessToCompare] === accessLevel
        )
      );
    },
    hasPermission: (grant: string) => {
      return (!!permissions['FULL_ACCESS']) || (permissions[grant] && (permissions[grant] === 'grant'));
    },
  };
};
