import { useEffect, useMemo, useState } from 'react';

import {
  Button,
  Col,
  Form,
  FormCheck,
  Row,
} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  distinct, first, floatVal, intVal, Nullable, Optional,
} from '@jamesgmarks/utilities';

import { createOneTimeFeeInvoice } from '../../../redux/features/invoices/actions';
import { dateFromYmd, makeYmd } from '../../../app-utils';
import { getFeesQueue } from '../../../redux/features/subscriptions/actions';
import { loadClientBillingContacts, migrateNewContactsToXero } from '../../../redux/features/clients/actions';
import { OwnershipGroupDropdown } from '../../parts/OwnershipGroupDropdown';
import { useAppSelector } from "../../../redux/hooks";
import { RequirePermission } from '../../parts/RequirePermission';
import { showMessage } from '../../../redux/features/messaging/actions';
import { loadCurrentBillingAccount } from '../../../redux/features/accounts/actions';
import { BillingAddressCard } from '../../parts/BillingAddressCard';
import {
  Box,
  Grid, Paper, Table, TableBody, TableHead, Typography,
} from '@mui/material';
import { StyledTableCell, StyledTableRow } from '../../parts/mui/StyledTables';
import { getEmailStringFromContactInformation } from '../../../app-utils/helpers';
import { ISubscriptionsWithInvoices } from '../../../redux/features/subscriptions/interfaces';
import { EClientMigrateState } from 'src/redux/features/clients/clientsSlice';
import { assertIdIsNotNullOrUndefined } from 'src/interfaces/interfaceAssertions';

const getSalesRepName = (subscription: ISubscriptionsWithInvoices) => {
  return (
    `${subscription.salesRep?.user?.firstName ?? ''} ${subscription.salesRep?.user?.lastName ?? ''}`
  );
};

const getOptionDescriptionForFee = (fee: ISubscriptionsWithInvoices) => {
  return (
    `(${fee.id}) `
    + `${fee.baseSubscription.service?.invoiceItem ?? fee.baseSubscription.partner?.name} `
    + `${fee.invoiceDescription} - `
    + `$${fee.basePrice ?? 'unknown'} `
    + `[${fee.startDate}]`
  );
};

interface IFeeDataRowProps {
  fee: ISubscriptionsWithInvoices;
  fn: (fee: ISubscriptionsWithInvoices) => void;
  label: string;
  variant: string;
}

const FeeDataRow = ({ fee, fn, label, variant }: IFeeDataRowProps) => (
  <StyledTableRow className="billing-runs-row">
    <StyledTableCell className="billing-runs-column billing-run-id-column">{fee.id}</StyledTableCell>
    <StyledTableCell className="billing-runs-column billing-run-id-column">
      {getSalesRepName(fee)} {fee.salesRepId ? `${fee.salesRepId}` : ''}
    </StyledTableCell>
    <StyledTableCell className="billing-runs-column billing-run-id-column">
      <b>{fee.baseSubscription.service?.invoiceItem}</b>
      <br></br>
      <i>{fee.invoiceDescription}</i>
    </StyledTableCell>
    <StyledTableCell className="billing-runs-column billing-run-id-column">
      {
        fee.handleBilling === 0
          ? <div>PARTNER INVOICED: {fee.baseSubscription.partner?.name}</div>
          : fee.client?.name
      }
    </StyledTableCell>
    <StyledTableCell className="billing-runs-column billing-run-id-column">{'$' + fee.basePrice}</StyledTableCell>
    <StyledTableCell customSx={{ px: 2 }} className="billing-runs-column billing-run-actions-column">
      <Button variant={variant} onClick={() => fn(fee)}>{label}</Button>
    </StyledTableCell>
  </StyledTableRow>
);

export const FeesManager = () => {
  const isDeveloper = useAppSelector(state => state.auth.isDeveloper);

  const feesQueue = useAppSelector(state => state.subscriptions.feesQueue);
  const loadedState = useAppSelector(state => state.invoices.loadedState);
  const [useSampleData, setUseSampleData] = useState(false);

  const billingContactsList = useAppSelector(state => state.clients.billingContactsList);

  const currentAccount = useAppSelector(state => state.accounts.currentAccount);

  const clientsMigratingState = useAppSelector(state => state.clients.migratingState);

  const [checkedBillingContacts, setCheckedBillingContacts] = useState<number[]>([]);
  const [contactListToggle, setContactListToggle] = useState(false);

  const [ownershipGroupSearchIdFilter, setSearchIdFilter] = useState<Optional<number>>();

  const setCheckedBillingContact = (contactId: number, selected: boolean) => {
    selected
      ? setCheckedBillingContacts(distinct(checkedBillingContacts.concat([contactId])))
      : setCheckedBillingContacts(checkedBillingContacts.filter(c => c !== contactId));
  };

  const [invoiceItems, setInvoiceItems] = useState<ISubscriptionsWithInvoices[]>([]);
  const [invoiceDate, setInvoiceDate] = useState(new Date());

  const [accountIdFilter, setAccountIdFilter] = useState<Nullable<number>>(null);

  const addItemToInvoice = (fee: ISubscriptionsWithInvoices) => {
    setInvoiceItems(invoiceItems.concat([fee]));
    setAccountIdFilter(fee.client?.freshbooksClientId ?? null);
  };

  const removeItemFromInvoice = (fee: ISubscriptionsWithInvoices) => {
    setInvoiceItems(invoiceItems.filter(_fee => _fee !== fee));
  };

  const [sendNowChecked, setSendNowChecked] = useState(false);
  const [shouldLoad, setShouldLoad] = useState(true);

  useEffect(() => {
    getFeesQueue(false || (isDeveloper && useSampleData));
    setShouldLoad(false);
  }, [shouldLoad, useSampleData, isDeveloper]);

  useEffect(() => {
    if (invoiceItems.length === 0) {
      setAccountIdFilter(null);
      setSearchIdFilter(null);
    }
  }, [invoiceItems]);

  useEffect(() => {
    loadClientBillingContacts({freshbooksClientId: accountIdFilter ? intVal(accountIdFilter) : null });
    if (accountIdFilter) {
      loadCurrentBillingAccount(intVal(accountIdFilter));
    }
  }, [accountIdFilter]);

  useEffect(() => {
    setCheckedBillingContacts(
      (billingContactsList ?? [])
        .filter(bc => bc.receivesInvoices && bc.ownershipGroupId === null).map(bc => bc.id),
    );

  }, [billingContactsList]);

  const filteredFees = useMemo(() => {
    return (feesQueue || [])
      .filter((fee) => (
        !invoiceItems.includes(fee)
        && (!accountIdFilter || fee.client?.freshbooksClientId === accountIdFilter)
      ));
  }, [ feesQueue, invoiceItems, accountIdFilter ]);

  if (loadedState === 'invoiceCreated' && invoiceItems.length > 0) {
    setInvoiceItems([]);
    setAccountIdFilter(null);
  }

  const billingAddress = useMemo(
    () => ({
      organization: currentAccount?.billingProfile?.organization,
      // TODO: do we need/use `firstName`, `lastName` or `rName` (and what the heck is `rName`)
      street1: currentAccount?.billingProfile?.street1,
      street2: currentAccount?.billingProfile?.street2,
      city: currentAccount?.billingProfile?.city,
      province: currentAccount?.billingProfile?.province,
      postalCode: currentAccount?.billingProfile?.code,
      country: currentAccount?.billingProfile?.country,
    }),
    [ currentAccount ],
  );

  const hasInconsistentClientPartnerBilling = (
    useMemo(() => distinct(invoiceItems.map((ii) => ii.handleBilling)).length !== 1, [ invoiceItems ])
  );

  const isPartnerOtf = useMemo(() => invoiceItems.every((ii) => !ii.handleBilling), [ invoiceItems ]);

  const clientWithClientFilterId = useMemo(() => (
    (feesQueue ?? []).find((fee)=> fee.client?.freshbooksClientId === accountIdFilter)
  ), [feesQueue, accountIdFilter]);

  const doesClientHasTaxes = useMemo(() => (
    (clientWithClientFilterId?.client?.clientsHasTaxes?.length ?? 0) > 0
  ), [ clientWithClientFilterId ]);

  return (
    <>
      <Grid container px={3}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Box>
              {
                isDeveloper
              && (
                <>
                  <h1>{loadedState}</h1>
                  <Grid item container xs={12} spacing={1}>
                    <Grid item mt={0.75}>Account ID:</Grid>
                    <Grid item>
                      <Form.Control
                        disabled={invoiceItems.length > 0}
                        type="number"
                        placeholder="Client ID"
                        value={accountIdFilter || ''}
                        onChange={(e) => {
                          e.preventDefault();
                          setAccountIdFilter(e.target.value ? intVal(e.target.value) : null);
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        variant="secondary"
                        disabled={invoiceItems.length > 0}
                        onClick={(e) => setAccountIdFilter(null)}
                      >
                        Clear
                      </Button>
                    </Grid>
                  
                    {
                      isDeveloper
                    && (
                      <Grid item xs={12} mt={1.5}>
                        <Form.Group controlId="useSampleDataCheckbox">
                          <FormCheck
                            checked={useSampleData}
                            onChange={(e) => setUseSampleData(e.target.checked)}
                            label="Use sample data"
                          />
                        </Form.Group>
                      </Grid>
                    )
                    }
                  </Grid>
                </>
              )
              }
            </Box>
            <Button
              variant="primary"
              disabled={clientsMigratingState === EClientMigrateState.migrating}
              onClick={(e) => migrateNewContactsToXero()}
            >
              Migrate New Clients
            </Button>
          </Box>
        </Grid>

        <Grid item xs={6} p={1}>
          <Typography textAlign='center' sx={{ mb: 2 }} variant='h4'>One Time Fee List</Typography>
          <Table component={Paper}>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>#</StyledTableCell>
                <StyledTableCell>Sales Rep</StyledTableCell>
                <StyledTableCell>Line Item/<br /> Description</StyledTableCell>
                <StyledTableCell>Client</StyledTableCell>
                <StyledTableCell>Total</StyledTableCell>
                <StyledTableCell />
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {
                filteredFees
                  .map(fee => <FeeDataRow key={fee.id} fee={fee} fn={addItemToInvoice} label='Add' variant="primary" />)
              }
            </TableBody>
          </Table>
        </Grid>

        <Grid item xs={6} p={1}>
          <Typography textAlign='center' sx={{ mb: 2 }} variant='h4'>Invoice</Typography>
          {
            invoiceItems.some(ii => ii.handleBilling === 0)
            && <div>
              Partner invoiced:&nbsp;
              {invoiceItems.find(ii => ii.handleBilling === 0)?.baseSubscription.partner?.name}
              <BillingAddressCard billingAddress={billingAddress} />
            </div>
          }
          <Table component={Paper}>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>#</StyledTableCell>
                <StyledTableCell>Sales Rep</StyledTableCell>
                <StyledTableCell>Line Item/<br /> Description</StyledTableCell>
                <StyledTableCell>Client</StyledTableCell>
                <StyledTableCell>Total</StyledTableCell>
                <StyledTableCell />
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {
                invoiceItems
                  .map(fee => (
                    <FeeDataRow key={fee.id} fee={fee} fn={removeItemFromInvoice} label='Remove' variant="danger" />
                  ))
              }
              {
                invoiceItems.length > 0 && filteredFees.length > 0
                && (
                  <StyledTableRow>
                    <StyledTableCell colSpan={6}>
                      <select onChange={(e) => console.log(e.target.value) }>
                        {
                          filteredFees
                            .map(fee => {
                              assertIdIsNotNullOrUndefined<ISubscriptionsWithInvoices>(fee);
                              return (
                                <option
                                  key={fee.id}
                                  value={fee.id}
                                >{getOptionDescriptionForFee(fee)}</option>
                              );
                            })
                        }
                      </select>
                    </StyledTableCell>
                  </StyledTableRow>
                )
              }
            </TableBody>
          </Table>

          {
            accountIdFilter != null && invoiceItems.length > 0
            && (
              <Row>
                <Grid item container xs={12} p={3}>

                  <Grid item xs={12} my={1}>
                    <strong>Total Charge: $</strong>
                    <strong>
                      {`${invoiceItems.reduce((acc, curr) => acc + (floatVal(curr.basePrice) || 0), 0.0).toFixed(2)}`}
                    </strong>
                  </Grid>

                  <Grid item xs={12} my={1}>
                    <strong>Client/Partner: </strong> {first(invoiceItems)?.client?.name}
                  </Grid>
                  <Grid item xs={12}>
                    <strong style={{ marginRight: '0.8rem' }}>Invoice Date: </strong>
                    <DatePicker
                      dateFormat='yyyy-MM-dd'
                      selected={invoiceDate}
                      onChange={(date) => date && !Array.isArray(date) ? setInvoiceDate(date) : null}
                    />
                  </Grid>

                  <Grid item container alignItems='center' xs={12} my={1}>
                    <Grid item xs='auto' mr={2}>
                      <strong>Ownership Group </strong> {ownershipGroupSearchIdFilter}
                    </Grid>
                    <Grid item xs>
                      <OwnershipGroupDropdown
                        allowFreeform
                        freshbooksClientId={first(invoiceItems)?.client?.freshbooksClientId ?? null}
                        ownershipGroupId={ownershipGroupSearchIdFilter}
                        onOwnershipGroupChanged={(ownershipGroup) => {
                          setSearchIdFilter(ownershipGroup ? intVal(ownershipGroup.id) : undefined);
                        }}
                        onOwnershipGroupFreeForm={(ownershipGroupString) => {
                          // TODO: Remove free-form support for ths one.
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid item container alignItems='center' xs={12} my={1}>
                    <Grid item>
                      <FormCheck
                        id='sendNowCheckbox'
                        checked={sendNowChecked}
                        onChange={(e) => setSendNowChecked(e.target.checked)}
                        label='Email now'
                      />
                    </Grid>
                  </Grid>

                  {
                    sendNowChecked
                    && !contactListToggle
                    && (
                      <Row>
                        <Col sm={3}>
                          <div><strong>Send to:</strong></div>
                        </Col>
                        <Col>
                          {(billingContactsList ?? [])
                            .filter(bc => checkedBillingContacts.includes(bc.id))
                            .map(
                              bc => <div key={bc.id} >{getEmailStringFromContactInformation(bc.billingContact)}</div>,
                            )
                          }
                        </Col>
                        <RequirePermission grant="FULL_ACCESS">
                          <Col sm={2}>
                            <Button size="sm" variant="secondary" onClick={() => setContactListToggle(true)}>
                              Choose
                            </Button>
                          </Col>
                        </RequirePermission>
                      </Row>
                    )
                  }
                  {
                    sendNowChecked
                    && contactListToggle
                    && (
                      <Grid item xs={12}>
                        <Table component={Paper}>
                          <TableHead>
                            <StyledTableRow onClick={() => setContactListToggle(false)}>
                              <StyledTableCell colSpan={2}>Contacts ({accountIdFilter})</StyledTableCell>
                              <StyledTableCell style={{ textAlign: 'center', width: '80px' }}>OG ID</StyledTableCell>
                              <StyledTableCell style={{ textAlign: 'center', width: '80px' }}>Invoices</StyledTableCell>
                            </StyledTableRow>
                          </TableHead>
                          <TableBody>
                            {
                              billingContactsList
                              && (billingContactsList.slice())
                                .sort((a, b) => a.receivesInvoices ? -1 : 1)
                                .filter((bc) => bc.ownershipGroupId === null)
                                .map(bc => (
                                  <StyledTableRow
                                    key={bc.id}
                                    onClick={() => {
                                      setCheckedBillingContact(bc.id, !checkedBillingContacts.includes(bc.id));
                                    }}
                                  >
                                    <StyledTableCell style={{ textAlign: 'center' }}>
                                      <FormCheck
                                        checked={checkedBillingContacts.includes(bc.id)}
                                        onChange={(e) => { setCheckedBillingContact(bc.id, e.target.checked); }}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {getEmailStringFromContactInformation(bc.billingContact)}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ textAlign: 'center' }}>
                                      {bc.ownershipGroupId ?? 'None'}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ textAlign: 'center' }}>
                                      {bc.receivesInvoices ? 'Yes' : 'No'}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))
                            }
                          </TableBody>
                        </Table>
                      </Grid>
                    )
                  }

                  <Grid item mt={3} xs={12}>
                    {!doesClientHasTaxes && <Typography color="#ff0000">Client is missing taxes</Typography>}
                    {
                      hasInconsistentClientPartnerBilling
                      && <Typography color="#ff0000">Subscriptions have inconsistent client partner billing</Typography>
                    }
                    <Button
                      variant='primary'
                      disabled={
                        loadedState === 'loading'
                        || hasInconsistentClientPartnerBilling
                        || !doesClientHasTaxes
                      }
                      onClick={() => {
                        const billingContactIds = (
                          (billingContactsList ?? [])
                            .filter(bc => (checkedBillingContacts ?? []).includes(bc.id)).map(bc => bc.billingContactId)
                        );
                        const subscriptionIds = invoiceItems.map(subscription => subscription.id!);
                        if (sendNowChecked && billingContactIds.length === 0) {
                          showMessage({ message: 'Must select at least one contact to send invoice to.' });
                          return;
                        }
                        if (subscriptionIds.length === 0) {
                          showMessage({ message: 'Must add at least one subscription to the invoice.' });
                          return;
                        }

                        const subscriptionMonths =  distinct(
                          invoiceItems.map((subscription) => (
                            dateFromYmd(subscription.startDate || '')
                          ).getMonth()),
                        );
                        const firstSubscription = first(invoiceItems)!;

                        if(subscriptionMonths.length !== 1 || invoiceDate.getMonth() !== first(subscriptionMonths)){
                          showMessage({
                            message: (
                              'A subscriptions start date month doesn\'t match the invoice date month. '
                            + `Subscription ${firstSubscription.id}`
                            + `Start date: ${firstSubscription.startDate}`
                            + `Invoice date: ${invoiceDate.toString()}, `
                            ),
                          });
                          return;
                        }

                        createOneTimeFeeInvoice({
                          billingContactIds,
                          subscriptionIds,
                          sendNow: sendNowChecked,
                          invoiceDate: makeYmd(invoiceDate),
                          ownershipGroupId: ownershipGroupSearchIdFilter ? ownershipGroupSearchIdFilter: null,
                          isPartnerOtf,
                        });
                      }}
                    >
                      {sendNowChecked ? `Publish & Send Now` : `Publish as Draft`}
                    </Button>
                  </Grid>
                </Grid>
              </Row>
            )
          }
        </Grid>
      </Grid>
    </>
  );
};
