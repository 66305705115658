import { useState } from "react";

import {
  Box,
  Button,
  Card,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";

import {
  floatVal,
  Hash,
  HashOf,
  intVal,
  isDefinedObject,
  keys,
  Nullable,
  strVal,
} from "@jamesgmarks/utilities";

import { fixDataIntegrityCheckError } from "../../../../redux/features/data-integrity-checks/actions";
import { FixFormCheckbox, FixFormMultiSelect, FixFormTextField } from "./FixFormComponents";
import {
  IDataIntegrityCheckError,
  TFixFnArgumentOption,
  TFixFnArgumentsSchema,
  TFixFnArgumentTypes,
} from "../../../../redux/features/data-integrity-checks/interfaces";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

type fixFormComponentParameters = {
  label: string,
  onBlurHandler?:(newValue: string | boolean) => void
  onChangeHandler:(newValue: string | boolean| HashOf<Hash>) => void
  required?: boolean,
  type: TFixFnArgumentTypes,
  value: TFixFnArgumentOption
}

const fixFormBooleanComponent = (
  props: fixFormComponentParameters,
) =>{
  const {value, label, onChangeHandler} = props;
  return <FixFormCheckbox
    checked={!!value}
    label={label}
    onChangeHandler={onChangeHandler}
  />;
};

const fixFormMultiSelectComponent= (
  props: fixFormComponentParameters,
)  =>{
  const {value, onChangeHandler} = props;

  return <FixFormMultiSelect
    value={value}
    onChangeHandler={onChangeHandler}
  />;
};

const fixFormDefaultComponent = (
  props: fixFormComponentParameters,
) =>{
  const {value, label, required, onBlurHandler, onChangeHandler} = props;
  return     <FixFormTextField
    disabled={label === 'id'}
    label={`${label}`}
    onBlurHandler={onBlurHandler}
    onChangeHandler={onChangeHandler}
    required={required}
    value={strVal(value)}
  />;
};

const fixFormUIMap : Record<TFixFnArgumentTypes, (props: fixFormComponentParameters) => JSX.Element> = {
  'string': fixFormDefaultComponent,
  'object': fixFormDefaultComponent,
  'int': fixFormDefaultComponent,
  'float': fixFormDefaultComponent,
  'boolean': fixFormBooleanComponent,
  'multiselect': fixFormMultiSelectComponent,
};

const getArgumentInputComponent = (
  props: fixFormComponentParameters,
) => {
  const {
    label, onBlurHandler, onChangeHandler, required, type, value,
  } = props;
  return fixFormUIMap[type]({
    label,
    onBlurHandler,
    onChangeHandler,
    required,
    type,
    value,
  });
};

interface IFixFormProps {
  dataIntegrityCheckName: string;
  fixFnArguments: TFixFnArgumentsSchema;
  fixFnName: string;
  handleRemedied: () => void;
  message: string;
  objectId?: string;
  errorData: IDataIntegrityCheckError;
}

/**
 * Renders input elements and tracks state of those elements, for submission to
 * a 'fix function'. This function will use the specified parameters to fix the
 * data integrity error.
 * @param props.dataIntegrityCheckName The name of the respective data integrity check.
 * @param props.message A message that describes the fix.
 * @param props.fixFnArguments An object that describes the 'fix function' arguments
 * and their properties.
 * @param props.fixFnName The name of the 'fix function' for this data integrity error.
 * @returns A form that manages the data for a data integrity error 'fix function'.
 */
export const FixForm = (
  {
    dataIntegrityCheckName,
    fixFnArguments,
    fixFnName,
    handleRemedied,
    message,
    objectId = '',
    errorData,
  }: IFixFormProps,
) => {
  const parseJsonString = (str: string): { isParseable: boolean, parsedString: Nullable<Hash> } => {
    try {
      const parsedString = JSON.parse(str);

      return {
        isParseable: true,
        parsedString,
      };
    } catch (e: unknown) {
      return {
        isParseable: false,
        parsedString: null,
      };
    }
  };

  /**
     * A map storing functions that convert a controlled MUI input's value into a value in state.
     * The received value will come from either a `TextField` or `CheckBox` component.
     * @param x The value from the input, either a `string` or `boolean`.
     * @returns A function that converts the value from the input, either a `string` or `boolean`, into
     * a type that a 'fix function' can use.
     */
  const argumentToStateConvertersForChange: Record<
  TFixFnArgumentTypes,
  (x: string | boolean | HashOf<Hash>) => TFixFnArgumentOption
  > = {
    'boolean': (x) => !!x,
    'float': (x) => x,
    'int': intVal,
    'object': (x) => x,
    'string': strVal,
    'multiselect': (x) => x,
  };

  /**
   * Different functions may be necessary for `onBlur` so that the user can type
   * 'invalid' syntax that leads to valid syntax for the value type.
   */
  const argumentToStateConvertersForBlur: Record<
  TFixFnArgumentTypes,
  (x: string | boolean) => TFixFnArgumentOption
  > = {
    'boolean': (x) => !!x,
    'float': floatVal,
    'int': intVal,
    'object':
      (x) => {
        const { isParseable, parsedString } = parseJsonString(strVal(x));

        return (
          typeof x === 'string'
          && isParseable
          && isDefinedObject(parsedString)
        )
          ? parsedString
          : null;
      },
    'string': (x) => strVal(x) || null,
    'multiselect': (x) => (x),
  };

  const [ formState, setFormState ] = useState<HashOf<TFixFnArgumentOption>>(
    keys(fixFnArguments)
      .reduce(
        (acc, argumentName) => (
          {
            ...acc,
            [argumentName]: fixFnArguments[argumentName].default ??
              (fixFnArguments[argumentName].type === 'boolean'
                ? false
                : argumentName === 'id'
                  ? objectId
                  : (errorData.fixFunctionDefaults)?.[argumentName] ?? null
              ),
          }
        ),
        {},
      ),
  );

  const submitHandler = async (
    e: React.FormEvent,
    dataIntegrityCheckName: string,
    fixFnName: string,
  ) => {
    e.preventDefault();

    const fixResult = await fixDataIntegrityCheckError(dataIntegrityCheckName, fixFnName, {...formState, errorData});

    fixResult && handleRemedied();
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography> {fixFnName}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container columnSpacing={5} rowSpacing={2}>
          <Grid item xs={12}>
            <hr />
          </Grid>
          <Grid item xs={7}>
            <Card
              elevation={10}
              sx={
                {
                  backgroundColor: '#c0e8ff',
                  border: '2.5px solid white',
                  borderRadius: 4.5,
                  boxShadow: '5px 7px 10px gray',
                  p: 2,
                }
              }
            >
              <form onSubmit={(e) => submitHandler(e, dataIntegrityCheckName, fixFnName)}>
                <Typography sx={{ color: '#2d414c', mt: 1 }}>{message}</Typography>
                {
                  keys(fixFnArguments)
                    .map((argumentName) => {
                      const type = fixFnArguments[argumentName].type;
                      const required = fixFnArguments[argumentName].required;
                      const helperText = fixFnArguments[argumentName].helperText;

                      return (
                        <Card
                          key={argumentName}
                          elevation={3}
                          sx={
                            {
                              backgroundColor: '#ededed',
                              borderRadius: 3.5,
                              mt: 2,
                              p: 1,
                              pl: 1.6,
                              pt: 2,
                            }
                          }
                        >
                          {
                            getArgumentInputComponent(
                              {
                                onBlurHandler: (newValue) => setFormState(
                                  (old) => (
                                    {
                                      ...old,
                                      [argumentName]: argumentToStateConvertersForBlur[type](newValue),
                                    }
                                  ),
                                ),
                                onChangeHandler:
                            (newValue) => setFormState(
                              (old) => (
                                {
                                  ...old,
                                  [argumentName]: argumentToStateConvertersForChange[type](newValue),
                                }
                              ),
                            ),
                                label: strVal(argumentName),
                                required,
                                type,
                                value: argumentName === 'id' ? objectId : formState[argumentName],
                              },
                            )
                          }

                          <FormHelperText>
                            {required && <span style={{ fontWeight: 800 }}>Required.</span>} <span>{helperText}</span>
                          </FormHelperText>
                        </Card>
                      );
                    })
                }

                <Box mt={3} pr={1.5} textAlign='right'>
                  <Button
                    color='secondary'
                    size='small'
                    type='submit'
                    variant='contained'
                  >
                Fix Error
                  </Button>
                </Box>
              </form>
            </Card>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
