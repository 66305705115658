import { Route, Switch } from 'react-router-dom';

import { CreditNoteListView } from './CreditNoteListView';
import { CreditNoteSingleView } from './CreditNoteSingleView';
import { CreditNoteViewContainer } from './CreditNoteViewContainer';
import { ICreditNote } from '../../../interfaces/ICreditNote';
import { saveCreditNote } from '../../../redux/features/credit-notes/actions';

export const CreditNotesContainer = () => {
  const onSave = (creditNote: ICreditNote) => {
    saveCreditNote(creditNote);
  };

  return (
    <div>
      <header className="Section-header">Credit Notes</header>
      
      <Switch>
        <Route path="/credit_notes/add_credit">
          <CreditNoteSingleView creditNote={null} onSave={onSave} />
        </Route>

        <Route path="/credit_notes/:creditNoteId">
          <CreditNoteViewContainer />
        </Route>
        
        <Route path="/credit_notes/">
          <CreditNoteListView/>
        </Route>
      </Switch>
    </div>
  );
};
