import { useEffect } from "react";
import { Switch, useLocation } from "react-router-dom";

import { useAppSelector } from "../../../redux/hooks";
import { loadCustomReportsList } from "../../../redux/features/reporting/actions";
import { ucaseFirst } from "../../../app-utils";

import { AuthRoute } from "../../parts/AuthRoute";
import { MonthLock } from "./MonthLock";
import { ReportingListView } from "./ReportingListView";
import { MonthlyBillingReport } from "./Reports/MonthlyBillingReport/MonthlyBillingReport";
import { PartnerCallTrackingLinesSummary } from "./Reports/PartnerCallTrackingLinesSummary";
import { PadmapperMonthlySummary } from "./Reports/PadmapperMonthlySummary";
import { TaxBreakdownByCurrency } from "./Reports/TaxBreakdownByCurrency/TaxBreakdownByCurrency";
import { RunReport } from "./RunReport";

export const ReportingContainer = () => {
  const customReportsBreakdown = useAppSelector((state) => state.reporting.customReportsBreakdown);

  const location = useLocation();

  const reports = [
    ...(
      !location.pathname.endsWith('/runners')
        ? [ { name: 'Monthly Billing Summary', path: '/monthly_summary' } ]
        : []
    ),
    ...(
      (customReportsBreakdown ?? []).map((rbd) => ({
        name: `CSV: ${rbd.runnerName.split('_').map(ucaseFirst).join(' ')}`,
        path: `/runner/${rbd.runnerName}`,
      }))
    ),
  ];

  useEffect(() => { loadCustomReportsList(); }, []);

  return (
    <>
      <Switch>
        <AuthRoute path="/reporting/reports/padmapper_monthly_summary"><PadmapperMonthlySummary /></AuthRoute>
        <AuthRoute path="/reporting/reports/month_locks"><MonthLock /></AuthRoute>
        <AuthRoute path="/reporting/reports/monthly_summary"><MonthlyBillingReport /></AuthRoute>
        <AuthRoute path="/reporting/reports/partner_call_tracking_lines_summary">
          <PartnerCallTrackingLinesSummary />
        </AuthRoute>
        <AuthRoute path="/reporting/reports/tax_breakdown_summary"><TaxBreakdownByCurrency /></AuthRoute>
        <AuthRoute path="/reporting/reports/runner/:reportName"><RunReport /></AuthRoute>
        <AuthRoute path="/reporting/reports/runners"><ReportingListView reports={(reports || [])} /></AuthRoute>
        <AuthRoute path="/reporting/reports"><ReportingListView reports={(reports || [])} /></AuthRoute>
        <AuthRoute path="/reporting"><ReportingListView reports={(reports || [])} /></AuthRoute>
      </Switch>
    </>
  );
};
