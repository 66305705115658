import {
  TextField as MuiTextField,
} from "@mui/material";

interface INumberFieldProps {
  label: string,
  value: string,
  type: 'int' | 'float',
  increment?: number,
  onChange: (React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>),
}

export const NumberField = ({
  label,
  value,
  type,
  increment,
  onChange,
}: INumberFieldProps) => {
  return (
    <MuiTextField
      label={label}
      fullWidth={true}
      value={value}
      type='number'
      inputProps={{
        // max: 100,
        // min: 1,
        step: increment,
      }}
      placeholder={type}
      variant="outlined"
      onChange={onChange}
    />
  );
};
