import { TextField, TextFieldProps } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

interface IDatePickerFieldProps {
  label: string;
  value: string | Date | null;
  options?: Array<'day' | 'month' | 'year'>;
  textFieldBackgroundColor?: string;
  showToolbar?: boolean;
  disabled?: boolean;
  onChange: ((newValue: Date | null) => void);
}

export const DatePickerField = ({
  label,
  value,
  options = ['day', 'month', 'year'],
  showToolbar = false,
  textFieldBackgroundColor = '#fff',
  disabled = false,
  onChange,
}: IDatePickerFieldProps) => {
  /**
   * It takes an array of strings representing the options for the datepicker,
   * and returns a string for the date format to be displayed on date picker text field
   * @param options - Array<'day'|'month'|'year'>
   * @returns A string that is a date format.
   */
  const getInputFormat = (options: Array<'day' | 'month' | 'year'>): string => {
    const format = (
      `${options.includes('year') ? 'yyyy' : ''}`
      + `${options.includes('month') ? 'MM' : ''}`
      + `${options.includes('day') ? 'dd' : ''}`
    );
    return format.split('').reduce((formatAccumulator, letter) => (
      (formatAccumulator && letter !== formatAccumulator[formatAccumulator.length - 1])
        ? `${formatAccumulator}-${letter}`
        : `${formatAccumulator}${letter}`
    ), '');
  };

  const dateFormatter = (stringDate: string) => stringDate;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        disabled={disabled}
        label={label}
        value={value}
        inputFormat={getInputFormat(options)}
        onChange={onChange}
        views={options}
        openTo={options[0]}
        rifmFormatter={dateFormatter}
        renderInput={(props: TextFieldProps) => <TextField sx={{ background: textFieldBackgroundColor }} {...props} />}
        showToolbar={showToolbar}
      />
    </LocalizationProvider>
  );
};
