import { Dispatch, SetStateAction, useState } from "react";
import { Button, IconButton, Tooltip } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { intVal, Nullable, strVal } from "@jamesgmarks/utilities";

import { dateFromYmd, makeYmd } from "../../../app-utils";
import { EBillingRunScheduleStatus, IBillingRunSchedule } from "../../../interfaces/IBillingRunSchedule";
import { StyledTableCell, StyledTableRow } from "../../parts/mui/StyledTables";
import { deleteBillingRunSchedule } from "../../../redux/features/billing-runs/actions";
import { emptyFormData, IFormData } from "./BillingRunSchedules";

interface IExistingBillingRunScheduleRowProps {
    billingRunSchedule: IBillingRunSchedule;
    currentlyEditedId: Nullable<number>;
    setCurrentlyEditedId: Dispatch<SetStateAction<Nullable<number>>>;
    setEditing: Dispatch<SetStateAction<boolean>>;
    setFormData: Dispatch<SetStateAction<IFormData>>;
}

export const ExistingBillingRunScheduleRow = ({
  billingRunSchedule: { id, year, month, billingDate, status },
  currentlyEditedId,
  setCurrentlyEditedId,
  setEditing,
  setFormData,
}: IExistingBillingRunScheduleRowProps) => {
  const [ showDeleteConfirmation, setShowDeleteConfirmation ] = useState(false);

  const canEdit = status === EBillingRunScheduleStatus.pending;
  const canDelete = canEdit;

  const handleEdit = () => {
    setEditing(true);
    setCurrentlyEditedId(id);
    setShowDeleteConfirmation(false);

    const [ billingDateYear, billingDateMonth, billingDateDate ] = strVal(billingDate).split('-');

    setFormData(
      {
        id,
        year: strVal(year),
        month: strVal(month),
        billingDate: new Date(intVal(billingDateYear), intVal(billingDateMonth) - 1, intVal(billingDateDate)),
      },
    );
  };

  return (
    <StyledTableRow
      customSx={ currentlyEditedId === id ? { border: '3px solid #50c878' } : {} }
    >
      <StyledTableCell>{`${year}-${strVal(month).padStart(2, '0')}`}</StyledTableCell>
      <StyledTableCell>{makeYmd(dateFromYmd(strVal(billingDate)), true)}</StyledTableCell>
      <StyledTableCell>

        <Tooltip
          title='Cannot edit once ran.'
          placement='right'
          disableFocusListener={canEdit}
          disableHoverListener={canEdit}
          disableTouchListener={canEdit}
        >
          <span>
            <IconButton
              disabled={!canEdit}
              onClick={handleEdit}
            >
              <EditIcon
                sx={{
                  backgroundColor: canEdit ? '#1976d2' : 'lightgray',
                  borderRadius: '5px',
                  color: 'white',
                  cursor: 'pointer',
                  fontSize: '2rem',
                  padding: 1,
                  '&:hover': { opacity: 0.8 },
                }}
              />
            </IconButton>
          </span>
        </Tooltip>
      </StyledTableCell>
      <StyledTableCell>
        {
          showDeleteConfirmation
            ? (
              <Button
                disabled={!canDelete}
                color='error'
                onClick={() => {
                  deleteBillingRunSchedule(id);
                  setShowDeleteConfirmation(false);
                  setEditing(false);
                  setFormData(emptyFormData);
                }
                }
                size='small'
                variant='contained'
              >
                Confirm
              </Button>
            )
            : (
              <Tooltip
                title='Cannot delete once ran.'
                placement='right'
                disableFocusListener={canDelete}
                disableHoverListener={canDelete}
                disableTouchListener={canDelete}
              >
                <span>
                  <IconButton
                    disabled={!canDelete}
                    onClick={() => setShowDeleteConfirmation(true) }
                  >
                    <DeleteIcon
                      sx={{
                        backgroundColor: canDelete ? 'crimson' : 'lightgray',
                        borderRadius: '5px',
                        color: 'white',
                        cursor: 'pointer',
                        fontSize: '2rem',
                        padding: 1,
                        '&:hover': { opacity: 0.8 },
                      }}
                    />
                  </IconButton>
                </span>
              </Tooltip>
            )
        }
      </StyledTableCell>
    </StyledTableRow>
  );
};
