import { HashOf } from "@jamesgmarks/utilities";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

interface IMultiSelectFieldProps {
  label: string;
  value: string;
  options: HashOf<string>;
  onChange: ((event: SelectChangeEvent, child: React.ReactNode) => void);
}

export const MultiSelectField = ({
  label,
  value,
  options,
  onChange,
}: IMultiSelectFieldProps) => {
  return (
    <FormControl variant="outlined"
      fullWidth={true}>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        fullWidth={true}
        onChange={onChange}
        label={value}
      >
        <MenuItem value="" selected={!value}>
          <em>None</em>
        </MenuItem>
        {Object.keys(options).map((key) => {
          return <MenuItem key={key} value={key} selected={value === key}>{options[key]}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};
