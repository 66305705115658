import React from 'react';
import {
  Box,
  CircularProgress,
  Grid,
} from "@mui/material";

interface TableProps<T> {
  data: T[];
  rowComponent: React.ComponentType<{ item: T }>;
  columnsComponent: React.FC<{}>;
  loadingState: string; 
  dark?: boolean;
  spacing?: number;
}

const CustomDataTable = <T extends {}>(
  {
    data,
    rowComponent: RowComponent,
    columnsComponent: ColumnsComponent,
    loadingState = '',
    dark = false,
    spacing = 0,
  } : TableProps<T>,
) => {

  const DEFAULT_LIGHT = '#fff';
  const lightRowCss = {
    backgroundColor: DEFAULT_LIGHT,
    color: 'black',
  };

  const DEFAULT_DARK = '#3e3e3e';
  const darkRowCss = {
    backgroundColor: DEFAULT_DARK,
    color: 'white',
  };

  const variantCss = dark ? darkRowCss : lightRowCss;

  return (
    <Grid container spacing={spacing} sx={{ ...variantCss, padding: '1' }}>
      <Grid item container>
        <ColumnsComponent/>
      </Grid>
      {
        loadingState === 'loading' 
          ? 
          <Grid item container justifyContent="center" xs>
            <CircularProgress size={25} color='primary'/>
          </Grid>
          : 
          (
            data.length === 0 
              && 
              <Grid item container justifyContent="center" xs>
                <Box>NO DATA AVAILABLE</Box>
              </Grid>
          )
      }
      {data.map((item, index) => (
        <Grid item container key={index} >
          <RowComponent item={item}/>
        </Grid>
      ))}
    </Grid>
  );
};

export default CustomDataTable;