import { Box, IconButton, Modal } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from "src/redux/hooks";
import { showEmbed } from "src/redux/features/messaging/actions";
export const VideoEmbed = ({
}:{
}) => {
  const open = useAppSelector(state => state.messaging.embed.show);
  const url = useAppSelector(state => state.messaging.embed.url);
  const handleClose = () => showEmbed(false);
  const autoPlayParam = '?autoplay=1';
  const muteParam = '&mute=1';
  const src = url ?? "https://www.youtube.com/embed/hJresi7z_YM?si=zZaFCmFjSlfSy8Kf";
  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ width: '100vw', height: '100vh' }}
      >
        <Box sx={{alignItems: 'flex-start', textAlign: 'center' }}>
          <IconButton
            aria-label="close"
            onClick={() => {window.open(src+autoPlayParam, "_blank"); handleClose();} }
            sx={{
              color: (theme) => theme.palette.grey[500],
              position: 'absolute',
              right: Math.floor(Math.random() * (150 - 8 + 1)) + 8,
              top: Math.floor(Math.random() * (150 - 8 + 1)) + 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <iframe
            title="videoEmbed"
            width="640"
            height="480"
            src={src+autoPlayParam+muteParam}>
          </iframe>

        </Box>
      </Modal>
    </>

  );
};