import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { HHSimpleTable, IColumnConfig } from "src/components/parts/HHSimpleTable";
import { useCallback, useMemo } from "react";
import { floatVal } from "@jamesgmarks/utilities";
import { TEditorComponentProps } from "./TEditorComponentProps";
export const CommissionEditor = ({ subscription, setSubscriptionProp }: TEditorComponentProps) => {

  const commissionSchemeForSalesRep = useCallback((salesRepId: number) => {
    const commissionScheme = subscription?.commissionSchemes.find((cs) => cs.salesRepId === salesRepId);
    if (!commissionScheme) {
      return [];
    }
    return commissionScheme.scheme.rates;
  }, [subscription?.commissionSchemes]);

  const commissionRateTableColumnsConfig: IColumnConfig<{
    startDate: string | null;
    rate: string;
  }>[] = useMemo(() => (
    [
      {
        headerLabel: 'Start Date',
        fieldName: 'startDate',
      },
      {
        headerLabel: 'Commission Rate',
        fieldName: 'rate',
        format: (value) => `${floatVal(value) * 100}%`,
      },
    ]
  ), []);

  return <><label>
    <h4> Commissions </h4>
  </label>
  <div>
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="primary-salesrep-commission-content"
        id="primary-salesrep-commission-header"
      >
                    Primary Sales Rep: {subscription.salesRep?.user?.firstName} {subscription.salesRep?.user?.lastName}
      </AccordionSummary>
      <AccordionDetails>
        <HHSimpleTable<{startDate: string | null; rate: string;}>
          rows={subscription.salesRepId ? commissionSchemeForSalesRep(subscription.salesRepId) :[]}
          columns={commissionRateTableColumnsConfig}
          rowHover={false}
        />
      </AccordionDetails>
    </Accordion>
    { subscription.parentSalesRepId &&
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="parent-salesrep-commission-content"
            id="parent-salesrep-commission-header"
          >
          Parent Sales Rep: {
              subscription.parentSalesRep?.user?.firstName} {subscription.parentSalesRep?.user?.lastName
            }
          </AccordionSummary>
          <AccordionDetails>
            <HHSimpleTable<{startDate: string | null; rate: string;}>
              rows={commissionSchemeForSalesRep(subscription.parentSalesRepId)}
              columns={commissionRateTableColumnsConfig}
              rowHover={false}
            />
          </AccordionDetails>
        </Accordion>
    }
  </div>
  </>;
};