
import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useAuth } from '../../customHooks/useAuth';
import { Unauthorized } from '../pages/Unauthorized';

export const AuthRoute = ({ component, children, ...otherprops }: RouteProps) => {
  const { hasAccess } = useAuth();
  const Component = component;
  return <Route {...otherprops} render={(props) => (
    (hasAccess(`${otherprops.path}`, 'any'))
      ? (
        Component 
          ? <Component {...props}>{children}</Component>
          : <>{children}</>
      )
      : <Unauthorized />
  )} />;
};