import { useCallback, useEffect, useState } from 'react';

import { 
  Autocomplete, 
  TextField,
  Typography,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

import { Nullable } from '@jamesgmarks/utilities';

import { IClientDropdownItem } from '../../entity-interfaces/IClientDropdownItem';
import { loadDropDownClients } from '../../redux/features/clients/actions';
import { useAppSelector } from '../../redux/hooks';

type TClientMultiSelectDropdownProps = {
  allowedClientIds?: number[],
  defaultClientIds?: Nullable<number[]>,
  initialSearchString?: string,
  onClientChanged: (client: Nullable<IClientDropdownItem[]>) => void,
  showPartners?: boolean,
  starredClientIds?: Nullable<number[]>,
  textFieldBackgroundColor?: string,
}

export const ClientMultiSelectDropdown = (
  {
    allowedClientIds,
    defaultClientIds,
    onClientChanged,
    showPartners = true,
    textFieldBackgroundColor = '#fff',
    starredClientIds,
  }: TClientMultiSelectDropdownProps,
) => {
  const clientList = (
    useAppSelector(
      (state) => state.clients.clientDropDownSource
        .filter((c) => allowedClientIds ? allowedClientIds.includes(c.id) : true),
    )
  );
  
  const options = (
    clientList
      .slice()
      .sort((a, b) => a.name.trim().toLowerCase() < b.name.trim().toLowerCase() ? -1 : 1)
  );

  const [ clientQuery, setClientQuery ] = useState<Nullable<string>>(null);
  const [ selectedClient, setSelectedClient ] = useState<IClientDropdownItem[]>([]);
  
  const handleInputChange = (clientSearch: Nullable<string>) => {
    setClientQuery(clientSearch);
  };
  
  const handleClientSelected = useCallback(
    (client: Nullable<IClientDropdownItem[]>) => {
      const clientSelected = client ?? [];
      setSelectedClient(clientSelected);

      onClientChanged(clientSelected);

    }, [ onClientChanged ],
  );

  useEffect(() => {
    loadDropDownClients(showPartners);
  }, [ showPartners ]);

  useEffect(() => {
    if (defaultClientIds && clientList) {
      const client = clientList.filter((c) => defaultClientIds.includes(c.id));
      client && handleClientSelected(client);
    }
  }, [defaultClientIds, clientList, handleClientSelected]);

  return (
    <Autocomplete
      id="client-selector"
      autoComplete
      autoHighlight
      clearOnBlur
      clearOnEscape
      multiple
      fullWidth
      getOptionLabel={
        (option) => { 
          return `${option.name} (${option.type === 'client' ? 'Client' : 'Partner'} ID: ${option.id})`;
        }
      }
      inputValue={clientQuery ?? ''}
      onChange={
        (_e, newValue, _reason) => {
          handleClientSelected(newValue ?? null);
        }
      }
      onInputChange={
        (_e, value: string, _reason) => {
          handleInputChange(value);
        }
      }
      options={options}
      renderInput={
        (params) =>  (
          <TextField
            { ...params }
            label="Client"
            sx={{ backgroundColor: textFieldBackgroundColor }}
            variant="outlined"
          />
        )
      }
      renderOption={
        (props, option) => {
          return (
            <li {...props}>
              <Typography>
                <span style={{ color: 'dodgerblue', fontWeight: 550 }}>{option.name}</span>
                <span style={{ color: 'gray' }}> | </span>
                <span>
                  {`${option.type === 'client' ? 'Client': 'Partner'} ID: ${option.id}`}
                </span>
                { option.status !== 'active'
                 && <>
                   <span style={{ color: 'gray' }}> | </span>
                   <span style={{ color: 'red', fontWeight: 550 }}>
                     {option.status}
                   </span>
                 </>
                }
              </Typography>
              { starredClientIds && starredClientIds.includes(option.id) && <StarIcon sx={{ ml: 2, mb: 0.3 }} />}
            </li>
          );
        }
      }
      size="small"
      value={selectedClient}
    />
  );
};
