import { intVal, Nullable, strVal } from "@jamesgmarks/utilities";

export interface IVersionCompareOptions {
  lexicographical?: boolean,
  zeroExtend?: boolean,
}

/**
 * Compares two software version numbers (e.g. "1.7.1" or "1.2b").
 *
 * This function was born in http://stackoverflow.com/a/6832721.
 *
 * @param v1 The first version to be compared.
 * @param v2 The second version to be compared.
 * @param options Optional flags that affect comparison behavior:
 *
 * - lexicographical: true
 *     - compares each part of the version strings lexicographically instead of
 *       naturally; this allows suffixes such as "b" or "dev" but will cause "1.10" to be considered smaller than "1.2".
 * - zeroExtend: true
 *     - changes the result if one version string has less parts than the other. In
 *       this case the shorter string will be padded with "zero" parts instead of being considered smaller.
 *
 * @returns
 *    - 0 if the versions are equal
 *    - a negative integer iff v1 < v2
 *    - a positive integer iff v1 > v2
 *    - null if either version string is in the wrong format
 *
 * @copyright by Jon Papaioannou (["john", "papaioannou"].join(".") + "@gmail.com")
 * @license This function is in the public domain. Do what you want with it, no strings attached.
 *
 * @author Jon Papaioannou (original Javascript: https://gist.github.com/TheDistantSea/8021359)
 * @author James Marks (Typescript annotations)
 */
export const versionCompare = (v1: string, v2: string, options: IVersionCompareOptions): Nullable<Number> => {
  const lexicographical = options && options.lexicographical;
  const zeroExtend = options && options.zeroExtend;
  let v1parts: (number|string)[] = v1.split('.');
  let v2parts: (number|string)[] = v2.split('.');

  const isValidPart = (x: string) => {
    return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
  };

  if (!v1parts.map(strVal).every(isValidPart) || !v2parts.map(strVal).every(isValidPart)) {
    return null;
  }

  if (zeroExtend) {
    while (v1parts.length < v2parts.length) v1parts.push("0");
    while (v2parts.length < v1parts.length) v2parts.push("0");
  }

  if (!lexicographical) {
    v1parts = v1parts.map(intVal);
    v2parts = v2parts.map(intVal);
  }

  for (let i = 0; i < v1parts.length; ++i) {
    if (v2parts.length === i) {
      return 1;
    }

    if (v1parts[i] === v2parts[i]) {
      continue;
    } else {
      return (v1parts[i] > v2parts[i]) ? 1 : -1;
    }
  }

  return (v1parts.length !== v2parts.length) ? -1 : 0;
};