import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import {
  Grid,
  Paper,
} from "@mui/material";

import { ucaseFirst } from "../../../app-utils";
import { useAppSelector } from "../../../redux/hooks";
import { RunnerFieldsForm } from "../../parts/runner-form/RunnerFieldsForm";
import { loadCustomReportsList, runCustomReportCsv } from "../../../redux/features/reporting/actions";

export const RunReport = () => {
  const customReportsBreakdown = useAppSelector((state) => state.reporting.customReportsBreakdown);
  const loadedState = useAppSelector((state) => state.reporting.loadedState);

  const { reportName } = useParams<{ reportName: string }>();

  useEffect(() => { loadCustomReportsList(); }, []);

  const currentReportBreakdown = useMemo(
    () => customReportsBreakdown?.find((cr) => cr.runnerName === reportName)!,
    [customReportsBreakdown, reportName],
  );

  return (<>
    <br />
    <header className="Section-header">
      Download Report: {currentReportBreakdown?.runnerName.split('_').map(ucaseFirst).join(' ')}
    </header>
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={10}>
        <Paper>
          <Grid container justifyContent="center" spacing={2} mt={7}>
            <Grid item xs={10} style={{ textAlign: 'left' }}><h4>Download Report:</h4></Grid>
            <Grid item xs={10} style={{ textAlign: 'right' }}>
              <RunnerFieldsForm
                fields={currentReportBreakdown.runnerFields}
                submitDisabled={loadedState !== 'loaded'}
                onSubmit={(parameters) => {
                  runCustomReportCsv(reportName, parameters);
                }}
                buttonLabel="Download"
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  </>);
};
