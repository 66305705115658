import { createSlice } from '@reduxjs/toolkit';

import { merge } from '@jamesgmarks/utilities';

import { BillingRunSchedule } from '../../../../../entities/hydra';
import { IBillingRun } from '../../../entity-interfaces/IBillingRun';
import { IClientRuntimeGroup } from '../../../entity-interfaces/IClientRuntimeGroup';
import { refreshBillingRuns } from './actions';

export interface IBillingRunState {
  billingRunSchedules: BillingRunSchedule[],
  billingRunSchedulesLoadState: EBillingRunScheduleLoadedState,
  loadState: EBillingRunLoadedState,
  runs: IBillingRun[],
  runtimeGroups?: IClientRuntimeGroup[],
};

export enum EBillingRunLoadedState {
  idle = 'idle',
  loading = 'loading',
  loaded = 'loaded',
}

export enum EBillingRunScheduleLoadedState {
  idle = 'idle',
  loading = 'loading',
  loaded = 'loaded',
}

export const billingRunSlice = createSlice({
  name: 'billing-runs',
  initialState: {
    billingRunSchedules: [],
    billingRunSchedulesLoadState: EBillingRunScheduleLoadedState.idle,
    loadState: EBillingRunLoadedState.idle,
    runs: [],
  } as IBillingRunState,
  reducers: {
    billingRunListReceived: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.runs = action.payload; // TODO: Fix remote endpoint to use IApiQueryResponse (ISSUE:#177)
      state.loadState = EBillingRunLoadedState.idle;
    },
    billingRunScheduleCreated: (state, action: { type: string, payload: BillingRunSchedule }) => {
      state.billingRunSchedules.push(action.payload);
    },
    billingRunScheduleDeleted: (state, action: { type: string, payload: number }) => {
      state.billingRunSchedules = state.billingRunSchedules.filter((sch) => sch.id !== action.payload);
    },
    billingRunSchedulesReceived: (state, action: { type: string, payload: BillingRunSchedule[] }) => {
      state.billingRunSchedules = action.payload;
    },
    billingRunScheduleUpdated: (state, action: { type: string, payload: BillingRunSchedule }) => {
      state.billingRunSchedules = [
        ...state.billingRunSchedules.filter((sch) => sch.id !== action.payload.id),
        action.payload,
      ];
    },
    billingRunUpdated: (state, { type, payload }) => {
      const updateData = payload;
      const runId = updateData.billingRun.id;
      const run = (state.runs ?? []).find(r => r.id === runId);
      const newRun = (
        run
          ? merge(run, updateData.billingRun)
          : updateData.billingRun
      ) as IBillingRun;

      state.runs = [
        ...(state.runs ?? []).filter(r => r !== run),
        newRun,
      ];
    },
    orchestrationResponseReceived: (state, action) => {
      refreshBillingRuns();
    },
    runtimeGroupsListReceived: (state, action) => {
      state.runtimeGroups = action.payload;
    },
    setBillingRunScheduleLoadState: (state, action: { type: string, payload: EBillingRunScheduleLoadedState }) => {
      state.billingRunSchedulesLoadState = action.payload;
    },
    setLoadState: (state, action: { type: string, payload: EBillingRunLoadedState }) => {
      state.loadState = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  billingRunListReceived,
  billingRunScheduleCreated,
  billingRunScheduleDeleted,
  billingRunSchedulesReceived,
  billingRunScheduleUpdated,
  billingRunUpdated,
  orchestrationResponseReceived,
  runtimeGroupsListReceived,
  setBillingRunScheduleLoadState,
  setLoadState,
} = billingRunSlice.actions;

export default billingRunSlice.reducer;
