import { dispatch } from '../../store';
import { IApiQueryListResponse, IApiQuerySingularResponse, typedApiFetch } from '../../utils';
import {
  sandboxListReceived,
  updateLastSandboxResult,
  setSandboxLoadedState,
} from './sandboxSlice';
import { REACT_APP_API_ROOT_URI } from '../../../App';
import { ISandbox } from '../../../interfaces/ISandbox';
import { Hash } from '@jamesgmarks/utilities';

export const loadSandboxes = async () => {
  const url = `${REACT_APP_API_ROOT_URI}/sandbox/get_all_sandboxes`;
  console.info(`Loading sandboxes.`, { url });
  const response = await typedApiFetch<IApiQueryListResponse<ISandbox>>(url);
  const responseData = await response.json();
  dispatch(sandboxListReceived(responseData.data));
};

export const runSandbox = async (sandboxname: string, parameters: Hash) => {
  dispatch(setSandboxLoadedState({ loadedState: 'loading' }));
  const url = `${REACT_APP_API_ROOT_URI}/sandbox/run_sandbox`;
  const response = (
    await typedApiFetch<
    IApiQuerySingularResponse<{
      sandboxname: string,
      parameters: Hash,
      sandbox_response: Hash,
    }>
    >(
      url, {
        method: 'POST',
        body: JSON.stringify({
          sandboxname,
          parameters,
        }),
      },
    )
  );
  const responseOut = await response.json();
  dispatch(updateLastSandboxResult({ sandboxname, output: responseOut.data.sandbox_response }));
};
