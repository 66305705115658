import { createSlice } from '@reduxjs/toolkit';
import { IActionType } from '../../utils';
import { IDownloadStats } from './IDownloadStats';

export interface IHubspotState {
  loadedState: string,
  totalInvoiceCount: number,
  totalUniqueDownloadCount: number,
};

export const pdfGenerationsSlice = createSlice({
  name: 'pdfGenerations',
  initialState: {
    loadedState: 'idle',
    totalInvoiceCount: 0,
    totalUniqueDownloadCount: 0,
  } as IHubspotState,
  reducers: {
    setLoadedState: (state, action: { type: string, payload: string }) => {
      state.loadedState = action.payload;
    },
    processDownloadStats: (state, action: IActionType<IDownloadStats>) => {
      state.totalInvoiceCount = action.payload.stats.totalInvoiceCount;
      state.totalUniqueDownloadCount = action.payload.stats.totalUniqueDownloadCount;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoadedState,
  processDownloadStats,
} = pdfGenerationsSlice.actions;

export default pdfGenerationsSlice.reducer;
