import { intVal } from "@jamesgmarks/utilities";
import { SelectChangeEvent } from "@mui/material";

const toString = (value: string) => value.toString() as never;

export const handleInputChange = <T>(
  setter: React.Dispatch<React.SetStateAction<T>>,
  valueTransform: ((value: string) => T) = toString,
): React.ChangeEventHandler<HTMLInputElement> => {
  return (e: React.ChangeEvent<HTMLInputElement>) => setter(valueTransform(e.target.value));
};

export const handleInputBlur = <T>(
  setter: React.Dispatch<React.SetStateAction<T>>,
  valueTransform: ((value: string) => T) = toString,
): React.FocusEventHandler<HTMLInputElement> => {
  return (e: React.FocusEvent<HTMLInputElement>) => setter(valueTransform(e.target.value));
};

export const numberSelectValueTransform = (value: unknown) => value === '' ? null : intVal(value);

export interface ISelectChangeHandler {
  (event: SelectChangeEvent<string>, child: React.ReactNode): void
}

export const handleSelectChanged = <P>(
  setter: React.Dispatch<React.SetStateAction<P>>,
  valueTransform: ((value: unknown) => P),
): ISelectChangeHandler => (
  (event, child) => setter(valueTransform(event.target.value))
);
