import {
  IBillingFrequencies,
  IBillingTypes,
  IPartners,
  IServices,
  ISubscriptions,
} from '@llws/lift-entity-interfaces';
import { createSlice } from '@reduxjs/toolkit';
import { Charge } from '../../../../../entities/hydra/Charge';
import { IClientRuntimeGroup } from '../../../entity-interfaces/IClientRuntimeGroup';
import { IActionType } from '../../utils';

export interface ILookupsState {
  billingFrequencies: IBillingFrequencies[],
  partners: IPartners[],
  services: IServices[],
  billingTypes: IBillingTypes[],
  subscriptions: ISubscriptions[],
  charges: Charge[],
  runtimeGroups: IClientRuntimeGroup[],
};

export const lookupsSlice = createSlice({
  name: 'lookupsSlice',
  initialState: {
    billingFrequencies: [],
    partners: [],
    services: [],
    billingTypes: [],
    subscriptions: [],
    charges: [],
    runtimeGroups: [],
  } as ILookupsState,
  reducers: {
    billingFrequenciesReceived: (state, action: IActionType<IBillingFrequencies[]>) => {
      // console.log(`billingFrequenciesReceived: `, action.payload)
      state.billingFrequencies = action.payload.sort((a, b) => ((a.name ?? '') < (b.name ?? '')) ? -1 : 1);
    },
    billingTypesReceived: (state, action: IActionType<IBillingTypes[]>) => {
      // console.log(`servicesReceived: `, action.payload)
      state.billingTypes = action.payload.sort((a, b) => ((a.name ?? '') < (b.name ?? '')) ? -1 : 1);
    },
    partnersReceived: (state, action: IActionType<IPartners[]>) => {
      state.partners = action.payload.sort((a, b) => ((a.name ?? '') < (b.name ?? '')) ? -1 : 1);
    },
    servicesReceived: (state, action: IActionType<IServices[]>) => {
      // console.log(`servicesReceived: `, action.payload)
      state.services = action.payload.sort((a, b) => ((a.name ?? '') < (b.name ?? '')) ? -1 : 1);
    },
    subscriptionsReceived: (state, action: IActionType<ISubscriptions[]>) => {
      // console.log(`subscriptionsReceived: `, action.payload)
      state.subscriptions = action.payload.sort(
        (a, b) => ((a.invoiceDescription ?? '') < (b.invoiceDescription ?? '')) ? -1 : 1,
      );
    },
    chargesReceived: (state, action: IActionType<Charge[]>) => {
      // console.log(`generatedSubscriptionsReceived: `, action.payload)
      state.charges = (action.payload ?? []).slice().sort(
        (a, b) => (
          ((a.month ?? '') > (b.month ?? ''))
            ? -1
            : 1
        ),
      ).sort(
        (a, b) => (
          ((a.year ?? '') > (b.year ?? ''))
            ? -1
            : 1
        ),
      );
    },
    runtimeGroupsReceived: (state, action: IActionType<IClientRuntimeGroup[]>) => {
      state.runtimeGroups = (action.payload ?? []).sort(
        (a, b) => ((a.id ?? '') < (b.id ?? '') ? -1 : 1),
      );
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  billingFrequenciesReceived,
  billingTypesReceived,
  partnersReceived,
  servicesReceived,
  subscriptionsReceived,
  chargesReceived,
  runtimeGroupsReceived,
} = lookupsSlice.actions;

export default lookupsSlice.reducer;
