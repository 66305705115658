import { Route, Switch } from "react-router-dom";
import {FeesManager} from './FeesManager';
export const FeesContainer = () => {
  return (
    <>
      <header className="Section-header">Fees</header>
      <Switch>
        <Route path="/fees">< FeesManager /></Route>
      </Switch>
    </>
  );
};
