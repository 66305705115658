import { createSlice } from '@reduxjs/toolkit';
import { ELoadState, IActionType } from '../../utils';
import { replaceAt } from '@jamesgmarks/utilities';
import { IServices, IBaseSubscriptions } from '@llws/lift-entity-interfaces';

export interface IProductsSliceState {
  loadedState: ELoadState,
  services?: IServices[],
  baseSubscriptions?: IBaseSubscriptions[],
};

export const productsSlice = createSlice({
  name: 'products',
  initialState: {
    baseSubscriptions: [],
    loadedState: 'idle' as ELoadState,
    services: [],
  } as IProductsSliceState,
  reducers: {
    servicesListReceived: (state, action: IActionType<IServices[]>) => {
      state.services = action.payload;
      state.loadedState = ELoadState.loaded;
    },
    serviceUpdateReceived: (state, action: IActionType<IServices>) => {
      const newData = action.payload;
      const index = (state.services ?? []).findIndex(service => service.id === newData.id);
      state.services = (
        index === -1
          ? [ newData ]
          : replaceAt((state.services ?? []), index, newData)
      );
    },
    baseSubscriptionsListReceived: (state, action: IActionType<IBaseSubscriptions[]>) => {
      state.baseSubscriptions = action.payload;
      state.loadedState = ELoadState.loaded;
    },
    baseSubscriptionUpdateReceived: (state, action: IActionType<IBaseSubscriptions>) => {
      const newData = action.payload;
      const index = (state.baseSubscriptions ?? []).findIndex(bs => bs.id === newData.id);
      const newBaseSubs = (
        index === -1
          ? [ ...(state.baseSubscriptions ?? []), newData ]
          : replaceAt((state.baseSubscriptions ?? []), index, newData)
      );
      return ({
        ...state,
        baseSubscriptions: newBaseSubs,
      });
    },
    setLoadState: (state, action: IActionType<ELoadState>) => {
      state.loadedState = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  baseSubscriptionsListReceived,
  baseSubscriptionUpdateReceived,
  servicesListReceived,
  serviceUpdateReceived,
  setLoadState,
} = productsSlice.actions;

export default productsSlice.reducer;
