import { createSlice } from '@reduxjs/toolkit';
import { IApiQueryResponse } from '@llws/api-common';
import { arrayWrap, first, Hash, HashOf } from '@jamesgmarks/utilities';
import { IActionType } from '../../utils';
import { versionCompare } from '../../../app-utils/versionCompare';

export enum EVersionStatus {
  invalid = 'invalid',
  valid = 'valid',
  unknown = 'unknown',
}

export interface ISystemInfoState {
  freshbooksAccountId: string,
  timezone: string,
  timezoneOffset: number,
  env: HashOf<string>,
  version: string,
  requiredVersion: string,
  versionStatus: EVersionStatus,
};

export const systemInfoSlice = createSlice({
  name: 'systemInfo',
  initialState: {
    freshbooksAccountId: '',
    timezone: '',
    timezoneOffset: 0,
    env: {},
    version: '',
    requiredVersion: '',
    versionStatus: EVersionStatus.unknown,
  } as ISystemInfoState,
  reducers: {
    versionReceived: (state, action: IActionType<string>) => {
      state.version = action.payload;
      if (action.payload && state.requiredVersion) {
        state.versionStatus = (
          versionCompare(action.payload, state.requiredVersion, {}) === -1
            ? EVersionStatus.invalid
            : EVersionStatus.valid
        );
      }
    },
    requiredVersionReceived: (state, action: IActionType<string>) => {
      state.requiredVersion = action.payload;
      if (action.payload && state.version) {
        state.versionStatus = (
          versionCompare(state.version, action.payload, {}) === -1
            ? EVersionStatus.invalid
            : EVersionStatus.valid
        );
      }
    },
    systemInfoReceived: (state, action: { type: string, payload: IApiQueryResponse<ISystemInfoState> }) => {
      const systemInfo = first(arrayWrap(action.payload.data))!;
      if (systemInfo) {
        state.freshbooksAccountId = systemInfo.freshbooksAccountId;
        state.timezone = systemInfo.timezone;
        state.timezoneOffset = systemInfo.timezoneOffset;
        state.env = (
          Object
            .keys(systemInfo.env)
            .sort()
            .reduce((info, key) => ({ ...info, [key]: systemInfo.env[key] }), {} as Hash)
        );
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  requiredVersionReceived,
  systemInfoReceived,
  versionReceived,
} = systemInfoSlice.actions;

export default systemInfoSlice.reducer;