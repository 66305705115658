import React from 'react';
import {
  TableContainer, Table, TableHead, TableBody, Paper, TableFooter, 
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from 'src/components/parts/mui/StyledTables';
import { Nullable } from '@jamesgmarks/utilities';

export interface IColumnConfig<T> {
  headerLabel: string;
  fieldName: string;
  headerRender?: () => React.ReactNode;
  format?: (value: string | number) => string | number;
  render?: (data: T) => React.ReactNode;
}

export interface IFooterConfig<T> {
  label?: string;
  colSpan?: number;
  calculate?: (rows: T[]) => string | number;
  render?: (data: T[], calcValue?: Nullable<string | number>) => React.ReactNode;
}
export interface IFooterRowConfig<T> {
  cells: IFooterConfig<T>[];
}

export interface ITableProps<T extends object> {
  rows: T[];
  columns: IColumnConfig<T>[];
  rowHover?: boolean;
  footer?: IFooterRowConfig<T>
  scrollable?: boolean;
  maxHeight?: string;
}

const isFieldNameInT= <T extends object, >(row: T, fieldName: unknown): fieldName is keyof T => {
  return (
    Object.keys(row).includes(`${fieldName}`)
  );
};

const assertFieldNameInT = <T extends object, >(row:T, fieldName: unknown) => {
  if (!isFieldNameInT<T>(row, fieldName)) {
    throw new Error(`Field Name not found inside the row keys of type T`);
  }
  return row[fieldName];
};

export const HHSimpleTable= <T extends object, >({
  rows, columns, rowHover = true, footer, scrollable = false, maxHeight = '400px', 
}: ITableProps<T>) => {
  const renderFooterRow = (footerRow: IFooterRowConfig<T>) => (
    <StyledTableRow key={`footer-row-${rows.length}`}>
      {footerRow.cells.map((footerCell, index) => {
        const calculatedValue = footerCell.calculate ? footerCell.calculate(rows) : null;
        return (
          <StyledTableCell 
            colSpan={footerCell.colSpan || 1}
            key={`footer-cell-${index}`}
          >
            {footerCell.render ? footerCell.render(rows, calculatedValue) : calculatedValue || footerCell.label}
          </StyledTableCell>
        );
      })}
    </StyledTableRow>
  );
  return (
    <TableContainer 
      component={Paper} 
      sx={{
        maxHeight: scrollable ? maxHeight : undefined,
        overflowY: scrollable ? 'auto' : undefined,
      }}
    >
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <StyledTableRow dark={true} style={{ cursor: 'pointer' }}>
            {columns.map((column, columnIndex) => (
              <StyledTableCell
                dark={true}
                style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                key={`${column.headerLabel}_${columnIndex}`}
              >
                {column.headerRender ? column.headerRender() : column.headerLabel}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => (
            <StyledTableRow 
              style={{ cursor: 'pointer' }}
              dark={true} 
              hover={rowHover} 
              role="checkbox" 
              tabIndex={-1} 
              key={rowIndex}
            >
              {columns.map((column, columnIndex) => {
                const cellValue = assertFieldNameInT<T>(row, column.fieldName);
                const isValueStringOrNumber = typeof cellValue === 'string' || typeof cellValue === 'number';
                return (
                  <StyledTableCell dark={true} style={{ whiteSpace: 'nowrap' }} key={columnIndex}>
                    {
                      column.render 
                        ? column.render(row) 
                        : column.format && isValueStringOrNumber 
                          ? column.format(cellValue) 
                          : cellValue}
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
          ))}
        </TableBody>
        {footer && (
          <TableFooter sx={{ position: 'sticky', bottom: 0, zIndex: 1, backgroundColor: 'inherit' }}>
            {renderFooterRow(footer)}
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};