// src/ConfigEditor.tsx
import { Box } from '@mui/material';

export type TCustomConfig = Record<string, unknown>;

type TConfigItemProps = {
  itemKey: string;
  value: unknown;
  path: string[];
}

const isTCustomConfigObject = (value: unknown): value is TCustomConfig => {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
};

const ConfigItem= ({ itemKey, value, path }: TConfigItemProps) => {
  const currentPath = [...path, itemKey];
  const pathString = currentPath.join('.');

  if (isTCustomConfigObject(value)) {
    return (
      <div key={pathString} style={{ marginLeft: '20px' }}>
        <strong>{itemKey}</strong>
        <div>
          {Object.keys(value).map(childKey =>
            <ConfigItem 
              key={childKey} 
              itemKey={childKey} 
              value={value[childKey]} 
              path={currentPath} 
            />)}
        </div>
      </div>
    );
  }

  if (Array.isArray(value)) {
    return (
      <div key={pathString} style={{ marginLeft: '20px' }}>
        <strong>{itemKey}:</strong>
        <ul>
          {value.map((item, index) => 
            <li key={index}>
              {
                typeof item === 'string' 
                  ? item 
                  : <ConfigItem itemKey={index.toString()} value={item} path={currentPath} />
              }
            </li>)}
        </ul>
      </div>
    );
  }

  return (
    <div key={pathString} style={{ marginLeft: '20px' }}>
      <strong>{itemKey}:</strong> {String(value)}
    </div>
  );
};

export const HHConfigEditor = (
  { customConfig, boxColor = 'white' }: {customConfig: TCustomConfig, boxColor?: string},
) => {
  return (
    <Box
      sx={{
        border: 1,
        borderRadius: 2,
        borderColor: 'grey.500',
        padding: 2,
        maxHeight: 400,
        overflowY: 'auto',
        resize: 'both',
        backgroundColor: boxColor,
      }}
    >
      {Object.keys(customConfig).map(key =>
        <ConfigItem key={key} itemKey={key} value={customConfig[key]} path={[]} />)}
    </Box>
  );
};
