import { Dispatch, SetStateAction } from "react";
import { Checkbox } from "@mui/material";

import { StyledTableCell, StyledTableRow } from "../../parts/mui/StyledTables";
import { TDocumentFieldGetter } from "../../../redux/features/documents/interfaces";
import { TTableData } from "./table-cell-getters";

function getFieldValue <T>(document: T, field: TDocumentFieldGetter<T>) {
  if (typeof field === 'string' || typeof field === 'number' || typeof field === 'symbol') {
    return document[field];
  }
  
  return field(document);
};

interface IDocumentListTableRowProps<T extends { id: number }> {
  document: T;
  fields: TTableData<T>[];
  selected: boolean;
  setSelected: Dispatch<SetStateAction<number[]>>;
};

export const DocumentListTableRow = <T extends { id: number }>({
  document,
  fields,
  selected,
  setSelected,
}: IDocumentListTableRowProps<T>) => {
  const handleCheckboxChange = (
    () => setSelected((old) => (
      old.includes(document.id)
        ? old.filter((id) => id !== document.id)
        : [ ...old, document.id ]
    ))
  );

  return (
    <StyledTableRow key={document.id}>
      <StyledTableCell>
        <Checkbox
          checked={selected}
          onChange={() => handleCheckboxChange()}
        />
      </StyledTableCell>
      {
        fields.map((field) => (
          <StyledTableCell key={field.name}>
            {getFieldValue<T>(document, field.getter)}
          </StyledTableCell>
        ))
      }
    </StyledTableRow>
  );
};
