import {  IApiCommandResponse, IApiQueryResponse } from '@llws/api-common';
import { dispatch } from '../../store';
import {
  billingAccountsReceived,
  EClientLoadState,
  setLoadedState,
  singleBillingAccountReceived,
} from './accountsSlice';
import {  typedApiFetch } from '../../utils';
import { REACT_APP_API_ROOT_URI } from '../../../App';
import { BillingAccount  } from '../../../../../entities/hydra';
import { showMessage } from '../messaging/actions';

interface IRunTimeGroupUpdateInfo {
  accountId: number;
  clientRuntimeGroupId: number | null;
}

export const loadBillingAccounts = async () => {
  dispatch(setLoadedState(EClientLoadState.loading));

  const url = `${REACT_APP_API_ROOT_URI}/accounts`;
  const response = await typedApiFetch<IApiQueryResponse<BillingAccount>>(url);
  const responseData = await response.json();
  dispatch(billingAccountsReceived(responseData ?? [])); // TODO: Error handling?

  dispatch(setLoadedState(EClientLoadState.loaded));
};

export const loadCurrentBillingAccount = async (accountId: number) => {
  dispatch(setLoadedState(EClientLoadState.loading));

  const url = `${REACT_APP_API_ROOT_URI}/accounts/${accountId}`;
  const response = await typedApiFetch<IApiQueryResponse<BillingAccount>>(url);
  const responseData = await response.json();
  dispatch(singleBillingAccountReceived(responseData ?? [])); // TODO: Error handling?

  dispatch(setLoadedState(EClientLoadState.loaded));
};

/**
 * sends the request (PUT) to update a billing account
 * with the new runtime group id
 * @param {IRunTimeGroupUpdateInfo} runTimeGroupUpdateInfo object containing 
 * the account id to be updated and the new runtime group id
 * @return the updataed billing account
 */
export const updateBillingAccountRuntimeGroup = async (runTimeGroupUpdateInfo: IRunTimeGroupUpdateInfo) => {
  const url = `${REACT_APP_API_ROOT_URI}/accounts/${runTimeGroupUpdateInfo!.accountId}`;
  try {
    const response = await typedApiFetch(
      url,
      {
        method: 'PUT',
        body: JSON.stringify(runTimeGroupUpdateInfo),
      },
    );

    const responseData = await response.json() as IApiCommandResponse<BillingAccount>;
    if(responseData.data) {
      showMessage({
        message: `Run time group updated successfully`,
        severity: 'success',
      });
    }
    return responseData.data;
  } catch (error) {
    console.error('Failed to update run time group', error);
    showMessage({
      message: `Failed to update run time group: ${error}`,
      severity: 'error',
      asAlert: true,
    });
  }

};
