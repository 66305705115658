import {
  Checkbox,
  FormControlLabel,
} from "@mui/material";

interface IBoolFieldProps {
  label: string;
  checked: boolean;
  onChange: ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void);
}

export const BoolField = ({
  label,
  checked,
  onChange,
}: IBoolFieldProps) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          color="primary"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          onChange={onChange}
        />
      }
      label={label}
    />
  );
};
