import { Dispatch, SetStateAction, useState } from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";

import { assertMonthNumber } from "../../../interfaces/IYearMonth";
import { refreshBillingRuns } from "../../../redux/features/billing-runs/actions";
import { getLastMonth } from "../Clients/ClientsListView";
import { getMonthStart } from "../../../app-utils";
import { Nullable } from "@jamesgmarks/utilities";
import { useAuth } from "../../../customHooks/useAuth";
import { HHMonthPicker } from "../../parts/HHMonthPicker";

export enum EBillingRunStatus {
  pending = 'pending',
  processing = 'processing',
  error = 'error',
  completed = 'completed',
}

export type CheckedStatusFilters = {
  [k in EBillingRunStatus]: boolean;
}

interface IBillingRunFiltersProps {
  checkedStatusFilters: CheckedStatusFilters;
  setRunIdFilter: Dispatch<SetStateAction<string>>;
  setStatusFilter: (filterName: EBillingRunStatus, checked: boolean) => void;
}

export const BillingRunFilters = ({
  checkedStatusFilters,
  setRunIdFilter,
  setStatusFilter,
}: IBillingRunFiltersProps) => {
  const [ runId, setRunId ] = useState('');

  const [ startMonth, setStartMonth ] = useState<Nullable<Date>>(getLastMonth());
  const [ endMonth, setEndMonth ] = useState<Nullable<Date>>(getLastMonth());

  const [ querySubmitted, setQuerySubmitted ] = useState(false);

  const handleRefreshBillingRuns = () => {
    (startMonth && endMonth && !isNaN(startMonth.getTime()) && !isNaN(endMonth.getTime()))
      ?
      refreshBillingRuns({
        startYear: startMonth.getFullYear(),
        startMonth: assertMonthNumber(startMonth.getMonth() + 1),
        endYear: endMonth.getFullYear(),
        endMonth: assertMonthNumber(endMonth.getMonth() + 1),
      })
      :
      refreshBillingRuns();
  };

  const { isDeveloper, hasPermission } = useAuth();
  const canCreateManualBillingRun = isDeveloper || hasPermission('CREATE_MANUAL_BILLING_RUN');

  return (
    <Grid container m='auto' textAlign='center' spacing={1}>
      <Grid
        item
        container
        component={Card}
        mt={3}
        pr={1}
        pb={1}
        sx={{ background: '#f5f5f5' }}
        xs={12}
        lg={9}
      >
        <Grid item container spacing={1} pt={0.75} xs={12}>
          <Grid item mb={2} my='auto' xs={4}>
            <TextField
              label='Run ID Filter'
              onBlur={() => !querySubmitted && setRunIdFilter(runId)}
              onChange={(e) => setRunId(e.target.value.replace(/[^0-9]/g, ''))}
              onFocus={() => setQuerySubmitted(false)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  setRunIdFilter(runId);
                  setQuerySubmitted(true);
                }
              }}
              size='small'
              sx={{ background: '#fff' }}
              value={runId}
            />
          </Grid>

          <Grid item xs={4}>
            <Grid item xs>
              <HHMonthPicker
                label='Start Month'
                onChange={
                  (d: Nullable<Date>) => {
                    setStartMonth(d ? getMonthStart(d.getFullYear(), d.getMonth() + 1) : null);
                  }
                }
                value={startMonth}
              />
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <Grid item xs>
              <HHMonthPicker
                label='End Month'
                onChange={
                  (d: Nullable<Date>) => {
                    setEndMonth(d ? getMonthStart(d.getFullYear(), d.getMonth() + 1) : null);
                  }
                }
                value={endMonth}
              />
            </Grid>
          </Grid>

          <Grid item container justifyContent='left' xs={12}>

            <Grid item pl={1.25}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedStatusFilters.pending}
                      onChange={(e) => setStatusFilter(EBillingRunStatus.pending, e.target.checked)}
                    />
                  }
                  label='Pending'
                  sx={{
                    backgroundColor: '#fff',
                    border: '1px solid gray',
                    borderRadius: '9px',
                    pr: 2.25,
                  }}
                />
              </FormGroup>
            </Grid>

            <Grid item pl={1.25}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedStatusFilters.processing}
                      onChange={(e) => setStatusFilter(EBillingRunStatus.processing, e.target.checked)}
                    />
                  }
                  label='Processing'
                  sx={{
                    backgroundColor: '#fff',
                    border: '1px solid gray',
                    borderRadius: '9px',
                    pr: 2.25,
                  }}
                />
              </FormGroup>
            </Grid>
              
            <Grid item pl={1.25}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedStatusFilters.error}
                      onChange={(e) => setStatusFilter(EBillingRunStatus.error, e.target.checked)}
                    />
                  }
                  label='Error'
                  sx={{
                    backgroundColor: '#fff',
                    border: '1px solid gray',
                    borderRadius: '9px',
                    pr: 2.25,
                  }}
                />
              </FormGroup>
            </Grid>
              
            <Grid item pl={1.25}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedStatusFilters.completed}
                      onChange={(e) => setStatusFilter(EBillingRunStatus.completed, e.target.checked)}
                    />
                  }
                  label='Completed'
                  sx={{
                    backgroundColor: '#fff',
                    border: '1px solid gray',
                    borderRadius: '9px',
                    pr: 2.25,
                  }}
                />
              </FormGroup>
            </Grid>

            <Grid item xs>
              <Button
                color='primary'
                fullWidth
                size='large'
                onClick={() => handleRefreshBillingRuns()}
                startIcon={<RefreshIcon />}
                variant='contained'
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Grid>

      </Grid>

      <Grid item container alignContent='space-around' spacing={1} xs={12} lg={3}>

        <Grid item xs={6} md={12} pr={1}>
          <Link to='/billing_runs/schedules'>
            <Button
              color='info'
              fullWidth
              startIcon={<CalendarMonthIcon />}
              variant='contained'
            >
              Manage Automated Schedules
            </Button>
          </Link>
        </Grid>

        <Grid item xs={6} md={12} pr={1}>
          <Link style={{ ...(canCreateManualBillingRun ? {} : { pointerEvents: 'none' }) }} to='/billing_runs/create'>
            <Button
              disabled={!canCreateManualBillingRun}
              color='secondary'
              fullWidth
              startIcon={<AddIcon />}
              variant='contained'
            >
              Create Manual Run
            </Button>
          </Link>
        </Grid>
      </Grid>

    </Grid>
  );
};
