import { floatVal } from "@jamesgmarks/utilities";
import { Grid, TextField } from "@mui/material";
import { handleInputBlur, handleInputChange } from "../../../../../app-utils/handlers";
import { isBasePriceScalarBillingType } from "@llws/hydra-shared";

export const BasePriceScalar = ({
  billingTypeId,
  basePriceScalar,
  setBasePriceScalar,
}: {
  billingTypeId: number | null;
  basePriceScalar: number;
  setBasePriceScalar: React.Dispatch<React.SetStateAction<number>>,
}) => {
  if (billingTypeId === null || !isBasePriceScalarBillingType(billingTypeId)) { return <></>; }

  return (
    <>
      <Grid item xs={12}>
        <TextField
          id="baseSubscriptionBasePriceScalar"
          variant="outlined"
          label="Base Price Scalar"
          name="baseSubscriptionBasePriceScalar"
          type="number"
          value={basePriceScalar ?? ''}
          onChange={handleInputChange(setBasePriceScalar, floatVal)}
          onBlur={handleInputBlur(setBasePriceScalar, floatVal)}
          size="small"
          fullWidth={true} />
      </Grid>
    </>
  );
};
