import { useEffect } from "react";
import {
  Card, FormControlLabel, FormGroup, Grid, Switch, Typography, 
} from "@mui/material";

import { keys } from "@jamesgmarks/utilities";

import { getApiValidatorSettings, updateApiValidatorSettings } from "../../../redux/features/api-validators/actions";
import { EApiValidatorStatus, IApiValidatorSettingsOptions } from "../../../redux/features/api-validators/interfaces";
import { useAppSelector } from "../../../redux/hooks";

interface IRouterSwitchProps {
  router: string;
  settings: IApiValidatorSettingsOptions;
}

const RouterSwitch = ({ router, settings }: IRouterSwitchProps) => (
  <FormGroup>
    <FormControlLabel
      control={
        <Switch
          checked={settings.status === EApiValidatorStatus.enabled}
          onChange={
            (e) => {
              updateApiValidatorSettings(
                router,
                {
                  ...settings,
                  status: (
                    e.currentTarget.checked
                      ? EApiValidatorStatus.enabled
                      : EApiValidatorStatus.disabled
                  ), 
                },
              ); 
            }}
        />
      }
      label={router}
    />
  </FormGroup>
  
);

export const ApiValidators = () => {
  const { settings } = useAppSelector((state) => state.apiValidators);

  useEffect(
    () => { getApiValidatorSettings(); },
    [],
  );

  return (
    <Grid container textAlign='center' m={3}>
      <Grid item xs={12}>
        <Typography>Toggle on/off API validation for the following router prefixes:</Typography>
      </Grid>
      
      <Card sx={{ m: 7, p: 2 }}>
        <Grid item container>
          {
            keys(settings ?? {}).map(
              (router) => (
                <Grid key={router} item px={3} xs={2}>
                  <RouterSwitch router={router} settings={settings[router]} />
                </Grid>
              ),
            )
          }
        </Grid>
      </Card>
    </Grid>
  );
};
