import { BankingInformation } from "./BankingInformation";
import { Terms } from "./Terms";

export const DefaultStatementFooter = ({terms, bankingInformation} : {terms: string, bankingInformation: string}) => {
  return (
    <>
      <Terms terms={terms}/>
      {bankingInformation !== '' && <BankingInformation bankingInfo={bankingInformation}/>}
    </>
  );
};