import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { Button } from "react-bootstrap";

interface IReplayWebhooksModalProps {
  open: boolean;
  selectedIds: number[];
  onClose: () => void;
  onConfirm: () => void;
}

export const ReplayWebhooksModal = ({
  open,
  selectedIds,
  onClose,
  onConfirm,
}: IReplayWebhooksModalProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to replay the following Webhook(s): {selectedIds.join(', ')}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          No
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};