import {
  addMessage,
  clearAllMessages,
  clearFirstErrorMessage,
  clearSpecificMessage,
  setEmbedUrl,
  setShowEmbed,
  TActionButtonInfo,
  TMessageLinesWithStyling,
} from './messagingSlice';
import { dispatch, store } from '../../store';

export interface IAuthResult {
  token: string;
}

export interface IShowMessageProps {
  actionButtonInfo?: TActionButtonInfo;
  asAlert?: boolean;
  id?: string;
  message: string | TMessageLinesWithStyling;
  severity?: 'warning' | 'info' | 'success' | 'error';
}

export const showMessage = async ({
  actionButtonInfo,
  asAlert = false,
  id,
  message,
  severity = 'error',
}: IShowMessageProps) => {
  const doAction = (
    actionButtonInfo
    ?? { actionButtonText: 'Report Error', buttonAction: 'report-error' }
  );
  dispatch(
    addMessage({
      // actionButtonInfo: doAction,
      asAlert,
      id,
      message,
      severity,
      timestamp: Date.now(),
    }),
  ); // TODO: Error handling?
};

export const clearErrorMessage = async () => {
  dispatch(clearFirstErrorMessage({}));
};

export const closeMessage = async (id: string) => {
  dispatch(clearSpecificMessage({ id }));
};

export const closeAllMessages = async () => {
  dispatch(clearAllMessages());
};

export const notifyEnvironment = async () => {
  const systemInfo = store.getState().systemInfo;
  showMessage({ message: systemInfo.env.NODE_ENV, severity: 'info' });
};

export const showEmbed = async (showEmbed:boolean) =>  {
  dispatch(setShowEmbed(showEmbed));
};

export const setEmbedUrlForVideo = async (url:string) =>  {
  dispatch(setEmbedUrl(url));
};
