import { useMemo, useState } from "react";

import {
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";

import { filterKeys, HashOf, intVal, keys } from "@jamesgmarks/utilities";

import { BillingRunFilters, CheckedStatusFilters, EBillingRunStatus } from "./BillingRunFilters";
import { EBillingRunLoadedState } from "../../../redux/features/billing-runs/billingRunSlice";
import { IBillingRun } from "../../../entity-interfaces/IBillingRun";
import { ParentBillingRunAccordion } from "./ParentBillingRunAccordion";
import { useAppSelector } from "../../../redux/hooks";
import { ConfirmationModal } from "../../parts/ConfirmationModal";
import { rerunBillingRun } from "../../../redux/features/billing-runs/actions";

interface IBillingRunListViewProps {
  billingRuns: IBillingRun[];
}

export const BillingRunListView = ({
  billingRuns,
}: IBillingRunListViewProps) => {
  const loadState = useAppSelector((state) => state.billingRuns.loadState);

  const [ runIdFilter, setRunIdFilter ] = useState('');

  const [ checkedStatusFilters, setCheckedStatusFilters ] = useState<CheckedStatusFilters>({
    pending: true,
    processing: true,
    completed: true,
    error: true,
  });

  const [ erroredRuns, setErroredRuns ] = useState<IBillingRun[]>([]);
  const [ rerunModalOpen, setRerunModalOpen ] = useState(false);

  const setErroredRunsForRerun = (erroredRuns: IBillingRun[]) => {
    setErroredRuns(erroredRuns);
    setRerunModalOpen(true);
  };

  const rerunAllFailedBillingRuns = async () => {
    erroredRuns.forEach((erroredRun) => rerunBillingRun(erroredRun.id));
  };

  const setStatusFilter = (
    status: EBillingRunStatus,
    checked: boolean,
  ) => {
    setCheckedStatusFilters(
      (oldCheckedStatusFilters) => ({
        ...oldCheckedStatusFilters,
        [status]: checked,
      }),
    );
  };

  const runsGroupedByParent = useMemo<HashOf<IBillingRun[]>>(
    () => billingRuns
      .filter((br) => !br.parentId)
      .reduce(
        (acc, parentRunId) => ({
          ...acc,
          [`${parentRunId.id}`]: (
            billingRuns.filter((run) => run.parentId === parentRunId.id || run.id === parentRunId.id)
          ),
        }),
        {},
      ),
    [ billingRuns ],
  );

  const filteredRunsGroupedByParent = useMemo<HashOf<IBillingRun[]>>(
    () => {
      const allParentRunsGroupedWithArrayOfMembers = (
        keys(runsGroupedByParent)
          .reduce<HashOf<IBillingRun[]>>(
            (acc, parentRunId) => ({
              ...acc,
              [parentRunId]: (
                runsGroupedByParent[parentRunId]
                  .filter(
                    (run) => (
                      (
                        keys(checkedStatusFilters)
                          .filter(status => checkedStatusFilters[status])
                          .includes(run.status)
                      )
                      && (!runIdFilter.trim() || run.id === intVal(runIdFilter) || run.parentId === intVal(runIdFilter))
                    ),
                  )
              ),
            }),
            {},
          )
      );

      return (
          filterKeys(
            allParentRunsGroupedWithArrayOfMembers,
            (k) => allParentRunsGroupedWithArrayOfMembers[k].length > 0,
          )
        ) as HashOf<IBillingRun[]>;
    }
    , [ runsGroupedByParent, checkedStatusFilters, runIdFilter ],
  );

  const totalRecordsToDisplay = (
    keys(filteredRunsGroupedByParent)
      .reduce(
        (acc, parentRunId) => intVal(acc) + filteredRunsGroupedByParent[parentRunId].length,
        0,
      )
  );

  return (
    <Grid
      item
      container
      justifyContent='center'
      m='auto'
      spacing={2}
      xs={10}
      lg={7}
      xl={5}
    >
      <BillingRunFilters
        checkedStatusFilters={checkedStatusFilters}
        setRunIdFilter={setRunIdFilter}
        setStatusFilter={setStatusFilter}
      />

      {
        loadState === EBillingRunLoadedState.loading
          ? (
            <Box width='100%' mt={12} sx={{ textAlign: 'center' }}>
              <CircularProgress color="secondary" />
            </Box>
          )
          : (
            <Box mx='auto' my={2}>
              {
                (keys(filteredRunsGroupedByParent) ?? [])
                  .sort((a, b) => a > b ? -1 : 1)
                  .map(
                    (parentRunId) => (
                      <ParentBillingRunAccordion
                        key={parentRunId}
                        created={runsGroupedByParent[parentRunId].find((run) => !run.parentId )!.created}
                        filteredBillingRunsForParent={filteredRunsGroupedByParent[parentRunId]}
                        parentRunId={intVal(parentRunId)}
                        runIdFilter={runIdFilter.trim()}
                        setStatusFilter={setStatusFilter}
                        setErroredRunsForRerun={setErroredRunsForRerun}
                      />
                    ),
                  )
              }
            </Box>
          )
      }

      {
        (totalRecordsToDisplay === 0 && loadState === EBillingRunLoadedState.loaded)
          ? (
            <Grid item xs={12} textAlign='center'>
              <Typography mt={8} variant='h4'>No runs found.</Typography>
            </Grid>
          )
          : <></>
      }

      <ConfirmationModal
        open={rerunModalOpen}
        setOpen={setRerunModalOpen}
        dialogTitle='Confirm Billing Re-Run'
        promptText="Are you sure that you want to re-run this parent billing run and its children?"
        onConfirm={() => rerunAllFailedBillingRuns()}
      />
    </Grid>
  );
};
