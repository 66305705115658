import { Hash } from '@jamesgmarks/utilities';
import React from 'react';
import { Link } from "react-router-dom";
import { Button } from 'react-bootstrap';

export const Unauthorized = (props: Hash) => (
  <div className="App">
    <header className="App-header">
      {/* <caption>{systemInfo.env.NODE_ENV}</caption> */}
      You are not authorized to access this page. Please log in with a valid user or navigate elsewhere.
      <Button as={Link} to="/login">Log In</Button>
    </header>
  </div>
);
