import { useEffect, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import {
  floatVal, intVal, last, Nullable, pickNot, replaceAt, sort,
} from "@jamesgmarks/utilities";

import {
  IBaseSubscriptions,
  IBillingZones,
  IPartnerInquiries,
  ISalesRepCommissions,
  ISubscriptions,
  IVariableBasePrices,
  IWorkInProgressQueue,
} from "@llws/lift-entity-interfaces";

import {
  assertValidBillingType,
  BILLING_FEATURE_MANAGED_EXTERNALLY,
  BILLING_FEATURES,
  billingTypeHasFeature,
  billingTypesConfig,
  getBillingFeatureMetadata,
  isBasePriceBillingType,
  isBasePriceScalarBillingType,
  isCostPerLeadMaxBillingType,
  isExternalBillingType,
  isValidBillingType,
  isVariableBasePriceBillingType,
} from '@llws/hydra-shared';

import {
  handleInputChange,
  handleSelectChanged,
  numberSelectValueTransform,
} from "../../../../app-utils/handlers";

import { assertNotNullOrUndefined } from "../../../../app-utils/primitive-assertions";

import {
  loadBillingFrequenciesLookup,
  loadBillingTypesLookup,
  loadPartnersLookup,
  loadServicesLookup,
} from "../../../../redux/features/lookups/actions";

import { useAppSelector } from "../../../../redux/hooks";

import { BasePrice } from "./Components/BasePrice";
import { CostPerLeadMax } from "./Components/CostPerLeadMax";
import { VariableBasePrices } from "./Components/VariableBasePrices";
import { showMessage } from "../../../../redux/features/messaging/actions";
import { BasePriceScalar } from "./Components/BasePriceScalar";

// TODO: Cycle back to billing zones. Must support managing (or at least displaying) them.
// import { BillingZones } from "./Components/BillingZones";

type TBaseSubscriptionsWithPartialRelations = (
  Omit<Partial<IBaseSubscriptions>, 'variableBasePrices' | 'billingZones'>
  & {
    billingZones?: Partial<IBillingZones>[],
    variableBasePrices?: Partial<IVariableBasePrices>[],
    subscriptions?: Partial<ISubscriptions>[],
    salesRepCommissions?: Partial<ISalesRepCommissions>[],
    workInProgressQueues?: Partial<IWorkInProgressQueue>[],
    contractPartnerInquiries?: Partial<IPartnerInquiries>[],
  }
)
export const BaseSubscriptionCreateEditModal = ({
  baseSubscription, show, setShow, onSaveRequest,
}: {
  baseSubscription: Partial<TBaseSubscriptionsWithPartialRelations>;
  show: boolean;
  setShow: ((newState: boolean) => void);
  onSaveRequest: ((baseSubscription: Partial<TBaseSubscriptionsWithPartialRelations>) => void);
}) => {
  const services = useAppSelector((store) => store.lookups.services);
  const partners = useAppSelector((store) => store.lookups.partners);
  const billingFrequencies = useAppSelector((store) => store.lookups.billingFrequencies);
  const billingTypes = useAppSelector((store) => store.lookups.billingTypes);

  const [serviceId, setServiceId] = useState<Nullable<number>>(baseSubscription.serviceId ?? null);
  const [partnerId, setPartnerId] = useState<Nullable<number>>(baseSubscription.partnerId ?? null);
  const [billingFrequencyId, setBillingFrequencyId] = useState<Nullable<number>>(
    baseSubscription.billingFrequencyId ?? null,
  );
  const [revenueTrackingCode, setRevenueTrackingCode] = useState(`${baseSubscription.revenueTrackingCode ?? ''}`);
  const [invoiceDescription, setInvoiceDescription] = useState(`${baseSubscription.invoiceDescription ?? ''}`);
  const [basePrice, setBasePrice] = useState<number>(baseSubscription.basePrice ?? 0);
  const [basePriceScalar, setBasePriceScalar] = useState<number>(baseSubscription.basePriceScalar ?? 1); // Default scalar should be 1. Same value for single- and multi-family properties.
  const [costPerLeadMax, setCostPerLeadMax] = useState<number>(baseSubscription.costPerLeadMax ?? 0);
  const [handleBilling, setHandleBilling] = useState<0 | 1>((baseSubscription.handleBilling ?? 1) as 0 | 1);
  const [partnerCut, setPartnerCut] = useState<number>(baseSubscription.partnerCut ?? 0);
  const [status, setStatus] = (
    useState<'active' | 'inactive'>(`${baseSubscription.status ?? 'active'}` as 'active' | 'inactive')
  );

  const [variableBasePrices, setVariableBasePrices] = (
    useState<Nullable<(Partial<IVariableBasePrices>)[]>>(baseSubscription.variableBasePrices ?? null)
  );
  const [editingVariableBasePrices, setEditingVariableBasePrices] = useState<boolean>(false);

  const billingTypeId = useMemo(() => {
    return services.find((s) => s.id === serviceId)?.billingTypeId ?? null;
  }, [ services, serviceId ]);

  const billingTypeName = useMemo(() => {
    return billingTypes.find((t) => t.id === billingTypeId)?.name;
  }, [ billingTypeId, billingTypes ]);

  const itemName = useMemo(() => {
    return (
      (services.find((s) => s.id === serviceId)?.invoiceItem)
      ?? (partners.find((p) => p.id === partnerId)?.name)
      ?? '[Requires Service or Partner Selected]'
    );
  }, [ services, partners, serviceId, partnerId ]);

  const filteredBillingFrequencies = useMemo(() => {
    return billingFrequencies.filter((bf) => (
      !!billingTypeId
      && billingTypesConfig[assertValidBillingType(billingTypeId)]
        ?.billingFrequencies.some((_bf) => _bf === bf.id)
    ));
  }, [ billingTypeId, billingFrequencies ]);

  const defaultBillingFrequencyId = useMemo(() => (
    filteredBillingFrequencies.length === 1
      ? (filteredBillingFrequencies[0].id ?? null)
      : null
  ), [ filteredBillingFrequencies ]);

  const defaultTrackingCode = useMemo(() => (
    services.find((s) => s.id === serviceId)?.revenueTrackingCode
  ), [services, serviceId]);

  const variableBasePricesAreValid = useMemo(() => (
    (variableBasePrices?.length ?? 0) > 0
    // TODO: && How to ensure valid data???
  ), [ variableBasePrices ]);

  const handleClose = () => {
    setShow(false);
  };

  // TODO: DUPLICATE FUNCTION. This may be a repeat of logic that already exists on the back end. Should ship it to a shareable package.
  const vbpSort = (vbp: Partial<IVariableBasePrices>[]) => {
    return sort(vbp, (a, b) => {
      if (!a.maxValue) return 1;
      if (!b.maxValue) return -1;
      return (
        intVal(a.maxValue ?? 0) < intVal(b.maxValue ?? 0)
          ? -1
          : 1
      );
    });
  };

  const saveVariableBasePrices = (vbp: Partial<IVariableBasePrices>[]) => {
    const _sorted = vbpSort(vbp ?? []);

    const toSave = (
      _sorted.length > 0
        ?  [
          ..._sorted.slice(0, -1),
          { ...last(_sorted), maxValue: null },
        ]
        : []
    );

    setVariableBasePrices(toSave);
  };

  const sortedVariableBasePrices = useMemo(() => vbpSort(variableBasePrices ?? []), [ variableBasePrices ]);

  const handleSave = () => {

    if (billingTypeId === null || !isValidBillingType(billingTypeId)) {
      showMessage({ message: `Error. Invalid Billing Type (${billingTypeId}). Can't save`, severity: 'error' });
      return;
    }

    // Required field validation
    if (
      !serviceId
      || !partnerId
      || (!billingFrequencyId && !defaultBillingFrequencyId)
      || !(revenueTrackingCode ?? defaultTrackingCode) // Business rule: either the base sub rev code or the service code must exist.
      || !invoiceDescription
    ) {
      showMessage({ message: 'Please fill out ALL required fields', severity: 'error' });
      console.error('Missing fields', {
        serviceId,
        partnerId,
        billingFrequencyId,
        revenueTrackingCode,
        invoiceDescription,
      });
      return;
    }

    if (
      billingTypeHasFeature(billingTypeId, BILLING_FEATURE_MANAGED_EXTERNALLY)
    ) {
      showMessage({ message: 'Changes Invalid. Subscription Type is managed externally (likely Supersync).'});
      return;
    }

    const errors = [
      {
        shouldError: partnerCut < 0 || partnerCut > 1,
        message: 'Partner cut must be between 0 (0%) and 1 (100%)',
      },
      {
        shouldError: (isBasePriceBillingType(billingTypeId) && !basePrice && basePrice !== 0),
        message: 'Base Price is required.',
      },
      {
        shouldError: (isBasePriceScalarBillingType(billingTypeId) && (basePriceScalar ?? 0) <= 0),
        message: 'Base Price Scalar must be a value > 0.',
      },
      {
        shouldError: (isCostPerLeadMaxBillingType(billingTypeId) && !costPerLeadMax && costPerLeadMax <= 0),
        message: 'Cost Per Lead Max must be a value > 0.',
      },
      {
        shouldError: (isVariableBasePriceBillingType(billingTypeId) && !variableBasePricesAreValid),
        message: 'Invalid Variable Base Price data.',
      },
    ];

    const errorMessages = errors.filter((e) => e.shouldError).map((e) => e.message);

    if (errorMessages.length > 0) {
      showMessage({ message: `Errors in Submission:\n - ${errorMessages.join('\n - ')}`, severity: 'error' });
      return;
    }

    // If we have variable base price data, ensure the last record does not have a `maxValue` (There should always be a bracket that does not have a ceiling.)
    if (isVariableBasePriceBillingType(billingTypeId)) {
      if (!variableBasePrices || variableBasePrices.length === 0) {
        showMessage({ message: 'Invalid Variable Base Price data.', severity: 'error' });
        return;
      }

      const lastRecord: Partial<IVariableBasePrices> = {
        ...pickNot(assertNotNullOrUndefined(last(variableBasePrices)), [ 'maxValue' ]),
        maxValue: null,
      };

      const vbps = assertNotNullOrUndefined(variableBasePrices);

      setVariableBasePrices(replaceAt(vbps, vbps.length - 1, lastRecord));
    }

    onSaveRequest({
      ...baseSubscription,
      serviceId,
      partnerId,
      billingFrequencyId: billingFrequencyId ?? defaultBillingFrequencyId,
      invoiceDescription,
      basePrice,
      basePriceScalar,
      costPerLeadMax,
      handleBilling,
      partnerCut,
      status,
      variableBasePrices: variableBasePrices ?? undefined, // Note: but no other way than using `as`.
      ...(
        (revenueTrackingCode ?? '').trim() === ''
          ? { revenueTrackingCode: '' }
          : { revenueTrackingCode }
      ),
    });
    setShow(false);
  };

  useEffect(() => {
    loadBillingFrequenciesLookup();
    loadBillingTypesLookup();
    loadPartnersLookup({ includeInactive: true });
    loadServicesLookup({ includeInactive: true });
  }, []);

  if (billingTypeId && isExternalBillingType(assertValidBillingType(billingTypeId))) {
    // TODO: Can this be improved? It feels really verbose.
    const meta: Record<string, unknown> = getBillingFeatureMetadata(
      billingTypeId,
      BILLING_FEATURES.BILLING_FEATURE_MANAGED_EXTERNALLY,
    ) ?? {};
    const url = (
      'url' in meta
        ? meta.url
        : null
    );
    return (
      <Dialog open={show} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={'lg'}>
        <DialogTitle id="form-dialog-title">{baseSubscription.id ? 'Edit ' : 'Create '} Subscription Type</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ID: {baseSubscription.id ?? '(automatically generated on save)'}
            <p>This billing type is managed by another tool</p>
            {
              url && (<><a href={"https://supersync.rentsync.com" + url}>Go to Supersync</a></>)
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <div>
      <Dialog open={show} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={'lg'}>
        <DialogTitle id="form-dialog-title">{baseSubscription.id ? 'Edit ' : 'Create '} Subscription Type</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ID: {baseSubscription.id ?? '(new base subscription)'}
          </DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl variant="outlined" size="small" fullWidth={true}>
                <InputLabel id="baseSubscriptionServiceId-label">Service</InputLabel>
                {services.length > 0
                  ? <Select
                    labelId="baseSubscriptionServiceId-label"
                    id="baseSubscriptionServiceId"
                    value={serviceId?.toString() ?? ''}
                    onChange={handleSelectChanged(setServiceId, numberSelectValueTransform)}
                    disabled={!!baseSubscription.id}
                    required
                  >
                    <MenuItem value={''}>-- Select One --</MenuItem>
                    {
                      services
                        .filter((s) => (
                          s.status === 'active'
                          && !isExternalBillingType(s.billingTypeId)
                        ))
                        .map((s) => (
                          <MenuItem key={s.id} value={s.id ?? ''}>{s.name} ({s.id})</MenuItem>
                        ))
                    }
                  </Select>
                  : '...'}
                {
                  serviceId &&
                  <div><small>Service Billing Type: {billingTypeName}</small></div>
                }
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" size="small" fullWidth={true}>
                <InputLabel id="baseSubscriptionPartnerId-label">Partner</InputLabel>
                {partners.length > 0
                  ? <Select
                    labelId="baseSubscriptionPartnerId-label"
                    id="baseSubscriptionPartnerId"
                    value={partnerId?.toString() ?? ''}
                    onChange={handleSelectChanged(setPartnerId, numberSelectValueTransform)}
                    disabled={!!baseSubscription.id}
                    required
                  >
                    <MenuItem value={''}>-- Select One --</MenuItem>
                    {partners.map((p) => (
                      <MenuItem key={p.id} value={p.id ?? ''}>{p.name}</MenuItem>
                    ))}
                  </Select>
                  : '...'}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              { filteredBillingFrequencies.length > 0
                  && <FormControl variant="outlined" size="small" fullWidth={true}>
                    <InputLabel id="baseSubscriptionBillingFrequencyId-label">Frequency</InputLabel>
                    <Select
                      labelId="baseSubscriptionBillingFrequencyId-label"
                      id="baseSubscriptionBillingFrequencyId"
                      value={(billingFrequencyId ?? defaultBillingFrequencyId)?.toString() ?? ''}
                      onChange={handleSelectChanged(setBillingFrequencyId, numberSelectValueTransform)}
                      disabled={!!baseSubscription.id || filteredBillingFrequencies.length === 1}
                      required
                    >
                      <MenuItem value={''}>-- Select One --</MenuItem>
                      {filteredBillingFrequencies.map((bf) => (
                        <MenuItem key={bf.id} value={bf.id ?? ''}>{bf.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
              }
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="baseSubscriptionRevenueTrackingCode"
                variant="outlined"
                label="Revenue Tracking Code (GL Code)"
                name="baseSubscriptionRevenueTrackingCode"
                type="text"
                placeholder={defaultTrackingCode ?? '* Required *'}
                value={revenueTrackingCode ?? ''}
                onChange={handleInputChange(setRevenueTrackingCode)}
                required
                size="small"
                fullWidth={true} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="baseSubscriptionInvoiceDescription"
                variant="outlined"
                label="Invoice Description"
                name="baseSubscriptionInvoiceDescription"
                type="text"
                value={invoiceDescription ?? ''}
                onChange={handleInputChange(setInvoiceDescription)}
                size="small"
                fullWidth={true} />
            </Grid>
            <BasePrice
              billingTypeId={billingTypeId ?? null}
              basePrice={basePrice}
              setBasePrice={setBasePrice}
            />
            <BasePriceScalar
              billingTypeId={billingTypeId ?? null}
              basePriceScalar={basePriceScalar}
              setBasePriceScalar={setBasePriceScalar}
            />
            <CostPerLeadMax
              billingTypeId={billingTypeId ?? null}
              costPerLeadMax={costPerLeadMax}
              setCostPerLeadMax={setCostPerLeadMax}
            />
            <VariableBasePrices
              isNewBaseSubscription={!baseSubscription.id}
              billingTypeId={billingTypeId ?? null}
              variableBasePrices={sortedVariableBasePrices ?? []}
              saveVariableBasePrices={saveVariableBasePrices}
              onEditingStateChanged={(x: boolean) => { setEditingVariableBasePrices(x); }}
            />
            {/*
            TODO: This will simply show a warning that an existing editable subscription type may be affected by billing zones, if they exist.
            <BillingZones
              billingTypeId={billingTypeId ?? null}
              billingZones={billingZones ?? []}
              setBillingZones={setBillingZones}
            /> */}
            <Grid item xs={12}>
              <div>
                <em>
                If the "Handle Billing" checkbox is checked, we charge the client.
                If the checkbox is not checked, we charge the partner directly.
                </em>
              </div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={handleBilling === 1}
                    onChange={(e) => { setHandleBilling(e.target.checked ? 1 : 0); }}
                    name="baseSubscriptionHandleBilling"
                    color="primary"
                  />
                }
                label="Handle Billing" />
            </Grid>
            {partnerId !== 20
              && <Grid item xs={12}>
                <TextField
                  id="baseSubscriptionPartnerCut"
                  variant="outlined"
                  label="Partner Cut"
                  name="baseSubscriptionPartnerCut"
                  type="number"
                  value={partnerCut ?? ''}
                  onChange={handleInputChange(setPartnerCut, floatVal)}
                  size="small"
                  fullWidth={true} />
              </Grid>
            }
            <Grid item xs={12}>
              <FormControl variant="outlined" size="small" fullWidth={true}>
                <InputLabel id="baseSubscriptionStatus-label">Status</InputLabel>
                <Select
                  labelId="baseSubscriptionStatus-label"
                  id="baseSubscriptionStatus"
                  value={status}
                  onChange={handleSelectChanged(setStatus, (value) => value === 'active' ? 'active' : 'inactive')}
                >
                  <MenuItem value={'active'}>Active</MenuItem>
                  <MenuItem value={'inactive'}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              Appears on invoice as:
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Item Name</strong></TableCell>
                      <TableCell><strong>Description</strong></TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{itemName}</TableCell>
                      <TableCell>{invoiceDescription || '[Please enter a description]'}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            disabled={
              editingVariableBasePrices
              || (
                // If this is a variable base price type, it must have at least one variable base price record.
                !!billingTypeId
                && isVariableBasePriceBillingType(billingTypeId)
                && (variableBasePrices ?? []).length === 0
              )
            }
            onClick={handleSave}
            color="primary"
          >
            {baseSubscription.id ? 'Save' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
