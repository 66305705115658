import { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Navbar as BsNavbar, Nav, NavDropdown } from 'react-bootstrap';

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAuth } from "../../customHooks/useAuth";

import { checkRouteAccessForUser, RequireAccess } from "./RequireAccess";

import { logout } from "../../redux/features/auth/actions";
import { getUserNotifications } from "../../redux/features/dynamic-hydra/custom-actions";
import { TRealTimeStatus } from "../../redux/features/realtime/realtimeSlice";

import { useAppSelector } from "../../redux/hooks";

import navLogo from '../../assets/images/hydra-logo-with-text-navbar.svg';

import { NotificationsModal } from "./NotificationsModal";

type NavMapItem = { label: string, subtitle?: string, toLink: string, disabled?: boolean };

type NavMap = Array<(
  NavMapItem
  | { label: string, subtitle?: string, disabled?: boolean, dropdownItems: Array<NavMapItem> }
)>;

export const UserNav = ({ isDeveloper } : { isDeveloper: boolean }) => {
  const reportingNavMap: NavMapItem[] = [
    { label: 'Commissions', toLink: '/commissions/commissions_tabs' },
    { label: 'Integrity Checks', toLink: '/data_integrity_errors' },
    { label: 'Monthly Report', toLink: '/reporting/reports/monthly_summary' },
    { label: 'Month Locks', toLink: '/reporting/reports/month_locks' },
    { label: 'Padmapper Summary', toLink: '/reporting/reports/padmapper_monthly_summary' },
    { label: 'Partner Call Tracking Lines Summary', toLink: '/reporting/reports/partner_call_tracking_lines_summary' },
    { label: 'Tax Report', toLink: '/reporting/reports/tax_breakdown_summary', disabled: !isDeveloper },
    { label: '-', toLink: '#' },
    { label: 'Downloadable CSV Reports', toLink: '/reporting/reports/runners' },
  ];
  const invoicingNavMap: NavMapItem[] = [
    { label: 'Charges', toLink: '/charges' },
    { label: 'Credit Notes', toLink: '/credit_notes' },
    { label: 'Fees Manager', toLink: '/fees' },
    { label: 'Invoices', toLink: '/invoices' },
    { label: 'Statements', toLink: '/statements' },
    { label: 'Send Documents', toLink: '/send_documents'},
  ];
  const controlCenterNavMap: NavMapItem[] = [
    { label: 'Billing Runs', toLink: '/billing_runs', disabled: !isDeveloper },
    { label: 'Finance Control Tools', toLink: '/finance_control_tools' },
  ];
  const productManagementNavMap: NavMapItem[] = [
    { label: 'Partners', toLink: '/partners', disabled: !isDeveloper },
    { label: 'Service Categories', toLink: '/products/services', disabled: !isDeveloper },
    {
      label: 'Subscriptions Types',
      subtitle: 'Base Subscriptions',
      toLink: '/products/subscription_types',
    },
    { label: 'Client Subscriptions', toLink: '/subscriptions', disabled: !isDeveloper },
  ];
  const userNavMap: NavMap = [
    { label: 'Clients', toLink: '/clients', disabled: false },
    { label: 'Invoicing', dropdownItems: invoicingNavMap },
    { label: 'Product Management', dropdownItems: productManagementNavMap },
    { label: 'Reporting', dropdownItems: reportingNavMap },
    { label: 'Control Center', dropdownItems: controlCenterNavMap },
  ];

  // Note: Disabling based on the `isDeveloper` property is not necessary as the `devNavMap` is already
  //       not displayed for a non-developer.
  // TODO: Change from using an `isDeveloper` property to using more consistent permissions logic, such `hasRole`, or the <RequireAccess /> or <RequirePermission /> components.
  const devNavMap: NavMapItem[] = [
    { label: 'API Validators', toLink: '/developer/api_validators' },
    { label: 'Component Playground', toLink: '/developer/component_playground' },
    { label: 'FBInvoices', toLink: '/freshbooks_invoices' },
    { label: 'Promoted Listings Segment Timeline', toLink: '/developer/promoted_listings_segment_timeline' },
    { label: 'Info', toLink: '/developer/info' },
    { label: 'Payment Playground', toLink: '/developer/payment_playground' },
    { label: 'Sandbox', toLink: '/developer/sandbox' },
    { label: 'Mobile', toLink: '/mobile' },
    { label: 'Webhook Callback Managment', toLink: '/developer/webhook_callback_management'},
  ];

  const { hasAccess } = useAuth();

  return (<Nav>
    {userNavMap.map((item, i) => (
      ('toLink' in item)
        ? <RequireAccess to={item.toLink} key={i}>
          <Nav.Link as={Link} to={item.toLink} disabled={item.disabled ?? false}>{item.label}</Nav.Link>
        </RequireAccess>
        :
        (item.dropdownItems ?? [])
          .map((dropdownItem) => checkRouteAccessForUser(hasAccess, dropdownItem.toLink))
          .filter(Boolean).length > 0
          ?
          <NavDropdown title={item.label} id={`collapsible-nav-dropdown-${i}`} key={i} disabled={item.disabled}>
            {item.dropdownItems?.map((ddItem, ii) => (
              (ddItem.toLink === '#' && ddItem.label === '-')
                ? <NavDropdown.Divider key={ii} />
                : <RequireAccess to={ddItem.toLink} key={ii}>
                  <NavDropdown.Item as={Link} to={ddItem.toLink} disabled={ddItem.disabled ?? false}>
                    <div>{ddItem.label}</div>
                    {ddItem.subtitle && <div><small>({ddItem.subtitle})</small></div>}
                  </NavDropdown.Item>
                </RequireAccess>
            ))}
          </NavDropdown>
          : null
    ))}
    {
      isDeveloper &&
      <NavDropdown title="Developer" id="collapsible-nav-dropdown">
        {devNavMap.map((item, i) => (
          <RequireAccess to={item.toLink} key={i}>
            <NavDropdown.Item as={Link} to={item.toLink}>{item.label}</NavDropdown.Item>
          </RequireAccess>
        ))}
      </NavDropdown>
    }
  </Nav>);
};

export const Navbar = (
  {
    realtimeStatus,
    isDeveloper,
    isLoggedIn,
  }
  : {
    realtimeStatus: TRealTimeStatus,
    isDeveloper: boolean,
    isLoggedIn: boolean,
  },
) => {
  const location = useLocation();
  const history = useHistory();
  const systemInfo = useAppSelector(state => state.systemInfo);
  const notifications = useAppSelector(state => state.dynamicHydra.data.notifications?.list);
  const token = useAppSelector(state => state.auth.decodedToken);
  const { version, requiredVersion, versionStatus } = useAppSelector(state => state.systemInfo);

  const unreadNotificationCount = useMemo(() => (
    notifications?.filter(n => n.readStatus === 'unread')?.length ?? 0
  ), [ notifications ]);

  const [ showNotifications, setShowNotifications ] = useState(false);

  useEffect(() => {
    getUserNotifications(token);
  }, [ token ]);

  const colors: Record<TRealTimeStatus, string> = {
    idle: 'yellow',
    failed: 'red',
    error: 'red',
    connected: 'green',
  };

  const realtimeColor = colors[realtimeStatus];

  return (
    (location?.pathname === '/')
      ? <div />
      : <BsNavbar bg="dark" variant="dark" fixed="top">
        <BsNavbar.Brand href="/">
          <img src={navLogo} className="App-logo" alt="logo" style={{ height: '30px' }} />
        </BsNavbar.Brand>
        <Nav>
          {isLoggedIn && <UserNav isDeveloper={isDeveloper} />}
        </Nav>
        <Nav className="mr-auto"></Nav>
        <Nav>
          <Nav.Link onClick={() => window.history.back()}>Back</Nav.Link>
        </Nav>
        <Nav>
          {
            isLoggedIn
              ? <Nav.Link onClick={() => {logout(); history.push('/login');} } >Log Out</Nav.Link>
              : <Nav.Link as={Link} to="/login">Login</Nav.Link>
          }
          {
            isDeveloper
            && <Nav.Link as={Link} to='#'>
              <em
                style={{ color: systemInfo.env.NODE_ENV === 'production' ? 'red' : 'gray' }}
              >{systemInfo.env.NODE_ENV}</em>
            </Nav.Link>
          }
        </Nav>
        <Nav>
          <Nav.Link as={Link} to='#'>
            <div style={{ whiteSpace: 'nowrap' }}>
              <FontAwesomeIcon
                icon={faBell as IconProp}
                pulse={unreadNotificationCount > 0}
                onClick={() => { setShowNotifications(true); }}
              />
              <span
                style={{
                  background: '#AA0000',
                  borderRadius: '15px',
                  color: 'white',
                  position: 'relative',
                  fontSize: '8pt',
                  fontWeight: 'bold',
                  padding: '1px',
                  top: '-10px',
                  left: '-8px',
                }}
              >&nbsp;{unreadNotificationCount}&nbsp;</span>
            </div>
            <NotificationsModal
              notifications={notifications ?? []}
              open={showNotifications}
              hide={() => setShowNotifications(false)}
            />
          </Nav.Link>
        </Nav>
        <Nav>
          <Nav.Link as={Link} to='#'>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              borderRadius: '3px',
              backgroundColor: 'green',
              padding: '3px',
            }}>
              <div
                style={{
                  backgroundColor: realtimeColor,
                  fontSize: '10px',
                  lineHeight: '10px',
                  padding: '5px',
                }}
                title={`Realtime features are ${realtimeStatus}`}
              >
                <em>Realtime</em>
              </div>
              <div
                style={{
                  backgroundColor: version === requiredVersion ? 'green' : 'red',
                  fontSize: '7px',
                  // lineHeight: '7px',
                  padding: '5px',
                  textAlign: 'center',
                }}
                title={`Requires v${requiredVersion} - ${versionStatus}`}
              >
                <em>v{version}</em>
              </div>
            </div>
          </Nav.Link>
        </Nav>
      </BsNavbar>
  );
};
