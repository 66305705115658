import querystring from 'querystring';
import { Nullable } from '@jamesgmarks/utilities';

import { dispatch } from "../../store";
import { IDocumentMap, TDocumentName } from "./interfaces";
import { EDocumentsLoadedState, loadReceivedDocuments, setLoadedState } from "./documentsSlice";
import { REACT_APP_API_ROOT_URI } from "../../../App";
import { typedApiFetch } from "../../utils";

export const getDocuments = async (
  isoStartDate: string,
  isoEndDate: string,
  documentNames: TDocumentName[],
  billingAccountId: Nullable<string>,
) => {
  dispatch(setLoadedState(EDocumentsLoadedState.loading));

  const parsedQuery = querystring.stringify({
    isoStartDate,
    isoEndDate,
    documentNames,
    ...(billingAccountId ? { billingAccountId } : {}),
  });

  const url = `${REACT_APP_API_ROOT_URI}/documents?${parsedQuery}`;

  const response = await typedApiFetch<IDocumentMap>(url);

  const documentsMap = await response.json();

  dispatch(loadReceivedDocuments(documentsMap));

  dispatch(setLoadedState(EDocumentsLoadedState.loaded));
};
