import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import {
  Box,
  IconButton,
  Snackbar,
  ThemeProvider,
} from "@mui/material";
import { Close as CloseIcon } from '@mui/icons-material';
import { Container } from 'react-bootstrap';
import { theme } from './theme';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import hydraLogo from './assets/images/hydra-logo.svg';

import { closeMessage } from './redux/features/messaging/actions';
import { dispatch } from './redux/store';
import { EVersionStatus } from './redux/features/systemInfo/systemInfoSlice';
import { getSystemInfo } from './redux/features/systemInfo/actions';
import { isTokenExpired, isTokenValid } from './redux/features/auth/helpers';
import { setUserToken } from './redux/features/auth/authSlice';
import { useAppSelector } from './redux/hooks';

import { AuthRoute } from './components/parts/AuthRoute';
import { BillingRunsContainer } from './components/pages/BillingRuns/BillingRuns';
import { ClientsContainer } from './components/pages/Clients/Clients';
import { CommissionsContainer } from './components/pages/Commissions/CommissionsContainer';
import { CreditNotesContainer } from './components/pages/Credits/CreditNotesContainer';
import { DataIntegrityErrors } from './components/pages/Developer/DataIntegrityErrors/DataIntegrityErrors';
import { Developer } from './components/pages/Developer/Developer';
import { FeesContainer } from './components/pages/Subscriptions/Fees';
import { FreshbooksInvoice } from './components/pages/Invoices/FreshbooksInvoice';
import { FreshbooksInvoices } from './components/pages/Invoices/FreshbooksInvoices';
import { ChargesContainer } from './components/pages/Charges/Charges';
import { Home } from './components/pages/Home';
import { InvoicesContainer } from './components/pages/Invoices/InvoicesContainer';
import { LoginForm } from './components/pages/LoginForm';
import { Manager } from './components/pages/Manager';
import { Navbar } from './components/parts/Navbar';
import { PartnersContainer } from './components/pages/Partners/Partners';
import { ProductManager } from './components/pages/Products/ProductManager';
import { ReportingContainer } from './components/pages/Reporting/ReportingContainer';
import { SendDocuments } from './components/pages/SendDocuments/SendDocuments';
import { SubscriptionManagement } from './components/pages/Subscriptions/SubscriptionManagement';
import { Mobile } from './components/pages/Mobile/Mobile';
import { StatementsContainer } from './components/pages/Statements/StatementsContainer';
import { ChatBot } from './components/parts/ChatBot';
import { VideoEmbed } from './components/parts/VideoEmbed';
import { ErrorMessageAlert } from './components/parts/ErrorMessageAlert';
import { FinanceControlToolsContainer } from './components/pages/FinanceControlTools/FinanceControlToolsContainer';

export const REACT_APP_ROOT_URI = (
  window.location.origin === 'http://localhost:3000' ? 'http://localhost:5000' : window.location.origin
);
export const REACT_APP_API_ROOT_URI = REACT_APP_ROOT_URI + '/api';

const checkLocalStorageToken = () => {
  const token = localStorage.getItem('token');
  if (token) {
    dispatch(setUserToken(token));
  }
};

checkLocalStorageToken();

function App() {
  useEffect(() => {
    if (isTokenValid()) {
      getSystemInfo();
    }
  }, []);

  const decodedToken = useAppSelector(state => state.auth?.decodedToken);
  const isDeveloper = useAppSelector(state => state.auth?.isDeveloper);
  const errorMessages = useAppSelector(state => state.messaging.errors);
  const realtimeStatus = useAppSelector(state => state.realtime.connectedState);
  const { version, requiredVersion, versionStatus } = useAppSelector((state) => state.systemInfo);

  const isLoggedIn = !!decodedToken && !isTokenExpired();

  if (versionStatus === EVersionStatus.invalid) {
    return (
      <Container>
        <div style={{ width: '60%', textAlign: 'center', margin: '0 auto' }}>
          <img src={hydraLogo} className="App-logo" alt="logo" />
        </div>
        <header className="Section-header">Invalid UI Version</header>
        <p>
          Your User Interface is out of date.
          You are using version {version} but the current required version is {requiredVersion}.
        </p>
        <p>
          You probably loaded an older version from your browser cache.{' '}
          Please try refreshing your browser to load a more recent version of the Hydra UI.
        </p>
        <p>
          If this message persists, contact a developer.
        </p>
      </Container>
    );
  }

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Navbar isLoggedIn={isLoggedIn} isDeveloper={isDeveloper} realtimeStatus={realtimeStatus} />
        {isLoggedIn && <ChatBot></ChatBot>}
        <VideoEmbed />
        <Container style={{ marginTop: '4rem' }}>
          <div>
            {errorMessages.map((message) => (
              message.asAlert
                ? (
                  <Box key={message.id} pt={1}>
                    <ErrorMessageAlert
                      messageDescription={message}
                      messageId={message.id}
                      showTitle={false}
                      currentUserId={decodedToken?.userId}
                    />
                  </Box>
                ) : (
                  <Snackbar
                    key={message.id}
                    // action={
                    //   <IconButton
                    //     aria-label="close"
                    //     color="inherit"
                    //     onClick={(e) => closeMessage(message.id ?? Date.now().toString())}
                    //     size="small"
                    //   >
                    //     <CloseIcon fontSize="inherit" />
                    //   </IconButton>
                    // }
                    anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                    autoHideDuration={10000}
                    // onClose={(event, reason) => closeMessage(message.id!)}
                    open={true}
                  >
                    <div> {/* NOTE: This `div` is necessary for the `Snackbar` to accept a function-component child */}
                      <ErrorMessageAlert
                        messageDescription={message}
                        messageId={message.id}
                        variant='filled'
                      />
                    </div>
                  </Snackbar>
                )
            ))}
          </div>
        </Container>
        {
          !isLoggedIn
          && !window.location.href.endsWith('/login')
          && !(window.location.pathname.trim() === '/')
          && !(window.location.pathname.trim() === '')
          && <Redirect push to="/login" />
        // && <LoginModal isLoggedIn={isLoggedIn} token={token ?? ''} show={true} />
        }
        <Box style={{paddingTop: '1.5rem'}}>
          <Switch>
            <AuthRoute path="/mobile"><Mobile /></AuthRoute>
            <AuthRoute path="/billing_runs"><BillingRunsContainer /></AuthRoute>
            <AuthRoute path="/clients"><ClientsContainer /></AuthRoute>
            <AuthRoute path="/commissions"><CommissionsContainer /></AuthRoute>
            <AuthRoute path="/credit_notes"><CreditNotesContainer /></AuthRoute>
            <AuthRoute path="/data_integrity_errors"><DataIntegrityErrors /></AuthRoute>
            <AuthRoute path="/developer"><Developer /></AuthRoute>
            <AuthRoute path="/fees"><FeesContainer /></AuthRoute>
            <AuthRoute path="/finance_control_tools"><FinanceControlToolsContainer /></AuthRoute>
            <AuthRoute path="/freshbooks_invoices/:invoiceId"><FreshbooksInvoice /></AuthRoute>
            <AuthRoute path="/freshbooks_invoices"><FreshbooksInvoices /></AuthRoute>
            <AuthRoute path="/charges"><ChargesContainer /></AuthRoute>
            <AuthRoute path="/invoices"><InvoicesContainer /></AuthRoute>
            <AuthRoute path="/statements"><StatementsContainer /></AuthRoute>
            <AuthRoute path="/manager"><Manager /></AuthRoute>
            <AuthRoute path="/partners"><PartnersContainer /></AuthRoute>
            <AuthRoute path="/products"><ProductManager /></AuthRoute>
            <AuthRoute path="/send_documents"><SendDocuments /></AuthRoute>
            <AuthRoute path="/subscriptions"><SubscriptionManagement /></AuthRoute>
            <Route path="/reporting"><ReportingContainer /></Route>
            <Route path="/login"><LoginForm isLoggedIn={isLoggedIn} /></Route>
            <Route path="/"><Home /></Route>
          </Switch>
        </Box>
      </ThemeProvider>
    </Router>
  );
}

export default App;
