import { REACT_APP_API_ROOT_URI } from "src/App";
import { IApiQuerySingularResponse, typedApiFetch } from "src/redux/utils";
import { IPaymentDetail } from '@llws/hydra-shared';

export const createCheckoutFormSessionAndGetSessionId = async (details: IPaymentDetail[]) => {
  const url = `${REACT_APP_API_ROOT_URI}/payments/checkout-session`;
  const body = { paymentDetails: [...details]};
  const response = await typedApiFetch<IApiQuerySingularResponse<string>>(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const sessionIdObject = await response.json();
  return sessionIdObject.data;
};