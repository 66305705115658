import { createSlice } from '@reduxjs/toolkit';
import { arrayWrap, Nullable } from '@jamesgmarks/utilities';
import { IApiQueryResponse } from '@llws/api-common';

import { IActionType } from '../../utils';
import { BillingAccount } from '../../../../../entities/hydra';

export enum EClientLoadState {
  idle = 'idle',
  loading = 'loading',
  loaded = 'loaded',
}

export interface IAccountsState {
  loadedState: EClientLoadState,
  accountsList: BillingAccount[],
  currentAccount: Nullable<BillingAccount>,
};

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState: {
    loadedState: EClientLoadState.idle,
    accountsList: [],
    currentAccount: null,
  } as IAccountsState,
  reducers: {
    billingAccountsReceived: (state, action: IActionType<IApiQueryResponse<BillingAccount>>) => {
      state.accountsList = arrayWrap(action.payload.data);
    },
    singleBillingAccountReceived: (state, action: IActionType<IApiQueryResponse<BillingAccount>>) => {
      [ state.currentAccount ] = arrayWrap(action.payload.data);
    },
    setLoadedState: (state, action: IActionType<EClientLoadState>) => {
      state.loadedState = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  billingAccountsReceived,
  setLoadedState,
  singleBillingAccountReceived,
} = accountsSlice.actions;

export default accountsSlice.reducer;
