import { Charge } from "../../../entities/hydra/Charge";
import { ICommissionClawback } from "./ICommissionClawback";
import { TCurrencyCode } from '@llws/hydra-shared';
import { Invoice } from "../../../entities/hydra";

// eslint-disable-next-line no-shadow
export enum ECreditTypes {
  note = 'Credit (Note)',
  payment = 'Credit (Payment)',
  reduction = 'Credit (Reduction)',
  legacy = 'Credit (Legacy)',
}

export interface ICredit {
  id?: number;
  creditNoteId?: number;
  creditNumber: string | null;
  freshbooksClientId: number;
  clientId: number | null;
  partnerId: number | null;
  partnerClawback?: string | null;
  clawbackFromPartner: boolean | null;
  invoiceId: number | null;
  invoiceNumber: number | null;
  invoiceDate?: string;
  subscriptionId: number | null;
  generatedSubscriptionId: number | null;
  freshbooksCreditId?: number | null;
  currencyCode: TCurrencyCode;
  amount: string;
  taxRate: string;
  taxAmount: string;
  description: string;
  hydraState: 'active' | 'deleted';
  creditDate: Date;
  year?:number | null;
  month?:number | null;
  rawJson?: string;
  commissionClawbackList?: ICommissionClawback[];
  generatedSubscription?: Charge;
  invoice?: Invoice;
}
