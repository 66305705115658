import { IApiQueryResponse } from "@llws/api-common";
import { Credit } from "../../../../../entities/hydra";
import { IAddCreditModalSubmitData } from "../../../interfaces/IAddCreditModalSubmitData";
import { dispatch } from "../../store";
import { apiFetch } from "../../utils";
import { creditDataReceived, creditsListReceived, setLoadedState } from "./creditsSlice";
import { ICreditSummaryData } from '../../../interfaces/ICreditSummaryData';
import { IApplyCreditModalSubmitData } from "../../../interfaces/IApplyCreditModalSubmitData";
import { loadCurrentInvoice } from "../invoices/actions";
import { REACT_APP_API_ROOT_URI } from "../../../App";

export const addCredit = async (creditData: IAddCreditModalSubmitData) => {
  console.log('Credit data: ', creditData);
  const url = `${REACT_APP_API_ROOT_URI}/credits`;
  dispatch(setLoadedState('loading'));
  const response = await apiFetch(url, {
    method: 'POST',
    body: JSON.stringify({ credit: creditData }),
  });
  const responseData = await response.json() as IApiQueryResponse<Credit>;
  console.log({ status: response.status, responseData });
  // dispatch(currentCreditReceived(responseData)); // TODO: Error handling?
  if (creditData.invoiceId) {
    await loadCurrentInvoice({ invoiceId: creditData.invoiceId, forceRefresh: true });
  }
  const { freshbooksClientId, currencyCode, taxRate } = creditData;
  if (freshbooksClientId) {
    await loadAvailableCreditData({freshbooksClientId, currencyCode, taxRate: `${taxRate}`});
  }
};

export const applyCredit = async (creditApplyData: IApplyCreditModalSubmitData) => {
  const url = `${REACT_APP_API_ROOT_URI}/credits/applied`;
  dispatch(setLoadedState('loading'));
  const response = await apiFetch(url, {
    method: 'POST',
    body: JSON.stringify(creditApplyData),
  });
  const responseData = await response.json() as IApiQueryResponse<Credit>;
  console.log({ status: response.status, responseData });
  // dispatch(currentCreditReceived(responseData)); // TODO: Error handling?
  if (creditApplyData.invoiceId) {
    await loadCurrentInvoice({ invoiceId: creditApplyData.invoiceId, forceRefresh: true });
  }
  const { freshbooksClientId, currencyCode, taxRate } = creditApplyData;
  if (freshbooksClientId) {
    await loadAvailableCreditData({freshbooksClientId, currencyCode, taxRate: `${taxRate}`});
  }
};

export const loadCreditsForClient = async ({ clientId, currencyCode }: { clientId: number, currencyCode: string }) => {
  const url = `${REACT_APP_API_ROOT_URI}/credits?clientId=${clientId}&currencyCode=${currencyCode}`;
  dispatch(setLoadedState('loading'));
  const response = await apiFetch(url);
  const responseData = await response.json() as IApiQueryResponse<Credit>;
  dispatch(creditsListReceived(responseData));
};

export const loadAvailableCreditData = async ({
  freshbooksClientId, currencyCode, taxRate,
}: {
  freshbooksClientId: number, currencyCode: string, taxRate: string
}) => {
  const url = (
    `${REACT_APP_API_ROOT_URI}/credits/available`
    + `?freshbooksClientId=${freshbooksClientId}&currencyCode=${currencyCode}&taxRate=${taxRate}`
  );
  dispatch(setLoadedState('loading'));
  const response = await apiFetch(url);
  const responseData = await response.json() as IApiQueryResponse<ICreditSummaryData>;
  dispatch(creditDataReceived(responseData));
};
