import { useCallback, useEffect, useState } from 'react';

import { 
  Autocomplete, 
  TextField,
  Typography,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

import { Hash, Nullable } from '@jamesgmarks/utilities';

import { IClientDropdownItem } from '../../entity-interfaces/IClientDropdownItem';
import { loadDropDownClients } from '../../redux/features/clients/actions';
import { useAppSelector } from '../../redux/hooks';

type TClientDropdownProps = {
  allowFreeform?: boolean,
  allowedClientIds?: number[],
  clientId: Nullable<number>,
  initialSearchString?: string,
  onClientChanged: (client: Nullable<IClientDropdownItem>) => void,
  showPartners?: boolean,
  starredClientId?: number,
  isPartnerId?: boolean,
  textFieldBackgroundColor?: string,
} & (
  {
    allowFreeform: true,
    onClientFreeForm: (freeFormName: Nullable<string>) => void,
  }
  | {
    allowFreeform: false,
    onClientFreeForm?: (freeFormName: Nullable<string>) => void,
  }
  | {
    onClientFreeForm?: (freeFormName: Nullable<string>) => void,
  }
)

const getClientOptionString = (client: IClientDropdownItem) => (
  `${client.name} (${client.type === 'client' ? 'Client' : 'Partner'} ID: ${client.id})`
);

export const ClientDropdown = (
  {
    allowFreeform = false,
    allowedClientIds,
    clientId,
    onClientChanged,
    onClientFreeForm,
    showPartners = true,
    starredClientId,
    isPartnerId = false,
    textFieldBackgroundColor = '#fff',
  }: TClientDropdownProps,
) => {
  const clientList = (
    useAppSelector(
      (state) => state.clients.clientDropDownSource
        .filter((c) => allowedClientIds ? allowedClientIds.includes(c.id) : true),
    )
  );
  
  const options = (
    clientList
      .slice()
      .sort((a, b) => a.name.trim().toLowerCase() < b.name.trim().toLowerCase() ? -1 : 1)
  );

  const [ clientQuery, setClientQuery ] = useState<Nullable<string>>(null);
  const [ selectedClient, setSelectedClient ] = useState<Nullable<IClientDropdownItem>>(null);
  
  const handleInputChange = (clientSearch: Nullable<string>) => {
    setClientQuery(clientSearch);

    if (allowFreeform) {
      onClientFreeForm!(clientSearch);
    };
  };
  
  const handleClientSelected = useCallback(
    (client: Nullable<IClientDropdownItem>) => {
      setSelectedClient(client);

      onClientChanged(client);

      allowFreeform && onClientFreeForm!(client ? getClientOptionString(client) : '');
    }, [ allowFreeform, onClientChanged, onClientFreeForm ],
  );

  useEffect(() => {
    loadDropDownClients(showPartners);
  }, [ showPartners ]);

  useEffect(() => {
    if (clientId && clientList) {
      const client = clientList.find((c) => c.id === clientId && (c.type === (isPartnerId ? 'partner' : 'client')));
      client && handleClientSelected(client);

      if (client && client.id !== clientId) {
        setClientQuery(getClientOptionString(client));
      }
    }
  }, [ clientId, clientList, clientQuery, handleClientSelected, isPartnerId ]);

  return (
    <Autocomplete
      id="client-selector"
      autoComplete
      autoHighlight={!allowFreeform}
      clearOnBlur={!allowFreeform}
      clearOnEscape
      freeSolo={allowFreeform}
      fullWidth
      getOptionLabel={
        (option: unknown) => { 
          if (typeof option === 'string') {
            return option;
          } else if (option && (option as Hash).inputValue) {
          // Create a new value from the user input
            return (option as Hash).inputValue;
          } else {
            const c = option as IClientDropdownItem;
            return `${c.name} (${c.type === 'client' ? 'Client' : 'Partner'} ID: ${c.id})`;
          }
        }
      }
      inputValue={clientQuery ?? ''}
      onChange={
        (_e, newValue: unknown, _reason) => {
          if (typeof newValue === 'string') {
            handleInputChange(newValue);
          } else if (newValue && (newValue as Hash).inputValue) {
          // Create a new value from the user input
            handleInputChange((newValue as Hash).inputValue);
          } else if ((newValue as IClientDropdownItem)?.freshbooksClientId) {
            handleClientSelected(newValue as IClientDropdownItem);
          } else {
            handleClientSelected(null);
          }
        }
      }
      onInputChange={
        (_e, value: string, _reason) => {
          handleInputChange(value);
        }
      }
      options={options}
      renderInput={
        (params) =>  (
          <TextField
            { ...params }
            label="Client"
            sx={{ backgroundColor: textFieldBackgroundColor }}
            variant="outlined"
          />
        )
      }
      renderOption={
        (props, option: unknown) => {
          const o = option as IClientDropdownItem;

          return (
            <li {...props}>
              <Typography>
                <span style={{ color: 'dodgerblue', fontWeight: 550 }}>{o.name}</span>
                <span style={{ color: 'gray' }}> | </span>
                <span>
                  {`${o.type === 'client' ? 'Client': 'Partner'} ID: ${o.id}`}
                </span>
                { o.status !== 'active'
                 && <>
                   <span style={{ color: 'gray' }}> | </span>
                   <span style={{ color: 'red', fontWeight: 550 }}>
                     {o.status}
                   </span>
                 </>
                }
              </Typography>
              { starredClientId && o.id === starredClientId && <StarIcon sx={{ ml: 2, mb: 0.3 }} />}
            </li>
          );
        }
      }

      size="small"
      value={selectedClient}
    />
  );
};
