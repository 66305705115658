import { floatVal, Hash, Nullable } from "@jamesgmarks/utilities";
import { 
  TableContainer,
  Table as MuiTable,
  TableHead,
  Paper,
  TableBody,
  CircularProgress,
  Box,
  Checkbox,
} from "@mui/material";
import { useCallback, useMemo } from "react";
import { makeYmd, toDollarAmount } from "src/app-utils";
import { StyledTableCell, StyledTableRow } from "src/components/parts/mui/StyledTables";
import { useAppSelector } from "src/redux/hooks";
import React from "react";
import { EStatementGenerationType, IStatement } from "src/entity-interfaces/IStatement";
import { IApiQueryListResponse } from "src/redux/utils";
import { SplitButton, SplitButtonItem } from "src/components/parts/SplitButton";
import { downloadSingleStatement } from "./StatementsListView";

const StatementRow = (
  {
    statementData, 
    checked,
    setSelectedStatement,
  } : {
    statementData: IStatement, 
    checked: boolean,
    setSelectedStatement: ((statementId: number, checkboxState: boolean) => void)
  },
) => {
  const clientName = useMemo(() => {
    return (statementData.billingAccount?.clients ?? [])
      .find((client) => client.isParentClient)
      ?.clientName ?? 'N/A';
  }, [statementData]);

  const totalClients = useMemo(() => {
    return (statementData.billingAccount?.clients ?? [])
      .length;
  }, [statementData]);

  const ownershipGroupBasedOnGenerationType = useMemo(() => (
    statementData.generationType === EStatementGenerationType.manualOg
      ? 'No Ownership Group (N/A)'
      : null
  ), [statementData]);

  const ownershipGroupName = useMemo(() => (
    statementData.ownershipGroup?.name ?? ownershipGroupBasedOnGenerationType
  ), [statementData, ownershipGroupBasedOnGenerationType]);

  const downloadSinglePdf = useCallback(async (statementData: IStatement) => {
    await downloadSingleStatement(statementData.id, statementData.statementType);
  }, []);

  const splitButtonActions = (statementData: IStatement): Hash => (
    {
      'view': () => {
        const url = `/statements/${statementData.id}`;
        window.open(url, '_blank');
      },
      'downloadPdf': () => {
        downloadSinglePdf(statementData);
      },
    }
  );

  return (
    <StyledTableRow
      customSx={{ userSelect: 'none' }}
      dark={true}
      hover={true}
    >
      <StyledTableCell dark={true} style={{ whiteSpace: 'nowrap' }}>
        <Checkbox
          checked={checked}
          style={{color: 'white'}}
          onChange={() => setSelectedStatement(statementData.id, !checked)}
        />
        <span>{checked}</span>
      </StyledTableCell>
      <StyledTableCell dark={true} style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
        {clientName}
        {totalClients > 1 && ` (${totalClients} total clients)`}
        {ownershipGroupName && <><br /><small>{ownershipGroupName}</small></>}
      </StyledTableCell>
      <StyledTableCell dark={true} style={{ whiteSpace: 'nowrap' }}>
        {makeYmd(new Date(statementData.generationTimestamp))}
      </StyledTableCell>
      <StyledTableCell dark={true}>
        <span
          style={{ whiteSpace: 'nowrap' }}
        >
          {toDollarAmount(floatVal(statementData.totalOutstanding))}
        </span>
      </StyledTableCell>
      <StyledTableCell dark={true}>
        <SplitButton onSelect={(id, index) => {
          splitButtonActions(statementData)[id]();
        }}
        >
          <SplitButtonItem label="View" id="view"/>
          <SplitButtonItem label="Download PDF" id="downloadPdf"/>
        </SplitButton>
      </StyledTableCell>
    </StyledTableRow>
  );
};

// improve performance by only re-rendering if props change
const StatementListRow = React.memo(StatementRow);

export const StatementListContainer = ({
  statementsList,
  statementListMetaData,
  selectedStatements,
  selectAllStatementCheckboxState,
  toggleAllStatementsCheckbox,
  toggleIndividualStatementCheckbox,
}: {
  statementsList: IStatement[],
  statementListMetaData: Nullable<IApiQueryListResponse<IStatement>['meta']>,
  selectedStatements: number[],
  selectAllStatementCheckboxState: boolean,
  toggleAllStatementsCheckbox: ((checkedValue: boolean) => void),
  toggleIndividualStatementCheckbox: ((statementId: number, checkboxState: boolean) => void),

}) => {

  const loadedState = useAppSelector(state => state.statements.loadedState);

  return (
    <>
      <TableContainer component={Paper} elevation={12} sx={{ mb: 1 }}>

        <MuiTable stickyHeader style={{ borderCollapse: 'collapse' }}>
          <TableHead>
            <StyledTableRow dark={true} >
              <StyledTableCell
                dark={true}
                style={{ textAlign: 'center' }}
              >
                <Checkbox
                  style={{color: 'white'}}
                  checked={selectAllStatementCheckboxState}
                  onChange={(e) => toggleAllStatementsCheckbox(e.target.checked)}
                />
              </StyledTableCell>
              <StyledTableCell
                dark={true}
                style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
              >
                Client & Group
              </StyledTableCell>
              <StyledTableCell
                dark={true}
                style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
              >
                Statement Date
              </StyledTableCell>
              <StyledTableCell
                dark={true}
                style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
              >
                Total Outstanding
              </StyledTableCell>
              <StyledTableCell>&nbsp;</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {(loadedState === 'loading'
              ? []
              : (statementsList ?? []))
              .map((i) => (
                <StatementListRow
                  key={i.id}
                  statementData={i}
                  checked={selectedStatements.includes(i.id)}
                  setSelectedStatement={toggleIndividualStatementCheckbox}
                />
              ))
            }
          </TableBody>
        </MuiTable>
      </TableContainer>
      {loadedState === 'loading'
        ? <Box sx={{ textAlign: 'center' }}><CircularProgress size={25} color='primary' sx={{ ml: 2 }} /> </Box>
        : statementsList?.length === 0 && <Box sx={{ textAlign: 'center' }}>NO DATA AVAILABLE</Box>
      }
    </>
  );
};