import { createSlice } from '@reduxjs/toolkit';
import { Hash, Nullable } from '@jamesgmarks/utilities';
import { IActionType } from '../../utils';
import { ISandbox } from '../../../interfaces/ISandbox';

export interface ISandboxesState {
  loadedState: Nullable<string>,
  sandboxes?: ISandbox[],
  lastSandboxResult: Nullable<{ sandboxname: string, output: Hash }>,
};

export const sandboxSlice = createSlice({
  name: 'sandboxes',
  initialState: {
    loadedState: null,
    sandboxes: [],
    lastSandboxResult: null,
  } as ISandboxesState,
  reducers: {
    sandboxListReceived: (state, action: IActionType<ISandbox[]>) => {
      console.log(`Receiving (${action.payload?.length}) sandboxes.`);
      state.sandboxes = action.payload;
      state.loadedState = 'loaded';
    },
    updateLastSandboxResult: (state, action: IActionType<{ sandboxname: string, output: Hash }>) => {
      state.lastSandboxResult = action.payload;
      state.loadedState = 'loaded';
    },
    setSandboxLoadedState: (state, action: IActionType<{ loadedState: string }>) => {
      state.loadedState = action.payload.loadedState;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  sandboxListReceived,
  setSandboxLoadedState,
  updateLastSandboxResult,
} = sandboxSlice.actions;

export default sandboxSlice.reducer;