import { Nullable } from "@jamesgmarks/utilities";
import {
  Avatar, Box, Button, FormControlLabel, FormGroup, Grid, Paper, Switch, TextField, Typography,
} from "@mui/material";
import { useState } from "react";
import { capitalize } from "../../../app-utils/helpers";
import { ICreditNote, isICreditNote } from "../../../interfaces/ICreditNote";
import { INoteComment } from "../../../../../entities/jsonTypes/INoteComment";

interface ICreditNoteNotesSectionProps {
  allowEdits: boolean;
  incomingCreditNote?: Nullable<ICreditNote>;
  onSave: (note: ICreditNote) => void;
  setCreditNote: React.Dispatch<React.SetStateAction<ICreditNote>>;
}
export const NoteComment = ({
  note,
  createdUserId,
  createdUserName,
  createdTimestamp,
  incomingDisplayCommentOnPdf,
  onSave,
  setCreditNote,
}: INoteComment & {
  onSave: (note: ICreditNote) => void,
  setCreditNote: React.Dispatch<React.SetStateAction<ICreditNote>>,
  incomingDisplayCommentOnPdf: boolean,
}) => {
  const [ displayCommentOnPdf, setDisplayCommentOnPdf ] = useState(incomingDisplayCommentOnPdf);

  /**
   * Takes a credit note, and a new `displayCommentOnPdf` setting. Updates the current `NoteComment`
   * on the credit note with the new setting.
   * @param creditNote The credit note to update.
   * @param displayCommentOnPdf Whether or not to display this comment on generated PDFs.
   */
  const updateCreditNoteCommentWithNewDisplayOnPdfSetting = (
    creditNote: ICreditNote,
    displayCommentOnPdf: boolean,
  ): ICreditNote => ({
    ...creditNote,
    notes: [
      ...(creditNote?.notes ?? []).filter((n) => n.createdTimestamp !== createdTimestamp),
      {
        ...(creditNote?.notes ?? [])
          .find((n) => n.createdTimestamp === createdTimestamp)!, displayCommentOnPdf,
      },
    ],
  });

  return (
    <>
      { createdUserName && createdUserId && createdTimestamp
      &&
      <Paper  elevation={3}  sx={{ p: 2, mb: 1 }}>
        <Grid container  mt={1}   wrap="nowrap"  spacing={2}>
          <Grid item>
            <Avatar sx={{ bgcolor: "#" + createdUserId.toString(16).padStart(6, '0') }} variant="circular">
              {createdUserName?.length > 1 ? capitalize(createdUserName).slice(0, 1) : 'no name'}
            </Avatar>
          </Grid>
          <Grid justifyContent="left" item xs zeroMinWidth>
            <Typography variant="h5">
              {capitalize(createdUserName)}
            </Typography>
            <Typography variant="body1" mt={1}>
              {note}
            </Typography>
            <Typography variant="body1" color="gray"  mt={ 2 }>
              {new Date(createdTimestamp).toLocaleString()}
            </Typography>
          </Grid>
          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={displayCommentOnPdf}
                    onChange={
                      () => setDisplayCommentOnPdf((old) => {
                        setCreditNote((oldCreditNote) => {
                          const newCreditNote = updateCreditNoteCommentWithNewDisplayOnPdfSetting(oldCreditNote, !old);
                          onSave(newCreditNote);

                          return newCreditNote;
                        });

                        return !old;
                      })
                    }
                  />
                }
                label="Display on PDF"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Paper>
      }
    </>
  );
};

interface INoteCommentSectionProps {
  comments:INoteComment[];
  setCreditNote: React.Dispatch<React.SetStateAction<ICreditNote>>;
  onSave: (note: ICreditNote) => void;
}

export const NoteCommentSection = ({
  comments,
  setCreditNote,
  onSave,
}: INoteCommentSectionProps) => {
  const sortedComments = (comments ?? []).slice().sort((commentA, commentB) => {
    return (
      commentA.createdTimestamp && commentB.createdTimestamp
        ? new Date(commentA.createdTimestamp).getTime() - new Date (commentB.createdTimestamp).getTime()
        : -1
    );
  });

  return (
    <>
      {
        sortedComments.length > 0
        && (
          <Box>
            {
              sortedComments.map((comment, key) => {
                return (
                  <NoteComment
                    key={key}
                    note={comment.note}
                    createdUserId={comment.createdUserId}
                    createdUserName={comment.createdUserName}
                    createdTimestamp={comment.createdTimestamp}
                    version={comment.version}
                    displayCommentOnPdf={comment.displayCommentOnPdf}
                    incomingDisplayCommentOnPdf={comment.displayCommentOnPdf ?? false}
                    setCreditNote={setCreditNote}
                    onSave={onSave}
                  />
                );
              })
            }
          </Box>
        )
      }
    </>
  );
};

export const CreditNoteNotesSection = ({
  allowEdits,
  incomingCreditNote,
  onSave,
  setCreditNote,
}: ICreditNoteNotesSectionProps) => {
  const [newComment, setNewComment] = useState('');
  const [ displayCommentOnPdf, setDisplayCommentOnPdf ] = useState(false);

  /**
   * Adds a new `NoteComment` to the provided credit note.
   * @param creditNote The credit note to add the note to.
   * @param displayCommentOnPdf Whether or not to display this comment on generated PDFs.
   */
  const addCommentToCreditNote = (
    creditNote: ICreditNote,
    note: string,
    displayCommentOnPdf: boolean,
  ): ICreditNote => ({
    ...creditNote,
    notes: [
      ...(incomingCreditNote?.notes ?? []), {
        note,
        version: '2.0',
        displayCommentOnPdf,
      },
    ],
  });

  return (
    <>
      <Grid item mt={5} xs={12}>
        <Typography variant="h4" mb={2}>
          Notes
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <NoteCommentSection
          comments={incomingCreditNote?.notes ?? []}
          setCreditNote={setCreditNote}
          onSave={onSave}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          id="outlined-multiline-static"
          label="Notes"
          multiline
          rows={4}
          value={newComment}
          sx={{ mt: 2, background: 'white' }}
          onChange={ (e) =>{
            setNewComment(e.target.value);
            setCreditNote((oldCreditNote) => {
              const newCreditNote = addCommentToCreditNote(oldCreditNote, e.target.value, displayCommentOnPdf);

              return isICreditNote(newCreditNote) ? newCreditNote : oldCreditNote;
            });
          }}
        />
      </Grid>

      {
        newComment.length > 0
        && !allowEdits
        && (
          <Grid item xs container mt={1} mb={5} spacing={1.25}>
            <Grid item>
              <Button variant="contained"
                onClick={() => {
                  setCreditNote((oldCreditNote) => {
                    const newCreditNote = addCommentToCreditNote(oldCreditNote, newComment, displayCommentOnPdf);

                    if (isICreditNote(newCreditNote)) {
                      onSave(newCreditNote);
                    }

                    return isICreditNote(newCreditNote) ? newCreditNote : oldCreditNote;
                  });

                  setNewComment('');
                  setDisplayCommentOnPdf(false);
                }}
              >Save</Button>
            </Grid>
            <Grid item>
              <Button color='warning' variant="contained" onClick={()=> {
                setNewComment('');
              }}>Cancel</Button>
            </Grid>
          </Grid>
        )
      }
      <Grid item ml={2}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={displayCommentOnPdf}
                onChange={
                  () => {
                    setDisplayCommentOnPdf((old) => {
                      setCreditNote((oldCreditNote) => {
                        const newCreditNote = addCommentToCreditNote(oldCreditNote, newComment, !old);

                        return isICreditNote(newCreditNote) ? newCreditNote : oldCreditNote;
                      });

                      return !old;
                    });
                  }
                }
              />
            }
            label="Display on PDF"
          />
        </FormGroup>
      </Grid>
    </>
  );
};
