import {
  IBillingFrequencies,
  IPartners,
  IServices,
  IBillingTypes,
  ISubscriptions,
} from '@llws/lift-entity-interfaces';
import { Charge } from '../../../../../entities/hydra';
import { REACT_APP_API_ROOT_URI } from '../../../App';
import { makeYmd } from '../../../app-utils';
import { IClientRuntimeGroup } from '../../../entity-interfaces/IClientRuntimeGroup';
import { dispatch } from '../../store';
import { apiFetch, IApiQueryListResponse, typedApiFetch } from '../../utils';
import * as lookups from './lookupsSlice';

export const loadBillingFrequenciesLookup = async () => {
  const url = `${REACT_APP_API_ROOT_URI}/lookups/billing_frequencies`;
  const response = await apiFetch(url);
  const responseData = await response.json() as IApiQueryListResponse<IBillingFrequencies>;
  dispatch(lookups.billingFrequenciesReceived(responseData.data)); // TODO: Error handling?
};

export const loadPartnersLookup = async ({
  includeInactive = false,
}: {
  includeInactive: boolean,
}) => {
  const queryString = `includeInactive=${includeInactive ? 'true' : 'false'}`;
  const url = `${REACT_APP_API_ROOT_URI}/lookups/partners${queryString.length > 0 ? `?${queryString}` : ''}`;
  const response = await apiFetch(url);
  const responseData = await response.json() as IApiQueryListResponse<IPartners>;
  dispatch(lookups.partnersReceived(responseData.data)); // TODO: Error handling?
};

export const loadServicesLookup = async ({
  includeInactive = false,
}: {
  includeInactive: boolean,
}) => {
  const queryString = `includeInactive=${includeInactive ? 'true' : 'false'}`;
  const url = `${REACT_APP_API_ROOT_URI}/lookups/services${queryString.length > 0 ? `?${queryString}` : ''}`;
  const response = await apiFetch(url);
  const responseData = await response.json() as IApiQueryListResponse<IServices>;
  dispatch(lookups.servicesReceived(responseData.data)); // TODO: Error handling?
};

export const loadBillingTypesLookup = async () => {
  const url = `${REACT_APP_API_ROOT_URI}/lookups/billing_types`;
  const response = await apiFetch(url);
  const responseData = await response.json() as IApiQueryListResponse<IBillingTypes>;
  dispatch(lookups.billingTypesReceived(responseData.data)); // TODO: Error handling?
};

export const loadSubscriptionsLookup = async (options: {
  clientId: number,
  /** First day of the month */
  month?: Date,
}) => {
  const { clientId, month } = options;
  const queryString = `?clientId=${clientId}${month ? `&month=${makeYmd(month, true)}` : ''}`;
  const url = `${REACT_APP_API_ROOT_URI}/lookups/subscriptions${queryString}`;
  const response = await apiFetch(url);
  const responseData = await response.json() as IApiQueryListResponse<ISubscriptions>;
  dispatch(lookups.subscriptionsReceived(responseData.data)); // TODO: Error handling?
};

export const loadChargesLookup = async (options: {
  subscriptionId: number,
}) => {
  const { subscriptionId } = options;
  const queryString = `?subscriptionId=${subscriptionId}`;
  const url = `${REACT_APP_API_ROOT_URI}/lookups/charges${queryString}`;
  const response = await apiFetch(url);
  const responseData = await response.json() as IApiQueryListResponse<Charge>;
  dispatch(lookups.chargesReceived(responseData.data)); // TODO: Error handling?
};

/**
 * sends a request to get all runtime groups
 */
export const loadRuntimeGroupsLookup = async (options?: {
  runType: string,
}) => {
  const runType = options?.runType ?? 'clients';
  const queryString = `?runType=${runType}`;
  const url = `${REACT_APP_API_ROOT_URI}/lookups/runtime_groups${queryString}`;
  const response = await typedApiFetch<IApiQueryListResponse<IClientRuntimeGroup>>(url);
  const responseData = await response.json();
  dispatch(lookups.runtimeGroupsReceived(responseData.data)); // TODO: Error handling?

};