import { Fragment, useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Tooltip,
  Typography, 
} from "@mui/material";
import ConstructionIcon from '@mui/icons-material/Construction';
import { GridExpandMoreIcon } from "@mui/x-data-grid";

import { HashOf } from "@jamesgmarks/utilities";

import { compactArray } from "@jamesgmarks/utilities";
import {
  EIgnoredDataIntegrityErrorState,
  EIgnoredDataIntegrityErrorType,
} from "../../../../interfaces/EIgnoredDataIntegrityError";
import { IDataIntegrityCheckInfo } from "../../../../redux/features/data-integrity-checks/interfaces";
import { SingleErrorContainer } from "./SingleErrorContainer";
import { useAppSelector } from "../../../../redux/hooks";
import { useAuth } from "../../../../customHooks/useAuth";
import { camelCaseToCapitalized } from "../../../../app-utils/helpers";

interface IDataIntegrityCheckContainerProps {
  dataIntegrityCheckData: IDataIntegrityCheckInfo;
  dataIntegrityCheckName: string;
  type: EIgnoredDataIntegrityErrorType;
}

/** Holds the errors and data for a single data integrity check (pre or post). */
const DataIntegrityCheckContainer = (
  {
    dataIntegrityCheckData: {
      errors,
      fixOptions,
    },
    dataIntegrityCheckName,
    type,
  }: IDataIntegrityCheckContainerProps,
) => (
  <Box>
    {
      errors
        .map((error, i) => (
          <Fragment key={error.objectId}>
            <SingleErrorContainer
              dataIntegrityCheckName={dataIntegrityCheckName}
              error={error}
              fixOptions={fixOptions}
              lastError={i === errors.length - 1}
              type={type}
            />
            <br />
          </Fragment>
        ))
    }
  </Box>
);

interface IDataIntegrityCheckAccordionProps {
  dataIntegrityCheckData: IDataIntegrityCheckInfo;
  dataIntegrityCheckDescription?: string;
  dataIntegrityCheckName: string;
  type: EIgnoredDataIntegrityErrorType;
}

export const DataIntegrityCheckAccordion = ({
  dataIntegrityCheckData,
  dataIntegrityCheckDescription,
  dataIntegrityCheckName,
  type,
}: IDataIntegrityCheckAccordionProps) => {
  const numDisplayableErrors = dataIntegrityCheckData.errors.length;
  
  const accordionLabelStyle = numDisplayableErrors > 0 ? { color: '#9c27b0' } : { color: 'inherit' };

  const { hasPermission, isDeveloper } = useAuth();

  const canInteractWithDataIntegrityError = (
    !!(
      isDeveloper
      || (
        (hasPermission('FIX_DATA_INTEGRITY_ERROR') && dataIntegrityCheckData.fixOptions)
        || hasPermission('IGNORE_DATA_INTEGRITY_ERROR')
      )
    )
  );

  const [ expanded, setExpanded ] = useState(false);

  return (
    <Accordion
      disabled={numDisplayableErrors === 0 || !canInteractWithDataIntegrityError}
      expanded={expanded && numDisplayableErrors > 0 && canInteractWithDataIntegrityError}
      key={dataIntegrityCheckName}
      onChange={(_e) => setExpanded((old) => !old)}
    >
      <Tooltip
        title={
          dataIntegrityCheckDescription
            ? <div style={{ whiteSpace: 'pre-line' }}>{dataIntegrityCheckDescription}</div>
            : ''
        }
        placement='top-start'
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          expandIcon={<GridExpandMoreIcon />}
          id="panel1a-header"
        >
          <>
            <Typography variant="h6" gutterBottom sx={accordionLabelStyle}>
              {camelCaseToCapitalized(dataIntegrityCheckName)}
              <span>
                {' '}({numDisplayableErrors})
              </span>
            </Typography>
            {
              dataIntegrityCheckData.fixOptions
                ? (
                  <Tooltip placement='right' title="This error can be fixed">
                    <ConstructionIcon sx={{ ml: 2.5, fontSize: '2.25rem' }} />
                  </Tooltip>
                )
                : <></>
            }
          </>
        </AccordionSummary>
      </Tooltip>

      <AccordionDetails>
        <DataIntegrityCheckContainer
          dataIntegrityCheckData={dataIntegrityCheckData}
          dataIntegrityCheckName={dataIntegrityCheckName}
          type={type}
        />
      </AccordionDetails>
    </Accordion>
  );
};

interface IDataIntegrityCheckAccordionsProps {
  errorData: HashOf<IDataIntegrityCheckInfo>;
  showIgnoredErrors: boolean;
  type: EIgnoredDataIntegrityErrorType;
}

/** Displays an `Accordion` component for each pre/postcheck in the passed `errorData` object. */
export const DataIntegrityCheckAccordions = (
  { errorData, showIgnoredErrors, type }: IDataIntegrityCheckAccordionsProps,
) => {
  const ignoredDataIntegrityErrors = useAppSelector(
    (state) => state.dataIntegrityChecks[
      type === EIgnoredDataIntegrityErrorType.precheck
        ? 'ignoredPrecheckErrors'
        : 'ignoredPostcheckErrors'
    ],
  );

  const fixedDataIntegrityErrors = useAppSelector(
    (state) => state.dataIntegrityChecks.fixedErrors,
  );

  const ignoredDataIntegrityErrorIds = compactArray(
    (ignoredDataIntegrityErrors ?? [])
      .map((i) => i.state === EIgnoredDataIntegrityErrorState.active ? i.problematicObjectId : null),
  );
  
  return (
    <Paper>
      {
        Object.keys(errorData)
          .map((dataIntegrityCheckName) => {
            const filteredErrors = errorData[dataIntegrityCheckName].errors
              .filter((e) => (
                !e.objectId
                || (!fixedDataIntegrityErrors
                  .find((f) => f.handlerName === dataIntegrityCheckName && f.objectId === e.objectId)
                  && (
                    showIgnoredErrors
                    || !ignoredDataIntegrityErrorIds.includes(e.objectId)
                  )
                )
              ));

            return (
              <DataIntegrityCheckAccordion
                dataIntegrityCheckData={{
                  ...errorData[dataIntegrityCheckName],
                  errors: filteredErrors,
                }}
                dataIntegrityCheckDescription={errorData[dataIntegrityCheckName].description}
                dataIntegrityCheckName={dataIntegrityCheckName}
                key={dataIntegrityCheckName}
                type={type}
              />
            );
          })
      }
    </Paper>
  );
};
