import { useEffect, useState } from "react";

import { Box, Button, Container, Grid } from "@mui/material";

import { intVal, Nullable, strVal } from "@jamesgmarks/utilities";
import { MonthEndLocks } from "@llws/typeorm-entities";

import { DataTable } from "../../parts/data-table/DataTable";
import { MonthLockModal } from "./MonthLockModal";
import { RequirePermission } from "../../parts/RequirePermission";
import { isValidMonth, isValidYear } from "../../../app-utils";
import { loadMonthLockList } from "../../../redux/features/reporting/actions";
import { useAppSelector } from "../../../redux/hooks";
import { HHMonthPicker } from "../../parts/HHMonthPicker";

const today = new Date();
const dateOneMonthPrior = new Date(today.getFullYear(), today.getMonth() - 1, 1);

export const MonthLock = () => {
  const lastMonthDate = new Date();
  lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);

  const lastMonthsYear = lastMonthDate.getFullYear();
  const lastMonth = lastMonthDate.getMonth() + 1;

  const [year, setYear] = useState(`${lastMonthsYear}`);
  const [month, setMonth] = useState(`${lastMonth}`);

  const [showModal, setShowModal] = useState(false);

  const [disableMonthLockButton, setDisableMonthLockButton] = useState(true);
  const monthLockList = useAppSelector(state => state.reporting.monthLockList);

  useEffect(() => {
    if (!monthLockList) loadMonthLockList();
  }, [monthLockList]);

  useEffect(() => {
    const disabled = 
      monthLockList?.find((ml) => ml.year === intVal(year) && ml.month === intVal(month))
        ? true
        : false
      || !isValidYear(year)
      || !isValidMonth(month);
    
    setDisableMonthLockButton(disabled);
  }, [year, month, monthLockList]);

  return (
    <Container>
      <MonthLockModal showModal={showModal}
        setShowModal={setShowModal}
        year={intVal(year)}
        month={intVal(month)}
      />

      <Box my={2} pt={3}>
        <RequirePermission grant="LOCK_MONTH">
          <Grid container spacing={1}>
            <Grid item xs={6} >
              <HHMonthPicker
                onChange={
                  (d: Nullable<Date>) => {
                    setYear(strVal(d ? d.getFullYear() : dateOneMonthPrior.getFullYear()));
                    setMonth(strVal((d ? d.getMonth() : dateOneMonthPrior.getMonth()) + 1));
                  }
                }
                value={new Date(intVal(year), intVal(month) - 1, 1)}
              />
            </Grid>

            <Grid item xs={12} >
              <Button
                variant="contained"
                color="primary"
                size="large"
                disabled={disableMonthLockButton}
                onClick={() => { setShowModal(true); }}
              >
                Lock Month
              </Button>
            </Grid>
          </Grid>
        </RequirePermission>
      </Box>

      {monthLockList && <DataTable data={monthLockList as MonthEndLocks[]} />}
    </Container>
  );
};
