import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from '@mui/material';

import { floatVal } from '@jamesgmarks/utilities';

import { ICreditNote } from '../../../interfaces/ICreditNote';
import { clearCurrentCreditNote, loadCreditNotes } from '../../../redux/features/credit-notes/actions';
import { StyledTableCell, StyledTableRow } from '../../parts/mui/StyledTables';
import { toDollarAmount } from '../../../app-utils';
import { useAppSelector } from '../../../redux/hooks';
import { useEffect, useState } from 'react';

interface CreditNoteListDataRowProps {
  creditNote: ICreditNote
}

const CreditNoteListDataRow = ({
  creditNote,
}: CreditNoteListDataRowProps) => {
  const [ viewButtonColor, setViewButtonColor ] = useState<'primary' | 'secondary' | 'info'>('primary');

  return (
    <StyledTableRow
      dark={true}
      hover={true}
      onMouseOver={(e: MouseEvent | TouchEvent) => setViewButtonColor('secondary')}
      onMouseLeave={(e: MouseEvent | TouchEvent) => setViewButtonColor('primary')}
    >
      <StyledTableCell dark={true}>{creditNote.id}</StyledTableCell>
      <StyledTableCell dark={true}>{creditNote.noteNumber}</StyledTableCell>
      <StyledTableCell dark={true}>{creditNote.state}</StyledTableCell>
      <StyledTableCell dark={true}>{creditNote.billingAccountId}</StyledTableCell>
      <StyledTableCell dark={true}>{toDollarAmount(floatVal(creditNote.subtotalAmount ?? 0))}</StyledTableCell>
      <StyledTableCell dark={true}>{creditNote.noteDate.slice(0, 10)}</StyledTableCell>{/* TODO: Handle this better as a date - was crashing app on Date format */}
      <StyledTableCell dark={true}>
        <Link to={`/credit_notes/${creditNote.id}`}>
          <Button
            size='small'
            color={viewButtonColor}
            variant='contained'
            onClick={() => clearCurrentCreditNote()}
          >
            View
          </Button>
        </Link>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export const CreditNoteListView = () => {
  const creditNotes = useAppSelector((state) => state.creditNotes.creditNotes ?? [] as ICreditNote[]);
  const loadedState = useAppSelector((state) => state.creditNotes.loadedState);

  useEffect(() => {
    if (creditNotes.length === 0 && loadedState === 'idle') {
      loadCreditNotes({});
    }
  }, [ creditNotes, loadedState ]);

  return (
    <>
      <Box px={10} pb={5}>
        <Link to={`/credit_notes/add_credit`}>
          <Button variant='contained' sx={{ mb: 2 }} onClick={() => clearCurrentCreditNote()}>Add Credit Note</Button>
        </Link>
        <TableContainer elevation={16} component={Paper}>
          <Table>
            <TableHead>
              <StyledTableRow dark={true}>
                <StyledTableCell dark={true}>ID</StyledTableCell>
                <StyledTableCell dark={true}>Note Number</StyledTableCell>
                <StyledTableCell dark={true}>State</StyledTableCell>
                <StyledTableCell dark={true}>Billing Account ID</StyledTableCell>
                <StyledTableCell dark={true}>Subtotal</StyledTableCell>
                <StyledTableCell dark={true}>Note Date</StyledTableCell>
                <StyledTableCell dark={true}>&nbsp;</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {
                creditNotes
                  .slice()
                  .sort((a, b) => (a.id ?? 0) < (b.id ?? 0) ? 1 : -1 )
                  .map(creditNote => (
                    <CreditNoteListDataRow key={creditNote.id} creditNote={creditNote} />
                  ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>);
};
