import React from 'react';
import PropTypes from 'prop-types';

import { intersect } from '@jamesgmarks/utilities';

import { EUserAccessLevel } from '../../../../entities/hydra/UserUiAccess';
import { useAuth } from '../../customHooks/useAuth';

export interface RequireAccessProps {
  to: string,
  levels?: EUserAccessLevel[],
  noAccessContent?: React.ReactNode,
  children: React.ReactChild,
}

export const checkRouteAccessForUser = (
  accessValidator: (to: string, accessLevel: "any" | "readonly" | "readwrite") => boolean,
  accessTo: string,
  levels?: EUserAccessLevel[],
) => {
  const accessLevel = (
      (!levels || levels.length === 0)
        ? 'any'
        : intersect((levels ?? []), ['readonly', 'readwrite']).length === 2 ? 'any' : levels[0]
    ) as 'any' | 'readonly' | 'readwrite';

  return accessValidator(accessTo, accessLevel);
};

export const RequireAccess: React.FC<RequireAccessProps> = ({
  to: accessTo,
  levels,
  noAccessContent,
  children,
}) => (
    checkRouteAccessForUser(
      useAuth().hasAccess,
      accessTo,
      levels,
    )
      ? children
      : noAccessContent ?? null
  ) as React.ReactElement;

RequireAccess.propTypes = {
  children: PropTypes.element.isRequired,
};
