import { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import { Button, Table, TextField, Grid } from "@mui/material";

import { floatVal, intVal, Nullable } from "@jamesgmarks/utilities";
import { Credits } from "@llws/typeorm-entities";

import '../MonthlySubscriptionBreakdown.css';

import { ClientDropdown } from "../../../../../parts/ClientDropdown";
import { Credit } from "../../../../../../../../entities/hydra";
import { DownloadCSV } from "../../../../../parts/DownloadCSV";
import { ECreditAppliedAs } from "../../../../../../interfaces/ICreditApplied";
import { ECreditTypes } from "../../../../../../interfaces/ICredit";
import { EHydraCreditMethod } from "../../../../../../interfaces/EHydraCreditMethod";
import { HashOf } from "@jamesgmarks/utilities";
import { ICreditBreakdown } from "../../../../../../redux/features/reporting/IMonthlySummaryData";
import { Paper, TableBody, TableContainer, TableFooter, TableHead } from "@mui/material";
import { RequirePermission } from "../../../../../parts/RequirePermission";
import { StyledTableCell, StyledTableRow } from "../../../../../parts/mui/StyledTables";
import { toDollarAmount } from "../../../../../../app-utils";

const isHydraCredit = (credit: Credit | Credits): credit is Credit => 
  (credit as Credit).hydraState !== undefined;
    
const getHydraCreditType = (credit: Credit) => {
  const standaloneCreditAppliedTypesMap = {
    [ECreditAppliedAs.payment]: ECreditTypes.payment,
    [ECreditAppliedAs.reduction]: ECreditTypes.reduction,
  };
      
  return credit.creditMethod === EHydraCreditMethod.creditNote
    ? ECreditTypes.note
    : standaloneCreditAppliedTypesMap[credit.creditsApplied[0].appliedAs];
};
 
const getLegacyCreditLabel = (credit: Credits) => (
  `Credit${credit?.partner?.name?`: ${credit?.partner?.name}`: ''} #${credit.creditNumber}`
);
    
const getHydraCreditLabel = (credit: Credit) => 
  `${getHydraCreditType(credit)}\
    ${credit?.partner?.partnerName ? `: ${credit?.partner?.partnerName}` : ''} #${credit.creditNumber}`;

interface CreditBreakdownTableRowProps {
  credit: Credit | Credits
  currencyCode: string
}

const CreditBreakdownTableRow = ({ credit, currencyCode }: CreditBreakdownTableRowProps) => {
  const [ linkColor, setLinkColor ] = useState('#007bff');
  
  const [ selected, setSelected ] = useState(false);
  const textColorCss = selected ? { color: 'dodgerblue' } : {};

  const invoices = isHydraCredit(credit)
    ? credit.creditsApplied
      .map((ca) => ({ id: ca.invoice.id, number: ca.invoice.invoiceNumber }))
    : credit.creditsHasInvoices
      .map((chi) => ({ id: chi.invoiceId, number: chi.invoice.invoiceNumber}));

  const creditNumber = credit.creditNumber;
  const label = isHydraCredit(credit) ? getHydraCreditLabel(credit) : getLegacyCreditLabel(credit);
  const description = isHydraCredit(credit) ? credit.description : credit.description;
  const clientName = isHydraCredit(credit) ? credit.client.clientName : credit.client.name;
  const partnerClawback = floatVal(credit.partnerClawback ?? '0');
  const subtotal = floatVal(credit.amount);
  const lwsClawback = subtotal - partnerClawback;
  const taxRate = floatVal(credit.taxRate);
  const taxTotal = taxRate * subtotal;
  const total = subtotal + taxTotal;

  return (
    <StyledTableRow
      onClick={() => setSelected((old) => !old)}
      onMouseEnter={() => setLinkColor('white')}
      onMouseLeave={() => setLinkColor('#007bff')}
      customSx={ selected
        ? {
          borderTop: '3px solid dodgerblue',
          borderBottom: '3px solid dodgerblue',
        } 
        : {}
      }
      dark={true}
      hover={true}
    >
      <StyledTableCell dark={true}>
        <ul
          style={
            {
              listStyleType: 'none',
              margin: '0px',
              padding: '0px',
            }
          }
        >
          {
            invoices.map(({ id, number }, i) => (
              <li
                style={
                  {
                    listStyleType: 'none',
                    margin: '0px',
                    padding: '0px',
                  }
                }
                key={i}
              >
                <Link
                  to={`/invoices/${id}`}
                  target="_blank"
                  style={{ color: linkColor }}
                >
                  {number}
                </Link>
              </li>
            ))
          }
        </ul>
      </StyledTableCell>

      <StyledTableCell style={{ ...textColorCss }} dark={true}>{creditNumber}</StyledTableCell>

      <StyledTableCell style={{ ...textColorCss }} dark={true}>
        <b>H: </b>
        <span style={{color: 'darkgray' }}>{label.split(':')[0]}</span>: {label.split(':')[1]}
      </StyledTableCell>

      <StyledTableCell style={{ ...textColorCss }} dark={true}>{description}</StyledTableCell>

      <StyledTableCell style={{ ...textColorCss }} dark={true}>{clientName}</StyledTableCell>

      <StyledTableCell style={{ ...textColorCss }} dark={true}>
        {toDollarAmount(-1 * partnerClawback, 'brackets')}
      </StyledTableCell>

      <StyledTableCell style={{ ...textColorCss }} dark={true}>
        {toDollarAmount(-1 * lwsClawback, 'brackets')}
      </StyledTableCell>

      <StyledTableCell style={{ ...textColorCss }} dark={true}>
        {toDollarAmount(-1 * subtotal, 'brackets')}
      </StyledTableCell>

      <StyledTableCell style={{ ...textColorCss }} dark={true}>
        {toDollarAmount(-1 * taxTotal, 'brackets')}
      </StyledTableCell>

      <StyledTableCell style={{ ...textColorCss }} dark={true}>
        {toDollarAmount(-1 * total, 'brackets')}
      </StyledTableCell>

      <StyledTableCell style={{ ...textColorCss }} dark={true}>
        {isHydraCredit(credit) ? credit.currencyCode : currencyCode}
      </StyledTableCell>
      
      <StyledTableCell style={{ ...textColorCss }} dark={true}>
        {isHydraCredit(credit) && credit.creditNote?.noteNumber}
      </StyledTableCell>
    </StyledTableRow>
  );
};

interface CreditsBreakdownTableProps {
  creditBreakdown: ICreditBreakdown
  currencyCode: string
}

export const CreditsBreakdownTable = ({
  creditBreakdown: { legacyCredits, hydraCredits },
  currencyCode,
}: CreditsBreakdownTableProps) => {  
  const [ filteredLegacyCredits, setFilteredLegacyCredits ] = useState(legacyCredits);
  const [ filteredHydraCredits, setFilteredHydraCredits ] = useState(hydraCredits);
  
  const [sortCriteria, setSortCriteria] = useState(['clientName', 'asc']);
  
  const [ totals, setTotals ] = useState({
    partnerRevenue: 0,
    lwsRevenue: 0,
    subTotal: 0,
    taxes: 0,
    total: 0,
  });
  
  const [ searchClientId, setSearchClientId ] = useState(null as Nullable<number>);

  useEffect(() => {
    const newTotals = [ ...filteredLegacyCredits, ...filteredHydraCredits ].reduce((acc, cur) => {
      const subtotal = floatVal(cur.amount);
      const partnerClawback = floatVal(cur.partnerClawback ?? '0');
      const taxRate = floatVal(cur.taxRate);
      const taxTotal = taxRate * subtotal;

      return {
        partnerRevenue: (acc.partnerRevenue ?? 0) - partnerClawback,
        lwsRevenue: acc.lwsRevenue - (subtotal - partnerClawback),
        subTotal: acc.subTotal - subtotal,
        taxes: acc.taxes - taxTotal,
        total: acc.total - subtotal - taxTotal,
      };
    }, {
      partnerRevenue: 0,
      lwsRevenue: 0,
      subTotal: 0,
      taxes: 0,
      total: 0,
    });

    setTotals(newTotals);
  }, [ filteredLegacyCredits, filteredHydraCredits ]);

  const getCreditLabelForSort = (credit: Credit | Credits) =>
    (isHydraCredit(credit) ? getHydraCreditLabel(credit) : getLegacyCreditLabel(credit)).split(': ')[1].toLowerCase();

  const getClientNameForSort = (credit: Credit | Credits) =>
    (isHydraCredit(credit) ? credit.client.clientName : credit.client.name).toLowerCase();

  const getLwsShareForSort = (credit: Credit | Credits) => isHydraCredit(credit)
    ? floatVal(credit.amount) - floatVal(credit.partnerClawback ?? '0')
    : floatVal(credit.amount) - floatVal(credit.partnerClawback ?? '0');

  const getTaxTotalForSort = (credit: Credit | Credits) => floatVal(credit.taxRate) * floatVal(credit.amount);

  const getTotalForSort = (credit: Credit | Credits) => floatVal(credit.amount) + getTaxTotalForSort(credit);

  const sortFns = {
    'creditNumber': (a, b) => a.creditNumber > b.creditNumber ? 1 : -1,
    'itemName': (a, b) => (getCreditLabelForSort(a) > getCreditLabelForSort(b)) ? 1 : -1,
    'clientName': (a, b) => (getClientNameForSort(a) > getClientNameForSort(b)) ? 1 : -1,
    'partnerShare': (a, b) => floatVal(a.partnerClawback ?? '0') > floatVal(b.partnerClawback ?? '0') ? 1 : -1,
    'lwsShare': (a, b) => (getLwsShareForSort(a) > getLwsShareForSort(b) ? 1 : -1),
    'subtotal': (a, b) => (floatVal(a.amount) > floatVal(b.amount) ? 1 : -1),
    'taxTotal': (a, b) => (getTaxTotalForSort(a) > getTaxTotalForSort(b) ? 1 : -1),
    'total': (a, b) => (getTotalForSort(a) > getTotalForSort(b) ? 1 : -1),
    'noteNumber': (a, b) => (
      isHydraCredit(a) && isHydraCredit(b))
      ? (a.creditNote?.noteNumber > b.creditNote?.noteNumber ? 1 : -1)
      : 0,
  } as HashOf<(a: Credit | Credits, b: Credit | Credits) => (1 | -1)>;

  const sortBy = (criteria: string) => {
    setSortCriteria([criteria, (sortCriteria[0] === criteria && sortCriteria[1] === 'asc') ? 'desc' : 'asc']);
  };

  const getSortIcon = (criteria: string) => {
    return sortCriteria[0] === criteria ? (sortCriteria[1] === 'asc' ? <>&#x25b2;</> : <>&#x25bc;</>) : null;
  };

  const doSort = (a: Credit | Credits, b: Credit | Credits) => {
    return (
      sortFns[sortCriteria[0]](a, b) * (sortCriteria[1] === 'asc' ? 1 : -1)
    );
  };

  const initialFilters = {
    invoiceNumber: '',
    creditNumber: '',
    itemName: '',
    clientName: '',
  };

  const [ filters, setFilters ] = useState(initialFilters);

  const updateFilters = (filterName: string, value: string) => {
    setFilters((oldFilters) => ({
      ...oldFilters,
      [filterName]: value,
    }));
  };

  const applyFilters = () => {
    setFilteredLegacyCredits(
      legacyCredits.filter(creditItem => {
        return (
          !filters.clientName
          || creditItem.client.name.toLowerCase().includes(filters.clientName.toLowerCase())
        )
      
        && (
          !filters.itemName
          || getLegacyCreditLabel(creditItem).toLowerCase().includes(filters.itemName.toLowerCase())
        )

        && (!filters.invoiceNumber || creditItem.creditsHasInvoices.filter(
          (chi) => chi.invoice.invoiceNumber.includes(filters.invoiceNumber),
        ).length > 0)

        && (!filters.creditNumber || creditItem.creditNumber.includes(filters.creditNumber));
      }),
    );

    setFilteredHydraCredits(
      hydraCredits
        .filter(creditItem => {
          return (
            !filters.clientName
            || creditItem.client.clientName.toLowerCase().includes(filters.clientName.toLowerCase())
          )

          && (
            !filters.itemName
            || getHydraCreditLabel(creditItem).toLowerCase().includes(filters.itemName.toLowerCase())
          )

          && (
            creditItem.creditNoteId // Credit note credits generate `Payment`s, not `CreditApplied` records
            || !filters.invoiceNumber
            || creditItem.creditsApplied
              .filter((ca) => ca.invoice.invoiceNumber.includes(filters.invoiceNumber)).length > 0
          )

          && (!filters.creditNumber || creditItem.creditNumber.includes(filters.creditNumber));
        }),
    );
  };

  return (
    <>
      <Grid container mt={5}>
        <Grid item textAlign='left' xs={12}>
          <TextField
            label='Invoice Number'
            value={filters.invoiceNumber}
            onChange={
              (e) => updateFilters('invoiceNumber', e.target.value.trim())
            }
            size='small'
            sx={{ width: '25%' }}
            variant='outlined'
          />
        </Grid>

        <Grid item mt={1.5} textAlign='left' xs={12}>
          <TextField
            label='Credit Number'
            value={filters.creditNumber}
            onChange={
              (e) => updateFilters('creditNumber', e.target.value.trim())
            }
            size='small'
            sx={{ width: '25%' }}
            variant='outlined'
          />
        </Grid>

        <Grid item mt={1.5} textAlign='left' xs={12}>
          <TextField
            label='Item'
            value={filters.itemName}
            onChange={
              (e) => updateFilters('itemName', e.target.value.trim())
            }
            size='small'
            sx={{ width: '50%' }}
            variant='outlined'
          />
        </Grid>

        <Grid item mt={1.5} xs={6}>
          <ClientDropdown
            allowFreeform
            clientId={searchClientId}
            onClientChanged={(client) => {
              setSearchClientId(client ? intVal(client.id) : null);
            }}
            onClientFreeForm={(clientString) => {
              clientString !== null && updateFilters('clientName', clientString);
            }}
          />
        </Grid>

        <Grid item textAlign='left' mt={1} xs={12}>
          <Button
            onClick={applyFilters}
            size='small'
            variant='contained'
          >
              Filter
          </Button>
          <Button
            sx={{ ml: 2 }}
            color='info'
            onClick={() => {
              setFilters(initialFilters);
              setFilteredHydraCredits(hydraCredits);
              setFilteredLegacyCredits(legacyCredits);
            }}
            size='small'
            variant='contained'
          >
              Clear
          </Button>
        </Grid>
      </Grid>

      <Grid textAlign='left' sx={{ color: 'gray', position: 'relative', top: 45, left: 8 }}>
        <small>Click any row to toggle selection/highlight...</small>
      </Grid>
      <DownloadCSV filename={`credits-breakdown`}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <StyledTableRow dark={true}>
                <StyledTableCell dark={true}>Invoice(s)</StyledTableCell>

                <StyledTableCell onClick={() => sortBy('creditNumber')} dark={true} className='sortable-header'>
                  Credit # {getSortIcon('creditNumber')}
                </StyledTableCell>

                <StyledTableCell onClick={() => sortBy('itemName')} dark={true} className="sortable-header">
                  Item {getSortIcon('itemName')}
                </StyledTableCell>
                
                <StyledTableCell dark={true}>
                  Description
                </StyledTableCell>
                
                <StyledTableCell onClick={() => sortBy('clientName')} dark={true} className="sortable-header">
                  Client {getSortIcon('clientName')}
                </StyledTableCell>

                <StyledTableCell onClick={() => sortBy('partnerShare')} dark={true} className="sortable-header">
                  Partner Share {getSortIcon('partnerShare')}
                </StyledTableCell>

                <StyledTableCell onClick={() => sortBy('lwsShare')} dark={true} className="sortable-header">
                  LWS Share {getSortIcon('lwsShare')}
                </StyledTableCell>

                <StyledTableCell onClick={() => sortBy('subtotal')} dark={true} className="sortable-header">
                  Subtotal {getSortIcon('subtotal')}
                </StyledTableCell>

                <StyledTableCell onClick={() => sortBy('taxTotal')} dark={true} className="sortable-header">
                  Tax Total {getSortIcon('taxTotal')}
                </StyledTableCell>

                <StyledTableCell onClick={() => sortBy('total')} dark={true} className="sortable-header">
                  Total {getSortIcon('total')}
                </StyledTableCell>
                
                <StyledTableCell dark={true}>Currency </StyledTableCell>
                <StyledTableCell onClick={() => sortBy('noteNumber')} dark={true} className='sortable-header'>
                  Credit Note # {getSortIcon('noteNumber')}
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {
                [ ...filteredLegacyCredits, ...filteredHydraCredits ]
                  .sort((a, b) => doSort(a, b))
                  .map((credit, i) => 
                    <CreditBreakdownTableRow credit={credit} currencyCode={currencyCode} key={i} />)
              }

            </TableBody>
            <RequirePermission grant="FULL_ACCESS">
              <TableFooter>
                <StyledTableRow dark={true}>
                  <StyledTableCell dark={true}></StyledTableCell>
                  <StyledTableCell dark={true}>Totals</StyledTableCell>
                  <StyledTableCell dark={true}></StyledTableCell>
                  <StyledTableCell dark={true}></StyledTableCell>
                  <StyledTableCell dark={true}></StyledTableCell>
                  <StyledTableCell dark={true}>
                    {`${toDollarAmount(totals.partnerRevenue, 'brackets')}`}
                  </StyledTableCell>
                  <StyledTableCell dark={true}>{`${toDollarAmount(totals.lwsRevenue, 'brackets')}`}</StyledTableCell>
                  <StyledTableCell dark={true}>{`${toDollarAmount(totals.subTotal, 'brackets')}`}</StyledTableCell>
                  <StyledTableCell dark={true}>{`${toDollarAmount(totals.taxes, 'brackets')}`}</StyledTableCell>
                  <StyledTableCell dark={true}>{`${toDollarAmount(totals.total, 'brackets')}`}</StyledTableCell>
                  <StyledTableCell dark={true}></StyledTableCell>
                  <StyledTableCell dark={true}></StyledTableCell>
                </StyledTableRow>
              </TableFooter>
            </RequirePermission>
          </Table>
        </TableContainer>
      </DownloadCSV>
    </>
  );
};
