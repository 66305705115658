import { useState } from "react";
import {
  Button, Grid,
} from "@mui/material";
import { DatePickerField } from "src/components/parts/runner-form/DatePickerField";
import { makeYmd } from "src/app-utils";
import { triggerActionWithResponse } from "src/redux/features/finance-control-tools/actions";
export const TriggerXeroInvoiceSync = () => {

  const today = new Date();
  const lastWeek = new Date();
  lastWeek.setDate(today.getDate()-14);

  const [modifiedDateYMD, setModifiedDateYMD] = useState<string>(makeYmd(lastWeek));

  return (
    <>
      <h3>Xero Invoice Sync</h3>
      <p>Synchronize all invoices on xero that have been modified since a date</p>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <DatePickerField
            label={'Date'}
            value={modifiedDateYMD}
            options={['day', 'month', 'year']}
            onChange={(newValue) => {
              if (newValue){
                setModifiedDateYMD(makeYmd(newValue));
              }
            }}
          />
        </Grid>

        <Grid item xs={8}>
          <Button
            variant="contained"
            onClick={(e) => {
              triggerActionWithResponse('dispatchAction', {
                actionName: 'xeroInvoiceSync',
                parameters: {
                  date: modifiedDateYMD,
                },
              });
            }}
          > Trigger Xero Invoice Sync</Button>
        </Grid>
      </Grid>
    </>
  );
};
