import { Hash, Nullable } from '@jamesgmarks/utilities';
import { Link } from 'react-router-dom';
import { Container, Form, Button, Col, Card } from 'react-bootstrap';
import React, { useState } from 'react';
import { login } from '../../redux/features/auth/actions';
import { RequireAccess } from '../parts/RequireAccess';

const userNavMap = [
  { label: 'Mobile', toLink: '/mobile'},
  { label: 'Clients', toLink: '/clients' },
  { label: 'Commissions', toLink: '/commissions/commissions_tabs' },
  { label: 'Fees', toLink: '/fees' },
  { label: 'Invoices', toLink: '/invoices' },
  { label: 'Reporting', toLink: '/reporting'},
];

export const LoginForm = ({isLoggedIn}: Hash) => {
  const [ email, setEmail ] = useState<Nullable<string>>(null);
  const [ password, setPassword ] = useState<Nullable<string>>(null);

  return (
    <div>
      <Container>
        <h1>{isLoggedIn ? 'Welcome!' : 'Login'}</h1>
        {!isLoggedIn &&
          <>
            <Form
              onSubmit={(e) => {
                login({ email: email ?? '', password: password ?? '' });
                setPassword('');
                e.preventDefault();
              }}
            >
              <Form.Row>
                <Form.Group as={Col} controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Email"
                    value={email ?? ''}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={password ?? ''}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
              </Form.Row>
              <Button variant="primary" type="submit">
                Log In
              </Button>
            </Form>
          </>
        }
        {isLoggedIn && (
          <div>
            <p>You are logged in.</p>
            <ul>
              {
                userNavMap
                  && (
                    userNavMap.map((un, i) => (
                      <RequireAccess to={un.toLink} key={i}>
                        <li>
                          <Link to={un.toLink}>
                            <div style={{ padding: '15px', margin: '5px', backgroundColor: 'rgba(233, 233, 233, 1)' }}>
                              {un.label}
                            </div>
                          </Link>
                        </li>
                      </RequireAccess>
                    ))
                  )
              }
            </ul>
          </div>
        )
        }
      </Container>
    </div>
  );
};