import { useEffect, useState } from "react";

import {
  AppBar,
  Box,
  Container,
  Divider,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import { keys } from "@jamesgmarks/utilities";

import { DataIntegrityCheckAccordions } from "./DataIntegrityCheckAccordions";
import { DataIntegrityChecksForm } from "./DataIntegrityChecksForm";
import {
  EDataIntegrityChecksLoadedState,
} from "../../../../redux/features/data-integrity-checks/dataIntegrityChecksSlice";
import { EIgnoredDataIntegrityErrorType } from "../../../../interfaces/EIgnoredDataIntegrityError";
import { getPreAndPostCheckNames } from "../../../../redux/features/data-integrity-checks/actions";
import { LoadingSpinner } from "../../../parts/LoadingSpinner";
import { useAppSelector } from "../../../../redux/hooks";

interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: ITabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index &&
        <Box p={3}>
          {children}
        </Box>
      }
    </div>
  );
};

export const DataIntegrityErrors = () => {
  const [value, setValue] = useState(0);

  const [ showIgnoredErrors, setShowIgnoredErrors ] = useState(false);
  const [ checksAsJob, setChecksAsJob ] = useState(false);
  
  const handleChange = (_e: React.ChangeEvent<{}>, newValue: number) => setValue(newValue);
  
  const precheckNames = useAppSelector(state => state.dataIntegrityChecks.precheckNames);
  const postcheckNames = useAppSelector(state => state.dataIntegrityChecks.postcheckNames);

  const precheckErrors = useAppSelector(state => state.dataIntegrityChecks.precheckErrors);
  const postcheckErrors = useAppSelector(state => state.dataIntegrityChecks.postcheckErrors);

  const precheckLoadState = useAppSelector(state => state.dataIntegrityChecks.precheckLoadState);
  const postcheckLoadState = useAppSelector(state => state.dataIntegrityChecks.postcheckLoadState);
  
  useEffect(() => {
    const getNames = async () => {
      await getPreAndPostCheckNames();
    };

    getNames();
  }, []);

  return (
    <Container
      maxWidth='lg'
      sx={{
        backgroundColor: '#f6f6f6',
        borderRadius: '35px',
        height: '100%',
        my: '2rem',
      }}
    >
      <br />
      <h3 style={{ marginLeft: '1.5rem' }}>Data Integrity Checks</h3>
      <Divider />

      <DataIntegrityChecksForm
        postcheckNames={postcheckNames}
        precheckNames={precheckNames}
        setPostcheckTabActive={ () => setValue(1) }
        setPrecheckTabActive={ () => setValue(0) }
        setShowIgnoredErrors={() => setShowIgnoredErrors((old) => !old)}
        setChecksAsJob={() => setChecksAsJob((old) => !old)}
        checksAsJob={checksAsJob}
        showIgnoredErrors={showIgnoredErrors}
      />
      
      <AppBar sx={{ mt: 6, p: 1 }} color='inherit' position="static">
        <Tabs value={value} component='div' onChange={handleChange} aria-label="simple tabs example">
          <Tab sx={{ margin: 'auto', width: '50%' }} label="Precheck Errors" />
          <Tab sx={{ margin: 'auto', width: '50%' }} label="Postcheck Errors" />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        {
          precheckLoadState === EDataIntegrityChecksLoadedState.loading
            ?
            <LoadingSpinner loadedState={precheckLoadState} />
            :
            keys(precheckErrors).length === 0
              ?
              <Paper
                elevation={3}
                sx={{
                  borderRadius: '10px',
                  margin: ' 2rem auto',
                  p: '1rem',
                  textAlign: 'center',
                  width: '50%',
                }}
              >
                <Typography>Not yet loaded.</Typography>
              </Paper>
              :
              <DataIntegrityCheckAccordions
                errorData={precheckErrors}
                showIgnoredErrors={showIgnoredErrors}
                type={EIgnoredDataIntegrityErrorType.precheck}
              />
        }
      </TabPanel>

      <TabPanel value={value} index={1}>
        {
          postcheckLoadState === EDataIntegrityChecksLoadedState.loading
            ?
            <LoadingSpinner loadedState={postcheckLoadState} />
            : keys(postcheckErrors).length === 0
              ?
              <Paper
                elevation={3}
                sx={{
                  borderRadius: '10px',
                  margin: ' 2rem auto',
                  p: '1rem',
                  textAlign: 'center',
                  width: '50%',
                }}
              >
                <Typography>Not yet loaded.</Typography>
              </Paper>
              :
              <DataIntegrityCheckAccordions
                errorData={postcheckErrors}
                showIgnoredErrors={showIgnoredErrors}
                type={EIgnoredDataIntegrityErrorType.postcheck}
              />
        }
      </TabPanel>
    </Container>
  );
};
