import { useState } from "react";

import {
  Button, Col, Form, FormControl, Modal, Row, Table,
} from "react-bootstrap";
import DatePicker from "react-datepicker";

import { distinct, floatVal, Nullable } from "@jamesgmarks/utilities";

import { IAddCreditModalSubmitData } from "../../../interfaces/IAddCreditModalSubmitData";
import { ILineWithUsageItems } from "../../../redux/features/invoices/interfaces";
import { Invoice } from "../../../../../entities/hydra";
import { loadMonthLockData } from "../../../redux/features/invoices/actions";
import { useAppSelector } from "../../../redux/hooks";

export const AddCreditModal = ({
  generatedSubscriptionId,
  show,
  onHide,
  invoice,
  line,
  onSubmit,
}: {
  generatedSubscriptionId?: number,
  show: boolean,
  onHide?: (() => void),
  invoice: Nullable<Invoice>,
  line: Nullable<ILineWithUsageItems>,
  onSubmit: (submitData: IAddCreditModalSubmitData) => void,
}) => {
  const hasPartner = (
    (line?.usageItems ?? [])
      .reduce((acc, ui) => [...acc, ...(ui.partnerIds ?? [])], [] as number[])
      .length > 0
  );
  const hasCommissions = ((line?.commissions ?? []).length > 0);
  const defaultDescription = `Credit for ${line?.line.description}`;
  const maxCreditAmount = (line?.line?.qty ?? 0) * (line?.line?.unitCost?.amount ?? 0);
  const invoiceDate = new Date(invoice?.invoiceDate ?? '');

  const monthLocks = useAppSelector(state => state.invoices.monthLocks);

  const [creditDescription, setCreditDescription] = useState(defaultDescription);
  const [creditAmount, setCreditAmount] = useState(maxCreditAmount);
  const [creditDate, setCreditDate] = useState(new Date());
  const [clawbackFromPartner, setClawbackFromPartner] = useState(hasPartner);
  const [applyImmediately, setApplyImmediately] = useState(false);

  const invoiceYear = invoiceDate.getFullYear();
  const invoiceMonth = invoiceDate.getMonth() + 1;
  const monthLockForMonth = invoice ? monthLocks[`${invoiceYear}-${invoiceMonth}`] ?? null : null;
  if (invoice && invoiceYear && invoiceMonth && monthLockForMonth === null) {
    loadMonthLockData(invoiceYear, invoiceMonth);
  }
  const isMonthLocked = monthLockForMonth;
  // console.log({monthLocks, key: `${invoiceYear}-${invoiceMonth}`, isMonthLocked, monthLockForMonth})

  const subscriptionIds = distinct(line?.usageItems.map(ui => ui.subscriptionId) ?? []);

  const linePercentageAmount = creditAmount / maxCreditAmount;

  return (
    <Modal
      centered
      dialogClassName="modal-60w"
      backdrop="static"
      animation={false}
      show={!!line && !!show}
      onClose={() => onHide?.()}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Add Credit
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm="3">
            Subscription ID(s):
          </Col>
          <Col sm="9">
            {subscriptionIds.join(', ')}
          </Col>
        </Row>
        <Row><Col>
          <Form.Label>
            Credit Description:
          </Form.Label>
        </Col></Row>
        <Row>
          <Col sm="12">
            <FormControl
              as="textarea"
              value={creditDescription}
              onChange={(e) => setCreditDescription(e.target.value)}
            />
          </Col>
        </Row>
        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Credit Amount ($):
          </Form.Label>
          <Col sm="9">
            <FormControl
              as="input"
              type="number"
              value={floatVal(creditAmount)}
              onChange={(e) => setCreditAmount(
                Number.isNaN(floatVal(e.target.value))
                  ? 0
                  : Math.max(0, Math.min(floatVal(e.target.value), maxCreditAmount)),
              )}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Tax @ {floatVal(invoice?.taxRate ?? '0') * 100}%:
          </Form.Label>
          <Col sm="9">
            {floatVal(creditAmount) * floatVal(invoice?.taxRate ?? '0')}
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Credit Date:
          </Form.Label>
          <Col sm="9">
            <DatePicker
              dateFormat='yyyy-MM-dd'
              selected={creditDate}
              onChange={(date) => date && !Array.isArray(date) ? setCreditDate(date) : null}
            />
          </Col>
        </Form.Group>
        {hasCommissions &&
          <Form.Group as={Row}>
            <Col sm="3">
              Clawbacks
            </Col>
            <Col sm="9">
              <Table size="sm">
                <thead>
                  <tr>
                    <th>Sales Rep</th>
                    <th>Rate</th>
                    <th>Total Commission</th>
                    <th>Clawback Amt.</th>
                  </tr>
                </thead>
                <tbody>
                  {(line?.commissions ?? []).map(c => (
                    <tr key={c.salesRepId}>
                      <td>{c.salesRepId}</td>
                      <td>{c.commissionRate}</td>
                      <td>{c.commissionAmount}</td>
                      <td>{c.commissionAmount * linePercentageAmount}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <pre>
              </pre>
            </Col>
          </Form.Group>
        }
        {
          hasPartner
          && <>
            {/* TODO: (ISSUE:#159)
          <Form.Group as={Row}>
            <Col sm="3">
              Partner Clawback Amount:
            </Col>
            <Col sm="9">

            </Col>
          </Form.Group> */}
            <Form.Group as={Row}>
              <Col sm="12">
                <Form.Check
                  id={`add-credit-modal-partner-clawback-check`}
                  checked={clawbackFromPartner}
                  onChange={(e) => setClawbackFromPartner(e.target.checked)}
                  label={`Clawback from partner`}
                />
              </Col>
            </Form.Group>
          </>
        }
      </Modal.Body>
      <Modal.Footer>
        {
          ['draft', 'sent', 'viewed'].includes(invoice?.freshbooksState ?? '')
          && <Form.Group as={Row}>
            <Col sm="12">
              Month is {isMonthLocked ? '' : 'not'} {isMonthLocked} locked.
              <Form.Check
                id={`add-credit-modal-apply-now`}
                checked={applyImmediately}
                onChange={(e) => setApplyImmediately(e.target.checked)}
                label={`Apply now (as ${isMonthLocked ? 'Payment' : 'Line Item'})`}
              />
            </Col>
          </Form.Group>
        }
        <Button
          onClick={() => {
            onSubmit({
              invoiceId: invoice?.id ?? null,
              subscriptionId: subscriptionIds.length === 1 ? subscriptionIds[0] : null,
              generatedSubscriptionId: null,
              creditDescription: creditDescription,
              creditAmount: creditAmount,
              creditDate: creditDate,
              clawbackFromPartner: clawbackFromPartner,
              currencyCode: invoice?.currency ?? 'CAD',
              taxRate: floatVal(invoice?.taxRate ?? '0'),
              taxAmount: floatVal(creditAmount) * floatVal(invoice?.taxRate ?? '0'),
              freshbooksClientId: invoice?.freshbooksClientId,
            });
            onHide?.();
          }}
        >Add</Button>
        <Button onClick={onHide} variant="secondary">Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
};
