import { IApiQueryResponse } from "@llws/api-common";

import { dispatch } from "../../store";
import { performApiActionRequest } from "../../apiActionRequest";

import {
  hubspotClientUpdatesReceived,
  hubspotDealUpdateInfoReceived,
  setLoadedState,
  setMissingClientCount,
} from "./hubspotSlice";
import { HubspotClientUpdateResponse } from "./HubspotClientUpdateResponse";
import { apiFetch, IApiQuerySingularResponse } from "../../utils";
import { REACT_APP_API_ROOT_URI } from "../../../App";

export const updateHubspotClients = async () => {
  dispatch(setLoadedState('loading'));
  const responseData = await performApiActionRequest<HubspotClientUpdateResponse>(
    'updateMissingHubspotClients'
    , {},
  ) as IApiQueryResponse<HubspotClientUpdateResponse>;
  dispatch(hubspotClientUpdatesReceived(responseData));
  getMissingHubspotClientCount();
  dispatch(setLoadedState('loaded'));
};

export const updateHubspotDealsForMonth = async (year: number, month: number) => {
  dispatch(setLoadedState('loading'));
  const responseData = await performApiActionRequest<{ success: boolean }>(
    'updateHubspotDealsForMonth',
    { year, month },
  ) as IApiQueryResponse<{ success: boolean }>;
  dispatch(hubspotDealUpdateInfoReceived(responseData));
  dispatch(setLoadedState('loaded'));
};

export const getMissingHubspotClientCount = async () => {
  dispatch(setLoadedState('loading'));
  const clientCountResponse = (
    await apiFetch(`${REACT_APP_API_ROOT_URI}/clients/count_missing_hubspot_id`)
  );

  const clientCountResponseData = await clientCountResponse.json() as IApiQuerySingularResponse<{ count: number }>;

  dispatch(setMissingClientCount(clientCountResponseData.data.count));
  dispatch(setLoadedState('loaded'));
};
