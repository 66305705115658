import { FormEvent, useState } from "react";

import { Box, Button, FormControl, TextField, Typography } from "@mui/material";

import { updateBillingProfile } from "../../../redux/features/clients/actions";

type BillingProfileFields = {
    id: number,
    accountId: number,
    organization: string,
    email: string,
    street1: string,
    street2: string,
    city: string,
    province: string,
    country: string,
    code: string,
    contactPhone: string,
    contactName: string,
  }

interface IBillingProfileUpdateFormProps {
    afterSubmit?: () => void;
    billingProfile: BillingProfileFields;
  }

export const BillingProfileUpdateForm = (
  {
    afterSubmit,
    billingProfile,
  }: IBillingProfileUpdateFormProps,
) => {
  const [ organization, setOrganization ] = useState(billingProfile.organization);
  const [ email, setEmail ] = useState(billingProfile.email);
  const [ street1, setStreet1 ] = useState(billingProfile.street1);
  const [ street2, setStreet2 ] = useState(billingProfile.street2);
  const [ city, setCity ] = useState(billingProfile.city);
  const [ province, setProvince ] = useState(billingProfile.province);
  const [ country, setCountry ] = useState(billingProfile.country);
  const [ code, setCode ] = useState(billingProfile.code);
  const [ contactPhone, setContactPhone ] = useState(billingProfile.contactPhone);
  const [ contactName, setContactName ] = useState(billingProfile.contactName);
  
  const handleSubmit = async (_e: FormEvent<HTMLButtonElement>) => {
    await updateBillingProfile(
      {
        id: billingProfile.id,
        accountId: billingProfile.accountId,
        organization,
        email,
        street1,
        street2,
        city,
        province,
        country,
        code,
        contactPhone,
        contactName,
      },
    );
  
    afterSubmit?.();
  };
    
  return (
    <Box p={2} textAlign='center'>
      <Typography variant="h5">
        Edit Billing Profile
      </Typography>
      <br />
  
      <FormControl fullWidth sx={{ mb: 2.2 }}>
        <TextField
          label='Organization'
          onChange={(e) => setOrganization(e.target.value)}
          size='small'
          value={organization}
        />
      </FormControl>
        
      <FormControl fullWidth sx={{ mb: 2.2 }}>
        <TextField
          label='Email'
          onChange={(e) => setEmail(e.target.value)}
          size='small'
          value={email}
        />
      </FormControl>
        
      <FormControl fullWidth sx={{ mb: 2.2 }}>
        <TextField
          label='Street 1'
          onChange={(e) => setStreet1(e.target.value)}
          size='small'
          value={street1}
        />
      </FormControl>
        
      <FormControl fullWidth sx={{ mb: 2.2 }}>
        <TextField
          label='Street 2'
          onChange={(e) => setStreet2(e.target.value)}
          size='small'
          value={street2}
        />
      </FormControl>
        
      <FormControl fullWidth sx={{ mb: 2.2 }}>
        <TextField
          label='City'
          onChange={(e) => setCity(e.target.value)}
          size='small'
          value={city}
        />
      </FormControl>
        
      <FormControl fullWidth sx={{ mb: 2.2 }}>
        <TextField
          label='Province'
          onChange={(e) => setProvince(e.target.value)}
          size='small'
          value={province}
        />
      </FormControl>
        
      <FormControl fullWidth sx={{ mb: 2.2 }}>
        <TextField
          label='Country'
          onChange={(e) => setCountry(e.target.value)}
          size='small'
          value={country}
        />
      </FormControl>
        
      <FormControl fullWidth sx={{ mb: 2.2 }}>
        <TextField
          label='Code'
          onChange={(e) => setCode(e.target.value)}
          size='small'
          value={code}
        />
      </FormControl>
        
      <FormControl fullWidth sx={{ mb: 2.2 }}>
        <TextField
          label='Contact Phone'
          onChange={(e) => setContactPhone(e.target.value)}
          size='small'
          value={contactPhone}
        />
      </FormControl>
        
      <FormControl fullWidth>
        <TextField
          label='Contact Name'
          onChange={(e) => setContactName(e.target.value)}
          size='small'
          value={contactName}
        />
      </FormControl>
  
      <br /><br />
        
      <FormControl fullWidth>
        <Button variant='contained' type='submit' fullWidth onClick={handleSubmit}>
          Update Billing Profile
        </Button>
      </FormControl>
    </Box>
  );
};
