import React from 'react';

import { Button } from '@mui/material';

const getTextFromRow = (row: HTMLTableRowElement) => {
  const cells = [
    ...Array.from(row.getElementsByTagName('th')),
    ...Array.from(row.getElementsByTagName('td')),
  ];
  return cells.map(cell => cell.innerText.replace('\n', ' '));
};

const getTextFromTableSection = (tsection: Element) => {
  return Array.from(tsection.getElementsByTagName('tr')).map(row => getTextFromRow(row));
};

const getTableTextData = (table: Element) => {
  const thead = table.getElementsByTagName('thead')?.[0];
  const headerText = thead ? getTextFromTableSection(thead) : [];
  const tbody = table.getElementsByTagName('tbody')?.[0];
  const rowText = tbody ? getTextFromTableSection(tbody) : [];
  const tfoot = table.getElementsByTagName('tfoot')?.[0];
  const footerText = tfoot ? getTextFromTableSection(tfoot) : [];
  return [...headerText, ...rowText, ...footerText];
};

const getTableExportData = (table: Element) => {
  const data = getTableTextData(table);
  const dataText = data.map(tr => `"` + tr.join(`","`).replace(/[▲▼]/, '') + `"`).join(`\r\n`);
  return dataText;
};

const exportTableToCSV = (filename: string, table: Element) => {
  const data = getTableExportData(table);
  const blob = new Blob([data], { type: 'text/csv' });
  const element = document.createElement("a");
  element.href = URL.createObjectURL(blob);
  element.download = filename + ".csv";
  document.body.appendChild(element);
  element.click();
};

export const DownloadCSV = (props: React.PropsWithChildren<{ filename?: string }>) => {
  return (<>
    <Button 
      variant='outlined' 
      className="float-right" 
      sx={{my: 1}} 
      onClick={(e) => {
        const nextSibling = e.currentTarget.nextElementSibling;
        const table = (
          nextSibling?.tagName.toLowerCase() === 'table'
            ? nextSibling
            : nextSibling?.getElementsByTagName('table')?.[0]
        );
        return table ? exportTableToCSV(props.filename ?? 'Download', table) : null;
      }}>Download {props.filename} as CSV</Button>
    {props.children}
  </>
  );
};
