import { Nullable } from "@jamesgmarks/utilities";

export enum EBillingRunScheduleStatus {
    pending = 'pending',
    completed = 'completed',
    deleted = 'deleted',
  }

export interface IBillingRunSchedule {
    id: number;
    year: Nullable<number>;
    month: Nullable<number>;
    billingDate: Date;
    status: EBillingRunScheduleStatus;
    created: Date;
    createdUserId: number;
    modified: Nullable<Date>;
    modifiedUserId: Nullable<number>;
}
