import { IQueryCommandOptions, In } from "@llws/dynamic-router-utils";
import { 
  Grid,
  Paper,
  TableContainer,
  Table as MuiTable,
  Checkbox,
  TableHead,
  TableBody,
  Button,
  Box,
  TablePagination,
  CircularProgress,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { StyledTableRow, StyledTableCell } from "src/components/parts/mui/StyledTables";
import { getMany } from "src/redux/features/dynamic-hydra/actions";
import { useAppSelector } from "src/redux/hooks";
import React from "react";
import { intVal } from "@jamesgmarks/utilities";
import { rowsPerPageList } from "../Clients/Tabs";
import { ReplayWebhooksModal } from "./ReplayWebhookModal";
import { WebhookCallbackRow } from "./WebhookCallbackRow";
import { HHMultiStateToggle } from "src/components/parts/HHMultiStateToggle";
import { performApiActionRequest } from "src/redux/apiActionRequest";

export const paginationStyling = {
  '& p': { marginTop: '1rem' },
  backgroundColor: '#f2f2f2',
  '& .MuiTablePagination-actions > button': {
    '&:hover': {
      // Example hover color
      backgroundColor: '#e0e0e0',
    },
  },
};

export const WebhookCallbackManagement = () => {
  const webhookCallbackList = useAppSelector(state => state.dynamicHydra.data.webhook_callbacks?.list ?? []);
  const loadedState = useAppSelector(state => state.dynamicHydra.data.webhook_callbacks?.loadedState);
  const metaData = useAppSelector(state => state.dynamicHydra.metaData);

  const [ sortColumn, setSortColumn ] = useState('id');
  const [ sortDirection, setSortDirection ] = useState<'asc' | 'desc'>('desc');
  const [ page, setPage ] = useState<number>(0);
  const [ limit, setLimit ] = useState<number>(20);
  const [selectedWebhookCallback, setSelectedWebhookCallback] = useState<number[]>([]);
  const [isReplayModalOpen, setIsReplayModalOpen] = useState(false);
  const [webhookSourceType, setWebhookSourceType] = useState<string[]>(['xero']);

  const toggleOptions = useMemo(() => (
    [
      {
        value: 'xero',
        label: 'Xero',
      },
      {
        value: 'freshbooks',
        label: 'Freshbooks',
      },
      {
        value: 'stripe',
        label: 'Stripe',
      },
    ]
  ), []);

  const queryCommand: IQueryCommandOptions = useMemo(() => ({
    alias: 'webhookCallback',
    page,
    limit,
    where: [In('sourceType', webhookSourceType)],
    orderBy: [{field: sortColumn, direction: sortDirection}],
  }), [page, limit, sortColumn, sortDirection, webhookSourceType]); 

  useEffect(() => {
    getMany('webhook_callbacks', queryCommand);
  }, [queryCommand]);

  const sortBy = (criteria: string) => {
    setSortDirection(sortColumn === criteria && sortDirection === 'asc' ? 'desc' : 'asc');
    setSortColumn(criteria);
  };

  const toggleAllCheckbox = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    if (checked) {
      const allIds = webhookCallbackList.map(callback => callback.id);
      setSelectedWebhookCallback(allIds);
    } else {
      setSelectedWebhookCallback([]);
    }
  }, [webhookCallbackList]);

  const toggleRowCheckbox = useCallback((webhookCallbackId: number) => {
    setSelectedWebhookCallback(prev => {
      const isSelected = prev.includes(webhookCallbackId);
      if (isSelected) {
        return prev.filter(id => id !== webhookCallbackId);
      } else {
        return [...prev, webhookCallbackId];
      }
    });
  }, []);

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLimit(intVal(event.target.value));
    setPage(0);    
  };

  const handleReplayClick = (id?: number) => {
    setIsReplayModalOpen(true);
    if (id) {
      setSelectedWebhookCallback([id]);
    }
  };

  const handleConfirmReplay = async () => {
    await performApiActionRequest('replayXeroWebhook', {
      webhookCallBackIds: selectedWebhookCallback,
      replayAllFailed: false,
    });
    setIsReplayModalOpen(false);
  };
  
  return (
    <Grid container spacing={2} mt={2} justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <HHMultiStateToggle 
            options={toggleOptions} 
            defaultValues={['xero']}
            onChange={(value: string | string[]) => {
              if (!Array.isArray(value)) {
                return;
              }
              setWebhookSourceType(value);
            }}
            multiple={true}
          />
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => handleReplayClick()}
            disabled={selectedWebhookCallback.length === 0}
          >
            Replay {selectedWebhookCallback.length} Webhook{selectedWebhookCallback.length !== 1 ? 's' : ''}
          </Button>
        </Box>
        <TableContainer component={Paper} elevation={12} sx={{ mb: 1 }}>
          <TablePagination
            component="div"
            sx={paginationStyling}
            count={metaData?.totalRecords ?? 0} 
            page={page}
            rowsPerPage={limit}
            showFirstButton
            showLastButton
            rowsPerPageOptions={rowsPerPageList}
            onPageChange={(e, newPage)=>{setPage(newPage);}}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
          <MuiTable stickyHeader style={{ borderCollapse: 'collapse' }}>
            <TableHead>
              <StyledTableRow dark={true} >
                <StyledTableCell
                  dark={true}
                  style={{ textAlign: 'center' }}
                >
                  <Checkbox
                    style={{color: 'white'}}
                    indeterminate={
                      selectedWebhookCallback.length > 0 && selectedWebhookCallback.length < webhookCallbackList.length
                    }
                    checked={
                      webhookCallbackList.length > 0 && selectedWebhookCallback.length === webhookCallbackList.length
                    }
                    onChange={toggleAllCheckbox}
                  />
                </StyledTableCell>
                <StyledTableCell
                  dark={true}
                  style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                  onClick={() => sortBy('id')}
                >
                  Callback ID
                </StyledTableCell>
                <StyledTableCell
                  dark={true}
                  style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                  onClick={() => sortBy('name')}
                >
                  Name
                </StyledTableCell>
                <StyledTableCell
                  dark={true}
                  style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                  onClick={() => sortBy('objectId')}
                >
                  Object ID
                </StyledTableCell>
                <StyledTableCell
                  dark={true}
                  style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                  onClick={() => sortBy('sourceType')}
                >
                  Source
                </StyledTableCell>
                <StyledTableCell
                  dark={true}
                  style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                  onClick={() => sortBy('status')}
                >
                  Status
                </StyledTableCell>
                <StyledTableCell
                  dark={true}
                  style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                  onClick={() => sortBy('created')}
                >
                  Received Date
                </StyledTableCell>
                <StyledTableCell
                  dark={true}
                  style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                  onClick={() => sortBy('modified')}
                >
                  Last Replayed Date
                </StyledTableCell>
                <StyledTableCell
                  dark={true}
                  style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                  onClick={() => sortBy('modifiedUserId')}
                >
                  Replayed By
                </StyledTableCell>
                <StyledTableCell>&nbsp;</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {(loadedState === 'loading'
                ? []
                : (webhookCallbackList ?? []))
                .map((i) => (
                  <WebhookCallbackRow
                    key={i.id}
                    callbackData={i}
                    checked={selectedWebhookCallback.includes(i.id)}
                    onToggle={toggleRowCheckbox}
                    onReplay={handleReplayClick}
                  />
                ))
              }
            </TableBody>
          </MuiTable>
          {loadedState === 'loading'
            ? <Box sx={{ textAlign: 'center' }} pt={2}>
              <CircularProgress size={25} color='primary' sx={{ ml: 2 }} />
            </Box>
            : webhookCallbackList?.length === 0 && <Box sx={{ textAlign: 'center' }}>NO DATA AVAILABLE</Box>
          }
          <TablePagination
            component="div"
            sx={paginationStyling}
            count={metaData?.totalRecords ?? 0} 
            page={page}
            rowsPerPage={limit}
            showFirstButton
            showLastButton
            rowsPerPageOptions={rowsPerPageList}
            onPageChange={(e, newPage)=>{setPage(newPage);}}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </TableContainer>
      </Grid>
      <ReplayWebhooksModal
        open={isReplayModalOpen}
        selectedIds={selectedWebhookCallback}
        onClose={() => setIsReplayModalOpen(false)}
        onConfirm={handleConfirmReplay}
      />
    </Grid>
  );
};