import { ISingleViewStatementData } from "src/interfaces/ISingleViewStatementData";
import { EStatementType } from "../../../../../../entities/hydra/Statement";
import { DefaultStatementSingleView } from "./DefaultStatementSingleView";

export const SingleStatementViewComponents = (
  {
    statementData, 
    statementType,
  } : {
    statementData: ISingleViewStatementData,
    statementType: EStatementType
  },
) => {
  const statementViewMap = {
    'account': <DefaultStatementSingleView statementData={statementData}/>,
    'activity': <DefaultStatementSingleView statementData={statementData}/>,
  };

  return statementViewMap[statementType];
};