import { Nullable } from "@jamesgmarks/utilities";
import {
  Button, Card, CardContent, Chip, Container, Grid, Link,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IBillingRun } from "../../../entity-interfaces/IBillingRun";
import { IClientRuntimeGroup } from "../../../entity-interfaces/IClientRuntimeGroup";
import {
  refreshBillingRuns,
  rerunBillingRun,
} from "../../../redux/features/billing-runs/actions";
import { useAppSelector } from "../../../redux/hooks";

const MobileRunErrorDetails = ({ run } : { run: IBillingRun}) => {
  try {
    const parsedErrorDetails = JSON.parse(run.details);
    const timestamp = new Date(parsedErrorDetails.timestamp);

    return (
      <div>
        <div>
          &gt; Timestamp:
          <em>
            D: {timestamp.toLocaleDateString()},
            T: {timestamp.toLocaleTimeString()}
          </em>
        </div>
        <div>
          &gt; Error Type:
          <em>
            {parsedErrorDetails.errorName}
          </em>
        </div>
        <div>
          &gt; Message:
          <em>
            {parsedErrorDetails.errorMessage}
          </em>
        </div>
        <div>
          &gt; Data:
          <em>
            {JSON.stringify(parsedErrorDetails.errorData)}
          </em>
        </div>
        <Button
          variant='contained'
          onClick={() => run.id && rerunBillingRun(run.id)}
        >
          Re-run
        </Button>
      </div>
    );
  } catch (e) {
    return <div>Unparsable details: <code>{run.details}</code></div>;
  }
};

export const MobileBillingRuns = () => {
  const runs = useAppSelector((state) => state.billingRuns.runs);

  useEffect(() => { refreshBillingRuns(); }, []);

  const [currentParentId, setCurrentParentId] = useState<Nullable<number>>(null);

  const toggleCurrentParentId = useCallback((id: number) => {
    setCurrentParentId(currentParentId === id ? null : id);
  }, [currentParentId]);

  const parentRuns = useMemo(() => {
    return (
      runs
        .filter((r) => r.parentId === null)
        .sort((a, b) => (new Date(b.created).getTime() - new Date(a.created).getTime()))
    );
  }, [runs]);

  const childRuns = useMemo(() => {
    return runs.filter((r) => r.parentId === currentParentId);
  }, [runs, currentParentId]);

  const getRuntimeGroupLabel = useCallback((crg: IClientRuntimeGroup) => {
    return (
      crg.label
        ? `${crg.label} (Group ${crg.id} ${crg.runType})`
        : `Group ${crg.id} (${crg.runType})`
    );
  }, []);

  const StatusIndicator = ({ status }: { status: string }) => {
    const indicators: Record<string, JSX.Element> = {
      completed: <Chip label="Completed" color="success"/>,
      error: <Chip label="ERROR!" color="error"/>,
      processing: <Chip label="Processing..." color="primary"/>,
      pending: <Chip label="Pending..." color="primary" variant="outlined"/>,
    };

    return indicators[status] ?? <></>;

  };

  return <div>
    <Link href="/mobile">Back</Link>
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h1>Billing Runs</h1>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          {parentRuns.map((r) => (
            <Grid container spacing={2}>
              <Card variant="outlined">
                <CardContent>
                  <Grid item xs={12} onClick={() => toggleCurrentParentId(r.id)} key={r.id}>
                    <Card variant="outlined">
                      <CardContent>
                        <StatusIndicator status={r.status} /> {r.created}({r.id}) - {r.year}/{r.month}
                      </CardContent>
                    </Card>
                  </Grid>
                  {currentParentId === r.id
                    && <Grid item xs={12}>
                      <Card variant="outlined">
                        <CardContent>
                          <Grid container spacing={2}>
                            {childRuns.map((cr) => (
                              <Grid item xs={12}>
                                <Card variant="outlined">
                                  <CardContent>
                                    <StatusIndicator status={cr.status} />
                                    {cr.id} - {getRuntimeGroupLabel(cr.clientRuntimeGroup)}
                                    {cr.status === 'error' && <MobileRunErrorDetails run={cr} />}
                                  </CardContent>
                                </Card>
                              </Grid>
                            ))}
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  }
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
    <ul>

    </ul>
  </div>;
};
