
import { Autocomplete, TextField } from '@mui/material';
import { compactArray, Nullable } from '@jamesgmarks/utilities';
import { IOwnershipGroupDropdownItem } from '../../entity-interfaces/IOwnershipGroupDropdownItem';
import { useAppSelector } from '../../redux/hooks';
import React, { useEffect, useState } from 'react';
import { loadClientOwnershipGroupsDropdown } from '../../redux/features/clients/actions';

interface IOwnershipGroupDropdownProps {
  freshbooksClientId?: Nullable<number>;
  ownershipGroupId?: Nullable<number>;
  onOwnershipGroupChanged: (ownershipGroup: Nullable<IOwnershipGroupDropdownItem>) => void;
  onOwnershipGroupFreeForm: (freeFormName: Nullable<string>) => void;
  allowFreeform?: boolean;
  disableIfNoOwnershipGroups?: boolean;
  disableComponent?: boolean;
}

export const OwnershipGroupDropdown = ({
  freshbooksClientId,
  ownershipGroupId,
  onOwnershipGroupChanged,
  onOwnershipGroupFreeForm,
  allowFreeform = false,
  disableIfNoOwnershipGroups = false,
  disableComponent = false,
}: IOwnershipGroupDropdownProps ) => {
  const ownershipGroupList = useAppSelector(state => state.clients.ownershipGroupsList);

  const shouldDisable = (
    (
      disableIfNoOwnershipGroups
      && (ownershipGroupList?.length ?? 0) <= 0
    )

    || disableComponent
  ); 

  const [disabled, setDisabled] = useState(shouldDisable);
  
  const [searchboxState, _setSearchboxState] = useState({
    selectedOwnershipGroup: null as Nullable<IOwnershipGroupDropdownItem>,
    ownershipGroupSearchString: '',
    show: false,
    menuIndex: 0,
  });

  const setSearchboxState = (stuff: typeof searchboxState) => {
    _setSearchboxState(stuff);
  };

  const setSelectedOwnershipGroup = (ownershipGroup: Nullable<IOwnershipGroupDropdownItem>) => {
    const newOwnershipGroupName = ownershipGroup?.name ?? '';
    setSearchboxState({
      ...searchboxState,
      selectedOwnershipGroup: ownershipGroup,
      ownershipGroupSearchString: newOwnershipGroupName,
    });
    onOwnershipGroupChanged(ownershipGroup);
    onOwnershipGroupFreeForm(newOwnershipGroupName);
  };

  const setOwnershipGroupSearchString = (ownershipGroupSearch: Nullable<string>) => {
    setSearchboxState({
      ...searchboxState,
      selectedOwnershipGroup: null,
      ownershipGroupSearchString: ownershipGroupSearch ?? '',
    });
    onOwnershipGroupChanged(null);
    onOwnershipGroupFreeForm(ownershipGroupSearch);
  };

  useEffect(
    () => {  
      if(freshbooksClientId && freshbooksClientId > 0 && !disableComponent) {
        loadClientOwnershipGroupsDropdown(freshbooksClientId);
      }
    },
    [disableComponent, freshbooksClientId],
  );

  useEffect(
    () => {
      setDisabled(shouldDisable);
    },
    [ownershipGroupList, shouldDisable],
  );

  const selectedOwnershipGroup =(
    ownershipGroupList?.find(og => og.id === ownershipGroupId)
  ); 

  //TODO figure out how to add setSelectedOwnershipGroup to dependancy array without causing Maximum update depth exceeded
  useEffect(() => { 
    setSelectedOwnershipGroup(selectedOwnershipGroup ?? null);
  }, [ownershipGroupId, selectedOwnershipGroup]);

  const ownershipGroupLister = compactArray(
    ownershipGroupList
      ?.slice()
      .sort((a, b) => a?.name.trim().toLowerCase() < b?.name.trim().toLowerCase() ? -1 : 1)
    ?? [],
  );

  return (
    <Autocomplete
      key={ownershipGroupId}
      id="ownership-group-selector"
      size="small"
      options={ownershipGroupLister}
      getOptionLabel={(newValue: string | { inputValue: string } | IOwnershipGroupDropdownItem) => {  // IClientDropdownItem
        if (typeof newValue === 'string') {
          return newValue;
        } else if (newValue && typeof newValue === 'object' && 'inputValue' in newValue) {
          // Create a new value from the user input
          return newValue.inputValue;
        } else {
          const c = newValue as IOwnershipGroupDropdownItem;
          return `${c.name} ID: (${c.id})`;
        }
      }}
      renderInput={(params) => <TextField {...params} label="Ownership Group" variant="outlined" />}
      onChange={(event, newValue: string | { inputValue: string } | IOwnershipGroupDropdownItem | null) => {
        if (typeof newValue === 'string') {
          setOwnershipGroupSearchString(newValue);
        } else if (newValue && !('defaultCurrencyId' in newValue) && ('inputValue' in newValue)) {
          // Create a new value from the user input
          setOwnershipGroupSearchString(newValue.inputValue);
        } else {
          if (newValue !== null) {
            setSelectedOwnershipGroup(newValue as unknown as Nullable<IOwnershipGroupDropdownItem>);
          }
        }
      }}
      onInputChange={(event, value) => {
        setOwnershipGroupSearchString(value);
      }}
      autoComplete
      autoHighlight={!allowFreeform}
      clearOnBlur={!allowFreeform}
      clearOnEscape
      fullWidth
      freeSolo={allowFreeform}
      disabled={disabled}
      value={selectedOwnershipGroup ?? null}
    />
  );
};
