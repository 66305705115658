import { Response } from "koa";
import { typedApiFetch } from "../redux/utils";

/**
 * Makes a request that will return an `fs.readStream` that can be displayed to the user.
 * @param url The router endpoint to query for a file to display.
 * @param filename The name of the file existing on GCP.
 */
export const displayFile = async (url: string, filename: string) => {
  const response = await typedApiFetch<Response>(url);
  const file = window.URL.createObjectURL(await response.blob());
  const a = Object.assign(
    document.createElement('a'), {
      href: file,
      download: filename,
    },
  );
  document.body.appendChild(a);
  a.click(); a.remove();
};
