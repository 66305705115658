import { def, intVal } from "@jamesgmarks/utilities";
import React, { useEffect, useState } from "react";
import {
  Button, Col, Container, Form, InputGroup, Row,
} from "react-bootstrap";
import Tab from "react-bootstrap/esm/Tab";
import Tabs from "react-bootstrap/esm/Tabs";
import { loadSalesReps, markCommissionsAsPaidByFile } from "../../../redux/features/commissions/actions";
import { useAppSelector } from "../../../redux/hooks";
import { CommissionsPayable } from "./CommissionsPayable";
import { CommissionsUnpaid } from "./CommissionsUnpaid";
import { CommissionsMonthly } from "./CommissionsMonthly";
import { dispatch } from "../../../redux/store";
import { setCommissionSource } from "../../../redux/features/commissions/commissionsSlice";
import { CommissionsPaid } from "./CommissionsPaid";
import { useAuth } from "../../../customHooks/useAuth";

export const CommissionsTabView = () => {
  const salesReps = useAppSelector(state => state.commissions.salesRepList);
  const commissionSource = useAppSelector(state => state.commissions.commissionSource);
  const { userId, permissions } = useAuth();
  // TODO: Are these useState states redundant? (use hasPermission() ?)
  const [
    readAllCommissionsPermission,
    // setReadAllCommissionsPermission,
  ] = useState((permissions.FULL_ACCESS === 'grant' || permissions.READ_ALL_COMMISSIONS === 'grant'));
  const [
    readOwnCommissionsPermission,
    // setReadOwnCommissionsPermission,
  ] = useState((permissions.FULL_ACCESS === 'grant' || permissions.READ_OWN_COMMISSIONS === 'grant'));
  // const [
  //   updateCommissionPaidStatusPermission,
  //   setupdateCommissionPaidStatusPermission,
  // ] = useState((permissions.FULL_ACCESS === 'grant' || permissions.UPDATE_COMMISSION_PAID_STATUS === 'grant'));
  const [
    file,
    setFile,
  ] = useState({} as File);

  if (!def(salesReps) || (salesReps.length === 0)) {
    loadSalesReps();
  }

  const [
    salesRepId,
    setSalesRepId,
  ] = useState(readOwnCommissionsPermission ? (salesReps.find((s) => s.user.id === userId))?.id || 0 : 0);

  const [
    updateCommissionPaidStatusPermission,
    // setupdateCommissionPaidStatusPermission,
  ] = useState((permissions.FULL_ACCESS === 'grant' || permissions.UPDATE_COMMISSION_PAID_STATUS === 'grant'));

  useEffect(() => {
    setSalesRepId(readOwnCommissionsPermission ? (salesReps.find((s) => s.user.id === userId))?.id || 0 : 0);
  }, [ salesReps, readOwnCommissionsPermission, userId ]);

  return (
    <Container>
      <Row>
        {readAllCommissionsPermission && <Col xs={4} >
          <Form.Group>
            <Form.Label>Sales Rep</Form.Label>
            <Form.Control value={salesRepId} onChange={(e) => setSalesRepId(intVal(e.target.value))} as="select">
              <option value={0}>Select sales rep</option>
            ({salesReps.map((sr) => (
                <option key={sr.id} value={sr.id}>{sr.user.firstName + ' ' + sr.user.lastName}</option>
              ))})
            </Form.Control>
          </Form.Group>
        </Col>
        }
        <Col xs={4} >
          <Form.Group>
            <Form.Label>Source</Form.Label>
            <Form.Control
              value={commissionSource}
              onChange={(e) => dispatch(setCommissionSource((e.target.value as 'legacy' | 'hydra' | 'both')))}
              as="select"
            >
              <option key={'hydra'} value={'hydra'}>Hydra</option>
              <option key={'legacy'} value={'legacy'}>Legacy</option>
              <option key={'both'} value={'both'}>Both</option>
            </Form.Control>
          </Form.Group>
        </Col>
        {updateCommissionPaidStatusPermission && <Col xs={4} >
          <Form.Group>
            <Form.Label>Upload Paid Commissions</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Append>
                <Form.File
                  id="custom-file"
                  label={file.name ? file.name : 'No File Selected'}
                  accept='.csv'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const numFiles = e?.target?.files?.length || 0;
                    const choosenFile = (numFiles > 0 && e.target?.files !== null) ? e.target?.files[0] : null;
                    // const name = choosenFile ? choosenFile.name : 'No File Selected';
                    if (choosenFile) {
                      setFile(choosenFile);
                    }
                    console.log(file.name);
                  }}
                  custom
                />
                {/* <Button type='submit' variant="info">Browse</Button> */}
                <Button
                  disabled={!file.name}
                  variant="primary"
                  onClick={() => file !== null ? markCommissionsAsPaidByFile(file) : null}
                >Upload</Button>

              </InputGroup.Append>
              <InputGroup.Append>
                {/* <Button variant="primary" >Upload</Button> */}
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </Col>
        }

      </Row>

      {(readOwnCommissionsPermission || readAllCommissionsPermission)
        && <Tabs
          defaultActiveKey="payable"
          id="uncontrolled-tab-example"
          transition={false}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <Tab eventKey="payable" title="Commissions Payable">
            <CommissionsPayable salesRepId={salesRepId}></CommissionsPayable>
          </Tab>
          <Tab eventKey="paid" title="Commissions Paid">
            <CommissionsPaid salesRepId={salesRepId}></CommissionsPaid>
          </Tab>
          <Tab eventKey="monthly" title="Commissions Monthly">
            <CommissionsMonthly salesRepId={salesRepId}></CommissionsMonthly>
          </Tab>
          <Tab eventKey="unpaid" title="Unpaid Commissions">
            <CommissionsUnpaid salesRepId={salesRepId}></CommissionsUnpaid>
          </Tab>
        </Tabs>
      }
    </Container>
  );
};