import { Tab, Tabs } from "react-bootstrap";
import {
  IMonthlySummaryData,
} from "../../../../../redux/features/reporting/IMonthlySummaryData";
import { MonthlyRevenueSummary } from "./MonthlyRevenueSummary";
import { MonthlyRevenueComparison } from "./MonthlyRevenueComparison";
import { MonthlyClientBreakdown } from "./MonthlyClientBreakdown";
import { MonthlySubscriptionBreakdown } from "./MonthlySubscriptionBreakdown";
import { useAuth } from "../../../../../customHooks/useAuth";
import { MonthlyCreditSummary } from "./MonthlyCreditSummary/MonthlyCreditSummary";
import { EHideableColumns } from "./MonthlyBillingReport";

interface IMonthlyBillingCurrencyTabProps {
  currencyCode: string;
  hiddenColumns?: EHideableColumns[];
  monthlySummary: IMonthlySummaryData;
}

export const MonthlyBillingCurrencyTab = ({
  currencyCode,
  hiddenColumns = [],
  monthlySummary,
}: IMonthlyBillingCurrencyTabProps) => {
  const perStreamSummary = monthlySummary?.perStreamSummary;
  const creditTotals = monthlySummary?.creditTotals;
  const creditBreakdown = monthlySummary?.creditBreakdown;
  const subscriptionBreakdown = monthlySummary?.subscriptionBreakdown;

  const { hasPermission }= useAuth();
  const canSeeClientsSubtab = hasPermission('FULL_ACCESS');
  const currencySubscriptionBreakdown = subscriptionBreakdown.filter(bd => bd.currencyCode === currencyCode);
  return (
    <>
      <Tabs defaultActiveKey={`summary_tab_${currencyCode}`} transition={false} mountOnEnter>
        <Tab eventKey={`summary_tab_${currencyCode}`} title="Summary">
          <h2 style={{ marginTop: 30 }}>Revenue Summary ({currencyCode})</h2>
          <MonthlyRevenueSummary
            currencyCode={currencyCode}
            creditTotals={creditTotals ?? ({})}
            hiddenColumns={hiddenColumns}
            perStreamSummary={perStreamSummary ?? ({})}
          />
        </Tab>

        <Tab eventKey={`comparison_tab_${currencyCode}`} title="Comparison">
          <h2 style={{ marginTop: 30 }}>Revenue Comparison ({currencyCode})</h2>
          <MonthlyRevenueComparison
            currencyCode={currencyCode}
            monthlySummary={monthlySummary}
          />
        </Tab>

        <Tab eventKey={`breakdown_tab_${currencyCode}`} title="Breakdown">
          <h2 style={{ margin: '30px 0' }}>Subscription Breakdown ({currencyCode})</h2>
          <MonthlySubscriptionBreakdown
            reportVersion={monthlySummary.version}
            legacyCreditBreakdown={
              creditBreakdown.legacyCredits.filter(c => c.currencyId === (currencyCode === 'USD' ? 2 : 1))
            }
            hydraCreditBreakdown={creditBreakdown.hydraCredits.filter(c => c.currencyCode === currencyCode)}
            subscriptionBreakdown={currencySubscriptionBreakdown}
            currencyCode={currencyCode}
          />
        </Tab>

        { canSeeClientsSubtab &&
          <Tab eventKey={`client_breakdown_tab_${currencyCode}`} title="Client Breakdown">
            <h2 style={{ margin: '30px 0' }}>Client Breakdown ({currencyCode})</h2>
            <MonthlyClientBreakdown subscriptionBreakdown={currencySubscriptionBreakdown} currencyCode={currencyCode} />
          </Tab>
        }

        <Tab eventKey={`credit_tab_${currencyCode}`} title="Credits">
          <h2 style={{ margin: '30px 0' }}>Credits Overview ({currencyCode})</h2>
          <MonthlyCreditSummary
            creditTotals={creditTotals[currencyCode] ?? {}}
            creditBreakdown={creditBreakdown}
            currencyCode={currencyCode}
            hiddenColumns={hiddenColumns}
          />
        </Tab>
      </Tabs>

    </>
  );
};
