import { intVal, Nullable } from "@jamesgmarks/utilities";
import {
  Button, Checkbox, FormControlLabel, FormGroup, Grid, Typography, 
} from "@mui/material";
import { useEffect, useState } from "react";
import { OwnershipGroupDropdown } from "src/components/parts/OwnershipGroupDropdown";

export const AssignExtraInfoToBillingContact = (
  {
    billingAccountId,
    showSaveButton = true,
    defaultOwnershipGroupId,
    defaultReceivesInvoices,
    handleActionButtonClick,
  } : {
    billingAccountId : number,
    showSaveButton? : boolean,
    defaultOwnershipGroupId : Nullable<number>,
    defaultReceivesInvoices : number,
    handleActionButtonClick: (
      (ownershipGroupId: Nullable<number>,
        receivesInvoices: number
      ) => void
    )
  },
) => {

  const [toggleAllOwnershipGroups, setToggleAllOwnershipGroups] = useState(!defaultOwnershipGroupId);
  const [ownershipGroupId, setOwnershipGroupId] = useState<Nullable<number>>(defaultOwnershipGroupId);
  const [receivesInvoices, setReceivedInvoices] = useState<number>(defaultReceivesInvoices);

  useEffect(() => {
    if (!showSaveButton) {
      handleActionButtonClick(ownershipGroupId, receivesInvoices);
    }
  }, [receivesInvoices, ownershipGroupId, showSaveButton, handleActionButtonClick]);

  const handleCheckboxChange = (checkedStatus: boolean) => {
    setToggleAllOwnershipGroups(checkedStatus);
    if (checkedStatus) setOwnershipGroupId(null);
  };
  
  return (
    <Grid container spacing={1} mb={2} mt={1} alignItems='center'>
      <Grid item container mt={0.5} spacing={2} justifyContent="space-around">
        <Typography>Assign Ownership group to the contact</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormGroup>
          <FormControlLabel
            label='Assign all ownership groups to the contact'
            control={
              <Checkbox
                checked={toggleAllOwnershipGroups}
                onChange={(e)=> handleCheckboxChange(e.target.checked)}
              />
            }
          />
          <FormControlLabel
            label='Receive Invoices'
            control={
              <Checkbox
                checked={receivesInvoices === 1}
                onChange={(e)=> setReceivedInvoices(e.target.checked ? 1 : 0)}
              />
            }
          />
        </FormGroup>
      </Grid>
      <Grid item xs={2}>
        Ownership Group:
      </Grid>
      <Grid item xs={10} pr={2}>
        <OwnershipGroupDropdown
          freshbooksClientId={billingAccountId}
          ownershipGroupId={ownershipGroupId}
          onOwnershipGroupChanged={(ownershipGroup) => {
            setOwnershipGroupId(ownershipGroup ? intVal(ownershipGroup.id) : null);
          }}
          onOwnershipGroupFreeForm={(ownershipGroupString) => {}}
          disableIfNoOwnershipGroups={true}
          disableComponent={toggleAllOwnershipGroups}
        />
      </Grid>
      {
        showSaveButton 
        && <Grid item container mt={2} spacing={2} justifyContent="flex-end">
          <Grid item mr={2}>
            <Button
              style={{ verticalAlign: 'middle' }}
              variant='contained'
              onClick={() => handleActionButtonClick(ownershipGroupId, receivesInvoices)}
            >
            Save
            </Button>
          </Grid>
        </Grid>
      }
    </Grid>
  );
};