import { Grid } from "@mui/material";
import { ISingleViewStatementData } from "src/interfaces/ISingleViewStatementData";
import { ActivityDateText } from "./StatementSingleViewBodyComponents/ActivityDateText";
import { CreatedDateText } from "./StatementSingleViewBodyComponents/CreatedDateText";
import { DefaultStatementTable } from "./StatementSingleViewBodyComponents/DefaultStatementTable";
import { DefaultStatementFooter } from "./StatementSingleViewFooterComponents/DefaultStatementFooter";
import { DefaultStatementHeader } from "./StatementSingleViewHeaderComponents/DefaultStatementHeader";

export const DefaultStatementSingleView = ({ statementData }: { statementData: ISingleViewStatementData }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DefaultStatementHeader address={statementData.address} statementType={statementData.statementType} />
      </Grid>
      <Grid item xs={12}>
        <CreatedDateText
          createdDate={statementData.statementStartDate ?? statementData.createDate}
        />
        {
          statementData.statementStartDate
          && statementData.statementEndDate
          && <>
            <br />
            <ActivityDateText startDate={statementData.statementStartDate} endDate={statementData.statementEndDate} />
          </>
        }
      </Grid>
      <Grid item xs={12}>
        <DefaultStatementTable statementData={statementData} />
      </Grid>
      <Grid item xs={12}>
        <DefaultStatementFooter
          terms={statementData.terms}
          bankingInformation={statementData.bankingInformation ?? ''}
        />
      </Grid>
    </Grid>
  );
};