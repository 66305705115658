import { compactArray, Nullable } from "@jamesgmarks/utilities";
import { ClientHasBillingContacts } from "@llws/typeorm-entities";
import { TBillingContactInternalNote } from "@llws/typeorm-entities/dist/entities/lift/BillingContacts";
import {
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  FormControl, 
  FormControlLabel, 
  Grid, 
  Paper, 
  Switch, 
  TextField, 
} from "@mui/material";
import { useMemo, useState } from "react";
import { sanitizeTelInput } from "src/app-utils/input-sanitizer";
import { isValidEmailString, isValidPhoneNumber, isValidPostalCode } from "src/app-utils/input-validator";
import { createNewBillingContact, updateBillingContact } from "src/redux/features/billing-contacts/actions";
import { AssignExtraInfoToBillingContact } from "./AssignExtraInfoToBillingContact";
import {  InternalNotesBox } from "../../parts/InternalNotesBox";

type TFormFieldError = {
  field: Nullable<string>,
  message: Nullable<string>
}

export const CreateBillingContactModal = ({
  billingAccountId,
  showModal,
  clientHasBillingContact,
  setShowModal,
}: {
  billingAccountId: number,
  showModal: boolean,
  setShowModal: ((newState: boolean) => void),
  clientHasBillingContact?: Nullable<ClientHasBillingContacts>,
}) => {

  const editingBillingContact = clientHasBillingContact ? clientHasBillingContact.billingContact : null;
  const internalNotesIfTheyExists = (
    clientHasBillingContact && clientHasBillingContact.billingContact.internalNotes
      ? clientHasBillingContact.billingContact.internalNotes
      : null
  );
  
  const [name, setName] = useState(editingBillingContact?.name ?? '');
  const [email, setEmail] = useState(editingBillingContact?.email ?? '');
  const [primaryPhone, setPrimaryPhone] = useState(editingBillingContact?.phone ?? '');
  const [secondaryPhone, setSecondaryPhone] = useState(editingBillingContact?.phone2 ?? '');
  const [address, setAddress] = useState(editingBillingContact?.address ?? '');
  const [city, setCity] = useState(editingBillingContact?.city ?? '');
  const [province, setProvince] = useState(editingBillingContact?.province ?? '');
  const [country, setCountry] = useState(editingBillingContact?.country ?? '');
  const [postalCode, setPostalCode] = useState(editingBillingContact?.postal ?? '');
  const [formFieldErrors, setFormFieldErrors] = useState<Nullable<TFormFieldError[]>>([]);
  const [showInternalNotes, setShowInternalNotes] = useState(false);
  const [internalNotes, setInternalNotes] = useState<Nullable<TBillingContactInternalNote[]>>(
    internalNotesIfTheyExists,
  );
  const [ownershipGroupId, setOwnershipGroupId] = useState<Nullable<number>>(
    clientHasBillingContact?.ownershipGroupId ?? null,
  );
  // @ts-ignore next line
  const [receivesInvoices, setReceivedInvoices] = useState<number>(clientHasBillingContact?.receivesInvoices ?? 0);
  
  const handleClose = () => {
    setShowModal(false);
  };

  const checkErrorExists = (fieldName: string) => {
    return !!(formFieldErrors ?? []).find((fieldError) => fieldError.field === fieldName);
  };

  const getErrorMessage = (fieldName: string) => {
    const errorField = (formFieldErrors ?? []).find((fieldError) => fieldError.field === fieldName);
    return errorField?.message ?? null;
  };

  const assembleBillingContactData = () => (
    {
      name,
      email,
      phone: primaryPhone !== '' ? primaryPhone : null,
      phone2: secondaryPhone !== '' ? secondaryPhone : null,
      address: address !== '' ? address : null,
      city: city !== '' ? city : null,
      province: province !== '' ? province : null,
      postal: postalCode !== '' ? postalCode : null,
      country: country !== '' ? country : null,
      internalNotes,
    }
  );

  const clientHasBillingContactInfo = () => (
    {
      freshbooksClientId: billingAccountId,
      ownershipGroupId,
      receivesInvoices,
      billingContactId: clientHasBillingContact?.billingContactId ?? null,
    }
  );

  const validateFormAndSetErrors = () => {
    const nameError= name === ''
      ? {field: 'name', message: 'Please provide a valid name'} 
      : null;
    const emailError= !isValidEmailString(email) 
      ? {field: 'email', message: 'Please provide a valid email address'} 
      : null;
    const primaryPhoneError = primaryPhone !== '' && !isValidPhoneNumber(primaryPhone) 
      ? {field: 'primaryPhone', message: 'Please provide a valid phone number'}
      : null;
    const secondaryPhoneError = secondaryPhone !== '' && !isValidPhoneNumber(secondaryPhone)
      ? {field: 'secondaryPhone', message: 'Please provide a valid phone number'} 
      : null;
    const addressError = address !== '' && address.length > 256
      ? {field: 'address', message: 'Please provide a valid address between 1-256 characters'} 
      : null;
    const postalCodeError = postalCode !== '' && !isValidPostalCode(postalCode) 
      ? {field: 'postalCode', message: 'Please provide a valid postal code'} 
      : null;
    const errors: TFormFieldError[] = compactArray([
      nameError, 
      emailError, 
      primaryPhoneError, 
      secondaryPhoneError, 
      addressError, 
      postalCodeError, 
    ]);
    setFormFieldErrors(errors);
    return errors.length;
  };

  const handleSubmit = async () => {
    const hasErrors = validateFormAndSetErrors() > 0;
    const billingContactBody = assembleBillingContactData();
    const clientHasBillingContactBody = clientHasBillingContactInfo();
    const shouldUpdateClientHasBillingContact = !hasErrors && !!clientHasBillingContact && !!editingBillingContact;
    const clientHasBillingContactUpdated = (
      shouldUpdateClientHasBillingContact
        ? await updateBillingContact(
          {...billingContactBody, id: editingBillingContact.id},
          { 
            ...clientHasBillingContactBody,
            billingContactId: clientHasBillingContact.billingContactId,
            id: clientHasBillingContact.id,
          },
        )
        : null
    );
    const shouldCreateBillingContact = !hasErrors && !clientHasBillingContactUpdated;
    const createdBillingContactResponse = (
      shouldCreateBillingContact
        ? createNewBillingContact(
          billingContactBody,
          clientHasBillingContactBody,
        )
        :null
    );
    return clientHasBillingContactUpdated ?? createdBillingContactResponse;

  };

  const handleNoteAdd = (internalNotes: TBillingContactInternalNote[]) => {
    setInternalNotes(internalNotes);
  };

  const handleExtraInfoChanged = (ownershipGroupId: Nullable<number>, receivesInvoices: number) => {
    setOwnershipGroupId(ownershipGroupId);
    setReceivedInvoices(receivesInvoices);
  };

  const modalTitle = useMemo(() => (
    clientHasBillingContact ? 'Edit' : 'Create'
  ), [clientHasBillingContact]);

  return (
    <Dialog 
      id="modal-billing-contact"
      open={showModal} 
      aria-labelledby="form-billing-contact" 
      scroll="paper" 
      maxWidth='md' 
      onClose={handleClose}>
      <DialogTitle id="form-billing-contact">{modalTitle} a Billing Contact</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={1} justifyContent="center">
          <form onSubmit={(e) => e.preventDefault()}>
            <Grid container spacing={2} justifyContent="center" alignItems="center" my={0.5} >
              <Grid item xs={2}>
                Contact Name:
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Contact Name"
                  size='small'
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  error={checkErrorExists('name')}
                  helperText={getErrorMessage('name')}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                Contact Email:
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Contact Email"
                  type="email"
                  size='small'
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  error={checkErrorExists('email')}
                  helperText={getErrorMessage('email')}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                Primary Phone#:
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Primary Phone#"
                  type="tel"
                  size='small'
                  onChange={(e) => setPrimaryPhone(sanitizeTelInput(e.target.value))}
                  value={primaryPhone}
                  error={checkErrorExists('primaryPhone')}
                  helperText={getErrorMessage('primaryPhone')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                Secondary Phone#:
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Secondary Phone#"
                  type="tel"
                  size='small'
                  onChange={(e) => setSecondaryPhone(sanitizeTelInput(e.target.value))}
                  value={secondaryPhone}
                  error={checkErrorExists('secondaryPhone')}
                  helperText={getErrorMessage('secondaryPhone')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                Address:
              </Grid>
              <Grid item xs={10}>
                <TextField
                  label="Address"
                  size='small'
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                  error={checkErrorExists('address')}
                  helperText={getErrorMessage('address')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                City:
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="City"
                  size='small'
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                Province:
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Province"
                  size='small'
                  onChange={(e) => setProvince(e.target.value)}
                  value={province}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                Postal Code:
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Postal Code"
                  size='small'
                  onChange={(e) => setPostalCode(e.target.value)}
                  value={postalCode}
                  error={checkErrorExists('postalCode')}
                  helperText={getErrorMessage('postalCode')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                Country:
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Country"
                  size='small'
                  onChange={(e) => setCountry(e.target.value)}
                  value={country}
                  fullWidth
                />
              </Grid>
            </Grid>
          </form>
          <Grid item xs={12}>
            <Paper elevation={3} sx={{padding: '0.25rem 1rem 1rem 1rem'}}>
              <AssignExtraInfoToBillingContact
                billingAccountId={billingAccountId}
                showSaveButton={false}
                handleActionButtonClick={handleExtraInfoChanged}
                defaultOwnershipGroupId={ownershipGroupId}
                defaultReceivesInvoices={receivesInvoices}
              />
            </Paper>
          </Grid> 
    
          <Grid item xs={12}>
            <FormControl>
              <FormControlLabel 
                label="Show Internal Notes"
                control={
                  <Switch
                    value={showInternalNotes}
                    onChange={(e) => setShowInternalNotes(e.target.checked)}
                  />
                }
              />
            </FormControl>
          </Grid>
          { 
            showInternalNotes 
            && <Grid item xs={12}>
              <InternalNotesBox internalNotes={internalNotes} onNoteAdd={handleNoteAdd}/>
            </Grid> 
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
            Close
        </Button>
        <Button variant='contained' onClick={handleSubmit} color="primary">
            Save
        </Button>
      </DialogActions>
    </Dialog>

  );
};
  