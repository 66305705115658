import {
  floatVal, intVal, isNumeric, last, Nullable, removeAt,
} from "@jamesgmarks/utilities";
import { IVariableBasePrices } from "@llws/lift-entity-interfaces";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  isMinDurationBillingType,
  isVariableBasePriceBillingType,
} from "@llws/hydra-shared";
import {
  Delete,
} from "@mui/icons-material";
import { showMessage } from "src/redux/features/messaging/actions";

const VariableBasePriceEditRow = ({
  vbp,
  startRange,
  isLastRow,
  hasMinDurationFeature,
  onChange,
  requestDelete,
}: {
  vbp: Partial<IVariableBasePrices>,
  startRange: number,
  isLastRow: boolean,
  hasMinDurationFeature: boolean,
  onChange: ((newVbp: Partial<IVariableBasePrices>) => void),
  requestDelete: () => void,
}) => {
  const [ maxValue, setMaxValue ] = useState<Nullable<string>>(
    (vbp.maxValue ? intVal(vbp.maxValue).toString() :  null),
  );
  const [ basePrice, setBasePrice ] = useState<string>(floatVal(vbp.basePrice ?? 0).toFixed(4));
  const [ costPerUnit, setCostPerUnit ] = useState<string>(floatVal(vbp.costPerUnit ?? 0).toFixed(4));
  const [ minDuration, setMinDuration ] = useState<Nullable<string>>(
    hasMinDurationFeature ? intVal(vbp.minDuration ?? 0).toString() : null,
  );

  useEffect(() => {
    setMaxValue(
      vbp.maxValue
        ? intVal(vbp.maxValue ?? 0).toString()
        : null,
    );
  }, [ vbp, setMaxValue ]);

  const maxValueDisplay = useMemo(() => (isLastRow ? 'xxx' : (
    maxValue ?? 'xxx'
  )), [isLastRow, maxValue]);

  const updateRow = useCallback(() => {
    onChange({
      ...vbp,
      maxValue: maxValue ? intVal(maxValue) : null,
      basePrice: floatVal(basePrice),
      costPerUnit: floatVal(costPerUnit),
      minDuration: (hasMinDurationFeature ? intVal(minDuration) : null),
    });
  }, [ vbp, maxValue, basePrice, costPerUnit, minDuration, onChange, hasMinDurationFeature ]);

  return (
    <TableRow>
      <TableCell>
        {startRange} to&nbsp;
        <TextField
          size='small'
          value={maxValueDisplay}
          disabled={isLastRow}
          onChange={(e) => {
            setMaxValue(e.currentTarget.value || '0');
          }}
          onBlur={updateRow}
        />
        {
          (!isLastRow && (!isNumeric(maxValue) || intVal(maxValue) < 0))
          && <div style={{ color: 'red' }}>Max Value must be 0 or greater.</div>
        }
      </TableCell>
      <TableCell>
        <TextField
          size='small'
          value={basePrice}
          onChange={(e) => {
            setBasePrice(e.currentTarget.value || '0');
          }}
          onBlur={updateRow}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </TableCell>
      <TableCell>
        <TextField
          size='small'
          value={costPerUnit}
          onChange={(e) => {
            setCostPerUnit(e.currentTarget.value || '0');
          }}
          onBlur={updateRow}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </TableCell>
      { hasMinDurationFeature && <TableCell>
        <TextField
          size='small'
          value={minDuration}
          onChange={(e) => {
            setMinDuration(e.currentTarget.value || '0');
          }}
          onBlur={updateRow}
        />
      </TableCell>}
      <TableCell>
        {
          isLastRow
          && <IconButton onClick={() => requestDelete()}><Delete /></IconButton>
        }
      </TableCell>
    </TableRow>
  );
};

const VariableBasePriceReadOnlyRow = ({
  vbp,
  startRange,
  isLastRow,
  hasMinDurationFeature,
}: {
  vbp: Partial<IVariableBasePrices>,
  startRange: number,
  isLastRow: boolean,
  hasMinDurationFeature: boolean,
}) => {
  if (!isLastRow && !isNumeric(vbp.maxValue)) {
    showMessage({ severity: 'error', message: 'Max Value empty for this row' });
    return <>ERROR: Max Value empty for this row</>;
  }
  return (
    <TableRow>
      <TableCell>
        {startRange} to { isLastRow ? <>&infin;</> : intVal(vbp.maxValue) }
      </TableCell>
      <TableCell>
        ${floatVal(vbp.basePrice ?? 0).toFixed(4)}
      </TableCell>
      <TableCell>
        ${floatVal(vbp.costPerUnit ?? 0).toFixed(4)}
      </TableCell>
      {
        hasMinDurationFeature
        && <TableCell> {intVal(vbp.minDuration ?? 0).toString()} </TableCell>
      }
      <TableCell>

      </TableCell>
    </TableRow>
  );
};

const getNewVbpLine = () => ({
  maxValue: null,
  basePrice: 0,
  costPerUnit: 0,
  minDuration: 0,
});

export const VariableBasePrices = ({
  billingTypeId,
  variableBasePrices,
  saveVariableBasePrices,
  isNewBaseSubscription,
  onEditingStateChanged,
}: {
  billingTypeId: number | null,
  variableBasePrices: (Partial<IVariableBasePrices>)[];
  saveVariableBasePrices: (newPrices: Partial<IVariableBasePrices>[]) => void;
  isNewBaseSubscription: boolean;
  onEditingStateChanged: (value: boolean) => void;
}) => {
  const [ editableVbps, setEditableVbps ] = useState<Partial<IVariableBasePrices>[]>(
    isNewBaseSubscription ? [ getNewVbpLine() ] : variableBasePrices,
  );

  const visibleVbps = useMemo(() => editableVbps ?? [], [ editableVbps ]);

  const [ editingVbps, setEditingVbps ] = useState<boolean>(isNewBaseSubscription);

  useEffect(() => {
    onEditingStateChanged(editingVbps);
  }, [ editingVbps, onEditingStateChanged]);

  const editorControls = useMemo(() => (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          saveVariableBasePrices(
            (editableVbps.length === 0)
              ? []
              : [
                ...editableVbps.slice(0, -1),
                {
                  ...last(editableVbps),
                  maxValue: null,
                },
              ],
          );
          setEditingVbps(false);
        }}
      >Update</Button>
      <Button variant="outlined" color="error" onClick={() => setEditingVbps(false)}>Cancel</Button>
    </>
  ), [ editableVbps, saveVariableBasePrices ]);

  if (billingTypeId === null || !isVariableBasePriceBillingType(billingTypeId)) { return <></>; }

  const addNewVbpLine = () => {
    const lastMax = intVal(editableVbps.at(-2)?.maxValue ?? 0) + 1;

    const updatedLast = {
      ...editableVbps.at(-1),
      maxValue: lastMax,
    };

    const newEditables = [
      ...editableVbps.slice(0, -1),
      updatedLast,
      getNewVbpLine(),
    ];

    setEditableVbps(newEditables);
  };

  return (
    <Grid item xs={12}>
      <b>Variable Prices:</b>
      <div><small>Requires at least one row.</small></div>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Range</TableCell>
              <TableCell>Base Price</TableCell>
              <TableCell>Cost Per Item</TableCell>
              {isMinDurationBillingType(billingTypeId) ? <TableCell>Min Duration</TableCell> : <></>}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleVbps.map((vbp, index) => (
              editingVbps
                ? <VariableBasePriceEditRow
                  key={vbp.id ?? `new-${index}`}
                  vbp={vbp}
                  startRange={( index === 0 ? 0 : intVal(editableVbps[index - 1]?.maxValue ?? 0) + 1 )}
                  isLastRow={index === editableVbps.length - 1}
                  hasMinDurationFeature={isMinDurationBillingType(billingTypeId)}
                  onChange={(newVbp) => {
                    editableVbps[index] = newVbp; // Could not get this to work without mutation.
                    setEditableVbps(editableVbps);
                  }}
                  requestDelete={() => {
                    setEditableVbps(removeAt(editableVbps, index));
                  }}
                />
                : <VariableBasePriceReadOnlyRow
                  key={vbp.id ?? `new-${index}`}
                  vbp={vbp}
                  startRange={( index === 0 ? 0 : intVal(editableVbps[index - 1]?.maxValue ?? 0) + 1 )}
                  isLastRow={index === editableVbps.length - 1}
                  hasMinDurationFeature={isMinDurationBillingType(billingTypeId)}
                />
            )) ?? <>No prices</>}
            <TableRow>
              {
                editingVbps
                  ? <>
                    <TableCell colSpan={2}>
                      <Button variant="outlined" color="success" onClick={addNewVbpLine}>&#x2b; Add Range</Button>
                    </TableCell>
                    <TableCell colSpan={2} align="right">
                      { editorControls }
                    </TableCell>
                  </>
                  : <TableCell colSpan={4}>
                    <Button variant="outlined" color="primary" onClick={() => setEditingVbps(true)}>Edit</Button>
                  </TableCell>
              }
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};
