import { createSlice } from '@reduxjs/toolkit';
import { Hash, Nullable } from '@jamesgmarks/utilities';
import { ICreditNote } from '../../../interfaces/ICreditNote';
import { IActionType, IApiQueryListResponse, IApiQuerySingularResponse } from '../../utils';
import { PdfGeneration } from '../../../../../entities/hydra';

export interface ICreditNotesState {
  loadedState: string,
  creditNotes: ICreditNote[],
  currentCreditNote?: ICreditNote | null,
  nextPage: Nullable<string>,
  filters: Hash,
};

export const creditNotesSlice = createSlice({
  name: 'creditNotes',
  initialState: {
    loadedState: 'idle',
    creditNotes: [],
    currentCreditNote: null,
    nextPage: null,
    filters: {},
  } as ICreditNotesState,
  reducers: {
    creditNotesListReceived: (state, action: { type: string, payload: IApiQueryListResponse<ICreditNote> }) => {
      state.creditNotes = action.payload.data;
      state.nextPage = action.payload.meta?.links?.next;
      state.loadedState = 'loaded';
    },
    setLoadedState: (state, action: { type: string, payload: string }) => {
      state.loadedState = action.payload;
    },
    currentCreditNoteReceived: (
      state,
      action: { type: string, payload: IApiQuerySingularResponse<ICreditNote> },
    ) => {
      state.currentCreditNote = action.payload.data;
      state.loadedState = 'loaded';
    },
    currentCreditNoteCleared: (state, action: { type: string, payload: void }) => {
      state.currentCreditNote = null;
    },
    currentCreditNotePdfGenerated: (state, { payload }: IActionType<PdfGeneration>) => {
      if (!state.currentCreditNote) {
        console.warn('There is not currently a loaded credit note.');
        return;
      }

      state.currentCreditNote = (
        {
          ...state.currentCreditNote,
          pdfGenerations: [
            ...state.currentCreditNote.pdfGenerations,
            payload,
          ],
        }
      );
    },
    creditNoteUpdated: (state, { payload }: IActionType<ICreditNote>) => {
      const current = (state.creditNotes ?? []).find((creditNote) => creditNote.id === payload.id);

      if (!current) {
        console.info('Credit note not found: ', { payload });
      }

      state.creditNotes = [
        ...(state.creditNotes ?? []).filter((creditNote) => !current || creditNote.id !== current.id),
        payload,
      ];

      if (state.currentCreditNote?.id === payload.id) {
        state.currentCreditNote = payload;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  creditNoteUpdated,
  creditNotesListReceived,
  currentCreditNoteCleared,
  currentCreditNotePdfGenerated,
  currentCreditNoteReceived,
  setLoadedState,
} = creditNotesSlice.actions;

export default creditNotesSlice.reducer;
