import React from 'react';
import { useAuth } from '../../customHooks/useAuth';

export interface RequirePermissionProps {
  grant: string,
  noPermissionContent?: React.ReactNode,
  children: React.ReactNode | React.ReactNode[],
}

export const RequirePermission = ({
  grant,
  noPermissionContent,
  children,
}: RequirePermissionProps) => {
  const { hasPermission } = useAuth();

  return <>
    {
      hasPermission(grant) || hasPermission('FULL_ACCESS')
        ? <>{children}</>
        : noPermissionContent
    }
  </>;
};