import { floatVal } from "@jamesgmarks/utilities";
import { TTableData } from ".";

import { CreditNote } from "../../../../../../entities/hydra";
import { dateFromYmd, makeYmd, toDollarAmount } from "../../../../app-utils";

export const creditNoteListFieldGetters: TTableData<CreditNote>[] = [
  { name: 'Number', getter: 'noteNumber' },
  {
    name: 'Client', getter: (cn) => (
      <>
        {
          (cn.billingAccount?.clients ?? []).length === 1
            && (cn.billingAccount?.clients ?? []).map(c => c.clientName).join(', ')
        }
        {
          (cn.billingAccount?.clients ?? []).length > 1
            && (
              <>
                {(cn.billingAccount?.clients ?? []).filter(c => c.isParentClient).map(c => c.clientName).join(', ')}
                {` (${(cn.billingAccount?.clients ?? []).length} total clients)`}
              </>
            )
        }
        {
          (cn.billingAccount?.partners ?? []).length === 1
            && (cn.billingAccount?.partners ?? []).map(p => `${p.partnerName}`).join(', ')
        }
        {(cn.billingAccount?.partners ?? []).length > 1 && 'Multiple Partners'}
      </>
    ),
  },
  {
    name: 'Note Date',
    getter: (cn) => makeYmd(dateFromYmd((cn.noteDate as unknown as string).split('T')[0]), true),
  },
  { name: 'Subtotal', getter: (cn) => toDollarAmount(floatVal(cn.subtotalAmount)) },
  { name: 'State', getter: 'state' },
  { name: 'Void State', getter: 'voidState' },
];
