import React, { useEffect, useState, useMemo } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import {
  Button,
  Card,
  CardContent,
  Grid,
  TablePagination,
  useTheme,
} from "@mui/material";
import { floatVal, intVal } from '@jamesgmarks/utilities';
import { useParams } from 'react-router-dom';
import { toDollarAmount } from '../../../app-utils';
import { showSortIconIfAvailable } from '../../../app-utils/helpers';
import CustomDataTable from '../../parts/customDataTable/CustomDataTable';
import { rowsPerPageList } from './Tabs';
import { getMany } from '../../../redux/features/dynamic/actions';
import { Equal, IQueryCommandOptions } from '@llws/dynamic-router-utils';
import { ISubscriptions } from '@llws/lift-entity-interfaces';

export const SubscriptionsTab = () => {

  const subscriptions = useAppSelector(state => state.dynamic.data.subscriptions?.list ?? []);
  const loadedState = useAppSelector(state => state.dynamic.data.subscriptions?.loadedState);
  const metaData = useAppSelector(state => state.dynamic.metaData);
  
  const accountId = intVal(useParams<{ billingAccountId?: string }>().billingAccountId);

  const [ sortColumn, setSortColumn ] = useState('id');
  const [ sortDirection, setSortDirection ] = useState<`ASC` | `DESC`>(`ASC`);

  const [ page, setPage ] = useState<number>(1);
  const [ limit, setLimit ] = useState<number>(20);

  const queryCommand: IQueryCommandOptions = useMemo(() => ({
    alias: 's',
    relations: [
      { path: 's.client', alias: 'c' },
      { path: 's.baseSubscription', alias: 'bs' },
      { path: 'bs.service', alias: 'svc' },
      { path: 'bs.partner', alias: 'p' },
      { path: 's.billingFrequency', alias: 'fq' },
    ],
    where: [Equal('c.freshbooksClientId', accountId)],
    page,
    limit,
    orderBy: [{field: sortColumn, direction: (sortDirection === `ASC` ? `asc` : `desc`)}],
  }), [accountId, page, limit, sortColumn, sortDirection]); 
  
  useEffect(() => {
    getMany('subscriptions', queryCommand);
  }, [queryCommand]);

  const sortBy = (criteria: string) => {
    setSortDirection(sortColumn === criteria && sortDirection === 'ASC' ? 'DESC' : 'ASC');
    setSortColumn(criteria);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLimit(intVal(event.target.value));
    setPage(1);
  };

  const customColumnsStyle = {
    width: '100%',
    backgroundColor: 'black',
    marginBottom: 1,
    fontWeight: 'bold',
    fontSize: '0.875rem',
    color: 'white',
  };
  const CustomColumns = () => {
    return (
      <>
        <Card sx={{ ...customColumnsStyle }}>
          <CardContent>
            <Grid container direction='row'>
              <Grid item xs sx={{cursor: 'pointer'}} onClick={() => sortBy('id')}>
                Subscription ID {showSortIconIfAvailable('id', sortColumn, sortDirection)}
              </Grid>
              <Grid item xs sx={{cursor: 'pointer'}} onClick={() => sortBy('bs.invoiceDescription')}>
                Base Subscription {showSortIconIfAvailable('bs.invoiceDescription', sortColumn, sortDirection)}
              </Grid>
              <Grid item xs sx={{cursor: 'pointer'}} onClick={() => sortBy('svc.name')}>
                Service {showSortIconIfAvailable('svc.name', sortColumn, sortDirection)}
              </Grid>
              <Grid item xs sx={{cursor: 'pointer'}} onClick={() => sortBy('svc.invoiceItem')}>
                Item {showSortIconIfAvailable('svc.invoiceItem', sortColumn, sortDirection)}
              </Grid>              
              <Grid item xs sx={{cursor: 'pointer'}} onClick={() => sortBy('fq.name')}>
                Billing Freq. {showSortIconIfAvailable('fq.name', sortColumn, sortDirection)}
              </Grid>
              <Grid item xs sx={{cursor: 'pointer'}} onClick={() => sortBy('startDate')}>
                Start Date {showSortIconIfAvailable('startDate', sortColumn, sortDirection)}
              </Grid>
              <Grid item xs sx={{cursor: 'pointer'}} onClick={() => sortBy('expiryDate')}>
                Expiry Date {showSortIconIfAvailable('expiryDate', sortColumn, sortDirection)}
              </Grid>
              <Grid item xs sx={{cursor: 'pointer'}} onClick={() => sortBy('partnerCut')}>
                Partner Cut {showSortIconIfAvailable('partnerCut', sortColumn, sortDirection)}
              </Grid>
              <Grid item xs sx={{cursor: 'pointer'}} onClick={() => sortBy('discountRate')}>
                Discount Rate {showSortIconIfAvailable('discountRate', sortColumn, sortDirection)}
              </Grid>
              <Grid item xs sx={{cursor: 'pointer'}} onClick={() => sortBy('basePrice')}>
                Base Price {showSortIconIfAvailable('basePrice', sortColumn, sortDirection)}
              </Grid>
              <Grid item xs>
                &nbsp;
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  };

  const customRowStyle = {
    width: '100%',
    marginBottom: 1,
    fontSize: '0.875rem',
    backgroundColor: 'gray',
    color: 'white',
  };
  const CustomRow = ({ item }: { item: ISubscriptions }) => {
    const [viewButtonColor, setViewButtonColor] = useState<'primary' | 'secondary' | 'info'>('primary');
    const theme = useTheme();
    return (
      <>
        <Card 
          sx={{ ...customRowStyle }}
          onMouseOver={() => setViewButtonColor('secondary')}
          onMouseLeave={() => setViewButtonColor('primary')}
        >
          <CardContent>
            <Grid container direction='row' sx={{ marginBottom: 1}}>
              <Grid item xs>
                {item.id}
              </Grid>
              <Grid item xs>
                {item.baseSubscription?.invoiceDescription} - {item.baseSubscription?.partner?.name}
              </Grid>
              <Grid item xs>
                {item.baseSubscription?.service?.name}
              </Grid>
              <Grid item xs>
                {item.baseSubscription?.service?.invoiceItem ?? item.baseSubscription?.partner?.name}
              </Grid>
              <Grid item xs>
                {item.billingFrequency?.name}
              </Grid>
              <Grid item xs>
                {item.startDate}
              </Grid>
              <Grid item xs>
                {item.expiryDate ?? '--'}
              </Grid>
              <Grid item xs>
                {floatVal(item.partnerCut) * 100}%
              </Grid>
              <Grid item xs>
                {`${floatVal(item.discountRate) * 100}%`}
              </Grid>
              <Grid item xs>
                {
                  item.basePrice
                    ? toDollarAmount(floatVal(item.basePrice))
                    : `--`
                }
              </Grid>
              <Grid item xs>
                <Button
                  href={`https://superlift.theliftsystem.com/subscriptions/view/${item.id}`}
                  sx={{'&:hover': {color: theme.palette.common.white}}}
                  target='_blank'
                  variant='contained'
                  color={viewButtonColor}
                  size='small'
                >
                  View
                </Button>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs>
                <em>{item.invoiceDescription}</em>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  };

  return (
    <>
      <TablePagination
        component="div"
        sx={{'& p': {marginTop: '1rem'}, backgroundColor: 'white'}}
        count={metaData?.totalRecords ?? 0} 
        page={metaData && metaData.currentPage ? metaData.currentPage - 1 : 0}
        rowsPerPage={limit}
        showFirstButton
        showLastButton
        rowsPerPageOptions={rowsPerPageList}
        onPageChange={(e, newPage)=>{setPage(newPage + 1);}}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
      <CustomDataTable
        data={
          (loadedState === 'loading' 
            ? [] 
            : (subscriptions ?? []))
        }
        rowComponent={CustomRow}
        columnsComponent={CustomColumns}
        loadingState={loadedState}
        dark={true}
        spacing={0}
      />
    </>
  );
};