
export const getFreshbooksUrlForClient = (freshbooksClientId: string) => {
  // console.log({ env: process.env })
  return `${process.env.REACT_APP_FRESHBOOKS_ROOT_URI}/client/${freshbooksClientId}`;
};

export const FreshbooksClientLink = ({
  freshbooksClientId,
  label,
  includeCopyButton,
}: { freshbooksClientId: number, label: string, includeCopyButton?: boolean  }) => {
  return (
    <>
      <a href={getFreshbooksUrlForClient(`${freshbooksClientId}`)} target="_blank" rel="noreferrer">
        {label}
        <img src='/freshbooks.ico' width='16px' height='16px' alt='Open Freshbooks Invoice' />
      </a>
      { includeCopyButton && <button onClick={() => navigator.clipboard.writeText(`${freshbooksClientId}`)}>C</button> }
    </>
  );
};