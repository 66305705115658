import { configureStore } from '@reduxjs/toolkit';
import apiValidatorsSlice from './features/api-validators/apiValidatorsSlice';
import authSlice from './features/auth/authSlice';
import accountsSlice from './features/accounts/accountsSlice';
import billingRunSlice from './features/billing-runs/billingRunSlice';
import chargesSlice from './features/charges/chargesSlice';
import clientsSlice from './features/clients/clientsSlice';
import commissionsSlice from './features/commissions/commissionsSlice';
import creditsSlice from './features/credits/creditsSlice';
import creditNotesSlice from './features/credit-notes/creditNotesSlice';
import dataIntegrityChecksSlice from './features/data-integrity-checks/dataIntegrityChecksSlice';
import documentsSlice from './features/documents/documentsSlice';
import dynamicHydraSlice from './features/dynamic-hydra/dynamicHydraSlice';
import dynamicSlice from './features/dynamic/dynamicSlice';
import pdfGenerationsSlice from './features/pdf-generations/pdfGenerationsSlice';
import hubspotSlice from './features/hubspot/hubspotSlice';
import invoicesSlice from './features/invoices/invoiceSlice';
import lookupsSlice from './features/lookups/lookupsSlice';
import messagingSlice from './features/messaging/messagingSlice';
import ownershipGroupsSlice from './features/ownershipGroups/ownershipGroupsSlice';
import partnersSlice from './features/partners/partnersSlice';
import productsSlice from './features/products/productsSlice';
import realtimeSlice from './features/realtime/realtimeSlice';
import reportingSlice from './features/reporting/reportingSlice';
import sandboxSlice from './features/sandbox/sandboxSlice';
import subscriptionsSlice from './features/subscriptions/subscriptionsSlice';
import systemInfoSlice from './features/systemInfo/systemInfoSlice';
import promotedListingsSlice from './features/promotedListings/promotedListingsSlice';
import xeroSlice from './features/xero/xeroSlice';
import billingContactsSlice from './features/billing-contacts/billingContactsSlice';
import statementsSlice from './features/statements/statementsSlice';
import chatBotSlice from './features/chat-bot/chatBotSlice';
// ...

export const store = configureStore({
  reducer: {
    accounts: accountsSlice,
    apiValidators: apiValidatorsSlice,
    auth: authSlice,
    billingContacts: billingContactsSlice,
    billingRuns: billingRunSlice,
    charges: chargesSlice,
    chatBot: chatBotSlice,
    clients: clientsSlice,
    commissions: commissionsSlice,
    credits: creditsSlice,
    creditNotes: creditNotesSlice,
    dataIntegrityChecks: dataIntegrityChecksSlice,
    documents: documentsSlice,
    dynamic: dynamicSlice,
    dynamicHydra: dynamicHydraSlice,
    pdfGenerations: pdfGenerationsSlice,
    promotedListings: promotedListingsSlice,
    hubspot: hubspotSlice,
    invoices: invoicesSlice,
    lookups: lookupsSlice,
    messaging: messagingSlice,
    ownershipGroups: ownershipGroupsSlice,
    partners: partnersSlice,
    products: productsSlice,
    realtime: realtimeSlice,
    reporting: reportingSlice,
    sandboxes: sandboxSlice,
    subscriptions: subscriptionsSlice,
    statements: statementsSlice,
    systemInfo: systemInfoSlice,
    xero: xeroSlice,
  },
});

export const dispatch = store.dispatch;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
