import { useEffect, useState } from "react";
import { Grid, Paper, TextField } from "@mui/material";

import { loadReport } from "../../../../redux/features/reporting/actions";
import { MonthPicker } from "../../../parts/MonthPicker";
import { roundFloatToCents } from "../../../../app-utils";
import { useAppSelector } from "../../../../redux/hooks";

export const PadmapperMonthlySummary = () => {
  const padmapperRevenueSummary = useAppSelector(state => state.reporting.padmapperRevenueSummary);

  const [ monthDate, setMonthDate ] = useState(new Date());

  useEffect(() => {
    const year = monthDate.getFullYear();
    const month = monthDate.getMonth() + 1;

    loadReport('padmapper_revenue_summary', { year, month });
  }, [ monthDate ]);

  const getTextForCurrency = (currency: 'CAD' | 'USD') => {
    const currencyData = padmapperRevenueSummary?.[currency] ?? null;
    const text = (
      currencyData
        ? `Total: ${roundFloatToCents(currencyData.totalSpendBeforeCredits ?? 0)}\n`
          + `Credits: (${roundFloatToCents(Math.abs(currencyData.totalCredits ?? 0))})\n\n`

          + `Total After Credits: ${roundFloatToCents(
            (currencyData.totalSpendBeforeCredits ?? 0)
            + (currencyData.totalCredits ?? 0),
          )}\n\n`

          + `Zumper Share: ${roundFloatToCents(currencyData.totalPartnerShare ?? 0)}`
        : ''
    );
    return text;
  };

  const CADText = getTextForCurrency('CAD');
  const USDText = getTextForCurrency('USD');

  return (
    <>
      <h2>Padmapper monthly report</h2>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={10} component={Paper}>
          <Grid container>
            <Grid item xs={12}>
              <MonthPicker
                label='Month'
                value={monthDate}
                setValue={(d) => (d ? setMonthDate(d) : null)}
              />
            </Grid>
            <Grid item xs={12}>
              <h5>CAD:</h5>
              <TextField
                multiline
                value={CADText}
              />
            </Grid>
            <Grid item xs={12}>
              <h5>USD:</h5>
              <TextField
                multiline
                value={USDText}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};