import { createSlice } from '@reduxjs/toolkit';
import { IActionType } from '../../utils';
import { IDocumentMap } from './interfaces';

export enum EDocumentsLoadedState {
  idle = 'idle',
  loading = 'loading',
  loaded = 'loaded',
}

interface IDocumentsState {
  documents: IDocumentMap;
  loadedState: EDocumentsLoadedState;
}

const documentsSlice = createSlice({
  name: 'documents',
  initialState: {
    documents: {},
    loadedState: EDocumentsLoadedState.idle,
  } as IDocumentsState,
  reducers: {
    loadReceivedDocuments: (state, { payload }: IActionType<IDocumentMap>) => {
      state.documents = payload;
    },
    setLoadedState: (state, { payload }: IActionType<EDocumentsLoadedState>) => {
      state.loadedState = payload;
    },
  },
});

export const {
  loadReceivedDocuments,
  setLoadedState,
} = documentsSlice.actions;

export default documentsSlice.reducer;
