import { Dispatch, SetStateAction, useState } from 'react';

import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type SelectOption = {
  label: string
  value: string
};

interface MultipleSelectCheckmarksProps {
  options: SelectOption[],
  inputLabelText: string,
  setSelectedValues: Dispatch<SetStateAction<string[]>>,
}

export default function MultipleSelectCheckmarks({
  options,
  inputLabelText,
  setSelectedValues,
}: MultipleSelectCheckmarksProps) {
  const [ selected, setSelected ] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof selected>) => {
    const {
      target: { value },
    } = event;

    const newSelectedValues = value[value.length - 1] === 'all'
      ? (selected.length === options.length ? [] : options.map((o) => o.value))
      : (typeof value === 'string' ? value.split(',') : value);

    setSelected(newSelectedValues);
    setSelectedValues(newSelectedValues);
  };

  return (
    <div>
      <FormControl sx={{ width: '100%', maxWidth: 1000 }}>
        <InputLabel id='select-invoices'>{inputLabelText}</InputLabel>

        <Select
          id='select-multiple-invoices'
          input={<OutlinedInput label='Invoices' />}
          labelId='select-invoices'
          MenuProps={MenuProps}
          multiple
          onChange={handleChange}
          renderValue={(selected: string[]) => options
            .filter((o) => selected.includes(o.value))
            .map((o) => o.label)
            .join(', ')
          }
          size='small'
          sx={{ backgroundColor: 'white', paddingTop: '8px' }}
          value={selected}
          variant='outlined'
        >
          <MenuItem value="all">
            <ListItemIcon>
              <Checkbox
                checked={options.length > 0 && selected.length === options.length}
                indeterminate={selected.length > 0 && selected.length < options.length}
              />
            </ListItemIcon>
            
            <ListItemText primary="Select All" />
          </MenuItem>

          {options.map(({ label, value }) => (
            <MenuItem key={value} value={value} sx={{ height: '35px' }}>
              <Checkbox sx={{ mb: 0.5, mr: 1.5 }} checked={selected.indexOf(value) > -1} />
              <ListItemText primary={label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
