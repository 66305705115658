import { OwnershipGroups } from "@llws/typeorm-entities";
import { Statement } from "../../../entities/hydra";

export interface IStatement extends Statement {
  ownershipGroup: OwnershipGroups
}

// # Duplicate type
export enum EStatementGenerationType {
  client = 'client',
  group = 'group',
  monthly = 'monthly',
  manual = 'manual',
  manualFull = 'manual_full',
  manualOg = 'manual_og'
}