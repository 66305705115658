import { useCallback, useEffect, useMemo, useState } from 'react';
import { 
  Autocomplete, 
  TextField,
  Typography,
} from '@mui/material';
import { Nullable } from '@jamesgmarks/utilities';
import { useAppSelector } from '../../redux/hooks';
import { getMany } from '../../redux/features/dynamic/actions';
import { Equal, IQueryCommandOptions, In, Not } from '@llws/dynamic-router-utils';
import { IBuildings } from '@llws/lift-entity-interfaces';

type TBuildingDropdownProps = {
  accountId: number,
  onBuildingChanged: (building: { buildingName: string, id: number }) => void,
  numberOfBuildingChanges?: number,
  buildingStatuses?: ('enabled' | 'hidden' | 'disabled' | 'deleted')[]
  excludeStatus?: boolean
}

export const BuildingDropdown = (
  {
    accountId,
    onBuildingChanged,
    numberOfBuildingChanges,
    buildingStatuses = ['enabled'],
    excludeStatus = false,
  }: TBuildingDropdownProps,
) => {

  const buildings = useAppSelector(
    state => state.dynamic.data.buildings?.list ?? [],
  );
  const queryCommand: IQueryCommandOptions = useMemo(() => ({
    alias: 'b',
    relations: [
      { path: 'b.client', alias: 'c'},
      { path: 'b.ownershipGroupHasBuildings', alias: 'oghb' },
    ],
    select: ['b.id', 'b.buildingName'],
    where: [
      Equal('c.freshbooksClientId', accountId), 
      excludeStatus ? Not(In('b.status', buildingStatuses)) : In('b.status', buildingStatuses),
    ],
    limit: 1000,
  }), [accountId, excludeStatus, buildingStatuses]);
  
  useEffect(() => {
    getMany('buildings', queryCommand);
  }, [queryCommand, numberOfBuildingChanges]);

  const options = buildings.filter(
    (b: IBuildings) => b.ownershipGroupHasBuildings === null,
  ).map((b) => {
    return {buildingName: b.buildingName, id: b.id};
  });
    
  const [ buildingQuery, setBuildingQuery ] = useState<Nullable<string>>(null);
  const [selectedBuilding, setSelectedBuilding ] = useState(null);
  
  const handleInputChange = (buildingSearch: Nullable<string>) => {
    setBuildingQuery(buildingSearch);
  };
  
  const handleBuildingSelected = useCallback(
    (building) => {
      if (building) {
        setSelectedBuilding(building);
        onBuildingChanged(building);
      }
    },
    [ onBuildingChanged ],
  );

  return (
    <Autocomplete
      key={numberOfBuildingChanges}
      id="building-selector"
      fullWidth
      clearOnBlur={true}
      isOptionEqualToValue={(option, value) => {
        return (option.buildingName === value.buildingName && option.id === value.id);
      }}
      getOptionLabel={
        (option) => { 
          return `${option.buildingName} (Building ID: ${option.id})`;
        }
      }
      inputValue={buildingQuery ?? ''}
      onChange={
        (_e, newValue: unknown, _reason) => {
          handleBuildingSelected(newValue);
        }
      }
      onInputChange={
        (_e, value: string, _reason) => {
          handleInputChange(value);
        }
      }
      options={options}
      renderInput={
        (params) =>  (
          <TextField
            { ...params }
            label="Building"
            sx={{ backgroundColor: '#fff' }}
            variant="outlined"
          />
        )
      }
      renderOption={
        (props, option) => {
          return (
            <li {...props}>
              <Typography>
                <span style={{ color: 'dodgerblue', fontWeight: 550 }}>{option.buildingName}</span>
                <span style={{ color: 'gray' }}> | </span>
                <span>{`ID: ${option.id}`}</span>
              </Typography>
            </li>
          );
        }
      }
      size="small"
      value={selectedBuilding}
    />
  );
};
