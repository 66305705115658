import { createSlice } from '@reduxjs/toolkit';

import { ELoadState, IActionType, IApiQuerySingularResponse } from '../../utils';

import { ChatCompletionMessageParam } from 'openai/resources';

export interface IChatBotState {
  loadedState: ELoadState,
  messagesFromBot: ChatCompletionMessageParam[],
};

export const chatBotSlice = createSlice({
  name: 'chatBot',
  initialState: {
    loadedState: ELoadState.idle,
    messagesFromBot: [],
  } as IChatBotState,
  reducers: {
    messagesFromBotReceived: (state, action: IActionType<IApiQuerySingularResponse<ChatCompletionMessageParam[]>>) => {
      state.messagesFromBot = action.payload.data;
    },

    setLoadedState: (state, action: IActionType<ELoadState>) => {
      state.loadedState = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  messagesFromBotReceived,
  setLoadedState,
} = chatBotSlice.actions;

export default chatBotSlice.reducer;
