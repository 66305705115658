import {
  Switch,
  Route,
} from "react-router-dom";
import { InvoiceSingleView } from './InvoiceSingleView';
import { InvoiceSingleViewOLD } from './InvoiceSingleViewOLD';

import { InvoicesListView } from "./InvoicesListView";

export interface IBillingRunFilters {
  status?: string,
  details?: string,
}

export enum EUnitOfMeasure { // eslint-disable-line no-shadow
  'calls-received',
  'call-tracking',
  'exempt',
  'leads',
  'lift-base-cost-responsibility',
  'local-line-count',
  'messages-received',
  'multi-family',
  'property-volume',
  'single-family',
  'toll-free-call-tracking',
  'toll-free-line-count',
  // 'call-minutes',
  // 'recording-minutes',
}

export const InvoicesContainer = () => {
  return (
    <div>
      <Switch>
        <Route path="/invoices/old/:invoiceId"><InvoiceSingleViewOLD /></Route>
        <Route path="/invoices/number/:invoiceNumber"><InvoiceSingleView /></Route>
        <Route path="/invoices/:invoiceId"><InvoiceSingleView /></Route>
        <Route path="/invoices"><>
          <header className="Section-header">Invoices</header>
          <InvoicesListView />
        </></Route>
      </Switch>
    </div>
  );
};
