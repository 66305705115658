import { createSlice } from '@reduxjs/toolkit';

import { HashOf } from '@jamesgmarks/utilities';

import {
  IDataIntegrityCheckInfo,
  IDataIntegrityChecksResponse,
  IIgnoredDataIntegrityError,
} from './interfaces';

interface FixedDataIntegrityError {
  handlerName: string;
  objectId: string;
}

export interface IDataIntegrityChecksState {
  fixedErrors: FixedDataIntegrityError[],
  ignoredPostcheckErrors: IIgnoredDataIntegrityError[],
  ignoredPrecheckErrors: IIgnoredDataIntegrityError[],
  postcheckErrors: HashOf<IDataIntegrityCheckInfo>,
  postcheckLoadState: EDataIntegrityChecksLoadedState,
  postcheckNames: string[],
  precheckErrors: HashOf<IDataIntegrityCheckInfo>,
  precheckLoadState: EDataIntegrityChecksLoadedState,
  precheckNames: string[],
};

export enum EDataIntegrityChecksLoadedState {
  idle = 'idle',
  loading = 'loading',
  loaded = 'loaded',
}

export const dataIntegrityChecksSlice = createSlice({
  name: 'data-integrity-checks',
  initialState: {
    fixedErrors: [],
    ignoredPostcheckErrors: [],
    ignoredPrecheckErrors: [],
    postcheckErrors: {},
    postcheckLoadState: EDataIntegrityChecksLoadedState.idle,
    postcheckNames: [],
    precheckErrors: {},
    precheckLoadState: EDataIntegrityChecksLoadedState.idle,
    precheckNames: [],
  } as IDataIntegrityChecksState,
  reducers: {
    dataIntegrityErrorFixed: (state, action: { type: string, payload: FixedDataIntegrityError }) => {
      state.fixedErrors.push(action.payload);
    },
    postcheckErrorListReceived: (state, action: { type: string, payload: IDataIntegrityChecksResponse }) => {
      state.postcheckErrors = action.payload.errorData;
      state.ignoredPostcheckErrors = action.payload.ignoredDataIntegrityErrors;
    },
    postcheckIgnored: (state, action: { type: string, payload: IIgnoredDataIntegrityError }) => {
      state.ignoredPostcheckErrors.push(action.payload);
    },
    postcheckNoLongerIgnored: (state, action: { type: string, payload: number }) => {
      state.ignoredPostcheckErrors = state.ignoredPostcheckErrors
        .filter((i) => i.id !== action.payload);
    },
    preAndPostcheckNamesReceived: (
      state,
      action: { type: string, payload: { precheckNames: string[], postcheckNames: string[] } },
    ) => {
      state.precheckNames = action.payload.precheckNames;
      state.postcheckNames = action.payload.postcheckNames;
    },
    precheckErrorListReceived: (state, action: { type: string, payload: IDataIntegrityChecksResponse }) => {
      state.precheckErrors = action.payload.errorData;
      state.ignoredPrecheckErrors = action.payload.ignoredDataIntegrityErrors;
    },
    precheckIgnored: (state, action: { type: string, payload: IIgnoredDataIntegrityError }) => {
      state.ignoredPrecheckErrors.push(action.payload);
    },
    precheckNoLongerIgnored: (state, action: { type: string, payload: number }) => {
      state.ignoredPrecheckErrors = state.ignoredPrecheckErrors
        .filter((i) => i.id !== action.payload);
    },
    setPostcheckLoadState: (state, action: { type: string, payload: EDataIntegrityChecksLoadedState }) => {
      state.postcheckLoadState = action.payload;
    },
    setPrecheckLoadState: (state, action: { type: string, payload: EDataIntegrityChecksLoadedState }) => {
      state.precheckLoadState = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  dataIntegrityErrorFixed,
  postcheckErrorListReceived,
  postcheckIgnored,
  postcheckNoLongerIgnored,
  preAndPostcheckNamesReceived,
  precheckErrorListReceived,
  precheckIgnored,
  precheckNoLongerIgnored,
  setPostcheckLoadState,
  setPrecheckLoadState,
} = dataIntegrityChecksSlice.actions;

export default dataIntegrityChecksSlice.reducer;
