/**
 * Removes the last character from the string
 * if it isn't allowed in a valid phone number
 * @param input string to be sanitized
 * @returns cleaned up string (only removes the last character)
 */
export const sanitizeTelInput = (input: string) => {
  const telRegex = /^(\+\s?)?(\(?\-?\d\s?\-?\)?\s?)*$/;
  const sanitzedInput = input === '' || telRegex.test(input) ? input : input.slice(0, -1);
  return sanitzedInput;
};