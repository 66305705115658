import { Route, Switch } from "react-router-dom";
import { MobileActions } from "./MobileActions";
import { MobileBillingRuns } from "./MobileBillingRuns";
import { MobileIntegrityChecks } from "./MobileIntegrityChecks";
import { MobileNav } from "./MobileNav";

export const Mobile = () => {
  return (
    <>
      <header className="Section-header">Mobile Control Center</header>

      <Switch>
        <Route path="/mobile/billing-runs"><MobileBillingRuns /></Route>
        <Route path="/mobile/actions"><MobileActions /></Route>
        <Route path="/mobile/integrity-checks"><MobileIntegrityChecks /></Route>
        <Route path="/mobile"><MobileNav /></Route>
      </Switch>
    </>
  );
};
