import { useEffect, useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { arrayWrap, keys, HashOf } from "@jamesgmarks/utilities";

import {
  EIgnoredDataIntegrityErrorType,
} from "../../../../interfaces/EIgnoredDataIntegrityError";
import {
  fixDataIntegrityError,
  ignoreDataIntegrityError,
  unignoreDataIntegrityError,
} from "../../../../redux/features/data-integrity-checks/actions";
import { FixForm } from "./FixForm";
import {
  IDataIntegrityCheckError,
  IDataIntegrityCheckFixData,
} from "../../../../redux/features/data-integrity-checks/interfaces";
import { KeyValueDisplay } from "./KeyValueDisplay";
import { useAppSelector } from "../../../../redux/hooks";
import { useAuth } from "../../../../customHooks/useAuth";

interface ISingleErrorContainerProps {
  dataIntegrityCheckName: string
  error: IDataIntegrityCheckError
  fixOptions: HashOf<Omit<IDataIntegrityCheckFixData, 'fixFn'>> | null
  lastError?: boolean
  type: EIgnoredDataIntegrityErrorType
}

/**
 * Renders the `FixForm` (if fixes exist for the error) and a display of the data
 * integrity error's properties.
 */
export const SingleErrorContainer = (
  {
    dataIntegrityCheckName,
    error,
    fixOptions,
    lastError,
    type,
  }: ISingleErrorContainerProps,
) => {
  const matchingIgnoredDataIntegrityError = useAppSelector(
    (state) => (
      state.dataIntegrityChecks[
        type === EIgnoredDataIntegrityErrorType.precheck
          ? 'ignoredPrecheckErrors'
          : 'ignoredPostcheckErrors'
      ] ?? [])
      .find((ir) => error.objectId && ir.problematicObjectId === error.objectId),
  );

  const [ ignored, setIgnored ] = useState(false);
  const [ ignoredReason, setIgnoredReason ] = useState(matchingIgnoredDataIntegrityError?.reason ?? '');
  const [ showIgnoreReasonModal, setShowIgnoreReasonModal ] = useState(false);

  useEffect(() => {
    setIgnored(!!matchingIgnoredDataIntegrityError);
    matchingIgnoredDataIntegrityError && setIgnoredReason(matchingIgnoredDataIntegrityError.reason);
  }, [ matchingIgnoredDataIntegrityError ]);

  const handleClose = () => setShowIgnoreReasonModal(false);

  const handleIgnore = () => {
    setShowIgnoreReasonModal(false);

    ignoreDataIntegrityError(
      {
        dataIntegrityCheckName,
        objectId: error.objectId!,
        reason: ignoredReason,
        type,
      },
    );
  };

  const handleRemedied = () => {
    error.objectId && fixDataIntegrityError({
      dataIntegrityCheckName,
      objectId: error.objectId,
      type,
      metaData: error.metaData,

    });
  };

  const errorFixes = (
    error.fixFunctions
      ? error.fixFunctions.concat('default') // Errors don't need to opt in to the 'default' 'fix function'.
      : fixOptions
        ? ['default']
        : null
  );

  const { hasPermission, isDeveloper } = useAuth();

  return (
    <Box mb={1} mt={1}>
      <Grid item container mb={2} xs={12}>
        <Grid item xs={6} sm={5} md={4} lg={3} xl={2}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  disabled={!isDeveloper || !hasPermission('IGNORE_DATA_INTEGRITY_ERROR')}
                  checked={ignored}
                  onChange={
                    (_e, checked) => {
                      setShowIgnoreReasonModal(checked);

                      if (!checked) {
                        unignoreDataIntegrityError({ type, dataIntegrityCheckName, objectId: error.objectId! });
                        setIgnoredReason('');
                      }
                    }
                  }
                />
              }
              label='Ignored'
              sx={{
                backgroundColor: '#fff',
                border: '1px solid gray',
                borderRadius: '9px',
                mb: '1rem',
                ml: '3px',
                p: '3px',
              }}
            />
          </FormGroup>
        </Grid>
        {
          ignored && ignoredReason &&
              <Grid item container xs={12} sm={8} px={1.5} pb={1} alignItems='center'>
                <Typography color='#212121' variant='subtitle1'><b>Reason</b>: {ignoredReason}</Typography>
              </Grid>
        }
      </Grid>

      {
        // An error can be remedied by a `default` `errorFix`, even if it doesn't
        // list 'default' as one of its 'fix functions'.
        // This is the only case in which a 'fix function' declaration may be omitted.
        (
          hasPermission('FIX_DATA_INTEGRITY_ERROR') &&
            errorFixes &&
              fixOptions &&
              errorFixes.some((fixName) => keys(fixOptions ?? {}).includes(fixName))
        ) &&
            <Paper
              elevation={10}
              sx={
                {
                  backgroundColor: 'dodgerblue',
                  backgroundImage: 'linear-gradient(90deg, dodgerblue, #F4C5C5)',
                  borderRadius: 4.5,
                  color: 'white',
                  mb: 4,
                  p: 3,
                }
              }>
              <Typography variant='h5'>Available Fixes:</Typography>
              {
                errorFixes
                  .filter((fixName) => keys(fixOptions ?? {}).includes(fixName))
                  .map((fixName) => (
                    <Box key={fixName}>
                      <FixForm
                        dataIntegrityCheckName={dataIntegrityCheckName}
                        fixFnArguments={fixOptions[fixName].fixFnArguments}
                        fixFnName={fixName}
                        handleRemedied={handleRemedied}
                        message={fixOptions[fixName].message}
                        objectId={error.objectId}
                        errorData={error}
                      />
                    </Box>
                  ))
              }
            </Paper>
      }
      <br />
      {
        keys(error).filter((key) => key !=='fixFunctionDefaults')
          .map((keyStr, i) =>
            <KeyValueDisplay key={i} label={keyStr} value={error[keyStr]!} />)
      }
      {
        !lastError &&
            <>
              <br />
              <Divider />
            </>
      }

      <Dialog
        fullWidth={true}
        open={showIgnoreReasonModal}
        onClose={handleClose}

      >
        <DialogTitle>
            Ignore Data Integrity Check Error
        </DialogTitle>

        <Divider />

        <DialogContent>
          <br />

          <DialogContentText>
              Provide a reason to ignore the error:
          </DialogContentText>

          <Grid container alignItems='center' mt={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth={true}
                label='Ignore reason'
                multiline={true}
                onChange={(e) => setIgnoredReason(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleIgnore();
                  }
                }}
                required={true}
                rows={4}
                size='medium'
                value={ignoredReason}
              />
            </Grid>

            <Grid item container mt={2} xs={12}>
              <Grid item xs={6}>
                <Button variant='outlined' onClick={handleClose}>Close</Button>
              </Grid>

              <Grid item textAlign={'right'} xs={6}>
                <Button
                  color='info'
                  onClick={handleIgnore}
                  type='submit'
                  variant='contained'
                >
                      Ignore &crarr;
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
