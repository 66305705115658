import { Optional } from "@jamesgmarks/utilities";

/** Type guard for the `number` primitive type. */
export const isNumber = (value: unknown): value is number => typeof value === 'number';

/** Type guard for the `string` primitive type. */
export const isString = (value: unknown): value is string => typeof value === 'string';

/** Type guard for the `Boolean` primitive type. */
export const isBoolean = (value: unknown): value is boolean => typeof value === 'boolean';

/** Type guard for the `null` primitive type. */
export const isNull = (value: unknown): value is null => value === null;

/** Type guard for the `undefined` primitive type. */
export const isUndefined = (value: unknown): value is undefined => value === undefined;

/** Returns the passed-in value if it is a `number`. Throws an `Error` otherwise. */
export const assertNumber = (value: unknown): number | never => {
  if (!isNumber(value)) {
    throw new Error('Value is not a number!');
  }

  return value;
};

/** Returns the passed-in value if it is a `string`. Throws an `Error` otherwise. */
export const assertString = (value: unknown): string | never => {
  if (!isString(value)) {
    throw new Error('Value is not a string!');
  }

  return value;
};

/** Returns the passed-in value if it is a `Boolean`. Throws an `Error` otherwise. */
export const assertBoolean = (value: unknown): boolean | never => {
  if (!isBoolean(value)) {
    throw new Error('Value is not a Boolean!');
  }

  return value;
};

/** Returns the passed-in value if it is `null`. Throws an `Error` otherwise. */
export const assertNull = (value: unknown): null | never => {
  if (!isNull(value)) {
    throw new Error('Value is not `null`!');
  }

  return value;
};

/** Returns the passed-in value if it is `undefined`. Throws an `Error` otherwise. */
export const assertUndefined = (value: unknown): undefined | never => {
  if (!isUndefined(value)) {
    throw new Error('Value is not `undefined`!');
  }

  return value;
};

/** Returns `true` if the passed-in value is not `null` or `undefined`. */
export const isNullOrUndefined = <T extends unknown>(value: Optional<T>): value is null | undefined => {
  return isUndefined(value) || isNull(value);
};

/** Returns the passed-in value if it is not `null` or `undefined`. Throws an `Error` otherwise. */
export const assertNotNullOrUndefined = <T extends unknown>(value: Optional<T>): T | never => {
  if (isNullOrUndefined(value)) {
    throw new Error('Value is `null` or `undefined`!');
  }

  return value!;
};
