import { useState } from "react";

import {
  Paper, Table, TableBody, TableContainer, TableFooter, TableHead, 
} from '@mui/material';

import { HashOf, floatVal, keys, strVal } from "@jamesgmarks/utilities";

import { DownloadCSV } from "../../../../../parts/DownloadCSV";
import { EHideableColumns } from "../MonthlyBillingReport";
import { ICreditTotals } from "../../../../../../../../rentsync-api/reporting";
import { IMonthlyPerStreamSummaryItem } from "../../../../../../redux/features/reporting/IMonthlySummaryData";
import { StyledTableCell, StyledTableRow } from "../../../../../parts/mui/StyledTables";
import { partnerCoinStyle } from '../styles';
import { toDollarAmount } from "../../../../../../app-utils";

interface ICreditTotalsWithStream extends ICreditTotals {
    stream: string
}

interface CreditsSummaryTableProps {
  creditTotals: HashOf<ICreditTotals>
  hiddenColumns?: EHideableColumns[];
}

export const CreditsSummaryTable = ({
  creditTotals,
  hiddenColumns = [],
}: CreditsSummaryTableProps) => {
  const showAccountNumber = !hiddenColumns.includes(EHideableColumns.accountNumber);

  const creditTotalsArray = keys(creditTotals).reduce<ICreditTotalsWithStream[]>((cur, acc) => [
    ...cur,
    {
      ...creditTotals[acc],
      stream: strVal(acc),
    },
  ], []);

  const [sortCriteria, setSortCriteria] = useState(['creditType', 'asc']);

  const totalBillings = {
    itemName: 'Totals',
    subTotal: 0,
    tax: 0,
    total: 0,
    partnerShare: 0,
    lwsShare: 0,
  } as IMonthlyPerStreamSummaryItem;

  const addCreditToRevenueTotal = (
    totalReference: IMonthlyPerStreamSummaryItem,
    credit: IMonthlyPerStreamSummaryItem,
  ) => {
    totalReference.subTotal = totalReference.subTotal - Math.abs(credit.subTotal);
    totalReference.tax = totalReference.tax - Math.abs(credit.tax);
    totalReference.total = totalReference.total - Math.abs(credit.total);
    totalReference.partnerShare = totalReference.partnerShare - Math.abs(credit.partnerShare);
    totalReference.lwsShare = totalReference.lwsShare - Math.abs(credit.lwsShare);
  };

  const getStreamForSort = (creditTotal: ICreditTotalsWithStream) =>
    creditTotal.stream.split(': ')[1].toLowerCase();

  const getCreditLabelForSort = (creditTotal: ICreditTotalsWithStream) =>
    creditTotal.stream.split(': ')[0].toLowerCase();

  const getLwsShareForSort = (creditTotal: ICreditTotalsWithStream) =>
    creditTotal.amount - creditTotal.partner_clawback;

  const sortFns = {
    'stream': (a, b) => getStreamForSort(a) > getStreamForSort(b) ? 1 : -1,
    'creditType': (a, b) => getCreditLabelForSort(a) > getCreditLabelForSort(b) ? 1 : -1,
    'subtotal': (a, b) => floatVal(a.amount) > floatVal(b.amount) ? 1 : -1,
    'taxTotal': (a, b) => floatVal(a.tax) > floatVal(b.tax) ? 1 : -1,
    'total': (a, b) => floatVal(a.total) > floatVal(b.total) ? 1 : -1,
    'partnerShare': (a, b) => floatVal(a.partner_clawback) > floatVal(b.partner_clawback) ? 1 : -1,
    'lwsShare': (a, b) => getLwsShareForSort(a) > getLwsShareForSort(b) ? 1 : -1,
  } as HashOf<(a: ICreditTotalsWithStream, b: ICreditTotalsWithStream) => (1 | -1)>;

  const sortBy = (criteria: string) => {
    setSortCriteria([criteria, (sortCriteria[0] === criteria && sortCriteria[1] === 'asc') ? 'desc' : 'asc']);
  };

  const getSortIcon = (criteria: string) => {
    return sortCriteria[0] === criteria ? (sortCriteria[1] === 'asc' ? <>&#x25b2;</> : <>&#x25bc;</>) : null;
  };

  const doSort = (a: ICreditTotalsWithStream, b: ICreditTotalsWithStream) => {
    return (
      sortFns[sortCriteria[0]](a, b) * (sortCriteria[1] === 'asc' ? 1 : -1)
    );
  };

  return (
    <>
      <DownloadCSV filename={`credit-totals`}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <StyledTableRow dark={true}>
                <StyledTableCell dark={true} onClick={() => sortBy('stream')} className='sortable-header'>
                    Partner {getSortIcon('stream')}
                </StyledTableCell>

                {
                  showAccountNumber &&
                  <StyledTableCell dark={true}>
                    Account #
                  </StyledTableCell>
                }

                <StyledTableCell dark={true} onClick={() => sortBy('creditType')} className='sortable-header'>
                    Credit Type {getSortIcon('creditType')}
                </StyledTableCell>

                <StyledTableCell dark={true} onClick={() => sortBy('subtotal')} className='sortable-header'>
                    Subtotal {getSortIcon('subtotal')}
                </StyledTableCell>

                <StyledTableCell dark={true} onClick={() => sortBy('taxTotal')} className='sortable-header'>
                    Tax {getSortIcon('taxTotal')}
                </StyledTableCell>

                <StyledTableCell dark={true} onClick={() => sortBy('total')} className='sortable-header'>
                    Total {getSortIcon('total')}
                </StyledTableCell>

                <StyledTableCell dark={true} onClick={() => sortBy('partnerShare')} className='sortable-header'>
                    Partner Share {getSortIcon('partnerShare')}
                </StyledTableCell>

                <StyledTableCell dark={true} onClick={() => sortBy('lwsShare')} className='sortable-header'>
                    LWS Share {getSortIcon('lwsShare')}
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {
                creditTotalsArray
                  .sort((a, b) => doSort(a, b))
                  .map((creditTotal, i) => {

                    const creditType = strVal(creditTotal.stream).split(': ')[0];
                    const partner = strVal(creditTotal.stream).split(': ')[1]; // `<credit-type>: <partner>`

                    const lwsShare = creditTotal.amount - creditTotal.partner_clawback;

                    addCreditToRevenueTotal(totalBillings, {
                      itemName: 'Credit',
                      subTotal: (creditTotal.amount),
                      tax: (creditTotal.tax),
                      total: (creditTotal.total),
                      partnerShare: (creditTotal.partner_clawback),
                      lwsShare: (lwsShare),
                    } as IMonthlyPerStreamSummaryItem); // $715,810.30

                    return (
                      <StyledTableRow dark={true} hover={true} key={i}>
                        <StyledTableCell dark={true}>{partner}</StyledTableCell>

                        {
                          showAccountNumber &&
                          <StyledTableCell dark={true}></StyledTableCell>
                        }

                        <StyledTableCell dark={true}>{creditType}</StyledTableCell>
                        
                        <StyledTableCell dark={true}>
                          {toDollarAmount(-1 * creditTotal.amount, 'brackets')}
                        </StyledTableCell>
                        
                        <StyledTableCell dark={true}>
                          {toDollarAmount(-1 * creditTotal.tax, 'brackets')}
                        </StyledTableCell>
                        
                        <StyledTableCell dark={true}>
                          {toDollarAmount(-1 * creditTotal.total, 'brackets')}
                        </StyledTableCell>
                        
                        <StyledTableCell dark={true}>
                          {toDollarAmount(-1 * creditTotal.partner_clawback, 'brackets')}
                        </StyledTableCell>
                        
                        <StyledTableCell dark={true}>
                          {toDollarAmount(-1 * (creditTotal.amount - creditTotal.partner_clawback), 'brackets')}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })
              }
            </TableBody>

            <TableFooter>
              <StyledTableRow dark={true} key='revenue_total'>
                <StyledTableCell dark={true}>
                  {totalBillings.itemName.slice(totalBillings.itemName.startsWith('*') ? 1 : 0)}
                  {totalBillings.itemName.startsWith('*') ? <span style={partnerCoinStyle}>partner invoiced</span> : ''}
                </StyledTableCell>

                {
                  showAccountNumber &&
                  <StyledTableCell dark={true}></StyledTableCell>
                }
                
                <StyledTableCell dark={true}></StyledTableCell>

                <StyledTableCell dark={true}>
                  {`${toDollarAmount(totalBillings.subTotal, 'brackets')}`}
                </StyledTableCell>

                <StyledTableCell dark={true}>{`${toDollarAmount(totalBillings.tax, 'brackets')}`}</StyledTableCell>

                <StyledTableCell dark={true}>{`${toDollarAmount(totalBillings.total, 'brackets')}`}</StyledTableCell>

                <StyledTableCell dark={true}>
                  {`${toDollarAmount(totalBillings.partnerShare, 'brackets')}`}
                </StyledTableCell>
                
                <StyledTableCell dark={true}>
                  {`${toDollarAmount(totalBillings.lwsShare, 'brackets')}`}
                </StyledTableCell>
              </StyledTableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </DownloadCSV>
    </>
  );
};
