import { Nullable } from '@jamesgmarks/utilities';
import { CallTrackingNumberForwarding } from '@llws/typeorm-entities';
import { 
  Autocomplete, 
  capitalize, 
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { IClientRuntimeGroup } from '../../entity-interfaces/IClientRuntimeGroup';
import { loadRuntimeGroupsLookup } from '../../redux/features/lookups/actions';
import { useAppSelector } from '../../redux/hooks';
    
interface IAutoCompleteDropdownProps {
  id: string;
  label: string;
  defaultValue: IClientRuntimeGroup | null;
  onRuntimeGroupChange: ((runtimeGroupId: number) => void)
}
    
export const RuntimeGroupDropdown =({
  id,
  label,
  defaultValue,
  onRuntimeGroupChange,  
}: IAutoCompleteDropdownProps) => {

  const runtimeGroups = useAppSelector((state) => state.lookups.runtimeGroups);
  const billingAccount = useAppSelector((state) => state.clients.currentClientBillingAccount);

  const [ runtimeGroupQuery, setRuntimeGroupQuery] = useState('');
  const [ selectedRuntimeGroup, setSelectedRuntimeGroup] = useState<Nullable<IClientRuntimeGroup>>(
    billingAccount?.clientRuntimeGroup ?? null,
  );

  const handleRuntimeGroupSelected = useCallback(
    (runtimeGroup: Nullable<IClientRuntimeGroup>) => {
      setSelectedRuntimeGroup(runtimeGroup);
      if (runtimeGroup) onRuntimeGroupChange(runtimeGroup.id);
    }, [onRuntimeGroupChange],
  );

  useEffect(() => {
    loadRuntimeGroupsLookup();
  }, []);

  useEffect(() => {
    if (billingAccount) {
      setSelectedRuntimeGroup(billingAccount.clientRuntimeGroup);
    }
  }, [billingAccount]);

  const createOptionLabel = (option: IClientRuntimeGroup) => (
    option.label 
      ? `${option.label} (${option.id})` 
      : `${capitalize(option.runType)} Group: ${option.id}`
  );
    
  return (
    <div key={defaultValue?.id ?? 0}>
      <Autocomplete
        id={id}
        autoComplete
        autoHighlight
        clearOnBlur
        clearOnEscape
        fullWidth
        inputValue={runtimeGroupQuery ?? ''}
        onChange={ 
          (_e, newValue, _reason) => {
            if (typeof newValue !== 'string') handleRuntimeGroupSelected(newValue ?? null);
          }
        }
        onInputChange={ 
          (_e, value: string, _reason) => {
            setRuntimeGroupQuery(value);
          }
        }
        getOptionLabel={(option) => (
          typeof option === 'string' 
            ? option
            : createOptionLabel(option)
        )}
        options={ runtimeGroups }
        isOptionEqualToValue = {
          (option, value) => {
            return option.label === value.label || option.id === value.id;
          }
        }
        renderOption={
          (props, option: IClientRuntimeGroup) => {
            return <li {...props}>
              <Typography>
                <span style={{ color: 'dodgerblue', fontWeight: 550 }}>
                  {
                    option.label 
                      ? `${option.label}` 
                      : `${capitalize(option.runType)} Group: ${option.id}`
                  }
                </span>
                { option.label && <span>{` (${option.id})`}</span>}
              </Typography>
            </li>;
          }
        }
        renderInput={
          (params) =>  (
            <TextField
              { ...params }
              label={ label }
              sx={{ backgroundColor: '#fff' }}
              variant="outlined"
            />
          )
        }
        size="small"
        value={ selectedRuntimeGroup }
      />
    </div>
  );
};