import React, { useEffect, useMemo, useState } from "react";
import {
  Button, CircularProgress, Grid, TextField, Paper,
} from "@mui/material";
import { useAppSelector } from '../../../redux/hooks';
import { compactArray, Nullable } from '@jamesgmarks/utilities';
import { getMany, updateOne } from '../../../redux/features/dynamic/actions';
import { Equal, IQueryCommandOptions } from '@llws/dynamic-router-utils';
import { IOwnershipGroupContactInfo } from '@llws/lift-entity-interfaces';
import { isValidPostalCode } from "../../../app-utils/input-validator";
import { showMessage } from "src/redux/features/messaging/actions";

type TFormFieldError = {
  field: Nullable<string>,
  message: Nullable<string>
}

interface IOwnershipGroupContactsProps {
  ownershipGroupId: number,
  handleModalSubmit: () => void,
}

export const OwnershipGroupContacts = ({ ownershipGroupId, handleModalSubmit }: IOwnershipGroupContactsProps) => {
  const contactInfoList = useAppSelector(state => state.dynamic.data.ownership_group_contact_info?.list ?? []);

  const [contactInfoToBeEdited, setContactInfoToBeEdited] = useState<Nullable<IOwnershipGroupContactInfo>>(
    contactInfoList[0] ?? null,
  );
  const [loadingState, setLoadingState] = useState<boolean>(true);
  const [formFieldErrors, setFormFieldErrors] = useState<Nullable<TFormFieldError[]>>([]);

  const queryCommand: IQueryCommandOptions = useMemo(() => ({
    alias: 'ogci',
    where: [Equal('ogci.ownershipGroupId', ownershipGroupId)],
  }), [ownershipGroupId]);

  useEffect(() => {
    setLoadingState(true);
    getMany('ownership_group_contact_info', queryCommand);
  }, [queryCommand]);

  useEffect(() => {
    if (contactInfoList.length > 0) {
      setContactInfoToBeEdited(contactInfoList[0]);
    }
    setLoadingState(false);
  }, [contactInfoList]);

  const checkErrorExists = (fieldName: string) => {
    return !!(formFieldErrors ?? []).find((fieldError) => fieldError.field === fieldName);
  };

  const getErrorMessage = (fieldName: string) => {
    const errorField = (formFieldErrors ?? []).find((fieldError) => fieldError.field === fieldName);
    return errorField?.message ?? null;
  };

  const validateFormAndSetErrors = () => {
    const careOf = contactInfoToBeEdited?.careOf ?? '';
    const careOfError = careOf !== '' && careOf.length > 256
      ? {field: 'careOf', message: 'Please provide a valid care of value between 1-256 characters'}
      : null;
    const address = contactInfoToBeEdited?.address ?? '';
    const addressError = address !== '' && address.length > 256
      ? {field: 'address', message: 'Please provide a valid address between 1-256 characters'}
      : null;
    const city = contactInfoToBeEdited?.city ?? '';
    const cityError = city !== '' && city.length > 256
      ? {field: 'city', message: 'Please provide a valid city between 1-256 characters'}
      : null;
    const province = contactInfoToBeEdited?.province ?? '';
    const provinceError = province !== '' && province.length > 256
      ? {field: 'province', message: 'Please provide a valid province between 1-256 characters'}
      : null;
    const postal = contactInfoToBeEdited?.postal ?? '';
    const postalCodeError = postal !== '' && !isValidPostalCode(postal)
      ? {field: 'postalCode', message: 'Please provide a valid postal code'}
      : null;
    const country = contactInfoToBeEdited?.country ?? '';
    const countryError = country !== '' && country.length > 256
      ? {field: 'country', message: 'Please provide a valid country between 1-256 characters'}
      : null;
    const errors: TFormFieldError[] = compactArray([
      careOfError,
      addressError,
      cityError,
      provinceError,
      postalCodeError,
      countryError,
    ]);
    setFormFieldErrors(errors);
    return errors.length;
  };

  const handleSubmit = async () => {
    if (!contactInfoToBeEdited) {
      showMessage({ severity: 'error', message: 'contactInfoToBeEdited is empty' });
      return;
    }
    const hasErrors = validateFormAndSetErrors() > 0;
    const hasUpdated = !hasErrors
      ? await updateOne(
        'ownership_group_contact_info',
        contactInfoToBeEdited,
        contactInfoList[0]?.id ?? -1,
      )
      : false;
    if(hasUpdated) {
      handleModalSubmit();
    }
  };

  return (
    <>
      {loadingState
        ? <Grid item container justifyContent="center" xs>
          <CircularProgress size={25} color='primary'/>
        </Grid>
        :
        contactInfoToBeEdited
          ? <Grid container spacing={1} component={Paper} p={2} justifyContent="center" elevation={3}>
            <form onSubmit={(e) => e.preventDefault()}>
              <Grid container spacing={2} justifyContent="center" alignItems="center" my={0.5} >
                <Grid item xs={2}>
                  Care Of:
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    label="Care Of"
                    size='small'
                    onChange={(e) => setContactInfoToBeEdited({...contactInfoToBeEdited, careOf: e.target.value})}
                    value={contactInfoToBeEdited.careOf ?? ''}
                    error={checkErrorExists('careOf')}
                    helperText={getErrorMessage('careOf')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  Address:
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    label="Address"
                    size='small'
                    onChange={(e) => setContactInfoToBeEdited({...contactInfoToBeEdited, address: e.target.value})}
                    value={contactInfoToBeEdited.address ?? ''}
                    error={checkErrorExists('address')}
                    helperText={getErrorMessage('address')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  City:
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="City"
                    size='small'
                    onChange={(e) => setContactInfoToBeEdited({...contactInfoToBeEdited, city: e.target.value})}
                    value={contactInfoToBeEdited.city ?? ''}
                    error={checkErrorExists('city')}
                    helperText={getErrorMessage('city')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  Province:
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Province"
                    size='small'
                    onChange={(e) => setContactInfoToBeEdited({...contactInfoToBeEdited, province: e.target.value})}
                    value={contactInfoToBeEdited.province ?? ''}
                    error={checkErrorExists('province')}
                    helperText={getErrorMessage('province')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  Postal Code:
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Postal Code"
                    size='small'
                    onChange={(e) => setContactInfoToBeEdited({...contactInfoToBeEdited, postal: e.target.value})}
                    value={contactInfoToBeEdited.postal ?? ''}
                    error={checkErrorExists('postalCode')}
                    helperText={getErrorMessage('postalCode')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  Country:
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Country"
                    size='small'
                    onChange={(e) => setContactInfoToBeEdited({...contactInfoToBeEdited, country: e.target.value})}
                    value={contactInfoToBeEdited.country ?? ''}
                    error={checkErrorExists('country')}
                    helperText={getErrorMessage('country')}
                    fullWidth
                  />
                </Grid>
                <Grid item container justifyContent="flex-end">
                  <Grid item>
                    <Button variant='contained' onClick={handleSubmit}>
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
          : <>No data</>

      }
    </>
  );
};
