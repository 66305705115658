import { HashOf, intVal, isNumeric, Nullable } from "@jamesgmarks/utilities";
import { IApiQueryResponse } from "@llws/api-common";
import { MonthEndLocks } from "@llws/typeorm-entities";
import { REACT_APP_API_ROOT_URI } from "../../../App";
import { dispatch } from "../../store";
import { IApiQueryListResponse, IApiQuerySingularResponse, typedApiFetch } from "../../utils";
import { loadMonthLockData } from "../invoices/actions";
import { showMessage } from "../messaging/actions";
import { IMonthlySummaryData } from "./IMonthlySummaryData";
import { IPartnerCallTrackingLineSummary } from '../../../../../rentsync-api/reporting/partnerCallTrackingLineSummary';
import {
  monthLockListReceived,
  monthlySummaryReceived,
  setLoadingState,
  setSummaryMonth,
  padmapperRevenueSummaryReceived,
  partnerCallTrackingLinesReceived,
  partnerIdReceived,
  customReportsBreakdownReceived,
} from "./reportingSlice";
import { ICustomRunnerBreakdown } from "../../../../../custom_runners/ICustomRunnerBreakdown";
import { makeYmdHms } from "../../../app-utils";

const loaders = {
  'monthly_billing': async (filters: HashOf<unknown>) => {
    const { year, month } = filters;
    const { rebuildCache } = filters;
    if (!isNumeric(year) || !isNumeric(month)) {
      console.log(`Invalid filters for 'monthly_billing' loader.`, filters);
      showMessage({
        message: `Invalid filters for 'monthly_billing' loader. Year and Month must be valid numeric values.`,
      });
      return;
    }
    const url = (
      `${REACT_APP_API_ROOT_URI}/reporting/billing_summary/${year}-${month}${rebuildCache ? '?rebuildCache=1' : ''}`
    );
    const response = await typedApiFetch<IApiQueryResponse<IMonthlySummaryData>>(url);
    const responseData = await response.json();
    await loadMonthLockData(intVal(year), intVal(month));
    dispatch(monthlySummaryReceived(responseData)); // TODO: Error handling?
  },
  'padmapper_revenue_summary': async (filters: HashOf<unknown>) => {
    const { year, month } = filters;
    if (!isNumeric(year) || !isNumeric(month)) {
      console.log(`Invalid filters for 'monthly_billing' loader.`, filters);
      showMessage({
        message: `Invalid filters for 'monthly_billing' loader. Year and Month must be valid numeric values.`,
      });
      return;
    }
    const url = `${REACT_APP_API_ROOT_URI}/reporting/padmapper_revenue_summary/${year}-${month}`;

    console.log(`Loading monthly padmapper revenue breakdown.`, { url });
    const response = await typedApiFetch<IApiQuerySingularResponse<Nullable<{
      CAD: HashOf<number>;
      USD: HashOf<number>;
    }>>>(url);
    const responseData = await response.json();
    dispatch(padmapperRevenueSummaryReceived(responseData.data)); // TODO: Error handling?
  },
  'partner_call_tracking_lines_summary': async (filters: HashOf<unknown>) => {
    const {year, month, partnerId} = filters;
    if (!isNumeric(year) || !isNumeric(month) || !isNumeric(partnerId)) {
      console.log(`Invalid filters for 'partner_call_tracking_lines_summary' loader.`, filters);
      showMessage({
        message: (
          `Invalid filters for 'partner_call_tracking_lines_summary' loader. `
          + `Year, Month and PartnerId must be valid numeric values.`
        ),
      });
      return;
    }

    const url = `${REACT_APP_API_ROOT_URI}/reporting/partner_call_tracking_lines_summary/${partnerId}/${year}-${month}`;
    const response = await typedApiFetch<IApiQueryResponse<IPartnerCallTrackingLineSummary[]>>(url);
    const responseData = await response.json();

    dispatch(partnerIdReceived(partnerId as number));
    dispatch(partnerCallTrackingLinesReceived(responseData));
    dispatch(setSummaryMonth({
      year: year as number,
      month: month as number,
    }));
    dispatch(setLoadingState('loaded'));
  },
  'none': async () => { },
};

export const loadReport = async (reportType: keyof typeof loaders, filters: HashOf<unknown>) => {
  await loaders[reportType](filters);
};

export const loadMonthLockList = async () => {
  const url = `${REACT_APP_API_ROOT_URI}/reporting/month_locks`;
  dispatch(setLoadingState('loading'));
  const response = await typedApiFetch<IApiQueryResponse<MonthEndLocks[]>>(url);
  const responseData = await response.json();
  dispatch(monthLockListReceived(responseData));
  dispatch(setLoadingState('loaded'));
};

export const sendMonthLockRequest = async (
  year: number,
  month: number,
  userId: number,
) => {
  const body = {
    year,
    month,
    userId,
  };
  const url = `${REACT_APP_API_ROOT_URI}/reporting/lock_month`;
  const response = await typedApiFetch<IApiQueryResponse<string>>(url, { method: 'POST', body: JSON.stringify(body) });
  await response.json();
  showMessage({ message: 'Month lock request sent', severity: 'info',  asAlert: true });
  loadMonthLockList();
};

export const loadCustomReportsList = async () => {
  const url = `${REACT_APP_API_ROOT_URI}/reporting/reports_breakdown`;
  dispatch(setLoadingState('loading'));
  const response = await typedApiFetch<IApiQueryListResponse<ICustomRunnerBreakdown>>(url);
  const responseData = await response.json();
  dispatch(customReportsBreakdownReceived(responseData.data));
  dispatch(setLoadingState('loaded'));
};

export const runCustomReportCsv = async (reportName: string, parameters: HashOf<unknown>) => {
  dispatch(setLoadingState('loading'));
  const url = `${REACT_APP_API_ROOT_URI}/reporting/${reportName}/get_csv`;

  const response = await typedApiFetch<string>( // Body of response is expected to be CSV text.
    url,
    {
      method: 'POST',
      body: JSON.stringify({
        reportName,
        parameters,
      }),
    },
  );
  if (!response.ok) {
    showMessage({
      message: 'Download failed, please try again. If this issue persists, contact your system administrator.',
      severity: 'error',
    });
    return;
  }

  dispatch(setLoadingState('loaded'));

  const file = window.URL.createObjectURL(await response.blob());
  const a = Object.assign(
    document.createElement('a'), {
      href: file,
      download: `${reportName}_${makeYmdHms(new Date())}.csv`,
    },
  );
  document.body.appendChild(a);
  a.click();
  a.remove();
};
