import { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import { Nullable, pickNot } from "@jamesgmarks/utilities";

import { handleInputChange, handleSelectChanged, numberSelectValueTransform } from "../../../app-utils/handlers";
import { loadBillingTypesLookup } from "../../../redux/features/lookups/actions";
import { loadServices, saveService } from "../../../redux/features/products/actions";
import { showMessage } from "../../../redux/features/messaging/actions";
import { Spinner } from "../../parts/Spinner";
import { useAppSelector } from "../../../redux/hooks";
import { IServices } from "@llws/lift-entity-interfaces";

export const ServiceCreateEditModal = ({
  service,
  show,
  setShow,
  onSaveRequest,
}:{
  service: Partial<IServices>,
  show: boolean,
  setShow: ((newState: boolean) => void),
  onSaveRequest: ((service: Partial<IServices>) => void),
}) => {
  const billingTypes = useAppSelector((store) => store.lookups.billingTypes);

  const [ name, setName ] = useState(service.name as Nullable<string>);
  const [ invoiceItem, setInvoiceItem ] = useState(service.invoiceItem as Nullable<string>);
  const [ website, setWebsite ] = useState(service.website as Nullable<string>);
  const [ billingTypeId, setBillingTypeId ] = useState(service.billingTypeId as Nullable<number>);
  const [ status, setStatus ] = useState(service.status as 'active' | 'inactive');

  const handleClose = () => {
    setShow(false);
  };

  const handleSave = () => {
    if (!name) {
      showMessage({
        message: 'Name is required.',
        severity: 'error',
      });
      return;
    }
    if (!billingTypeId) {
      showMessage({
        message: 'Billing Type is required.',
        severity: 'error',
      });
      return;
    }
    onSaveRequest({
      id: service.id,
      name,
      invoiceItem,
      website,
      billingTypeId,
      status,
    } as Partial<IServices>);
    setShow(false);
  };

  useEffect(() => {
    loadBillingTypesLookup();
  }, [ ]);

  return (
    <div>
      <Dialog open={show} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{service.id ? 'Edit ' : 'Create '} Service</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ID: {service.id ?? '(new)'}
          </DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                id="serviceName"
                variant="outlined"
                label="Service Name"
                name="serviceName"
                type="text"
                value={name ?? ''}
                onChange={handleInputChange(setName)}
                size="small"
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="serviceInvoiceItem"
                variant="outlined"
                label="Invoice Item"
                name="serviceInvoiceItem"
                type="text"
                value={invoiceItem ?? ''}
                onChange={handleInputChange(setInvoiceItem)}
                size="small"
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="serviceWebsite"
                variant="outlined"
                label="Website"
                name="serviceWebsite"
                type="text"
                value={website ?? ''}
                onChange={handleInputChange(setWebsite)}
                size="small"
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" size="small" fullWidth={true}>
                <InputLabel id="serviceBillingTypeId-label">Billing Type</InputLabel>
                {
                  billingTypes.length > 0
                    ? <Select
                      labelId="serviceBillingTypeId-label"
                      id="serviceBillingTypeId"
                      value={billingTypeId?.toString() ?? ''}
                      onChange={ handleSelectChanged(setBillingTypeId, numberSelectValueTransform) }
                    >
                      <MenuItem value={''}>-- Select One --</MenuItem>
                      {billingTypes.map((bt) => (
                        <MenuItem key={bt.id} value={bt.id ?? ''}>{bt.name} ({bt.id})</MenuItem>
                      ))}
                    </Select>
                    : '...'
                }
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" size="small" fullWidth={true}>
                <InputLabel id="serviceStatus-label">Status</InputLabel>
                <Select
                  labelId="serviceStatus-label"
                  id="serviceStatus"
                  value={status ?? 'active'}
                  onChange={ handleSelectChanged(setStatus, (value) => value as 'active' | 'inactive') }
                >
                  <MenuItem value={'active'}>Active</MenuItem>
                  <MenuItem value={'inactive'}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button disabled={false} onClick={handleSave} color="primary">
            {service.id ? 'Save' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export const ServicesListTableRow = ({
  service,
  onEdit,
  onCreateFromCopy,
}: {
  service: IServices
  onEdit: () => void,
  onCreateFromCopy: (service: IServices) => void,
}) => {
  const style = service.status === 'inactive' ? { color: '#CCC' } : {};

  return (
    <>
      <TableRow style={style}>
        <TableCell>{service.id}</TableCell>
        <TableCell component="th" scope="row">
          {service.name ?? ''}
        </TableCell>
        <TableCell>{service.invoiceItem ?? ''}</TableCell>
        <TableCell>{service.website ?? ''}</TableCell>
        <TableCell>{service.billingType?.name}</TableCell>
        <TableCell>{service.status}</TableCell>
        <TableCell>
          <Button onClick={() => onEdit()}>Edit</Button>
          <Button onClick={() => onCreateFromCopy(service)}>Create Copy</Button>
        </TableCell>
      </TableRow>
    </>
  );
};

export const ServicesListTable = ({
  rows,
  onEditButtonClicked,
  onCreateFrom,
} : {
  rows: IServices[],
  onEditButtonClicked: (baseSubscription: IServices) => void,
  onCreateFrom: (baseSubscription: IServices) => void,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Invoice Item</TableCell>
            <TableCell>Website</TableCell>
            <TableCell>Billing Type</TableCell>
            <TableCell>Status</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <ServicesListTableRow
              key={row.id}
              service={row}
              onEdit={() => { onEditButtonClicked(row); }}
              onCreateFromCopy={onCreateFrom}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const Services = () => {
  const [ firstLoad, setFirstLoad ] = useState(false);
  const [ modalTarget, setModalTarget ] = useState({} as Partial<IServices>);
  const [ show, setShowModal ] = useState(false);

  const services = useAppSelector((store) => store.products.services);

  const activeServices = (services ?? []).filter(s => s.status === 'active');

  useEffect(() => {
    const handleFirstLoad = async () => {
      setFirstLoad(true);
      await loadServices();
      setFirstLoad(false);
    };
    handleFirstLoad();
  }, []);

  const onNewButtonClicked = () => {
    setModalTarget({});
    setShowModal(true);
  };

  const onEditButtonClicked = (service: IServices) => {
    console.log({ service });
    setModalTarget(service);
    setShowModal(true);
  };
  /**
   * Strips ID and created and modified information from provided base subscription and shows the result in the base subscription modal.
   * @param service
   */
  const onCreateFrom = (service: IServices) => {
    setModalTarget({
      ...pickNot(service, [ 'id', 'created', 'createdUserId', 'modified', 'modifiedUserId' ]),
    });
    setShowModal(true);
  };

  return (
    <>
      <header className="Section-header">Services ({activeServices.length})</header>
      <Grid container justifyContent="center">
        {firstLoad ?
          <Grid item xs={8} style={{ textAlign: 'center' }}>
            <Spinner state={'loading'} />
          </Grid>
          : <>
            <Grid item xs={8} style={{ textAlign: 'right' }}>
              <Button onClick={(e) => { onNewButtonClicked(); }}>+ New</Button>
            </Grid>
            <Grid item xs={8}>
              <ServicesListTable
                rows={activeServices ?? []}
                onEditButtonClicked={onEditButtonClicked}
                onCreateFrom={onCreateFrom}
              />
            </Grid>
          </>}
      </Grid>
      {show && <ServiceCreateEditModal
        show={show}
        setShow={setShowModal}
        service={modalTarget}
        onSaveRequest={(saveObject: Partial<IServices>) => {
          console.log({ saveObject });
          saveService(saveObject as unknown as IServices);
        }}
      />}
    </>
  );
};