import { createSlice } from '@reduxjs/toolkit';

export interface IXeroState {
  loadedState: string,
  consentUrl:string,
};

export const xeroSlice = createSlice({
  name: 'xero',
  initialState: {
    loadedState: 'idle',
    consentUrl: '',
   
  } as IXeroState,
  reducers: {
    xeroConsentUrlReceived: (state, action:{ payload: {consentUrl:string}}) => {
      state.consentUrl = action.payload.consentUrl;
    },
    setLoadedState: (state, action: { type: string, payload: string }) => {
      state.loadedState = action.payload;
    },

  },
});

// Action creators are generated for each case reducer function
export const {
  setLoadedState,
  xeroConsentUrlReceived,
} = xeroSlice.actions;

export default xeroSlice.reducer;