import { Hash } from "@jamesgmarks/utilities";
import React from "react";

interface ProgressRingProps {
  radius: number,
  stroke: number,
  progress: number,
  strokeColor?: string,
};

interface ProgressRingState {
};

export class ProgressRing extends React.Component<ProgressRingProps, ProgressRingState> {
  private normalizedRadius: number = 0;
  private circumference: number = 0;
  private strokeColor: string;

  constructor(props: { radius: number, stroke: number, progress: number }) {
    super(props);

    const { radius, stroke } = this.props;

    this.normalizedRadius = radius - stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
    this.strokeColor = this.props.strokeColor ?? 'black';
  }

  render() {
    const { radius, stroke, progress } = this.props as Hash;

    const strokeDashoffset = this.circumference - progress / 100 * this.circumference;

    return (
      <svg
        height={radius * 2}
        width={radius * 2}
      >
        <circle
          stroke={this.strokeColor}
          fill="transparent"
          strokeWidth={ stroke }
          strokeDasharray={ this.circumference + ' ' + this.circumference }
          style={ { strokeDashoffset } }
          stroke-width={ stroke }
          r={ this.normalizedRadius }
          cx={ radius }
          cy={ radius }
        />
      </svg>
    );
  }
}
