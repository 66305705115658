import { IApiQueryResponse } from '@llws/api-common';
import { dispatch } from '../../store';
import {
  feesQueueDataReceived, subscriptionListReceived,
} from './subscriptionsSlice';
import { ISubscriptionsWithInvoices } from './interfaces';
import { apiFetch } from '../../utils';
import { REACT_APP_API_ROOT_URI } from '../../../App';
import { ISubscriptions } from '@llws/lift-entity-interfaces';

export const getSubsforClient = async (freshbooksClientId:number) => {
  const url = `${REACT_APP_API_ROOT_URI}/subscriptions/${freshbooksClientId}`;
  const response = await apiFetch(url);
  const responseData = await response.json() as IApiQueryResponse< ISubscriptions >;
  console.log({ status: response.status, responseData });

  dispatch(subscriptionListReceived(responseData)); // ISSUE:#154: Error handling?
};

export const getFeesQueue = async (forTesting: boolean = false) => {
  const url = `${REACT_APP_API_ROOT_URI}/subscriptions/fees_queue${forTesting?'?filterUsed=0':''}`;
  const response = await apiFetch(url);
  const responseData = await response.json() as IApiQueryResponse<ISubscriptionsWithInvoices>;
  console.log({ status: response.status, responseData });
  dispatch(feesQueueDataReceived(responseData)); // TODO: Error handling?
};
