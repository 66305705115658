import { floatVal, intVal, Nullable } from "@jamesgmarks/utilities";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { UsageItem } from "../../../../../entities/hydra";
import { ISubscriptionCost } from "../../../../../rentsync-api/ISubscriptionCost";
import { loadChargeById } from "../../../redux/features/charges/actions";
import { setLoadedState } from "../../../redux/features/charges/chargesSlice";

import { useAppSelector } from "../../../redux/hooks";
import { CopiableText } from "../../parts/CopiableText";
import { Spinner } from "../../parts/Spinner";

const UsageItemRow = ({ usageItem }: { usageItem: UsageItem }) => {
  return <tr>
    <td>{usageItem.id}</td>
    <td>{usageItem.itemCost}</td>
    <td>{!usageItem.skipProration ? 'yes' : 'no'}</td>
    <td>{usageItem.billingTypeId}</td>
    <td>{usageItem.buildingId}</td>
    <td>{usageItem.partnerIds?.join(', ') ?? null}</td>
    <td>{usageItem.chargeId}</td>
    {/* <td>{usageItem.invoiceLineId}</td> */}
    <td>{usageItem.subscriptionId}</td>
    <td>{usageItem.phoneNumber}</td>
    <td>{usageItem.zoneCoefficient}</td>
    <td>{usageItem.amount}</td>
    <td>{usageItem.unitOfMeasure}</td>
    <td>{usageItem.created}</td>
    <td>{usageItem.createdUserId}</td>
    <td>{usageItem.modified}</td>
    <td>{usageItem.modifiedUserId}</td>
  </tr>;
};

export const ChargeSingleView = () => {
  const params = useParams<{ id?: string }>();
  const chargeId = useMemo(() => intVal(params.id), [ params ]);

  const [ costingData, setCostingData ] = useState(null as Nullable<ISubscriptionCost>);

  const loadedState = useAppSelector(state => state.charges.loadedState);
  const charge = useAppSelector(state => state.charges.currentCharge);

  useEffect(() => {
    setLoadedState('loading');
    loadChargeById(chargeId);
  }, [ chargeId ]);

  useEffect(() => {
    setCostingData(null);
    // TODO: Looks like half finished?
    // setCostingData(
    //   charge
    //     ? charge.hydratedJson
    //     : null,
    // );
  }, [ charge ]);

  const total1 = useMemo(() => (
    (floatVal(charge?.proratedSubtotal) ?? 0)
    + (floatVal(charge?.taxAmount) || 0)
  ), [ charge ]);

  const totalAfterDiscount = useMemo(() => (
    total1
    + (charge?.discountAmount ? floatVal(charge?.discountAmount) : 0)
  ), [ charge, total1 ]);
  const totalAfterProration = useMemo(() => (
    totalAfterDiscount
    * (charge?.prorationPercentage ? floatVal(charge?.prorationPercentage) : 0)
  ), [ charge, totalAfterDiscount ]);

  const invoices = useMemo(() => (
    (charge?.subscription?.invoices ?? []).filter(i => floatVal(i.amountInvoiced) !== 0)
  ), [ charge ]);

  return (
    <Container>
      <Row>
        <Col>
          <strong>Charge ID</strong>:<br />
          <CopiableText>{charge?.id || 'n/a'}</CopiableText>
        </Col>
        <Col><strong>Invoicing Month</strong>:<br />{charge?.year}-{charge?.month}</Col>
        <Col>
          <>
            <strong>Create Date:</strong>:<br />{charge?.created}
          </>
        </Col>
      </Row>
      <Row>
        <Col><strong>Prorated Subtotal</strong>:<br />{charge?.proratedSubtotal || 'n/a'} </Col>
        <Col><strong>Tax amount</strong>:<br />{charge?.taxAmount}</Col>
        <Col><strong>Total1</strong>:<br />{total1}</Col>
      </Row>
      <Row>
        <Col><strong>Proration</strong>:<br />{charge?.prorationPercentage || 'n/a'} </Col>
        <Col>
          <strong>Discount</strong>:<br />
          {charge?.discountAmount} ({charge?.discountPercentage})
        </Col>
        <Col><strong>Total2</strong>:<br />{totalAfterDiscount} / {totalAfterProration}</Col>
      </Row>
      <Row>
        <Col>
          <strong>Invoices ({invoices.length})</strong>:<br />
          <ul>
            {invoices.map(i => (
              <li>
                <Link to={`/invoices/${i.id}`}>
                  <>
                    {!i.invoiceNumber ? `PV#${i.id}` : i.invoiceNumber} [{i.invoiceDate}]
                  </>
                </Link>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
      <hr />
      <Spinner state={loadedState} />
      {
        charge?.usageItems
        && <>
          We have usage items! {charge.usageItems.length}
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Item Cost</th>
                <th>Prorated?</th>
                <th>Billing Type</th>
                <th>Property</th>
                <th>Partner(s)</th>
                <th>Charge</th>
                {/* <th>Invoice Line</th> */}
                <th>Subscription</th>
                <th>Phone Number</th>
                <th>Zone Coefficient</th>
                <th>Amount/Count</th>
                <th>UoM</th>
                <th>Created</th>
                <th>by</th>
                <th>Modified</th>
                <th>by</th>
              </tr>
            </thead>
            <tbody>
              {charge.usageItems.map((ui) => <UsageItemRow key={ui.id} usageItem={ui} />)}
            </tbody>
          </table>
        </>
      }
      {
        costingData
        && <>
          <Row>
            <Col><strong>SubscriptionId</strong>:<br />
              <CopiableText>
                <a
                  href={`https://superlift.theliftsystem.com/subscriptions/view/${costingData.subscription.id}`}
                  target='_blank'
                  rel='noreferrer noopener'
                >{costingData.subscription.id}</a>
              </CopiableText>
            </Col>
            <Col><strong>Type</strong>:<br />{costingData.billingTypeId}</Col>
            <Col><strong>usageItemCount</strong>:<br />{costingData.usageItems.length}</Col>
          </Row>
          <strong>Usage:</strong>
          <Table>
            <thead>
              <tr>
                <th>Building</th>
                <th>Partner(s)</th>
                <th>Unit</th>
                <th>Base Cost/Unit</th>
                <th>Zone Coefficient</th>
                <th>Cost/Unit</th>
                <th>Amount</th>
                <th>Before caps &amp; deductions</th>
              </tr>
            </thead>
            <tbody>
              {costingData.usageItems.map((ui) => (
                <>
                  <tr>
                    <td>{ui.buildingId}</td>
                    <td>{(ui.partnerIds || []).join(', ')}</td>
                    <td>{ui.unitOfMeasure}</td>
                    <td>{ui.itemCost}</td>
                    <td>{(ui.zoneCoefficient ?? 1)}</td>
                    <td>{(ui.itemCost ?? 0) * (ui.zoneCoefficient ?? 1)}</td>
                    <td>{ui.amount}</td>
                    <td>{(ui.itemCost ?? 0) * (ui.zoneCoefficient ?? 1) * ui.amount}</td>
                  </tr>
                  <tr>
                    <td colSpan={8}>{JSON.stringify(ui)}</td>
                  </tr>
                </>
              ))}
            </tbody>
          </Table>
        </>
      }
    </Container>
  );
};
