import { IApiQueryResponse } from '@llws/api-common';
import { logout } from './features/auth/actions';
import { showMessage } from './features/messaging/actions';

export enum ELoadState {
  /** Nothing happening */
  idle = 'idle',
  /** Waiting for response from a remote server. */
  pending = 'pending',
  /** Response received, not yet 'loaded' */
  loading = 'loading',
  /** Error occurred */
  error = 'error',
  /** Load is completed. */
  loaded = 'loaded',
}

export interface IActionType<T> {
  /** String identifier for action type */
  type: string,
  /** Payload/data included with action */
  payload: T,
}

export type IApiQuerySingularResponse<T> = Omit<IApiQueryResponse<T>, 'data'> & { data: T };
export type IApiQueryListResponse<T> = Omit<IApiQueryResponse<T>, 'data'> & { data: T[] };
export type IApiQueryListResponseMetaData = IApiQueryListResponse<unknown>['meta'];

export type TypedFetchResponse<T> = Omit<Response, 'json'> & {
  json(): Promise<T>;
};

export const getStoredToken = () => {
  return localStorage.getItem('token');
};

/**
 * @deprecated Use `typedApiFetch` instead for better typings.
 */
export const apiFetch = async (
  url: RequestInfo,
  init: RequestInit = {},
  options: { defaultJsonContent: boolean } = { defaultJsonContent: true },
) => {

  const token = getStoredToken();

  const contentType: string = (
    (init?.headers as Record<string, string>)?.['Content-Type']
    ?? (options.defaultJsonContent ? 'application/json' : undefined)
  );

  const headers = ({
    ...(['POST', 'PUT'].includes(init?.method ?? '') && contentType ? { 'Content-Type': contentType } : {}),
    ...(init?.headers || {}),
    ...(token ? { Authorization: 'Bearer ' + token } : {}),
  });

  const response = await fetch(url, {
    ...init,
    headers,
    credentials: 'same-origin',
  });

  if (response.status === 401) {
    showMessage({ message: 'User login not provided, expired or invalid. Please log in again.' });
    await logout();
  }

  if (response.status === 404) {
    showMessage({
      message: `Sorry! Cannot retrieve data from [${url}]. Please contact your system administrator for assistance.`,
      asAlert: true,
    });
  }

  if (response.status === 500) {
    showMessage({
      message: (
        `Sorry! There was an error on the server at the endpoint [${url}]. Try again.`
        + ` If this issue persists, please contact your system administrator for assistance.`
      ),
      asAlert: true,
    });
  }

  if (!response.ok && response.status !== 401) {
    showMessage({
      message: `Sorry! The response from the server at [${url}] returned an invalid status. Status:${response.status}.`,
      asAlert: true,
    });
  }

  return response;
};

export const typedApiFetch = async <TResponse>(
  url: RequestInfo,
  init: RequestInit = {},
  options: { defaultJsonContent: boolean } = { defaultJsonContent: true },
): Promise<TypedFetchResponse<TResponse>> => {
  return apiFetch(url, init, options);
};
