import React, { useEffect, useState } from "react";
import {
  Box, Checkbox, FormControlLabel, Grid, Paper, TextField, Tooltip,
} from "@mui/material";
import { Nullable, compactArray } from "@jamesgmarks/utilities";
import {
  createCheckoutFormSessionAndGetSessionId,
} from "src/redux/features/payments/actions";
import { useAppSelector } from "src/redux/hooks";

export const StripeContainer = () => {

  const [ paymentFirstInvoiceNumber, setPaymentFirstInvoiceNumber ] = useState<string>('');
  const [ paymentSecondInvoiceNumber, setPaymentSecondInvoiceNumber ] = useState<string>('');
  const [ paymentDescription, setPaymentDescription ] = useState<string>('');
  const [multiInvoicePayment, setMultiInvoicePayment ] = useState<boolean>(false);

  const getPaymentDetails = (invoiceNumber: string) => ({
    invoiceNumber: invoiceNumber,
    productDescription: `${paymentDescription} # ${invoiceNumber}`,
  });

  const handleCheckoutFromRedirect = async () => {
    const paymentDetails = compactArray([
      getPaymentDetails(paymentFirstInvoiceNumber),
      multiInvoicePayment ? getPaymentDetails(paymentSecondInvoiceNumber) : null,
    ]);
    const sessionUrl = await createCheckoutFormSessionAndGetSessionId(paymentDetails);

    if (!sessionUrl) {
      throw new Error(`No session url available`);
    }
    window.location.replace(sessionUrl);
  };
  return (
    <Grid container spacing={2} p={2} justifyContent="center" alignItems="center">
      <Grid container item xs={12} justifyContent="center" sx={{textAlign: 'center'}} spacing={2} p={2}>
        <Grid item xs={3}>
          <Tooltip title={'form hosted on stripe'}>
            <Paper
              elevation={3}
              sx={{
                backgroundColor: "#1ecbe1",
                '&:hover': { cursor: 'pointer', backgroundColor: "#1696a6" },
              }}
              onClick={() => {
                handleCheckoutFromRedirect();
              }}
            >
              <Box p={5}> Checkout Form</Box>
            </Paper>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item xs={6} sx={{textAlign: 'center'}}>
        <Box mt={2} mr={1.5}>
          <TextField
            label="Payment Description"
            value={paymentDescription}
            multiline
            onChange={(e) => setPaymentDescription(e.target.value)}
            fullWidth
          />
        </Box>
        <Box
          mt={2}
          sx={{
            '& > :not(style)': { mr: 2 },
          }}>
          <TextField
            label="Invoice Number # 1"
            value={paymentFirstInvoiceNumber}
            onChange={(e) => setPaymentFirstInvoiceNumber(e.target.value)}
          />
          <TextField
            label="Invoice Number # 2"
            disabled={!multiInvoicePayment}
            value={paymentSecondInvoiceNumber}
            onChange={(e) => setPaymentSecondInvoiceNumber(e.target.value)}
          />
        </Box>
        <Box>
          <FormControlLabel
            label="Enable Multiple Invoice Payments"
            control={
              <Checkbox
                checked={multiInvoicePayment}
                onChange={(e) => setMultiInvoicePayment(e.target.checked)}
              />
            }
          />
        </Box>
      </Grid>
    </Grid>
  );
};