import React, { useEffect, useState, useMemo } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import {
  Card,
  CardContent,
  Grid,
  TablePagination,
} from "@mui/material";
import { intVal, Nullable } from '@jamesgmarks/utilities';
import { useParams } from 'react-router-dom';
import { showSortIconIfAvailable } from '../../../app-utils/helpers';
import CustomDataTable from '../../parts/customDataTable/CustomDataTable';
import { rowsPerPageList } from './Tabs';
import { getMany } from '../../../redux/features/dynamic/actions';
import { Equal, IQueryCommandOptions } from '@llws/dynamic-router-utils';
import { IOwnershipGroupContactInfo, IOwnershipGroups } from '@llws/lift-entity-interfaces';
import { OwnershipGroupDetailsModal } from './OwnershipGroupDetailsModal';

export const OwnershipGroupsTab = () => {

  const ownershipGroups = useAppSelector(state => state.dynamic.data.ownership_groups?.list ?? []);
  const loadedState = useAppSelector(state => state.dynamic.data.ownership_groups?.loadedState);
  const metaData = useAppSelector(state => state.dynamic.metaData);

  const accountId = intVal(useParams<{ billingAccountId?: string }>().billingAccountId);

  const [ sortColumn, setSortColumn ] = useState('id');
  const [ sortDirection, setSortDirection ] = useState<`ASC` | `DESC`>(`ASC`);

  const [ page, setPage ] = useState<number>(1);
  const [ limit, setLimit ] = useState<number>(20);

  const [ openOwnershipGroupDetailsModal, setOpenOwnershipGroupDetailsModal ] = useState<boolean>(false);
  const [ activeOwnershipGroupId, setActiveOwnershipGroupId ] = useState<Nullable<number>>(null);

  const queryCommand: IQueryCommandOptions = useMemo(() => ({
    alias: 'o',
    relations: [
      { path: 'o.ownershipGroupHasBuildings', alias: 'oghb' },
      { path: 'o.ownershipGroupContactInfos', alias: 'ogci' },
    ],
    where: [Equal('o.freshbooksClientId', accountId)],
    page,
    limit,
    orderBy: [{field: sortColumn, direction: (sortDirection === `ASC` ? `asc` : `desc`)}],
  }), [accountId, page, limit, sortColumn, sortDirection]);

  useEffect(() => {
    getMany('ownership_groups', queryCommand);
  }, [queryCommand]);

  const sortBy = (criteria: string) => {
    setSortDirection(sortColumn === criteria && sortDirection === 'ASC' ? 'DESC' : 'ASC');
    setSortColumn(criteria);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLimit(intVal(event.target.value));
    setPage(1);
  };

  const openDetailsModal = (ogId: Nullable<number>) => {
    setActiveOwnershipGroupId(ogId);
    setOpenOwnershipGroupDetailsModal(true);
  };

  const handleModalSubmit = () => {
    setOpenOwnershipGroupDetailsModal(false);
    getMany('ownership_groups', queryCommand);
  };

  const customColumnsStyle = {
    width: '100%',
    backgroundColor: 'black',
    marginBottom: 1,
    fontWeight: 'bold',
    fontSize: '0.875rem',
    color: 'white',
  };
  const CustomColumns = () => {
    return (
      <>
        <Card sx={{ ...customColumnsStyle }}>
          <CardContent>
            <Grid container direction='row'>
              <Grid item xs sx={{cursor: 'pointer'}} onClick={() => sortBy('id')}>
                ID {showSortIconIfAvailable('id', sortColumn, sortDirection)}
              </Grid>
              <Grid item xs sx={{cursor: 'pointer'}} onClick={() => sortBy('name')}>
                Name {showSortIconIfAvailable('name', sortColumn, sortDirection)}
              </Grid>
              <Grid item xs>              
                Billing Address
              </Grid>
              <Grid item xs sx={{cursor: 'pointer'}} onClick={() => sortBy('ogci.careOf')}>
                Care Of {showSortIconIfAvailable('ogci.careOf', sortColumn, sortDirection)}
              </Grid>
              <Grid item xs>              
                Property Count
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  };

  const customRowStyle = {
    width: '100%',
    marginBottom: 1,
    fontSize: '0.875rem',
    backgroundColor: 'gray',
    color: 'white',
    cursor: 'pointer',
  };
  const CustomRow = ({ item }: { item: IOwnershipGroups }) => {
    const contactInfo: IOwnershipGroupContactInfo | undefined = item.ownershipGroupContactInfos?.find(
      (o) => o.freshbooksClientId === accountId,
    );
    const address: string = `${contactInfo?.address} ${contactInfo?.city} `
      + `${contactInfo?.province} ${contactInfo?.postal}`;
    return (
      <>
        <Card 
          sx={{ ...customRowStyle }}
          onClick={() => openDetailsModal(item.id ?? null)}
        >
          <CardContent>
            <Grid container direction='row' sx={{ marginBottom: 1}}>
              <Grid item xs>
                {item.id}
              </Grid>
              <Grid item xs>
                {item.name}
              </Grid>
              <Grid item xs>
                {address}
              </Grid>
              <Grid item xs>
                {
                  item.ownershipGroupContactInfos?.find(
                    (o) => o.freshbooksClientId === accountId,
                  )?.careOf
                }
              </Grid>
              <Grid item xs>
                {item.ownershipGroupHasBuildings?.length}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  };

  return (
    <>
      <TablePagination
        component="div"
        sx={{'& p': {marginTop: '1rem'}, backgroundColor: 'white'}}
        count={metaData?.totalRecords ?? 0} 
        page={metaData && metaData.currentPage ? metaData.currentPage - 1 : 0}
        rowsPerPage={limit}
        showFirstButton
        showLastButton
        rowsPerPageOptions={rowsPerPageList}
        onPageChange={(e, newPage)=>{setPage(newPage + 1);}}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
      <CustomDataTable
        data={
          (loadedState === 'loading' 
            ? [] 
            : (ownershipGroups ?? []))
        }
        rowComponent={CustomRow}
        columnsComponent={CustomColumns}
        loadingState={loadedState}
        dark={true}
        spacing={0}
      />
      <OwnershipGroupDetailsModal
        key = {activeOwnershipGroupId}
        showModal = {openOwnershipGroupDetailsModal}
        setShowModal = {setOpenOwnershipGroupDetailsModal}
        ownershipGroupId = {activeOwnershipGroupId}
        handleModalSubmit = {handleModalSubmit}
      />
    </>
  );
};