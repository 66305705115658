import { dispatch } from '../../store';
import {
  setLoadedState,
} from './chatBotSlice';
import {  ELoadState, IApiQuerySingularResponse, typedApiFetch } from '../../utils';
import { REACT_APP_API_ROOT_URI } from '../../../App';
import { messagesFromBotReceived } from './chatBotSlice';
import { ChatCompletionMessageParam } from 'openai/resources';

export const sendMessagesToChatBot = async (previousMessages: ChatCompletionMessageParam[], userMessage:string) => {
  dispatch(setLoadedState(ELoadState.loading));

  const messages = [
    ...previousMessages,
    {
      role: "user",
      content: userMessage,
    },
  ];
  const body= {
    messages,
  };

  console.log(messages);
  const url = `${REACT_APP_API_ROOT_URI}/chatbot/`;
  const response = await typedApiFetch<IApiQuerySingularResponse<ChatCompletionMessageParam[]>>(url, {
    method: 'POST',
    body: JSON.stringify(body),
  });
  console.log(response);

  const responseData = await response.json();
  console.log({message: responseData.data});
  const messageFromUser = responseData;
  dispatch(messagesFromBotReceived(responseData)); // TODO: Error handling?

  dispatch(setLoadedState(ELoadState.loaded));
};

