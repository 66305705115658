import { ReactChild, ReactElement } from "react";

interface IConditionalWrapProps {
  wrap: (wrappedChildren: ReactElement) => ReactElement;
  condition: boolean;
  children: ReactChild;
}

export const ConditionalWrap = ({ condition, wrap, children }: IConditionalWrapProps): ReactElement => (
  condition ? wrap(<div>{children}</div>) : <>{children}</>
);
