import { Spinner as BootstrapSpinner } from 'react-bootstrap';

export const Spinner = ({ state }: { state: string }) => (
  <>
    {state === 'loading' &&
      <BootstrapSpinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </BootstrapSpinner>
    }
  </>
);