import { floatVal } from "@jamesgmarks/utilities";
import { Grid, TextField } from "@mui/material";
import { handleInputChange } from "../../../../../app-utils/handlers";
import { isBasePriceBillingType } from "@llws/hydra-shared";

export const BasePrice = ({
  billingTypeId,
  basePrice,
  setBasePrice,
}: {
  billingTypeId: number | null;
  basePrice: number;
  setBasePrice: React.Dispatch<React.SetStateAction<number>>,
}) => {
  if (billingTypeId === null || !isBasePriceBillingType(billingTypeId)) { return <></>; }

  return (
    <>
      <Grid item xs={12}>
        <TextField
          id="baseSubscriptionBasePrice"
          variant="outlined"
          label="Base Price"
          name="baseSubscriptionBasePrice"
          type="number"
          value={basePrice ?? ''}
          onChange={handleInputChange(setBasePrice, floatVal)}
          size="small"
          fullWidth={true} />
      </Grid>
    </>
  );
};
