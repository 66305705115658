import { ReactNode } from 'react';

import {
  TableCell,
  tableCellClasses,
  TableRow,
  useTheme,
} from '@mui/material';

import { HashOf } from '@jamesgmarks/utilities';

interface StyledTableRowProps {
  children: ReactNode
  customSx?: {}
  dark?: boolean
  hover?: boolean
  hoverColor?: string
  shadedRowFirst?: boolean
  useDoubleRows?: boolean
}

export const StyledTableRow = ({
  children,
  customSx = {},
  dark = false,
  hover = false,
  hoverColor,
  shadedRowFirst = true,
  useDoubleRows = false,
  ...rest
}: StyledTableRowProps & HashOf<unknown>) => {
  const theme = useTheme();

  // Styles shared by both the `light` and `dark` variant
  const baseRowCss = {
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  };

  const DEFAULT_LIGHT = '#fff';
  const SHADED_LIGHT = theme.palette.action.hover;

  const lightRowCss = {
    ...(!useDoubleRows) && {
      '&:nth-of-type(odd)': {
        backgroundColor: shadedRowFirst ? SHADED_LIGHT : DEFAULT_LIGHT,
      },
    },
    ...(useDoubleRows) && {
      '&:nth-of-type(4n), &:nth-of-type(4n-1),': {
        backgroundColor: shadedRowFirst ? SHADED_LIGHT : DEFAULT_LIGHT,
      },
    },
    '&:hover': hover || hoverColor ? {
      backgroundColor: hoverColor ? hoverColor : 'rgba(231, 193, 228, 0.3)',
    } : {},
    backgroundColor: shadedRowFirst ? DEFAULT_LIGHT : SHADED_LIGHT,
  };

  const DEFAULT_DARK = '#3e3e3e';
  const SHADED_DARK = '#262626';

  const darkRowCss = {
    ...(!useDoubleRows) && {
      '&:nth-of-type(odd)': {
        backgroundColor: shadedRowFirst ? SHADED_DARK : DEFAULT_DARK,
      },
    },
    ...(useDoubleRows) && {
      '&:nth-of-type(4n-2), &:nth-of-type(4n-3)': {
        backgroundColor: shadedRowFirst ? SHADED_DARK : DEFAULT_DARK,
      },
    },
    '&:hover': hover || hoverColor ? {
      backgroundColor: hoverColor ? hoverColor : '#1873cc',
    } : {},
    backgroundColor: shadedRowFirst ? DEFAULT_DARK : SHADED_DARK,
  };

  const variantCss = dark ? darkRowCss : lightRowCss;

  return (
    <TableRow
      sx={{ ...baseRowCss, ...variantCss, ...customSx }}
      {...rest}
    >
      {children}
    </TableRow>
  );
};

interface StyledTableCellProps {
  children?: ReactNode
  dark?: boolean
  customSx?: {}
}

export const StyledTableCell = ({
  children,
  dark = false,
  customSx,
  ...rest
}: StyledTableCellProps & HashOf<unknown>) => {
  const theme = useTheme();

  const baseCellCss = {
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
    [`&.${tableCellClasses.footer}`]: {
      fontSize: 16,
      fontWeight: 'semibold',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.common.black,
    },
    padding: 1,
  };

  const lightCellCss = {

  };

  const darkCellCss = {
    [`&.${tableCellClasses.body}`]: {
      color: theme.palette.common.white,
    },
  };

  const variantCss = dark ? darkCellCss : lightCellCss;

  return (
    <TableCell
      align='center'
      sx={{ ...baseCellCss, ...variantCss, ...customSx }}
      {...rest}
    >
      {children}
    </TableCell>
  );
};
