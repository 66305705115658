import { createSlice } from '@reduxjs/toolkit';
import { IApiQueryResponse } from '@llws/api-common';
import { arrayWrap, Hash, Nullable } from '@jamesgmarks/utilities';
import { SalesReps } from '@llws/typeorm-entities';
import { ICommissionRecord } from './interfaces';

export interface ICommissionsState {
  loadedState: string,
  commissionSource: 'legacy' | 'hydra' | 'both',
  commissionsList: ICommissionRecord[],
  salesRepList: SalesReps[],
  nextPage: Nullable<string>,
  filters: Hash,
};

export const commissionsSlice = createSlice({
  name: 'commissions',
  initialState: {
    loadedState: 'idle',
    commissionSource: 'both',
    commissionsList: [],
    salesRepList: [],
    nextPage: null,
    filters: {},
  } as ICommissionsState,
  reducers: {
    salesRepListReceived: (state, action: { type: string, payload: IApiQueryResponse<SalesReps> }) => {
      state.salesRepList = arrayWrap(action.payload.data);
      console.log({ list: state.salesRepList });
    },
    commissionsPayableListReceived: (
      state,
      action: { type: string, payload: IApiQueryResponse<ICommissionRecord> },
    ) => {
      state.commissionsList = arrayWrap(action.payload.data);
      console.log({ list: state.commissionsList });
    },
    setLoadedState: (state, action: { type: string, payload: string }) => {
      state.loadedState = action.payload;
    },
    setCommissionSource: (state, action: { type: string, payload: 'legacy' | 'hydra' | 'both' }) => {
      state.commissionSource = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  commissionsPayableListReceived,
  salesRepListReceived,
  setLoadedState,
  setCommissionSource,
} = commissionsSlice.actions;

export default commissionsSlice.reducer;