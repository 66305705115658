import { createSlice } from '@reduxjs/toolkit';
import { IActionType } from '../../utils';
import { TApiValidatorSettings } from './interfaces';

interface IInitialState {
  settings: TApiValidatorSettings;
}

export const apiValidatorsSlice = createSlice({
  name: 'apiValidators',
  initialState: {
    settings: {},
  } as IInitialState,
  reducers: {
    receiveRouterSettings: (state, { payload }: IActionType<TApiValidatorSettings>) => {
      state.settings = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  receiveRouterSettings,
} = apiValidatorsSlice.actions;

export default apiValidatorsSlice.reducer;
