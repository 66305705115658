import { floatVal, Hash, intVal, Nullable } from "@jamesgmarks/utilities";
import { useEffect, useState } from "react";
import {
  Table, Container, Row, Col, Form, Button, Spinner,
} from "react-bootstrap";

// import IFbInvoice from '../../../../../freshbooks-api/models/IInvoices';
import { useAppSelector } from "../../../redux/hooks";
import {
  loadFreshbooksInvoices,
  loadFreshbooksInvoicesByClientId,
  loadFreshbooksInvoicesByInvoiceNumber,
} from '../../../redux/features/invoices/actions';
import { useQuery } from "../../../customHooks/useQuery";
import { Link } from "react-router-dom";

const InvoiceListDataRow = ({ checked, invoice /* setFilter, setSelected */ }: {
  checked: boolean,
  invoice: Hash,
  // setFilter: ((filterKey: string, filterValue: string) => void),
  // setSelected: ((runId: number, selected: boolean) => void),
}) => {
  // const fbData = invoice as IFbInvoice;
  return (
    <>
      <tr>
        <td>{invoice.invoiceId}</td>
        <td>{invoice.invoiceNumber}</td>
        <td>${floatVal(invoice?.amount?.amount ?? '0').toFixed(2)} ({invoice?.amount?.code})</td>
        <td>{invoice.createDate}</td>
        <td>{invoice.v3Status}</td>
        <td><Link to={`/freshbooks_invoices/${invoice.invoiceId}`}>Edit address</Link></td>
      </tr>
      {/* <tr><td colSpan={7}><pre>{JSON.stringify(invoice, null, 2)}</pre></td></tr> */}
    </>
  );
};

export const FreshbooksInvoices = () => {

  const [ clientIdFilter, setClientIdFilter ] = useState(null as Nullable<number>);
  const [ invoiceNumberFilter, setInvoiceNumberFilter ] = useState(null as Nullable<string>);

  const query = useQuery();

  // const [ freshbooksClientId, setFreshbooksClientId ] = useState(null as Nullable<number>)

  const invoices = useAppSelector(state => state.invoices.freshbooksInvoices);
  const loadedState = useAppSelector(state => state.invoices.loadedState);

  // const runId = query.get("billingRunId");
  const freshbooksClientId = query.get("freshbooksClientId");

  // const oldFreshbooksClientId = query.get("oldFreshbooksClientId");

  const reloadFreshbooksInvoices = () => {
    if ((invoiceNumberFilter?.length ?? 0) > 0) {
      loadFreshbooksInvoicesByInvoiceNumber({ invoiceNumber: invoiceNumberFilter || '' });
    } else if ((intVal(clientIdFilter) || 0) > 0) {
      loadFreshbooksInvoicesByClientId({ clientId: intVal(clientIdFilter) });
    }
  };

  if (!invoices && freshbooksClientId) {
    loadFreshbooksInvoices({ newFreshbooksClientId: intVal(freshbooksClientId)});
  }

  useEffect(() => {
    if (freshbooksClientId) loadFreshbooksInvoices({ newFreshbooksClientId: intVal(freshbooksClientId) });
  }, [freshbooksClientId]);

  return (
    <Container>
      <Form>
        <Row style={{padding: '.5rem'}}>
          <Col>
            <Row>
              <Form.Label column sm={3}>Client ID:</Form.Label>
              <Col sm={5}>
                <Form.Control
                  type="number"
                  placeholder="Client ID"
                  value={`${clientIdFilter}`}
                  onChange={(e) => {
                    setClientIdFilter(e.target.value ? intVal(e.target.value) : null);
                    e.preventDefault();
                  }} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{padding: '.5rem'}}>
          <Col>
            <Row>
              <Form.Label column sm={3}>Invoice Number:</Form.Label>
              <Col sm={5}>
                <Form.Control
                  type="text"
                  placeholder="Invoice Number"
                  value={invoiceNumberFilter ?? ''}
                  onChange={(e) => {
                    setInvoiceNumberFilter(e.target.value ?? null);
                    e.preventDefault();
                  }} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{padding: '.5rem'}}>
          <Col sm="auto">
            <div style={({ 'textAlign': 'right' })}>
              <Button
                variant='primary'
                onClick={() => { reloadFreshbooksInvoices(); }}
                disabled={
                  !(((invoiceNumberFilter?.length ?? 0) > 0) || ((intVal(clientIdFilter) || 0) > 0))
                  || (!!loadedState && loadedState !== 'loaded' && loadedState !== 'idle')
                }
              >{loadedState === 'loading' && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />)
                }{invoices ? 'Reload' : 'Search'}</Button>
            </div>
          </Col>
        </Row>
      </Form>

      { invoices &&
        <Table striped bordered hover variant="dark">
          <thead>
            <tr>
              <td>Invoice ID</td>
              <td>Invoice Number</td>
              <td>Amount:</td>
              <td>Date:</td>
              <td>Status</td>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {(invoices || []).map(i => <InvoiceListDataRow key={i.id} checked invoice={i} />)}
          </tbody>
        </Table>
      }
    </Container>
  );
};