import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './index.css';
import App from './App';
import { ContextProvider } from './components/pages/Developer/SocketContext';
import reportWebVitals from './reportWebVitals';
import { store } from './redux/store';

import packageJson from '../package.json';
import { setUiVersion } from './redux/features/systemInfo/actions';
setUiVersion(packageJson.version);

ReactDOM.render(
  <Provider store={store}>
    <ContextProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ContextProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
