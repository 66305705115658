import React from "react";

export const EditableContent: React.FC<{
  onFocus?: React.FocusEventHandler<HTMLSpanElement>,
  onInput?: React.FormEventHandler<HTMLDivElement>,
  onBlur?: React.FocusEventHandler<HTMLDivElement>,
  isEditable?: boolean,
  isEdited?: boolean,
  styleWhenEdited?: React.CSSProperties,
  selectOnFocus?: boolean,
}> = ({
  onFocus,
  onInput,
  onBlur,
  isEditable,
  isEdited,
  styleWhenEdited,
  children,
  selectOnFocus,
}) => {
  const editedStyle = styleWhenEdited ?? { color: 'red' };
  return (
    <span
      contentEditable={isEditable}
      suppressContentEditableWarning={true}
      onFocus={(e) => {
        if (selectOnFocus) {
          const cell = e.currentTarget;
          if (window.getSelection) {
            const selection = window.getSelection();
            const range = document.createRange();
            range.selectNodeContents(cell);
            selection?.removeAllRanges();
            selection?.addRange(range);
          }
        }
        if (onFocus) {
          onFocus(e);
        }
        onFocus?.(e);
      }}
      onInput={onInput}
      onBlur={onBlur}
      style={isEdited ? editedStyle : undefined}
    >{children}</span>
  );
};