import React from "react";
import { Spinner } from "react-bootstrap";

export const LoadingSpinner = ({
  loadedState,
}: {
  loadedState: string,
}) => {
  return (
    <h4 className="text-center">
      <br></br>
      Loading...
      <br></br>
      <br></br>
      <Spinner animation="border" role="status" variant="primary">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </h4>

  );
};