import { Typography } from "@mui/material";

import { Hash, isDefinedObject, keys, strVal } from "@jamesgmarks/utilities";
import { camelCaseToCapitalized } from "../../../../app-utils/helpers";

interface IKeyValueDisplayProps {
  indent?: number
  label: string
  value: string | string[] | Hash | boolean
}
  
export const KeyValueDisplay = (
  {
    indent = 0,
    label,
    value,
  }: IKeyValueDisplayProps,
) => {
  const indentColors: Record<number, string> = {
    0: 'dodgerblue',
    1: '#ff69b4',
    2: '#5d3fd3',
    3: '#40826D',
  };
    
  const valueColors: Hash = {
    'null': 'maroon',
    'true': '#0451a5',
    'false': '#0451a5',
  };
    
  const getValueColor = (valueStr: string) =>
    !isNaN(parseInt(valueStr)) && !strVal(valueStr).match(/\W/) ? '#76b947' : valueColors[valueStr];
  
  return (
    <Typography sx={{ pl: '3rem' }} variant='subtitle1' >
      <div style={{ display: 'inline-block', minWidth: '220px' }}>
        <span style={{ color: indentColors[indent % 4], fontWeight: 'semibold' }} >
          {`${camelCaseToCapitalized(label)}: `}
        </span>
      </div>

      <span style={{ color: 'slategray', fontFamily: 'monospace' }}>
        {
          isDefinedObject(value) && !Array.isArray(value)
            ?
            keys(value).map(
              (k) => (
                <KeyValueDisplay indent={indent + 1} key={k} label={strVal(k)} value={value[k]} />
              ),
            )
            :
            <span style={{ color: getValueColor(`${value}`) ?? 'inherit' }}>{`${value}`}</span>
        }
      </span>
    </Typography>
  );
};
