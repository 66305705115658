import { Hash } from '@jamesgmarks/utilities';
import { createSlice } from '@reduxjs/toolkit';
import { performApiActionRequest } from '../../apiActionRequest';
import { v4 as uuidv4 } from 'uuid';

export const alertButtonActions: Record<string, (error: IMessageDescription, userId?: unknown) => void> = {
  'say-hello': () => console.info('Hello'),
  'report-error': (messageDescription, userId) => {
    performApiActionRequest('reportError', {
      messageDescription,
      userId,
    });
  },
};

export type TActionButtonInfo = {
  actionButtonText: string,
  buttonAction?: keyof typeof alertButtonActions,
  redirectTo?: string,
}

export type TMessageLinesWithStyling = ({ text: string, style?: Hash }[])[]

export interface IMessageDescription {
  actionButtonInfo?: TActionButtonInfo,
  asAlert?: boolean,
  id?: string,
  message: string | TMessageLinesWithStyling,
  metaData?: Record<string, unknown>,
  severity?: 'success' | 'warning' | 'info' | 'error',
  timestamp: number,
}

export interface IMessagingState {
  errors: IMessageDescription[],
  embed : {
    show:boolean,
    url?: string
  }
};

export const messagingSlice = createSlice({
  name: 'messaging',
  initialState: {
    errors: [],
    embed: {
      show: false,
    },
  } as IMessagingState,
  reducers: {
    addMessage: (state, action: { type: string, payload: IMessageDescription }) => {
      const errorData = action.payload;
      if (!errorData.id) errorData.id = uuidv4();
      state.errors.push(action.payload);
    },
    clearSpecificMessage: (state, action : { type: string, payload: { id: string }}) => {
      state.errors = [
        ...state.errors.filter(e => e.id !== action.payload.id),
      ];
    },
    clearAllMessages: (state) => {
      state.errors = [];
    },
    clearFirstErrorMessage: (state, action) => {
      state.errors.pop();
    },
    setShowEmbed: (state, action) => {
      state.embed.show = action.payload;
    },
    setEmbedUrl: (state, action) => {
      state.embed.url = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addMessage,
  clearSpecificMessage,
  clearAllMessages,
  clearFirstErrorMessage,
  setShowEmbed,
  setEmbedUrl,
} = messagingSlice.actions;

export default messagingSlice.reducer;
