import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope, faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeLocaleDateTimeString } from "@hydra/internal";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { updateOne } from "../../redux/features/dynamic-hydra/actions";
import { Notification } from "../../../../entities/hydra"; // TODO: Are these in the shared package yet?
import { useCallback, useMemo, useState } from "react";

const NotificationList = ({ notifications }: { notifications: Notification[] }) => {
  const toggleNotificationReadStatus = useCallback((notification: Notification) => {
    const readStatus = notification.readStatus === 'unread' ? 'read' : 'unread';
    updateOne('notifications', JSON.stringify({ readStatus }), notification.id);
  }, []);

  if (notifications.length === 0) {
    return <>No Messages Found</>;
  }

  return <>
    <Grid container>
      {notifications.map((notification, index) => (
        <Grid item container key={notification.id} sx={{ backgroundColor: (index % 2 === 0 ? '#EEEEEE' : '#DDDDDD' )}}>
          <Grid item xs={10} padding='10px'>
            <div>
              <strong>{notification.subject}</strong>
              <small><em>[{makeLocaleDateTimeString(notification.created)}]</em></small>
            </div>
            {notification.body}
          </Grid>
          <Grid item xs={2} padding='10px' textAlign='right'>
            <FontAwesomeIcon
              icon={(notification.readStatus === 'unread' ? faEnvelope : faEnvelopeOpen) as IconProp}
              onClick={() => toggleNotificationReadStatus(notification)}
              style={{ cursor: 'pointer' }}
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  </>;
};

export const NotificationsModal = ({
  notifications,
  open,
  hide,
}: {
  notifications: Notification[],
  open: boolean
  hide: () => void,
}) => {
  type TStatusFilter = 'all' | 'unread';
  const [ statusFilter, setStatusFilter ] = useState<TStatusFilter>('all');

  const statusFilterFns: Record<TStatusFilter, ((n: Notification) => boolean)> = useMemo(() => {
    return {
      all: (n) => true,
      unread: (n) => n.readStatus === 'unread',
    };
  }, []);

  const messages = useMemo(() => {
    return notifications.filter(statusFilterFns[statusFilter]);
  }, [ notifications, statusFilter, statusFilterFns ]);

  const toggleStatusFilter = () => {
    setStatusFilter(statusFilter === 'all'? 'unread' : 'all');
  };
  const markAllRead = () => {
    notifications.filter(n => n.readStatus === 'unread').reduce(async (acc, n) => {
      await updateOne('notifications', JSON.stringify({ readStatus: 'read' }), n.id);
      return acc;
    }, {});
  };

  return <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={'lg'}>
    <DialogTitle id="form-dialog-title">Notifications</DialogTitle>
    <DialogContent>
      {/* <DialogContentText>
        Content Text
      </DialogContentText> */}
      <Grid container spacing={1}>
        <Grid item xs={12} textAlign="right">
          <Button onClick={toggleStatusFilter}>Show {statusFilter === 'all' ? 'Unread' : 'All'}</Button>
          <Button onClick={markAllRead}>Mark All Read</Button>
        </Grid>
        <Grid item xs={12}>
          <NotificationList notifications={messages} />
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button color="primary" onClick={hide}>
        Close
      </Button>
    </DialogActions>
  </Dialog>;
};
