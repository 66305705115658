import React from "react";
import { useAppSelector } from "../../../redux/hooks";

export const FreshbooksAuthorization = () => {
  const systemInfo = useAppSelector(state => state.systemInfo);

  const clientId = (
    systemInfo.env.NODE_ENV !== 'production'
      ? 'b078aa5e33a2282061c16ed63d2431a457aab0effb5a2ea6c85902942307e63a' // Dev client Id
      : '045dfd34372398cc7a96b7de8bed87bb9c55d82621c3f3a7da33510d3be24174' // Prod client Id
  );
  const redirectUri = (
    systemInfo.env.NODE_ENV !== 'production'
      ? 'https://localhost:5000/links/authorized' // Dev redirect
      : 'https://hydra-billing.rentsync.com/links/authorized' // Prod redirect
  );
  // client_id=045dfd34372398cc7a96b7de8bed87bb9c55d82621c3f3a7da33510d3be24174
  // redirect_uri=https://hydra-billing.rentsync.com/links/authorized
  /* <a style='color: black; text-decoration: none;' id='auth-link' href="https://auth.freshbooks.com/service/auth/oauth/authorize?client_id=045dfd34372398cc7a96b7de8bed87bb9c55d82621c3f3a7da33510d3be24174&response_type=code&redirect_uri=https://localhost:5000/freshbooks/authorized">Authorize</a> */

  return (
    <>
      <header className="Section-header">Freshbooks Authorization</header>
      Environment: {systemInfo.env.NODE_ENV}<br />
      <button>
        <a
          id='auth-link'
          target='_blank'
          rel='noreferrer'
          style={{ color: 'black', textDecoration: 'none' }}
          href={
            `https://auth.freshbooks.com/service/auth/oauth/authorize`
            + `?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}`
          }
        >Authorize</a>
      </button>
    </>
  );
};
