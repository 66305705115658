import { useRef, useState } from "react";
import { Link } from "react-router-dom";

import {
  Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper,
} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { capitalize } from "../../../app-utils/helpers";
import { EBillingRunStatus } from "./BillingRunFilters";
import { IBillingRun } from "../../../entity-interfaces/IBillingRun";
import { rerunBillingRun } from "../../../redux/features/billing-runs/actions";
import { StyledTableCell, StyledTableRow } from "../../parts/mui/StyledTables";
import { HashOf } from "@jamesgmarks/utilities";

interface IBillingRunDataRowProps {
  run: IBillingRun;
  setStatusFilter: (status: EBillingRunStatus, checked: boolean) => void;
}

export const BillingRunDataRow = ({
  run,
  setStatusFilter,
}: IBillingRunDataRowProps) => {
  const [ showFullDetails, setShowFullDetails ] = useState(false);

  const [ open, setOpen ] = useState(false);

  const anchorRef = useRef<HTMLDivElement>(null);

  const handleMenuItemClick = (
    optionKey: string,
  ) => {
    optionDetails[optionKey]?.handler();
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const optionDetails: HashOf<{
    label: string,
    handler: () => void,
    visible: boolean,
  }> = {
    rerunLocal: {
      label: 'Rerun Locally',
      handler: () => rerunBillingRun(run.id, { runLocally: true }),
      visible: true,
    },
    rerunInNewPod: {
      label: 'Rerun In New Pod',
      handler: () => rerunBillingRun(run.id),
      visible: true,
    },
  };

  return (
    <StyledTableRow dark={true} className="billing-runs-row">
      <StyledTableCell dark={true} className="billing-runs-column billing-run-id-column">{run.id}</StyledTableCell>

      <StyledTableCell dark={true} className="billing-runs-column billing-run-parent-id-column">
        { run.parentId ?? ' - ' }
      </StyledTableCell>

      <StyledTableCell dark={true} className="billing-runs-column billing-run-group-id-column">
        {
          run.clientRuntimeGroup?.label
            ? `${run.clientRuntimeGroup.label} (${run.clientRuntimeGroupId})`
            : run.clientRuntimeGroupId
        }
      </StyledTableCell>

      <StyledTableCell dark={true} className="billing-runs-column billing-run-month-column">
        {run.year}-{run.month}
      </StyledTableCell>

      <StyledTableCell
        dark={true}
        className="billing-runs-column billing-run-status-column"
        customSx={{
          '&:hover': { cursor: 'pointer' },
        }}
        onClick={
          () => {
            Object.keys(EBillingRunStatus)
              .filter((status) => run.status !== status)
              .forEach(
                (status) => {
                  setStatusFilter(EBillingRunStatus[status as keyof typeof EBillingRunStatus], false);
                },
              );
          }
        }
      >
        {capitalize(run.status)}
      </StyledTableCell>

      <StyledTableCell dark={true}
        className="billing-runs-column billing-run-details-column"
        customSx={{
          '&:hover': { cursor: 'pointer' },
        }}
        onClick={() => setShowFullDetails(!showFullDetails)}
      >
        {
          showFullDetails || (run.details?.length) <= 50
            ? run.details ?? ''
            : `${(run.details || '').slice(0, 50)}...`
        }
      </StyledTableCell>

      <StyledTableCell dark={true} className="billing-runs-column billing-run-record-created-column">
        {run.created}
      </StyledTableCell>

      <StyledTableCell dark={true} className="billing-runs-column billing-run-actions-column">
        {
          run?.id
          && run.status === EBillingRunStatus.error
          && (
            <>
              <ButtonGroup
                variant="contained"
                color='primary'
                ref={anchorRef}
                aria-label="split button"
              >
                <Button
                  color='warning'
                  onClick={() => run.id && rerunBillingRun(run.id)}
                >
                  Re-run
                </Button>

                <Button
                  color="secondary"
                  size="small"
                  aria-controls={open ? 'split-button-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={handleToggle}
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>

              <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition
                nonce={undefined}
                onResize={() => {}}
                onResizeCapture={() => {}}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={(e) => handleClose(e)}>
                        <MenuList id="split-button-menu">
                          {Object.keys(optionDetails).map((optionKey, index) => (
                            optionDetails[optionKey].visible && (
                              <MenuItem
                                key={optionKey}
                                onClick={() => handleMenuItemClick(optionKey)}
                              >
                                {optionDetails[optionKey].label}
                              </MenuItem>
                            )
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </>
          )
        }
        {
          run.status === EBillingRunStatus.completed
          && !!run.parentId
          && <Link to={`/invoices?billingRunId=${run.id}`}><Button variant='contained'>Invoices</Button></Link>
        }
      </StyledTableCell>

    </StyledTableRow>
  );
};
