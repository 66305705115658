import { useEffect } from "react";

import { Route, Switch } from "react-router-dom";

import { assertMonthNumber } from "../../../interfaces/IYearMonth";
import { BillingRunListView } from "./BillingRunListView";
import { BillingRunSchedules } from "./BillingRunSchedules";
import { CreateBillingRunForm } from "./CreateBillingRunForm";
import { refreshBillingRuns } from "../../../redux/features/billing-runs/actions";
import { useAppSelector } from "../../../redux/hooks";

export interface IBillingRunFilters {
  status?: string,
  details?: string,
}

/**
 * Provides the initial year/month search parameters for the Billing Runs UI filters.
 * @returns A two-member array with the year and the month to populate the filters with.
 */
export const getInitialYearMonthFilterValues = () => {
  const today = new Date();
  const startDateOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);

  return {
    searchYear: startDateOfLastMonth.getFullYear(),
    lastMonth: assertMonthNumber(startDateOfLastMonth.getMonth() + 1),
  };
};

export const BillingRunsContainer = () => {
  const billingRuns = useAppSelector(state => state.billingRuns.runs);

  useEffect(() => {
    const { searchYear, lastMonth } = getInitialYearMonthFilterValues();

    refreshBillingRuns(
      {
        startYear: searchYear,
        startMonth: lastMonth,
        endYear: searchYear,
        endMonth: lastMonth,
      },
    );
  }, []);

  return (
    <>
      <header className="Section-header">Billing Runs</header>

      <Switch>
        <Route path="/billing_runs/create"><CreateBillingRunForm /></Route>
        <Route path="/billing_runs/schedules"><BillingRunSchedules /></Route>
        <Route path="/billing_runs"><BillingRunListView billingRuns={(billingRuns || [])} /></Route>
      </Switch>
    </>
  );
};
