import { IApiQueryResponse } from '@llws/api-common';
import { dispatch } from '../../store';
import {
  ISystemInfoState,
  systemInfoReceived,
  requiredVersionReceived,
  versionReceived,
} from './systemInfoSlice';
import { apiFetch } from '../../utils';
import { REACT_APP_API_ROOT_URI } from '../../../App';

export const getSystemInfo = async () => {
  const url = `${REACT_APP_API_ROOT_URI}/developer/env`;
  const response = await apiFetch(url);
  const responseData = await response.json() as IApiQueryResponse<ISystemInfoState>;
  // console.log({ status: response.status, responseData });
  dispatch(systemInfoReceived(responseData)); // TODO: Error handling?
};

export const setUiVersion = async (version: string) => {
  dispatch(versionReceived(version));
};
export const setRequiredUiVersion = async (version: string) => {
  dispatch(requiredVersionReceived(version));
};