import { intVal, Nullable } from "@jamesgmarks/utilities";
import { IBillingContacts } from "@llws/lift-entity-interfaces";
import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle,
  Paper,
  TableContainer,
  Table as MuiTable,
  TableHead,
  TableBody,
  Grid,
  TextField,
  TablePagination,
  IconButton,
  Collapse,
  CircularProgress,
  Box,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import React from "react";
import { useEffect, useState } from "react";
import { loadAllBillingContacts } from "../../../redux/features/billing-contacts/actions";
import { useAppSelector } from "../../../redux/hooks";
import { StyledTableCell, StyledTableRow } from "../../parts/mui/StyledTables";
import { rowsPerPageList } from "./Tabs";
import { useParams } from "react-router-dom";
import { saveClientBillingContact } from "src/redux/features/clients/actions";
import { isBillingAccountIdValid } from "./ContactsTab";
import { showSortIconIfAvailable } from "src/app-utils/helpers";
import { AssignExtraInfoToBillingContact } from "./AssignExtraInfoToBillingContact";

const ExpandableRow = (
  {
    billingContact, 
    openCard,
    billingAccountId,
  }: {
    billingContact: IBillingContacts, 
    openCard: boolean,
    billingAccountId: number
  },
) => {
 
  const addClientHasBillingContact = (ownershipGroupId: Nullable<number>, receivesInvoices: number) => {
    saveClientBillingContact({
      freshbooksClientId: billingAccountId,
      billingContactId: billingContact.id!,
      ownershipGroupId,
      receivesInvoices,
    });
  };

  return (
    <StyledTableRow customSx={{ userSelect: 'none', backgroundColor: '#545454'}}
      dark={true}>
      <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
        <Collapse in={openCard} timeout="auto" unmountOnExit>
          <AssignExtraInfoToBillingContact
            billingAccountId={billingAccountId}
            handleActionButtonClick={addClientHasBillingContact}
            defaultOwnershipGroupId={null}
            defaultReceivesInvoices={0}
          />
        </Collapse>
      </StyledTableCell>
    </StyledTableRow>
  );
};

const BillingContactRow = ({
  billingContact,
  billingAccountId,
}:{
  billingContact: IBillingContacts,
  billingAccountId: number,
}) => {
  const [openCard, setOpenCard] = useState(false);
  
  return (
    <>
      <StyledTableRow
        customSx={{ userSelect: 'none' }}
        dark={true}
        hover={true}
      >
        <StyledTableCell dark={true} style={{ whiteSpace: 'nowrap' }}>
          {billingContact.name}
        </StyledTableCell>
        <StyledTableCell dark={true} style={{ whiteSpace: 'nowrap' }}>
          {billingContact.email}
        </StyledTableCell>
        <StyledTableCell dark={true} style={{ whiteSpace: 'nowrap' }}>
          {billingContact.phone ?? ''}
        </StyledTableCell>
        <StyledTableCell dark={true} style={{ whiteSpace: 'nowrap' }}>
          {billingContact.phone2 ?? ''}
        </StyledTableCell>
        <StyledTableCell dark={true} style={{ whiteSpace: 'nowrap' }}>
          <IconButton
            aria-label="expand row"
            size="small"
            color="inherit"
            onClick={() => setOpenCard(!openCard)}
          >
            <AddIcon />
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
      <ExpandableRow 
        key={billingContact.id} 
        billingContact={billingContact} 
        openCard={openCard}
        billingAccountId={billingAccountId}
      />
    </>
  );
};

const BillingContactListRow = React.memo(BillingContactRow);

export const AddExistingBillingContactsModal = ({
  showModal,
  setShowModal,
}: {
  showModal: boolean,
  setShowModal: ((newState: boolean) => void),
}) => {

  const billingContacts = useAppSelector((state) => state.billingContacts.billingContactsList);
  const billingAccountId = intVal(useParams<{ billingAccountId?: string }>().billingAccountId);
  const billingAccount = useAppSelector((state) => state.clients.currentClientBillingAccount);
  const loadedState = useAppSelector((state) => state.billingContacts.loadedState);
  const metaData = useAppSelector((state) => state.billingContacts.metaData);

  const [searchFilterValue, setSearchFilterValue] = useState('');
  const [filter, setFilter] = useState('');
  const [sortCriteria, setSortCriteria] = useState('id');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('DESC');
  const [ page, setPage ] = useState(1);
  const [ rowsPerPage, setRowsPerPage ] = useState(20);

  useEffect(() => {
    if (billingAccount && isBillingAccountIdValid(billingAccount, billingAccountId)) {
      loadAllBillingContacts({
        searchFilter: filter,
        page,
        pageSize: rowsPerPage,
        sortCriteria,
        sortDirection,
      });
    }
  }, [billingAccountId, billingAccount, filter, page, rowsPerPage, sortCriteria, sortDirection]);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(intVal(event.target.value));
    setPage(1);    
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const sortBy = (criteria: string) => {
    setSortCriteria(criteria);
    sortCriteria === criteria && sortDirection === 'ASC' ? setSortDirection('DESC') : setSortDirection('ASC');
    setPage(1);
  };

  const search = () => {
    setFilter(searchFilterValue);
  };
  return (
    <Dialog 
      id="modal-existing-billing-contact"
      open={showModal} 
      aria-labelledby="form-existing-billing-contact-dialog" 
      scroll="paper" 
      maxWidth='lg' 
      onClose={handleClose}
    >
      <DialogTitle id="form-existing-billing-contact-dialog">Add an Existing Billing Contact</DialogTitle>
      <DialogContent dividers>      
        <Grid container spacing={2} mt={2}  justifyContent="center" alignItems="center">
          <Grid item xs={12} component={Paper} ml={2} mb={2} sx={{ background: '#f5f5f5' }}>
            <Grid container spacing={1} mb={2} alignItems="center">
              <Grid item xs={2}>
                Search:
              </Grid>
              <Grid item xs={10} pr={2}>
                <TextField 
                  fullWidth 
                  size="small" 
                  label={'Search'} 
                  sx={{ background: '#fff' }}
                  onChange={(e) =>  setSearchFilterValue(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') search();
                  }}
                  value={searchFilterValue}
                />
              </Grid>
              <Grid item container  justifyContent="flex-end">
                <Grid item justifyContent="flex-end" pr={2}>
                  <Button
                    style={{ verticalAlign: 'middle' }}
                    variant='contained'
                    disabled={loadedState === 'loading'}
                    onClick={search}
                  >
                    Search
                    {loadedState === 'loading' && <CircularProgress size={25} color='primary' sx={{ml: 2}} />}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <TableContainer component={Paper} elevation={12} sx={{mb: 1, ml: 2}}>
            <TablePagination
              component="div"
              sx={{'& p': {marginTop: '1rem'}}}
              count={metaData?.totalRecords ?? 0} 
              page={metaData?.currentPage ? metaData?.currentPage - 1: 0}
              rowsPerPage={metaData?.recordsPerPage ?? 0}
              showFirstButton
              showLastButton
              rowsPerPageOptions={rowsPerPageList}
              onPageChange={(e, newPage)=>{handlePageChange(newPage + 1);}}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
            <MuiTable style={{ borderCollapse: 'collapse' }}>
              <TableHead>
                <StyledTableRow dark={true} style={{ cursor: 'pointer' }}>
                  <StyledTableCell
                    dark={true}
                    style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                    onClick={() => sortBy('name')}
                  >
                    Name {showSortIconIfAvailable('name', sortCriteria, sortDirection)}
                  </StyledTableCell>
                  <StyledTableCell
                    dark={true}
                    style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                    onClick={() => sortBy('email')}
                  >
                    Email Address {showSortIconIfAvailable('email', sortCriteria, sortDirection)}
                  </StyledTableCell>
                  <StyledTableCell
                    dark={true}
                    style={{ whiteSpace: 'nowrap', fontWeight: 'bold', sortCriteria, sortDirection }}
                    onClick={() => sortBy('phone')}
                  >
                    Phone {showSortIconIfAvailable('phone', sortCriteria, sortDirection)}
                  </StyledTableCell>
                  <StyledTableCell
                    dark={true}
                    style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                    onClick={() => sortBy('phone2')}
                  >
                    Secondary Phone {showSortIconIfAvailable('phone2', sortCriteria, sortDirection)}
                  </StyledTableCell>
                  <StyledTableCell
                    dark={true}
                    style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                  />
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {
                  (billingContacts ?? [])
                    .map((i) => (
                      <BillingContactListRow
                        key={i.id}
                        billingContact={i}
                        billingAccountId={ billingAccountId }
                      />
                    ))
                }
              </TableBody>
            </MuiTable>
          </TableContainer>
          { loadedState === 'loading' 
            ? <CircularProgress size={25} color='primary' sx={{ml: 2}} />
            : metaData?.totalRecords === 0 && <Box>NO DATA AVAILABLE</Box>
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
            Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};