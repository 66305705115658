import { IBillingAddress } from '../../../../rentsync-api/invoicing/IBillingAddress';
import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/system';
import SaveIcon from '@mui/icons-material/Save';
import {
  Button, Card, Grid, TextField, Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { keys } from '@jamesgmarks/utilities';
import { capitalize } from '@hydra/internal';

const AddressEditing = (
  {
    billingAddress,
    onChange,
  }:{
    billingAddress:IBillingAddress,
    onChange: (billingAddress:IBillingAddress) => void
  },
) => {
  return <>
    {
      keys(billingAddress).map((key)=>{
        return <>
          <Typography>{capitalize(key)}:</Typography>
          <TextField
            value={billingAddress[key]}
            onChange={(e) => onChange({
              ...billingAddress,
              [key]: e.currentTarget.value,
            })}>  </TextField>
        </>;
      })
    }
  </>;
};

const AddressDisplay = ({billingAddress}:{billingAddress:IBillingAddress}) => {
  return <Box>
    {
      billingAddress?.organization
    && <>  <Typography gutterBottom variant="h5" component="div">
      {billingAddress?.organization}
    </Typography></>
    }
    {billingAddress?.street1 && <div>{billingAddress?.street1}</div>}
    {billingAddress?.street2 && <div>{billingAddress?.street2}</div>}
    <div>
      {billingAddress?.city && `${billingAddress?.city}`}
      {billingAddress?.province && `, ${billingAddress?.province}`}
      {billingAddress?.postalCode && `, ${billingAddress?.postalCode}`}
    </div>
    {billingAddress?.country && <div>{billingAddress?.country}</div>}
  </Box>;
};

export const BillingAddressCard = ({ billingAddress, onSave }: {
  billingAddress: IBillingAddress,
  onSave?: (address:IBillingAddress) => void
}) => {

  const [isEditing, setIsEditing] = useState(false);
  const [editedAddress, setEditedAddress] = useState(billingAddress ?? null);

  useEffect(()=>{
    setEditedAddress(billingAddress);
  }, [billingAddress]);

  return <Card variant="outlined">
    <Grid container sx={{p: 3}}>
      <Grid item xs={10}>
        {
          isEditing
            ? <AddressEditing billingAddress={editedAddress} onChange={setEditedAddress}></AddressEditing>
            : <AddressDisplay billingAddress={billingAddress}></AddressDisplay>
        }
      </Grid>
      <Grid item xs={2}>

        {
          isEditing
            ?<Button variant="outlined" startIcon={<SaveIcon />} onClick={() => {
              onSave && onSave(editedAddress);
              setIsEditing(prev => !prev);
            }}>
              Save
            </Button>
            :<Button variant="outlined" startIcon={<EditIcon />} onClick={() => setIsEditing(prev => !prev)}>
              Edit
            </Button>

        }
      </Grid>
    </Grid>
    <Box>

    </Box>

  </Card>;
};