import { floatVal, intVal } from "@jamesgmarks/utilities";
import { Subscriptions } from "@llws/typeorm-entities";
import { BILLING_TYPE_LISTING } from '@llws/hydra-shared';
import { InvoiceLine } from "../../../entities/hydra";

/**
 *
 * @param year A four digit year.
 * @param month A month number between 1-12.
 */
export const getMonthStart = (year: number, month: number) => new Date(year, month - 1, 1);
/**
  *
  * @param year A four digit year.
  * @param month A month number between 1-12.
  */
export const getMonthEnd = (year: number, month: number, endOfDay: boolean = true) => (
  endOfDay
    ? new Date(year, month, 0, 23, 59, 59, 999)
    : new Date(year, month, 0)
);

export const getLastDayOfThisMonth = () => {
  const today = new Date();
  return getMonthEnd(today.getFullYear(), today.getMonth() + 1, false);
};

const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const roundFloatToCentsAsNumber = (float: number, algorithm: 'up' | 'down' | 'nearest' = 'nearest') => {
  // eslint-disable-next-line no-nested-ternary
  const rounder = algorithm === 'nearest' ? Math.round : (algorithm === 'up' ? Math.ceil : Math.floor);
  const nearestPennyUp = `${rounder(float * 100)}`.padStart(2, '0');

  return parseFloat(`${nearestPennyUp.slice(0, -2)}.${nearestPennyUp.slice(-2)}`);
};

export const roundedInvoiceLineTotal = (line: InvoiceLine) => {
  return roundFloatToCentsAsNumber(+(line.lineTotal ?? '0'), "nearest");
};

export const roundFloatToCents = (float: number, algorithm: 'up' | 'down' | 'nearest' = 'nearest') => {
  const absFloat = Math.abs(float);
  const isNegativeMultiplier = float < 0 ? -1 : 1;
  // eslint-disable-next-line no-nested-ternary
  const rounder = algorithm === 'nearest' ? Math.round : (algorithm === 'up' ? Math.ceil : Math.floor);
  const nearestPennyUp = `${rounder(absFloat * 100)}`.padStart(2, '0');

  return (
    usdFormatter
      .format(parseFloat(`${nearestPennyUp.slice(0, -2)}.${nearestPennyUp.slice(-2)}`) * isNegativeMultiplier)
  );
};

export const toDollarAmount = (
  num: number,
  negativeStrategy: 'brackets' | 'symbolPrefix' = 'symbolPrefix',
  fractionalDigits: number = 2,
) => {
  const applyNegativeStrategy = (number: string) => {
    const isNegative = number.startsWith('$-');
    return (isNegative && negativeStrategy === 'brackets')
      ? `($${number.slice(2)})`
      : `${isNegative ? '-$' : '$'}${number.slice(isNegative ? 2 : 1)}`;
  };
  const formattedNum = applyNegativeStrategy('$' + num.toFixed(fractionalDigits).replace(/\B(?=(\d{3})+(?!\d))/g, ","));
  return formattedNum;
};

export const dollarToFloat = (currency: string) => Number(currency.replace(/[^0-9.-]+/g, ""));

export const ucaseFirst = (str: string) => (`${str.slice(0, 1).toUpperCase()}${str.slice(1).toLowerCase()}`);

const monthNames = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December',
];
export const getMonthName = (date: Date) => monthNames[date.getMonth()];

export const isDST = (date: Date) => {
  let jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
  let jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
  return Math.max(jan, jul) !== date.getTimezoneOffset();
};

/**
 * Convenience method to add a number of days to a date.
 * @param date Start date
 * @param days Days to add
 */
export const addDays = (date: Date, days: number) => {
  const addedDate = new Date(date);
  addedDate.setDate(addedDate.getDate() + days);
  return addedDate;
};

/**
 * Convenience method to subtract a number of days to a date.
 * @param date Start date
 * @param days Days to subtract
 */
export const subtractDays = (date: Date, days: number) => {
  const negativeDays = days < 0 ? days : days * -1;
  return addDays(date, negativeDays);
};

export const makeYmd = (date: Date, zeroPad: boolean = false): string => {
  const _month = `${date.getMonth() + 1}`.padStart(zeroPad ? 2 : 1, '0');
  const _date = `${date.getDate()}`.padStart(zeroPad ? 2 : 1, '0');
  return `${date.getFullYear()}-${_month}-${_date}`;
};
/**
 * Add 0 or 1 characters to the beginning of `str`, default '0'
 * @param str String to add the prefix to.
 * @param padCharacter Optional. String to use as the prefix.
 */
export const prefix = (str: string, padCharacter?: string): string => str.padStart(2, padCharacter ?? '0');
/**
 * Return hours portion of a Date with a 0 prefix
 */
export const getPaddedHours = (date: Date): string => prefix(`${date.getHours()}`);
/**
  * Return minutes portion of a Date with a 0 prefix
  */
export const getPaddedMinutes = (date: Date): string => prefix(`${date.getMinutes()}`);
/**
  * Return seconds portion of a Date with a 0 prefix
  */
export const getPaddedSeconds = (date: Date): string => prefix(`${date.getSeconds()}`);

/**
 * Make a ymdhms string (Ex: '2013-10-01 13:53:13' - 24 hour clock) from a provided date.
 * @param date The date to convert to a string.
 */
export const makeYmdHms = (date: Date): string => (
  `${makeYmd(date)} ${getPaddedHours(date)}:${getPaddedMinutes(date)}:${getPaddedSeconds(date)}`
);

export const getNumDaysBetween = (
  startDate: Date,
  endDate: Date,
): number => Math.abs(Math.round((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)));

//Math.round((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
//Math.abs(Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)));

const isPayDate = (currentDate: Date) => {
  const firstPayDate = new Date(2010, 8, 15);
  const difference = getNumDaysBetween(firstPayDate, currentDate);

  return ((difference % 14) === 0);
};

// Recursive method to find next pay day
export const getNextPayDate = (today: Date = new Date(new Date().setHours(0, 0, 0, 0)), days = 0): Date => {
  const addedDate = addDays(today, days);
  return isPayDate(addedDate) ? addedDate : getNextPayDate(today, days + 1);
};

export const dateFromYmd = (ymdDate: string): Date => {
  // TODO: Sensible validator for YMD string.
  const [year, month, date] = (ymdDate ?? '').split('-');
  const today = new Date();
  return new Date(
    year ? intVal(year) : today.getFullYear(),
    month ? intVal(month) - 1 : today.getMonth(),
    date ? intVal(date) : today.getDate(),
  );
};
export const dateFromYmdHms = (ymdHmsDate: string): Date => {
  const [ymdDate, hms] = ymdHmsDate.split(' ');
  const [year, month, date] = ymdDate.split('-').map(intVal);
  const [hours, minutes, seconds] = (hms ?? '0:00:00').split(':').map(intVal);
  return new Date(year, month - 1, date, hours, minutes, seconds);
};

export const flattenOneLevel = <T>(usageOrCostArray: Array<Array<T>>): T[] => (
  usageOrCostArray.reduce((acc, curr) => [ ...acc, ...curr ], [])
);

/**
 * Validates that a string represents a valid year (1000+).
 * @param year A string representing a four-digit year.
 * @returns A Boolean indicating whether or not the string representation is valid.
 */
export const isValidYear = (year: string) => !!year.match(/^[12][0-9]{3}$/);

/**
 * Validates that a string represents a valid month (1 - 12).
 * @param month A string representing a month.
 * @returns A Boolean indicating whether or not the string representation is valid.
 */
export const isValidMonth = (month: string) => [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].includes(intVal(month));

/**
 * @deprecated Use `camelCaseToCapitalized()`
 * Converts a string in 'camelCase' form to a 'Title Case' string.
 * @param camelCase A 'camelCase' string.
 * @returns A string in 'Title Case'.
 */
export const camelToTitleCase = (camelCase: string) => {
  const result = camelCase.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

/**
 * Converts a string in 'snake_case' form to a 'camelCase' string.
 * @param snakeCase A 'snake_case' string.
 * @param returns A string in 'camelCase'.
 */
export const snakeToCamelCase = (snakeCase: string) => (
  snakeCase.replace(/([_][a-z])/g, ($1) => $1.toUpperCase().replace('_', ''))
);

// TODO: DUPLICATE FUNCTION
export const getDiscountRateFromSubscription = (subscription: Subscriptions): string => {
  const billingTypesFns = [
    {
      billingType: BILLING_TYPE_LISTING,
      getDiscountRate: (s: Subscriptions) => (
        floatVal(s.parentSubscription?.discountRate ?? '0') !== 0
          ? s.parentSubscription.discountRate
          : s.discountRate
      ),
    },
  ];
  const matchedItem = billingTypesFns.find(
    (btf) => btf.billingType === subscription.baseSubscription.service.billingTypeId,
  );

  return matchedItem?.getDiscountRate(subscription) ?? subscription.discountRate;
};
