import { ReactNode, useState, SyntheticEvent } from "react";

import { Box, Tab, Tabs } from "@mui/material";
import { InvoicesTab } from "./InvoicesTab.";
import { ChargesTab } from "./ChargesTab";
import { ContactsTab } from "./ContactsTab";
import { SubscriptionsTab } from './SubscriptionsTab';
import { OwnershipGroupsTab } from "./OwnershipGroupsTab";

interface ITabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}
  
const TabPanel = ({ children, value, index, ...other }: ITabPanelProps) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {
      value === index
        && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )
    }
  </div>
);

export const rowsPerPageList = [10, 20, 50, 100, 150, 200, 250, 500, 1000, 2000];
  
export const TabMenu = () => {
  const [ value, setValue ] = useState(0);
  
  return (
    <>
      <Box sx={
        {
          background: 'rgba(224,224,224,0.8)', 
          borderBottom: 1,
          borderColor: 'divider',
          borderRadius: '8px',
          mx: 1,
          pb: 0.5,
        }
      }>
        <Tabs value={value} onChange={(_e, newValue) => setValue(newValue)}>
          <Tab label='Invoices' />
          <Tab label='Contacts' />
          <Tab label='Charges' />
          <Tab label='Subscriptions' />
          <Tab label='Ownership Groups' />
          {/* <Tab label='Credits' />
            <Tab label='Contacts' />
            <Tab label='Runs' />
            <Tab label='Clients' /> */}
        </Tabs>
      </Box>
      <Box sx={
        {
          height: 800,
          maxHeight: 1000,
          mb: 2,
          overflow: 'hidden',
          overflowY: 'auto',
          width: '100%',
        }
      }>
        <TabPanel value={value} index={0}>
          <InvoicesTab />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ContactsTab />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ChargesTab />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <SubscriptionsTab />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <OwnershipGroupsTab />
        </TabPanel>
        {/* <TabPanel value={value} index={1}>
            <Box>Credits</Box>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Box>Contacts</Box>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Box>Runs</Box>
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Box>Clients</Box>
          </TabPanel> */}
      </Box>
    </>
  );
};
