import { Hash, keys } from "@jamesgmarks/utilities";

export type TMonthNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface IYearMonth {
  year: number
  month: TMonthNumber
}

/** Type guard for `TMonthNumber`, which is an integer between 1 and 12 (inclusive). */
export const isMonthNumber = (month: TMonthNumber | number): month is TMonthNumber => (
  Number.isInteger(month) && month >= 1 && month <= 12
);

/** Assert that a value is of type `TMonthNumber`, Return it if so, or throw `Error`. */
export const assertMonthNumber = (month: TMonthNumber | number): TMonthNumber | never => {
  if (!isMonthNumber(month)) {
    throw new Error(`Provided month is not assignable to type \`TMonthNumber\`: ${month}`);
  }

  return month;
};

/** Type guard for `IYearMonth`, which contains integer `year` and `month` (1 - 12) properties. */
const isYearMonth = (yearMonth: IYearMonth | Hash): yearMonth is IYearMonth => (
  keys(yearMonth).length === 2
  && keys(yearMonth).includes('year')
  && keys(yearMonth).includes('month')
  && Number.isInteger(yearMonth.year)
  && isMonthNumber(yearMonth.month)
);

/** Assert that a value satisfies the `IYearMonth` interface, Return it if so, or throw `Error`. */
export const assertYearMonth = (yearMonth: IYearMonth | Hash): IYearMonth | never => {
  if (!isYearMonth(yearMonth)) {
    throw new Error(`Provided object does not implement the \`IYearMonth\` interface`);
  }

  return yearMonth;
};
