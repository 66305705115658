import { HashOf, intVal, Nullable } from "@jamesgmarks/utilities";
import { 
  Paper,
  TableContainer,
  Table as MuiTable,
  TableHead,
  TableBody,
  Button,
  IconButton,
  Collapse,
  Grid,
  TextField,
  TablePagination,
  Tooltip,
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditIcon from '@mui/icons-material/Edit';
import { useCallback, useEffect, useMemo, useState } from "react";
import React from "react";
import { useParams } from "react-router-dom";
import { AddExistingBillingContactsModal } from "./AddExistingBillingContactsModal";
import { CreateBillingContactModal } from "./CreateBillingContactModal";
import { StyledTableCell, StyledTableRow } from "../../parts/mui/StyledTables";
import { IBillingAccount } from "../../../entity-interfaces/IBillingAccount";
import { loadClientBillingContacts } from "../../../redux/features/clients/actions";
import { useAppSelector } from "../../../redux/hooks";
import { BillingContacts, ClientHasBillingContacts } from "@llws/typeorm-entities";

import { OwnershipGroupDropdown } from "../../parts/OwnershipGroupDropdown";
import { rowsPerPageList } from "./Tabs";

/**
 * checks whether the billing account id provided
 * matches either the legacy account id or the
 * account id
 * @param billingAccount billing account record for a client
 * @param billingAccountId a number representing an account id
 * @returns boolean representing whether billing account id is 
 * valid or not
 */
export const isBillingAccountIdValid = (billingAccount: IBillingAccount, billingAccountId: number) => (
  billingAccount.accountId === billingAccountId || billingAccount.legacyAccountId === billingAccountId
);

const ExpandableRow = (
  {
    billingContact, 
    openCard,
  }: {
    billingContact: BillingContacts, 
    openCard: boolean
  },
) => {
  return (
    <StyledTableRow customSx={{ userSelect: 'none', backgroundColor: '#545454'}}
      dark={true}>
      <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
        <Collapse in={openCard} timeout="auto" unmountOnExit>
          <Grid container spacing={2} mb={2} mt={1} justifyContent="space-between">
            <Grid item><strong>Street Address: </strong>{ billingContact.address }</Grid>
            <Grid item><strong>City: </strong>{ billingContact.city }</Grid>
            <Grid item><strong>Province: </strong>{ billingContact.province }</Grid>
            <Grid item><strong>Zip Code: </strong>{ billingContact.postal }</Grid>
            <Grid item><strong>Country: </strong>{ billingContact.country }</Grid>
          </Grid>
        </Collapse>
      </StyledTableCell>
    </StyledTableRow>
  );
};

const ContactRow = (
  { 
    clientBillingContact, 
    onEditButtonClick, 
  } : { 
    clientBillingContact : ClientHasBillingContacts,
    onEditButtonClick: ((clientHasBillingContact: ClientHasBillingContacts) => void)
  },
) => {

  const systemInfo = useAppSelector(state => state.systemInfo);
  const [openCard, setOpenCard] = useState(false);
  const og = clientBillingContact.ownershipGroup?.name ?? `N/A`;
  const contact = clientBillingContact.billingContact;

  const handleEditButton = () => (
    onEditButtonClick(clientBillingContact)
  );

  return (
    <>
      <StyledTableRow
        customSx={{ userSelect: 'none' }}
        dark={true}
        hover={false}
      >
        <StyledTableCell dark={true} style={{ whiteSpace: 'nowrap' }}>
          <IconButton
            aria-label="expand row"
            size="small"
            color="inherit"
            sx={{ "&:hover": { backgroundColor: "black" } }}
            onClick={() => setOpenCard(!openCard)}
          >
            {openCard ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell dark={true} style={{ whiteSpace: 'nowrap' }}>
          { contact.name }
        </StyledTableCell>
        <StyledTableCell dark={true} style={{ whiteSpace: 'nowrap' }}>
          { contact.email }
        </StyledTableCell>
        <StyledTableCell dark={true} style={{ whiteSpace: 'nowrap' }}>
          { contact.phone ?? ''}
        </StyledTableCell>
        <StyledTableCell dark={true} style={{ whiteSpace: 'nowrap' }}>
          { contact.phone2 ?? ''}
        </StyledTableCell>
        <StyledTableCell dark={true} style={{ whiteSpace: 'nowrap' }}>
          { og }
        </StyledTableCell>
        <StyledTableCell dark={true} style={{ whiteSpace: 'nowrap' }}>
          { // @ts-ignore next line
            clientBillingContact.receivesInvoices === 1
              ? <span style={{ color: 'green' }} title="Receives invoices">&#x2713;</span>
              : <span style={{ color: 'red' }} title="Does not receive invoices">&#x2715;</span> 
          }
        </StyledTableCell>
        <StyledTableCell dark={true} style={{ whiteSpace: 'nowrap' }}>
          <IconButton 
            aria-label="open modal to edit contact"
            color="inherit" 
            onClick={handleEditButton}
            disabled={systemInfo.env.NODE_ENV === 'staging'}
            sx={{ "&:hover": { backgroundColor: "black" } }}
          >
            <EditIcon/>
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
      <ExpandableRow key={contact.id} billingContact={contact} openCard={openCard}/>
      
    </>
  );
};

const ContactListRow = React.memo(ContactRow);

export const ContactsTab = () => {

  const billingAccount = useAppSelector((state) => state.clients.currentClientBillingAccount);
  const billingContactsList = useAppSelector((state) => state.clients.billingContactsList);
  const systemInfo = useAppSelector(state => state.systemInfo);

  const billingAccountId = intVal(useParams<{ billingAccountId?: string }>().billingAccountId);

  const [sortCriteria, setSortCriteria] = useState(['name', 'asc']);
  const [searchOwnershipGroupId, setSearchOwnershipGroupId] = useState<Nullable<number>>(null);
  const [searchFilter, setSearchFilter] = useState('');
  const [ page, setPage ] = useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(20);
  const [ clientBillingContactSelected, setClientBillingContactSelected] = useState<Nullable<ClientHasBillingContacts>>(
    null,
  );
  const [ openNewBillingContactsModal, setOpenNewBillingContactsModal ] = useState(false);
  const [ openAddExistingBillingContactsModal, setopenAddExistingBillingContactsModal ] = useState(false);

  useEffect(() => {
    (
      billingAccount 
      && isBillingAccountIdValid(billingAccount, billingAccountId) 
      && !openAddExistingBillingContactsModal
    ) 
      ? loadClientBillingContacts({freshbooksClientId: billingAccountId})
      : loadClientBillingContacts({freshbooksClientId: null});
  }, [billingAccount, billingAccountId, openAddExistingBillingContactsModal, openNewBillingContactsModal]);

  useEffect(() => {
    if (!openNewBillingContactsModal) {
      setClientBillingContactSelected(null);
    }
  }, [openNewBillingContactsModal]);

  useEffect(() => {
    if (clientBillingContactSelected) {
      setOpenNewBillingContactsModal(true);
    }
  }, [clientBillingContactSelected]);

  const getCellDataForSorting = useCallback(
    (sortCriteriaName: string, clientHasBillingContact: ClientHasBillingContacts) => {
      const cellDataWithCosmeticChanges: HashOf<Function> = {
        'name': (contact: ClientHasBillingContacts) => (contact.billingContact.name.toLowerCase().trim()),
        'email': (contact: ClientHasBillingContacts) => (contact.billingContact.email.toLowerCase().trim()),
        'phone': (contact: ClientHasBillingContacts) => (contact.billingContact.phone ?? ''),
        'phone2': (contact: ClientHasBillingContacts) => (contact.billingContact.phone2 ?? ''),
        'ownershipGroup': (contact: ClientHasBillingContacts) =>(
          contact.ownershipGroup?.name ?? `N/A`
        ),
      };
      return (
        cellDataWithCosmeticChanges[sortCriteriaName]
          ? cellDataWithCosmeticChanges[sortCriteriaName](clientHasBillingContact)
          : (clientHasBillingContact)[sortCriteriaName as keyof ClientHasBillingContacts]
      );
    }, [],
  );

  const doSort = useCallback(
    (a: ClientHasBillingContacts, b: ClientHasBillingContacts) => {
      const [ field, sortOrder ] = sortCriteria;
      return (
        sortOrder === 'asc'
          ? (getCellDataForSorting(field, a) > getCellDataForSorting(field, b) ? 1 : -1)
          : (getCellDataForSorting(field, b) > getCellDataForSorting(field, a)) ? 1 : -1);
    }, [ sortCriteria, getCellDataForSorting ],
  );

  const sortedBillingContacts = useMemo(() => (
    (billingContactsList ?? []).slice().sort(doSort)
  ), [ doSort, billingContactsList ]);

  const sortBy = (criteria: string) => {
    setSortCriteria(
      (sortCriteria) => [criteria, (sortCriteria[0] === criteria && sortCriteria[1] === 'asc') ? 'desc' : 'asc'],
    );
  };
  const showSortIconIfAvailable = (criteria: string) => {
    return (
      sortCriteria[0] === criteria 
        ? (sortCriteria[1] === 'asc' 
          ? <>&#x25b2;</> 
          : <>&#x25bc;</>) 
        : null
    );
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(intVal(event.target.value));
    setPage(0);
  };

  const pagedBillingContacts = useMemo(
    () => sortedBillingContacts.slice(page * rowsPerPage, (page + 1) * rowsPerPage),
    [ page, rowsPerPage, sortedBillingContacts ],
  );

  const handleEditAction = (clientBillingContact: ClientHasBillingContacts) => {
    setClientBillingContactSelected(clientBillingContact);
  };

  const search = async () => {
    await loadClientBillingContacts({freshbooksClientId: null});
    await loadClientBillingContacts(
      {
        freshbooksClientId: billingAccountId,
        searchFilter: searchFilter,
        ownershipGroupId: searchOwnershipGroupId,
      },
    );
  };
  return (
    <>
      <Grid container spacing={2} mt={2}  justifyContent="center" alignItems="center">
        <Grid item xs={12} component={Paper} ml={2} mb={2} sx={{ background: '#f5f5f5' }}>
          <Grid container spacing={1} mb={2} alignItems="center">
            <Grid item container justifyContent="flex-end">
              <Grid item pr={2}>
                <Tooltip 
                  title='Add an existing contact' 
                  placement="top" 
                >
                  <span>
                    <Button
                      aria-label="add existing contact"
                      size="small"
                      variant='contained'
                      color="primary"
                      disabled={systemInfo.env.NODE_ENV === 'staging'}
                      onClick={() => setopenAddExistingBillingContactsModal(true)}
                    >
                      <PlaylistAddIcon /> 
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
              <Grid item pr={2}>
                <Tooltip 
                  title='Create a new contact' 
                  placement="top" 
                >
                  <span>
                    <Button
                      aria-label="create a new contact"
                      size="small"
                      variant='contained'
                      color="primary"
                      disabled={systemInfo.env.NODE_ENV === 'staging'}
                      onClick={() => setOpenNewBillingContactsModal(true)}
                    >
                      <PersonAddIcon /> 
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={2}>
            Search:
            </Grid>
            <Grid item xs={10} pr={2}>
              <TextField 
                fullWidth 
                size="small" 
                label={'Search'} 
                sx={{ background: '#fff' }}
                onChange={(e) =>  setSearchFilter(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') search();
                }}
                value={searchFilter}
              />
            </Grid>
            <Grid item xs={2}>
              Ownership Group:
            </Grid>
            <Grid item xs={10} pr={2}>
              <OwnershipGroupDropdown
                freshbooksClientId={billingAccountId}
                ownershipGroupId={searchOwnershipGroupId}
                onOwnershipGroupChanged={(ownershipGroup) => {
                  setSearchOwnershipGroupId(ownershipGroup ? intVal(ownershipGroup.id) : null);
                }}
                onOwnershipGroupFreeForm={(ownershipGroupString) => {}}
                disableIfNoOwnershipGroups={true}
              />
            </Grid>
            <Grid item container  justifyContent="flex-end">
              <Grid item justifyContent="flex-end" pr={2}>
                <Button
                  style={{ verticalAlign: 'middle' }}
                  variant='contained'
                  onClick={search}
                >
                Search
                
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <TableContainer component={Paper} elevation={12} sx={{mb: 1, ml: 2}}>
          <TablePagination
            component="div"
            sx={{'& p': {marginTop: '1rem'}}}
            count={(billingContactsList ?? []).length} 
            page={page}
            rowsPerPage={rowsPerPage}
            showFirstButton
            showLastButton
            rowsPerPageOptions={rowsPerPageList}
            onPageChange={(e, newPage)=>{setPage(newPage);}}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
          <MuiTable style={{ borderCollapse: 'collapse' }}>
            <TableHead>
              <StyledTableRow dark={true} style={{ cursor: 'pointer' }}>
                <StyledTableCell
                  dark={false}
                  style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                />
                <StyledTableCell
                  dark={true}
                  onClick={() => sortBy('name')}
                  style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                >
                  Name {showSortIconIfAvailable('name')}
                </StyledTableCell>
                <StyledTableCell
                  dark={true}
                  onClick={() => sortBy('email')}
                  style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                >
                  Email Address {showSortIconIfAvailable('email')}
                </StyledTableCell>
                <StyledTableCell
                  dark={true}
                  onClick={() => sortBy('phone')}
                  style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                >
                  Phone {showSortIconIfAvailable('phone')}
                </StyledTableCell>
                <StyledTableCell
                  dark={true}
                  onClick={() => sortBy('phone2')}
                  style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                >
                  Secondary Phone {showSortIconIfAvailable('phone2')}
                </StyledTableCell>
                <StyledTableCell
                  dark={true}
                  onClick={() => sortBy('ownershipGroup')}
                  style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                >
                  Ownership Group {showSortIconIfAvailable('ownershipGroup')}
                </StyledTableCell>
                <StyledTableCell
                  dark={true}
                  style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                >
                  Receives Invoices
                </StyledTableCell>
                <StyledTableCell
                  dark={true}
                  style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                />
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {
                (pagedBillingContacts ?? [])
                  .map((i) => (
                    <ContactListRow
                      key={i.id}
                      clientBillingContact={i}
                      onEditButtonClick={handleEditAction}
                    />
                  ))
              }
            </TableBody>
          </MuiTable>
        </TableContainer>
      </Grid>
      <CreateBillingContactModal
        key={`${clientBillingContactSelected?.id ?? billingAccountId}`}
        billingAccountId={billingAccountId}
        showModal={openNewBillingContactsModal} 
        setShowModal={setOpenNewBillingContactsModal}
        clientHasBillingContact={clientBillingContactSelected}
      />
      <AddExistingBillingContactsModal
        showModal = {openAddExistingBillingContactsModal}
        setShowModal = {setopenAddExistingBillingContactsModal}
      />
    </>
  );
};