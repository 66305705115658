import { Nullable } from "@jamesgmarks/utilities";

export enum ECreditAppliedAs {
  'payment' = 'payment',
  'reduction' = 'reduction',
}

export interface ICreditApplied {
  amount: string,
  appliedAs: ECreditAppliedAs,
  clientId: number,
  created: Date,
  createdUserId: number,
  creditId: number,
  currencyCode: string,
  dateApplied: Date,
  description: string,
  freshbooksClientId: Nullable<number>,
  freshbooksPaymentId: string,
  id: number,
  invoiceId: number,
  modified: Date | null,
  modifiedUserId: number | null
  taxAmount: string,
  taxRate: string,
}
