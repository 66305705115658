import { Fragment, useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableHead,
  Typography,
} from '@mui/material';

import {
  distinct,
  floatVal,
  Nullable,
  strVal,
} from '@jamesgmarks/utilities';

import { ISubscriptions } from '@llws/lift-entity-interfaces';

import { Charge } from '../../../../../entities/hydra';
import { Link } from 'react-router-dom';
import { loadChargesLookup } from '../../../redux/features/lookups/actions';
import MultipleSelectCheckmarks from '../../parts/mui/MultipleSelectCheckmarks';
import { StyledTableCell, StyledTableRow } from '../../parts/mui/StyledTables';
import { useAppSelector } from '../../../redux/hooks';
import { toDollarAmount } from '../../../app-utils';
import { EHydraState } from '../../../entity-interfaces/IInvoice';

interface CreditModalProps {
  onChargeSelected: (charge: Charge, subscription: ISubscriptions, invoiceNumbers: string[]) => void,
  onHide?: (() => void),
  show: boolean,
  subscriptions: ISubscriptions[],
}

export const CreditModal = ({
  onChargeSelected,
  onHide,
  show,
  subscriptions,
}: CreditModalProps) => {
  const charges = useAppSelector((state) => state.lookups.charges);

  const [ selectedSubscription, setSelectedSubscription ] = useState<Nullable<ISubscriptions>>(null);

  const [ selectedCharge, setSelectedCharge ] = useState<Nullable<Charge>>(null);

  const [ selectedChargeRowIndex, setSelectedChargeRowIndex ] = useState<Nullable<number>>(null);
  const [ selectedChargeInvoiceIds, setSelectedChargeInvoiceIds ] = useState<string[]>([]);

  useEffect(() => {
    if (selectedCharge && selectedSubscription && selectedChargeInvoiceIds[0] !== '') {
      onChargeSelected(selectedCharge, selectedSubscription, selectedChargeInvoiceIds);
      setSelectedSubscription(null);
      setSelectedCharge(null);
      setSelectedChargeInvoiceIds([]);
      setSelectedChargeRowIndex(null);
    }
  }, [
    onChargeSelected,
    selectedCharge,
    selectedChargeInvoiceIds,
    selectedSubscription,
    setSelectedCharge,
    setSelectedSubscription,
  ]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth='lg'
      open={show}
    >
      <DialogTitle sx={{ fontSize: '1.5rem' }}>
        { selectedSubscription ? 'Choose a Charge to Credit' : 'Choose a Subscription' }
      </DialogTitle>
      <DialogContent>
        {
          selectedSubscription
            ?
            <>
              <Grid container>
                <Grid item xs={8}>
                  <u>
                    <b>Selected Subscription</b>: {selectedSubscription.id} - {selectedSubscription.invoiceDescription}
                  </u>
                </Grid>
                <Grid item xs={4} textAlign='right'>
                  <Button
                    color='warning'
                    onClick={() => {
                      setSelectedSubscription(null);
                      setSelectedChargeRowIndex(null);
                    }}
                    variant='contained'
                  >View Subscriptions
                  </Button>
                </Grid>
              </Grid>
              {
                charges.length === 0
                  ?
                  <Typography mt={3} variant='h6'><b><u>No Charges Available for This Subscription</u></b></Typography>
                  :
                  <>
                    {/* <u><p>Choose a Charge to Credit:</p></u> */}
                    <Table sx={{ mt: 2, width: '100%', textAlign: 'center' }}>
                      <TableHead>
                        <StyledTableRow>
                          <StyledTableCell>Charge ID</StyledTableCell>
                          <StyledTableCell>Year</StyledTableCell>
                          <StyledTableCell>Month</StyledTableCell>
                          <StyledTableCell>Invoice Count</StyledTableCell>
                          <StyledTableCell>Subtotal</StyledTableCell>
                          <StyledTableCell>Tax</StyledTableCell>
                          <StyledTableCell>Total</StyledTableCell>
                          <StyledTableCell>&nbsp;</StyledTableCell>
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                        {
                          charges.map(
                            (charge, i) => {
                              const invoiceIds = distinct(
                                (charge.chargeHasTaxes ?? [])
                                  .filter((cht) => (
                                    ![EHydraState.deleted, EHydraState.unsent].includes(cht.invoice.hydraState)
                                  ))
                                  .map((cht) => strVal(cht.invoiceId)),
                              );

                              const invoiceCount = invoiceIds.length;

                              const invoiceNumbers = invoiceIds.map((id) => {
                                const gsht = charge.chargeHasTaxes.find(
                                  (cht) => strVal(cht.invoiceId) === id,
                                );
                                return gsht?.invoice?.invoiceNumber ? gsht.invoice.invoiceNumber : `PV: ${id}`;
                              });

                              const options = invoiceIds
                                .map((id, index) => ({ label: invoiceNumbers[index], value: id }))
                                .sort((a, b) => a.label < b.label ? -1 : 1);

                              return (
                                <Fragment key={i}>
                                  <StyledTableRow hover={true} key={i}>
                                    <StyledTableCell>
                                      <Link
                                        to={`/charges/${charge.id}`}
                                        target="_blank"
                                      >
                                        {charge.id}
                                      </Link>
                                    </StyledTableCell>
                                    <StyledTableCell>{charge.year}</StyledTableCell>
                                    <StyledTableCell>{charge.month}</StyledTableCell>
                                    <StyledTableCell> {invoiceCount} </StyledTableCell>
                                    <StyledTableCell>
                                      {
                                        toDollarAmount(
                                          floatVal(charge.proratedSubtotal) || 0 + floatVal(charge.discountAmount) || 0,
                                        )
                                      }
                                    </StyledTableCell>
                                    <StyledTableCell>{toDollarAmount(floatVal(charge.taxAmount || 0))}</StyledTableCell>
                                    <StyledTableCell>{
                                      toDollarAmount(
                                        floatVal(charge.proratedSubtotal) || 0
                                    + floatVal(charge.discountAmount) || 0
                                    + floatVal(charge.taxAmount) || 0,
                                      )
                                    }</StyledTableCell>
                                    <StyledTableCell>
                                      <Button
                                        color={i === selectedChargeRowIndex && selectedChargeInvoiceIds.length > 0
                                          ? 'success'
                                          : 'primary'}
                                        disabled={!invoiceCount
                                      || ( invoiceCount > 1
                                        && selectedChargeInvoiceIds.length === 0
                                        && selectedChargeRowIndex === i
                                      )}
                                        onClick={(e) => {
                                          if (selectedChargeRowIndex !== i && invoiceCount !== 1) {
                                            setSelectedChargeInvoiceIds([]);
                                            setSelectedChargeRowIndex(i);
                                            return;
                                          }

                                          if (invoiceCount === 1) {
                                            setSelectedChargeInvoiceIds(invoiceIds);
                                            setSelectedCharge(charge);
                                          }

                                          if (selectedChargeInvoiceIds.length > 0) {
                                            setSelectedCharge(charge);
                                          }
                                        }}
                                        size="small"
                                        variant='contained'
                                      >
                                        {
                                          i === selectedChargeRowIndex && selectedChargeInvoiceIds.length > 0
                                          // eslint-disable-next-line max-len
                                            ? `Credit ${selectedChargeInvoiceIds.length} Invoice${selectedChargeInvoiceIds.length > 1 ? 's' : ''}`
                                            : i === selectedChargeRowIndex
                                              ? 'Credit Invoices'
                                              : 'Select'
                                        }
                                      </Button>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  {
                                    selectedChargeRowIndex === i &&
                                <StyledTableRow>
                                  <StyledTableCell align='right' colSpan={8}>
                                    <MultipleSelectCheckmarks
                                      options={options}
                                      inputLabelText='Invoices'
                                      setSelectedValues={setSelectedChargeInvoiceIds}
                                    />
                                  </StyledTableCell>
                                </StyledTableRow>
                                  }
                                </Fragment>
                              );
                            },
                          )
                        }
                      </TableBody>
                    </Table>
                  </>
              }
            </>
            :
            <Table style={{ width: '100%' }}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>ID</StyledTableCell>
                  <StyledTableCell>Description</StyledTableCell>
                  <StyledTableCell>Start</StyledTableCell>
                  <StyledTableCell>Expiry</StyledTableCell>
                  <StyledTableCell>&nbsp;</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {
                  subscriptions.map((sub, i) => (
                    <StyledTableRow hover={true} key={i}>
                      <StyledTableCell>{sub.id}</StyledTableCell>
                      <StyledTableCell>{sub.invoiceDescription}</StyledTableCell>
                      <StyledTableCell>{sub.startDate}</StyledTableCell>
                      <StyledTableCell>{sub.expiryDate}</StyledTableCell>
                      <StyledTableCell style={{ textAlign: 'right' }}>
                        <Button
                          size='small'
                          variant='contained'
                          onClick={(e) => {
                            setSelectedSubscription(sub);
                            loadChargesLookup({ subscriptionId: sub.id ?? 0 });
                          }}
                        >
                        Select
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                }
              </TableBody>
            </Table>
        }</DialogContent>
      <DialogActions>
        <Button color="secondary"
          onClick={(e) => {
            setSelectedSubscription(null);
            setSelectedCharge(null);
            onHide?.();
          }}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
