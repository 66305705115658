import {
  TextField as MuiTextField,
} from "@mui/material";

interface IMultiLineTextFieldProps {
  label: string;
  value: string;
  onChange: (React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>);
}

export const MultiLineTextField = ({
  label,
  value,
  onChange,
}: IMultiLineTextFieldProps) => {
  return (
    <MuiTextField
      label={label}
      fullWidth={true}
      multiline
      rows={8}
      value={value}
      placeholder="Enter JSON Data"
      variant="outlined"
      onChange={onChange}
    />
  );
};
