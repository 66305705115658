import { HashOf } from "@jamesgmarks/utilities";
import { Grid } from "@mui/material";
import { DropdownMultiselect, ICustomDropdownMultiselectOption } from "./DropdownMultiselect";
import { DatePickerField } from "./runner-form/DatePickerField";

export const dateRangeDropdownCustomOptions: ICustomDropdownMultiselectOption[] = [
  {
    location: 'prepend',
    id: '1',
    label: 'Past Day',
  },
  {
    location: 'prepend',
    id: '7',
    label: 'Past 7 Days',
  },
  {
    location: 'prepend',
    id: '30',
    label: 'Past 30 Days',
  },
  {
    location: 'prepend',
    id: '60',
    label: 'Past 60 Days',
  },
  {
    location: 'prepend',
    id: '90',
    label: 'Past 90 Days',
  },
  {
    location: 'prepend',
    id: '365',
    label: 'Past Year',
  },
];

export const DateRangeDropdown = (
  {
    customOptions = dateRangeDropdownCustomOptions,
    selectionMap = {},
    title = ``,
    disabled = false,
    disableEndDate = false,
    startDate,
    endDate,
    textFieldBackgroundColor = '#fff',
    showDropdDownSelect = false,
    onCustomOptionSelected,
    onStartDateChange,
    onItemToggle,
    onEndDateChange,
  }: {
    customOptions?: ICustomDropdownMultiselectOption[],
    selectionMap?: HashOf<boolean>,
    title?: string,
    disabled?: boolean,
    disableEndDate?: boolean,
    showDropdDownSelect?: boolean,
    startDate: Date,
    endDate: Date,
    textFieldBackgroundColor?: string,
    onCustomOptionSelected?: (id: string) => void,
    onItemToggle: (id: string) => void,
    onStartDateChange: (d: Date | null) => void,
    onEndDateChange: (d: Date | null) => void,
  },
) => {
  return (
    <Grid container spacing={1} alignItems="center">
      {
        !showDropdDownSelect
        && <Grid item>
          <DropdownMultiselect
            title={title}
            disabled={disabled}
            customOptions={customOptions}
            selectionMap={selectionMap}
            onCustomOptionSelected={onCustomOptionSelected}
            onItemToggle={onItemToggle}
          />
        </Grid>
      }
      <Grid item>
        {
          !showDropdDownSelect
          && <>
            &nbsp;
            &nbsp;
          </>
        }
        <DatePickerField
          label="Start Date"
          value={startDate}
          textFieldBackgroundColor={textFieldBackgroundColor}
          onChange={onStartDateChange}

        />
      </Grid>
      <Grid item>
        <DatePickerField
          disabled={disableEndDate}
          label="End Date"
          value={endDate}
          textFieldBackgroundColor={textFieldBackgroundColor}
          onChange={onEndDateChange}
        />
      </Grid>
    </Grid>
  );
};