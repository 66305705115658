import { Nullable } from '@jamesgmarks/utilities';
import { IInvoice } from './IInvoice';

// eslint-disable-next-line no-shadow
export enum EDownloadStatus {
  'pending' = 'pending',
  'error' = 'error',
  'invalid' = 'invalid',
  'completed' = 'completed',
}

// eslint-disable-next-line no-shadow
export enum EUploadStatus {
  'pending' = 'pending',
  'error' = 'error',
  'completed' = 'completed',
}

export interface IPdfGeneration {
  id: number;
  invoiceId: number;
  objectId:number
  downloadTimestamp: string;
  downloadStatus: EDownloadStatus;
  errorDescription: string;
  uploadTimestamp: string;
  uploadStatus: EUploadStatus;
  googleFullpath: string;

  created: string;
  createdUserId: Nullable<number>;
  modified: Nullable<string>;
  modifiedUserId: Nullable<number>;

  invoice?: IInvoice;
}
