import { useState } from "react";
import {
  Button, FormControl, Grid, InputLabel, MenuItem, Select,
} from "@mui/material";
import { ClientDropdown } from "src/components/parts/ClientDropdown";
import { useAppSelector } from "src/redux/hooks";
import { Nullable, intVal } from "@jamesgmarks/utilities";
import { DatePickerField } from "src/components/parts/runner-form/DatePickerField";
import { makeYmd } from "src/app-utils";
import { triggerActionWithResponse } from "src/redux/features/finance-control-tools/actions";
export const MassMigrateBuildingBillingTypes = () => {

  const [clientId, setClientId] = useState<Nullable<number>>(null);
  const [buildingBillingTypeId, setBuildingBillingTypeId] = useState<number>(1);
  const searchIdFilter = useAppSelector((state) => state.clients.filters.searchIdFilter);
  const [migrationDateYMD, setMigrationDateYMD] = useState<string>(makeYmd(new Date()));

  return (
    <>
      <h3>Mass Migrate Building Billing Types</h3>
      <p>Migrate all non deleted buildings for a client to a billing type. This will overwrite exempt properties.</p>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <ClientDropdown
            allowFreeform
            clientId={searchIdFilter ?? null}
            onClientChanged={
              (client) => {
                if (client) {
                  setClientId(client.id);
                }
              }
            }
            onClientFreeForm={(clientString) => {}}
          />
        </Grid>

        <Grid item xs={8}>
          <FormControl fullWidth>
            <InputLabel id="building-billing-type-label">Building Billing Type to Migrate to</InputLabel>
            <Select
              labelId="building-billing-type-label"
              id="building-billing-type-select"
              value={buildingBillingTypeId}
              label="Building Billing Type to Migrate too"
              onChange={(e) => setBuildingBillingTypeId(intVal(e.target.value))}
            >
              <MenuItem value={1}>Single Family</MenuItem>
              <MenuItem value={2}>Multi Family</MenuItem>
              <MenuItem value={3}>Exempt</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={8}>
          <DatePickerField
            label={'Date'}
            value={migrationDateYMD}
            options={['day', 'month', 'year']}
            onChange={(newValue) => {
              if (newValue){
                setMigrationDateYMD(makeYmd(newValue));
              }
            }}
          />
        </Grid>

        <Grid item xs={8}>
          <Button
            variant="contained"
            onClick={(e) => {
              e.stopPropagation();
              triggerActionWithResponse('massMigrateBuildingBillingTypes', {
                clientId,
                buildingBillingTypeId,
                migrationDateYMD,
              });
            }}
          >Mass Migrate Building Billing Types</Button>
        </Grid>
      </Grid>
    </>
  );
};
