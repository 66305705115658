import { Nullable } from "@jamesgmarks/utilities";
import { Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { useAuth } from "../../../customHooks/useAuth";
import { EHydraCreditNoteState, EHydraCreditNoteVoidState } from "../../../entity-interfaces/EHydraCreditNoteState";
import { ICreditNote } from "../../../interfaces/ICreditNote";

interface ICreditNoteVoidSectionProps {
  creditNote: ICreditNote;
  onSave: (note: ICreditNote) => void;
  setConfirmationModalPromptText: React.Dispatch<React.SetStateAction<string>>;
  setShowConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
  setConfirmationModalOnConfirm: React.Dispatch<React.SetStateAction<() => void>>;
  setCreditNote: React.Dispatch<React.SetStateAction<ICreditNote>>;
}

export const CreditNoteVoidSection = ({
  creditNote,
  onSave,
  setConfirmationModalPromptText,
  setShowConfirmationModal,
  setConfirmationModalOnConfirm,
  setCreditNote,
}: ICreditNoteVoidSectionProps) => {
  const { hasPermission, isDeveloper } = useAuth();

  const [ showVoidRequestInput, setShowVoidRequestInput ] = useState(false);
  const [ showVoidApproveOrDenyInput, setShowVoidApproveOrDenyInput ] = useState(false);

  const [ editingVoidRequestReason, setEditingVoidRequestReason ] = useState(false);

  const [ voidRequestReason, setVoidRequestReason ] = useState<Nullable<string>>(null);
  const [ voidApproveOrDenyReason, setVoidApproveOrDenyReason ] = useState<Nullable<string>>(null);

  const hasProvidedApproveOrDenyReason = (voidApproveOrDenyReason ?? '').trim().length > 0;

  const permitRequestToVoid = (
    creditNote.state === EHydraCreditNoteState.sent
    && creditNote.voidState !== EHydraCreditNoteVoidState.voidApproved
  );

  const showFinalVoidRequestReason = creditNote.voidState && !showVoidRequestInput;

  const showFinalVoidApproveOrDenyReason = (
    creditNote.voidState
    && !showVoidRequestInput
    && [ EHydraCreditNoteVoidState.voidApproved, EHydraCreditNoteVoidState.voidDenied].includes(creditNote.voidState)
  );

  const showVoidApproveOrDenyButtons = (
    creditNote.voidState === EHydraCreditNoteVoidState.voidRequested
    && !editingVoidRequestReason
  );

  return (
    <>
      {
        permitRequestToVoid
        && (
          <Grid item xs={12} textAlign='right'>
            <Button
              color='error'
              variant='outlined'
              onClick={() => {
                setVoidRequestReason(null);
                setVoidApproveOrDenyReason(null);
                setShowVoidRequestInput(true);
                setShowVoidApproveOrDenyInput(false);
                setEditingVoidRequestReason(true);
              }}
            >
              Request to void
            </Button>
          </Grid>
        )
      }

      <Grid item xs={12} container>
        {
          showFinalVoidRequestReason
            && (
              <Grid item xs={12}>
                <Paper sx={{ p: 2 }}>
                  <Typography>
                    <b>Void Request Reason</b>: {creditNote.voidRequestReason ?? ''}
                  </Typography>
                </Paper>
              </Grid>
            )
        }
        {
          showFinalVoidApproveOrDenyReason
            && (
              <Grid item xs={12}>
                <Paper sx={{ p: 2, mt: 1 }}>
                  <Typography>
                    <b>
                      Void {
                        creditNote.state === EHydraCreditNoteState.void ? 'Approval' : 'Denial'
                      } Reason
                    </b>
                    : {creditNote.voidApproveOrDenyReason ?? ''}
                  </Typography>
                </Paper>
              </Grid>
            )
        }
      </Grid>

      {
        creditNote.state === EHydraCreditNoteState.sent
        && showVoidRequestInput
        && (
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <TextField
                autoFocus
                fullWidth
                multiline
                id="standard-basic"
                label="Void Request Reason"
                variant="outlined"
                size='small'
                sx={{ my: 1 }}
                value={voidRequestReason ?? ''}
                onChange={
                  (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    setVoidRequestReason(e.target.value);
                  }
                }
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                disabled={(voidRequestReason ?? '').trim().length === 0}
                variant='contained'
                onClick={
                  (e) => {
                    setCreditNote((old) => {
                      const newCreditNote = {
                        ...old,
                        voidState: EHydraCreditNoteVoidState.voidRequested,
                        voidRequestReason,
                        voidApproveOrDenyReason: null,
                      };

                      onSave(newCreditNote);

                      return newCreditNote;
                    });

                    setShowVoidRequestInput(false);
                    setEditingVoidRequestReason(false);
                  }
                }
              >
                Request
              </Button>
            </Grid>
          </Grid>
        )
      }

      {
        showVoidApproveOrDenyButtons
          && (
            <Grid item container xs={12} spacing={1} justifyContent='right'>
              <Grid item mt={1}>
                <Button
                  color={hasProvidedApproveOrDenyReason ? 'success' : 'primary'}
                  disabled={!(hasPermission('REVIEW_CREDIT_NOTE_VOID_REQUEST') || isDeveloper)}
                  variant='contained'
                  onClick={(_e) => {
                    setShowVoidApproveOrDenyInput(true);

                    if (hasProvidedApproveOrDenyReason) {
                      setConfirmationModalOnConfirm(
                        () => () => {
                          setCreditNote((old) => {
                            const newCreditNote = {
                              ...old,
                              state: EHydraCreditNoteState.void,
                              voidState: EHydraCreditNoteVoidState.voidApproved,
                              voidApproveOrDenyReason,
                            };
                          
                            onSave(newCreditNote);
                            return newCreditNote;
                          });

                          setShowVoidApproveOrDenyInput(false);
                        },
                      );

                      setConfirmationModalPromptText(
                        'Are you sure you want to APPROVE this void request?',
                      );

                      setShowConfirmationModal(true);
                    }
                  }}
                >
                  Approve{!hasProvidedApproveOrDenyReason && ' or deny...'}
                </Button>
              </Grid>

              {
                hasProvidedApproveOrDenyReason
                && (
                  <Grid item mt={1}>
                    <Button
                      color='error'
                      variant='contained'
                      onClick={(_e) => {
                        setConfirmationModalOnConfirm(
                          () => () => {
                            setCreditNote((old) => {
                              const newCreditNote = {
                                ...old,
                                voidState: EHydraCreditNoteVoidState.voidDenied,
                                voidApproveOrDenyReason,
                              };
                            
                              onSave(newCreditNote);
                              return newCreditNote;
                            });

                            setShowVoidApproveOrDenyInput(false);
                          },
                        );

                        setConfirmationModalPromptText(
                          'Are you sure you want to DENY this void request?',
                        );

                        setShowConfirmationModal(true);
                      }}
                    >
                      Deny
                    </Button>
                  </Grid>
                )
              }
            </Grid>
          )
      }

      {
        showVoidApproveOrDenyInput
        && (
          <Grid item container spacing={1} justifyContent='flex-start'>
            <Grid item xs={12} mt={2}>
              <TextField
                autoFocus
                fullWidth
                sx={{ m: 'auto' }}
                multiline
                id="standard-basic"
                label={'Void Approval/Denial Reason'}
                variant="outlined"
                size='small'
                margin='normal'
                value={voidApproveOrDenyReason ?? ''}
                onChange={
                  (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                    setVoidApproveOrDenyReason(e.target.value)
                }
              />
            </Grid>
          </Grid>
        )
      }
    </>
  );
};
