import { Hash } from '@jamesgmarks/utilities';
import { Link } from "react-router-dom";

export const Manager = (props: Hash) => (
  <div className="App">
    <header className="App-header">
      Manager Section
      <Link to="/billing_runs">Billing Runs</Link>
    </header>
  </div>
);