/**
 * Validates the input as a phone number
 * @param input string that needs to be validated
 * @returns true if the input is in valid phone number format
 */
export const isValidPhoneNumber = (input:string) => {
  const regex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  return regex.test(input);
};

/**
 * Validates the input as an email address
 * @param input  string that needs to be validated
 * @returns true if the input is in a valid email address format
 */
export const isValidEmailString = (input: string) => {
  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return regex.test(input);
};

/**
 * Validates the input as a US or Canadian 
 * postal code
 * US Format: XXXXX || XXXXX-XXXX where X is a number
 * Canada Format: AXA XAX where X is a number and
 * A is a alphabetic character
 * @param input  string that needs to be validated
 * @returns true if the input is in a valid email address format
 */
export const isValidPostalCode = (input: string) => {
  const regex = /^((\d{5}-\d{4})|(\d{5})|([A-Z]\d[A-Z] ?\d[A-Z]\d))$/;
  return regex.test(input);
};
