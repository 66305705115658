import { dispatch } from '../../store';
import { timelineReceived, setLoadedState } from './promotedListingsSlice';
import { IApiQueryListResponse,  typedApiFetch } from '../../utils';
import { REACT_APP_API_ROOT_URI } from '../../../App';
import { IPromotedListingSegmentTimeline } from './interfaces';
import { assertYearMonth, IYearMonth, TMonthNumber } from '../../../interfaces/IYearMonth';

export const loadTimeline = async (freshbooksClientId: number, yearMonth:IYearMonth) => {
  const {year, month} = assertYearMonth(yearMonth) && yearMonth;
  const url = `${REACT_APP_API_ROOT_URI}/promoted_listings/timeline/${year}-${month}/${freshbooksClientId}`;
  dispatch(setLoadedState('loading'));
  const response = await typedApiFetch<IApiQueryListResponse<IPromotedListingSegmentTimeline>>(url);
  const { data } = await response.json();
  dispatch(timelineReceived(data)); // TODO: Error handling?
  dispatch(setLoadedState('loaded')); 
};
