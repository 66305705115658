import { CreditNote, Invoice } from "../../../../../../entities/hydra";
import { TDocumentFieldGetter } from "../../../../redux/features/documents/interfaces";
import { creditNoteListFieldGetters } from "./creditNoteListFieldGetters";
import { invoiceListFieldGetters } from "./invoiceListFieldGetters";

export type TTableData<T> = { name: string, getter: TDocumentFieldGetter<T> };

export const documentListFieldGetters: {
    invoice: TTableData<Invoice>[],
    creditNote: TTableData<CreditNote>[],
  } = {
    invoice: invoiceListFieldGetters,
    creditNote: creditNoteListFieldGetters,
  };
