import { createSlice } from '@reduxjs/toolkit';
import { Hash, Nullable } from '@jamesgmarks/utilities';
import { Charge } from '../../../../../entities/hydra/Charge';
import { IApiQueryListResponse, IApiQuerySingularResponse } from '../../utils';
import { IApiQueryResponse } from '@llws/api-common';
import { TChargeForAccountView } from '../../../interfaces/TChargeForAccountView';

export type TChargesState = {
  loadedState: string,
  currentCharge: Nullable<Charge>,
  chargesList: Charge[],
  chargesListForAccountView: TChargeForAccountView[],
  nextPage: Nullable<string>,
  filters: Hash,
  metaData: Nullable<IApiQueryResponse<Charge>['meta']>,
};

export const chargesSlice = createSlice({
  name: 'charges',
  initialState: {
    loadedState: 'idle',
    currentCharge: null,
    currentGeneratedSubscription: null,
    chargesList: [],
    chargesListForAccountView: [],
    generatedSubscriptionsList: [],
    nextPage: null,
    filters: {},
    metaData: null,
  } as TChargesState,
  reducers: {
    currentChargeReceived: (
      state,
      action: { type: string, payload: IApiQuerySingularResponse<Charge> },
    ) => {
      state.currentCharge = action.payload.data;
      state.loadedState = 'loaded';
    },
    chargesListReceived: (
      state,
      action: { type: string, payload: IApiQueryListResponse<Charge> },
    ) => {
      state.chargesList = action.payload.data;
      state.nextPage = action.payload.meta?.links?.next;
      state.metaData = { ...action.payload.meta };
      state.loadedState = 'loaded';
    },
    chargesListForAccountViewReceived: (
      state,
      action: { type: string, payload: IApiQueryListResponse<TChargeForAccountView> },
    ) => {
      state.chargesListForAccountView = action.payload.data;
      state.nextPage = action.payload.meta?.links?.next;
      state.metaData = { ...action.payload.meta };
      state.loadedState = 'loaded';
    },
    setLoadedState: (state, action: { type: string, payload: string }) => {
      state.loadedState = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  currentChargeReceived,
  chargesListReceived,
  chargesListForAccountViewReceived,
  setLoadedState,
} = chargesSlice.actions;

export default chargesSlice.reducer;
