import { Nullable } from "@jamesgmarks/utilities";

export enum EStatementType {
  account = 'account',
  activity = 'activity',
}

export const isEStatementType = (object: unknown): object is EStatementType => (
  !!Object.values(EStatementType).find((type) => type === object)
);

export interface IStatementLine {
  lineId: number,
  date: string,
  dueDate: string,
  invoiceNumber: string,
  description: string,
  amountInvoiced: string,
  amountPaid: string,
  balance: string,
  currency: string,
}

export interface ISingleViewStatementData {
  statementId: number,
  statementType: EStatementType,
  address: string
  createDate: string
  statementStartDate: Nullable<string>,
  statementEndDate: Nullable<string>
  lines: IStatementLine[]
  invoicedAmount: string,
  paidAmount: string
  balanceDue: string
  terms: string
  bankingInformation?: string
  currencyCode?: string
}