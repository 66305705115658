import { Button } from '@mui/material';
import { Hash } from '@jamesgmarks/utilities';
import hydraLogo from '../../assets/images/hydra-logo.svg';
import { Link } from "react-router-dom";
import { RequirePermission } from '../parts/RequirePermission';
import { useAppSelector } from '../../redux/hooks';

export const Home = (props: Hash) => {
  const systemInfo = useAppSelector(state => state.systemInfo);
  return (
    <div className="App">
      <header className="App-header">
        <div style={{ marginBottom: '1rem' }}>
          <RequirePermission grant="FULL_ACCESS"><h4>{systemInfo.env.NODE_ENV?.toUpperCase()}</h4></RequirePermission>
        </div>
        <div style={{ width: '60%' }}>
          <img src={hydraLogo} className="App-logo" alt="logo" />
        </div>
        <Button
          color="primary"
          variant='outlined'
          component={Link} 
          to="/login" 
          style={{ fontWeight: 'bold', marginTop: '1.5rem' }}
        >Enter</Button>
      </header>
    </div>
  );
};