export const Terms = ({terms}: {terms: string}) => {
  return (
    <>
      <p style={{color: 'rgb(86, 86, 138)', fontWeight: 'bold'}}>
        Terms
      </p>
      <p>
        {terms.split('<br>').map((termsLine, index) => {
          return (<span key={index}>{termsLine}<br/></span>);
        })}
      </p>
    </>
  );
};