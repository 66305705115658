import { useState } from "react";
import {
  Button, Card, Col, Container, Form, Modal, Row, Table,
} from "react-bootstrap";
import { dollarToFloat, toDollarAmount } from "../../../app-utils";
import { ICreditNote } from "../../../interfaces/ICreditNote";
import { ICredit } from "../../../interfaces/ICredit";
import { ClientDropdown } from "../../parts/ClientDropdown";
import { compactArray, Nullable, replaceAt } from "@jamesgmarks/utilities";
import { IClientDropdownItem } from "../../../entity-interfaces/IClientDropdownItem";
import { EHydraCreditNoteState } from "../../../entity-interfaces/EHydraCreditNoteState";

/** Returns a UTC ISO string representing the start of the current day. */
export const getCreditNoteDate = (): string => {
  const today = new Date();
  const curYear = today.getFullYear();
  const curMonth = today.getMonth();
  const curDayOfMonth = today.getDate();

  const noteDate = new Date(Date.UTC(curYear, curMonth, curDayOfMonth));
  return noteDate.toISOString();
};

const CreditLine = ({
  credit,
  onChange,
  removeFn,
}: {
  credit: ICredit,
  onChange: (credit: ICredit) => void,
  removeFn: () => void
}) => {
  const [description, setDescription] = useState(credit.description);
  const [amount, setAmount] = useState(credit.amount);

  return (<>
    <tr>
      <td></td>
      <td>Credit</td>
      <td>
        <Form.Control
          type="text"
          placeholder="Description"
          value={description ?? 'nnothing'}
          onChange={(e) => {
            setDescription(e.target.value);
            onChange({
              ...credit,
              description: e.target.value,
            });
          }}
        />
      </td>
      <td>
        <Form.Control
          type="text"
          placeholder={`0.00`}
          value={toDollarAmount(-(amount), 'brackets')}
          onChange={(e) => {
            setAmount(`${-(dollarToFloat(e.target.value))}`);
            onChange({
              ...credit,
              description: e.target.value,
            });
          }}
        /></td>
      <td style={{ textAlign: 'center' }}>1</td>
      <td className='currency-right'>{toDollarAmount(-(amount), 'brackets')}</td>
      <td style={{ textAlign: 'center' }}>
        <Button onClick={(e) => { removeFn(); }} variant="danger">Remove</Button>
      </td>
    </tr>
  </>
  );
};

const CreditModal = ({
  show,
  onHide,
}: {
  show: boolean,
  onHide?: (() => void),
}) => {
  return (<>
    <Modal
      centered
      dialogClassName="modal-60w"
      backdrop="static"
      animation={false}
      show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Add Credit
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <p>
          Goodwill Credit or from Subscription?:{` `}
        </p>
        <p>
          SubscriptionId:{<Form.Control type="text" placeholder="Subscription" />}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button

        >Add</Button>
        <Button variant="secondary"
          onClick={(e) => {
            onHide?.();
          }}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  </>);

};

const createEmptyCreditNoteObject = () => ({
  ownershipGroupId: null,
  billingAddress: null,
  freshbooksCreditNoteId: null,
  currencyCode: 'CAD',
  subtotalAmount: null,
  taxName: null,
  taxRate: null,
  taxAmount: null,
  state: EHydraCreditNoteState.unpublished,
  voidState: null,
  voidRequestReason: null,
  voidApproveOrDenyReason: null,
  notes: null,
  noteDate: getCreditNoteDate(),
  credits: [],
  pdfGenerations: [],
} as ICreditNote);

export const CreditView = ({
  creditNote: incomingCreditNote,
  onSave,
}: {
  creditNote?: ICreditNote,
  onSave: (note: ICreditNote) => void,
}) => {
  const [showAddCreditModal, setShowAddCreditModal] = useState(false);
  const [creditNote, setCreditNote] = useState(incomingCreditNote ?? createEmptyCreditNoteObject());
  const [searchClient, setSearchClient] = useState<Nullable<IClientDropdownItem>>(null);

  // TODO: AMIR AMIR AMIR AMIR AMIR AMIR AMIR
  // When credit lines change, update creditNote via setCreditNote
  // Same with client / ownership group, etc.
  const creditLineChanged = (credit: ICredit, index: number) => {
    setCreditNote({
      ...creditNote,
      credits: replaceAt(creditNote.credits ?? [], index, credit),
    });
  };
  const removeCreditLine = (index: number) => {

    const updatedCredits = compactArray((creditNote.credits ?? []).map((credit, i) => {
      return i === index ? null : credit;
    }));
    console.log({ index, updatedCredits });
    setCreditNote({
      ...creditNote,
      credits: updatedCredits,
    });
  };
  const [ clientIdFilter ] = useState(1198);
  return (

    <Container>
      <br></br>
      <Row>
        <Col>
          <div>
            <h4>
              <strong>Credit #: {`${creditNote.noteNumber ?? 'nothing'}`} </strong>
            </h4>
            <br></br>
            <Button onClick={() => { setShowAddCreditModal(true); }}>Add Credit</Button>
            <CreditModal
              show={showAddCreditModal}
              onHide={() => {
                setShowAddCreditModal(false);
              }}
            />
          </div>
        </Col>

        <Col>
          <Card>
            <Card.Body style={{ padding: '.75rem' }}>
              <Card.Title>
                {
                  searchClient
                    ? searchClient.name
                    : <ClientDropdown
                      // eslint-disable-next-line max-len
                      clientId={clientIdFilter}
                      onClientChanged={(client) => { setSearchClient(client); }}
                      onClientFreeForm={(clientString) => { }}
                    />
                }
              </Card.Title>
              <div>{creditNote.billingAddress?.street1}</div>
              <div>{creditNote.billingAddress?.street2}</div>
              <div>
                {
                  `${creditNote.billingAddress?.city}, `
                  + `${creditNote.billingAddress?.province}, `
                  + `${creditNote.billingAddress?.postalCode}`
                }
              </div>
              {<div>{creditNote.billingAddress?.country}</div>}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <br></br>
      <Table striped bordered hover variant="dark" size="sm">
        <thead>
          <tr>
            <th>[+]</th>
            <th>Item</th>
            <th>Description</th>
            <th>Unit Cost</th>
            <th>Quantity</th>
            <th>Line Total</th>
          </tr>
        </thead>
        <tbody>
          {creditNote.credits?.map((credit, index) => (
            <CreditLine
              key={index}
              credit={credit}
              onChange={() => creditLineChanged(credit, index)}
              removeFn={() => removeCreditLine(index)} />
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={5}>Subtotal</th>
            <th className='currency-right'>{toDollarAmount(0)}</th>
          </tr>

          <tr>
            <td colSpan={5}>{'name'} ({'rate'}%)</td>
            <td colSpan={1} className='currency-right'>{toDollarAmount(0)}</td>
          </tr>

          <tr>
            <th colSpan={5}>Total</th>
            <th colSpan={1} className='currency-right'>{toDollarAmount(0)}</th>
          </tr>
          <tr>
            <th colSpan={5}>Paid</th>
            <th colSpan={1} className='currency-right'>{toDollarAmount(0)}</th>
          </tr>
          <tr>
            <th colSpan={5}>Outstanding</th>
            <th colSpan={1} className='currency-right'>{toDollarAmount(0)}</th>
          </tr>
        </tfoot>
      </Table>

      <Button onClick={() => { onSave(creditNote); }}>Save</Button>
    </Container >
  );
};
