import { ReactElement } from 'react';

import { Button } from '@mui/material';
import { Row, Col } from 'react-bootstrap';

import { distinct } from '@jamesgmarks/utilities';
import { flattenOneLevel, ucaseFirst } from '../../../app-utils';
import { bootstrapTableBuilder } from './table-builders/bootstrapTableBuilder';
import { DataTableParameters } from './DataTableParameters';
import { IDisplayAdaptor } from './IDisplayAdaptor';

const getDownloadTableExportData = (headers: string[], data: string[][]) => {
  const dataWithHeaders = [headers, ...data];
  const dataText = dataWithHeaders.map((tr: string[]) => `"` + tr.join(`","`) + `"`).join(`\r\n`);
  return dataText;
};

const exportDownloadTableToCSV = (filename: string, headers: string[], data: string[][]) => {
  const csvData = getDownloadTableExportData(headers, data);
  const blob = new Blob([csvData], { type: 'text/csv' });
  const element = document.createElement("a");
  element.href = URL.createObjectURL(blob);
  element.download = filename + (filename.endsWith('.csv') ? "" : ".csv");
  document.body.appendChild(element);
  element.click();
};

export const defaultDisplayAdaptor: IDisplayAdaptor = <T extends Record<string, unknown>>(
  recordFromArray: T,
): (ReactElement | string)[] => {
  return Object.keys(recordFromArray).map((key) => `${recordFromArray[key as keyof T] ?? ''}`);
};
export const defaultDownloadAdaptor = <T, >(recordFromArray: T): string[] => {
  return Object.keys(recordFromArray).map((key) => `${recordFromArray[key as keyof T] ?? ''}`);
};

export const defaultHeaderAdaptor = <T, >(data: Array<T>) => {
  return distinct(flattenOneLevel(
    data.map((recordFromArray) => Object.keys(recordFromArray).map((key) => ucaseFirst(key))),
  ));
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const DataTable = <T extends {}, >({ // eslint-disable-line @typescript-eslint/no-explicit-any
  data,
  displayHeaders = defaultHeaderAdaptor(data),
  displayAdaptor = defaultDisplayAdaptor,
  downloadHeaders = defaultHeaderAdaptor(data),
  downloadAdaptor = defaultDownloadAdaptor,
  downloadFilename = 'download.csv',
  tableBuilder = bootstrapTableBuilder,
  footerData = [],
} : DataTableParameters<T>) => {
  const downloadData: string[][] = data ? data.map((d: T) => downloadAdaptor(d)) : [];

  return (<>
    {downloadFilename && <Row>
      <Col>
        <Button
          color="primary"
          variant='contained'
          className="float-right"
          sx={{mb: 1}}
          onClick={() => { exportDownloadTableToCSV(downloadFilename, downloadHeaders, downloadData); }}>
          Download {downloadFilename} as CSV
        </Button>
      </Col>
    </Row>}
    { /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ }
    {tableBuilder({
      data,
      displayAdaptor: (displayAdaptor as IDisplayAdaptor<Record<string, unknown>>),
      displayHeaders,
      footerData,
    })}
  </>
  );
};