import {
  TextField as MuiTextField,
} from "@mui/material";

export const TextField = ({
  label,
  value,
  onChange,
} : {
  label: string,
  value: string,
  onChange: (React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>),
}) => {
  return (
    <MuiTextField
      fullWidth={true}
      label={label}
      value={value}
      placeholder="Enter text"
      variant="outlined"
      onChange={onChange}
    />
  );
};