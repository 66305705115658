import { first, HashOf, keys } from "@jamesgmarks/utilities";
import { assertIInvoiceArray, IInvoice, isIInvoiceArray } from "../../../entity-interfaces/IInvoice";
import { assertIAugmentedCreditNoteArray, IAugmentedCreditNote } from "../../../interfaces/ICreditNote";

export const getContactGroups = <T extends { id: number }>(
  documents: T[],
) => {
  const unsortedGroups = (
    isIInvoiceArray(documents)
      ?
      (assertIInvoiceArray(documents) ?? []).reduce<HashOf<IInvoice[]>>((grouped, invoice) => {
        const OGLabel = (
          ((invoice?.ownershipGroup?.clientHasBillingContacts ?? []).length === 0)
            ? null
            : `${invoice.ownershipGroupId ?? ''}`
        );
        const key = [ `${invoice.freshbooksClientId}`, OGLabel ].filter(Boolean).join('-');
      
        return {
          ...grouped,
          [key]: [ ...(grouped[key] ?? []), invoice ],
        };
      }, {})
      : (
        (assertIAugmentedCreditNoteArray(documents) ?? [])
          .reduce<HashOf<IAugmentedCreditNote[]>>((grouped, creditNote) => {
            const OGLabel = (
              ((creditNote?.ownershipGroup?.clientHasBillingContacts ?? []).length === 0)
                ? null
                : `${creditNote.ownershipGroupId ?? ''}`
            );
            const key = [ `${creditNote.billingAccountId}`, OGLabel ].filter(Boolean).join('-');
        
            return {
              ...grouped,
              [key]: [ ...(grouped[key] ?? []), creditNote ],
            };
          }, {})
      )
  );

  // A sorted list of the group keys based on what the values title will be.
  // Used for iterating over the groups in alphabetical order.
  const sortedGroupKeys = (
    keys(unsortedGroups)
      .sort((aKey, bKey) => {
        const getTitleKey = (key: string | number) => {
          // Information needed to construct group[key]'s title
          const invoicesKey = unsortedGroups[key];
          const ownershipGroupKey = first<IInvoice | IAugmentedCreditNote>(invoicesKey)!.ownershipGroup;
          const clientsKey = first<IInvoice | IAugmentedCreditNote>(invoicesKey)!.billingAccount.clients;
          const clientKey = first(clientsKey!)!;

          return(
            ownershipGroupKey?.name
              ? `${clientKey?.clientName} Ownership Group: [${ownershipGroupKey.id}] ${ownershipGroupKey?.name}`
              : clientKey?.clientName ?? ' ISSUE'
          );
        };

        // Compare titles and return them in alphabetical order
        return (getTitleKey(aKey) < getTitleKey(bKey)) ? -1 : 1;
      })
  );

  return { unsortedGroups, sortedGroupKeys };
};
