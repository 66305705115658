import { useContext } from "react";

import { Button, Card, Container } from "react-bootstrap";
import { Grid } from "@mui/material";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCopy } from "@fortawesome/free-regular-svg-icons";

import { getSystemInfo } from "../../../redux/features/systemInfo/actions";
import { isTokenExpired } from "../../../redux/features/auth/helpers";
import { notifyEnvironment, showMessage } from "../../../redux/features/messaging/actions";
import { SocketContext } from './SocketContext';
import { useAppSelector } from "../../../redux/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const copyTokenToClipboard = () => {
  const textCopy = document.getElementById("devToken")!;

  // Copy the text inside the text field
  navigator.clipboard.writeText(textCopy.innerText);

  // Alert the copied text
  showMessage({
    message: `Copied token to clipboard!`,
    severity: 'success',
    asAlert: false,
  });
};

export const Info = () => {
  const { updateSocketUser } = useContext(SocketContext);
  const token = useAppSelector(state => state.auth?.decodedToken);
  const encodedToken = useAppSelector(state => state.auth?.token);
  const userId = useAppSelector(state => state.auth?.decodedToken?.userId);
  const { version, requiredVersion } = useAppSelector(state => state.systemInfo);
  const systemInfo = useAppSelector(state => state.systemInfo);
  return (
    <Container>
      <Grid container justifyContent='center' gap={1} my={3}>
        <Grid item><Button onClick={() => updateSocketUser()}>Update Socket User</Button></Grid>
        <Grid item><Button onClick={() => notifyEnvironment()}>Show Environment</Button></Grid>
        <Grid item>
          <Button onClick={() => showMessage({
            message: 'This is a test Info alert. You can close me with the button on the right!',
            severity: 'info',
            asAlert: true,
          })}>Show `info` Alert
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={() => showMessage(
            {
              asAlert: true,
              actionButtonInfo: {
                actionButtonText: 'View Data Integrity Checks',
                buttonAction: 'say-hello',
                redirectTo: '/data_integrity_errors',
              },
              message: 'This is a test Success alert. You can close me with the button on the right!',
              severity: 'success',
            },
          )}
          >
            Show `success` Action Alert
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => showMessage({
              message: `This is a test Error alert. I will disappear in 10 seconds,
              or you can close me with the button on the right!`,
              severity: 'error',
              asAlert: false,
            })}>
          Show `error` Snackbar Alert
          </Button>
        </Grid>
      </Grid>
      <Card>
        <Card.Body>
          <strong>UI Version: {version} / {requiredVersion}</strong><br />
          <strong>User: {userId}</strong><br />
          <div>
            <strong>Token</strong><br/>
            <FontAwesomeIcon
              icon={faCopy as IconProp}
              style={{ position: 'absolute', margin: '8px', cursor: 'hand' }}
              onClick={copyTokenToClipboard}
            />
            <div style={{ paddingLeft: '25px', border: '1px solid #CCC' }}>
              <textarea id='devToken' style={{ width: '100%', border: 'none' }}>{encodedToken}</textarea>
            </div>
          </div>
          <strong>Token Data:</strong><br />
          <pre>{JSON.stringify(token, null, 2)}</pre>
          <strong>Issued:</strong><br />
          {new Date((token?.iat ?? 0) * 1000).toLocaleDateString()}
            &nbsp;{new Date((token?.iat ?? 0) * 1000).toLocaleTimeString()}<br />
          <strong>Expires:</strong><br />
          {new Date((token?.exp ?? 0) * 1000).toLocaleDateString()}
            &nbsp;{new Date((token?.exp ?? 0) * 1000).toLocaleTimeString()}<br />
          <strong>Current Time:</strong><br />
          {new Date().toLocaleDateString()} {new Date().toLocaleTimeString()}<br />
          <strong>Is Expired?</strong><br />
          {isTokenExpired() ? 'yes' : 'no'}<br />
          <strong>Freshbooks App Account ID:</strong>{process.env.REACT_APP_FRESHBOOKS_ACCOUNT_ID}<br/><br/>

          <strong>React app environment vars</strong><br/>
          <pre>{JSON.stringify(process.env, null, 2)}</pre>

          <Button onClick={() => getSystemInfo()}>Update System/Environment Info</Button>
          <pre>{JSON.stringify(systemInfo, null, 2)}</pre>
        </Card.Body>
      </Card>
    </Container>
  );
};
