import React, { ReactNode, useState } from "react";
import { 
  Box,
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle,
  Tab,
  Tabs,
} from "@mui/material";
import { OwnershipGroupContacts } from "./OwnershipGroupContacts";
import { OwnershipGroupProperties } from "./OwnershipGroupProperties";
import { Nullable } from "@jamesgmarks/utilities";

interface ITabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}
  
const TabPanel = ({ children, value, index, ...other }: ITabPanelProps) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {
      value === index
        && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )
    }
  </div>
);
  
export const OwnershipGroupDetailsModal = ({
  showModal,
  setShowModal,
  ownershipGroupId,
  handleModalSubmit,
}: {
  showModal: boolean,
  setShowModal: ((newState: boolean) => void),
  ownershipGroupId: Nullable<number>,
  handleModalSubmit: () => void,
}) => {
  const [ value, setValue ] = useState(0);

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <Dialog 
        id="modal-ownership-group-details"
        open={showModal} 
        aria-labelledby="form-ownership-group-details-dialog" 
        scroll="paper" 
        maxWidth='lg' 
        onClose={handleClose}
      >
        <DialogTitle id="form-ownership-group-details-dialog">Ownership Group Details</DialogTitle>
        <DialogContent dividers>
          <Box sx={
            {
              background: 'rgba(224,224,224,0.8)', 
              borderBottom: 1,
              borderColor: 'divider',
              borderRadius: '8px',
              mx: 1,
              pb: 0.5,
            }
          }>
            <Tabs value={value} onChange={(_e, newValue) => setValue(newValue)}>
              <Tab label='Contact' />
              <Tab label='Properties' />
            </Tabs>
          </Box>
          <Box sx={
            {
              height: 800,
              maxHeight: 1000,
              minWidth: 800,
              mb: 2,
              overflow: 'hidden',
              overflowY: 'auto',
              width: '100%',
            }
          }>
            {
              ownershipGroupId
                ? <>
                  <TabPanel value={value} index={0}>
                    <OwnershipGroupContacts ownershipGroupId={ownershipGroupId} handleModalSubmit={handleModalSubmit} />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <OwnershipGroupProperties ownershipGroupId={ownershipGroupId} />
                  </TabPanel>
                </>
                : <>Please provide a valid ownership group id!</>
            }
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
