import { HashOf, keys } from "@jamesgmarks/utilities";
import { DropdownMultiselect, ICustomDropdownMultiselectOption } from "../DropdownMultiselect";
import { ucaseFirst } from "../../../app-utils";
import { invoiceStatusCustomOptions, invoiceStatusFilterOptions } from "./StatusOptions";

// defaults to invoice status dropdown
export const StatusDropdown = (
  {
    title = ``,
    disabled = false,
    statusFilterOptions = invoiceStatusFilterOptions,
    customOptions = invoiceStatusCustomOptions,
    onCustomOptionSelected,
    onItemToggle,
  } : {
    title?: string,
    disabled?: boolean,
    statusFilterOptions?: HashOf<boolean>,
    customOptions?: ICustomDropdownMultiselectOption[],
    onCustomOptionSelected: (optionId: string) => void,
    onItemToggle: (id: string) => void,
  },
) => {
  
  return (
    <>
      <DropdownMultiselect
        title = {title}
        disabled= {disabled}
        customOptions = {customOptions}
        selectionMap = {statusFilterOptions}
        onCustomOptionSelected={onCustomOptionSelected}
        onItemToggle={onItemToggle}
      />
      &nbsp;
      &nbsp;
      &nbsp;
      {
        ` ${`${
          keys(statusFilterOptions)
            .filter(k => statusFilterOptions[k])
            .map((checked) => ucaseFirst(`${checked}`))
            .join(', ')
            .trim()
        }` || `No option selected` }`
      }
    </>
  );
};