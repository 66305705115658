import { useRef, useState } from "react";

import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
} from "@mui/material";

import { IClientMatch } from "../../../../../rentsync-api/IClientMatch";
import { HashOf, Nullable } from "@jamesgmarks/utilities";
import { sendClientRerunRequest } from "../../../redux/features/clients/actions";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useAuth } from '../../../customHooks/useAuth';
export const RerunModal = ({ client, month, setShowModal, showModal, year }
: {
  client: Nullable<IClientMatch>,
  month: Nullable<number>
  setShowModal: ((newState: boolean) => void),
  showModal: boolean,
  year: Nullable<number>,
}) => {

  const [notes, setNotes] = useState("");
  const anchorRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const { hasPermission } = useAuth();
  const handleClose = () => {
    setShowModal(false);
  };

  const handleRerunOnMainPod = () => {
    handleRerun();
  };
  const handleRerunAsJob = () =>{
    handleRerun(true);
  };

  const handleRerun = (asJob = false) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`are you sure you want to rerun invoices for ${client?.name} for ${year}-${month}`)) {
      sendClientRerunRequest(year || 0, month || 0, client?.freshbooksClientId || 0, notes, asJob);
      handleClose();
    }
  };
  const optionDetails: HashOf<{
    label: string,
    handler: () => void,
    visible: boolean,
  }> = {
    rerun: {
      label: 'Initiate Rerun as Job',
      handler: () => {
        handleRerunAsJob();
      },
      visible: hasPermission('RERUN_ACCESS') || hasPermission('FULL_ACCESS'),
    },
  };

  const handleMenuItemClick = (
    optionKey: string,
  ) => {
    optionDetails[optionKey]?.handler();
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handlePopperClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <Dialog open={showModal} aria-labelledby="form-dialog-title">
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition
          nonce={undefined}
          onResize={() => {}}
          onResizeCapture={() => {}}
          sx={{ zIndex: 1400}}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={(e) => handlePopperClose(e)}>
                  <MenuList id="split-button-menu">
                    {Object.keys(optionDetails).map((optionKey, index) => (
                      optionDetails[optionKey].visible && (
                        <MenuItem
                          key={optionKey}
                          onClick={() => handleMenuItemClick(optionKey)}
                        >
                          {optionDetails[optionKey].label}
                        </MenuItem>
                      )
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <DialogTitle id="form-dialog-title">Rerun {client?.name} for {year}-{month} </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Before rerunning invoices for this client, please enter some notes as to why you are doing this rerun.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Notes"
            type="text"
            value={notes}
            fullWidth
            onChange={(event) => { setNotes(event.target.value); }}
          />
        
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <ButtonGroup
            variant="contained"
            color='primary'
            ref={anchorRef}
            aria-label="split button"
          >
            <Button 
              disabled={notes.length < 1}
              onClick={handleRerunOnMainPod} color="primary"
            >
            Rerun
            </Button>
            <Button
              disabled={notes.length < 1} 
              onClick={handleToggle} 
              color="primary"
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu">
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
        
        </DialogActions>
      </Dialog>
    </div>
  );
};